import { EquipmentForShift } from '@/models/api';
import Equipment from '@/models/client/equipment';
import { EquipmentRole } from '../api/Data/equipment-role';

export const transformEquipment = (
    untransformed: EquipmentForShift[],
    shiftId: string,
    roles: EquipmentRole[]
): Equipment[] => {
    const transformed = untransformed.map((x) => {
        if (x.equipment === null || x.equipment === undefined)
            throw new Error(`No equipment assigned to planned equipment ${x.id}`);
        if (x.equipment.name === null) throw new Error(`Equipment name is null for planned equipment ${x.id}`);
        if (x.equipment.equipmentRoleId === null || x.equipment.equipmentRoleId === undefined)
            throw new Error(`Equipment role is null for planned equipment ${x.id}`);
        if (x.equipment.equipmentType === null) throw new Error(`Equipment type is null for planned equipment ${x.id}`);
        if (x.equipment.imageUrl === null) throw new Error(`Equipment image url is null for planned equipment ${x.id}`);

        const secondaryEquipment =
            x.lastKnownShiftLocations.find((x) => x.departmentShiftId === shiftId)?.secondaryEquipment ?? [];

        const comment = x.lastKnownShiftLocations.find((x) => x.departmentShiftId === shiftId)?.comments ?? '';
        const location = x.lastKnownShiftLocations.find((x) => x.departmentShiftId === shiftId)?.location ?? null;

        const role = roles.find((role) => role.id === x.equipment!.equipmentRoleId);
        if (role === undefined) throw new Error(`Equipment role ${x.equipment!.equipmentRoleId} cannot be found`);

        const equipment: Equipment = {
            id: x.id,
            equipmentId: x.equipment.id,
            name: x.equipment.name,
            role: {
                id: role.id,
                name: role.name!,
                displayOrder: role.displayOrder,
                isCrewType: role.isCrewType,
            },
            type: x.equipment.equipmentType,
            imageUrl: x.equipment.imageUrl,
            showConflicts: x.equipment.showConflicts,
            idleOrUnavailable: x.idleOrUnavailable,
            staffIds: x.staffIds,
            secondaryEquipmentIds: secondaryEquipment,
            location: location,
            comment: comment,
            availability: x.availability.map((x) => {
                return { startsAt: x.startsAt, endsAt: x.endsAt, type: x.equipmentAvailabilityType!, id: x.id };
            }),
            hazardNotes: x.hazardNotes,
        };

        return equipment;
    });

    return transformed;
};
