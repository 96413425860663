<script>
import { Pie } from 'vue-chartjs';

export default {
    extends: Pie,
    props: ['chartdata', 'options', 'width'],
    data() {
        return {
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            }
        };
    },
    mounted() {
        this.chartOptions = { ...this.chartOptions, ...this.options };
        this.create();
    },
    methods: {
        create() {
            if (!this.chartdata) {
                return;
            }
            setTimeout(() => this.renderChart(this.chartdata, this.chartOptions), 1);
        }
    },
    watch: {
        chartdata() {
            if (!this.chartdata) {
                return;
            }
            this.create();
        },
        options() {
            this.chartOptions = { ...this.chartOptions, ...this.options };
        }
    }
};
</script>

<style></style>
