import { defineStore } from 'pinia';
import EquipmentRoles from '@/lib/data/EquipmentRoles';
import EventBus, { Events } from '@/lib/EventBus';
import { StaffRole, EquipmentRole, Staff } from '@/models/api';
import StaffRoles from '@/lib/data/StaffRoles';
import StaffApi from '@/lib/data/Staff';
import WeekCrews from '@/lib/data/WeekCrews';

/**
 * This store contains all the information and data which is
 * specified at a tenant level.
 */
export const useSystemStore = defineStore('system', {
    state: () => {
        return {
            isInitialized: false,
            isOnline: true,
            equipmentRoles: [] as EquipmentRole[],
            staffRoles: [] as StaffRole[],
            staffMembers: [] as Staff[],
        };
    },
    getters: {
        getStaffRoleById(state): (roleId: string) => StaffRole {
            return (roleId: string) => {
                const staffRole = state.staffRoles.find((x) => x.id === roleId);
                if (staffRole === undefined) throw new Error(`Cannot find staff role with id ${roleId}`);

                return staffRole;
            };
        },
        getStaffById(state): (staffId: string) => Staff {
            return (staffId: string) => {
                const staffMember = state.staffMembers.find((x) => x.id === staffId);
                if (staffMember === undefined) throw new Error(`Cannot find staff member with id ${staffId}`);

                return staffMember;
            };
        },
    },
    actions: {
        async initializeStore() {
            await this.populateEquipmentRoles();
            await this.populateStaffRoles();
            await this.populateStaff();

            EventBus.$on(Events.StaffUpdated, this.onStaffUpdated);

            this.isInitialized = true;
        },
        async populateEquipmentRoles() {
            this.equipmentRoles = await EquipmentRoles.get();
        },
        async populateStaffRoles() {
            this.staffRoles = await StaffRoles.get();
        },
        async populateStaff() {
            this.staffMembers = await StaffApi.get(['StaffRole', 'Crew', 'StaffAvailability']);
        },
        async addStaffMember(staffMember: Staff) {
            await StaffApi.add({
                _type: "CreateStaffCommand",
                id: staffMember.id,
                crewId: staffMember.crewId,
                staffRoleId: staffMember.staffRoleId,
                name: staffMember.name,
                isSandpitUseOnly: staffMember.isSandpitUseOnly ?? false,
            });
            this.staffMembers.push(staffMember);
        },
        async scheduleUnplannedLeave(staffId: string, leaveTimes: []) {
            const staffMember = this.getStaffById(staffId);
            staffMember.staffAvailability.push(...leaveTimes);

            await WeekCrews.saveLeave(staffId, leaveTimes);
        },
        setOnline(isOnline: boolean) {
            this.isOnline = isOnline;

            if (this.isOnline === false) {
                EventBus.$emit(Events.ToastInfo, 'Editing offline - saves will send when back online');
            }
        },
        async onStaffUpdated() {
            await this.populateStaff();
        }
    },
});
