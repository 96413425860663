import { render, staticRenderFns } from "./Screen.vue?vue&type=template&id=9c1d3136&scoped=true&"
import script from "./Screen.vue?vue&type=script&lang=ts&"
export * from "./Screen.vue?vue&type=script&lang=ts&"
import style0 from "./Screen.vue?vue&type=style&index=0&id=9c1d3136&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c1d3136",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAppBar,VBtn,VBtnToggle,VContainer,VRow})
