<template>
    <v-dialog v-model="dialog" max-width="80vw" persistent>
        <v-card>
            <v-card-text class="pa-0 printwindow" v-if="url" style="position:relative;">
                <content-loading :value="loading" loaderName="Print Window"></content-loading>
                <embed class="printpreview" type="application/pdf" :src="url" ref="obj" />
            </v-card-text>
            <v-card-actions>
                <div>Problems previewing?</div>
                <v-btn @click="download" color="primary" small class="ml-3">Download</v-btn>
                <v-btn @click="refresh" text small class="ml-3">Refresh</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="close">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import DownloadHelper from '@/lib/DownloadHelper';
import AppHttp from '@/lib/AppHttp';
const fakeURL = null;

export default {
    data() {
        return { dialog: false, url: fakeURL, loading: false };
    },
    methods: {
        show(url) {
            this.url = AppHttp.urlFromPath(url);
            this.load();
        },
        load() {
            this.dialog = true;
            this.loading = true;
            var me = this;
            setTimeout(() => {
                var object = this.$refs.obj;
                if (object.attachEvent) {
                    object.attachEvent('onload', function() {
                        me.loading = false;
                    });
                } else {
                    object.onload = function() {
                        me.loading = false;
                    };
                }
            }, 1);
            setTimeout(() => (me.loading = false), 30000);
        },
        close() {
            this.url = fakeURL;
            setTimeout(() => {
                this.dialog = false;
            }, 10);
        },
        async download() {
            if (this.url == fakeURL) {
                return;
            }
            var parts = this.url.split('/');
            var name = parts[parts.length - 1];
            var pdf = await AppHttp.download(this.url);
            DownloadHelper.download(name, pdf.data);
        },
        refresh() {
            var url = this.url;
            this.url = fakeURL;
            setTimeout(() => {
                this.url = url;
                this.load();
            }, 10);
        }
    }
};
</script>
<style lang="scss" scoped>
.printwindow,
.printpreview {
    height: 80vh;
    width: 80vw;
}
</style>
