<template>
    <p class="text-xs-center pa-5">Logging you in...</p>
</template>

<script>
import AuthService from '@/lib/Auth';
import { Events, EventBus } from '@/lib/EventBus';
export default {
    name: 'callback',
    props: ['auth'],
    async created() {
        try {
            await AuthService.handleAuthentication();
        } catch (err) {
            EventBus.$emit(Events.ToastError, 'An error occurred while logging you in, please try again', err);
        }

        this.$router.push({ name: 'home' });
    }
};
</script>
