<template>
    <basic-screen>
        <template slot="appBarCenter">
            <router-link :to="{ name: `default${boardType}board` }">Back to Board</router-link>
        </template>
        <navigation-drawer :key="rerenderKey" :is-user-admin="isUserAdmin" :is-sandpit-manager="isSandpitManager"></navigation-drawer>

        <div style="flex-grow: 1">
            <router-view />
            <div class="mt-5" v-if="$route.query.superadmin">
                <h1>Super Admin</h1>
                <v-btn @click="fixUsers">FIX USERS</v-btn>
            </div>
        </div>
    </basic-screen>
</template>
<script>
import ShiftStore from '@/lib/stores/CurrentShiftStore';
import Security, { Roles } from '@/lib/Security';
import Admin from '@/lib/data/Admin';
import BasicScreen from '@/templates/BasicScreen.vue';
import { EventBus, Events } from '@/lib/EventBus';
import NavigationDrawer from '@/components/Admin/NavigationDrawer.vue';
export default {
    components: {
        NavigationDrawer,
        BasicScreen
    },
    data() {
        return {
            boardType: ShiftStore.selectedBoardType,
            isUserAdmin: false,
            isSandpitManager: false,
            rerenderKey: 0
        };
    },
    created() {
        this.isUserAdmin = Security.hasRole(Roles.UserAdministrator);
        this.isSandpitManager = Security.hasRole(Roles.SandpitManager);
        EventBus.$on(Events.TermsUpdated, this.refreshNavigationTerms);
    },
    destroyed() {
        EventBus.$off(Events.TermsUpdated, this.refreshNavigationTerms);
    },
    methods: {
        async fixUsers() {
            await Admin.fixUsers();
        },
        refreshNavigationTerms() {
            this.rerenderKey = this.rerenderKey + 1;
        }
    }
};
</script>
