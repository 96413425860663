import { GenericDataApi } from '@/lib/data/GenericDataApi';
import AppHttp from '@/lib/AppHttp';
import Errors from '@/lib/Errors';
import EditMappings from '../OfflineEdits/EditMappings';
import { AxiosError } from 'axios';
import { PlannedEquipmentViewModel } from '@/models/api/ViewModels/Boards/planned-equipment-view-model';
import { PlannedEquipment as PlannedEquipmentDataModel } from '@/models/api/Data/planned-equipment';
import RequestHandler from '../api/RequestHandler';
import {
    AddEquipmentHazardsAtShiftCommand, GetEquipmentUsageInOtherDepartmentsQueryResponse,
    UpdateEquipmentHazardsAtShiftCommand
} from '@/models/api';

export class PlannedEquipment extends GenericDataApi<PlannedEquipmentDataModel> {
    constructor() {
        super('PlannedEquipment');
    }

    public async forWeekWithoutTasks(weekId: string) {
        const response = await AppHttp.get(`PlannedEquipment/Week/${weekId}`);
        const data = response ? response.data : null;
        return data;
    }

    public async setLocationForShift(shiftId: string, plannedEquipmentId: string, location: string) {
        const response: any = await RequestHandler.execute(
            'POST',
            `PlannedEquipment/${plannedEquipmentId}/Shift/${shiftId}/Location`,
            {
                data: location,
            }
        );
        const data = response ? response.data : null;
        return data;
    }
    public async setCommentsForShift(shiftId: string, plannedEquipmentId: string, comments: string) {
        const response: any = await RequestHandler.execute(
            'POST',
            `PlannedEquipment/${plannedEquipmentId}/Shift/${shiftId}/Comments`,
            {
                data: comments,
            }
        );
        const data = response ? response.data : null;
        return data;
    }

    async addHazardsForShift(command: AddEquipmentHazardsAtShiftCommand) {
        const response: any = await RequestHandler.execute(
            'POST',
            this.resource + '/Hazards/Add',
            this.cleanOutgoingData({ ...command })
        );
        const data = response ? response.data : null;
        return data;
    }

    public async updateHazardsForShift(command: UpdateEquipmentHazardsAtShiftCommand) {
        const response: any = await AppHttp.post(
            this.resource + '/Hazards',
            this.cleanOutgoingData({ ...command })
        );
        const data = response ? response.data : null;
        return data;
    }

    public async setSecondaryEquipmentForShift(shiftId: string, plannedEquipmentId: string, secondary: string[]) {
        const response: any = await RequestHandler.execute(
            'POST',
            `PlannedEquipment/${plannedEquipmentId}/Shift/${shiftId}/Secondary`,
            secondary
        );
        const data = response ? response.data : null;
        return data;
    }
    public async setIdle(plannedEquipmentId: string, idle: boolean) {
        const response: any = await RequestHandler.execute(
            'POST',
            `PlannedEquipment/${plannedEquipmentId}/IdleOrUnavailable`,
            {
                data: idle,
            }
        );
        const data = response ? response.data : null;
        return data;
    }
}

export default new PlannedEquipment();
