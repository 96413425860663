import axios, { AxiosError, AxiosResponse } from 'axios';
import { EventBus, Events } from '@/lib/EventBus';
import { cleanseErrorOfSensitiveInformation, FetchResponseWrapper } from '@/lib/AppHttp';
import Config from '@/lib/Config';
import Errors from '@/lib/Errors';

class ResourceClient {
    public async get(path: any): Promise<FetchResponseWrapper<any> | null> {
        const url = this.urlFromPath(path);
        try {
            const headers: any = {
                'Content-Type': 'text/plain'
            };
            const response = await fetch(url, {
                method: 'GET',
                headers: headers
            });
            const wrapped = new FetchResponseWrapper();
            await wrapped.Init(response);
            if (!response.ok) {
                throw { response: wrapped };
            }
            EventBus.$emit(Events.ApiCallSuccess);
            return wrapped;
        } catch (err) {
            return await this.handleError(err, url, 'GET', null);
        }
    }

    public async download(path: string) {
        const url = this.urlFromPath(path);
        const headers: any = {
            'Content-Type': 'text/plain'
        };
        const response = await axios.get(url, { headers: headers, responseType: 'blob' });
        EventBus.$emit(Events.ApiCallSuccess);
        return response;
    }

    public urlFromPath(path: string) {
        if (path.indexOf('http') == 0) {
            return path;
        }
        return (path[0] === '/' ? '' : '/') + path;
    }

    private async handleError(err: any, url: string, method: string, model: any | undefined): Promise<any> {
        if (!err.response) {
            throw { handled: false, err: err};
        }

        switch (err.response.status) {
            default: {
                Errors.ApiError(cleanseErrorOfSensitiveInformation(err));
                throw { handled: false, err: cleanseErrorOfSensitiveInformation(err) };
            }
        }
    }
}

export default new ResourceClient();