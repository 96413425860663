import AppHttp from '@/lib/AppHttp';
import Errors from '@/lib/Errors';
import { AxiosError } from 'axios';
import { MineInteractionsBoardViewModel } from '@/models/api';

export class Interactions {
    public async interactions(mineAreaId: string, date: string, number: number): Promise<MineInteractionsBoardViewModel> {
        const params = {
            mineAreaId,
            date,
            number
        } as any;
        const response = await AppHttp.get(`Interactions/Board?${new URLSearchParams(params).toString()}`);
        const data = response ? response.data : null;
        return data;
    }

    public async resolveLevel(interactionsId: string, levelId: string, isAddressed: boolean, comment: string) {
        const response = await AppHttp.post(`Interactions/${interactionsId}/levels/${levelId}/conflict`, {
            isAddressed: isAddressed,
            warningComment: comment
        });
        const data = response ? response.data : null;
        return data;
    }
}
export default new Interactions();
