import {
    CreateTriggerActionResponsePlanAspectCommand,
    UpdateTriggerActionResponsePlanAspectCommand,
    ReorderTriggerActionResponsePlanAspectsCommand,
    TriggerActionResponsePlanAspectViewModel,
    GetTriggerActionResponsePlanAspectsQueryResponse,
    UpdateActualPhysicalsEntriesCommand,
    GetBlastPacketsForShiftWindowQueryResponse,
    GetActualPhysicalEntriesForShiftWindowQueryResponse,
    GetActualPhysicalEntriesForWeekWindowQueryResponse
} from '@/models/api';
import { AxiosError } from 'axios';
import AppHttp from '../AppHttp';
import Errors from '../Errors';
import RequestHandler from '@/lib/api/RequestHandler';

export class ActualPhysicals {
    public resource: string = "Shift/Actuals";

    async UpdateActualPhysicals(command: UpdateActualPhysicalsEntriesCommand) {
        await RequestHandler.execute("POST", `${this.resource}`, command);
    }

    async ExportShiftPhysicals(shiftId: string) {
        const response = await AppHttp.get(`Shift/${shiftId}/Actuals/Csv`);
        const data = response ? response.data : null;
        return data;
    }

    async ExportPhysicalsByTimeRange(departmentId: string, rangeStartTime: string | null, rangeEndTime: string | null) {
        let query = '';
        const queryParameters = [rangeStartTime != null ? `fromTime=${rangeStartTime}` : null, rangeEndTime != null ? `toTime=${rangeEndTime}` : null];

        if(queryParameters.filter(qp=>qp!=null).length > 0){
            query = queryParameters.filter(qp=>qp!=null).join("&");
            query = `?${query}`;
        }

        const response = await AppHttp.get(`ActualPhysicals/Departments/${departmentId}/Csv${query}`);

        const data = response ? response.data : null;
        return data;
    }

    public async GetWindowByShift(mineAreaId: string,
                                  departmentId: string,
                                  date: string,
                                  number: string,
                                  offsetPrior: number,
                                  offsetFollowing: number): Promise<GetActualPhysicalEntriesForShiftWindowQueryResponse> {
        const response = await AppHttp.get(`${this.resource}/GetWindowForShift?mineAreaId=${mineAreaId}&departmentId=${departmentId}&date=${date}&number=${number}&offsetPrior=${offsetPrior}&offsetFollowing=${offsetFollowing}`);

        const data = response ? response.data : null;
        if(data == null) throw Error('No response data');
        return data;
    }

    public async GetWindowByWeek(mineAreaId: string,
                                 departmentId: string,
                                 planId: string): Promise<GetActualPhysicalEntriesForWeekWindowQueryResponse> {
        const response = await AppHttp.get(`Week/Actuals/GetWindowForWeek?mineAreaId=${mineAreaId}&departmentId=${departmentId}&planId=${planId}`);

        const data = response ? response.data : null;
        if(data == null) throw Error('No response data');
        return data;
    }
}

export default new ActualPhysicals();
