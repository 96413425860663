import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const options: VueI18n.I18nOptions = {
    locale: 'en',
    fallbackLocale: 'en',
    silentFallbackWarn: true,
    messages: {
        en: {
            shared: {
                button: {
                    add: 'Add',
                    'add-new': 'Add New',
                    cancel: 'Cancel',
                    close: 'Close',
                    save: 'Save'
                },
                label: {
                    crew: 'Crew',
                    filter: 'Filter',
                    from: 'from',
                    leave: 'Leave',
                    'major-service': 'Major Service',
                    'minor-service': 'Minor Service',
                    name: 'Name',
                    overtime: 'Overtime',
                    role: 'Role',
                    service: 'Service',
                    unserviceable: 'Unserviceable',
                    'weekly-service': 'Weekly Service'
                },
                message: {
                    'no-connection': 'No Connection',
                    'shift-planning-not-started': 'Planning for this shift has not started'
                }
            },
            equipment: {
                'mark-as-serviceable': 'Mark as Serviceable',
                'mark-as-unserviceable': 'Mark as Unserviceable'
            },
            'equipment-availability-dialog': {
                heading: 'Equipment Availability'
            }
        },
        es: {
            shared: {
                button: {
                    add: 'Añadir',
                    cancel: 'Cancelar',
                    close: 'Cerrar',
                    save: 'Guardar'
                },
                label: {
                    crew: 'Personal de turno',
                    filter: 'Filtro',
                    from: 'desde',
                    'major-service': 'Servicio mayor',
                    'minor-service': 'Servicio menor',
                    role: 'Función',
                    unserviceable: 'Inservible',
                    'weekly-service': 'Servicio semanal'
                }
            },
            equipment: {
                'mark-as-unserviceable': 'Marcar como inservible'
            },
            'equipment-availability-dialog': {
                heading: 'Disponibilidad del equipo'
            }
        }
    }
};

export const i18n = new VueI18n(options);

export const Locales = [
    { text: 'English', value: 'en' },
    { text: 'Español', value: 'es' }
];
