import { render, staticRenderFns } from "./StaffMemberChip.vue?vue&type=template&id=4ee3f0c3&"
import script from "./StaffMemberChip.vue?vue&type=script&lang=ts&"
export * from "./StaffMemberChip.vue?vue&type=script&lang=ts&"
import style0 from "./StaffMemberChip.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBadge,VChip,VIcon})
