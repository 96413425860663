<template>
    <report-viewer />
</template>
<script>
import Shift from '@/lib/data/Shift';
import CurrentShiftStore from '@/lib/stores/CurrentShiftStore';
import ReportViewer from '@/components/Reports/ReportViewer.vue';

export default {
    components: { ReportViewer },
    computed: {
        priority1() {
            return this.shift.rows.filter(x => x.priority == 1);
        },
        priority2() {
            return this.shift.rows.filter(x => x.priority == 2);
        },
        theRest() {
            return this.shift.rows.filter(x => x.priority > 2);
        }
    },
    data() {
        return {
            shift: null,
            plannedEquipment: null,
            doesntExist: false,
            planDoesntExist: false
        };
    },
    async created() {
        await this.loadData();
    },
    destroyed() {},
    methods: {
        async loadData() {
            CurrentShiftStore.setBoard('analytics');
            try {
                this.$wait.start('get data');
                this.shift = await Shift.lastShift(
                    this.$route.params.mine,
                    this.$route.params.dept,
                    this.$route.params.date
                );

                if (!this.shift.exists) {
                    this.doesntExist = true;
                    this.planDoesntExist = !this.shift.planExists;
                    return;
                }
            } catch (e) {
                return;
            } finally {
                this.$wait.end('get data');
            }
        },
        getMaxSegments() {
            var max = 0;
            for (let row of this.shift.rows) {
                var complete = row.progress.complete + row.progress.pending;
                if (complete > max) {
                    max = complete;
                }
                if (row.progress.target > max) {
                    max = row.progress.target;
                }
            }
            return max;
        }
    }
};
</script>

<style lang="scss" scoped></style>
