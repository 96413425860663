
import { Events, EventBus } from '@/lib/EventBus';
import AppHttp from '@/lib/AppHttp';

export interface ITenant {
    id:string,
    name: string[]
}

export class Tenants {
    public GetCurrentTenant(): string | null {
        return localStorage.getItem("tenant");
    }
    public SetCurrentTenant(tenantId:string | null) {
        if (!tenantId) localStorage.removeItem("tenant")
        else localStorage.setItem("tenant", tenantId);
        EventBus.$emit(Events.TenantChanged, tenantId);
    }
    public async GetMyTenants() {
        const response = await AppHttp.get("Tenants/MyTenants");
        const data = response ? response.data as ITenant[] : null;
        return data;
    }
}

const instance = new Tenants();
export default instance;
