import { defineStore } from 'pinia';
import {
    LocationRingsStopeModel,
    PropertyDefinitionModel,
    RingConfiguration,
    RingConfigurationDesign,
    SetLocationPlanningPriorityCommand,
    SLCLocationTrackerLocationViewModel, StopeLocationTrackerLocationViewModel,
    TriggerActionResponsePlanAspectViewModel,
    UpdateLocationDetailsCommand,
    UpdateLocationStatusCommand,
    UpdateLocationTriggerActionResponsePlanStatusCommand,
    UpsertLocationExtendedPropertyCommand
} from '@/models/api';
import { useMineAreaStore } from '@/lib/stores/MineAreaStore';
import Locations from '@/lib/data/Locations';
import LocationDetails from '@/lib/data/LocationDetails';
import { useLocationTrackerStore } from '@/lib/stores/LocationTrackerStore';
import {
    ClientCommonLocationTracking,
    ClientSLCLocationTrackerLocationViewModel, ClientStopeLocationTrackerLocationViewModel
} from '@/models/client/location-tracker';

function transformLocationViewModelToClientModel(location: SLCLocationTrackerLocationViewModel): ClientSLCLocationTrackerLocationViewModel {
    return {
        locationId: location.locationId,
        commonInformation: {
            ... location.commonInformation!
        },
        productionInformation: {
            ... location.productionInformation!
        },
        subLevelCavingInformation: {
            ... location.subLevelCavingInformation!
        }
    };
}

export const useSLCLocationTrackerStore = defineStore('slcLocationTracker', {
    state: () => {
        return {
            _departmentId: '',
            _mineAreaId: '',
            isInitialized: false,
            locations: [] as ClientSLCLocationTrackerLocationViewModel[],
        };
    },
    actions: {
        async populateStore(mineId: string, departmentId: string) {
            this.isInitialized = false;
            this._departmentId = departmentId;
            this._mineAreaId = mineId;

            await this.populateLocations(departmentId);
            await useLocationTrackerStore().populateStore(mineId, departmentId, this.patchLocationCommonInformation);
            this.isInitialized = true;
        },
        async populateLocations(departmentId: string): Promise<void> {
            const response =
                ((await Locations.GetSLCLocationTrackerDetail(departmentId)) as SLCLocationTrackerLocationViewModel[])
                ?? ([] as ClientSLCLocationTrackerLocationViewModel[]);
            if (response) {
                this.locations.splice(0, this.locations.length, ...response.map(transformLocationViewModelToClientModel));
            }
        },
        async populateLocationRingsForStope(departmentId: string, stopeId: string) {
            await useLocationTrackerStore().populateLocationRingsForStope(departmentId, stopeId);
        },
        clearLocationRingsForOtherStopes(stopeId: string) {
            useLocationTrackerStore().clearLocationRingsForOtherStopes(stopeId);
        },
        clearLocationRingsForAllStopes() {
            useLocationTrackerStore().clearLocationRingsForAllStopes();
        },
        patchLocationCommonInformation(locationId: string, patcher: (location: ClientCommonLocationTracking) => ClientCommonLocationTracking) {
            const locationIndex = this.locations.findIndex(l => l.locationId === locationId);
            if(locationIndex === -1) return;
            const location = this.locations[locationIndex];
            if (location != null && location.commonInformation != null) {
                this.locations.splice(locationIndex, 1, {
                    ...location,
                    commonInformation: patcher(location.commonInformation)
                });
            }
        },
        async executeUpdateLocationTARPStatus(command: UpdateLocationTriggerActionResponsePlanStatusCommand) {
            await Locations.executeUpdateTARPStatusCommand(command);

            // force refresh
            await this.populateStore(this._mineAreaId, this._departmentId);
        },
        async executeUpdateLocationStatus(command: UpdateLocationStatusCommand) {
            await Locations.executeUpdateStatusCommand(command);

            const locationToUpdate = this.locations.find(
                (l: ClientSLCLocationTrackerLocationViewModel) => l.locationId === command.locationId
            );

            if (locationToUpdate) locationToUpdate.commonInformation!.locationStatus = command.status;
        },
        async executeUpdateLocationDetails(command: UpdateLocationDetailsCommand) {
            await LocationDetails.send(command);

            const locationToUpdate = this.locations.find(l=>l.locationId === command.locationId);

            if(locationToUpdate != null && locationToUpdate.commonInformation != null) {
                locationToUpdate.commonInformation.details = {
                    _type: 'LocationTrackingLocationDetailsViewModel',
                    stockpileId: command.stockpileId,
                    materialDestinationId: command.materialDestinationId,
                    materialTypeId: command.materialTypeId
                };
            }
        },
        async executeSetPlanningPriority(command: SetLocationPlanningPriorityCommand) {
            await Locations.executeSetPlanningPriorityCommand(command);

            await this.populateLocations(this._departmentId);
        },
        async executeUpdateExtendedProperty(command: UpsertLocationExtendedPropertyCommand): Promise<{ id: string }> {
            const response = await Locations.ExecuteUpsertExtendedProperty(command);

            await this.populateLocations(this._departmentId);
            return response;
        },
    }
});