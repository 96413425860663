<template>
    <div class="pa-5">
        <v-card v-if="data">
            <v-card-title>
                <h1 class="title">{{ 'Actual Physicals Sources' | term }}</h1>
                <v-btn small color="secondary" class="ml-3" @click="create()">
                    <v-icon left>mdi-plus</v-icon>Add
                </v-btn>
                <v-menu
                    v-model="importDialog"
                    max-width="310px"
                    :close-on-content-click="false"
                    v-if="!nonSystem.length"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" small class="ml-1" color="secondary">
                            <v-icon left>mdi-upload</v-icon>Import
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-text>
                            <input ref="inputUpload" type="file" accept="text/csv" @change="getFile($event)" />
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="secondary" @click="importData">Upload</v-btn>
                            <v-btn text @click="importDialog = false">cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
                <v-btn
                    small
                    class="ml-1"
                    color="gray"
                    v-if="nonSystem.length"
                    @click="exportData()"
                >
                    <v-icon left>mdi-download</v-icon>Export
                </v-btn>
                <v-btn small color="primary" class="ml-3" v-if="dirty" @click="saveList()">
                    <v-icon left>mdi-check</v-icon>Save
                </v-btn>
                <v-btn small color="grey" class="ml-3" v-if="dirty" @click="loadData()">
                    <v-icon left>mdi-close</v-icon>Cancel
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table
                disable-pagination
                hide-default-footer
                :headers="headers"
                :items="ordered"
            >
                <template v-slot:item="{ item }">
                    <tr :class="{ dirty: item.dirty }">
                        <td><v-icon v-if="isSystemSource(item)" class="mr-1" size="small">mdi-star-circle</v-icon>{{ item.name }}</td>
                        <td>
                            <v-access-control>
                                <div class="no-wrap">
                                    <v-btn text icon @click="up(item)">
                                        <v-icon>mdi-arrow-up</v-icon>
                                    </v-btn>
                                    <v-btn text icon @click="down(item)">
                                        <v-icon>mdi-arrow-down</v-icon>
                                    </v-btn>
                                    <v-btn text icon @click="edit(item)" v-if="!isSystemSource(item)">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </div>
                            </v-access-control>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
        <standard-dialog :render="!!selected" :value='showModal' :max-width="900" persistent>
            <template v-slot:title>
                <span v-if="selected.id">Edit</span>
                <span v-else>Create</span>
                <span class="ml-1">{{ 'Actual Physicals Source' | term }}</span>
            </template>
            <template v-slot:content>
                <v-form ref="form" :lazy-validation="true">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" class="pa-2">
                                <v-text-field
                                    autocomplete="off"
                                    label="Name"
                                    :rules="[$rules.required(), $rules.maxLength(100)]"
                                    v-model="selected.name"
                                    counter="100"
                                ></v-text-field>
                                <v-text-field
                                    autocomplete="off"
                                    label="Priority"
                                    :rules="[$rules.required(), $rules.min(1)]"
                                    v-model="selected.priority"
                                    type="number"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </template>
            <template v-slot:actions>
                <v-btn color='red' text v-if='selected && selected.id' @click='del()'>Delete</v-btn>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
                <v-btn color='primary' text @click='save()'>Save</v-btn>
            </template>
            <template v-slot:offline-actions>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
                <offline-btn text></offline-btn>
            </template>
        </standard-dialog>
        <confirm ref="confirm"></confirm>
    </div>
</template>
<script>
import { EventBus, Events } from '@/lib/EventBus';
import { v4 as uuidv4 } from 'uuid';
import ReducedLevels from '@/lib/data/ReducedLevels';
import DownloadHelper from '@/lib/DownloadHelper';
import ActualPhysicalsSourceTypes from '@/lib/data/ActualPhysicalsSourceTypes';
import StandardDialog from '@/components/Shared/Common/StandardDialog.vue';

export default {
    components: { StandardDialog },
    data() {
        return {
            data: null,
            selected: null,
            showModal: false,
            importDialog: false,
            file: null,
            headers: [
                { text: 'Name', value: 'name', sortable: false, width: '90%' },
                {
                    text: '',
                    value: 'priority',
                    sortable: false,
                    align: 'right'
                }
            ]
        };
    },
    async created() {
        await this.loadData();
    },
    destroyed() {},
    methods: {
        async loadData() {
            var d = await ActualPhysicalsSourceTypes.get();
            for (let x of d.sourceTypes) {
                x.dirty = false;
            }
            this.data = d.sourceTypes;
        },
        create() {
            var priority = this.ordered.length ? this.ordered[this.ordered.length - 1].priority + 1 : 1;
            this.edit({
                priority
            });
        },
        edit(item) {
            this.selected = item;
            this.showModal = true;
            setTimeout(() => this.$refs.form.resetValidation(), 1);
        },
        up(item) {
            var prev;
            for (let x of this.ordered) {
                if (x != item) {
                    prev = x;
                    continue;
                }
                var newPriority = prev.priority;
                prev.priority = item.priority;
                item.priority = newPriority;
                prev.dirty = true;
                item.dirty = true;
                break;
            }
        },
        down(item) {
            var isNext = false;
            for (let x of this.ordered) {
                if (isNext) {
                    var newPriority = x.priority;
                    x.priority = item.priority;
                    item.priority = newPriority;
                    x.dirty = true;
                    item.dirty = true;
                    break;
                }
                if (x == item) {
                    isNext = true;
                }
            }
        },
        isSystemSource(sourceType) {
            return sourceType.isSystem;
        },
        async saveList() {
            const priorities = this.data.reduce((a,v)=>({...a, [v.id]: v.priority}), {});

            await ActualPhysicalsSourceTypes.reprioritise({
                _type: 'ReprioritiseActualPhysicalsSourceTypesCommand',
                priorities
            });

            EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Actual Physicals Sources')} Saved`);
            await this.loadData();
        },
        async save() {
            if (!this.$refs.form.validate()
            || this.$wait.any) {
                return;
            }

            this.$wait.start('saving');
            try{
                if(this.selected.id){
                    await ActualPhysicalsSourceTypes.update({
                        _type: 'UpdateActualPhysicalsSourceTypeCommand',
                        id: this.selected.id,
                        name: this.selected.name,
                        priority: this.selected.priority
                    });
                } else{
                    await ActualPhysicalsSourceTypes.create({
                        _type: 'CreateActualPhysicalsSourceTypeCommand',
                        id: uuidv4(),
                        name: this.selected.name,
                        priority: this.selected.priority
                    });
                }
            }finally {
                this.$wait.end('saving');
            }


            EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Actual Physicals Source')} Saved`);
            this.showModal = false;
            await this.loadData();
        },
        async del() {
            if (
                await this.$refs.confirm.openAsDeleteResource(this.$termSync('Actual Physicals Source'),  { })
            ) {
                await ActualPhysicalsSourceTypes.delete(this.selected.id);
                EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Actual Physicals Source')} Deleted`);
                this.showModal = false;
                await this.loadData();
            }
        },
        async exportData() {
            const result = await ActualPhysicalsSourceTypes.export();
            var csv = result.csvFile;
            var blob = DownloadHelper.makeBlobFromFileString(csv);
            DownloadHelper.download('ActualPhysicalsSourceTypes.csv', blob);
        },
        async importData() {
            try {
                this.$wait.start('saving');
                this.uploadResult = null;
                await ActualPhysicalsSourceTypes.import(this.file);
                this.importDialog = false;
                await this.loadData();
            } finally {
                this.$wait.end('saving');
            }
        },
        async getFile(e) {
            this.file = e.target.files[0];
        }
    },
    computed: {
        ordered() {
            if (!this.data) {
                return [];
            }
            return [... this.data]
                .sort((a, b) => a.priority - b.priority);
        },
        nonSystem(){
          return this.ordered.filter(st=>!st.isSystem)
        },
        dirty() {
            for (let x of this.data) {
                if (x.dirty) {
                    return true;
                }
            }
            return false;
        }
    }
};
</script>
