import { GenericDataApi } from '@/lib/data/GenericDataApi';
import { AxiosError } from 'axios';
import AppHttp from '../AppHttp';
import Errors from '../Errors';

export class EquipmentApi extends GenericDataApi<any> {
    constructor() {
        super('Equipment');
    }
    public async export() {
        const response = await AppHttp.get(`${this.resource}/export`);
        const data = response ? response.data : null;
        return data;
    }
    public async import(file: any) {
        const response = await AppHttp.postWithFiles(`${this.resource}/import`, {}, [file]);
        const data = response ? response.data : null;
        return data;
    }
    async saveEquipment(model: any) {
        const response = await AppHttp.post(`${this.resource}/save`, model);
        const data = response ? response.data : null;
        return data;
    }
}

export default new EquipmentApi();
