<template>
    <div class='pa-5'>
        <v-card v-if='data'>
            <v-card-title>
                <h1 class='title'>{{ 'Mine Areas' | term }}</h1>
                <v-btn small color='secondary' class='ml-3' @click='create()'>
                    <v-icon left size='medium'>mdi-plus</v-icon>
                    Add
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table disable-pagination hide-default-footer :headers='headers' :items='data'>
                <template v-slot:item.shiftNames='{ item }'>
                    <v-chip v-for='shift in item.availableShifts' :key='shift.number'>{{ shift.name }}</v-chip>
                </template>
                <template v-slot:item.id='{ item }'>
                    <v-btn text icon @click='edit(item)' class='float-right'>
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn text icon @click='editSubscribers(item)' class='float-right'>
                        <v-icon>mdi-bell-plus</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>
        <standard-dialog :render='!!selected' :value='showModal' :max-width='900' persistent>
            <template v-slot:title>
                <span v-if='selected.id'>Edit</span>
                <span v-else>Create</span>
                <span class='ml-1'>{{ 'Mine Area' | term }}</span>
            </template>
            <template v-slot:content>
                <v-form ref='form' :lazy-validation='true'>
                    <v-container fluid>
                        <v-row>
                            <v-col cols='12' class='pa-2'>
                                <v-text-field
                                    autocomplete='off'
                                    label='Name'
                                    :rules='[$rules.required(), $rules.maxLength(50)]'
                                    v-model='selected.name'
                                    counter='50'
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols='4'>
                                <v-select
                                    label='Language'
                                    v-model='selected.locale'
                                    :items='locales'
                                    :menu-props='{ dark: true }'
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols='12' class='pa-2 title'>Shift Timings</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols='4' class='pa-2'>
                                <v-select
                                    label='Shifts Per Day'
                                    v-model='selected.numberOfShiftsPerDay'
                                    :rules='[$rules.required()]'
                                    :items='[1, 2, 3, 4]'
                                    @change='shiftsChanged()'
                                    :menu-props='{ dark: true }'
                                ></v-select>
                            </v-col>
                            <v-col cols='4' class='pa-2'>
                                <v-text-field
                                    autocomplete='off'
                                    label='First Shift Starts At'
                                    :rules='[$rules.required(), $rules.time()]'
                                    v-model='selected.firstShiftStartsAt'
                                ></v-text-field>
                            </v-col>
                            <v-col cols='4' class='pa-2'>
                                <v-text-field
                                    autocomplete='off'
                                    type='number'
                                    label='Shift Overlap (minutes)'
                                    :rules="[
                                            $rules.min(0),
                                            $rules.max(180),
                                            $rules.multipleOf(15, '15 minute increments only'),
                                        ]"
                                    v-model.number='selected.shiftOverlapMinutes'
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols='4' class='pa-2'>
                                <v-select
                                    label='First Day of Week'
                                    v-model='selected.firstDayOfWeek'
                                    :rules='[$rules.required()]'
                                    item-value='id'
                                    item-text='name'
                                    :items='daysOfWeek'
                                    :menu-props='{ dark: true }'
                                ></v-select>
                            </v-col>
                            <v-col cols='4' class='pa-2'>
                                <v-menu v-model='dateDialog' max-width='290px' :close-on-content-click='false'>
                                    <template v-slot:activator='{ on }'>
                                        <v-text-field
                                            v-model='formattedDate'
                                            label='Start Counting Weeks From'
                                            readonly
                                            name='date'
                                            v-on='on'
                                            required
                                            clearable
                                            :rules='[$rules.required()]'
                                            append-icon='mdi-calendar-today'
                                            @click:append='dateDialog = true'
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        dark
                                        v-model='selected.startWeekCountFrom'
                                        class='elevation-0'
                                        min='2019-01-01'
                                        no-title
                                        @change='dateDialog = false'
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols='4' class='pa-2'>
                                <v-select
                                    label='TimeZone'
                                    v-model='selected.timeZone'
                                    :rules='[$rules.required()]'
                                    item-value='value'
                                    item-text='label'
                                    :items='timezones'
                                    :menu-props='{ dark: true }'
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols='3' class='pa-2'>
                                <v-text-field
                                    autocomplete='off'
                                    label='Shift 1 Name'
                                    v-model='selected.shiftNameFirst'
                                    :rules='[$rules.required()]'
                                ></v-text-field>
                            </v-col>
                            <v-col cols='3' class='pa-2'>
                                <v-text-field
                                    autocomplete='off'
                                    :disabled='selected.numberOfShiftsPerDay < 2'
                                    label='Shift 2 Name'
                                    v-model='selected.shiftNameSecond'
                                    :rules='[$rules.requiredIf(() => selected.numberOfShiftsPerDay > 1)]'
                                ></v-text-field>
                            </v-col>
                            <v-col cols='3' class='pa-2'>
                                <v-text-field
                                    autocomplete='off'
                                    :disabled='selected.numberOfShiftsPerDay < 3'
                                    label='Shift 3 Name'
                                    v-model='selected.shiftNameThird'
                                    :rules='[$rules.requiredIf(() => selected.numberOfShiftsPerDay > 2)]'
                                ></v-text-field>
                            </v-col>
                            <v-col cols='3' class='pa-2'>
                                <v-text-field
                                    autocomplete='off'
                                    :disabled='selected.numberOfShiftsPerDay < 4'
                                    label='Shift 4 Name'
                                    v-model='selected.shiftNameFourth'
                                    :rules='[$rules.requiredIf(() => selected.numberOfShiftsPerDay > 3)]'
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if='selected.id'>
                            <v-col cols='12' class='pa-2 title'>Logo</v-col>
                            <v-col cols='4'>
                                <v-hover v-slot:default='{ hover }'>
                                    <v-img
                                        :src='imageSource'
                                        :gradient="
                                                hover || imageError
                                                    ? 'to top right, rgba(255,201,14,.8),  rgba(88,223,85,.7), rgba(79,167,255,.8)'
                                                    : null
                                            "
                                        :max-height='74'
                                        :max-width='250'
                                        :min-height='74'
                                        :min-width='200'
                                        @error='imageError = true'
                                        @load='imageError = false'
                                        contain
                                    >
                                        <v-container fill-height pa-0 v-show='hover || imageError'>
                                            <v-layout row wrap align-center class='ma-0'>
                                                <v-flex class='text-center text-uppercase white--text'>
                                                    <div>
                                                        <span v-if="$wait.is('uploading logo')">Sending...</span>
                                                        <v-btn
                                                            v-else
                                                            outlined
                                                            class='white--text'
                                                            @click='$refs.inputUpload.click()'
                                                        >Upload Logo
                                                        </v-btn
                                                        >
                                                    </div>
                                                    <input
                                                        v-show='false'
                                                        ref='inputUpload'
                                                        accept='image/png'
                                                        type='file'
                                                        @change='setImage'
                                                    />
                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-img>
                                </v-hover>
                            </v-col>
                            <v-col cols='8'>
                                <v-alert color='grey lighten-2'>
                                    Logos should ideally be 74 pixels high and no more than 250 pixels wide. To be
                                    displayed on a white background. PNG Only
                                </v-alert>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols='12' class='pa-2 title'>{{ 'Locations' | term }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols='6' class='pa-2'>
                                <v-text-field
                                    autocomplete='off'
                                    label='Location Name Convention'
                                    v-model='selected.locationConvention'
                                    :rules="[
                                            $rules.required(),
                                            (x) => (locationTestValid().badExpression ? 'Bad format' : true),
                                        ]"
                                    hint='A = letter, N = digit, . = Anything, * = Many. E.g. `A._N*` or `.* A*`'
                                    persistent-hint
                                ></v-text-field>
                            </v-col>
                            <v-col cols='6' class='pa-2'>
                                <v-btn
                                    text
                                    color='secondary'
                                    @click='showLocationHelp = true'
                                    v-if='!showLocationHelp'
                                    class='mt-3'
                                >Show help
                                </v-btn
                                >

                                <v-alert class='ml-5 pa-0' v-if='showLocationHelp'>
                                    <v-text-field
                                        class='mb-5'
                                        autocomplete='off'
                                        label='Test Location Pattern'
                                        v-model='testpattern'
                                        :error='!!testpattern && !locationTestValid().valid'
                                        placeholder='Enter a location name to test'
                                        counter='50'
                                        :append-icon="locationTestValid() ? 'mdi-check-circle' : 'mdi-cancel'"
                                    ></v-text-field>
                                    <pre class='mono-help grey--text'>
A = 1 letter         A* = many letters
N = 1 number         N* = many numbers
? = 1 letter or num  ?* = many letters or numbers
. = any 1 character  .* = many characters

Other allowed symbols: - _ / and spaces
e.g. `A._D*` matches H2_123 and HA_444</pre
                                    >
                                </v-alert>
                                <v-btn
                                    text
                                    color='secondary'
                                    @click='showLocationHelp = false'
                                    v-if='showLocationHelp'
                                >Hide help
                                </v-btn
                                >
                            </v-col>
                            <v-row>
                                <v-col cols='12' class='pa-2 title'>Configuration</v-col>
                                <v-col cols='6' class='pa-2'>
                                    <v-select
                                        label='Temperature Scale'
                                        v-model='selected.temperatureScale'
                                        :items='temperatureScales'
                                        item-value='id'
                                        item-text='name'
                                        :readonly='!!selected.id'
                                        :menu-props='{ dark: true }'
                                    ></v-select>
                                </v-col>
                                <v-col cols='6' class='pa-2' v-if='selected.id'>
                                    <v-btn

                                        text
                                        color='secondary'
                                        @click='showChangeTemperatureScale'
                                    >Change Temperature Scale
                                    </v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-row>
                    </v-container>
                </v-form>
            </template>
            <template v-slot:actions>
                <v-btn color='red' text v-if='selected && selected.id' @click='del()'>Delete</v-btn>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
                <v-btn color='primary' text @click='save'>Save</v-btn>
            </template>
            <template v-slot:offline-actions>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
                <offline-btn text></offline-btn>
            </template>
        </standard-dialog>
        <standard-dialog :value='showChangeTemperatureScaleModal' :max-width='400' persistent>
            <template v-slot:title>
                Change Temperature Scale
            </template>
            <template v-slot:content>
                <v-form ref='tempScaleForm' :lazy-validation='true'>
                    <span v-if="$wait.is('updating temp scale')">Updating...</span>
                    <v-select
                        v-else
                        label='Temperature Scale'
                        v-model='editingTemperatureScale'
                        :items='temperatureScales'
                        item-value='id'
                        item-text='name'
                        :menu-props='{ dark: true }'
                    ></v-select>
                </v-form>
            </template>
            <template v-slot:actions>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showChangeTemperatureScaleModal = false'>Cancel</v-btn>
                <v-btn color='primary' text @click='changeTemperatureScale'>Update</v-btn>
            </template>
            <template v-slot:offline-actions>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
                <offline-btn text></offline-btn>
            </template>
        </standard-dialog>
        <subscribers-dialog ref='subscribersDialog'></subscribers-dialog>
        <confirm ref='confirm'></confirm>
    </div>
</template>
<script>
import { EventBus, Events } from '@/lib/EventBus';
import AppHttp from '@/lib/AppHttp';
import MineAreas from '@/lib/data/MineAreas';
import dayjs from 'dayjs';
import { DateFormat, TimeZones } from '@/plugins/dates';
import { Locales } from '@/plugins/i18n';
import Locations from '@/lib/data/Locations';
import MineAreaStore from '@/lib/stores/MineAreaStore';
import Account from '@/lib/data/Account';
import SubscribersDialog from '@/components/Admin/SubscribersDialog.vue';
import StandardDialog from '@/components/Shared/Common/StandardDialog.vue';
import Tenants from '@/lib/Tenants';
import { TemperatureScales } from '@/models/api';
import { enumOptions } from '@/lib/EnumExtensions';
//A = letter, D = digit, . = Anything, * = Many. E.g. `A._D*` or `.* A*`

export default {
    components: { StandardDialog, SubscribersDialog },
    data() {
        return {
            data: null,
            showModal: false,
            showChangeTemperatureScaleModal: false,
            selected: null,
            editingTemperatureScale: null,
            temperatureScales: enumOptions(TemperatureScales, this.$options.filters.temperatureScaleName),
            showLocationHelp: false,
            dateDialog: false,
            testpattern: '',
            imageError: false,
            cacheKey: +new Date(),
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Shifts/day', value: 'numberOfShiftsPerDay' },
                { text: 'Shift Overlap (mins)', value: 'shiftOverlapMinutes' },
                { text: 'Shifts', value: 'shiftNames' },
                { text: 'Location Names', value: 'locationConvention' },
                { text: '', value: 'id' }
            ],
            daysOfWeek: [
                { id: 0, name: 'Sunday' },
                { id: 1, name: 'Monday' },
                { id: 2, name: 'Tuesday' },
                { id: 3, name: 'Wednesday' },
                { id: 4, name: 'Thursday' },
                { id: 5, name: 'Friday' },
                { id: 6, name: 'Saturday' }
            ],
            valid: false,
            timezones: [],
            locales: Locales
        };
    },
    async created() {
        this.timezones = TimeZones;
        await this.loadData();
    },
    destroyed() {
    },
    computed: {
        formattedDate: {
            get() {
                if (!this.selected.startWeekCountFrom) {
                    return '';
                }
                if (
                    dayjs(this.selected.startWeekCountFrom).toDate() <
                    dayjs(this.selected.startWeekCountFrom).subtract(10, 'y').toDate()
                ) {
                    return '';
                }
                return dayjs(this.selected.startWeekCountFrom).format(DateFormat.DefaultDisplay);
            },
            set() {
                this.selected.startWeekCountFrom = null;
            }
        },
        imageSource() {
            if (!this.selected || !this.selected.id) return null;
            return AppHttp.urlFromPath(
                `Images/${this.selected.id}_logo.png?viewTenantId=${Tenants.GetCurrentTenant()}&rnd=${this.cacheKey}`
            );
        }
    },
    methods: {
        shiftsChanged() {
            if (this.selected.numberOfShiftsPerDay < 4) {
                this.selected.shiftNameFourth = '';
            }
            if (this.selected.numberOfShiftsPerDay < 3) {
                this.selected.shiftNameThird = '';
            }
            if (this.selected.numberOfShiftsPerDay < 2) {
                this.selected.shiftNameSecond = '';
            }
        },
        async loadData() {
            this.data = await MineAreas.get();
            for (let x of this.data) {
                x.shiftNames = x.availableShifts.map((x) => x.name);
            }
        },
        create() {
            this.edit({
                locationConvention: 'A*D*',
                startWeekCountFrom: '2019-07-01',
                firstDayOfWeek: 1,
                locale: Locales[0].value,
                temperatureScale: TemperatureScales.Celsius
            });
        },
        edit(item) {
            this.cacheKey = +new Date();
            this.selected = { ...item, locale: item.locale ?? Locales[0].value };
            this.showModal = true;
            setTimeout(() => this.$refs.form.resetValidation(), 1);
        },
        async editSubscribers(item) {
            await this.$refs.subscribersDialog.show(
                async () => {
                    return await MineAreas.getSubscribersById(item.id);
                },
                async (subscribers) => {
                    await MineAreas.updateSubscribers({
                        _type: 'UpdateMineAreaNotificationSubscribersCommand',
                        mineAreaId: item.id,
                        subscribers: subscribers
                    });
                });
        },
        async del() {
            if (await this.$refs.confirm.openAsDeleteResource(this.$termSync('Mine Area'), {})) {
                await MineAreas.delete(this.selected.id);
                EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Mine Area')} Deleted`);
                this.showModal = false;
                await this.loadData();
            }
        },
        showChangeTemperatureScale() {
            if (this.selected == null)
                return;

            this.editingTemperatureScale = this.selected.temperatureScale;
            this.showChangeTemperatureScaleModal = true;
        },
        async changeTemperatureScale() {
            if (!this.$refs.tempScaleForm.validate() || !this.selected) {
                return;
            }

            this.$wait.start('updating temp scale');
            try{
                await MineAreas.updateTemperatureScale({
                    _type: 'UpdateMineAreaTemperatureScaleCommand',
                    mineAreaId: this.selected.id,
                    temperatureScale: this.editingTemperatureScale
                });
                this.selected.temperatureScale = this.editingTemperatureScale;
                const existingMineArea = this.data.find(x=>x.id === this.selected.id);
                if(existingMineArea != null) {
                    existingMineArea.temperatureScale = this.editingTemperatureScale;
                }
                this.showChangeTemperatureScaleModal = false;
                EventBus.$emit(Events.ToastSuccess, 'Temperature Scale Updated');
            } catch(e) {
                EventBus.$emit(Events.ToastError, 'Failed to update temperature scale');
            }
            finally {
                this.$wait.end('updating temp scale');
            }
        },
        async save() {
            if (!this.$refs.form.validate()) {
                return;
            }

            if (this.selected.id == null) {
                await MineAreas.create({
                    _type: 'CreateMineAreaCommand',
                    name: this.selected.name,
                    locale: this.selected.locale,
                    numberOfShiftsPerDay: this.selected.numberOfShiftsPerDay,
                    firstShiftStartsAt: this.selected.firstShiftStartsAt,
                    shiftOverlapMinutes: this.selected.shiftOverlapMinutes,
                    firstDayOfWeek: this.selected.firstDayOfWeek,
                    startWeekCountFrom: this.selected.startWeekCountFrom,
                    timeZone: this.selected.timeZone,
                    shiftNameFirst: this.selected.shiftNameFirst,
                    shiftNameSecond: this.selected.shiftNameSecond,
                    shiftNameThird: this.selected.shiftNameThird,
                    shiftNameFourth: this.selected.shiftNameFourth,
                    locationConvention: this.selected.locationConvention,
                    temperatureScale: this.selected.temperatureScale
                });
            } else {
                await MineAreas.update({
                    _type: 'UpdateMineAreaCommand',
                    id: this.selected.id,
                    name: this.selected.name,
                    locale: this.selected.locale,
                    startWeekCountFrom: this.selected.startWeekCountFrom,
                    shiftNameFirst: this.selected.shiftNameFirst,
                    shiftNameSecond: this.selected.shiftNameSecond,
                    shiftNameThird: this.selected.shiftNameThird,
                    shiftNameFourth: this.selected.shiftNameFourth,
                    locationConvention: this.selected.locationConvention
                });
            }

            EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Mine Area')} Saved`);
            this.showModal = false;
            await this.loadData();
            await MineAreaStore.updateStoredMineAreas();
        },
        locationTestValid() {
            return Locations.validate(this.selected.locationConvention, this.testpattern);
        },
        async setImage(e) {
            const file = e.target.files[0];

            if (!file.type.includes('image/png')) {
                EventBus.$emit(Events.ToastError, 'Please select an image or PDF file');
                return;
            }
            try {
                this.$wait.start('uploading logo');
                await AppHttp.postWithFiles(
                    '/Images/',
                    { name: this.selected.id + '_logo.png', parent: `${this.selected.id}` },
                    [file]
                );
                this.cacheKey = +new Date();
            } finally {
                this.$wait.end('uploading logo');
            }
        }
    }
};
</script>
<style lang='scss' scoped>
.mono-help {
    font-size: 0.8rem;
}
</style>
