<template>
    <screen :hideShiftOwner="true" :isOnline="isOnline">
        <template slot="extensionBarCentre">
            <v-btn icon v-if="leftDrawer" @click="toggleLeft">
                <v-icon>mdi-dock-left</v-icon>
            </v-btn>
        </template>
        <template slot="appBarCenter">
            <shift-selector />
        </template>
        <router-view :key="$route.path" />
    </screen>
</template>

<script>
import { EventBus, Events } from '@/lib/EventBus';
import CurrentShiftStore from '@/lib/stores/CurrentShiftStore';
import Screen from '@/templates/Screen.vue';
import { useSystemStore } from '@/lib/stores/SystemStore';
import { mapState } from 'pinia';

export default {
    components: {
        Screen,
    },
    data() {
        return {
            currentDepartments: [],
            leftDrawer: false,
            departments: [],
        };
    },
    computed: {
        ...mapState(useSystemStore, ['isOnline']),
    },
    async created() {
        CurrentShiftStore.setBoard('captain');
        EventBus.$on(Events.ShowLeftDrawerButton, this.showLeftDrawerButton);
        if (
            this.$route.name.indexOf('default') == 0 &&
            CurrentShiftStore.selectedDate &&
            CurrentShiftStore.selectedShiftNumber
        ) {
            this.$router.push({ name: 'captainboard', params: this.getParams() });
        }
    },
    destroyed() {
        EventBus.$off(Events.ShowLeftDrawerButton, this.showLeftDrawerButton);
    },
    methods: {
        getParams() {
            return {
                mine: CurrentShiftStore.selectedMineArea,
                date: CurrentShiftStore.selectedDate,
                shift: CurrentShiftStore.selectedShiftNumber,
                dept: CurrentShiftStore.selectedDepartment,
            };
        },
        toggleLeft() {
            EventBus.$emit(Events.LeftDrawer);
        },
        showLeftDrawerButton(show) {
            this.leftDrawer = show;
        },
    },
};
</script>
