import { ToDoActionCommentViewModel, ToDoActionViewModel } from '@/models/api';
import { ClientToDoAction, ClientToDoActionComment } from '@/models/client/client-to-do-action';
import dayjs from 'dayjs';

export function TransformToDoActionViewModelToClient(viewModel: ToDoActionViewModel): ClientToDoAction {
    return {
        id: viewModel.id,
        name: viewModel.name!,
        displayOrder: viewModel.displayOrder,
        departmentId: viewModel.departmentId ?? null,
        mineAreaId: viewModel.mineAreaId!,
        description: viewModel.description,
        createdBy: viewModel.createdBy!,
        status: viewModel.status!,
        completedAtUTC: viewModel.completedAtUTC ? dayjs(viewModel.completedAtUTC).utc() : null,
        startedAtUTC: viewModel.startedAtUTC ? dayjs(viewModel.startedAtUTC).utc() : null,
        createdAtUTC: dayjs(viewModel.createdAtUTC).utc(),
        comments: viewModel.comments.map(x=>TransformToDoActionCommentViewModelToClient(x)),
    };
}

export function TransformToDoActionCommentViewModelToClient(viewModel: ToDoActionCommentViewModel): ClientToDoActionComment {
    return {
        id: viewModel.id,
        madeBy: viewModel.madeBy!,
        madeAtUTC: dayjs(viewModel.madeAtUTC).utc(),
        comment: viewModel.comment ?? '',
    };
}