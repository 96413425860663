<template>
    <div style="width: fit-content">
        <div v-if="selectedDate" style="display: flex">
            <v-btn icon :disabled="!hasPreviousShift && !isWeek" @click="prev()" width="28">
                <v-icon large>mdi-chevron-left</v-icon>
            </v-btn>

            <div
                @click="openModal()"
                :class="`title date-title ${showClock ? '' : 'reduced'}`"
                style="justify-content: center; align-items: center"
            >
                <div class="shift-title">
                    {{ computedShiftName }}
                </div>
                <mine-time v-if="showClock" />
            </div>

            <v-btn icon :disabled="!hasNextShift && !isWeek" @click="next()" width="28">
                <v-icon large>mdi-chevron-right</v-icon>
            </v-btn>
        </div>
        <div v-else class="title text-center pt-2 clickable" @click="openModal()">Select Shift...</div>
        <standard-dialog v-model='dialog' :max-width="400">
            <template v-slot:title>
                Select Shift
            </template>
            <template v-slot:content>
                <v-form ref="form" :lazy-validation="true">
                    <v-container grid-list-md>
                        <v-flex sx12 v-if="mineAreas && mineAreas.length > 1">
                            <v-select
                                :items="mineAreas"
                                item-value="id"
                                item-text="name"
                                label="Mine Area"
                                v-model="selectingMineArea"
                                @change="onMineAreaChange"
                                :menu-props="{ dark: true }"
                                :rules="[$rules.required()]"
                            ></v-select>
                        </v-flex>
                        <v-flex sx12 v-if="selectingMineArea">
                            <v-select
                                :items="departments"
                                item-value="id"
                                item-text="name"
                                label="Department"
                                v-model="selectingDepartment"
                                :menu-props="{ dark: true }"
                                :rules="[$rules.required()]"
                                :disabled="!departments || departments.length <= 1 || isOnline === false"
                                :readonly="!departments || departments.length <= 1 || isOnline === false"
                                @change="loadPlans()"
                                clearable
                                required
                            ></v-select>
                        </v-flex>
                        <v-flex sx12 v-if="!!selectingMineArea">
                            <v-menu v-model="dateDialog" max-width="290px" :close-on-content-click="false">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="formattedDate"
                                        label="Date"
                                        readonly
                                        name="date"
                                        v-on="on"
                                        required
                                        clearable
                                        append-icon="mdi-calendar-today"
                                        @click:append="dateDialog = true"
                                        :rules="[$rules.required()]"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="selectingDate"
                                    class="elevation-0"
                                    min="2019-01-01"
                                    no-title
                                    :allowed-dates="isOnline === false ? (val) => allowedDates(val) : undefined"
                                    @change="dateDialog = false"
                                    :events="planEvent"
                                    dark
                                ></v-date-picker>
                            </v-menu>
                        </v-flex>
                        <v-flex sx12 v-if="!!selectingMineArea">
                            <v-chip-group
                                v-model="selectingShift"
                                column
                                filter
                                outlined
                                active-class="primary--text"
                                :rules="[$rules.required()]"
                            >
                                <v-chip :value="'week'" v-if="selectingDepartment != 'Interactions' && isOnline"
                                >Week of</v-chip
                                >
                                <v-chip v-for="shift in shifts" :key="shift.number" :value="shift.number">{{
                                        shift.name
                                    }}</v-chip>
                            </v-chip-group>
                            <v-text-field
                                class="custom-validation-message"
                                v-model="selectingShift"
                                readonly
                                @click:append="dateDialog = true"
                                :rules="[$rules.required()]"
                            ></v-text-field>
                        </v-flex>
                    </v-container>
                </v-form>
            </template>
            <template v-slot:actions>
                <v-spacer></v-spacer>
                <v-btn color="gray" text @click="dialog = false">Cancel</v-btn>
                <v-btn color="primary" text @click="select()">Select</v-btn>
            </template>
        </standard-dialog>
    </div>
</template>
<script>
import { EventBus, Events } from '@/lib/EventBus';
import Shift from '@/lib/data/Shift';
import MineAreaStore from '@/lib/stores/MineAreaStore';
import CurrentShiftStore from '@/lib/stores/CurrentShiftStore';
import dayjs from 'dayjs';
import { DateFormat } from '@/plugins/dates';
import MineTime from '@/components/Shared/MineTime';
import { useSystemStore } from '@/lib/stores/SystemStore';
import { useShiftWindowStore } from '@/lib/stores/ShiftWindowStore';
import { useDepartmentStore } from '@/lib/stores/DepartmentStore';
import { mapActions, mapState } from 'pinia';

export default {
    components: { MineTime },
    data() {
        return {
            dialog: false,
            selectedDate: null,
            selectedMineArea: null,
            selectedDepartment: null,
            mineAreas: null,
            shiftNumber: null,
            noMine: false,
            dateDialog: false,
            selectingMineArea: null,
            selectingDate: null,
            selectingShift: null,
            selectingDepartment: null,
            shiftName: null,
            customShiftName: null,
            isWeek: false,
            departments: null,
            plans: null,
        };
    },
    computed: {
        ...mapState(useSystemStore, ['isOnline']),
        ...mapState(useShiftWindowStore, ['hasNextShift', 'hasPreviousShift']),
        formattedDate: {
            get() {
                if (!this.selectingDate) {
                    return '';
                }
                return dayjs(this.selectingDate).format(DateFormat.DefaultDisplay);
            },
            set() {
                this.selectingDate = null;
            },
        },
        shifts() {
            return this.shiftsFor(this.selectingMineArea);
        },
        computedShiftName() {
            if (this.isWeek) {
                return this.customShiftName;
            }
            return `${this.shiftName} / ${dayjs(this.selectedDate).format(DateFormat.WeekDisplay)}`;
        },
        showClock() {
            return this.$vuetify.breakpoint.width < 750 === false;
        },
    },
    async mounted() {
        EventBus.$on(Events.ShiftSelected, this.onShiftSelected);
        EventBus.$on(Events.MineAreasUpdated, this.onMineAreas);
        EventBus.$on(Events.WeekBoard, this.onWeekBoard);
        EventBus.$on(Events.ShiftBoard, this.onShiftBoard);
        await this.load();
    },
    destroyed() {
        EventBus.$off(Events.ShiftSelected, this.onShiftSelected);
        EventBus.$off(Events.MineAreasUpdated, this.onMineAreas);
        EventBus.$off(Events.WeekBoard, this.onWeekBoard);
        EventBus.$off(Events.ShiftBoard, this.onShiftBoard);
    },
    methods: {
        ...mapActions(useShiftWindowStore, ['goToPreviousShift', 'goToNextShift']),
        ...mapActions(useDepartmentStore, ['initializeStore']),
        async load() {
            this.selectedDate = CurrentShiftStore.selectedDate;
            this.shiftNumber = CurrentShiftStore.selectedShiftNumber;
            this.selectedMineArea = CurrentShiftStore.selectedMineArea;
            this.selectedDepartment = CurrentShiftStore.selectedDepartment;

            await MineAreaStore.getMineAreas();
            await this.onMineAreas();
        },
        async loadPlans() {
            if (!this.selectingDepartment || this.selectingDepartment == 'Interactions') {
                this.plans = null;
                return;
            }
            try {
                this.$wait.start('get plans');
                var response = await Shift.plans(this.selectingDepartment);
                this.plans = response || [];
            } catch (e) {
                return;
            } finally {
                this.$wait.end('get plans');
            }
        },

        planEvent(date) {
            if (!this.plans || this.plans.length < 2) {
                return false;
            }

            var events = [];
            var d = date.substr(0, 10);
            for (let x of this.plans[0]) {
                if (x.substr(0, 10) == d) {
                    events.push('#d00');
                    break;
                }
            }
            for (let x of this.plans[1]) {
                if (x.substr(0, 10) == d) {
                    events.push('#0d0');
                    break;
                }
            }
            return events;
        },
        shiftsFor(mineAreaId) {
            if (!mineAreaId || !MineAreaStore.mineAreas || !MineAreaStore.mineAreas.length) {
                return [];
            }
            return MineAreaStore.mineAreas.filter((x) => x.id == mineAreaId)[0].availableShifts;
        },
        setShiftName() {
            if (!this.selectedMineArea || !this.shiftNumber) {
                this.shiftName = '?';
                return;
            }
            var filtered = this.mineAreas ? this.mineAreas.filter((x) => x.id == this.selectedMineArea) : null;
            var mineArea = filtered && filtered.length ? filtered[0] : null;
            if (!mineArea) {
                this.shiftName = '?';
                return;
            }
            this.shiftName = mineArea.availableShifts.filter((x) => x.number == this.shiftNumber)[0].name;
        },
        async select() {
            if (!this.$refs.form.validate()) {
                return;
            }
            var isWeek = this.selectingShift == 'week';
            this.selectedDate = this.selectingDate;
            this.shiftNumber = isWeek ? this.shifts[0].number : this.selectingShift;
            this.selectedMineArea = this.selectingMineArea;

            if (this.selectingDepartment == 'Interactions') {
                this.setShiftName();
                this.selectedDepartment = this.selectingDepartment;
                CurrentShiftStore.setDepartment(this.selectedDepartment);
                CurrentShiftStore.setBoard('captain');
                CurrentShiftStore.set(this.selectedMineArea, this.selectedDate, this.shiftNumber);
            } else {
                this.selectedDepartment = this.selectingDepartment;

                this.setShiftName();
                CurrentShiftStore.setBoard(isWeek ? 'week' : 'shift');
                await this.initializeStore(this.selectedDepartment);
                CurrentShiftStore.setDepartment(this.selectedDepartment);
                CurrentShiftStore.set(this.selectedMineArea, this.selectedDate, this.shiftNumber);
            }

            this.dialog = false;
        },
        openModal() {
            this.selectingDate = this.selectedDate;
            this.selectingMineArea = this.selectedMineArea;
            this.selectingDepartment = this.selectedDepartment;
            this.onMineAreaChange();
            this.selectingShift = this.isWeek ? 'week' : this.shiftNumber;

            this.loadPlans();
            this.dialog = true;
            setTimeout(() => this.$refs.form.resetValidation(), 1);
        },
        onShiftSelected(shift) {
            this.selectedDate = dayjs(shift.selectedDate).format(DateFormat.Canonical);
            this.shiftNumber = shift.shiftNumber;
            this.selectedMineArea = shift.mineArea;
            this.selectedDepartment = shift.department || this.selectedDepartment;
            this.setShiftName();
        },
        next() {
            if (this.isWeek) {
                this.selectedDate = dayjs(this.selectedDate).add(7, 'd').format(DateFormat.Canonical);
                this.shiftNumber = 1;
                CurrentShiftStore.set(this.selectedMineArea, this.selectedDate, this.shiftNumber);
                return;
            }
            var shifts = this.shiftsFor(this.selectedMineArea);
            if (this.shiftNumber == shifts[shifts.length - 1].number) {
                this.shiftNumber = shifts[0].number;
                this.selectedDate = dayjs(this.selectedDate).add(1, 'd').format(DateFormat.Canonical);
            } else {
                this.shiftNumber = this.shiftNumber + 1;
            }
            this.goToNextShift();
            CurrentShiftStore.set(this.selectedMineArea, this.selectedDate, this.shiftNumber);
        },
        prev() {
            if (this.isWeek) {
                this.selectedDate = dayjs(this.selectedDate).add(-7, 'd').format(DateFormat.Canonical);
                this.shiftNumber = 1;
                CurrentShiftStore.set(this.selectedMineArea, this.selectedDate, this.shiftNumber);
                return;
            }
            var shifts = this.shiftsFor(this.selectedMineArea);
            if (this.shiftNumber == shifts[0].number) {
                this.shiftNumber = shifts[shifts.length - 1].number;
                this.selectedDate = dayjs(this.selectedDate).add(-1, 'd').format(DateFormat.Canonical);
            } else {
                this.shiftNumber = this.shiftNumber - 1;
            }
            this.goToPreviousShift();
            CurrentShiftStore.set(this.selectedMineArea, this.selectedDate, this.shiftNumber);
        },
        async onMineAreas() {
            var data = await MineAreaStore.mineAreas;
            this.noMine = !data || !data.filter(ma=>ma.departments.length > 0).length;
            this.mineAreas = data?.filter(ma=>ma.departments.length > 0);
            if (this.mineAreas && this.mineAreas.length == 1) {
                this.selectedMineArea = this.mineAreas[0].id;
            }
            this.setShiftName();
        },
        onWeekBoard(week) {
            if (week) {
                this.customShiftName = `Week ${week.weekNumber} w/c ${dayjs(week.firstDay).format(
                    DateFormat.WeekDisplay
                )} `;
                this.isWeek = true;
            } else {
                this.customShiftName = null;
                this.isWeek = false;
            }
        },
        onShiftBoard(shift) {
            if (shift) {
                this.customShiftName = null;
                this.isWeek = false;
            }
        },
        onMineAreaChange() {
            var selectedMine = this.mineAreas.filter((x) => x.id == this.selectingMineArea)[0];

            if (!selectedMine) {
                this.departments = [];
                return;
            }
            this.departments = selectedMine.departments.map((x) => x);
            this.departments.unshift({ id: 'Interactions', name: 'Mine Interactions Board' });
            if (
                this.departments &&
                this.departments.length > 1 &&
                this.departments.findIndex((x) => x.id === this.selectingDepartment) === -1
            ) {
                this.selectingDepartment = this.departments[1].id;
            }
            if (CurrentShiftStore.selectedBoardType == 'captain') this.selectingDepartment = 'Interactions';
            this.loadPlans();
        },
        allowedDates(value) {
            const shifts = useShiftWindowStore().windowShifts;
            return shifts.map((x) => x.date).indexOf(value) !== -1;
        },
    },
};
</script>
<style lang="scss">
.date-title {
    width: 430px;
    text-align: center;
    display: flex;
    line-height: 36px;
    vertical-align: sub;
    cursor: pointer;
}
.date-title.reduced {
    width: 320px;
}
.shift-title {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    padding-top: 6px;
}
</style>
