import ClientRowModel from '@/models/client/client-row';
import { RingRuleEvaluationState, RingStatesLocationForWindowViewModel, TaskTypeViewModel } from '@/models/api';
import {
    ClientRowBlastPacketInformation,
    ClientRowBlastPacketRingInformation
} from '@/lib/stores/Production/ShiftWindowActualsStore';
import dayjs from 'dayjs';
import { ClientTaskWarning } from '@/models/client/client-task-warning';
import {
    BlastPacketRingTargetEvaluation, LocationBlastPacketStateMachine,
    LocationRingStateMachine
} from '@/lib/services/Production/LocationRingStateMachine';
import _ from 'lodash';
import { ClientBlastPacketActualsEntry, ClientBlastPacketTargetCompletion } from '@/models/client/client-actuals';
import { RowType } from '@/models/client/types/row-type';
import { AffectedAreaSpecification } from '@/lib/stores/HeadingsStore';
import { ApplyRingTaskValidators } from '@/lib/services/Production/RingStateValidation';
import { TimeBlock, TimePeriod } from '@/models/client/time-block';
import { ProductionValidationTaskModel } from '@/models/client/production-validation-task-model';
import { ApplyBlastPacketTaskValidators } from '@/lib/services/Production/BlastPacketStateValidation';
import { IsFireTaskType } from '@/lib/services/TaskType';

interface LocalBlastPacketRingTargetEvaluation extends  BlastPacketRingTargetEvaluation{
    locationRingId: string | null;
    blastPacketId: string;
    targetId: string;
    targetOrder: number;
}

interface EvaluationRoundInstructions {
    tasksEncountered: ProductionValidationTaskModel[];
    actualsEncountered: ClientBlastPacketActualsEntry[];
    completionsEncountered: ClientBlastPacketTargetCompletion[];
    enterProjectionMode: boolean;
}

export function EvaluateRingStatesForHeadings(currentMineTime: dayjs.Dayjs,
                                              clientCalculationTime: dayjs.Dayjs, // putting this here to ignore anything prior to that
                                              headings: { rowType: RowType, stopeInfo: { stopeId: string | null } | null, location: { id: string, name: string | null } | null, tasks: ProductionValidationTaskModel[] }[],
                                              allHeadingsCurrentStateInformation: RingStatesLocationForWindowViewModel[],
                                              allHeadingsCurrentActuals: ClientBlastPacketActualsEntry[],
                                              allHeadingsCurrentCompletions: ClientBlastPacketTargetCompletion[],
                                              blastPackets: ClientRowBlastPacketInformation[],
                                              taskTypes: TaskTypeViewModel[], ringErrorAccumulator: (t: ProductionValidationTaskModel, affectedArea: AffectedAreaSpecification, warning: ClientTaskWarning)=>void): RingStatesLocationForWindowViewModel[] | null {
    if(headings.some(h=>h.rowType !== RowType.RATE))
        throw new Error('Cannot evaluate ring states for non-rate rows.');

    if(headings.some(h=>h.location?.id == null))
        throw new Error('Cannot evaluate ring states for rows not associated to locations.');

    if(headings.some(h=>h.stopeInfo?.stopeId == null))
        throw new Error('Cannot evaluate ring states for rows not associated with a stope.');

    const headingLocationIds = headings.map(h=>h.location!.id);
    const headingStopeIds = headings.map(h=>h.stopeInfo!.stopeId!);

    const blastPacketTargetsInformation: LocalBlastPacketRingTargetEvaluation[] = _.flatMap(blastPackets.filter(bpr=>headingStopeIds.includes(bpr.locationId)), bp=> ([
        {
            targetQuantity: bp.fireTarget.targetQuantity,
            locationRingId: null,
            blastPacketId: bp.blastPacketId,
            targetId: bp.fireTarget.targetId,
            tolerance: bp.fireTarget.tolerance,
            taskType: taskTypes.find(tt=>tt.id === bp.fireTarget.taskTypeId)!,
            targetOrder: bp.fireTarget.order,
        },
        {
            targetQuantity: bp.bogTarget.targetQuantity,
            locationRingId: null,
            blastPacketId: bp.blastPacketId,
            targetId: bp.bogTarget.targetId,
            tolerance: bp.bogTarget.tolerance,
            taskType: taskTypes.find(tt=>tt.id === bp.bogTarget.taskTypeId)!,
            targetOrder: bp.bogTarget.order,
        },
        ..._.flatMap(bp.rings, r=>r.targets.map(t=>({
            targetQuantity: t.targetQuantity,
            blastPacketId: bp.blastPacketId,
            locationRingId: r.ringId,
            targetId: t.targetId,
            tolerance: t.tolerance,
            taskType: taskTypes.find(tt=>tt.id === t.taskTypeId)!,
            targetOrder: t.order,
        })))
    ]));

    // short circuit if there aren't any associated blast packets
    if(blastPacketTargetsInformation.length === 0)
        return null;

    const targetsMap = new Map<string, LocalBlastPacketRingTargetEvaluation>(blastPacketTargetsInformation.map(t=>[t.targetId, t]));

    const currentStateInformation = allHeadingsCurrentStateInformation.filter(i=> headingStopeIds.includes(i.stopeId!));

    const currentActuals = allHeadingsCurrentActuals.filter(actual=> headingLocationIds.includes(actual.locationId));
    const currentCompletions = allHeadingsCurrentCompletions.filter(comp=> headingLocationIds.includes(comp.locationId));

    const sortedAndRelevantHeadingTasks = _.flatMap(headings, h=>h.tasks.filter(t=>t.blastPacketRingTargetId)).sort(orderTasksForStateCalculation);
    const sortedAndRelevantActuals = currentActuals.filter(actual=>actual.endTime.isSameOrBefore(currentMineTime)).sort(_.curry(orderActualsForStateCalculation)(targetsMap))
    const sortedAndRelevantCompletions = currentCompletions.filter(comp=>comp.completedAt.isSameOrBefore(currentMineTime)).sort(_.curry(orderCompletionsForStateCalculation)(targetsMap));

    const statesMap: Map<string, RingRuleEvaluationState[]> = new Map<string, RingRuleEvaluationState[]>();
    const stateMachinesMap: Map<string, LocationBlastPacketStateMachine> = new Map<string, LocationBlastPacketStateMachine>();
    const targetToBlastPacketMap: Map<string,{ blastPacketId: string, locationRingId: string | null}> = new Map<string, { blastPacketId: string, locationRingId: string | null}>(blastPacketTargetsInformation.map(t=>[t.targetId, { blastPacketId: t.blastPacketId, locationRingId: t.locationRingId }]));

    const ringStatesGroupedByBlastPacket = _.groupBy(_.flatMap(currentStateInformation, s=>s.rings), r=>r.blastPacketId);

    for(const blastPacketId of _.keys(ringStatesGroupedByBlastPacket)) {
        const rings = ringStatesGroupedByBlastPacket[blastPacketId];

        const ringInitialStates: RingRuleEvaluationState[] = [];
        const ringStateMachines: Map<string, LocationRingStateMachine> = new Map<string, LocationRingStateMachine>();
        const ringSpecifications: AffectedAreaSpecification[] = [];

        const blastPacketAffectedAreaSpecification: AffectedAreaSpecification = {
            locationName: headings[0].location!.name ?? 'Unknown',
            locationId: headingLocationIds[0],
            blastPacketId: blastPacketId,
            blastPacketName: blastPackets.find(bp=>bp.blastPacketId === blastPacketId)?.blastPacketName ?? 'Unknown'
        };

        for(const ring of rings) {
            const initialState = _.last(_.takeWhile(ring.states, s=>!s.clientCanCalculate));

            if(initialState == null)
                continue;

            const affectedAreaSpecification: AffectedAreaSpecification = {
                ... blastPacketAffectedAreaSpecification,
                locationRingId: ring.locationRingId,
                locationRingName: blastPackets.find(bp=>bp.blastPacketId === ring.blastPacketId)?.rings?.find(bpr=>bpr.ringId === ring.locationRingId)?.ringName ?? 'Unknown'
            };

            ringSpecifications.push(affectedAreaSpecification);

            const taskEncounterCallback: (target: BlastPacketRingTargetEvaluation, task: ProductionValidationTaskModel, currentState: RingRuleEvaluationState)=> void = (target,task,currentState)=>{
                ApplyRingTaskValidators(target, task, currentState, (ctm,w)=>ringErrorAccumulator(ctm,affectedAreaSpecification,w));
            }

            statesMap.set(ring.locationRingId!, []);
            ringInitialStates.push(initialState);
            ringStateMachines.set(ring.locationRingId!, new LocationRingStateMachine(targetsMap, initialState, taskEncounterCallback));
        }

        const blastPacketTaskEncounterCallback: (target: BlastPacketRingTargetEvaluation, task: ProductionValidationTaskModel, ringStates: { locationRingId: string, currentState: RingRuleEvaluationState }[], currentBlastPacketState: { isFired: boolean, boggedAmount: number, boggingMarkedCompleted: boolean }) => void =
            (target, task, ringStates, currentBlastPacketState)=>
        {
            ApplyBlastPacketTaskValidators(target, task, ringSpecifications, ringStates, currentBlastPacketState, (ctm, w)=>ringErrorAccumulator(ctm,blastPacketAffectedAreaSpecification,w));
        };

        const blastPacketBogTarget = blastPackets.find(bp=>bp.blastPacketId===blastPacketId)?.bogTarget;

        stateMachinesMap.set(blastPacketId!, new LocationBlastPacketStateMachine(targetsMap, ringStateMachines, ringInitialStates, blastPacketTaskEncounterCallback));
    }


    let enteredProjectionMode: boolean = false;

    while(sortedAndRelevantHeadingTasks.length > 0 || sortedAndRelevantActuals.length > 0 || sortedAndRelevantCompletions.length > 0) {
        const evaluationRoundInstructions = getEvaluationRoundInstructions(sortedAndRelevantHeadingTasks, sortedAndRelevantActuals, sortedAndRelevantCompletions, currentMineTime, clientCalculationTime);

        if(evaluationRoundInstructions.enterProjectionMode) {
            enteredProjectionMode = true;
            enterProjectionMode(stateMachinesMap, currentMineTime);
        }

        // We must do actuals then completions and then tasks to make sure everything is applied correctly. Actuals will always be applied in ascending order.
        // Because these states are used to calculate violations and projection validity it's okay to discard completions that are for earlier actuals (which could happen
        // if we enter actuals and completions all at the end of a shift), because we're only interested in the latest actual.
        // We have to do tasks last because otherwise we could end up completely ignoring a task that starts at the same
        // time as some actuals
        applyEncounteredActuals(evaluationRoundInstructions.actualsEncountered, stateMachinesMap, targetToBlastPacketMap);
        applyEncounteredCompletions(evaluationRoundInstructions.completionsEncountered, stateMachinesMap, targetToBlastPacketMap);
        applyEncounteredTasks(evaluationRoundInstructions.tasksEncountered, statesMap, stateMachinesMap, targetToBlastPacketMap);
    }

    if(!enteredProjectionMode){
        enterProjectionMode(stateMachinesMap, currentMineTime);
    }


    finaliseStates(statesMap, stateMachinesMap);

    const newStateInformation = currentStateInformation.map(cs=>({
        ...cs,
        rings: cs.rings.map(r=>({
            ...r,
            states: statesMap.get(r.locationRingId!) ?? [...r.states]
        }))
    }))

    return newStateInformation;
}

function applyEncounteredTasks(tasksEncountered: ProductionValidationTaskModel[], statesMap: Map<string, RingRuleEvaluationState[]>, stateMachinesMap: Map<string, LocationBlastPacketStateMachine>, targetToRingsMap: Map<string, { blastPacketId: string, locationRingId: string | null}>) {
    for(const encounteredTask of tasksEncountered) {
        const blastPacketIds = targetToRingsMap.get(encounteredTask.blastPacketRingTargetId!);

        if(blastPacketIds != null) {
            const stateUpdates = stateMachinesMap.get(blastPacketIds.blastPacketId)?.EncounteredTask(blastPacketIds, encounteredTask);

            if(stateUpdates!=null) {
                for(const locationRingUpdates of stateUpdates){
                    if(locationRingUpdates.states !=null && locationRingUpdates.states.length > 0)
                        statesMap.set(locationRingUpdates.locationRingId, [... statesMap.get(locationRingUpdates.locationRingId)!, ... locationRingUpdates.states]);
                }
            }
        }
    }
}

function applyEncounteredActuals(actualsEncountered: ClientBlastPacketActualsEntry[], stateMachinesMap: Map<string, LocationBlastPacketStateMachine>, targetToRingsMap: Map<string, { blastPacketId: string, locationRingId: string | null}>) {
    for(const encounteredActual of actualsEncountered) {
        const blastPacketIds = targetToRingsMap.get(encounteredActual.targetId);

        if(blastPacketIds != null) {
            stateMachinesMap.get(blastPacketIds.blastPacketId)?.EncounteredActual(blastPacketIds, encounteredActual);
        }
    }
}

function applyEncounteredCompletions(completionsEncountered: ClientBlastPacketTargetCompletion[], stateMachinesMap: Map<string, LocationBlastPacketStateMachine>, targetToRingsMap: Map<string, { blastPacketId: string, locationRingId: string | null}>) {
    for(const encounteredCompletion of completionsEncountered) {
        const blastPacketIds = targetToRingsMap.get(encounteredCompletion.targetId);

        if(blastPacketIds != null) {
            stateMachinesMap.get(blastPacketIds.blastPacketId)?.EncounteredManualCompletion(blastPacketIds, encounteredCompletion);
        }
    }
}

function finaliseStates(statesMap: Map<string, RingRuleEvaluationState[]>, stateMachinesMap: Map<string, LocationBlastPacketStateMachine>) {
    for(const blastPacketId of stateMachinesMap.keys()) {
        const stateUpdates = stateMachinesMap.get(blastPacketId)?.FinalStates();

        if(stateUpdates != null) {
            for(const locationRingUpdates of stateUpdates){
                if(locationRingUpdates.states !=null && locationRingUpdates.states.length > 0)
                    statesMap.set(locationRingUpdates.locationRingId, [... statesMap.get(locationRingUpdates.locationRingId)!, ... locationRingUpdates.states]);
            }
        }
    }
}

function enterProjectionMode(stateMachinesMap: Map<string, LocationBlastPacketStateMachine>, currentMineTime: dayjs.Dayjs) {
    for(const stateMachine of stateMachinesMap.values()){
        stateMachine.EnterProjectionMode(currentMineTime);
    }
}

function getEvaluationRoundInstructions(tasks: ProductionValidationTaskModel[], actuals: ClientBlastPacketActualsEntry[], completions: ClientBlastPacketTargetCompletion[], currentMineTime: dayjs.Dayjs, clientCalculationTime: dayjs.Dayjs): EvaluationRoundInstructions {
    const taskTime = tasks.length > 0 ? getPopTimeForTask(tasks[tasks.length-1]): dayjs(new Date(9999,11,31));
    const actualsTime = actuals.length > 0 ? actuals[actuals.length-1].endTime : dayjs(new Date(9999,11,31));
    const completionTime = completions.length > 0 ? completions[completions.length-1].completedAt : dayjs(new Date(9999,11,31));

    const minTime = taskTime.isSameOrBefore(actualsTime) ?
        taskTime.isSameOrBefore(completionTime) ? taskTime
            : completionTime
        : actualsTime;

    const shouldEnterProjectMode = minTime.isSameOrAfter(currentMineTime);

    const tasksEncountered = minTime.isSame(taskTime) ? popTasksStartingAtGivenTime(tasks, minTime) : [];
    const actualsEncountered = minTime.isSame(actualsTime) ? popActualsEndingAtGivenTime(actuals, minTime) : [];
    const completionsEncountered = minTime.isSame(completionTime) ? popCompletionsMarkedAtGivenTime(completions, minTime) : [];

    if(minTime.isBefore(clientCalculationTime)){
        return {
            actualsEncountered: [],
            tasksEncountered:[],
            completionsEncountered: [],
            enterProjectionMode: false,
        }
    }


    return {
        actualsEncountered,
        tasksEncountered,
        completionsEncountered,
        enterProjectionMode: shouldEnterProjectMode
    };
}

function getPopTimeForTask(task: ProductionValidationTaskModel) {
    return IsFireTaskType(task.taskType) ? task.endTime : task.startTime;
}

function popTasksStartingAtGivenTime(tasks: ProductionValidationTaskModel[], time: dayjs.Dayjs) {
    return popEntriesMatchingGivenTime(tasks, t=>getPopTimeForTask(t), time);
}

function popActualsEndingAtGivenTime(actuals: ClientBlastPacketActualsEntry[], time: dayjs.Dayjs) {
    return popEntriesMatchingGivenTime(actuals, a=>a.endTime, time);
}

function popCompletionsMarkedAtGivenTime(completions: ClientBlastPacketTargetCompletion[], time: dayjs.Dayjs) {
    return popEntriesMatchingGivenTime(completions, c=>c.completedAt, time);
}

function popEntriesMatchingGivenTime<T>(entries: T[], timeRetrievalFunc: (entry: T)=>dayjs.Dayjs, time: dayjs.Dayjs) {
    const poppedEntries: T[] = [];
    while(entries.length > 0 && timeRetrievalFunc(entries[entries.length-1]).isSame(time)) {
        poppedEntries.push(entries.pop()!);
    }

    return poppedEntries;
}

// order tasks in reverse order, first by startTime, then use endTime to break ties, except use endTime for Fire tasks
function orderTasksForStateCalculation(a: ProductionValidationTaskModel, b: ProductionValidationTaskModel){
    const aInitialSortTime = IsFireTaskType(a.taskType) ? a.endTime : a.startTime;
    const bInitialSortTime = IsFireTaskType(b.taskType) ? b.endTime : b.startTime;

    if(aInitialSortTime.isSame(bInitialSortTime))
        return b.endTime.diff(a.endTime);
    else
        return bInitialSortTime.diff(aInitialSortTime);
}

// order actuals in reverse order
function orderActualsForStateCalculation(targetsMap: Map<string, LocalBlastPacketRingTargetEvaluation>, a: ClientBlastPacketActualsEntry, b: ClientBlastPacketActualsEntry): number {
    if(a.endTime.isSame(b.endTime))
        return targetsMap.get(b.targetId)!.targetOrder - targetsMap.get(a.targetId)!.targetOrder;
    else
        return b.endTime.diff(a.endTime);
}

function orderCompletionsForStateCalculation(targetsMap: Map<string, LocalBlastPacketRingTargetEvaluation>, a: ClientBlastPacketTargetCompletion, b: ClientBlastPacketTargetCompletion): number {
    if(a.completedAt.isSameOrAfter(b.completedAt))
        return targetsMap.get(b.targetId)!.targetOrder - targetsMap.get(a.targetId)!.targetOrder;
    else
        return b.completedAt.diff(a.completedAt);
}