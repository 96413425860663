export class DownloadHelper {
    public download(name: string, blob: Blob) {
        // @ts-ignore
        if (navigator.msSaveBlob) {
            // @ts-ignore
            return navigator.msSaveBlob(blob, name);
        }

        const a = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }, 0);
    }
    public parseBlob(b64Data: any, sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return byteArrays;
    }
    public makeCsv(headers: any[] | null, rows: any[][]) {
        if (headers != null) {
            rows.unshift(headers);
        }
        const safeval = (c: any) => {
            if (c == null || c == undefined) {
                return '';
            }
            if (c && c.replace) {
                return c.replace('"', '""');
            }
            return `${c}`;
        };
        const csv = rows.map((r) => r.map(safeval).join(',')).join('\n');
        return csv;
    }

    public makeBlobFromFileString(fileString: string) {
        return new Blob([fileString]);
    }
}

export default new DownloadHelper();
