import { render, staticRenderFns } from "./ResourceChart.vue?vue&type=template&id=72a9929e&scoped=true&"
import script from "./ResourceChart.vue?vue&type=script&lang=ts&"
export * from "./ResourceChart.vue?vue&type=script&lang=ts&"
import style0 from "./ResourceChart.vue?vue&type=style&index=0&id=72a9929e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72a9929e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VChip,VIcon,VTooltip})
