import { GenericDataApi } from '@/lib/data/GenericDataApi';
import { AvailableShifts } from '@/models/api/Data/available-shifts';
import { AxiosError } from 'axios';
import { StringIterator } from 'lodash';
import AppHttp from '../AppHttp';
import Errors from '../Errors';
import { Location } from '@/models/api/Data/location';
import {
    CreateLocationCommand,
    CreateMineAreaCommand, ExternalNotificationSubscriberViewModel,
    MineArea, PropertyDefinitionModel, TemperatureScales, UpdateDepartmentNotificationSubscribersCommand,
    UpdateLocationCommand,
    UpdateMineAreaCommand, UpdateMineAreaNotificationSubscribersCommand, UpdateMineAreaTemperatureScaleCommand
} from '@/models/api';

export class MineAreas {
    private resource: string = 'MineAreas';

    public async get(include?: string[]): Promise<MineArea[]> {
        let query = '';
        if (include) {
            const p: string = include.map((x) => `include=${x}`).join('&');
            query = '?' + p;
        }
        const response = await AppHttp.get(this.resource + query);
        const data = response ? response.data : null;
        return data;
    }

    public async getById(id: string): Promise<MineArea> {
        const response = await AppHttp.get(`${this.resource}/${id}`);
        const data = response ? response.data : null;
        return data;
    }

    public async getSubscribersById(id: string): Promise<ExternalNotificationSubscriberViewModel[]> {
        const response = await AppHttp.get(`${this.resource}/${id}/Subscribers`);
        const data = response ? response.data : null;
        return data;
    }

    public async updateSubscribers(command: UpdateMineAreaNotificationSubscribersCommand) {
        const response = await AppHttp.post(`${this.resource}/${command.mineAreaId}/Subscribers`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async update(command: UpdateMineAreaCommand) {
        const response = await AppHttp.post(`${this.resource}/Update`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async updateTemperatureScale(command: UpdateMineAreaTemperatureScaleCommand) {
        const response = await AppHttp.post(`${this.resource}/Update/TemperatureScale`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async create(command: CreateMineAreaCommand) {
        const response = await AppHttp.post(`${this.resource}/Create`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async delete(id: string) {
        const response = await AppHttp.delete(`${this.resource}/${id}`);
        const data = response ? response.data : null;
        return data;
    }

    public async getMineArea(mineAreaId: string): Promise<{
        id: string;
        name: string;
        firstShiftStartsAt: string;
        numberOfShiftsPerDay: number;
        firstDayOfWeek: number;
        availableShifts: AvailableShifts[];
        locale: string;
        isSandpit: boolean;
        temperatureScale: TemperatureScales;
    }> {
        const endpoint = `${this.resource}/${mineAreaId}`;

        const response = await AppHttp.get(endpoint);

        const data = response ? response.data : null;
        return data;
    }

    public async GetPropertyDefinitions(mineAreaId: string) : Promise<PropertyDefinitionModel[]>
    {
        const response = await AppHttp.get(`${this.resource}/${mineAreaId}/propertydefinitions`);

        const data = response ? response.data : [] as PropertyDefinitionModel[];
        return data.extendedProperties;
    }
}

export default new MineAreas();
