<template>
    <standard-dialog v-model='dialog' :max-width="600" persistent>
        <template v-slot:title>
            Select {{ locationName }}
            <v-spacer></v-spacer>
            <v-btn @click="addNew = true" small>Add New</v-btn>
        </template>
        <template v-slot:content>
            <v-form ref="form" :lazy-validation="true">
                <v-container fluid v-if="locations && !addNew">
                    <v-row>
                        <v-col cols="12">
                            <v-select
                                :items="filteredLocations"
                                item-value="id"
                                item-text="name"
                                :rules="[$rules.requiredIf(() => !addNew)]"
                                :label="locationName"
                                v-model="selectedLocations"
                                :menu-props="{ dark: true, contentClass:'with-prepend', maxHeight:'600' }"
                                multiple
                                ref="locations"
                            >
                                <template v-slot:item="{ item, attrs }">
                                    <v-icon class="mr-7">
                                        {{ attrs.inputValue ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
                                    </v-icon>
                                    <v-chip small class="mr-2">{{ item.displayOrder }}</v-chip>
                                    {{ item.name }}
                                </template>
                                <template v-slot:prepend-item>
                                    <div class="prepend theme--dark v-sheet">
                                        <v-list-item ripple>
                                            <v-list-item-content class="pa-0">
                                                <v-list-item-title class="d-flex">
                                                    <v-text-field outlined dense hide-details class="mt-1" label="Search" v-model="locationFilter" clearable />
                                                    <v-spacer></v-spacer>
                                                    <v-btn text small @click="$refs['locations'].blur()"> <v-icon left>mdi-close</v-icon>Close </v-btn>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="my-2"></v-divider>
                                        <v-list-item ripple @click="toggle">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <v-icon class="mr-7">{{ icon }}</v-icon
                                                    >Select All
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </div>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container fluid v-if="addNew">
                    <v-row>
                        <v-col cols="8" class="pa-2">
                            <v-text-field
                                autocomplete="off"
                                label="Name"
                                :rules="[$rules.requiredIf(() => addNew), $rules.maxLength(50)]"
                                v-model="newLocation.name"
                                counter="50"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4" class="pa-2">
                            <v-text-field
                                autocomplete="off"
                                type="number"
                                label="Priority"
                                :rules="[$rules.requiredIf(() => addNew), $rules.min(1)]"
                                v-model="newLocation.displayOrder"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="pa-2" v-if="isLocationNameInvalid">
                            <v-alert color="amber">{{ isLocationNameInvalid }}</v-alert>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="pa-2">
                            <v-select
                                autocomplete="off"
                                :label="$termSync('Level')"
                                :rules="[$rules.requiredIf(() => addNew)]"
                                v-model="newLocation.reducedLevelId"
                                :items="filteredReducedLevels"
                                item-value="id"
                                item-text="name"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="pa-2">
                            <v-select
                                autocomplete="off"
                                label="Cycle Type"
                                v-model="newLocation.cycleType"
                                :items="filteredCycleTypes"
                                item-value="id"
                                item-text="name"
                                :rules="[ $rules.requiredAllowZeroIf(() => addNew) ]"
                                @change="newLocation.cycleId = null"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row v-if="newLocation.reducedLevelId == 'MANUAL'">
                        <v-col :cols="8">
                            <v-text-field
                                autocomplete="off"
                                :label="'New ' + $termSync('Level') + ' Name'"
                                :rules="[
                                        $rules.requiredIf(() => newLocation.reducedLevelId == 'MANUAL'),
                                        $rules.maxLength(50)
                                    ]"
                                v-model="newLevel.name"
                                counter="50"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4" class="pa-2">
                            <v-text-field
                                autocomplete="off"
                                type="number"
                                label="Priority"
                                :rules="[
                                        $rules.requiredIf(() => newLocation.reducedLevelId == 'MANUAL'),
                                        $rules.min(1)
                                    ]"
                                v-model="newLevel.displayOrder"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="pa-2">
                            <v-checkbox
                                autocomplete="off"
                                label="Ignores start-of-shift tasks?"
                                v-model="newLocation.ignoreStartOfShiftSpecial"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="pa-2">
                            <v-checkbox
                                autocomplete="off"
                                label="Independent end-of-shift (firing)?"
                                v-model="newLocation.ignoreEndOfShiftSpecial"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row v-if="newLocation.cycleType == CycleType.Duration">
                        <v-col cols="12">
                            <v-select
                                autocomplete="off"
                                label="Default Cycle"
                                v-model="newLocation.cycleId"
                                :items="filteredCycles"
                                item-value="id"
                                item-text="name"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </template>
        <template v-slot:actions>
            <v-spacer></v-spacer>
            <v-btn color="gray" text @click="dialog = false">Cancel</v-btn>
            <v-access-control>
                <template #offline>
                    <offline-btn text></offline-btn>
                </template>
                <v-btn color="primary" text @click="select()" v-if="!addNew" :loading="$wait.is('saving')"
                >Select</v-btn
                >
                <v-btn color="primary" text @click="save()" v-if="addNew" :loading="$wait.is('saving')">Save</v-btn>
            </v-access-control>
        </template>
    </standard-dialog>
</template>
<script>
import Locations from '@/lib/data/Locations';
import MineAreaStore from '@/lib/stores/MineAreaStore';
import ReducedLevels from '@/lib/data/ReducedLevels';
import Cycles from '@/lib/data/Cycles';
import { CycleType } from '@/models/api/Enums/cycle-type';
import { waitFor } from 'vue-wait';
import { enumOptions } from '@/lib/EnumExtensions';

export default {
    props: ['locationName', 'mineAreaId', 'departmentId'],
    data() {
        return {
            selectedLocations: [],
            locations: null,
            selectedDepartment: null,
            dialog: false,
            existing: [],
            addNew: false,
            newLocation: {},
            newLevel: {},
            reducedLevels: [],
            cycles: [],
            cycleTypes: enumOptions(CycleType, this.$options.filters.cycleType),
            locationFilter: '',
            CycleType
        };
    },
    async created() {
        for (let mine of MineAreaStore.mineAreas) {
            for (let dept of mine.departments) {
                if (dept.id == this.departmentId) {
                    this.selectedDepartment = dept;
                    break;
                }
            }
            if (this.selectedDepartment) break;
        }
    },
    methods: {
        async show(existing) {
            this.existing = existing;
            this.dialog = true;
            this.addNew = false;
            this.selectedLocations = [];
            await this.loadData();
            setTimeout(() => this.$refs.form.resetValidation(), 1);
        },
        select: waitFor('saving', async function() {
            if (!this.$refs.form.validate()) {
                return;
            }
            if (this.selectedLocations && this.selectedLocations.length) {
                this.$emit('selected', this.selectedLocations);
            }
            this.dialog = false;
        }),
        save: waitFor('saving', async function() {
            if (!this.$refs.form.validate()) {
                return;
            }

            if (this.newLocation.reducedLevelId == 'MANUAL') {
                var levelResponse = await ReducedLevels.save(this.newLevel);
                if (levelResponse) {
                    this.newLocation.reducedLevelId = levelResponse.id;
                }
            }

            var locationResponse = await Locations.create({
                ... this.newLocation,
                _type: "CreateLocationCommand"
            });
            if (locationResponse) {
                this.$emit('selected', [locationResponse.id]);
            }
            this.dialog = false;
        }),
        async loadData() {
            this.locations = await Locations.getByDepartment(this.departmentId);
            this.reducedLevels = await ReducedLevels.get();
            this.cycles = await Cycles.get();

            this.newLocation = {
                displayOrder: 1,
                departmentId: this.departmentId,
                reducedLevelId: null,
                ignoreStartOfShiftSpecial: false,
                ignoreEndOfShiftSpecial: false,
                cycleType: null,
                cycleId: null
            };

            this.newLevel = {
                displayOrder: 1,
                mineAreaId: this.mineAreaId
            };
        },
        toggle() {
            this.$nextTick(() => {
                if (this.allSelected) {
                    this.selectedLocations = [];
                } else {
                    this.selectedLocations = this.filteredLocations.map(x => x.id).slice();
                }
            });
        }
    },
    computed: {
        filteredLocations() {
            return this.locations
                .filter(x =>!this.existing.filter(e => e == x.id).length)
                .filter(x => x.parentLocationId == null)
                .filter(x => !this.locationFilter || x.name.includes(this.locationFilter))
                .sort((a, b) => {
                    if (a.displayOrder == b.displayOrder) {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    }
                    return a.displayOrder - b.displayOrder;
                });
        },
        filteredReducedLevels() {
            if (!this.reducedLevels || !this.mineAreaId) {
                return [];
            }
            var levels = this.reducedLevels
                .filter(x => x.mineAreaId == this.mineAreaId)
                .sort((a, b) => a.displayOrder - b.displayOrder);
            levels.unshift({ id: 'MANUAL', name: 'Add New...' });
            return levels;
        },
        filteredCycles() {
            if (!this.cycles || !this.departmentId) {
                return [];
            }
            return this.cycles.filter(x => x.departmentId == this.departmentId);
        },
        filteredCycleTypes() {
            if (!this.selectedDepartment) return [];
            return this.cycleTypes;
        },
        isLocationNameInvalid() {
            if (!this.addNew || !this.newLocation || !this.newLocation.name) {
                return false;
            }
            var mine = MineAreaStore.mineAreas.filter(x => x.id == this.mineAreaId)[0];
            var convention = mine.locationConvention;
            if (Locations.validate(convention, this.newLocation.name)) return false;
            return `Location should be in the format '${convention}'`;
        },
        allSelected() {
            return this.selectedLocations.length === this.filteredLocations.length;
        },
        someSelected() {
            return this.selectedLocations.length > 0 && !this.allSelected;
        },
        icon() {
            if (this.allSelected) return 'mdi-checkbox-marked';
            if (this.someSelected) return 'mdi-minus-box';
            return 'mdi-checkbox-blank-outline';
        }
    }
};
</script>
<style lang="scss">
    .with-prepend {
        .prepend {
            position:sticky;
            top:0px;
            padding-top:8px;
            z-index: 1;
        }
        .v-select-list {
            padding-top: 0px;
        }
    }
</style>