import { defineStore } from 'pinia';
import {
    BlastPacketViewModel,
    BlastPacketStopeViewModel,
    CreateBlastPacketCommand, DeleteBlastPacketCommand,
    UpdateBlastPacketCommand, MoveRingsToNewBlastPacketCommand, MoveRingsToExistingBlastPacketCommand
} from '@/models/api';
import BlastPackets from '@/lib/data/BlastPackets';

export interface BlastPacket {
    locationId: string;
    blastPacketId: string | null;
    blastPacketName: string;
    blastPacketRingId: string | null;
    blastPacketRingTargetId: string | null;
    plannedAndCompletedPercentage: number | null;
    ringTargetRatePerHour: number | null;
    ringTargetQuantity: number;
    ringName: string | null;
    blastPacketRingSequence: number;
    tolerance: number;
}

function ConvertStopeToTargets(stope: BlastPacketStopeViewModel): BlastPacket[] {
    return stope.blastPackets.flatMap((bp) =>
        ([
            {
                locationId: stope.id,
                blastPacketId: bp.id,
                blastPacketName: bp.name ?? 'Unknown',
                blastPacketRingId: null,
                blastPacketRingTargetId: bp.bogTarget!.id,
                plannedAndCompletedPercentage: (bp.bogTarget!.plannedPercentage ?? 0) + (bp.bogTarget!.actualsPercentage ?? 0),
                ringTargetQuantity: bp.bogTarget!.target,
                ringTargetRatePerHour: bp.bogTarget!.ratePerHour ?? null,
                ringName: null,
                blastPacketRingSequence: bp.rings.reduce((prev,curr)=>Math.max(prev,curr.locationRingDisplayOrder),0),
                tolerance: bp.bogTarget!.tolerance,
            },
            {
                locationId: stope.id,
                blastPacketId: bp.id,
                blastPacketName: bp.name ?? 'Unknown',
                blastPacketRingId: null,
                blastPacketRingTargetId: bp.fireTarget!.id,
                plannedAndCompletedPercentage: (bp.fireTarget!.plannedPercentage ?? 0) + (bp.fireTarget!.actualsPercentage ?? 0),
                ringTargetQuantity: bp.fireTarget!.target,
                ringTargetRatePerHour: bp.fireTarget!.ratePerHour ?? null,
                ringName: null,
                blastPacketRingSequence: bp.rings.reduce((prev,curr)=>Math.max(prev,curr.locationRingDisplayOrder),0),
                tolerance: bp.fireTarget!.tolerance,
            },
            ...bp.rings.flatMap((r) =>
                r.targets.map(
                    (t) =>
                        ({
                            locationId: stope.id,
                            blastPacketId: bp.id,
                            blastPacketName: bp.name ?? 'Unknown',
                            blastPacketRingId: r.id,
                            blastPacketRingTargetId: t.id,
                            plannedAndCompletedPercentage: (t.plannedPercentage ?? 0) + (t.actualsPercentage ?? 0),
                            ringTargetQuantity: t.target,
                            ringTargetRatePerHour: t.ratePerHour ?? null,
                            ringName: r.locationRingName ?? '',
                            blastPacketRingSequence: r.locationRingDisplayOrder,
                            tolerance: t.tolerance,
                        } as BlastPacket)
                )
            )
        ])
    )
}

export const useBlastPacketsStore = defineStore('blastPackets', {
    state: () => {
        return {
            _departmentId: '',
            blastPackets: [] as BlastPacketStopeViewModel[],
            blastPacketTargets: [] as BlastPacket[],
        }
    },
    getters: {
        getBlastPackets(): (locationId: string) => BlastPacketViewModel[] {
            return (locationId: string) => {
                const model = this.blastPackets.find((x) => x.id === locationId);

                return model?.blastPackets ?? ([] as BlastPacketViewModel[]);
            };
        },
        getBlastPacketTargetForTargetId(): (blastPacketRingTargetId: string) => BlastPacket | null {
            return (blastPacketRingTargetId: string) => {
                const blastPacket =
                    this.blastPacketTargets?.find((x) => x.blastPacketRingTargetId === blastPacketRingTargetId) ||
                    null;
                return blastPacket;
            };
        },
    },
    actions: {
        async populateStore(departmentId: string, weekPlanId?: string) {
            this._departmentId = departmentId;
            const bp = weekPlanId ? await BlastPackets.getByDepartmentWeek(departmentId, weekPlanId) : null;
            if (bp) {
                this.blastPackets = bp.stopes;

                this.blastPacketTargets = this.blastPackets.flatMap(ConvertStopeToTargets);
            }
        },
        async populateStope(stopeDepartmentId: string, stopeLocationId: string) {
            const bp = await BlastPackets.getByStope(stopeDepartmentId, stopeLocationId);
            if (bp) {
                for (const stope of bp.stopes) {
                    const index = this.blastPackets.findIndex((x) => x.id === stope.id);
                    if (index !== -1) {
                        this.blastPackets.splice(index, 1, stope);
                    } else {
                        this.blastPackets = [...this.blastPackets, stope];
                    }

                    this.blastPacketTargets = this.blastPackets.flatMap(ConvertStopeToTargets);
                }
            }
        },
        clearOtherStopes(stopeLocationId: string) {
            this.blastPackets = this.blastPackets.filter((x) => x.id === stopeLocationId);
            this.blastPacketTargets = this.blastPacketTargets.filter((x) => x.locationId === stopeLocationId);
        },
        clearAllStopes(){
            this.blastPackets = [];
            this.blastPacketTargets = [];
        },
        getAssignedBlastPacketRings(locationId: string) {
            const bp = this.blastPackets.find((x) => x.id === locationId);
            if (bp) {
                const locationRingIds = bp.blastPackets.flatMap((x) => x.rings.map((x) => x.locationRingId));
                return [...locationRingIds];
            }
            return [];
        },
        async refreshBlastPacketsForStope(stopeId: string) {
            const reloadedBlastPackets = await BlastPackets.getByStope(this._departmentId, stopeId);

            this.blastPackets = [...this.blastPackets.filter((x) => x.id !== stopeId), ...reloadedBlastPackets.stopes];
            this.blastPacketTargets = this.blastPackets.flatMap(ConvertStopeToTargets);
        },
        async executeCreateNewBlastPacket(command: CreateBlastPacketCommand) {
            await BlastPackets.createBlastPacket(command);

            if(command.stopeId)
                await this.refreshBlastPacketsForStope(command.stopeId);
        },
        async executeUpdateBlastPacket(command: UpdateBlastPacketCommand) {
            await BlastPackets.updateBlastPacket(command);

            if(command.stopeId)
                await this.refreshBlastPacketsForStope(command.stopeId);
        },
        async executeDeleteBlastPacket(stopeId: string, blastPacketId: string) {
            const deleteCommand: DeleteBlastPacketCommand = {
                _type: 'DeleteBlastPacketCommand',
                id: blastPacketId,
            };

            await BlastPackets.deleteBlastPacket(deleteCommand);
            const bp = this.blastPackets.find((x) => x.id == stopeId);
            if (bp) {
                const index = bp.blastPackets.findIndex((x) => x.id == blastPacketId);
                if (index !== -1) {
                    bp.blastPackets.splice(index, 1);
                }
            }
        },
        async executeMoveRingsToNewBlastPacket(command: MoveRingsToNewBlastPacketCommand) {
            await BlastPackets.moveRingsToNewBlastPacket(command);

            const existingBlastPacket = this.blastPackets.find(x=> x.blastPackets.some(bp=> bp.id === command.previousBlastPacketId));

            if(existingBlastPacket?.id)
                await this.refreshBlastPacketsForStope(existingBlastPacket?.id);
        },
        async executeMoveRingsToExistingBlastPacket(command: MoveRingsToExistingBlastPacketCommand) {
            await BlastPackets.moveRingsToExistingBlastPacket(command);

            const existingBlastPacket = this.blastPackets.find(x=> x.blastPackets.some(bp=> bp.id === command.previousBlastPacketId));

            if(existingBlastPacket?.id)
                await this.refreshBlastPacketsForStope(existingBlastPacket?.id);
        }
    }
})