import { ICommandMapper } from '../ICommandMapper';
import { StartStopMeetingCommand } from '@/models/api';

export class StartStopMeetingCommandMapper implements ICommandMapper<StartStopMeetingCommand> {
    commandType = 'StartStopMeetingCommand';
    mappers = [
        {
            viewmodelType: 'Meeting',
            map(command: StartStopMeetingCommand, model: any): any | null {
                if (command.meetingId != model.id) {
                    return model;
                }

                if (command.isStop) {
                    model.endedMeetingAt = command.$meta.ended;
                } else {
                    model.startedMeetingAt = command.$meta.started;
                }

                return model;
            }
        }
    ];
}

export default new StartStopMeetingCommandMapper();
