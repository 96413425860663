import Account from '@/lib/data/Account';
import { EventBus, Events } from '@/lib/EventBus';
import Users from '@/lib/data/Users';
import User from '@/models/client/user';

export class UserStore {
    public currentUser: any | null = null;

    public async getCurrentUser(): Promise<User | null> {
        if (this.currentUser == null) {
            const isCurrentlyLoggedIn = await Users.ping();
            if (isCurrentlyLoggedIn) {
                this.currentUser = await Account.me();
                EventBus.$emit(Events.CurrentUserUpdated, this.currentUser);
            }
        }
        return this.currentUser;
    }
}

export default new UserStore();
