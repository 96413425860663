import { isDrillTask } from '@/lib/services/Task';
import { useBlastPacketsStore, BlastPacket } from '@/lib/stores/Production/BlastPacketsStore';
import WeekTask from '@/models/client/week-task';
import { AffectedAreaSpecification } from '@/lib/stores/HeadingsStore';
import { ClientTaskWarning } from '@/models/client/client-task-warning';

export const BLAST_PACKET_ORDER_ISSUE_TYPE = 'BLAST_PACKET_ORDER';

function nextTask(tasks: WeekTask[], startIndex: number, predicate: (task: WeekTask | null) => boolean) : WeekTask | null {
    for (let index = startIndex; index < tasks.length; index++){
        if (predicate(tasks[index]))
            return tasks[index];
    }

    return null;
}

export function validateBlastPacketRingSequencing(row: { tasks: WeekTask[] }, warningAccumulator: (task: WeekTask, affectedArea: AffectedAreaSpecification, warning: ClientTaskWarning)=>void){
    for (let i = 0; i < row.tasks.length; i++){
        const currentTask = row.tasks[i];
        const currentTaskBlastPacket = useBlastPacketsStore().getBlastPacketTargetForTargetId(currentTask.blastPacketRingTargetId ?? '');
        if (currentTaskBlastPacket !== null && !isDrillTask(currentTask)){
            const withMatchingBlastPacketTaskTypes = (t: WeekTask | null) => t?.taskType?.id === currentTask.taskType?.id && t?.blastPacketRingTargetId != null;
            const next = nextTask(row.tasks, i+1, withMatchingBlastPacketTaskTypes);
            if (next !== null){
                const nextTaskBlastPacket = useBlastPacketsStore().getBlastPacketTargetForTargetId(next.blastPacketRingTargetId ?? '');
                const wrongOrder = currentTaskBlastPacket.blastPacketRingSequence > (nextTaskBlastPacket?.blastPacketRingSequence ?? 0);
                if (wrongOrder){
                    warningAccumulator(currentTask, {
                        locationId: currentTask.locationId ?? '',
                        locationName: currentTask.locationName ?? 'unknown',
                        locationRingId: null,
                        locationRingName: null
                    }, { type: BLAST_PACKET_ORDER_ISSUE_TYPE, warningCategoryId: `${BLAST_PACKET_ORDER_ISSUE_TYPE}`, message: `${currentTask.blastPacketName} out of sequence` });
                }
            }
        }
    }
}
