<template>
    <standard-dialog v-model='dialog' :max-width="600">
        <template v-slot:title>
            {{ $t('heading.add-crew-member') }}
            <span class="subtitle-1">Temporarily add staff from another crew into this shift</span>
        </template>
        <template v-slot:content>
            <v-form ref="form" :lazy-validation="true">
                <v-container fluid v-if="weekCrews">
                    <v-row>
                        <v-col cols="12" class="pa-0">
                            <v-select
                                :items="weekCrews"
                                item-value="id"
                                item-text="crew.name"
                                :label="$t('shared.label.crew')"
                                v-model="selectedCrew"
                                :menu-props="{ dark: true }"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" v-if="selectedCrew" class="pa-0">
                            <v-text-field
                                class="pt-0"
                                :label="$t('shared.label.filter')"
                                clearable
                                v-model="filter"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" v-if="selectedCrew" class="pa-0">
                            <v-radio-group v-model="selectedStaff">
                                <v-radio v-for="member in crewStaff" :key="member.id" :value="member.id">
                                    <template slot="label">
                                        <strong>{{ member.name }}</strong>
                                        <span v-if="member.staffRole" class="ml-2"
                                        >({{ member.staffRole.name }})</span
                                        >
                                        <v-chip
                                            small
                                            v-if="isOvertime(member)"
                                            color="amber"
                                            text-color="white"
                                            class="ml-1"
                                        >
                                            {{ $t('shared.label.overtime') }}
                                        </v-chip>
                                        <v-chip
                                            small
                                            v-if="isLeave(member)"
                                            color="green"
                                            text-color="white"
                                            class="ml-1"
                                        >
                                            {{ $t('shared.label.leave') }}
                                        </v-chip>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>

                    <v-row v-if="selectedStaff == 'NEW'">
                        <v-access-control :offlineAccess="true">
                            <v-text-field
                                :label="$t('shared.label.name')"
                                clearable
                                v-model="newCrewName"
                                :rules="[$rules.requiredIf(() => selectedStaff == 'NEW'), $rules.maxLength(50)]"
                            />
                        </v-access-control>
                    </v-row>
                </v-container>
            </v-form>
        </template>
        <template v-slot:actions>
            <v-spacer></v-spacer>
            <v-btn color="gray" text @click="dialog = false" :loading="$wait.is('saving')">{{
                    $t('shared.button.cancel')
                }}</v-btn>
            <v-access-control :offlineAccess="true">
                <v-btn color="primary" text @click="select()" :disabled="!selectedStaff">{{
                        $t('shared.button.add')
                    }}</v-btn>
            </v-access-control>
        </template>
    </standard-dialog>
</template>
<i18n>
{
    "en": {
        "heading": {
            "add-crew-member": "Add Crew Member"
        }
    },
    "es": {
        "heading": {
            "add-crew-member": "Añadir miembro del personal"
        }
    }
}
</i18n>

<script>
import Staff from '@/lib/data/Staff';
import { v4 as uuidv4 } from 'uuid';

export default {
    data() {
        return {
            selectedStaff: null,
            selectedCrew: null,
            departmentId: null,
            departmentShiftId: null,
            shiftCrewIds: [],
            role: null,
            roles: [],
            dialog: false,
            weekCrews: [],
            staff: [],
            filter: '',
            newCrewName: '',
            date: null,
        };
    },
    computed: {
        crewStaff() {
            if (this.selectedCrew == 'UNASSIGNED') {
                var assignedStaffKeys = {};

                for (let c of this.weekCrews) {
                    if (!c.staff) continue;
                    for (let s of c.staff) {
                        assignedStaffKeys[s.staffId] = true;
                    }
                }
                var unassignedMembers = this.staff.filter((x) => {
                    if (assignedStaffKeys[x.id]) return false;
                    if (this.filter) {
                        var filter = this.filter.toLowerCase();
                        if (x.name.toLowerCase().indexOf(filter) > -1) return true;
                        if (x.staffRole.name.toLowerCase().indexOf(filter) > -1) return true;
                        return false;
                    }
                    return true;
                });
                return unassignedMembers;
            }

            var staffKeys = {};
            for (let s of this.weekCrews.filter((x) => x.id == this.selectedCrew)[0].staff) {
                staffKeys[s.staffId] = true;
            }
            var members = this.staff.filter((x) => {
                if (!staffKeys[x.id]) return false;
                if (this.filter) {
                    var filter = this.filter.toLowerCase();
                    if (x.name.toLowerCase().indexOf(filter) > -1) return true;
                    if (x.staffRole.name.toLowerCase().indexOf(filter) > -1) return true;
                    return false;
                }
                return true;
            });

            members.push({ name: this.$t('shared.button.add-new'), id: 'NEW' });
            return members;
        },
    },
    methods: {
        show(departmentId, departmentShiftId, selectedCrewIds, role, staff, weekCrews, roles, date) {
            this.selectedStaff = null;
            this.departmentId = departmentId;
            this.departmentShiftId = departmentShiftId;
            this.selectedCrewIds = selectedCrewIds;
            this.role = role;
            this.roles = roles;
            this.staff = staff;
            this.weekCrews = [...weekCrews];
            this.weekCrews.push({ id: 'UNASSIGNED', crew: { id: null, name: 'Unassigned' } });
            this.date = date;
            this.dialog = true;
            setTimeout(() => this.$refs.form.resetValidation(), 1);
        },
        async select() {
            if (!this.$refs.form.validate()) {
                return;
            }
            try {
                this.$wait.start('saving');
                var crew = this.weekCrews.filter((x) => x.id == this.selectedCrew)[0];
                if (this.selectedStaff == 'NEW') {
                    var newId = uuidv4();
                    var staffMember = {
                        _type: Staff.createModelType,
                        id: newId,
                        name: this.newCrewName,
                        crewId: crew.crewId,
                        crew: crew,
                        staffRole: this.role,
                        staffRoleId: this.role.id,
                        staffAvailability: [],
                    };

                    this.$emit('addNewStaff', staffMember);
                } else {
                    this.$emit('selectStaff', this.selectedStaff, this.role.id);
                }
                this.dialog = false;
            } finally {
                this.$wait.end('saving');
            }
            this.dialog = false;
        },
        isOvertime(member) {
            if (!member.staffAvailability) return false;
            for (let availability of member.staffAvailability) {
                if (availability.availabilityType == 1 && availability.date.substring(0, 10) == this.date) {
                    return true;
                }
            }
            return false;
        },
        isLeave(member) {
            if (!member.staffAvailability) return false;
            for (let availability of member.staffAvailability) {
                if (availability.availabilityType == 0 && availability.date.substring(0, 10) == this.date) {
                    return true;
                }
            }
            return false;
        },
    },
};
</script>
