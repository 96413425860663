import {
    CreateRingConfigurationCommand,
    CreateTaskTypeCommand,
    RingConfiguration,
    UpdateTaskTypeCommand,
    CommsFieldViewModel,
    UpdateCommsFieldCommand,
    CreateCommsFieldCommand,
    ReorderShiftCommsFieldsCommand,
    ReorderWeekCommsFieldsCommand
} from '@/models/api';
import AppHttp from '@/lib/AppHttp';
import ResourceHttp from '@/lib/ResourceHttp';

export interface IconOption {
    name: string;
    tags: string[]
}

export class CommunicationConfiguration {
    private resource: string = 'CommunicationConfiguration';

    public async getIconOptions(): Promise<IconOption[]> {
        const response = await ResourceHttp.get(`/mdi-icon-list.json`);
        const data = response ? response.data : [];
        return data;
    }

    public async getFields(departmentId: string): Promise<CommsFieldViewModel[]> {
        const response = await AppHttp.get(`${this.resource}/Department/${departmentId}/Fields`);
        const data = response ? response.data?.fields : [];
        return data;
    }

    public async updateField(departmentId: string, command: UpdateCommsFieldCommand) {
        const response = await AppHttp.post(`${this.resource}/Department/${departmentId}/Fields/Update`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async createField(departmentId: string, command: CreateCommsFieldCommand) {
        const response = await AppHttp.post(`${this.resource}/Department/${departmentId}/Fields/Create`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async archiveField(departmentId: string, id: string) {
        const response = await AppHttp.put(`${this.resource}/Department/${departmentId}/Fields/${id}/Archive`, null);
        const data = response ? response.data : null;
        return data;
    }

    public async restoreFieldFromArchive(departmentId: string, id: string) {
        const response = await AppHttp.put(`${this.resource}/Department/${departmentId}/Fields/${id}/Restore`, null);
        const data = response ? response.data : null;
        return data;
    }

    public async exportFields(departmentId: string) {
        const response = await AppHttp.get(`${this.resource}/Department/${departmentId}/Fields/export`);
        const data = response ? response.data : null;
        return data;
    }

    public async importFields(departmentId: string, file: any) {
        const response = await AppHttp.postWithFiles(`${this.resource}/Department/${departmentId}/Fields/import`, {}, [file]);
        const data = response ? response.data : null;
        return data;
    }

    public async reorderShiftFields(departmentId: string, command: ReorderShiftCommsFieldsCommand) {
        const response = await AppHttp.post(`${this.resource}/Department/${departmentId}/Fields/Reorder/Shift`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async reorderWeekFields(departmentId: string, command: ReorderWeekCommsFieldsCommand) {
        const response = await AppHttp.post(`${this.resource}/Department/${departmentId}/Fields/Reorder/Week`, command);
        const data = response ? response.data : null;
        return data;
    }
}

export default new CommunicationConfiguration();
