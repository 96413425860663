<template>
    <standard-dialog v-model='dialog' :max-width="900" persistent>
        <template v-slot:title>
            Import Staff Availability Plan
        </template>
        <template v-slot:content>
            <v-stepper v-model="step" class="elevation-0">
                <v-stepper-header class="elevation-0 mb-5">
                    <v-stepper-step :complete="step > 1" step="1">Upload File</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="step > 2" step="2">Review</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3">Save</v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1" class="pa-0">
                        <v-card elevation="0">
                            <v-card-text class="mb-5">
                                <v-row>
                                    <v-col>
                                        <v-alert type="info" class="white--text">
                                            <div>
                                                <ul>
                                                    <li>
                                                        Existing data found between provided date range will be
                                                        replaced or removed
                                                    </li>
                                                    <li>
                                                        Imported data that is not between provided date range below
                                                        will be skipped
                                                    </li>
                                                </ul>
                                            </div>
                                        </v-alert>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-menu
                                            v-model="dateFromDialog"
                                            max-width="290px"
                                            :close-on-content-click="false"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    ref="dateFrom"
                                                    v-model="formattedDateFrom"
                                                    label="From"
                                                    readonly
                                                    name="datefrom"
                                                    v-on="on"
                                                    required
                                                    clearable
                                                    append-icon="mdi-calendar-today"
                                                    @click:append="dateFromDialog = true"
                                                    :rules="[$rules.required(), $rules.before(formattedDateTo)]"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="selectedDateFrom"
                                                class="elevation-0"
                                                no-title
                                                @change="dateFromDialog = false"
                                                dark
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col>
                                        <v-menu
                                            v-model="dateToDialog"
                                            max-width="290px"
                                            :close-on-content-click="false"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    ref="dateTo"
                                                    v-model="formattedDateTo"
                                                    label="To"
                                                    readonly
                                                    name="dateto"
                                                    v-on="on"
                                                    required
                                                    clearable
                                                    append-icon="mdi-calendar-today"
                                                    @click:append="dateToDialog = true"
                                                    :rules="[$rules.required()]"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="selectedDateTo"
                                                class="elevation-0"
                                                :min="selectedDateFrom"
                                                no-title
                                                @change="dateToDialog = false"
                                                dark
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-checkbox
                                            v-model="selelctedAvailabilityTypes"
                                            label="Import Leave Records"
                                            value="Leave"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="selelctedAvailabilityTypes"
                                            label="Import Overtime Records"
                                            value="Overtime"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <input
                                            ref="inputUpload"
                                            type="file"
                                            accept="text/csv"
                                            @change="getFile($event)"
                                        />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <a
                                    download="StaffAvailabilityPlan.csv"
                                    href="data:application/octet-stream,Name%2CFrom%2CTo%2CType"
                                >Download Template</a
                                >
                                <v-spacer></v-spacer>
                                <v-btn text @click="cancel">Cancel</v-btn>
                                <v-btn
                                    color="primary"
                                    @click="upload"
                                    :disabled="!canUpload"
                                    :loading="$wait.is('uploading')"
                                    text
                                >Upload</v-btn
                                >
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="2" class="pa-0">
                        <v-card elevation="0">
                            <v-card-text class="mb-5">
                                <v-simple-table dense v-if="importResult && importResult.length">
                                    <template v-slot:default>
                                        <thead>
                                        <tr>
                                            <th class="text-left">Name</th>
                                            <th class="text-left">From</th>
                                            <th class="text-left">To</th>
                                            <th class="text-left">Type</th>
                                            <th class="text-left"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <template v-for="(person, personIndex) in importResult">
                                            <template
                                                v-for="(availability, availabilityIndex) in person.availability"
                                            >
                                                <tr
                                                    :key="'availability' + personIndex + availabilityIndex"
                                                    :class="{ 'grey--text': availability.operation === 2 }"
                                                >
                                                    <td>
                                                        <strong v-if="availabilityIndex === 0">
                                                            {{ person.name }}
                                                        </strong>
                                                    </td>
                                                    <td>{{ availability.from | formatDate }}</td>
                                                    <td>{{ availability.to | formatDate }}</td>
                                                    <td>
                                                        <v-chip
                                                            label
                                                            small
                                                            color="orange"
                                                            v-if="availability.type === 1"
                                                            class="ma-1"
                                                        >
                                                            <v-icon left>mdi-clock-outline</v-icon>
                                                            {{ availability.type | availabilityType }}
                                                        </v-chip>
                                                        <v-chip label small color="green" v-else class="ma-1">
                                                            <v-icon left>mdi-sofa-single</v-icon>
                                                            {{ availability.type | availabilityType }}
                                                        </v-chip>
                                                    </td>
                                                    <td>
                                                        <v-icon
                                                            medium
                                                            color="green darken-2"
                                                            v-if="availability.operation === 1"
                                                        >mdi-calendar-check</v-icon
                                                        >
                                                        <v-icon medium color="red darken-2" v-else
                                                        >mdi-calendar-remove</v-icon
                                                        >
                                                        {{
                                                            availability.operation | availabilityImportOperation
                                                        }}
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                                <v-alert color="warning" class="white--text" v-else-if="caughtError === null">
                                        <span>
                                            No matched records available for import. Please make sure your CSV contains
                                            data. And some or all dates provided in the CSV falls between
                                            {{ selectedDateFrom | formatDate('YYYY-MM-DD') }} and
                                            {{ selectedDateTo | formatDate('YYYY-MM-DD') }}
                                        </span>
                                </v-alert>
                                <v-alert color="error" class="white--text" v-else-if="caughtError !== null">
                                    <span>There was an error importing your staff availability plan</span>
                                </v-alert>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn text @click="back"> <v-icon left>mdi-chevron-left</v-icon>Back </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn text @click="cancel">Cancel</v-btn>
                                <v-btn
                                    color="primary"
                                    @click="save"
                                    :disabled="importResult == null || importResult.length === 0"
                                    :loading="$wait.is('saving')"
                                    text
                                >Save</v-btn
                                >
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="3" class="pa-0">
                        <v-card elevation="0">
                            <v-card-text class="mb-5">
                                <v-alert color="success" class="white--text" v-if="saveSuccess">
                                    <p>Staff availability plan saved successfully</p>
                                </v-alert>
                                <v-alert color="error" class="white--text" v-else>
                                    <p>There was an error saving</p>
                                </v-alert>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="complete">Done</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </template>
    </standard-dialog>
</template>
<script>
import WeekCrews from '@/lib/data/WeekCrews';
import { DateFormat } from '@/plugins/dates';
import dayjs from 'dayjs';

export default {
    props: ['departmentId', 'weekDate'],
    data() {
        return {
            dialog: false,
            dateFromDialog: false,
            dateToDialog: false,
            step: 1,
            importResult: null,
            saveSuccess: false,
            selectedDateFrom: null,
            selectedDateTo: null,
            file: null,
            selelctedAvailabilityTypes: ['Leave'],
            caughtError: null
        };
    },
    mounted() {
        setTimeout(() => {
            if (this.$refs.inputUpload) {
                this.$refs.inputUpload.value = null;
            }
        }, 50);
    },
    methods: {
        show() {
            this.selectedDateFrom = dayjs(this.weekDate).format();
            this.selectedDateTo = dayjs(this.weekDate)
                .add(6, 'day')
                .format();
            this.dialog = true;
            this.step = 1;
        },
        cancel() {
            this.dialog = false;
            this.clear();
        },
        complete() {
            this.dialog = false;
            this.clear();
            this.$emit('complete');
        },
        clear() {
            this.file = null;
            this.importResult = null;
            this.saveSuccess = false;
            this.selectedDateFrom = null;
            this.selectedDateTo = null;
            this.$refs.inputUpload.value = null;
            this.caughtError = null;
            this.selelctedAvailabilityTypes = ['Leave'];
            this.step = 1;
        },
        back() {
            this.file = null;
            this.$refs.inputUpload.value = null;
            if (this.step > 1) this.step = this.step - 1;
        },
        async upload() {
            try {
                this.$wait.start('uploading');
                this.caughtError = null;
                this.importResult = null;
                this.importResult = await WeekCrews.uploadStaffAvailabilityPlan(
                    this.departmentId,
                    {
                        dateRange: {
                            from: dayjs(this.selectedDateFrom).format(DateFormat.Canonical),
                            to: dayjs(this.selectedDateTo).format(DateFormat.Canonical)
                        },
                        availabilityTypes: this.selelctedAvailabilityTypes
                    },
                    this.file
                );
            } catch (err) {
                this.importResult = null;
                this.caughtError = err;
            } finally {
                this.step = 2;
                this.$wait.end('uploading');
            }
        },
        async getFile(e) {
            this.file = e.target.files[0];
        },
        async save() {
            try {
                this.caughtError = null;
                this.$wait.start('saving');
                await WeekCrews.saveStaffAvailabilityPlan(this.departmentId, this.importResult);
                this.saveSuccess = true;
            } catch (err) {
                this.saveSuccess = false;
                this.caughtError = err;
            } finally {
                this.step = 3;
                this.$wait.end('saving');
            }
        }
    },
    watch: {
        selectedDateTo: function(val) {
            if (this.$refs.dateFrom && val) {
                setTimeout(() => {
                    this.$refs.dateFrom.validate();
                }, 100);
            }
        }
    },
    computed: {
        formattedDateFrom: {
            get() {
                if (!this.selectedDateFrom) {
                    return '';
                }
                return dayjs(this.selectedDateFrom).format(DateFormat.DefaultDisplay);
            },
            set() {
                this.selectedDateFrom = null;
            }
        },
        formattedDateTo: {
            get() {
                if (!this.selectedDateTo) {
                    return '';
                }

                return dayjs(this.selectedDateTo).format(DateFormat.DefaultDisplay);
            },
            set() {
                this.selectedDateTo = null;
            }
        },
        canUpload: {
            get() {
                return (
                    this.file &&
                    this.$refs.dateFrom &&
                    this.$refs.dateTo &&
                    this.$refs.dateFrom.valid &&
                    this.$refs.dateTo.valid &&
                    this.selelctedAvailabilityTypes.length > 0
                );
            }
        }
    }
};
</script>
