import { FormatWithPrecision } from '@/lib/services/Utils';

export function applyMetresAdvancedToCycleName(cycleName: string, metresAdvanced : number | null | undefined): string {
    if (!metresAdvanced || metresAdvanced === 0 || isNaN(metresAdvanced))
        return stripMetresAdvancedFromCycleName(cycleName);

    return `${FormatWithPrecision(metresAdvanced, 1, false)}m ${stripMetresAdvancedFromCycleName(cycleName)}`;
}

function stripMetresAdvancedFromCycleName(cycleName: string): string{
    return cycleName.replace(/^\d+\.\d+m\s/g, '');
}