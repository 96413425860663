var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-5"},[_c('content-loading',{attrs:{"value":_vm.$wait.is('saving') || _vm.$wait.is('get data'),"loaderName":"User Admin"}}),(_vm.data)?_c('v-card',[_c('v-card-title',[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm._f("term")('Users')))]),_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.create()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Add ")],1),_c('v-spacer'),_c('v-text-field',{staticClass:"admin-table-filter",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.data,"search":_vm.search},scopedSlots:_vm._u([{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.editRoles(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_vm._l((item.roles),function(role){return _c('v-chip',{key:role},[_vm._v(_vm._s(role))])})]}},{key:"item.departments",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.editDepartments(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_vm._v(" "+_vm._s(_vm.userDepartmentsDisplay(item))+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"float-right",attrs:{"text":"","icon":""}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.deleteUser(item)}}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"red darken-1"}},[_vm._v("mdi-check")])],1),_c('v-list-item-title',{staticClass:"red--text text--darken-1"},[_vm._v("Confirm DELETE")])],1),_c('v-list-item',{on:{"click":function($event){_vm.on = false}}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-close")])],1),_c('v-list-item-title',{staticClass:"grey--text"},[_vm._v("Cancel")])],1)],1)],1),(item.disabled)?_c('v-btn',{staticClass:"float-right",attrs:{"text":"","icon":"","color":"red"},on:{"click":function($event){return _vm.activate(item)}}},[_c('v-icon',[_vm._v("mdi-shield_check")])],1):_vm._e(),(!item.disabled)?_c('v-menu',{attrs:{"offset-y":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"float-right",attrs:{"text":"","icon":"","color":"green"}},on),[_c('v-icon',[_vm._v("mdi-shield_check")])],1)]}}],null,true)},[(false)?_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.deactivate(item)}}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"red darken-1"}},[_vm._v("mdi-check")])],1),_c('v-list-item-title',{staticClass:"red--text text--darken-1"},[_vm._v("Confirm Deactivate")])],1),_c('v-list-item',{on:{"click":function($event){_vm.on = false}}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-close")])],1),_c('v-list-item-title',{staticClass:"grey--text"},[_vm._v("Cancel")])],1)],1):_vm._e()],1):_vm._e()]}}],null,false,3953905347)})],1):_vm._e(),(_vm.showCreateDialog && _vm.roles !== null)?_c('create-user-dialog',{attrs:{"roles":_vm.roles,"departments":_vm.departments},on:{"cancelEdit":function($event){_vm.showCreateDialog = false},"saveUser":_vm.saveUser}}):_vm._e(),(_vm.showRoleDialog && _vm.selected !== null)?_c('user-roles-dialog',{attrs:{"userName":_vm.selected.name,"userRoles":_vm.editingRoles},on:{"cancelEdit":function($event){_vm.showRoleDialog = false},"saveRoles":_vm.saveRoles}}):_vm._e(),(_vm.showDepartmentDialog && _vm.selected !== null)?_c('user-departments-dialog',{attrs:{"departments":_vm.departments,"user-name":_vm.selected.name,"user-departments":_vm.selected.departments},on:{"cancelEdit":function($event){_vm.showDepartmentDialog = false},"saveDepartments":_vm.saveDepartments}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }