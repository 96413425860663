<template>
    <standard-dialog :render="!!staff" v-model='dialog' :max-width="500" persistent>
        <template v-slot:title>
            {{
                $t('heading.availability-for', { name: staff.name })
            }}
        </template>
        <template v-slot:content>
            <p class="body-2 text-center">{{ staff.staffRole.name }}</p>
            <v-form ref="form" :lazy-validation="true">
                <v-container fluid>
                    <v-row>
                        <v-col cols="6">
                            <h3 class="body-1">{{ $t('shared.label.leave') }}</h3>
                            <shift-assigner
                                :mine="mine"
                                :weekDate="weekDate"
                                :editable="false"
                                :data="availabilityFor(0)"
                                icon="mdi-close"
                            ></shift-assigner>
                        </v-col>
                        <v-col cols="6">
                            <h3 class="body-1">{{ $t('shared.label.overtime') }}</h3>
                            <shift-assigner
                                :mine="mine"
                                :weekDate="weekDate"
                                :editable="false"
                                :data="availabilityFor(1)"
                                icon="mdi-close"
                            ></shift-assigner>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </template>
        <template v-slot:actions>
            <v-menu dark v-if="canSubmitUnplannedLeave">
                <template #activator="{ on }">
                    <v-btn color="error" :disabled="readonly" text v-on="on">{{
                            $t('button.unplanned-leave')
                        }}</v-btn>
                </template>
                <v-list>
                    <v-list-item @click="unplannedLeave()">
                        <v-list-item-avatar>
                            <v-icon color="red darken-2">mdi-check</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-title class="red--text text--darken-2">
                            {{ $t('message.unplanned-leave-confirm') }}
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="on = false">
                        <v-list-item-avatar>
                            <v-icon color="grey">mdi-close</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-title class="grey--text">{{ $t('shared.button.cancel') }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-btn color="gray" text @click="dialog = false">{{ $t('shared.button.close') }}</v-btn>
        </template>
    </standard-dialog>
</template>
<i18n>
{
    "en": {
        "button": {
            "unplanned-leave": "Unplanned Leave"
        },
        "heading": {
            "availability-for": "Availability For {name}"
        },
        "message": {
            "unplanned-leave-confirm": "Confirm Unplanned Leave for Shift"
        }
    },
    "es": {
    }
}
</i18n>
<script>
import dayjs from 'dayjs';
import ShiftAssigner from '@/components/Shared/ShiftAssigner';
import WeekCrews from '@/lib/data/WeekCrews';
export default {
    props: ['readonly'],
    components: { ShiftAssigner },
    data() {
        return {
            staff: null,
            availability: null,
            weekDate: null,
            mine: null,
            dialog: false,
            date: null,
            shiftNumber: null,
        };
    },
    methods: {
        show(staff, availability, weekDate, mine, date, shiftNumber) {
            this.staff = staff;
            this.availability = availability;
            this.weekDate = weekDate;
            this.mine = mine;
            this.date = date;
            this.shiftNumber = shiftNumber;
            this.dialog = true;
        },
        async unplannedLeave() {
            var items = [{ delete: false, availabilityType: 0, date: this.date, shiftNumber: this.shiftNumber }];
            this.$emit('scheduleUnplannedLeave', this.staff.id, items);
            this.dialog = false;
        },
        availabilityFor(availabilityType) {
            var shifts = [];
            for (var i = 1; i <= this.mine.numberOfShiftsPerDay; i++) {
                shifts.push({ number: i, days: [false, false, false, false, false, false, false] });
            }
            if (!this.availability) {
                return shifts;
            }

            for (let record of this.availability) {
                if (record.availabilityType != availabilityType) continue;
                var date = dayjs(record.date);
                var day = date.day();
                var dayInShiftWeek = day - this.mine.firstDayOfWeek;

                if (dayInShiftWeek < 0) dayInShiftWeek = dayInShiftWeek + 7;
                if (record.shiftNumber != null) {
                    shifts[record.shiftNumber - 1].days[dayInShiftWeek] = true;
                } else {
                    for (var shift of shifts) {
                        shift.days[dayInShiftWeek] = true;
                    }
                }
            }
            return shifts;
        },
    },
    computed: {
        canSubmitUnplannedLeave() {
            return !this.readonly && !this.hasLeaveForToday;
        },
        isLeave() {
            return this.staff.staffAvailability.some((x) => {
                // @ts-ignore
                if (x.availabilityType == 0 && x.date.substring(0, 10) == this.$route.params.date) {
                    //whole day
                    if (x.shiftNumber == null) return true;
                    return x.shiftNumber == parseInt(this.$route.params.shift, 10);
                }
            });
        },
    },
};
</script>
