import axios, { AxiosError, AxiosResponse } from 'axios';
import { EventBus, Events } from '@/lib/EventBus';
import UserSessionState from '@/lib/SessionState';
import Config from '@/lib/Config';
import Errors from '@/lib/Errors';
import dayjs from 'dayjs';
import EditMappings from './OfflineEdits/EditMappings';
import CurrentShiftStore from '@/lib/stores/CurrentShiftStore';
import Tenants from '@/lib/Tenants';

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
const VERSION_HEADER = 'x-version';

export function cleanseErrorOfSensitiveInformation(err: any): any {
    if(err.isAxiosError){
        const safeErr = {...err};
        safeErr.config = {};
        safeErr.request = {};
        if(safeErr.response)
            safeErr.response = {
                status: err.response.status,
                statusText: err.response.statusText,
                data: {}
            };

        return safeErr;
    }

    return err;
}

export class FetchResponseWrapper<T> {
    async Init(response: Response) {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
            await response.json().then((data: any) => {
                this.data = data as T;
            });
        }
        this.status = response.status;
        this.headers = response.headers;
    }
    public data: T | null = null;
    public status: number = 0;
    public headers: any;
}

const baseUrlFromConfig = /\/$/.test(Config.ApiBasePath) ? Config.ApiBasePath : Config.ApiBasePath + '/';
const baseUrl = baseUrlFromConfig + 'api';

EventBus.$on(Events.AuthLogout, (initiatedByUser?: boolean) => {
    if (!initiatedByUser && (window.location.pathname == '/' || window.location.pathname == '')) {
        return;
    }

    window.location.href = '/login';
});

class ApiClient {
    constructor(isForReplayingCommands: boolean) {
        this.isForReplayingCommands = isForReplayingCommands;
    }

    private isForReplayingCommands: boolean = false;
    private userSessionState = new UserSessionState();

    public baseUrl = baseUrl;

    public urlFromPath(path: string) {
        if (path.indexOf('http') == 0) {
            return path;
        }
        return baseUrl + (path[0] === '/' ? '' : '/') + path;
    }

    public urlFromPathAndToken(path: string, token: string) {
        let accessToken = path.indexOf('?') > -1 ? '&' : '?';
        accessToken += 'access_token=' + token;
        return this.urlFromPath(path) + accessToken;
    }

    private markUpHeaders(headers: any, commandCreated: Date | undefined) {
        if (this.isForReplayingCommands) {
            headers['Offline-Replay'] = 'true';
            headers['Attempted-At'] = (dayjs as any).utc(commandCreated).toISOString(); //todo: save at time of attempt
        }
        
        const department = CurrentShiftStore.selectedDepartment === 'Interactions' ? null : CurrentShiftStore.selectedDepartment;
        const tenantId = Tenants.GetCurrentTenant();

        if (department) headers['Department'] = department;
        if (tenantId) headers['Tenant-Id'] = tenantId
        headers[VERSION_HEADER] = Config.ApplicationVersion;
    }

    public async put(path: string, data: any, commandCreated: Date | undefined = undefined) {
        const url = this.urlFromPath(path);
        try {
            const headers: any = { Authorization: `Bearer ${this.userSessionState.idToken}` };
            this.markUpHeaders(headers, commandCreated);
            const response = await axios.put(url, data, { headers: headers });
            EventBus.$emit(Events.ApiCallSuccess);
            return response;
        } catch (err) {
            return await this.handleError(err, url, 'PUT', data);
        }
    }

    public async post(path: string, data: any, commandCreated: Date | undefined = undefined) {
        return this.postInternal(path, data, false, commandCreated);
    }

    private async postInternal(
        path: string,
        data: any,
        multipart: boolean | undefined,
        commandCreated: Date | undefined
    ) {
        const url = this.urlFromPath(path);
        try {
            const postModel = data && data.$meta ? { ...data, $meta: undefined } : data;
            const headers: any = { Authorization: `Bearer ${this.userSessionState.idToken}` };
            this.markUpHeaders(headers, commandCreated);
            const response = await axios.post(url, postModel, { headers: headers });
            EventBus.$emit(Events.ApiCallSuccess);
            return response;
        } catch (err) {
            return await this.handleError(err, url, multipart ? 'POST_FILES' : 'POST', data);
        }
    }

    public postWithFiles(path: string, data: any, files: any[], commandCreated: Date | undefined = undefined) {
        const json = JSON.stringify(data);
        const blob = new Blob([json], {
            type: 'application/json'
        });
        const formData = new FormData();
        formData.append('json', blob);
        for (const file of files) {
            formData.append('file', file);
        }
        return this.postInternal(path, formData, true, commandCreated);
    }

    public async patch(path: string, data: any, commandCreated: Date | undefined = undefined) {
        const url = this.urlFromPath(path);
        try {
            const headers: any = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.userSessionState.idToken}`
            };
            this.markUpHeaders(headers, commandCreated);
            const response = await axios.patch(url, data, { headers: headers });
            EventBus.$emit(Events.ApiCallSuccess);
            return response;
        } catch (err) {
            return await this.handleError(err, url, 'PATCH', data);
        }
    }
    public async get(path: any): Promise<FetchResponseWrapper<any> | null> {
        const url = this.urlFromPath(path);
        try {
            const headers: any = {
                'Content-Type': 'text/plain',
                Authorization: `Bearer ${this.userSessionState.idToken}`
            };
            this.markUpHeaders(headers, undefined);
            const response = await fetch(url, {
                method: 'GET',
                headers: headers
            });
            const wrapped = new FetchResponseWrapper();
            await wrapped.Init(response);
            if (!response.ok) {
                throw { response: wrapped };
            }
            EventBus.$emit(Events.ApiCallSuccess);
            return wrapped;
        } catch (err) {
            return await this.handleError(err, url, 'GET', null);
        }
    }

    public async getWithHeaders(path: string, headers: any) {
        const url = this.urlFromPath(path);
        try {
            this.markUpHeaders(headers, undefined);
            const response = await fetch(url, {
                method: 'GET',
                headers: headers
            });
            const wrapped = new FetchResponseWrapper();
            await wrapped.Init(response);
            if (!response.ok) {
                throw { response: wrapped };
            }
            EventBus.$emit(Events.ApiCallSuccess);
            return wrapped;
        } catch (err) {
            return await this.handleError(err, url, 'GET', null);
        }
    }

    public async download(path: string) {
        const url = path.indexOf('http') == 0 ? path : this.urlFromPath(path);
        const headers: any = {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.userSessionState.idToken}`
        };
        const response = await axios.get(url, { headers: headers, responseType: 'blob' });
        EventBus.$emit(Events.ApiCallSuccess);
        return response;
    }

    public async delete(path: string, data: any | undefined = undefined, commandCreated: Date | undefined = undefined) {
        const url = this.urlFromPath(path);
        try {
            const headers: any = { Authorization: `Bearer ${this.userSessionState.idToken}` };
            this.markUpHeaders(headers, commandCreated);
            const response = await axios.delete(url, { headers: headers, data: data });
            EventBus.$emit(Events.ApiCallSuccess);
            return response;
        } catch (err) {
            return await this.handleError(err, url, 'DELETE', data ? data : {});
        }
    }

    private async handleError(err: any, url: string, method: string, model: any | undefined): Promise<any> {
        if (!navigator.onLine || `${err}`.includes('Network Error')) {
            if (model && model._type && EditMappings.hasCommand(model._type)) {
                if (!this.isForReplayingCommands) {
                    EventBus.$emit(Events.OfflineEdit, model, url, method);
                    //Give the offline edit pipeline a chance to complete before returning back to the caller
                    await delay(100);
                }
                return { offlineEditQueued: true };
            }
        }

        if (!err.response) {
            throw { handled: false, err: cleanseErrorOfSensitiveInformation(err)};
        }

        switch (err.response.status) {
            case 302: {
                EventBus.$emit(Events.AuthLogout, false);
                break;
            }
            case 400: {
                if (err.response && err.response.data && err.response.data.message) {
                    Errors.ApiError(err);
                }
                if (!this.isForReplayingCommands) {
                    EventBus.$emit(Events.ApiCallSuccess);
                }
                throw { handled: false, err: cleanseErrorOfSensitiveInformation(err) };
            }
            case 401: {
                EventBus.$emit(Events.AuthLogout, false);
                break;
            }
            case 403: {
                if (!this.isForReplayingCommands) {
                    EventBus.$emit(Events.ApiCallSuccess);
                }
                // "RestrictedShiftOwnerAction" first introduced in ShiftOwnerAuthorizationFilter (WebAPi project)
                if (err.response.data.type === 'RestrictedShiftOwnerAction') {
                    EventBus.$emit(Events.ShiftOwnerChanged);
                }
                EventBus.$emit(
                    Events.ToastError,
                    err.response.data.message || 'You do not have permission to perform this action.'
                );
                break;
            }
            case 409: {
                EventBus.$emit(Events.ToastError, err.response.data.message || 'There was a conflict while sending the changes.');
                if (err.response.data.code){
                    switch (err.response.data.code) {
                        case "Conflict":
                            if(this.isForReplayingCommands)
                                EventBus.$emit(Events.ChangeConflictDuringOfflineEdits, model, err.response.data);

                                EventBus.$emit(Events.ChangeConflict, model, err.response.data);
                            break;
                        case "Lock"://TODO check this code
                            if(this.isForReplayingCommands)
                                EventBus.$emit(Events.LockFailureDuringOfflineEdits, model, err.response.data);

                            EventBus.$emit(Events.LocationLockFailed, err.response.data);
                            break;
                        default:
                            break;
                    }
                }
                break;
            }
            case 412: {
                //HTTP Precondition Failed - returned when the client and API versions are mismatched
                EventBus.$emit(Events.UiVersionMatchesApiVersion, false, Config.ApplicationVersion, err.response.headers[VERSION_HEADER] ||  err.response.headers.get(VERSION_HEADER) || err.response.data.version);
                break;
            }
            case 500: {
                Errors.ApiError(cleanseErrorOfSensitiveInformation(err));
                throw { handled: false, err: cleanseErrorOfSensitiveInformation(err) };
                break;
            }
            default: {
                Errors.ApiError(cleanseErrorOfSensitiveInformation(err));
                throw { handled: false, err: cleanseErrorOfSensitiveInformation(err) };
            }
        }
    }
}

export default new ApiClient(false);
export const OfflineReplayApiClient = new ApiClient(true);
