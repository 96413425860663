<template>
    <standard-dialog :render="!!shiftLevel" :value='dialog' :max-width="500" persistent>
        <template v-slot:title>
            Resolve Conflicts for {{ shiftLevel.reducedLevel.name }}
        </template>
        <template v-slot:content>
            <v-form ref="form" :lazy-validation="true">
                <v-container fluid class="pb-0">
                    <v-row>
                        <v-col cols="12" class="pa-2">
                            <v-textarea
                                label="Comments"
                                auto-grow
                                rows="3"
                                class="mt-0 pt-0"
                                v-model="comment"
                                @keyup="markresolved"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" class="pa-2">
                            <v-checkbox label="Resolved" v-model="isResolved"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col :cols="12" class="d-flex mt-5 subtitle-1 pa-0">
                            <v-spacer></v-spacer>Jump to Department:
                            <v-spacer></v-spacer>
                        </v-col>
                        <v-col :cols="12" class="d-flex pa-0 mt-1">
                            <v-spacer></v-spacer>
                            <v-btn
                                small
                                color="secondary"
                                class="mr-2"
                                v-for="(dept, i) in conflictingDepartments"
                                :key="'dept-' + i"
                                @click="goToDept(dept.id)"
                            >
                                <v-icon>mdi-menu-right</v-icon>
                                {{ dept.name }}
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </template>
        <template v-slot:actions>
            <v-spacer></v-spacer>
            <v-btn color="gray" text @click="cancel()">Cancel</v-btn>
            <v-btn color="primary" text @click="save()">Save</v-btn>
        </template>
    </standard-dialog>
</template>
<script>
import Interactions from '@/lib/data/Interactions';
export default {
    props: ['model'],
    data() {
        return {
            dialog: false,
            shiftLevel: null,
            task: null,
            overlapping: [],
            interactionsId: null,
            isResolved: false,
            comment: null
        };
    },
    computed: {
        conflictingDepartments() {
            if (!this.model || !this.shiftLevel) return [];

            var matchingDepts = this.model.departments.filter(
                d => this.shiftLevel.departmentLevels.filter(x => x.hasData && x.departmentId == d.id).length
            );

            return matchingDepts;
        }
    },
    created() {},
    methods: {
        goToDept(departmentId) {
            this.$emit('gotoDepartment', departmentId);
        },
        show(interactionsId, shiftLevel) {
            this.interactionsId = interactionsId;
            this.shiftLevel = shiftLevel;
            this.isResolved = this.shiftLevel.conflictAddressed;
            this.comment = this.shiftLevel.conflictComment;
            this.dialog = true;
        },
        async save() {
            await Interactions.resolveLevel(
                this.interactionsId,
                this.shiftLevel.reducedLevel.id,
                this.isResolved,
                this.comment
            );
            this.dialog = false;
            this.$emit('saved');
        },
        cancel() {
            this.dialog = false;
            this.$emit('closed');
        },
        markresolved() {
            if (this.comment) this.isResolved = true;
        }
    }
};
</script>
<style lang="scss" scoped></style>
