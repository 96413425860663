import { Events, EventBus } from '@/lib/EventBus';
import { useSystemStore } from './stores/SystemStore';

export default {
    init() {
        window.addEventListener('load', () => {
            window.addEventListener('online', () => {
                useSystemStore().setOnline(true);
                EventBus.$emit(Events.Online);
            });
            window.addEventListener('offline', () => {
                useSystemStore().setOnline(false);
                EventBus.$emit(Events.Offline);
            });
        });
    },
};
