import { RateMetric } from "@/models/api";

export function unitDescription(rateMetric:RateMetric) {
    switch(rateMetric) {
        case RateMetric.Distance:
            return 'm';
        case RateMetric.WeightTonnes:
            return 't';
        case RateMetric.WeightKilograms:
            return 'kg';
        default:
            return '';
    }
}

export function coalescingUnitDescription(rateMetric:RateMetric | null | undefined): string {
    switch(rateMetric) {
        case RateMetric.Distance:
            return 'm';
        case RateMetric.WeightTonnes:
            return 't';
        case RateMetric.WeightKilograms:
            return 'kg';
        default:
            return '';
    }
}

export function UnitLongDescription(rateMetric: RateMetric | null | undefined): string {
    switch (rateMetric) {
        case RateMetric.Distance:
            return 'Metres';
        case RateMetric.WeightTonnes:
            return 'Tonnes';
        case RateMetric.WeightKilograms:
            return 'Kilograms';
        case RateMetric.Count:
            return 'Count';
        default:
            return 'Unknown';
    }
}

export function UnitLongRateDescription(rateMetric: RateMetric | null | undefined): string {
    switch (rateMetric) {
        case RateMetric.Distance:
            return 'Metres per hour';
        case RateMetric.WeightTonnes:
            return 'Tonnes per hour';
        case RateMetric.WeightKilograms:
            return 'Kilograms per hour';
        case RateMetric.Count:
            return 'Number per hour';
        default:
            return 'Unknown';
    }
}

export function unitLongDescription(rateMetric:RateMetric){
    switch(rateMetric){
        case RateMetric.Distance:
            return 'metres';
        case RateMetric.WeightTonnes:
            return 'tonnes';
        case RateMetric.WeightKilograms:
            return 'kilograms';
        default:
            return '';
    }
}

export function defaultingUnitLongDescription(rateMetric: RateMetric | null | undefined, defaultValue: string = '') {
    if (rateMetric === null || rateMetric === undefined) {
        return defaultValue;
    }
    return unitLongDescription(rateMetric);
}

export function defaultingUnitDescription(rateMetric: RateMetric | null | undefined, defaultValue: string = '') {
    if (rateMetric === null || rateMetric === undefined) {
        return defaultValue;
    }
    return unitDescription(rateMetric);
}

export function rateDescription(metric:RateMetric) {
    return `${unitDescription(metric)}/hr`;
}

export function isRate(metric:RateMetric | null) {
    return metric !== null
}