<template>
    <standard-dialog v-model='dialog' :max-width="900" persistent>
        <template v-slot:title>
            Import Equipment Availability Plan
        </template>
        <template v-slot:content>
            <v-stepper v-model="step" class="elevation-0">
                <v-stepper-header class="elevation-0">
                    <v-stepper-step :complete="step > 1" step="1">Upload File</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="step > 2" step="2">Review</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3">Save</v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1" class="pa-0">
                        <v-card elevation="0">
                            <v-card-text class="mb-5">
                                <v-row>
                                    <v-col>
                                        <v-alert type="info" class="white--text">
                                            <div>
                                                <ul>
                                                    <li>
                                                        Existing data found between provided date range will be
                                                        replaced or removed
                                                    </li>
                                                    <li>
                                                        Existing data that overlap provided date range below will be
                                                        affected
                                                    </li>
                                                    <li>
                                                        Imported data that is not between provided date range below
                                                        will be skipped
                                                    </li>
                                                </ul>
                                            </div>
                                        </v-alert>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-datetime-picker
                                            label="From"
                                            v-model="selectedDateFrom"
                                            :textFieldProps="{ 'hide-details': true }"
                                            :dateFormat="dateFormat"
                                            timeFormat="HH:mm"
                                        >
                                            <template #dateIcon>
                                                <v-icon>mdi-calendar</v-icon>
                                            </template>
                                            <template #timeIcon>
                                                <v-icon>mdi-clock-outline</v-icon>
                                            </template>
                                        </v-datetime-picker>
                                        <v-text-field
                                            ref="dateFrom"
                                            class="custom-validation-message"
                                            v-model="selectedDateFrom"
                                            readonly
                                            :rules="[$rules.required(), $rules.before(selectedDateTo)]"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-datetime-picker
                                            label="To"
                                            v-model="selectedDateTo"
                                            :dateFormat="dateFormat"
                                            timeFormat="HH:mm"
                                            :textFieldProps="{ 'hide-details': true }"
                                        >
                                            <template #dateIcon>
                                                <v-icon>mdi-calendar</v-icon>
                                            </template>
                                            <template #timeIcon>
                                                <v-icon>mdi-clock-outline</v-icon>
                                            </template>
                                        </v-datetime-picker>
                                        <v-text-field
                                            ref="dateTo"
                                            class="custom-validation-message"
                                            v-model="selectedDateTo"
                                            readonly
                                            :rules="[$rules.required(), $rules.after(selectedDateFrom)]"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <input
                                            ref="inputUpload"
                                            type="file"
                                            accept="text/csv"
                                            @change="getFile($event)"
                                        />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <a
                                    download="EquipmentsAvailabilityPlan.csv"
                                    href="data:application/octet-stream,Equipment%2CService%20Type%2CStart%20Date%2CStart%20Shift%2CDuration"
                                >Download Template</a
                                >
                                <v-spacer></v-spacer>
                                <v-btn text @click="cancel">Cancel</v-btn>
                                <v-btn
                                    color="primary"
                                    @click="upload"
                                    :disabled="!canUpload"
                                    :loading="$wait.is('uploading')"
                                    text
                                >Upload</v-btn
                                >
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="2" class="pa-0">
                        <v-card elevation="0">
                            <v-card-text class="mb-5">
                                <v-row
                                    justify="center"
                                    class="pb-5 black--text"
                                    v-if="importResult && importResult.isValid"
                                >
                                    <v-col :cols="3">
                                        <div>From:</div>
                                        {{ selectedDateFrom | formatDate(longDateTimeFormat) }}
                                    </v-col>
                                    <v-col :cols="3">
                                        <div>To:</div>
                                        {{ selectedDateTo | formatDate(longDateTimeFormat) }}
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <template v-if="importResult.isValid">
                                            <v-simple-table dense v-if="importResult.plan.length > 0">
                                                <template v-slot:default>
                                                    <thead>
                                                    <tr>
                                                        <th class="text-left">Equipment</th>
                                                        <th class="text-left">Service Type</th>
                                                        <th class="text-left">Starts At</th>
                                                        <th class="text-left">Ends At</th>
                                                        <th class="text-left"></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <template
                                                        v-for="(equipment, equipmentIndex) in importResult.plan"
                                                    >
                                                        <template
                                                            v-for="(availability,
                                                                    availabilityIndex) in equipment.availability"
                                                        >
                                                            <tr
                                                                :key="'id' + equipmentIndex + availabilityIndex"
                                                                :class="{
                                                                            'grey--text': availability.operation === 2
                                                                        }"
                                                            >
                                                                <td class="black--text">
                                                                    <strong v-if="availabilityIndex === 0">
                                                                        {{ equipment.name }}
                                                                    </strong>
                                                                </td>
                                                                <td>
                                                                    <v-chip
                                                                        label
                                                                        small
                                                                        :color="colorFor(availability.type)"
                                                                        class="ma-1"
                                                                    >
                                                                        {{
                                                                            availability.type
                                                                                | equipmentAvailabilityType
                                                                        }}
                                                                    </v-chip>
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        availability.from
                                                                            | formatDate(longDateTimeFormat)
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        availability.to
                                                                            | formatDate(longDateTimeFormat)
                                                                    }}
                                                                </td>

                                                                <td>
                                                                    <v-icon
                                                                        medium
                                                                        color="green darken-2"
                                                                        v-if="availability.operation === 1"
                                                                    >mdi-calendar-check</v-icon
                                                                    >
                                                                    <v-icon medium color="red darken-2" v-else
                                                                    >mdi-calendar-remove</v-icon
                                                                    >
                                                                    {{
                                                                        availability.operation
                                                                            | availabilityImportOperation
                                                                    }}
                                                                </td>
                                                            </tr>
                                                        </template>
                                                    </template>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                            <v-alert color="warning" class="white--text" v-else>
                                                    <span>
                                                        No matched records available for import. Please make sure your
                                                        CSV contains data. And some or all dates provided in the CSV
                                                        falls between
                                                        {{ selectedDateFrom | formatDate(longDateTimeFormat) }} and
                                                        {{ selectedDateTo | formatDate(longDateTimeFormat) }}
                                                    </span>
                                            </v-alert>
                                        </template>
                                        <v-alert
                                            color="warning"
                                            class="white--text"
                                            v-else-if="!importResult.isValid"
                                        >
                                            <div>
                                                There was a problem importing your equipments availability plan:
                                                <ul>
                                                    <template v-for="(error, index) in importResult.errorMessages">
                                                        <li :key="index">
                                                            {{ error }}
                                                        </li>
                                                    </template>
                                                </ul>
                                            </div>
                                        </v-alert>
                                        <v-alert color="error" class="white--text" v-else-if="caughtError !== null">
                                                <span
                                                >There was unexpected error importing your equipments availability
                                                    plan</span
                                                >
                                        </v-alert>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn text @click="back"> <v-icon left>mdi-chevron-left</v-icon>Back </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    @click="save"
                                    :disabled="!importResult.isValid || importResult.plan.length === 0"
                                    :loading="$wait.is('saving')"
                                >Save</v-btn
                                >

                                <v-btn text @click="cancel">Cancel</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="3" class="pa-0">
                        <v-card elevation="0">
                            <v-card-text class="mb-5">
                                <v-alert color="success" class="white--text" v-if="saveSuccess">
                                    <p>Equipments availability plan saved successfully</p>
                                </v-alert>
                                <v-alert color="error" class="white--text" v-else>
                                    <p>There was an error saving</p>
                                </v-alert>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="complete">Done</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </template>
    </standard-dialog>
</template>
<script>
import EquipmentAvailability from '@/lib/data/EquipmentAvailability';
import { DateFormat } from '@/plugins/dates';
import dayjs from 'dayjs';

export default {
    props: ['mineId', 'weekDate'],
    data() {
        return {
            dialog: false,
            dateFromDialog: false,
            dateToDialog: false,
            step: 1,
            importResult: {},
            saveSuccess: false,
            selectedDateFrom: null,
            selectedDateTo: null,
            file: null,
            caughtError: null,
            dateFormat: DateFormat.ShortDisplayPicker,
            longDateTimeFormat: DateFormat.LongDateTimeDisplay
        };
    },
    mounted() {
        setTimeout(() => {
            if (this.$refs.inputUpload) {
                this.$refs.inputUpload.value = null;
            }
        }, 50);
    },
    methods: {
        show() {
            this.selectedDateFrom = dayjs(this.weekDate).toDate();
            this.selectedDateTo = dayjs(this.weekDate)
                .add(7, 'day')
                .toDate();
            this.dialog = true;
            this.step = 1;
        },
        cancel() {
            this.dialog = false;
            this.clear();
        },
        complete() {
            this.dialog = false;
            this.clear();
            this.$emit('complete');
        },
        clear() {
            this.file = null;
            this.importResult = {};
            this.saveSuccess = false;
            this.selectedDateFrom = null;
            this.selectedDateTo = null;
            this.$refs.inputUpload.value = null;
            this.caughtError = null;
            this.step = 1;
        },
        back() {
            this.file = null;
            this.$refs.inputUpload.value = null;
            if (this.step > 1) this.step = this.step - 1;
        },
        colorFor(x) {
            return EquipmentAvailability.colorFor(x);
        },
        async upload() {
            try {
                this.$wait.start('uploading');
                this.caughtError = null;
                this.importResult = {};
                this.importResult = await EquipmentAvailability.uploadAvailabilityPlan(
                    this.mineId,
                    {
                        from: dayjs(this.selectedDateFrom).format(),
                        to: dayjs(this.selectedDateTo).format()
                    },
                    this.file
                );
            } catch (err) {
                this.importResult = {};
                this.caughtError = err;
            } finally {
                this.step = 2;
                this.$wait.end('uploading');
            }
        },
        async getFile(e) {
            this.file = e.target.files[0];
        },
        async save() {
            try {
                this.caughtError = null;
                this.$wait.start('saving');
                await EquipmentAvailability.saveAvailabilityPlan(this.importResult.plan);
                this.saveSuccess = true;
            } catch (err) {
                this.saveSuccess = false;
                this.caughtError = err;
            } finally {
                this.step = 3;
                this.$wait.end('saving');
            }
        }
    },
    watch: {
        selectedDateTo: function(val) {
            if (this.$refs.dateFrom && val) {
                setTimeout(() => {
                    this.$refs.dateFrom.validate();
                }, 100);
            }
        }
    },
    computed: {
        canUpload: {
            get() {
                return (
                    this.file &&
                    this.$refs.dateFrom &&
                    this.$refs.dateTo &&
                    this.$refs.dateFrom.valid &&
                    this.$refs.dateTo.valid
                );
            }
        }
    }
};
</script>
