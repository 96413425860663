import {
    HazardNoteUpdateModel,
    UpdateEquipmentHazardsAtShiftCommand,
    UpdateLocationHazardsAtShiftCommand
} from '@/models/api';

export interface ClientUpdateHazardsCommand {
    toRemoveIds: string[];
    toAddOrUpdate: HazardNoteUpdateModel[];
}

export function TransformClientUpdateHazardsCommandForLocation(command: ClientUpdateHazardsCommand, shiftId: string, locationId: string): UpdateLocationHazardsAtShiftCommand {
    return {
        _type: 'UpdateLocationHazardsAtShiftCommand',
        shiftId,
        locationId,
        toRemoveIds: command.toRemoveIds,
        toAddOrUpdate: command.toAddOrUpdate,
    };
}

export function TransformClientUpdateHazardsCommandForEquipment(command: ClientUpdateHazardsCommand, shiftId: string, equipmentId: string): UpdateEquipmentHazardsAtShiftCommand {
    return {
        _type: 'UpdateEquipmentHazardsAtShiftCommand',
        shiftId,
        equipmentId,
        toRemoveIds: command.toRemoveIds,
        toAddOrUpdate: command.toAddOrUpdate,
    };
}