import {
    AddTaskBeforeOrAfterCommand,
    CreateAdHocTaskCommand,
    DeleteAdHocTaskCommand,
    DeleteTaskCommand,
    FixRateTaskCommand, LocationModel,
    PlannedEquipmentViewModel,
    SetTaskEquipmentCommand,
    SplitAdhocTaskCommand,
    SplitTaskCommand, SyncTasksCommand,
    TaskCommandResponse,
    TaskTypeViewModel,
    UpdateAdHocTaskCommand,
    UpdateAdHocTaskTimesCommand, UpdateTaskQuantityCommand,
    UpdateTaskTimesCommand,
    UpdateType
} from '@/models/api';
import ClientTaskModel from '@/models/client/client-task';
import PlannedAdHocTasks from '../data/PlannedAdHocTasks';
import Shift from '../data/Shift';
import { curry, curryRight } from 'lodash';
import {
    AddNewTaskRelativeToExisting,
    BringTaskForward,
    DeleteTask,
    DeleteAdHocTask,
    SplitAdHocTask,
    SplitTask,
    AddNewAdHocTask,
    DoNothing,
    UpdateAdHocTask,
    SetTaskEquipment,
    RemoveTaskWithNoSideEffects,
    ClientOnlyRemoveTaskCommand,
    ClientOnlyTaskCommand, AddNewTaskAtTimePoint, NoOpApiCall
} from '@/lib/services/Location';
import { ClientRowBlastPacketTargetDisplayInformation } from '@/lib/stores/Production/ShiftWindowActualsStore';
import ClientRow from '@/models/client/client-row';
import { AddTaskAtTimePointCommand } from '@/models/client/Commands/add-task-at-time-point-command';

interface CommandHandler<T> {
    syncType: 'TASK' | 'ADHOC' | 'TASK_THEN_API' | 'NONE';
    syncChangeSetTransformerFactory?: (command: T) => (changeSet: SyncTasksCommand) => SyncTasksCommand;
    clientHandler: (headingTasks: ClientTaskModel[], command: T) => ClientTaskModel[];
    apiHandler: (command: T) => Promise<TaskCommandResponse>;
    updateHeading: boolean;
}

export function NoOpChangeSetTransformerFactory(command: any): (changeSet: SyncTasksCommand) => SyncTasksCommand {
    return cs => cs;
}

function addTaskBeforeOrAfterExistingChangeSetTransformer(command: AddTaskBeforeOrAfterCommand): (changeSet: SyncTasksCommand) => SyncTasksCommand {
    return (changeSet: SyncTasksCommand) => ({
        ... changeSet,
        newTaskCycles: [...command.newTaskCycles],
        tasksWithChangedCycles: [...command.tasksWithChangedCycles]
    });
}

function addTaskAtTimePointExistingChangeSetTransformer(command: AddTaskAtTimePointCommand): (changeSet: SyncTasksCommand) => SyncTasksCommand {
    return (changeSet: SyncTasksCommand) => ({
        ... changeSet,
        newTaskCycles: [...command.newTaskCycles]
    });
}

export function GetCommandType(command: any) {
    if (command._type === 'UpdateTaskTimesCommand' && command.updateType === UpdateType.BringForward) {
        return 'BringTaskForwardCommand';
    }

    return command._type;
}

export function isTaskCommandResponse(arg: any): arg is TaskCommandResponse {
    return arg && ('planningUpdateTag' in arg);
}

export function GetClientOnlyCommandHandlers(commands: ClientOnlyTaskCommand[]) {
    return commands.map(command=>{
        switch(command.type) {
            case 'RemoveTaskCommand':
            {
                const removeTaskCommand = command as ClientOnlyRemoveTaskCommand;
                return curryRight(RemoveTaskWithNoSideEffects)(removeTaskCommand);
            }
            default:
                throw new Error(`Could not find command type ${command.type}`);
        }
    });
}

export function GetCommandHandlers(
    commandType: string,
    locationInformation: LocationModel,
    departmentId: string,
    equipment: PlannedEquipmentViewModel[],
    taskTypes: TaskTypeViewModel[],
    blastPacketTargets: ClientRowBlastPacketTargetDisplayInformation[]
) {
    switch (commandType) {
        case 'DeleteTaskCommand':
            return {
                syncType: 'TASK',
                clientHandler: DeleteTask,
                apiHandler: NoOpApiCall,
                updateHeading: true,
            } as CommandHandler<DeleteTaskCommand>;
        case 'DeleteAdHocTaskCommand':
            return {
                syncType: 'ADHOC',
                clientHandler: DeleteAdHocTask,
                apiHandler: NoOpApiCall,
                updateHeading: true,
            } as CommandHandler<DeleteAdHocTaskCommand>;
        case 'AddTaskBeforeOrAfterCommand':
            return {
                syncType: 'TASK',
                clientHandler: curry(AddNewTaskRelativeToExisting)(taskTypes)(blastPacketTargets),
                syncChangeSetTransformerFactory: addTaskBeforeOrAfterExistingChangeSetTransformer,
                apiHandler: NoOpApiCall,
                updateHeading: true,
            } as CommandHandler<AddTaskBeforeOrAfterCommand>;
        case 'AddTaskAtTimePointCommand':
            return {
                syncType: 'TASK',
                clientHandler: curry(AddNewTaskAtTimePoint)(taskTypes)(blastPacketTargets)(locationInformation)(departmentId),
                syncChangeSetTransformerFactory: addTaskAtTimePointExistingChangeSetTransformer,
                apiHandler: NoOpApiCall,
                updateHeading: true,
            } as CommandHandler<AddTaskAtTimePointCommand>;
        case 'SplitTaskCommand':
            return {
                syncType: 'TASK',
                clientHandler: curry(SplitTask)(taskTypes),
                apiHandler: NoOpApiCall,
                updateHeading: true,
            } as CommandHandler<SplitTaskCommand>;
        case 'SplitAdhocTaskCommand':
            return {
                syncType: 'ADHOC',
                clientHandler: curry(SplitAdHocTask)(taskTypes),
                apiHandler: NoOpApiCall,
                updateHeading: true,
            } as CommandHandler<SplitAdhocTaskCommand>;
        case 'CreateAdHocTaskCommand':
            return {
                syncType: 'ADHOC',
                clientHandler: curry(AddNewAdHocTask)(taskTypes),
                apiHandler: NoOpApiCall,
                updateHeading: true,
            } as CommandHandler<CreateAdHocTaskCommand>;
        case 'UpdateAdHocTaskCommand':
            return {
                syncType: 'ADHOC',
                clientHandler: curry(UpdateAdHocTask)(taskTypes),
                apiHandler: NoOpApiCall,
                updateHeading: true,
            } as CommandHandler<UpdateAdHocTaskCommand>;
        case 'SetTaskEquipmentCommand':
            return {
                syncType: 'NONE',
                clientHandler: curry(SetTaskEquipment)(equipment),
                apiHandler: Shift.updateEquipment,
                updateHeading: false,
            } as CommandHandler<SetTaskEquipmentCommand>;
        case 'BringTaskForwardCommand':
            return {
                syncType: 'TASK',
                clientHandler: BringTaskForward,
                apiHandler: NoOpApiCall,
                updateHeading: true,
            } as CommandHandler<UpdateTaskTimesCommand>;
        case 'UpdateTaskTimesCommand':
            return {
                syncType: 'TASK',
                clientHandler: DoNothing,
                apiHandler: NoOpApiCall,
                updateHeading: true,
            } as CommandHandler<UpdateTaskTimesCommand>;
        case 'UpdateTaskQuantityCommand':
            return {
                syncType: 'TASK',
                clientHandler: DoNothing,
                apiHandler: NoOpApiCall,
                updateHeading: true,
            } as CommandHandler<UpdateTaskQuantityCommand>;
        case 'UpdateAdHocTaskTimesCommand':
            return {
                syncType: 'ADHOC',
                clientHandler: DoNothing,
                apiHandler: NoOpApiCall,
                updateHeading: true,
            } as CommandHandler<UpdateAdHocTaskTimesCommand>;
        case 'FixRateTaskCommand':
            return {
                syncType: 'TASK',
                clientHandler: DoNothing,
                apiHandler: NoOpApiCall,
                updateHeading: true,
            } as CommandHandler<FixRateTaskCommand>;
        default:
            throw new Error(`Could not find command type ${commandType}`);
    }
}
