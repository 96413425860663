import { GenericDataApi } from '@/lib/data/GenericDataApi';
import AppHttp from '@/lib/AppHttp';
import Errors from '@/lib/Errors';
import {
    AddLocationHazardsAtShiftCommand,
    LocationFileType,
    LocationTrackerShiftLocationDetailsViewModel,
    ShiftLocationDetailViewModel, UpdateLocationHazardsAtShiftCommand,
    UpdateShiftLocationDetailsCommand
} from '@/models/api';
import EditMappings from '../OfflineEdits/EditMappings';
import { AxiosError } from 'axios';
import RequestHandler from '../api/RequestHandler';

export class ShiftLocationDetails extends GenericDataApi<any> {
    constructor() {
        super('ShiftLocationDetails');
    }

    public async getFor(shiftId: string, plannedLocationId: string, include?: string[]): Promise<ShiftLocationDetailViewModel> {
        let query = '';
        if (include) {
            const p: string = include.map((x) => `include=${x}`).join('&');
            query = '?' + p;
        }
        const response = await AppHttp.get(this.resource + `/${shiftId}/Locations/${plannedLocationId}` + query);
        const data = response ? response.data : null;
        return await EditMappings.applyPendingEdits<ShiftLocationDetailViewModel>('ShiftLocationDetailViewModel', data);
    }

    public async uploadLocationFile(
        shiftId: string,
        locationId: string,
        locationFileType: LocationFileType,
        file: File
    ) {
        await AppHttp.postWithFiles(
            `${this.resource}/${shiftId}/Locations/${locationId}/${locationFileType}`,
            {},
            [file]
        );
    }

    public async deleteLocationFile(shiftId: string, locationFileId: string) {
        await AppHttp.delete(`${this.resource}/${shiftId}/Locations/${locationFileId}`);
    }

    async send(model: UpdateShiftLocationDetailsCommand) {
        const response: any = await RequestHandler.execute(
            'POST',
            this.resource + '/Details',
            this.cleanOutgoingData({ ...model })
        );
        const data = response ? response.data : null;
        return data;
    }

    async addHazards(command: AddLocationHazardsAtShiftCommand) {
        const response: any = await RequestHandler.execute(
            'POST',
            this.resource + '/Details/Hazards/Add',
            this.cleanOutgoingData({ ...command })
        );
        const data = response ? response.data : null;
        return data;
    }

    async updateHazards(command: UpdateLocationHazardsAtShiftCommand) {
        const response: any = await AppHttp.post(
            this.resource + '/Details/Hazards',
            this.cleanOutgoingData({ ...command })
        );
        const data = response ? response.data : null;
        return data;
    }
}

export default new ShiftLocationDetails();
