<template>
    <shift-loader 
        name="resources" 
        @loaded="loadData" 
        :showPageFeatures="enablePageFeatures"
        :hidePageFeatures="disablePageFeatures"
        :isLoading="$wait.is('get data')"
    >
        <template #default="{ isCurrentShiftOwner }">
            <v-container fluid class="relative ma-0 px-3 py-0 fill-height" style="overflow: hidden">
                <h1 class="printonly title">{{ $t('heading.resources') }}, {{ $route.params.date | formatDate }}</h1>
                <v-row class="ma-0 page-layout">
                    <v-col
                        :cols="4"
                        :sm="4"
                        :md="4"
                        :lg="3"
                        :xl="2"
                        class="pr-2 full-height clear-bottom d-flex flex-column"
                        style="overflow-x: hidden; overflow-y: hidden; max-width: 250px"
                    >
                        <v-container>
                            <v-row>
                                <v-col :cols="2">
                                    <h3 class="title mb-0">{{ $t('heading.crews') }}</h3>
                                </v-col>
                                <v-col :cols="10">
                                    <v-select
                                        :disabled="!isCurrentShiftOwner"
                                        :readonly="!isCurrentShiftOwner"
                                        style="max-width: 250px"
                                        :value="crewIds"
                                        :items="crewSelect"
                                        item-value="id"
                                        item-text="name"
                                        class="mt-0 pt-0 ml-10"
                                        hide-details
                                        @change="debouncedUpdateShiftSelectedCrews"
                                        :multiple="true"
                                        :loading="$wait.is('set_selected_crews')"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-divider />
                        <v-container v-if="noCrewSelected" class="ma-0 pa-0 fill-height pb-4 justify-center">
                            <empty-state icon="mdi-account-off" :message="$t('message.no-crew-selected')" />
                        </v-container>
                        <v-container v-else class="ma-0 pa-0 pb-4" style="overflow-y: auto">
                            <v-row class="my-1 pa-0" v-for="(role, index) in roles" :key="role.id">
                                <v-col cols="12" class="py-0">
                                    <v-card :elevation="1" :class="{ striped: index % 2 == 1 }" outlined class="pa-2">
                                        <v-card-title class="subtitle-1 pl-1 pr-0 py-2">
                                            <strong>{{ role.name }}</strong>
                                            <v-spacer />
                                            <v-btn
                                                small
                                                outlined
                                                primary
                                                :elevation="0"
                                                @click="manuallyAddCrew(role)"
                                                :disabled="isCurrentShiftOwner === false"
                                                >Add</v-btn
                                            >
                                        </v-card-title>
                                        <div
                                            v-for="crew in getMembersForRoleByCrew(role.id)"
                                            :key="crew.id + '-' + role.id"
                                        >
                                            <v-card :elevation="0" class="pa-1 mb-1">
                                                <div class="body-2">
                                                    {{ crew.name }}
                                                </div>
                                                <div v-for="member in crew.staff" :key="member.id">
                                                    <shift-member
                                                        :staff="member"
                                                        :isReadOnly="isCurrentShiftOwner === false"
                                                        :showUtilisation="isOnline"
                                                        @dragStart="startDrag"
                                                        @dragEnd="endDrag"
                                                        @touchDrop="onStaffTouchDrop"
                                                        @scheduleClick="viewLeaveFor(member, true)"
                                                        @addClick="onAddCrewMember(member)"
                                                        @removeClick="onRemoveCrewMember(member)"
                                                    />
                                                </div>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row class="my-2 mx-1 pa-0">
                                <v-btn
                                    small
                                    color="secondary"
                                    class="flex-grow-1"
                                    :elevation="0"
                                    :disabled="isCurrentShiftOwner === false"
                                    @click="addCrewRole"
                                    >{{ $t('button.add-crew-role') }}</v-btn
                                >
                            </v-row>
                        </v-container>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col
                        :cols="6"
                        :sm="6"
                        :md="7"
                        :lg="8"
                        :xl="9"
                        class="full-height clear-bottom"
                        style="overflow-x: hidden; overflow-y: hidden"
                    >
                        <v-row style="min-height: 70px">
                            <v-col :cols="12">
                                <div class="d-flex align-center">
                                    <h3 class="title">{{ $t('heading.assigned-resources') }}</h3>
                                    <v-spacer></v-spacer>
                                    <div class="d-flex align-center">
                                        <v-menu
                                            v-model="copyPreviousAllocationsMenu"
                                            :close-on-content-click="false"
                                            offset-y
                                        >
                                            <template v-slot:activator="{ on: menu, attrs }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on: tooltip }">
                                                        <v-access-control
                                                            :useCustom="!isCurrentShiftOwner"
                                                            :offlineAccess="false"
                                                        >
                                                            <template #custom>

                                                            </template>
                                                            <v-btn
                                                                color="secondary"
                                                                small
                                                                class="mr-1 float-right mb-0 ml-1"
                                                                v-bind="attrs"
                                                                :loading="$wait.is('copy equipment allocations - info')"
                                                                :disabled="copyPreviousAllocationsInfo == null || !copyPreviousAllocationsInfo.canCopy"
                                                                v-on="{ ...tooltip, ...menu }"
                                                            >
                                                                <v-icon left>mdi-content-copy</v-icon
                                                                >{{ $t('button.copy-previous') }}
                                                            </v-btn>
                                                        </v-access-control>
                                                    </template>
                                                    <span>Copy Previous Allocations</span>
                                                </v-tooltip>
                                            </template>
                                            <v-card v-if="copyPreviousAllocationsInfo">
                                                <v-list>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title>Copy Previous Shift Allocations?</v-list-item-title>
                                                            <v-list-item-subtitle>Copy from {{ copyPreviousAllocationsInfo.copyShiftName }}</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                                <v-divider></v-divider>
                                                <v-list>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            This will copy {{ copyPreviousAllocationsInfo.countOfEquipmentThatCanPopulate }} equipment {{ copyPreviousAllocationsInfo.countOfEquipmentThatCanPopulate | pluralize('allocation', 'allocations') }} and {{ copyPreviousAllocationsInfo.countOfRolesThatCanPopulate }} role {{ copyPreviousAllocationsInfo.countOfRolesThatCanPopulate | pluralize('allocation', 'allocations') }}.
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>

                                                <v-card-actions>
                                                    <v-spacer></v-spacer>

                                                    <v-btn color="gray" text @click="() => copyPreviousAllocationsMenu = false">No</v-btn>
                                                    <v-btn role="button" color="primary" @click="copyPreviousAllocation" text
                                                    ><v-icon>mdi-check</v-icon>Yes</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-menu>
                                        <v-switch class="mr-2" dense v-model="hideUnavailableEquipment" :label="toggleUnavailableLabel"></v-switch>
                                        <v-access-control
                                            v-if="showClearEquipmentAssignment"
                                            :useCustom="!isCurrentShiftOwner"
                                            :offlineAccess="true"
                                        >
                                            <template #custom>
                                                <no-permission-btn :label="$t('button.clear-staff')" />
                                            </template>
                                            <v-btn
                                                color="secondary"
                                                small
                                                @click="clearEquipmentAssignment()"
                                                class="mr-1 float-right mb-0 ml-1"
                                            >
                                                <v-icon left>mdi-close</v-icon
                                                >{{ $t('button.clear-staff') }}
                                            </v-btn>
                                        </v-access-control>
                                        <v-text-field
                                            outlined
                                            v-model="equipmentFilter"
                                            :label="$t('shared.label.filter')"
                                            clearable
                                            class="mb-0"
                                            style="max-width: 200px"
                                            hide-details
                                            dense
                                        />
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-container
                            fluid
                            class="ma-0 pa-0 fill-height pb-4 align-content-space-between"
                            style="overflow-y: auto"
                        >
                            <v-divider></v-divider>
                            <v-row class="ma-0 pa-0" >
                                <v-col :cols="12">
                                    <h3 class="title">{{ $t('heading.assigned-roles') }}</h3>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col
                                    :key="roleField.id" v-for="roleField in shiftCommsRoles"
                                    :md="6"
                                    :lg="4"
                                    :xl="3"
                                    :sm="12"
                                    :cols="12"
                                    class="pa-0"
                                >
                                    <transition name="fade" appear>
                                        <role-card
                                            :role-value='roleField'
                                            :isReadOnly="isCurrentShiftOwner === false"
                                            :isActive="dropTargetId === roleField.fieldId"
                                            :possible-staff-names="staffNamesAssignableToRoles"
                                            @addStaff="onAddStaffToRole"
                                            @inActiveZone="onAddRoleDropTarget"
                                            @outOfActiveZone="onRemoveDropTarget" />
                                    </transition>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <template v-for="(group, index) in groupedEquipment">
                                <v-row class="ma-0 pa-0" :key="index" v-if="group.data.length > 0">
                                    <v-col :cols="12">
                                        <h5 class="title">{{ $t(group.translationKey) }}</h5>
                                        <v-divider></v-divider>
                                    </v-col>
                                    <v-col
                                        :key="equip.id" v-for="equip in group.data"
                                        :md="6"
                                        :lg="4"
                                        :xl="3"
                                        :sm="12"
                                        :cols="12"
                                        class="pa-0"
                                    >
                                        <transition name="fade" appear>
                                            <equipment-card
                                                :equipment="equip"
                                                :shiftId="shiftId"
                                                :isReadOnly="isCurrentShiftOwner === false"
                                                :isActive="dropTargetId === equip.id"
                                                :is-in-workshop="equipmentInWorkshop.includes(equip.equipmentId)"
                                                :is-needed-in-workshop="equipmentToWorkshop.includes(equip.equipmentId)"
                                                @inActiveZone="onAddEquipmentDropTarget"
                                                @outOfActiveZone="onRemoveDropTarget"
                                                @servicesClick="onOpenEquipmentServices"
                                                @hazardsClick="onOpenEquipmentHazards"
                                                @inWorkshopClick="toggleInWorkshop"
                                                @neededInWorkshopClick="toggleNeededInWorkshop"
                                                @addStaff="onAddStaffToEquipment"
                                                @removeStaffMember="onRemoveStaffFromEquipment"
                                                @addSecondaryEquipment="onAddSecondaryEquipmentToEquipment"
                                                @removeSecondaryEquipment="onRemoveSecondaryEquipmentFromEquipment"
                                                @changeEquipmentLocation="setEquipLocationFor"
                                                @changeEquipmentComment="setEquipCommentsFor"
                                            />
                                        </transition>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-container>
                    </v-col>
                    <v-col v-if="isCurrentShiftOwner" :cols="2" :md="1" class="full-height px-2 mid-col clear-bottom">
                        <v-list dense class="pt-0">
                            <v-subheader>{{ $t('heading.secondary-equipment') }}</v-subheader>
                            <v-text-field
                                outlined
                                v-model="secondaryEquipmentFilter"
                                :label="$t('shared.label.filter')"
                                clearable
                                class="mb-2"
                                hide-details
                                dense
                            />

                            <div v-for="(group, index) in filteredSortedEquipment" :key="index">
                                <h3 class="subtitle-2" v-if="group.equipment.length">{{ group.equipmentRole.name }}</h3>
                                <drag
                                    @dragstart="startDrag"
                                    @dragend="endDrag"
                                    @touchdrop="onEquipmentTouchDrop(...arguments)"
                                    class="drag"
                                    v-for="equip in group.equipment"
                                    :key="equip.id"
                                    :transferData="{ type: 'equipment', equipment: equip }"
                                >
                                    <transition name="fade" appear>
                                    <v-list-item :id="equip.id" class="py-0 px-0 clickable draggable equiplist mb-1">
                                        <v-list-item-title class="overline px-0">
                                            <v-img
                                                :src="equip.imageUrl"
                                                :max-width="60"
                                                :max-height="42"
                                                contain
                                                class="avatar-image"
                                            />
                                            <span class="equipname">{{ equip.name }}</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                    </transition>
                                </drag>
                            </div>
                        </v-list>
                    </v-col>
                </v-row>
                <equipment-availability-dialog
                    v-if="selectedEquipmentAvailability != null"
                    :shiftId="shiftId"
                    :model="selectedEquipmentAvailability"
                    :isReadOnly="!isCurrentShiftOwner"
                    @cancel="selectedEquipmentAvailability = null"
                    @saved="onSaveAvailability"
                />
                <add-crew-role @selectRole="onAdditionalRoleSelected" ref="addCrewRole" />
                <add-any-staff
                    @addNewStaff="onAddNewStaffMember"
                    @selectStaff="onAdditionalStaffSelected"
                    ref="addAnyStaff"
                />
                <view-leave-overtime
                    :readonly="!isCurrentShiftOwner"
                    @scheduleUnplannedLeave="onUnplannedLeaveScheduled"
                    ref="viewLeaveOvertime"
                />
                <shift-printer
                    :shiftId="shiftId"
                    :departmentId="$route.params.dept"
                    :departmentName="departmentName"
                    :date="$route.params.date"
                    :shift="$route.params.shift"
                />
                <shift-reports
                    :shiftId="shiftId"
                    :departmentId="$route.params.dept"
                    :departmentName="departmentName"
                    :date="$route.params.date"
                    :shift="$route.params.shift"
                />
                <equipment-shift-hazard-notes-dialog
                    v-if="selectedHazardEquipment != null"
                    :equipment="selectedHazardEquipment"
                    :shift-id="shiftId"
                    @close="onCloseEquipmentHazards"
                >
                </equipment-shift-hazard-notes-dialog>
            </v-container>
        </template>
    </shift-loader>
</template>

<i18n>
{
    "en": {
        "button": {
            "add-crew-role": "Add Crew Role",
            "copy-previous": "Copy Previous",
            "clear-staff": "Clear Staff"
        },
        "heading": {
            "assigned-resources": "Assigned Resources",
            "assigned-roles": "Assigned Roles",
            "crews": "Crews",
            "primary-equipment": "Primary Equipment",
            "resources": "Resources",
            "secondary-equipment": "Secondary Equipment",
            "support-equipment": "Support Equipment"
        },
        "label": {
            "no-tasks": "No Tasks",
            "unassigned": "Unassigned"
        },
        "message": {
            "no-crew-selected": "No crew selected",
            "clear-staff-confirmation": "Cleared all assigned staff from equipment.",
            "clear-staff-confirm-prompt": "Are you sure you want to clear all staff that has been assigned to equipment?",
            "no-secondary-equipment-assigned": "No Secondary Equipment Assigned",
            "nothing-to-copy": "Nothing to copy - No previous shift found for this department with the same crews selected: {crewNames}",
            "copy-previous": "Allocations copied from previous shift"
        }
    },
    "es": {
        "button": {
            "add-crew-role": "Añadir función para el personal",
            "copy-previous": "Copiar anterior"
        },
        "heading": {
            "assigned-resources": "Recursos asignados",
            "assigned-roles": "Funciones asignadas",
            "crews": "Personal de turno",
            "primary-equipment": "Equipo primario",
            "resources": "Recursos",
            "secondary-equipment": "Equipo secundario",
            "support-equipment": "Equipo de soporte"
        },
        "label": {
        },
        "message": {
            "no-secondary-equipment-assigned": "Equipo secundario no ha sido asignado"
        }
    }
}
</i18n>

<script>
import { EventBus, Events } from '@/lib/EventBus';
import MineAreas from '@/lib/data/MineAreas';
import Staff from '@/lib/data/Staff';
import StaffRoles from '@/lib/data/StaffRoles';
import PlannedEquipment from '@/lib/data/PlannedEquipment';
import Shift from '@/lib/data/Shift';
import WeekCrews from '@/lib/data/WeekCrews';
import CurrentShiftStore from '@/lib/stores/CurrentShiftStore';
import AddCrewRole from './Dialogs/AddCrewRole.vue';
import AddAnyStaff from './Dialogs/AddAnyStaff.vue';
import ViewLeaveOvertime from './Dialogs/ViewLeaveOvertime.vue';
import EquipmentRoles from '@/lib/data/EquipmentRoles';
import EquipmentAvailabilityDialog from './SubComponents/EquipmentAvailabilityDialog.vue';
import EquipmentAvailability from '@/lib/data/EquipmentAvailability';
import UserStore from '@/lib/stores/UserStore';
import ModelBuilder from '@/lib/ModelBuilder';
import { showToastMessage } from '@/lib/Toast';
import { IsPrimary, IsSecondary, IsSupport, SortByRoleThenName } from '@/lib/services/Equipment';
import { useDepartmentStore } from '@/lib/stores/DepartmentStore';
import { useShiftWindowStore } from '@/lib/stores/ShiftWindowStore';
import { mapActions, mapState } from 'pinia';
import { useHeadingsStore } from '@/lib/stores/HeadingsStore';
import { useMineAreaStore } from '@/lib/stores/MineAreaStore';
import { useSystemStore } from '@/lib/stores/SystemStore';
import { useShiftCrew, useShiftEquipment, useShiftDetails, useShiftCommunication } from '@/lib/stores/Shift';
import { populateStoresForShift } from '@/lib/stores/DataManager';
import ShiftMember from '@/components/ShiftBoard/Resources/ShiftMember.vue';
import EquipmentCard from '@/components/ShiftBoard/Resources/EquipmentCard.vue';
import ShiftLoader from '@/components/ShiftBoard/ShiftLoader.vue';
import _ from 'lodash';
import { ShiftUtilisationPercentageDisplayGroup } from '@/models/client/shift-utilisation-percentage-display';
import EquipmentShiftHazardNotesDialog from '@/components/Shared/EquipmentShiftHazardNotesDialog.vue';
import { CommsFieldDataType, CommsFieldTemporalLevel, UpdateShiftCommunicationsCommand } from '@/models/api';
import { DropTargetType } from '@/components/ShiftBoard/Resources/models';
import RoleCard from '@/components/ShiftBoard/Resources/RoleCard.vue';

export default {
    components: {
        RoleCard,
        EquipmentShiftHazardNotesDialog,
        AddCrewRole,
        AddAnyStaff,
        ViewLeaveOvertime,
        EquipmentAvailabilityDialog,
        EquipmentCard,
        ShiftMember,
        ShiftLoader,
    },
    data() {
        return {
            dragging: false,
            dropTarget: null,
            dragElem: null,
            selectedShiftCrew: null,
            selectedEquipmentAvailability: null,
            mine: null,
            equipmentFilter: '',
            secondaryEquipmentFilter: '',
            hideUnavailableEquipment: true,
            copyPreviousAllocationsInfo: null,
            copyPreviousAllocationsMenu: false,
            selectedHazardEquipment: null,
        };
    },
    watch: {
        utilisationStatsDisplay(newValue, oldValue){
            EventBus.$emit(Events.ShowStats, this.utilisationStatsDisplay);
        }
    },
    computed: {
        ...mapState(useDepartmentStore, ['departmentName', 'shiftOwnerId']),
        ...mapState(useHeadingsStore, ['getTasksWithEquipmentByShift']),
        ...mapState(useShiftDetails, ['shiftId', 'weekDate', 'primaryEquipmentUtilisation', 'secondaryEquipmentUtilisation', 'supportEquipmentUtilisation', 'crewUtilisation']),
        ...mapState(useShiftCommunication, ['shiftCommunication']),
        ...mapState(useMineAreaStore, [
            'firstShiftStartsAt',
            'numberOfShiftsPerDay',
            'availableShifts',
            'firstDayOfWeek',
            'locale',
            'isSandpit'
        ]),
        ...mapState(useSystemStore, [
            'staffMembers',
            'equipmentRoles',
            'staffRoles',
            'getStaffRoleById',
            'getStaffById',
            'isOnline',
        ]),
        ...mapState(useShiftEquipment, [
            'equipment',
            'equipmentById',
            'getSecondaryEquipmentByEquipment',
            'getStaffForEquipment',
            'equipmentInWorkshop',
            'equipmentToWorkshop'
        ]),
        ...mapState(useShiftCrew, ['crewIds', 'weekCrews', 'roles', 'staffOnShift']),
        crewIdToNameMap() {
            const map = {};

            this.weekCrews.forEach((weekCrew) => {
                map[weekCrew.crew.id] = weekCrew.crew.name;
            });

            return map;
        },
        getMembersForRoleByCrew(roleId) {
            return (roleId) => {
                const staffWithMatchingRole = this.staffOnShift.filter((x) => x.roleId === roleId);
                const staffForRoleByCrewId = _.groupBy(staffWithMatchingRole, 'crewId');
                return Object.keys(staffForRoleByCrewId).map((x) => {
                    return { id: x, name: this.crewIdToNameMap[x], staff: staffForRoleByCrewId[x] };
                });
            };
        },
        noCrewSelected() {
            return this.crewIds.length === 0;
        },
        wrappedEquipment() {
            return this.equipment.map((x) => {
                const wrapped = {
                    ...x,
                    secondaryEquipment: this.getSecondaryEquipmentByEquipment(x.id),
                    staff: this.getStaffForEquipment(x.id),
                    tasks: this.getTasksWithEquipmentByShift(x.id, x.equipmentId),
                };
                return wrapped;
            });
        },
        groupedEquipment() {
            return [
                { name: 'Primary Equipment', translationKey: 'heading.primary-equipment', data: this.primaryEquip },
                {
                    name: 'Secondary Equipment',
                    translationKey: 'heading.secondary-equipment',
                    data: this.secondaryEquip,
                },
                { name: 'Support Equipment', translationKey: 'heading.support-equipment', data: this.supportEquip },
            ];
        },
        primaryEquip() {
            if (!this.wrappedEquipment) return [];
            var searchFilter = (this.equipmentFilter || '').toLowerCase();
            var equipment = this.wrappedEquipment.filter((x) => IsPrimary(x) && this.filterEquipment(x, searchFilter));
            return equipment.sort(SortByRoleThenName);
        },
        secondaryEquip() {
            if (!this.wrappedEquipment) return [];
            var searchFilter = (this.equipmentFilter || '').toLowerCase();
            var equipment = this.wrappedEquipment.filter(
                (x) => IsSecondary(x) && this.filterEquipment(x, searchFilter)
            );
            return equipment.sort(SortByRoleThenName);
        },
        supportEquip() {
            if (!this.wrappedEquipment) return [];
            var searchFilter = (this.equipmentFilter || '').toLowerCase();
            var equipment = this.wrappedEquipment.filter((x) => IsSupport(x) && this.filterEquipment(x, searchFilter));
            return equipment.sort(SortByRoleThenName);
        },
        crewSelect() {
            if (this.weekCrews == null) {
                return [];
            }
            let infoMsg = [];
            infoMsg.push({
                header: 'Changing crews will clear assigned roles',
            });
            infoMsg.push({
                divider: true,
            });
            let crewSelects = this.weekCrews.map((x) => ({ id: x.id, name: x.crew.name }));
            return infoMsg.concat(crewSelects);
        },
        filteredSortedEquipment() {
            var groups = {};

            for (let x in this.equipmentRoles) {
                if (this.equipmentRoles.hasOwnProperty(x)) {
                    groups[this.equipmentRoles[x].id] = { equipmentRole: this.equipmentRoles[x], equipment: [] };
                }
            }

            groups['Unknown'] = {
                equipmentRole: {
                    name: 'Uncategorised',
                },
                equipment: [],
            };

            var searchFilter = (this.secondaryEquipmentFilter || '').toLowerCase();
            for (let equip of this.wrappedEquipment) {
                if (IsPrimary(equip)) continue;
                if (!this.filterEquipment(equip, searchFilter)) continue;
                if (equip.role.id && groups[equip.role.id]) {
                    groups[equip.role.id].equipment.push(equip);
                } else {
                    groups['Unknown'].equipment.push(equip);
                }
            }

            var ret = [];
            for (let x in groups) {
                ret.push(groups[x]);
            }

            return ret;
        },
        staffHasBeenAssignedToEquipment() {
            return this.wrappedEquipment.some((x) => x.staffIds.length > 0);
        },
        showClearEquipmentAssignment() {
            return this.crewIds.length > 0 && this.staffHasBeenAssignedToEquipment;
        },
        utilisationStatsDisplay() {
            return {
                groupTitle: 'Resource Utilisation',
                utilisationFigures: [
                    {
                        ... this.crewUtilisation,
                        helpText: 'Percentage utilisation of crew-members in this shift',
                        shortTitle: 'C'
                    },
                    {
                        ... this.primaryEquipmentUtilisation,
                        helpText: 'Percentage utilisation of primary equipment in this shift',
                        shortTitle: 'P'
                    },
                    {
                        ... this.secondaryEquipmentUtilisation,
                        helpText: 'Percentage utilisation of secondary equipment in this shift',
                        shortTitle: 'S'
                    },
                    {
                        ... this.supportEquipmentUtilisation,
                        helpText: 'Percentage utilisation of support equipment in this shift',
                        shortTitle: 'Sp'
                    }
                ]
            };
        },
        dropTargetId() {
            return this.dropTarget?.id ?? null;
        },
        shiftCommsRoles() {
            return this.shiftCommunication.fieldValues.filter(x=>x.fieldTemporalLevel == CommsFieldTemporalLevel.Shift && x.fieldDataType == CommsFieldDataType.Role).sort((a,b)=>a.fieldDisplayOrder-b.fieldDisplayOrder);
        },
        toggleUnavailableLabel(){
            return this.hideUnavailableEquipment ? 'Show Unavailable Equipment' : 'Hide Unavailable Equipment';
        },
        staffNamesAssignableToRoles() {
            return _.uniq(this.staffMembers?.filter((x) => !x.isArchived).map(x=>x.name) ?? []).sort((a,b)=>a.localeCompare(b));
        },
    },
    methods: {
        ...mapActions(useShiftEquipment, [
            'addStaffToEquipment',
            'removeSecondaryEquipment',
            'addSecondaryEquipment',
            'setEquipmentLocation',
            'setEquipmentComments',
            'removeAllStaffFromEquipment',
            'removeStaffFromEquipment',
            'markEquipmentAsInWorkshop',
            'markEquipmentAsNotInWorkshop',
            'markEquipmentAsNeededInWorkshop',
            'markEquipmentAsNotNeededInWorkshop',
        ]),
        ...mapActions(useShiftCrew, [
            'changeSelectedCrews',
            'addStaffMemberToShift',
            'addRoleToShift',
            'removeStaffMemberFromShift',
            'calculateStaffUtilisation',
        ]),
        ...mapActions(useSystemStore, ['scheduleUnplannedLeave', 'addStaffMember']),
        ...mapActions(useShiftDetails, ['populateCrewAndEquipmentUtilisationScores']),
        ...mapActions(useShiftCommunication, ['getShiftCommunication', 'updateShiftCommunication']),
        onOpenEquipmentServices(equipmentId) {
            this.selectedEquipmentAvailability = this.equipmentById(equipmentId);
        },
        onOpenEquipmentHazards(equipmentId) {
            this.selectedHazardEquipment = this.equipmentById(equipmentId);
        },
        onCloseEquipmentHazards() {
            this.selectedHazardEquipment = null;
        },
        async toggleInWorkshop(equipmentId, inWorkshop) {
            if (inWorkshop) {
                await this.markEquipmentAsInWorkshop(equipmentId);
            } else {
                await this.markEquipmentAsNotInWorkshop(equipmentId);
            }
        },
        async toggleNeededInWorkshop(equipmentId, neededInWorkshop) {
            if (neededInWorkshop) {
                await this.markEquipmentAsNeededInWorkshop(equipmentId);
            } else {
                await this.markEquipmentAsNotNeededInWorkshop(equipmentId);
            }
        },
        filterEquipment(e, searchFilter) {
            if (e.idleOrUnavailable) return false;
            if(this.hideUnavailableEquipment && e.availability && e.availability.length > 0 ) return false;
            if (!searchFilter) return true;
            if ((e.name || '').toLowerCase().indexOf(searchFilter) > -1) return true;
            if (!e.role) return true;
            if ((e.role.name || '').toLowerCase().indexOf(searchFilter) > -1) return true;
        },
        onAddRoleDropTarget(roleId) {
            setTimeout(() => {
                this.dropTarget = { type: DropTargetType.ROLE, id: roleId };
            }, 1);
        },
        onAddEquipmentDropTarget(equipmentId) {
            setTimeout(() => {
                this.dropTarget = { type: DropTargetType.EQUIPMENT, id: equipmentId };
            }, 1);
        },
        onRemoveDropTarget(targetId) {
            if (this.dropTarget === targetId) {
                setTimeout(() => {
                    this.dropTarget = null;
                }, 1);
            }
        },
        debouncedUpdateShiftSelectedCrews: _.debounce(function(crewIds) {
            this.updateShiftSelectedCrews(crewIds);
        }, 500),
        async updateShiftSelectedCrews(crewIds){

            this.$wait.start('set_selected_crews');
            try{
                await this.changeSelectedCrews(crewIds);
            }finally{
                this.$wait.end('set_selected_crews');
            }

            this.$wait.start('copy equipment allocations - info');
            try{
                await this.refreshCopyPreviousAllocationsInfo();
            } finally {
                this.$wait.end('copy equipment allocations - info');
            }
        },
        clearDropTarget($event) {
            this.dropTarget = null;
        },
        async refreshCopyPreviousAllocationsInfo() {
            if(this.crewIds.length === 0) {
                this.copyPreviousAllocationsInfo = null;
            } else {
                this.copyPreviousAllocationsInfo = await WeekCrews.getShiftEquipmentCopyInfo(this.shiftId, this.crewIds);
            }
        },
        async copyPreviousAllocation() {
            this.copyPreviousAllocationsMenu = false;

            if (this.copyPreviousAllocationsInfo == null || !this.copyPreviousAllocationsInfo.canCopy) {
                return;
            }

            try {
                this.$wait.start('copy equipment allocations');
                await WeekCrews.copyAllocationsFromPreviousShift(this.shiftId, this.copyPreviousAllocationsInfo.copyShiftId);
                EventBus.$emit(Events.ShiftRefreshRequested);
                EventBus.$emit(Events.ToastSuccess, this.$t('message.copy-previous'));
            } catch (e) {
                EventBus.$emit(Events.ToastError, e);
            } finally {
                this.$wait.end('copy equipment allocations');
            }
        },
        async loadData() {
            try {
                this.$wait.start('get data');

                this.mine = {
                    numberOfShiftsPerDay: this.numberOfShiftsPerDay,
                    firstShiftStartsAt: this.firstShiftStartsAt,
                    availableShifts: this.availableShifts,
                    firstDayOfWeek: this.firstDayOfWeek,
                    locale: this.locale,
                    isSandpit: this.isSandpit
                };

                this.calculateStaffUtilisation(false);
                await this.refreshCopyPreviousAllocationsInfo();

                // TODO: The mine area is loaded asynchronously so the page will display English
                // labels briefly until it switches to the mine's language.
                this.$root.$i18n.locale = this.mine?.locale ?? 'en';
            } finally {
                this.$wait.end('get data');
            }
        },
        addCrewRole() {
            const rolesNotInTemplate = this.staffRoles.filter(
                (role) => this.roles.some((shiftRole) => shiftRole.id === role.id) === false
            );
            this.$refs.addCrewRole.show(this.$route.params.dept, this.shiftId, this.crewIds, rolesNotInTemplate);
        },
        async clearEquipmentAssignment() {
            let confirmResult = confirm(this.$t('message.clear-staff-confirm-prompt'));
            if (!confirmResult) {
                return;
            }

            try {
                this.$wait.start('clear equipment assignment');
                var command = {
                    departmentShiftId: this.shiftId,
                };

                this.removeAllStaffFromEquipment();
                var result = await WeekCrews.clearEquipmentAssignmentsForShift(command);

                EventBus.$emit(Events.ToastSuccess, this.$t('message.clear-staff-confirmation'));
            } catch (e) {
                return;
            } finally {
                this.$wait.end('clear equipment assignment');
            }
        },
        startDrag() {
            this.dragging = true;
        },
        endDrag() {
            this.dragging = false;
            setTimeout(() => this.clearDropTarget(), 1);
        },
        async onUnplannedLeaveScheduled(staffId, items) {
            await this.scheduleUnplannedLeave(staffId, items);
        },
        async onAddSecondaryEquipmentToEquipment(equipment, secondaryEquipmentId) {
            await this.addSecondaryEquipment(equipment.id, secondaryEquipmentId);
            await this.calculateStaffUtilisation(true);
        },
        async onRemoveSecondaryEquipmentFromEquipment(equipment, secondaryEquipmentId) {
            await this.removeSecondaryEquipment(equipment.id, secondaryEquipmentId);
            await this.calculateStaffUtilisation(true);
        },
        async onEquipmentTouchDrop(data) {
            await this.addSecondaryEquipment(this.dropTarget.id, data.equipment.id);
        },
        async onStaffTouchDrop(data) {
            if(this.dropTarget == null)
                return;

            if(this.dropTarget.type === DropTargetType.ROLE){
                await this.onStaffDroppedOnRole(data, this.dropTarget.id);
            } else if(this.dropTarget.type === DropTargetType.EQUIPMENT){
                await this.onStaffDroppedOnEquipment(data, this.dropTarget);
            }
        },
        async onStaffDroppedOnEquipment(data, dropTarget) {
            await this.addStaffToEquipment(dropTarget.id, data.staff.id);
            await this.calculateStaffUtilisation(true);
        },
        async onStaffDroppedOnRole(data, roleFieldId) {
            await this.onAddStaffToRole(roleFieldId, data.staff.name);
        },
        async onAdditionalRoleSelected(roleId) {
            await this.addRoleToShift(roleId);
        },
        async onAddNewStaffMember(staffMember) {
            await this.addStaffMember({ ... staffMember, isSandpitUseOnly: staffMember.crew.crew.isSandpitUseOnly });
            await this.addStaffMemberToShift(staffMember.id, staffMember.staffRoleId);
        },
        async onAdditionalStaffSelected(staffId, roleId) {
            await this.addStaffMemberToShift(staffId, roleId);
        },
        async onAddCrewMember(staffMember) {
            await this.addStaffMemberToShift(staffMember.id, staffMember.staffRoleId);
        },
        async onRemoveCrewMember(staffMember) {
            await this.removeStaffMemberFromShift(staffMember.id);
        },
        async onAddStaffToRole(roleFieldId, staffName) {
            var command = {
                _type: 'UpdateShiftCommunicationsCommand',
                isOfflineReplay: false,
                attemptedAt: new Date(),
                shiftId: this.shiftId,
                fieldValues: [{commsFieldId: roleFieldId, value: staffName, _type: 'UpdateCommsFieldDto'}],
            };
            await Shift.updateShiftCommunications(ModelBuilder.Commands.UpdateShiftCommunicationsCommand(command));
            this.updateShiftCommunication(command);
        },
        async onAddStaffToEquipment(equipmentId, staffId) {
            await this.addStaffToEquipment(equipmentId, staffId);
            await this.calculateStaffUtilisation(true);
        },
        async onRemoveStaffFromEquipment(equipmentId, staffId) {
            await this.removeStaffFromEquipment(equipmentId, staffId);
            await this.calculateStaffUtilisation(true);
        },
        async setEquipLocationFor(equipmentId, location) {
            await this.setEquipmentLocation(equipmentId, location);
        },
        async setEquipCommentsFor(equipmentId, comment) {
            await this.setEquipmentComments(equipmentId, comment);
        },
        manuallyAddCrew(role) {
            this.$refs.addAnyStaff.show(
                this.$route.params.dept,
                this.shiftId,
                this.crewIds,
                role,
                this.staffMembers.filter(s=>!s.isArchived && this.mine.isSandpit === s.isSandpitUseOnly),
                this.weekCrews,
                this.staffRoles.filter(sr=>this.mine.isSandpit === sr.isSandpitUseOnly),
                this.$route.params.date
            );
        },
        viewLeaveFor(member, alwaysLookAtStaffArray) {
            var staffAvailability = member.staffAvailability;
            if ((alwaysLookAtStaffArray || !staffAvailability) && this.staffMembers) {
                for (let x of this.staffMembers) {
                    if (x.id == member.id) {
                        staffAvailability = x.staffAvailability;
                        break;
                    }
                }
            }
            this.$refs.viewLeaveOvertime.show(
                member,
                staffAvailability || [],
                this.weekDate,
                this.mine,
                this.$route.params.date,
                this.$route.params.shift
            );
        },

        async onSaveAvailability() {
            this.selectedEquipmentAvailability = null;
        },
        enablePageFeatures() {
            EventBus.$emit(Events.ShowStats, this.utilisationStatsDisplay);
        },
        disablePageFeatures() {
            EventBus.$emit(Events.ShowStats, null);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/variables.scss';
@media print {
    .page-layout > .col-6 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    .v-card {
        display: block;
        page-break-inside: avoid !important;
    }
    .v-card .row {
        display: flex;
    }
}

.clear-bottom {
    padding-bottom: 80px !important;
}

.dropMagnet {
    background-color: #f2f2f2;
}
.mid-col {
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
}
.highlighted {
    box-shadow: 0px 0px 2px 2px #3333;
}
.striped {
    background-color: $stripe-color;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>
