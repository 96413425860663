<template>
    <div class="px-1 pb-1 commentContainer">
        <v-btn icon :color="data ? 'primary' : ''" @click="dialog = true">
            <v-icon>mdi-message-text</v-icon>
        </v-btn>
        <div v-if="data" class="commentPreview">{{ data }}</div>
        <standard-dialog v-model='dialog' :max-width="400" persistent>
            <template v-slot:title>
                {{ $t('heading.comments-for', { name }) }}
            </template>
            <template v-slot:content>
                <v-form ref="form" :lazy-validation="true">
                    <v-textarea :disabled="readonly" :readonly="readonly" v-model="value" :clearable="!readonly" />
                </v-form>
            </template>
            <template v-slot:actions>
                <v-spacer></v-spacer>
                <v-btn color="gray" text @click="dialog = false">{{ $t('shared.button.cancel') }}</v-btn>
                <v-btn v-if="!readonly" color="primary" text @click="save()">{{ $t('shared.button.save') }}</v-btn>
            </template>
        </standard-dialog>
    </div>
</template>
<i18n>
{
    "en": {
        "heading": {
            "comments-for": "Comments for {name}"
        }
    },
    "es": {
        "heading": {
            "comments-for": "Comentarios para {name}"
        }
    }
}
</i18n>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: ['data', 'name', 'readonly'],
    data() {
        return { dialog: false, value: this.data };
    },
    methods: {
        save() {
            this.dialog = false;
            this.$emit('save', this.value);
        },
    },
    watch: {
        data() {
            this.value = this.data;
        },
    },
});
</script>

<style lang="scss" scoped>
.commentContainer {
    position: relative;
}
.commentPreview {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 80%;
    line-height: 36px;
    color: #666;
    font-size: 14px;
    vertical-align: bottom;
}
</style>
