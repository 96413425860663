import AppHttp from '@/lib/AppHttp';
import Errors from '@/lib/Errors';
import { AxiosError } from 'axios';

class PowerBIService {
    public async getReportEmbedConfig(reportName: string) {
        const response = await AppHttp.get(`Reports/GetEmbedReport/${reportName}`);
        const data = response ? response.data : null;
        return data;
    }
}

export default new PowerBIService();