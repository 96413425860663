import { defineStore } from 'pinia';
import ClientRowModel from '@/models/client/client-row';
import {
    CommentaryViewModel,
    CreateShiftSnapshotCommand,
    RateTaskActualViewModel,
    RecordShiftSnapshotCommand,
    ShiftCrewMemberViewModel,
    ShiftLocationDetailViewModel,
    ShiftLocationsViewModel,
    TimeUnit,
    UpdateShiftLocationDetailsCommand,
    UpdateLocationHazardsAtShiftCommand,
    ShiftHazardNoteViewModel,
    AddLocationHazardsAtShiftCommand, ShiftLocationVarianceToPlanModel, TaskCommunications
} from '@/models/api';
import Shift from '@/lib/data/Shift';
import dayjs, { Dayjs, UnitTypeLong } from 'dayjs';
import { useSystemStore } from '../SystemStore';
import { SupervisorReportViewModel } from '@/models/api';
import ModelBuilder from '@/lib/ModelBuilder';
import { useHeadingsStore } from '@/lib/stores/HeadingsStore';
import { transformRowsToOfflineRows } from '@/lib/stores/Transforms';
import { NextShiftDetails, ShiftDetails } from '../ShiftWindowStore';
import Logging from '@/lib/Logging';
import LocationDetails from '@/lib/data/LocationDetails';
import { useMineAreaStore } from '@/lib/stores/MineAreaStore';
import _ from 'lodash';
import { DateFormat } from '@/plugins/dates';
import UserStore from '@/lib/stores/UserStore';
import { ClientAddHazardsCommand } from '@/models/client/Commands/client-add-hazards-command';
import { ClientUpdateHazardsCommand } from '@/models/client/Commands/client-update-hazards-command';
import { AddToExistingHazards, UpdateExistingHazards } from '@/lib/services/Hazards';

export interface UtilisationInformation {
    utilisationPercentage: number | null,
    changeFromPrevious: null | number
}

function calculateUtilisation(calculateChangeFromPrevious: boolean, newScore: number | null | undefined, previousUtilisation: UtilisationInformation){
    return {
        changeFromPrevious: calculateChangeFromPrevious && previousUtilisation.utilisationPercentage != null && newScore != null ? newScore - previousUtilisation.utilisationPercentage : null,
        utilisationPercentage: newScore ?? null
    };
}

function defaultUtilisation(): UtilisationInformation{
    return {
        changeFromPrevious: null,
        utilisationPercentage: null
    }
}


export const useShiftDetails = defineStore('shift', {
    state: () => {
        return {
            hasWeekPlan: false,
            _hasShiftPlan: false,
            hasActuals: false,
            _shiftId: '' as string,
            _weekId: '' as string,
            deploymentMeetingId: null as string | null,
            handoverMeetingId: null as string | null,
            timeUnits: [] as TimeUnit[],
            shiftName: '?' as string,
            nextShiftDetails: [] as NextShiftDetails[],
            priority1LocationsUtilisation: defaultUtilisation(),
            priority2LocationsUtilisation: defaultUtilisation(),
            lowerPriorityLocationsUtilisation: defaultUtilisation(),
            primaryEquipmentUtilisation: defaultUtilisation(),
            secondaryEquipmentUtilisation: defaultUtilisation(),
            supportEquipmentUtilisation: defaultUtilisation(),
            crewUtilisation: defaultUtilisation(),
            individualStaffUtilisation: {} as { [key: string]: number; },
            individualEquipmentUtilisation: {} as { [key: string]: number; },
            _mineAreaId: null as string | null,
            _departmentId: null as string | null,
            _shiftNumber: null as string | null,
            _date: null as string | null,
            supervisorShiftReport: null as SupervisorReportViewModel | null,
            weekDate: null as string | null,
            locationsForShift: null as ShiftLocationsViewModel | null,
            productionActuals: [] as RateTaskActualViewModel[],
        };
    },
    getters: {
        shiftId(): string {
            if (this._shiftId === '') {
                throw new Error('Shift Id is empty and has not been set correctly');
            }

            return this._shiftId;
        },
        weekId(): string {
            if (this._weekId === '') {
                throw new Error('Shift Week Id is empty and has not been set correctly');
            }

            return this._weekId;
        },
        shiftStartTime(): Dayjs {
            return dayjs.utc(this.timeUnits[0].startTime);
        },
        shiftEndTime(): Dayjs {
            const indexLastTimeUnit = this.timeUnits.length - 1;
            // @ts-ignore
            return dayjs.utc(this.timeUnits[indexLastTimeUnit].endTime);
        },
        lastShiftIn24HoursEndTime(): Dayjs {
            const indexLastTimeUnit = this.timeUnits24Hours.length - 1;
            // @ts-ignore
            return dayjs.utc(this.timeUnits24Hours[indexLastTimeUnit].endTime);
        },
        shiftDurationInMinutes(): number {
            return this.shiftEndTime.diff(this.shiftStartTime, 'minutes');
        },
        shiftDurationInHours(): number {
            return this.shiftEndTime.diff(this.shiftStartTime, 'hours');
        },
        weekStartTime(): Dayjs {
            return useMineAreaStore().getFirstShiftOnDay(dayjs.utc(this.weekDate));
        },
        previousShiftStartTime(): Dayjs {
            return this.previousShiftEndTime.add(-1 * this.shiftDurationInMinutes, 'minutes');
        },
        previousShiftEndTime(): Dayjs {
            return this.shiftStartTime;
        },
        timeUnits24Hours(): TimeUnit[] {
            return [... this.timeUnits, ..._.flatMap(this.nextShiftDetails, x=>x.timeUnits)];
        },
        timeSlots(): string[] {
            return this.timeUnits.map((x) => x.name!);
        },
        timeSlots24Hours(): string[] {
            return this.timeUnits24Hours.map((x) => x.name!);
        },
        hourlyTimeSlots(): string[] {
            return this.timeSlots.filter((_x, i) => i % 4 == 0);
        },
        hourlyTimeSlots24Hours(): string[] {
            return this.timeSlots24Hours.filter((_x, i) => i % 4 == 0);
        },
        shiftNames24Hours(): string []{
            return [`${this.shiftName} / ${dayjs(this.shiftStartTime).format(DateFormat.WeekDisplay)}`, ...this.nextShiftDetails.map(x=>`${x.shiftName} / ${dayjs(this.shiftStartTime).format(DateFormat.WeekDisplay)}`)];
        },
        snapshotModel(): { id: string; rows: ClientRowModel[]; shiftStartTime: string } {
            const headings = useHeadingsStore().headings;
            return { id: this.shiftId, rows: headings, shiftStartTime: this.shiftStartTime.toISOString() };
        },
    },
    actions: {
        setupEmptyShift(hasWeekPlan: boolean, hasShiftPlan: boolean) {
            this._hasShiftPlan = hasShiftPlan;
            this.hasWeekPlan = hasWeekPlan;
        },
        async setUpStore(details: ShiftDetails, shiftSupervisorReport: SupervisorReportViewModel | null) {
            this._hasShiftPlan = details.hasShiftPlan;
            this.hasWeekPlan = details.hasWeekPlan;

            this._shiftId = details.shiftId;
            this._weekId = details.weekId;
            this.timeUnits = details.timeUnits;
            this.nextShiftDetails = details.nextShiftDetails;
            this.shiftName = details.shiftName;

            this.locationsForShift = details.locationsForShift;
            this.hasActuals = details.hasActuals;
            this.deploymentMeetingId = details.deploymentMeetingId;
            this.handoverMeetingId = details.handoverMeetingId;
            this.weekDate = details.weekDate;
            this.supervisorShiftReport = shiftSupervisorReport;
            this.productionActuals = details.productionActuals;

            this.populateLocationUtilisationScores(false);
        },
        populateCrewAndEquipmentUtilisationScores(calculateChangeFromPrevious: boolean) {
            if (useSystemStore().isOnline) {
                try {
                    Shift.crewAndEquipmentUtilisationScores(this._shiftId).then(result=>{
                        this.primaryEquipmentUtilisation = calculateUtilisation(calculateChangeFromPrevious, result.primaryEquipmentScore, this.primaryEquipmentUtilisation);

                        this.secondaryEquipmentUtilisation = calculateUtilisation(calculateChangeFromPrevious, result.secondaryEquipmentScore, this.secondaryEquipmentUtilisation);
                        this.supportEquipmentUtilisation = calculateUtilisation(calculateChangeFromPrevious, result.supportEquipmentScore, this.supportEquipmentUtilisation);
                        this.crewUtilisation = calculateUtilisation(calculateChangeFromPrevious, result.crewScore, this.crewUtilisation);

                        this.individualEquipmentUtilisation = result.individualEquipmentScores;
                        this.individualStaffUtilisation = result.individualStaffScores;
                    }).catch(e=>{
                        Logging.error(e);
                        // don't block whole page if we fail to get utilisation scores
                        this.primaryEquipmentUtilisation = defaultUtilisation();
                        this.secondaryEquipmentUtilisation = defaultUtilisation();
                        this.supportEquipmentUtilisation = defaultUtilisation();
                        this.crewUtilisation = defaultUtilisation();
                        this.individualEquipmentUtilisation = {};
                        this.individualStaffUtilisation = {};
                    });
                } catch (e) {
                    Logging.error(e);
                    // don't block whole page if we fail to get utilisation scores
                    this.primaryEquipmentUtilisation = defaultUtilisation();
                    this.secondaryEquipmentUtilisation = defaultUtilisation();
                    this.supportEquipmentUtilisation = defaultUtilisation();
                    this.crewUtilisation = defaultUtilisation();
                    this.individualEquipmentUtilisation = {};
                    this.individualStaffUtilisation = {};
                }
            } else {
                this.primaryEquipmentUtilisation = defaultUtilisation();
                this.secondaryEquipmentUtilisation = defaultUtilisation();
                this.supportEquipmentUtilisation = defaultUtilisation();
                this.crewUtilisation = defaultUtilisation();
                this.individualEquipmentUtilisation = {};
                this.individualStaffUtilisation = {};
            }
        },
        populateLocationUtilisationScores(calculateChangeFromPrevious: boolean) {
            if (useSystemStore().isOnline) {
                try {
                    Shift.utilisationScores(this._shiftId).then(result => {
                        this.priority1LocationsUtilisation = {
                            changeFromPrevious: calculateChangeFromPrevious && this.priority1LocationsUtilisation.utilisationPercentage != null && result.priority1LocationsScore != null ? result.priority1LocationsScore - this.priority1LocationsUtilisation.utilisationPercentage : null,
                            utilisationPercentage: result.priority1LocationsScore ?? null
                        };

                        this.priority2LocationsUtilisation = {
                            changeFromPrevious: calculateChangeFromPrevious && this.priority2LocationsUtilisation.utilisationPercentage != null && result.priority2LocationsScore != null ? result.priority2LocationsScore - this.priority2LocationsUtilisation.utilisationPercentage : null,
                            utilisationPercentage: result.priority2LocationsScore ?? null
                        };

                        this.lowerPriorityLocationsUtilisation = {
                            changeFromPrevious: calculateChangeFromPrevious && this.lowerPriorityLocationsUtilisation.utilisationPercentage != null && result.lowerPriorityLocationsScore != null ? result.lowerPriorityLocationsScore - this.lowerPriorityLocationsUtilisation.utilisationPercentage : null,
                            utilisationPercentage: result.lowerPriorityLocationsScore ?? null
                        };
                    }).catch(e=>{
                        Logging.error(e);
                        // don't block whole page if we fail to get utilisation scores
                        this.priority1LocationsUtilisation = defaultUtilisation();
                        this.priority2LocationsUtilisation = defaultUtilisation();
                        this.lowerPriorityLocationsUtilisation = defaultUtilisation();
                    });
                } catch (e) {
                    Logging.error(e);
                    // don't block whole page if we fail to get utilisation scores
                    this.priority1LocationsUtilisation = defaultUtilisation();
                    this.priority2LocationsUtilisation = defaultUtilisation();
                    this.lowerPriorityLocationsUtilisation = defaultUtilisation();
                }
            } else {
                this.priority1LocationsUtilisation = defaultUtilisation();
                this.priority2LocationsUtilisation = defaultUtilisation();
                this.lowerPriorityLocationsUtilisation = defaultUtilisation();
            }
        },
        updateShiftLocationDetails(rowId: string, command: UpdateShiftLocationDetailsCommand) {
            const currentShiftLocations = this.getCurrentShiftLocationDetails(rowId);
            currentShiftLocations.comments = command.comments;
            currentShiftLocations.varianceToPlan = command.varianceToPlan;
            currentShiftLocations.stockpileId = command.stockpileId;
            currentShiftLocations.materialDestinationId = command.materialDestinationId;
            currentShiftLocations.materialTypeId = command.materialTypeId;
            currentShiftLocations.unavailable = command.unavailable;
            //this.validateAll();
        },
        async addShiftLocationHazards(locationId: string, command: ClientAddHazardsCommand){
            const user = await UserStore.getCurrentUser();
            // @ts-ignore
            const madeAtUTC: Date = dayjs.utc();
            const currentShiftLocation = this.getCurrentShiftLocationDetailsByLocationId(locationId);

            currentShiftLocation.hazardNotes = AddToExistingHazards(currentShiftLocation.hazardNotes, command, user?.name || '', madeAtUTC);
        },
        async updateShiftLocationHazards(locationId: string, command: ClientUpdateHazardsCommand){
            const user = await UserStore.getCurrentUser();
            // @ts-ignore
            const madeAtUTC: Date = dayjs.utc();
            const currentShiftLocation = this.getCurrentShiftLocationDetailsByLocationId(locationId);

            currentShiftLocation.hazardNotes = UpdateExistingHazards(currentShiftLocation.hazardNotes, command, user?.name || '', madeAtUTC);
        },
        getCurrentShiftLocationDetails(rowId: string): ShiftLocationDetailViewModel {
            const heading = useHeadingsStore().getHeadingById(rowId);
            return heading.shiftLocationDetails!;
        },
        getCurrentShiftLocationDetailsByLocationId(locationId: string): ShiftLocationDetailViewModel {
            const heading = useHeadingsStore().getHeadingByLocationId(locationId);
            return heading.shiftLocationDetails!;
        },
        completeShiftActuals() {
            this.hasActuals = true;
        },
        updateLocationCommentary(rowId: string, commentary: CommentaryViewModel) {
            const heading = useHeadingsStore().getHeadingById(rowId);

            if(heading?.shiftLocationDetails != null) {
                heading.shiftLocationDetails.commentary = commentary;
            }
        },
        async updateShiftManningAndEquipment(shiftCrewId: string, shiftCrewMembers: ShiftCrewMemberViewModel[], taskComments: TaskCommunications[]) {
            const command = ModelBuilder.Commands.UpdateShiftManningAndEquipmentCommand({
                _type: 'UpdateShiftManningAndEquipmentCommand',
                isOfflineReplay: false,
                attemptedAt: new Date(),
                shiftCrewId: shiftCrewId,
                shiftCrewMembers: shiftCrewMembers,
                reportId: this.supervisorShiftReport!.id,
                shiftId: this.shiftId,
                tasks: taskComments
            });

            await Shift.updateShiftManningAndEquipment(this.shiftId, command);
        },
        async updateShiftSafetyAndIncidents() {
            if(this.supervisorShiftReport == null)
                return;

            const command = ModelBuilder.Commands.UpdateShiftSafetyAndIncidentsCommand({
                _type: 'UpdateShiftSafetyAndIncidentsCommand',
                isOfflineReplay: false,
                attemptedAt: new Date(),
                reportId: this.supervisorShiftReport!.id,
                shiftId: this.shiftId,
                conditionMonitoring: this.supervisorShiftReport.conditionMonitoring,
                safetyIncidents: this.supervisorShiftReport.safetyIncidents,
                safetyObservations: this.supervisorShiftReport.safetyObservations,
                jobSafetyEnvironmentalAnalyses: this.supervisorShiftReport.jobSafetyEnvironmentalAnalyses,
            });

            await Shift.updateShiftSafetyAndIncidents(this.shiftId, command);
        },
        async updateShiftVarianceAndCommentary() {
            const shiftLocations: ShiftLocationVarianceToPlanModel[] = [];
            const headings = useHeadingsStore().headings;
            for (const row of headings) {
                if (row.shiftLocationDetails) {
                    shiftLocations.push({
                        _type: 'ShiftLocationVarianceToPlanModel',
                        plannedLocationId: row.shiftLocationDetails.plannedLocationId,
                        varianceToPlan: row.shiftLocationDetails.varianceToPlan
                    });
                }
            }
            this.supervisorShiftReport!.departmentShiftId = this.shiftId;

            const productionActuals = useShiftDetails().productionActuals;
            const command = ModelBuilder.Commands.UpdateShiftVarianceAndCommentaryCommand({
                _type: 'UpdateShiftVarianceAndCommentaryCommand',
                isOfflineReplay: false,
                attemptedAt: new Date(),
                reportId: this.supervisorShiftReport!.id,
                shiftId: this.shiftId,
                comments: this.supervisorShiftReport!.comments,
                varianceToPlanComments: shiftLocations,
            });

            await Shift.updateShiftVarianceAndCommentary(this.shiftId, command);
        },
        async saveOfflineSnapshot(name: string) {
            const model = {
                ...this.snapshotModel,
                rows: transformRowsToOfflineRows(this.snapshotModel.rows),
            };
            const command = {
                name: name,
                snapshottedAt: new Date(),
                isPublish: false,
                isActuals: false,
                departmentShiftId: this.shiftId,
                snapshotJson: JSON.stringify(model),
                attemptedAt: new Date(),
                isOfflineReplay: false,
            } as RecordShiftSnapshotCommand;
            await Shift.recordOfflineShiftSnapshot({ ...command, _type: 'RecordShiftSnapshotCommand' });
        },
        addEquipmentLocationForShift(locationName: string) {
            if(this.locationsForShift !== null ){
                if(!this.locationsForShift.locations.includes(locationName)) {
                    this.locationsForShift.locations = [...this.locationsForShift.locations, locationName];
                }
            }
        }
    },
});
