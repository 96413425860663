<template>
    <basic-screen :showMenu="false">
        <v-skeleton-loader class="mx-auto" type="card" v-if="$wait.is('get user')"></v-skeleton-loader>

        <div v-else class="splash" style="background: url('/img/splash.jpg') no-repeat center; background-size: cover;">
            <div class="logo" :style="logo"></div>
            <div class="text-center mt-5 action">
                <div v-if="isLoggedIn">
                    <v-btn class="large-btn" :to="{ name: `default${boardType}board` }" color="primary">Begin</v-btn>
                </div>

                <div v-if="isLoggedIn">
                    <v-btn @click="$nav('/logout')" text>Logout</v-btn>
                </div>
                <v-btn class="large-btn" @click="$nav('login')" color="primary" v-if="!isLoggedIn">Login</v-btn>
            </div>
        </div>
    </basic-screen>
</template>

<script>
import Config from '@/lib/Config';
import UserStore from '@/lib/stores/UserStore';
import ShiftStore from '@/lib/stores/CurrentShiftStore';
import BasicScreen from '@/templates/BasicScreen.vue';

export default {
    components: {
        BasicScreen
    },
    data() {
        return { isLoggedIn: false, boardType: ShiftStore.selectedBoardType };
    },
    async created() {
        this.$wait.start('get user');
        var user = await UserStore.getCurrentUser();
        this.isLoggedIn = !!user;
        this.$wait.end('get user');
    },
    computed: {
        logo: () => {
            return {
                backgroundImage: `url('/img/${Config.Logo}')`
            };
        }
    }
};
</script>
<style lang="scss">
.large-btn {
    font-size: 1.2rem !important;
    height: auto !important;
    padding: 18px 30px !important;
}
.splash {
    background-size: cover;
    height: 50vh;
    width: 100%;
    position: relative;

    .logo {
        width: 400px;
        height: 134px;
        left: 50%;
        top: 50%;
        margin-top: -65px;
        margin-left: -200px;
        position: absolute;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .action {
        position: absolute;
        bottom: -3rem;
        height: 6rem;
        width: 100vw;
        line-height: 6rem;
        font-size: 1.8rem;
    }
}
</style>
