import { RingRuleEvaluationState, RingRuleEvaluationStatus, TaskTypeViewModel } from '@/models/api';
import dayjs from 'dayjs';
import { CalculateQuantity, IncludesExclusiveEnd, TaskIncludesTime } from '@/lib/services/Task';
import { ClientBlastPacketActualsEntry, ClientBlastPacketTargetCompletion } from '@/models/client/client-actuals';
import { ConvertTaskTypeToStatus } from '@/lib/services/Production/shared';
import { ProductionValidationTaskModel } from '@/models/client/production-validation-task-model';

export interface BlastPacketRingTargetEvaluation{
    tolerance: number;
    targetQuantity: number;
    taskType: TaskTypeViewModel;
}

export class LocationBlastPacketStateMachine {
    projectedBoggedAmount: number = 0;

    actualBoggedAmount: number = 0;

    boggingMarkedCompleted: boolean = false;

    isFired: boolean = false;

    ringsMap: Map<string, LocationRingStateMachine>;

    targetsMap: Map<string,BlastPacketRingTargetEvaluation>;

    lastEncounteredBoggingTask: ProductionValidationTaskModel | null = null;

    lastEncounteredBoggingTaskMeasuredFrom: dayjs.Dayjs | null = null;

    lastEncounteredBoggingTarget: BlastPacketRingTargetEvaluation | null = null;

    encounteredTaskCallback: (target: BlastPacketRingTargetEvaluation, task: ProductionValidationTaskModel, ringStates: { locationRingId: string, currentState: RingRuleEvaluationState }[], currentBlastPacketState: { isFired: boolean, boggedAmount: number, boggingMarkedCompleted: boolean, cutTaskAt: dayjs.Dayjs | null }) => void;

    projectionMode: boolean = false;

    constructor(targets: Map<string, BlastPacketRingTargetEvaluation>, rings: Map<string, LocationRingStateMachine>, ringInitialStates: RingRuleEvaluationState[], encounteredTaskCallback: (target: BlastPacketRingTargetEvaluation, task: ProductionValidationTaskModel, ringStates: { locationRingId: string, currentState: RingRuleEvaluationState }[], currentBlastPacketState: { isFired: boolean, boggedAmount: number, boggingMarkedCompleted: boolean})=>void) {
        this.targetsMap = targets;
        this.ringsMap = rings;
        if(ringInitialStates.some(r=>r.ringStatus===RingRuleEvaluationStatus.Bogging)) {
            this.isFired = true;
            if(ringInitialStates.some(r=>r.ringStatus===RingRuleEvaluationStatus.Bogging && r.basedOnActuals))
                this.actualBoggedAmount = ringInitialStates.filter(r=>r.ringStatus===RingRuleEvaluationStatus.Bogging && r.basedOnActuals).reduce((prev,curr)=>Math.max(curr.quantity,prev), 0);
            else if(ringInitialStates.some(r=>r.ringStatus===RingRuleEvaluationStatus.Bogging && !r.basedOnActuals))
                this.projectedBoggedAmount = ringInitialStates.filter(r=>r.ringStatus===RingRuleEvaluationStatus.Bogging && !r.basedOnActuals).reduce((prev,curr)=>Math.max(curr.quantity,prev), 0);
        } else if(ringInitialStates.some(r=>r.ringStatus===RingRuleEvaluationStatus.Fired))
            this.isFired=true;

        this.encounteredTaskCallback = encounteredTaskCallback;
    }

    EncounteredTask(blastPacketRingIds: { blastPacketId: string, locationRingId: string | null }, task: ProductionValidationTaskModel): { locationRingId: string, states: RingRuleEvaluationState[]}[] {
        if(task.blastPacketRingTargetId == null)
            return [];
        const incomingStatusType = ConvertTaskTypeToStatus(task.taskType);

        if(!incomingStatusType)
            return [];


        const blastPacketRingTarget = this.targetsMap.get(task.blastPacketRingTargetId);

        if(blastPacketRingTarget == null)
            return [];

        const returnStates: { locationRingId: string, states: RingRuleEvaluationState[]}[] = [];

        if(blastPacketRingIds.locationRingId == null) {
            if(incomingStatusType === RingRuleEvaluationStatus.Bogging) {
                if(this.lastEncounteredBoggingTask != null) {
                    this.projectedBoggedAmount = this.projectedBoggedAmount +
                        CalculateQuantity(this.lastEncounteredBoggingTask.ratePerHour ?? 0,
                            this.lastEncounteredBoggingTaskMeasuredFrom != null
                                ? this.lastEncounteredBoggingTask.endTime.diff(this.lastEncounteredBoggingTaskMeasuredFrom, 'minutes') :
                                this.lastEncounteredBoggingTask.durationMinutes);
                }

                this.lastEncounteredBoggingTask = task;
                this.lastEncounteredBoggingTaskMeasuredFrom = null;
                this.lastEncounteredBoggingTarget = blastPacketRingTarget;
            } else {
                this.projectedBoggedAmount = 0;
                this.lastEncounteredBoggingTarget = null;
                this.lastEncounteredBoggingTask = null;
                this.lastEncounteredBoggingTaskMeasuredFrom = null;
            }

            if(this.projectionMode){
                // do validation
                const ringStates = [...this.ringsMap.keys()].map(locationRingId => {
                    return { locationRingId: locationRingId, currentState: this.ringsMap.get(locationRingId)!.GetCurrentState(task.startTime)};
                }).filter(s=>s.currentState != null).map(s=> ({
                    locationRingId: s.locationRingId, currentState: s.currentState!
                }));

                this.encounteredTaskCallback(blastPacketRingTarget, task, ringStates, { isFired: this.isFired, boggedAmount: this.actualBoggedAmount+this.projectedBoggedAmount, boggingMarkedCompleted: this.boggingMarkedCompleted, cutTaskAt: null});
            }

            for(const locationStateMachine of this.ringsMap) {
                returnStates.push({ locationRingId: locationStateMachine[0], states: locationStateMachine[1].EncounteredTask(task, false)});
            }
        } else {
            returnStates.push({ locationRingId: blastPacketRingIds.locationRingId, states: this.ringsMap.get(blastPacketRingIds.locationRingId)!.EncounteredTask(task, true)});
        }

        return returnStates;
    }

    EncounteredActual(blastPacketRingIds: { blastPacketId: string, locationRingId: string | null }, entry: ClientBlastPacketActualsEntry) {
        if(this.projectionMode)
            throw new Error('Cannot encounter actuals after current mine time.');

        const blastPacketRingTarget = this.targetsMap.get(entry.targetId);

        if(blastPacketRingTarget == null)
            return;

        const incomingStatusType = ConvertTaskTypeToStatus(blastPacketRingTarget.taskType);

        if(incomingStatusType==null)
            return;


        if(blastPacketRingIds.locationRingId == null) {
            if(incomingStatusType === RingRuleEvaluationStatus.Bogging) {
                this.projectedBoggedAmount = 0;
                this.actualBoggedAmount = this.actualBoggedAmount + entry.quantity;

                if(this.lastEncounteredBoggingTask != null){
                    if(TaskIncludesTime(this.lastEncounteredBoggingTask, entry.endTime))
                        this.lastEncounteredBoggingTaskMeasuredFrom=entry.endTime;
                    else {
                        this.lastEncounteredBoggingTask = null;
                        this.lastEncounteredBoggingTaskMeasuredFrom = null;
                        this.lastEncounteredBoggingTarget = null;
                    }
                }
            }

            for(const locationStateMachine of this.ringsMap) {
                locationStateMachine[1].EncounteredActual(entry);
            }
        } else {
            this.ringsMap.get(blastPacketRingIds.locationRingId)!.EncounteredActual(entry);
        }
    }

    EncounteredManualCompletion(blastPacketRingIds: { blastPacketId: string, locationRingId: string | null }, completion: ClientBlastPacketTargetCompletion) {
        if(this.projectionMode)
            throw new Error('Cannot encounter actuals after current mine time.');

        const blastPacketRingTarget = this.targetsMap.get(completion.targetId);

        if(blastPacketRingTarget == null)
            return;

        const incomingStatusType = ConvertTaskTypeToStatus(blastPacketRingTarget.taskType);

        if(incomingStatusType==null)
            return;

        if(blastPacketRingIds.locationRingId == null) {
            if(incomingStatusType === RingRuleEvaluationStatus.Bogging) {
                this.projectedBoggedAmount = 0;
                this.boggingMarkedCompleted = true;

                if(this.lastEncounteredBoggingTask != null){
                    if(TaskIncludesTime(this.lastEncounteredBoggingTask, completion.completedAt))
                        this.lastEncounteredBoggingTaskMeasuredFrom=completion.completedAt;
                    else {
                        this.lastEncounteredBoggingTask = null;
                        this.lastEncounteredBoggingTaskMeasuredFrom = null;
                        this.lastEncounteredBoggingTarget = null;
                    }
                }
            }

            for(const locationStateMachine of this.ringsMap) {
                locationStateMachine[1].EncounteredManualCompletion(completion);
            }
        } else {
            this.ringsMap.get(blastPacketRingIds.locationRingId)!.EncounteredManualCompletion(completion);
        }
    }

    FinalStates(): { locationRingId: string, states: RingRuleEvaluationState[]}[]{
        const returnStates: { locationRingId: string, states: RingRuleEvaluationState[]}[] = [];

        for(const locationStateMachine of this.ringsMap) {
            returnStates.push({ locationRingId: locationStateMachine[0], states: locationStateMachine[1].FinalState()});
        }

        return returnStates;
    }

    EnterProjectionMode(modeEnteredAsOfTime: dayjs.Dayjs) {
        this.projectionMode = true;

        if(this.lastEncounteredBoggingTask != null && TaskIncludesTime(this.lastEncounteredBoggingTask, modeEnteredAsOfTime)) {
            const ringStates = [...this.ringsMap.keys()].map(locationRingId => {
                return { locationRingId: locationRingId, currentState: this.ringsMap.get(locationRingId)!.GetCurrentState(this.lastEncounteredBoggingTaskMeasuredFrom ?? this.lastEncounteredBoggingTask!.startTime)};
            }).filter(s=>s.currentState != null).map(s=> ({
                locationRingId: s.locationRingId, currentState: s.currentState!
            }));
            this.encounteredTaskCallback(
                this.lastEncounteredBoggingTarget!,
                this.lastEncounteredBoggingTask,
                ringStates,
                { isFired: this.isFired, boggedAmount: this.actualBoggedAmount+this.projectedBoggedAmount, boggingMarkedCompleted: this.boggingMarkedCompleted, cutTaskAt: this.lastEncounteredBoggingTaskMeasuredFrom});
        }

        for(const locationStateMachine of this.ringsMap) {
            locationStateMachine[1].EnterProjectionMode(modeEnteredAsOfTime);
        }
    }
}

export class LocationRingStateMachine {
    initialState: RingRuleEvaluationState;

    actualState: ActualState | null = null;

    projectedState: ProjectedState | null = null;

    projectionMode: boolean = false;

    hasEmittedInitialState: boolean = false;

    targetsMap: Map<string,BlastPacketRingTargetEvaluation>;

    encounteredTaskCallback: (target: BlastPacketRingTargetEvaluation, task: ProductionValidationTaskModel, stateAtEncounter: RingRuleEvaluationState) => void;

    constructor(targets: Map<string, BlastPacketRingTargetEvaluation>, initialState: RingRuleEvaluationState, encounteredTaskCallback: (target: BlastPacketRingTargetEvaluation, task: ProductionValidationTaskModel, stateAtEncounter: RingRuleEvaluationState) => void) {
        this.targetsMap = targets;
        this.initialState = initialState;
        this.encounteredTaskCallback = encounteredTaskCallback;
    }

    EncounteredTask(task: ProductionValidationTaskModel, canTriggerTaskEncounterEvent: boolean): RingRuleEvaluationState[] {
        if(task.blastPacketRingTargetId == null)
            return [];
        const incomingStatusType = ConvertTaskTypeToStatus(task.taskType);

        if(!incomingStatusType)
            return [];

        // if the task is wholly prior to the initial state, we need to discard it as the initial state cannot be changed
        if(this.initialState != null && task.endTime.isSameOrBefore(this.initialState.startAt))
            return [];

        const blastPacketRingTarget = this.targetsMap.get(task.blastPacketRingTargetId);

        const previousProjectedState = this.projectedState;
        let emitPreviousProjectedState = false;

        if(this.projectedState !== null && this.projectedState.status === incomingStatusType) {
            this.projectedState = this.projectedState.WithNewLastEncountered(task);
        } else {
            const quantity = task.quantity ?? 0;
            const stateTransitionTime = this.GetStateChangeTimeForTaskType(incomingStatusType, task);
            emitPreviousProjectedState = true;
            this.projectedState = new ProjectedState(incomingStatusType, stateTransitionTime, quantity, task, blastPacketRingTarget?.targetQuantity ?? 0, blastPacketRingTarget?.tolerance ?? 0, canTriggerTaskEncounterEvent);
        }

        const returnedStates: RingRuleEvaluationState[] = [];

        if(!this.projectionMode)
            return returnedStates;

        if(!this.hasEmittedInitialState){
            returnedStates.push({
                ...this.initialState,
                endAt: (this.actualState?.startTimeFrom ?? previousProjectedState?.startTimeFrom ?? this.projectedState.startTimeFrom).toDate()
            });
            this.hasEmittedInitialState = true;
        }

        if(this.actualState != null && !this.actualState.hasEmitted){
            returnedStates.push(this.actualState.Emit(previousProjectedState?.startTimeFrom ?? task.startTime));
        }

        if(previousProjectedState != null && emitPreviousProjectedState)
            returnedStates.push({
                _type: 'RingRuleEvaluationState',
                ringStatus: previousProjectedState.status,
                basedOnActuals: false,
                reachedTarget: this.ProjectedAndActualsReachTarget(previousProjectedState),
                markedCompleted: this.ProjectedShouldBeMarkedCompleted(previousProjectedState),
                startAt: previousProjectedState.startTimeFrom.toDate(),
                endAt: this.projectedState.startTimeFrom.toDate(),
                quantity: this.ProjectedAndActualsQuantity(previousProjectedState),
                clientCanCalculate: true
            });

        if(previousProjectedState != null && canTriggerTaskEncounterEvent){
            this.encounteredTaskCallback(blastPacketRingTarget!, task, {
                _type: 'RingRuleEvaluationState',
                ringStatus: previousProjectedState.status,
                basedOnActuals: false,
                reachedTarget: this.ProjectedAndActualsReachTarget(previousProjectedState),
                markedCompleted: this.ProjectedShouldBeMarkedCompleted(previousProjectedState),
                startAt: previousProjectedState.startTimeFrom.toDate(),
                endAt: this.projectedState.startTimeFrom.toDate(),
                quantity: this.ProjectedAndActualsQuantity(previousProjectedState),
                clientCanCalculate: true
            });
        } else if(returnedStates.length > 0 && canTriggerTaskEncounterEvent){
            this.encounteredTaskCallback(blastPacketRingTarget!, task, returnedStates[returnedStates.length-1]);
        }

        return returnedStates;
    }

    EncounteredActual(entry: ClientBlastPacketActualsEntry) {
        if(this.projectionMode)
            throw new Error('Cannot encounter actuals after current mine time.');

        const blastPacketRingTarget = this.targetsMap.get(entry.targetId);

        if(blastPacketRingTarget == null)
            return;

        if(this.initialState !=null && entry.endTime.isSameOrBefore(this.initialState.startAt))
            return; // actuals before we entered our initial state are ignored as they can't feasibly change our projections.

        const incomingStatusType = ConvertTaskTypeToStatus(blastPacketRingTarget.taskType);

        if(incomingStatusType==null)
            return;

        if(this.actualState != null && incomingStatusType === this.actualState.status){
            this.actualState = this.actualState.WithAdditionalQuantity(entry.quantity);
        } else if(this.actualState == null && this.initialState != null && this.initialState.ringStatus === incomingStatusType && this.initialState.basedOnActuals && entry.quantity > 0) {
            this.actualState = new ActualState(incomingStatusType, entry.startTime != null ? dayjs(entry.startTime).utc() : dayjs(entry.endTime).utc(), entry.quantity + this.initialState.quantity, blastPacketRingTarget, this.initialState.markedCompleted);
        } else if(entry.quantity > 0){
            this.actualState = new ActualState(incomingStatusType, entry.startTime != null ? dayjs(entry.startTime).utc() : dayjs(entry.endTime).utc(), entry.quantity, blastPacketRingTarget, false);
        }

        if(this.projectedState != null) {
            const entryEndTimeAsDayjs = dayjs(entry.endTime).utc();
            if(IncludesExclusiveEnd(this.projectedState.lastEncounteredTask, entryEndTimeAsDayjs)) {
                if(this.ShouldApportionProjectedState() && (entry.quantity > 0 || incomingStatusType === this.projectedState.status)){
                    const durationToProject = this.projectedState.lastEncounteredTask.endTime.diff(entryEndTimeAsDayjs, 'minutes');
                    const projectedQuantity = CalculateQuantity(this.projectedState.lastEncounteredTask.ratePerHour ?? 60, durationToProject);
                    this.projectedState = this.projectedState.WithNewTimeAndQuantity(entryEndTimeAsDayjs, projectedQuantity);
                }
            } else {
                if(entry.quantity > 0 || incomingStatusType === this.projectedState.status)
                    this.projectedState = null;
            }
        }
    }

    EncounteredManualCompletion(completion: ClientBlastPacketTargetCompletion) {
        if(this.projectionMode)
            throw new Error('Cannot encounter actuals after current mine time.');

        const blastPacketRingTarget = this.targetsMap.get(completion.targetId);

        if(blastPacketRingTarget == null)
            return;

        if(this.initialState !=null && completion.completedAt.isSameOrBefore(this.initialState.startAt))
            return; // completions before we entered our initial state are ignored as they can't feasibly change our projections.

        const incomingStatusType = ConvertTaskTypeToStatus(blastPacketRingTarget.taskType);

        if(incomingStatusType == null)
            return;

        if(this.actualState != null && incomingStatusType === this.actualState.status) {
            this.actualState = this.actualState.WithManualCompletion();
            // completions can only ADVANCE our state, not decrement it. Ideally all completion dates would be when it was ACTUALLY completed, but it's likely some will just be "end of this shift" and we don't want to wipe out partially-completed actuals
        } else if(this.actualState == null && this.initialState != null && this.initialState.ringStatus === incomingStatusType && this.initialState.basedOnActuals) {
            this.actualState = new ActualState(incomingStatusType, completion.completedAt, this.initialState.quantity, blastPacketRingTarget, true);
        }
        else if(this.actualState == null || incomingStatusType > this.actualState.status){
            this.actualState = new ActualState(incomingStatusType, completion.completedAt, 0, blastPacketRingTarget, true);
        }

        if(this.projectedState != null){
            if(IncludesExclusiveEnd(this.projectedState.lastEncounteredTask, completion.completedAt)) {
                if(this.ShouldApportionProjectedState()){
                    const durationToProject = this.projectedState.lastEncounteredTask.endTime.diff(completion.completedAt, 'minutes');
                    const projectedQuantity = durationToProject * (this.projectedState.lastEncounteredTask.ratePerHour ?? 60) / 60;
                    this.projectedState = this.projectedState.WithNewTimeAndQuantity(completion.completedAt, projectedQuantity);
                }
            } else {
                this.projectedState = null;
            }
        }
    }

    FinalState(): RingRuleEvaluationState[] {
        const returnedStates: RingRuleEvaluationState[] = [];

        if(!this.hasEmittedInitialState){
            returnedStates.push({
                ...this.initialState,
                endAt: (this.actualState?.startTimeFrom ?? this.projectedState?.startTimeFrom)?.toDate() ?? new Date(9999, 11,31),
            });
            this.hasEmittedInitialState = true;
        }

        if(this.actualState != null && !this.actualState.hasEmitted) {
            returnedStates.push(this.actualState.Emit(this.projectedState?.startTimeFrom ?? dayjs(new Date(9999,11,31)).utc()));
        }

        if(this.projectedState != null)
            returnedStates.push({
                _type: 'RingRuleEvaluationState',
                ringStatus: this.projectedState.status,
                basedOnActuals: false,
                reachedTarget: this.ProjectedAndActualsReachTarget(this.projectedState),
                markedCompleted: this.ProjectedShouldBeMarkedCompleted(this.projectedState),
                startAt: this.projectedState.startTimeFrom.toDate(),
                endAt: new Date(9999,11,31),
                clientCanCalculate: true,
                quantity: this.ProjectedAndActualsQuantity(this.projectedState)
            });

        return returnedStates;
    }

    EnterProjectionMode(modeEnteredAsOfTime: dayjs.Dayjs) {
        // if we haven't previously entered projection mode, we may need to retroactively 'encounter' a state for the current projected state's task
        if(!this.projectionMode && this.projectedState != null && IncludesExclusiveEnd(this.projectedState.lastEncounteredTask, modeEnteredAsOfTime) && this.projectedState.canTriggerTaskEncounterEvent) {
            const blastPacketRingTarget = this.targetsMap.get(this.projectedState.lastEncounteredTask.blastPacketRingTargetId!);

            if(blastPacketRingTarget != null)
            {
                if(this.actualState != null)
                    this.encounteredTaskCallback(blastPacketRingTarget, this.projectedState.lastEncounteredTask, this.actualState.Emit(this.projectedState.startTimeFrom, true));
                else if(this.initialState != null)
                    this.encounteredTaskCallback(blastPacketRingTarget, this.projectedState.lastEncounteredTask, { ...this.initialState, endAt: this.projectedState.lastEncounteredTask.startTime.toDate()});
            }
        }

        this.projectionMode = true;
    }

    GetCurrentState(getStateAt: dayjs.Dayjs): RingRuleEvaluationState | null {
        if(this.projectedState != null){
            return {
                _type: 'RingRuleEvaluationState',
                ringStatus: this.projectedState.status,
                basedOnActuals: false,
                reachedTarget: this.ProjectedAndActualsReachTarget(this.projectedState),
                markedCompleted: this.ProjectedShouldBeMarkedCompleted(this.projectedState),
                startAt: this.projectedState.startTimeFrom.toDate(),
                endAt: this.projectedState.startTimeFrom.toDate(),
                quantity: this.ProjectedAndActualsQuantity(this.projectedState),
                clientCanCalculate: true
            };
        } else if(this.actualState != null){
            return this.actualState.Emit(getStateAt, true);
        } else if(this.initialState != null)
        {
            return this.initialState;
        }

        else return null;
    }

    GetStateChangeTimeForTaskType(incomingStatus: RingRuleEvaluationStatus, incomingTask: ProductionValidationTaskModel) {
        if(incomingStatus === RingRuleEvaluationStatus.Fired)
            return incomingTask.endTime;
        else
            return incomingTask.startTime;
    }

    ProjectedAndActualsReachTarget(projectedState: ProjectedState) {
        if(projectedState.status === RingRuleEvaluationStatus.Fired)
            return true;

        const actualQuantity = this.retrieveActualQuantityForProjectedState(projectedState);

        return projectedState.projectedQuantity + actualQuantity >= projectedState.targetQuantity*(1-projectedState.targetQuantityTolerance);
    }

    retrieveActualQuantityForProjectedState(projectedState: ProjectedState) {
        if(this.actualState != null) {
            if(this.actualState.status === projectedState.status)
                return this.actualState.actualQuantity;
        } else if(this.initialState != null && this.initialState.ringStatus === projectedState.status && this.initialState.basedOnActuals)
            return this.initialState.quantity;

        return 0;
    }

    ProjectedShouldBeMarkedCompleted(projectedState: ProjectedState) {
        if(this.actualState != null) {
            if(this.actualState.status === projectedState.status)
                return this.actualState.manuallyCompleted;
        } else if(this.initialState != null && this.initialState.ringStatus === projectedState.status && this.initialState.basedOnActuals)
            return this.initialState.markedCompleted;

        return false;
    }

    ShouldApportionProjectedState(): boolean {
        if(this.projectedState == null)
            return false;

        if(this.projectedState.status === RingRuleEvaluationStatus.Fired)
            return false;

        return true;
    }

    ProjectedAndActualsQuantity(projectedState: ProjectedState): number {
        if(projectedState.status === RingRuleEvaluationStatus.Fired)
            return 0;

        if(this.actualState != null && this.actualState.status === projectedState.status)
            return this.actualState.actualQuantity+projectedState.projectedQuantity;

        if(this.actualState == null && this.initialState != null && this.initialState.ringStatus === projectedState.status)
            return this.initialState.quantity+projectedState.projectedQuantity;

        return projectedState.projectedQuantity;
    }
}

class ActualState {
    status: RingRuleEvaluationStatus;
    startTimeFrom: dayjs.Dayjs;
    target: BlastPacketRingTargetEvaluation;
    actualQuantity: number;
    manuallyCompleted: boolean;
    hasEmitted: boolean = false;

    constructor(status: RingRuleEvaluationStatus, startTimeFrom: dayjs.Dayjs, actualQuantity: number, target: BlastPacketRingTargetEvaluation, manuallyCompleted: boolean) {
        this.status = status;
        this.startTimeFrom = startTimeFrom;
        this.actualQuantity = actualQuantity;
        this.target = target;
        this.manuallyCompleted = manuallyCompleted;
    }

    WithAdditionalQuantity(quantityToAdd: number): ActualState {
        return new ActualState(this.status, this.startTimeFrom, this.actualQuantity+quantityToAdd, this.target, this.manuallyCompleted);
    }

    WithManualCompletion(): ActualState {
        return new ActualState(this.status, this.startTimeFrom, this.actualQuantity, this.target, true);
    }

    TargetQuantity(): number {
        return this.status === RingRuleEvaluationStatus.Fired ? 0 : this.target.targetQuantity;
    }

    ToleranceProportion(): number {
        return this.target.tolerance;
    }

    ReachedTarget(): boolean {
        return this.actualQuantity >= this.TargetQuantity() * (1 - this.ToleranceProportion());
    }

    Emit(endTime: dayjs.Dayjs, dryRun: boolean = false): RingRuleEvaluationState {
        if(!dryRun)
            this.hasEmitted = true;
        return {
            _type: 'RingRuleEvaluationState',
            ringStatus: this.status,
            basedOnActuals: true,
            markedCompleted: this.manuallyCompleted,
            reachedTarget: this.ReachedTarget(),
            startAt: this.startTimeFrom.toDate(),
            endAt: endTime.toDate(),
            clientCanCalculate: true,
            quantity: this.actualQuantity
        };
    }
}

class ProjectedState {
    status: RingRuleEvaluationStatus;
    startTimeFrom: dayjs.Dayjs;
    targetQuantity: number;
    targetQuantityTolerance: number;
    lastEncounteredTask: ProductionValidationTaskModel;
    projectedQuantity: number;
    canTriggerTaskEncounterEvent: boolean = false;

    constructor(status: RingRuleEvaluationStatus, startTimeFrom: dayjs.Dayjs, projectedQuantity: number, lastEncounteredTask: ProductionValidationTaskModel, targetQuantity: number, targetQuantityTolerance: number, canTriggerTaskEncounterEvent: boolean) {
        this.status = status;
        this.startTimeFrom = startTimeFrom;
        this.projectedQuantity = projectedQuantity;
        this.lastEncounteredTask = lastEncounteredTask;
        this.targetQuantity = targetQuantity;
        this.targetQuantityTolerance = targetQuantityTolerance;
        this.canTriggerTaskEncounterEvent = canTriggerTaskEncounterEvent;
    }

    WithNewTimeAndQuantity(startTimeFrom: dayjs.Dayjs, projectedQuantity: number): ProjectedState {
        return new ProjectedState(this.status, startTimeFrom, projectedQuantity, this.lastEncounteredTask, this.targetQuantity, this.targetQuantityTolerance, this.canTriggerTaskEncounterEvent);
    }

    WithNewLastEncountered(lastEncountered: ProductionValidationTaskModel): ProjectedState {
        return new ProjectedState(this.status, this.startTimeFrom, this.projectedQuantity + (lastEncountered.quantity ?? 0), lastEncountered, this.targetQuantity, this.targetQuantityTolerance, this.canTriggerTaskEncounterEvent);
    }
}