import { ICommandMapper } from '../ICommandMapper';
import { AddRoleToShiftCommand } from '@/models/api';

export class AddRoleToShiftCommandMapper implements ICommandMapper<AddRoleToShiftCommand> {
    commandType = 'AddRoleToShiftCommand';
    mappers = [
        {
            viewmodelType: 'ShiftCrew[]',
            map(command: AddRoleToShiftCommand, model: any[]): any[] | null {
                for (const shiftCrew of model) {
                    if (
                        shiftCrew.crews.length == command.crewIds.length &&
                        shiftCrew.crews
                            .map((c: { crewId: string }) => c.crewId)
                            .every((v: string) => command.crewIds.includes(v))
                    ) {
                        shiftCrew.shiftCrewMembers.push({
                            id: command.id,
                            shiftCrewId: shiftCrew.id,
                            staffRoleId: command.$meta.role.id,
                            staffRole: command.$meta.role
                        });
                    }
                }
                return model;
            }
        }
    ];
}

export default new AddRoleToShiftCommandMapper();
