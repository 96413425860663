import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { Iconfont } from 'vuetify/types/services/icons';
import PhysicalsIcon from '@/components/Shared/Icons/PhysicalsIcon.vue';
import VarianceIcon from '@/components/Shared/Icons/VarianceIcon.vue';

Vue.use(Vuetify);

export const presets = {
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            dark: {
                primary: '#26a69a',
                secondary: '#49b8d2',
                accent: '#f1b125',
                background: '#231f20'
            },
            light: {
                primary: '#26a69a',
                secondary: '#49b8d2',
                accent: '#f1b125'
            }
        }
    },
    icons: {
        iconFont: 'mdi',
        values: {
            physicals: {
                component: PhysicalsIcon
            },
            varianceChart: {
                component: VarianceIcon
            }
        }
    }
};

export default new Vuetify(presets);
