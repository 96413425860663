import Vue from 'vue';
import VueRouter, { RawLocation, Route } from 'vue-router';
import Home from '@/views/Home.vue';
import Account from '@/views/Account.vue';
import Callback from '@/views/Callback.vue';
import Login from '@/views/Login.vue';
import Logout from '@/views/Logout.vue';
import Error404 from '@/views/errors/Error404.vue';

import Admin from '@/views/Admin.vue';
import AdminHome from '@/components/Admin/Home.vue';
import AdminUsers from '@/components/Admin/Users/Users.vue';
import AdminTerms from '@/components/Admin/Terms.vue';
import AdminAreas from '@/components/Admin/MineAreas.vue';
import AdminReducedLevels from '@/components/Admin/ReducedLevels.vue';
import AdminLocations from '@/components/Admin/Locations.vue';
import AdminDepartments from '@/components/Admin/Departments.vue';
import AdminCycles from '@/components/Admin/Cycles.vue';
import AdminTasks from '@/components/Admin/Tasks.vue';
import AdminTaskCategories from '@/components/Admin/TaskCategories.vue';
import AdminMeetingGuide from '@/components/Admin/MeetingGuide.vue';
import AdminEquipment from '@/components/Admin/Equipment/Equipment.vue';
import AdminCrews from '@/components/Admin/Crews.vue';
import AdminStaff from '@/components/Admin/Staff.vue';
import AdminStaffRoles from '@/components/Admin/StaffRoles.vue';
import AdminEquipmentRoles from '@/components/Admin/EquipmentRoles.vue';
import AdminStockpiles from '@/components/Admin/Stockpiles.vue';
import AdminMaterialDestinations from '@/components/Admin/MaterialDestinations.vue';
import AdminMaterialTypes from '@/components/Admin/MaterialTypes.vue';
import AdminSandpit from '@/components/Admin/Sandpit/Sandpit.vue';
import AdminActualPhysicalsSourceTypes from '@/components/Admin/ActualPhysicalsSourceTypes.vue';
import AdminTriggerActionResponsePlanAspects from '@/components/Admin/TriggerActionResponsePlans.vue';
import AdminPropertyDefintitions from '@/components/Admin/LocationExtendedProperties.vue';
import AdminProductionFronts from '@/components/Admin/ProductionFronts.vue';
import AdminRingConfigurations from '@/components/Admin/RingConfigurations.vue';
import AdminCommunicationsConfiguration from '@/components/Admin/CommunicationsConfiguration.vue';

import Captain from '@/views/Captain.vue';
import DefaultCaptainBoard from '@/components/CaptainBoard/Default.vue';
import CaptainBoard from '@/components/CaptainBoard/Board.vue';

import Shift from '@/views/Shift.vue';
import DefaultShiftBoard from '@/components/ShiftBoard/Default.vue';
import ShiftBoard from '@/components/ShiftBoard/Board.vue';
import ShiftResources from '@/components/ShiftBoard/Resources.vue';
import ShiftResourceChart from '@/components/ShiftBoard/ResourceChart.vue';
import ShiftWeek from '@/components/ShiftBoard/Weekly.vue';
import ShiftWeekResourcing from '@/components/ShiftBoard/WeekResourcing.vue';
import ShiftWeekEquipment from '@/components/ShiftBoard/WeekEquipment.vue';
import ShiftCommunicationsPage from '@/components/ShiftBoard/Communications/CommunicationsPage.vue';
import ShiftAnalytics from '@/components/ShiftBoard/Analytics.vue';
import ShiftPriotityAnalytics from '@/components/ShiftBoard/PriorityAnalytics.vue';
import ShiftSupervisorPage  from '@/components/ShiftBoard/Supervisor/ShiftSupervisorPage.vue';
import Security from '@/lib/Security';
import { EventBus, Events } from '@/lib/EventBus';

import SLCTrackerBoard from '@/components/LocationTracker/SLCTrackerBoard.vue';
import DevelopmentTrackerBoard from '@/components/LocationTracker/DevelopmentTrackerBoard.vue';
import StopeTrackerBoard from '@/components/LocationTracker/StopeTrackerBoard.vue';
import ProductionFrontViewer from '@/components/ProductionFrontViewer/ProductionFrontViewer.vue';
import Terms from '@/lib/Terms';

Vue.use(VueRouter);

//turn off navigation duplicated errors
const { isNavigationFailure, NavigationFailureType } = VueRouter;
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: RawLocation): Promise<Route> {
    return (originalPush.call(this, location) as any as Promise<Route>).catch((error) => {
        if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
            throw Error(error);
        }
    }) as Promise<Route>;
};

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/callback',
        name: 'callback',
        component: Callback,
        meta: { noAuth: true },
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { noAuth: true },
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout,
        meta: { noAuth: true },
    },
    {
        path: '/account',
        name: 'account',
        component: Account,
    },
    {
        path: '/captain',
        component: Captain,
        children: [
            { path: '', name: 'defaultcaptainboard', component: DefaultCaptainBoard },
            { path: ':mine/:date/:shift', name: 'captainboard', component: CaptainBoard },
        ],
        beforeEnter: (to: any, from: any, next: any) => {
            Terms.guaranteeTermsFetched()
                .finally(()=>next());
        }
    },
    {
        path: '/shift',
        component: Shift,
        children: [
            { path: '', name: 'defaultshiftboard', component: DefaultShiftBoard },
            { path: '', name: 'defaultweekboard', component: DefaultShiftBoard },
            { path: '', name: 'defaultweekresourcingboard', component: DefaultShiftBoard },
            { path: '', name: 'defaultweekequipmentboard', component: DefaultShiftBoard },
            { path: '', name: 'defaultresourcesboard', component: DefaultShiftBoard },
            { path: '', name: 'defaultresourcechartboard', component: DefaultShiftBoard },
            { path: '', name: 'defaultcommunicationsboard', component: DefaultShiftBoard },
            { path: '', name: 'defaultanalyticsboard', component: DefaultShiftBoard },
            { path: '', name: 'defaultshiftsupervisorboard', component: DefaultShiftBoard },
            { path: '', name: 'defaultslctrackerboard', component: SLCTrackerBoard },
            { path: '', name: 'defaultdevtrackerboard', component: DevelopmentTrackerBoard },
            { path: '', name: 'defaultstopetrackerboard', component: StopeTrackerBoard },
            { path: '', name: 'defaultpriorityanalyticsboard', component: DefaultShiftBoard },
            { path: '', name: 'defaultproductionfrontviewerboard', component: ProductionFrontViewer },
            {
                path: 'board/:mine/:dept/:date/:shift',
                name: 'shiftboard',
                component: ShiftBoard,
                beforeEnter: (to: any, from: any, next: any) => {
                    EventBus.$emit(Events.ShowFeature, 'Searchbar', true);
                    EventBus.$emit(Events.ClearSearchbar);
                    next();
                },
            },
            { path: 'resources/:mine/:dept/:date/:shift', name: 'resourcesboard', component: ShiftResources },
            {
                path: 'resourcechart/:mine/:dept/:date/:shift',
                name: 'resourcechartboard',
                component: ShiftResourceChart,
            },
            {
                path: 'communications/:mine/:dept/:date/:shift',
                name: 'communicationsboard',
                component: ShiftCommunicationsPage,
            },
            {
                path: 'week/:mine/:dept/:date/:shift',
                name: 'weekboard',
                component: ShiftWeek,
                beforeEnter: (to: any, from: any, next: any) => {
                    EventBus.$emit(Events.ShowFeature, 'Searchbar', true);
                    EventBus.$emit(Events.ClearSearchbar);
                    next();
                },
            },
            { path: 'weekcrew/:mine/:dept/:date/:shift', name: 'weekresourcingboard', component: ShiftWeekResourcing },
            {
                path: 'weekequipment/:mine/:dept/:date/:shift',
                name: 'weekequipmentboard',
                component: ShiftWeekEquipment,
            },
            { path: 'analytics/:mine/:dept/:date/:shift', name: 'analyticsboard', component: ShiftAnalytics },
            { path: 'priority-analytics/:mine/:dept/:date/:shift', name: 'priorityanalyticsboard', component: ShiftPriotityAnalytics },
            {
                path: 'shiftsupervisor/:mine/:dept/:date/:shift',
                name: 'shiftsupervisorboard',
                component: ShiftSupervisorPage,
            },
            {
                name: 'slctrackerboard',
                path: 'slctrackerboard/:mine/:dept/:date/:shift',
                component: SLCTrackerBoard,
                beforeEnter: (to: any, from: any, next: any) => {
                    EventBus.$emit(Events.ShowFeature, 'Searchbar', true);
                    EventBus.$emit(Events.ClearSearchbar);
                    next();
                },
            },
            {
                name: 'devtrackerboard',
                path: 'devtrackerboard/:mine/:dept/:date/:shift',
                component: DevelopmentTrackerBoard,
                beforeEnter: (to: any, from: any, next: any) => {
                    EventBus.$emit(Events.ShowFeature, 'Searchbar', true);
                    EventBus.$emit(Events.ClearSearchbar);
                    next();
                },
            },
            {
                name: 'stopetrackerboard',
                path: 'stopetrackerboard/:mine/:dept/:date/:shift',
                component: StopeTrackerBoard,
                beforeEnter: (to: any, from: any, next: any) => {
                    EventBus.$emit(Events.ShowFeature, 'Searchbar', true);
                    EventBus.$emit(Events.ClearSearchbar);
                    next();
                },
            },
            {
                name: 'productionfrontviewerboard',
                path: 'frontviewer/:mine/:dept/:date/:shift',
                component: ProductionFrontViewer,
                beforeEnter: (to: any, from: any, next: any) => {
                    EventBus.$emit(Events.ShowFeature, 'Searchbar', true);
                    EventBus.$emit(Events.ClearSearchbar);
                    next();
                },
            },
        ],
        beforeEnter: (to: any, from: any, next: any) => {
            Terms.guaranteeTermsFetched()
                .finally(()=>next());
        }
    },
    {
        path: '/admin',
        component: Admin,
        beforeEnter: (to: any, from: any, next: any) => {
            if (Security.isAnyAdmin() === false) next({ name: 'home' });
            else Terms.guaranteeTermsFetched()
                .finally(()=>next());
        },
        children: [
            { path: '', name: 'adminhome', component: AdminHome },
            { path: '/users', name: 'adminusers', component: AdminUsers },
            { path: '/terms', name: 'adminterms', component: AdminTerms },
            { path: '/areas', name: 'adminmineareas', component: AdminAreas },
            { path: '/deparments', name: 'admindepartments', component: AdminDepartments },
            { path: '/levels', name: 'adminlevels', component: AdminReducedLevels },
            { path: '/locations', name: 'adminlocations', component: AdminLocations },
            { path: '/cycles', name: 'admincycles', component: AdminCycles },
            { path: '/tasks', name: 'admintasks', component: AdminTasks },
            { path: '/taskcategories', name: 'admintaskcategories', component: AdminTaskCategories },
            { path: '/equipment', name: 'adminequipment', component: AdminEquipment },
            { path: '/equipmentroles', name: 'adminequipmentroles', component: AdminEquipmentRoles },
            { path: '/crews', name: 'admincrews', component: AdminCrews },
            { path: '/staff', name: 'adminstaff', component: AdminStaff },
            { path: '/staffroles', name: 'adminstaffroles', component: AdminStaffRoles },
            { path: '/meetingguid', name: 'adminmeetingtasks', component: AdminMeetingGuide },
            { path: '/commsconfig', name: 'admincommsconfig', component: AdminCommunicationsConfiguration },
            { path: '/stockpiles', name: 'adminstockpiles', component: AdminStockpiles },
            { path: '/materialdestination', name: 'adminmaterialdestinations', component: AdminMaterialDestinations },
            { path: '/materialtypes', name: 'adminmaterialtypes', component: AdminMaterialTypes },
            { path: '/ringconfigurations', name: 'adminringconfigurations', component: AdminRingConfigurations },
            { path: '/sandpit', name: 'adminsandpit', component: AdminSandpit },
            {
                path: '/actualssources',
                name: 'adminactualphysicalssources',
                component: AdminActualPhysicalsSourceTypes,
            },
            { path: '/tarp', name: 'admintarpaspects', component: AdminTriggerActionResponsePlanAspects },
            {
                path: '/customlocationproperties',
                name: 'adminpropertydefinitions',
                component: AdminPropertyDefintitions,
            },
            { path: '/productionfronts', name: 'adminproductionfronts', component: AdminProductionFronts },
        ],
    },
    {
        path: '/404',
        alias: '*',
        name: 'Error404',
        component: Error404,
        meta: { noAuth: true },
    },
    {
        path: '*',
        redirect: '/404',
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
