import { Cycle } from '@/models/api';
import { IsBoggingTaskType, IsChargeTaskType, IsDrillTaskType, IsFireTaskType } from '@/lib/services/TaskType';

export * from '@/lib/services/Production/LocationRingStateMachine';

export function IsValidProductionRingCycle(cycle: Cycle) {
    if(cycle.tasks.some(t=>IsBoggingTaskType(t.taskType!) || IsFireTaskType(t.taskType!)))
        return false;

    const filteredForDrillingAndCharging= cycle.tasks.filter(t=>IsDrillTaskType(t.taskType!) || IsChargeTaskType(t.taskType!)).sort((a,b)=>a.order - b.order);

    if(filteredForDrillingAndCharging.length != 2)
        return false;

    return IsDrillTaskType(filteredForDrillingAndCharging[0].taskType!) && IsChargeTaskType(filteredForDrillingAndCharging[1].taskType!);
}

export function RemoveLocationNameFromBlastPacketOrRingName(blastPacketOrRingName: string | null | undefined, locationName: string | null | undefined) {
    if (!blastPacketOrRingName || !locationName) return blastPacketOrRingName ?? null;

    if(blastPacketOrRingName.toLowerCase().startsWith(locationName.toLowerCase()))
        return blastPacketOrRingName.substring(locationName.length).trim();

    return blastPacketOrRingName;
}