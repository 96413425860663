import { defineStore } from 'pinia';
import {
    RateTaskActualViewModel, RecordShiftSnapshotCommand, ShiftCrewMemberViewModel, ShiftLocationDetailViewModel,
    ShiftLocationsViewModel,
    SupervisorReportViewModel,
    TimeUnit, UpdateShiftLocationDetailsCommand
} from '@/models/api';
import { NextShiftDetails, ShiftDetails } from '@/lib/stores/ShiftWindowStore';
import dayjs, { Dayjs } from 'dayjs';
import { useMineAreaStore } from '@/lib/stores/MineAreaStore';
import _ from 'lodash';
import ClientRowModel from '@/models/client/client-row';
import { useHeadingsStore } from '@/lib/stores/HeadingsStore';
import { useSystemStore } from '@/lib/stores/SystemStore';
import { transformRowsToOfflineRows } from '@/lib/stores/Transforms';
import { useShiftDetails } from '@/lib/stores/Shift/ShiftDetailsStore';
import CurrentShiftStore, { SHIFT_VIEW_24H, SHIFT_VIEW_SHIFT } from '@/lib/stores/CurrentShiftStore';

export const useShiftDisplay = defineStore('shiftDisplay', {
    state: () => {
        return {
            isMultiShift: false as boolean,
        };
    },
    getters: {
        weekSafeDisplayTimeUnits(): TimeUnit[] {
            return !this.spansMultipleWeeks ? this.displayTimeUnits : useShiftDetails().timeUnits;
        },
        displayTimeUnits(): TimeUnit[] {
            return this.isMultiShift ? useShiftDetails().timeUnits24Hours : useShiftDetails().timeUnits;
        },
        weekSafeShiftDisplayStartTime(): Dayjs {
            return !this.spansMultipleWeeks ? this.shiftDisplayStartTime : useShiftDetails().shiftStartTime;
        },
        shiftDisplayStartTime(): Dayjs {
            return dayjs.utc(this.displayTimeUnits[0].startTime);
        },
        weekSafeShiftDisplayEndTime(): Dayjs {
            return !this.spansMultipleWeeks ? this.shiftDisplayEndTime : useShiftDetails().shiftEndTime;
        },
        shiftDisplayEndTime(): Dayjs {
            return this.isMultiShift ? useShiftDetails().lastShiftIn24HoursEndTime : useShiftDetails().shiftEndTime;
        },
        weekSafeDisplayTimeSlots(): string[] {
            return !this.spansMultipleWeeks ? this.displayTimeSlots : useShiftDetails().timeSlots;
        },
        displayTimeSlots(): string[] {
            return this.isMultiShift ? useShiftDetails().timeSlots24Hours : useShiftDetails().timeSlots;
        },
        weekSafeDisplayHourlyTimeSlots(): string[] {
            return !this.spansMultipleWeeks ? this.displayHourlyTimeSlots : useShiftDetails().hourlyTimeSlots;
        },
        displayHourlyTimeSlots(): string[] {
            return this.isMultiShift ? useShiftDetails().hourlyTimeSlots24Hours : useShiftDetails().hourlyTimeSlots;
        },
        displayShiftNames(): string[] {
              return this.isMultiShift ? useShiftDetails().shiftNames24Hours : [];
        },
        weekSafeDisplayShiftNames(): string[] {
            return !this.spansMultipleWeeks ? this.displayShiftNames : [];
        },
        weekSafeIsMultiShift(): boolean {
            return !this.spansMultipleWeeks ? this.isMultiShift : false;
        },
        spansMultipleWeeks(): boolean {
            const currentShiftWeekDate = useShiftDetails().weekDate;
            return useShiftDetails().nextShiftDetails.some((nextShiftDetails: NextShiftDetails) => {
                return nextShiftDetails.weekDate !== currentShiftWeekDate;
            });
        },
        canShowMultiShiftView(): boolean {
            return useShiftDetails().nextShiftDetails.length > 0;
        }
    },
    actions: {
        setUpStore() {
              this.isMultiShift = CurrentShiftStore.selectedShiftView === SHIFT_VIEW_24H;
        },
        setMultiShiftView() {
            this.isMultiShift = true;
            CurrentShiftStore.setShiftView(SHIFT_VIEW_24H);
        },
        setSingleShiftView(){
            this.isMultiShift = false;
            CurrentShiftStore.setShiftView(SHIFT_VIEW_SHIFT);
        },
        ensureMultiShiftStatusIsConsistentWithAvailability(){
            if(!this.canShowMultiShiftView && this.isMultiShift){
                this.setSingleShiftView();
            }
        }
    },
});
