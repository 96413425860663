import { defineStore } from 'pinia';
import {
    ActualPhysicalsEntryViewModel,
    BlastPacketLocationForOffsetViewModel,
    RingStatesLocationForWindowViewModel
} from '@/models/api';
import { ClientBlastPacketActualsEntry, ClientBlastPacketTargetCompletion } from '@/models/client/client-actuals';
import { transformActualsToClient } from '@/lib/stores/Transforms';
import ClientRowModel from '@/models/client/client-row';
import ClientTaskModel from '@/models/client/client-task';
import { AffectedAreaSpecification } from '@/lib/stores/HeadingsStore';
import selectedMineAreaStore from '@/lib/stores/SelectedMineAreaStore';
import { useDepartmentStore } from '@/lib/stores/DepartmentStore';
import {
    EvaluateRingStatesForHeadings
} from '@/lib/services/Production/LocationRingStates';
import {
    ClientRowBlastPacketInformation,
    ClientRowBlastPacketRingInformation
} from '@/lib/stores/Production/ShiftWindowActualsStore';
import { RowType } from '@/models/client/types/row-type';
import dayjs from 'dayjs';
import { ProductionValidationTaskModel } from '@/models/client/production-validation-task-model';
import { ClientTaskWarning } from '@/models/client/client-task-warning';

export const useWindowedActuals = defineStore('windowedActuals', {
    state: () => {
        return {
            locationBlastPacketRingStateInformation: [] as RingStatesLocationForWindowViewModel[],
            localBlastPacketTargetCompletions: [] as ClientBlastPacketTargetCompletion[],
            localActualPhysicalEntries: [] as ClientBlastPacketActualsEntry[],
            locationBlastPacketInformation: [] as ClientRowBlastPacketInformation[],
            clientCalculationTime: dayjs() as dayjs.Dayjs,
        };
    },
    actions: {
        setUpStore(clientCalculationTime: dayjs.Dayjs, locationBlastPacketInformation: ClientRowBlastPacketInformation[], locationRingStatesInformation: RingStatesLocationForWindowViewModel[], actualPhysicalEntries: ClientBlastPacketActualsEntry[], completions: ClientBlastPacketTargetCompletion[]) {
            this.locationBlastPacketRingStateInformation = locationRingStatesInformation;
            this.localBlastPacketTargetCompletions = completions;
            this.localActualPhysicalEntries = actualPhysicalEntries;
            this.locationBlastPacketInformation = locationBlastPacketInformation;
            this.clientCalculationTime = clientCalculationTime;
        },
        updateActuals(actualPhysicalEntries: ClientBlastPacketActualsEntry[]){
            this.localActualPhysicalEntries = actualPhysicalEntries;
        },
        updateCompletions(completions: ClientBlastPacketTargetCompletion[]){
            this.localBlastPacketTargetCompletions = completions;
        },
        reEvaluateRingStatesForHeading(headings: { rowType: RowType, stopeInfo: { stopeId: string | null } | null, location: { id: string, name: string | null } | null, tasks: ProductionValidationTaskModel[] }[], ringErrorAccumulator: (t: ProductionValidationTaskModel, affectedArea: AffectedAreaSpecification, warning: ClientTaskWarning)=>void) {
            const headingLocationIds = headings.map(h=>h.location?.id);

            if(headingLocationIds.some(id=>id==null))
                return;

            const currentMineTime = selectedMineAreaStore.getMineCurrentTime();
            const currentMineTimeComparableToTasks = currentMineTime.utc(true);

            const taskTypes = useDepartmentStore().taskTypes;
            const allActuals = this.localActualPhysicalEntries;

            const newStateInformation = EvaluateRingStatesForHeadings(currentMineTimeComparableToTasks, this.clientCalculationTime, headings, this.locationBlastPacketRingStateInformation, allActuals, this.localBlastPacketTargetCompletions,
                this.locationBlastPacketInformation, taskTypes, ringErrorAccumulator);

            if(newStateInformation == null)
                return;

            this.locationBlastPacketRingStateInformation= [...this.locationBlastPacketRingStateInformation.filter(l=>!headingLocationIds.includes(l.locationId ?? '')), ...newStateInformation];
        }
    }
});