import Auth from './Auth';

export const Roles = {
    ReadOnly: 'ReadOnly',
    Administrator: 'Administrator',
    UserAdministrator: 'UserAdministrator',
    SandpitManager: 'SandpitManager',
    WeekPlanner: 'WeekPlanner',
    ShiftPlanner: 'ShiftPlanner'
};

export default class Security {
    public static isAnyAdmin() {
        return Auth.hasRole(Roles.Administrator) || Auth.hasRole(Roles.UserAdministrator);
    }
    public static isReadOnly() {
        return Auth.hasRole(Roles.ReadOnly);
    }
    public static hasRole(role: string) {
        return Auth.hasRole(role);
    }
    public static hasAny(roles: string[]) {
        for (const role of roles) {
            if (Auth.hasRole(role)) return true;
        }
        return false;
    }
    public static isAuthenticated() {
        return Auth.isAuthenticated();
    }
    public static logout() {
        return Auth.logout();
    }
}
