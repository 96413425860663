import MineAreaStore from '@/lib/stores/MineAreaStore';
import CurrentShiftStore from '@/lib/stores/CurrentShiftStore';
import dayjs from 'dayjs';

export class SelectedMineAreaStore {
    public getSelectedMineArea() {
        if (!CurrentShiftStore.selectedMineArea || !MineAreaStore.mineAreas) {
            return null;
        }
        const selectedMine = MineAreaStore.mineAreas.filter(x => x.id == CurrentShiftStore.selectedMineArea);
        return selectedMine && selectedMine.length ? selectedMine[0] : null;
    }

    public getMineTimeZone() {
        const mine = this.getSelectedMineArea();
        return mine ? mine.timeZone : '';
    }

    public getMineCurrentTime(): dayjs.Dayjs {
        // @ts-ignore
        return dayjs().tz(this.getMineTimeZone())
    }
}
const instance = new SelectedMineAreaStore();
export default instance;
