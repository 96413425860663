import { DelayTypes, TaskClasses, TaskTypeViewModel } from '@/models/api';
import ClientTaskModel from '@/models/client/client-task';

export const DefaultTaskForegroundColour: string = '#ffffff';
export const DefaultTaskBackgroundColour: string = '#999999';

export function IsFloatDelayType(taskType: TaskTypeViewModel) {
    if (
        taskType?.isDelay &&
        (taskType?.delayType === DelayTypes.Float ||
            // @ts-ignore
            taskType?.delayType === 1 ||
            // @ts-ignore
            taskType?.delayType === 'Float')
    ) {
        return true;
    }

    return false;
}

export function IsFireTaskType(taskType: { taskClass: TaskClasses | null }) {
    return taskType.taskClass === TaskClasses.Fire;
}

export function IsDrillTaskType(taskType: { taskClass: TaskClasses | null }) {
    return taskType.taskClass === TaskClasses.Drilling;
}

export function IsChargeTaskType(taskType: { taskClass: TaskClasses | null }) {
    return taskType.taskClass === TaskClasses.Charge;
}

export function IsBoggingTaskType(taskType: { taskClass: TaskClasses | null }) {
    return taskType.taskClass === TaskClasses.ConventionalBogging || taskType.taskClass === TaskClasses.RemoteBogging;
}

export function IsRateType(taskType: { rateMetric: number | null | undefined }) {
    if (taskType.rateMetric === null || taskType.rateMetric === undefined) return false;

    return true;
}