import { GenericDataApi } from '@/lib/data/GenericDataApi';
import AppHttp from '@/lib/AppHttp';
import Errors from '@/lib/Errors';
import dayjs from 'dayjs';
import { DateFormat } from '@/plugins/dates';
import { AxiosError } from 'axios';
import RequestHandler from '../api/RequestHandler';

export class EquipmentAvailability extends GenericDataApi<any> {
    constructor() {
        super('EquipmentAvailability');
    }

    public async find(equipmentId: string, dateTime: string | number | dayjs.Dayjs | Date) {
        const from = dayjs(dateTime).format(DateFormat.CanonicalWith24hrTime);
        const response = await AppHttp.get(`EquipmentAvailability/Find/${equipmentId}/${from}`);
        const data = response ? response.data : null;
        return data;
    }

    public colorFor(e: number) {
        if (e == 0) {
            return 'green';
        }
        if (e == 1) {
            return 'amber';
        }
        if (e == 2) {
            return 'red';
        }
        if (e == 3) {
            return 'grey';
        }
        return 'black';
    }

    public nameFor(e: number) {
        if (e == 0) {
            return 'Weekly Service';
        }
        if (e == 1) {
            return 'Minor Service';
        }
        if (e == 2) {
            return 'Major Service';
        }
        if (e == 3) {
            return 'Unserviceable';
        }
        return 'Service';
    }

    public translationKeyFor(e: number) {
        // See src/plugins/i18n.ts.
        if (e == 0) {
            return 'shared.label.weekly-service';
        }
        if (e == 1) {
            return 'shared.label.minor-service';
        }
        if (e == 2) {
            return 'shared.label.major-service';
        }
        if (e == 3) {
            return 'shared.label.unserviceable';
        }
        return 'shared.label.service';
    }

    async uploadAvailabilityPlan(mineId: string, dateRange: { from: Date; to: Date }, file: any) {
        const response = await AppHttp.postWithFiles(
            `${this.resource}/mine/${mineId}/UploadAvailabilityPlan`,
            dateRange,
            [file]
        );
        const data = response ? response.data : null;
        return data;
    }

    async saveAvailabilityPlan(plan: any) {
        const response = await AppHttp.post(`${this.resource}/SaveAvailabilityPlan`, plan);
        const data = response ? response.data : null;
        return data;
    }

    async markAsServiceable(equipmentAvailabilityId: string, shiftId: string) {
        const response: any = await RequestHandler.execute(
            'POST',
            `${this.resource}/${equipmentAvailabilityId}/MarkAsServiceable/${shiftId}`,
            {}
        );
        const data = response ? response.data : null;
        return data;
    }

    async markAsUnserviceable(equipmentId: string, shiftId: string, equipmentAvailabilityId: string) {
        const response: any = await RequestHandler.execute(
            'POST',
            `${this.resource}/${equipmentId}/MarkAsUnserviceable/${shiftId}?id=${equipmentAvailabilityId}`,
            {}
        );
        const data = response ? response.data : null;
        return data;
    }

    async markAsInWorkshop(equipmentId: string, shiftId: string) {
        const response: any = await RequestHandler.execute(
            'POST',
            `${this.resource}/${equipmentId}/MarkAsInWorkshop/${shiftId}`,
            {}
        );
        const data = response ? response.data : null;
        return data;
    }

    async markAsNotInWorkshop(equipmentId: string, shiftId: string) {
        const response: any = await RequestHandler.execute(
            'POST',
            `${this.resource}/${equipmentId}/MarkAsNotInWorkshop/${shiftId}`,
            {}
        );
        const data = response ? response.data : null;
        return data;
    }

    async markAsNeededInWorkshop(equipmentId: string, shiftId: string) {
        const response: any = await RequestHandler.execute(
            'POST',
            `${this.resource}/${equipmentId}/MarkAsNeededInWorkshop/${shiftId}`,
            {}
        );
        const data = response ? response.data : null;
        return data;
    }

    async markAsNotNeededInWorkshop(equipmentId: string, shiftId: string) {
        const response: any = await RequestHandler.execute(
            'POST',
            `${this.resource}/${equipmentId}/MarkAsNotNeededInWorkshop/${shiftId}`,
            {}
        );
        const data = response ? response.data : null;
        return data;
    }
}

export default new EquipmentAvailability();
