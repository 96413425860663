import {
    COLUMN_IDS,
    ExtraColumnReasonToExist,
    SortDirection,
} from '@/components/Board/GroupingFilteringAndSorting';
import {
    CycleType,
    ExtendedPropertyInfo,
} from '@/models/api';
import { BoardGroupingType } from '@/components/ShiftBoard/Drawers/BoardGroupingDrawerProps';
import _, { curry } from 'lodash';
import { TimePeriod } from '@/models/client/time-block';
import { CollapseTimePeriods } from '@/lib/services/Location';
import { RowType } from '@/models/client/types/row-type';
import { ClientWeekBoardRow } from '@/models/client/client-week-board-row';
import { alphabeticSortingFunc, defineManipulationStore } from '@/lib/stores/BoardManipulation/shared';

export type HeadingClientWeekBoardRow = ClientWeekBoardRow & {
    occupiedTimes: TimePeriod[],
    representsRealLocation: boolean,
}

export interface GroupedWeekRow {
    headingRow: HeadingClientWeekBoardRow,
    additionalRows: ClientWeekBoardRow[]
}

function stopeGroupingFunc(headings: ClientWeekBoardRow[]): GroupedWeekRow[] {
    const headerRows = headings.filter(h=>h.location?.stopeInfo == null || h.location?.stopeInfo.stopeId === h.location?.id);

    const rowsGroupedByStope = _.groupBy(headings.filter(h=>h.location?.stopeInfo != null && h.location?.stopeInfo.stopeId !== h.location?.id), h=>h.location?.stopeInfo!.stopeId);

    return headerRows.map(hr=>
    {
        const childRows = rowsGroupedByStope[hr.location!.id] ?? [];

        const occupiedTimes = CollapseTimePeriods(_.flatMap(childRows, cr=>cr.tasks.filter(t=>!t.isDeleted && !t.taskType.isDelay)));

        return {
            headingRow: {
                ...hr,
                representsRealLocation: true,
                occupiedTimes: occupiedTimes
            },
            additionalRows: rowsGroupedByStope[hr.location!.id] ?? []
        }
    });
}

function levelGroupingFunc(headings: ClientWeekBoardRow[]): GroupedWeekRow[] {
    const groupedRows = _.groupBy(headings, h=>h.location?.reducedLevelName);

    return _.keys(groupedRows).map(k=>{
        const rows = groupedRows[k];
        const occupiedTimes = CollapseTimePeriods(_.flatMap(rows, cr=>cr.tasks.filter(t=>!t.isDeleted && !t.taskType.isDelay)));
        return {
            headingRow: {
                ...createAggregateRow(rows),
                occupiedTimes
            },
            additionalRows: rows
        };
    });
}

function extendedPropertyGroupingFunc(extendedPriorityId: string, headings: ClientWeekBoardRow[]): GroupedWeekRow[] {
    const groupedRows = _.groupBy(headings, h=>h.location?.extendedProperties.filter(ep=>ep.propertyDefinitionId === extendedPriorityId)[0]?.value ?? 'N/A');

    return _.keys(groupedRows).map(k=>{
        const rows = groupedRows[k];
        const occupiedTimes = CollapseTimePeriods(_.flatMap(rows, cr=>cr.tasks.filter(t=>!t.isDeleted && !t.taskType.isDelay)));
        return {
            headingRow: {
                ...createAggregateRowForExtendedProperty(rows, {
                    _type: 'ExtendedPropertyInfo',
                    id: '_',
                    propertyDefinitionId: extendedPriorityId,
                    value: k,
                }),
                occupiedTimes
            },
            additionalRows: rows
        };
    });
}

function createAggregateRowForExtendedProperty(groupedRows: ClientWeekBoardRow[], extendedProperty: ExtendedPropertyInfo): HeadingClientWeekBoardRow {
    return createAggregateRow(groupedRows, headingRow=>{
        return {
            ...headingRow,
            location: {
                ...headingRow.location!,
                extendedProperties: [
                    extendedProperty
                ]
            }
        };
    });
}

function createAggregateRow(groupedRows: ClientWeekBoardRow[], transformer: (headingRow: HeadingClientWeekBoardRow)=>HeadingClientWeekBoardRow = headingRow=>headingRow): HeadingClientWeekBoardRow {
    return transformer({
        availableCycles: [],
        cyclesPlanned: _.sumBy(groupedRows, x=>x.cyclesPlanned),
        cyclesRequired: _.sumBy(groupedRows, x=>x.cyclesRequired),
        id: `agg_${groupedRows[0].id}`,
        location: {
            id: `agg_${groupedRows[0].id}`,
            name: '',
            miningMethod: null,
            cycleType: groupedRows.some(x=>x.rowType === RowType.RATE) ? CycleType.Rate : CycleType.Duration,
            planningUpdateTag: null,
            parentLocationId: null,
            stopeInfo: null,
            reducedLevelName: _.uniq(groupedRows.map(x=>x.location?.reducedLevelName)).length === 1 ? _.uniq(groupedRows.map(x=>x.location?.reducedLevelName))[0] ?? '' : '',
            extendedProperties: [],
            commentary: null
        },
        metresToAdvance: groupedRows.some(x=>x.metresToAdvance != null) ? _.sumBy(groupedRows, x=>x.metresToAdvance ?? 0) : null,
        numCompletions: _.sumBy(groupedRows, x=>x.numCompletions),
        occupiedTimes: [],
        priority: _.min(groupedRows.map(x=>x.priority)) ?? 0,
        representsRealLocation: false,
        rowType: groupedRows.some(x=>x.rowType === RowType.RATE) ? RowType.RATE : RowType.DURATION,
        tasks: [],
        tonnesToFire: groupedRows.some(x=>x.tonnesToFire != null) ? _.sumBy(groupedRows, x=>x.tonnesToFire ?? 0) : null,
    });
}

const locationNameComparison = (a: ClientWeekBoardRow, b: ClientWeekBoardRow) => {
    if (a.location!.name! < b.location!.name!) {
        return -1;
    }
    if (a.location!.name! > b.location!.name!) {
        return 1;
    }

    return 0;
}

const sortLocations = (
    fallbackComparison: (a: ClientWeekBoardRow, b: ClientWeekBoardRow) => number,
    a: ClientWeekBoardRow,
    b: ClientWeekBoardRow,
    descending: boolean
) => {
    const multiplier = descending ? -1 : 1;

    if (a.location!.reducedLevelName! < b.location!.reducedLevelName!) {
        return -1 * multiplier;
    }
    if (a.location!.reducedLevelName! > b.location!.reducedLevelName!) {
        return 1 * multiplier;
    }

    return descending ? fallbackComparison(b,a) : fallbackComparison(a, b);
};

export function alphabeticAreaAndLocationNameSortingFunc(rows: GroupedWeekRow[], direction: SortDirection): GroupedWeekRow[] {
    const curriedSortLocations = curry(sortLocations)(locationNameComparison);

    const transformingCurriedSortLocations = (a: GroupedWeekRow, b: GroupedWeekRow)=>curriedSortLocations(a.headingRow, b.headingRow, direction === SortDirection.Descending);

    const groupedRows = [
        rows.filter((x) => x.headingRow.rowType === RowType.DURATION).map(x=>({
            headingRow: x.headingRow,
            additionalRows: [...x.additionalRows].sort((a, b) => curriedSortLocations(a, b, direction === SortDirection.Descending))
        })), // development rows
        rows.filter((x) => x.headingRow.rowType === RowType.RATE).map(x=>({
            headingRow: x.headingRow,
            additionalRows: [...x.additionalRows].sort((a, b) => curriedSortLocations(a, b, direction === SortDirection.Descending))
        })), // production rows
        rows.filter((x) => x.headingRow.rowType === RowType.ADHOC).map(x=>({
            headingRow: x.headingRow,
            additionalRows: [...x.additionalRows].sort((a, b) => curriedSortLocations(a, b, direction === SortDirection.Descending))
        })) // services rows
    ];

    return _.flatMap(groupedRows, x=>{
        return [...x].sort(transformingCurriedSortLocations);
    });
}

export function prioritySortingFunc(rows: GroupedWeekRow[], direction: SortDirection): GroupedWeekRow[] {
    const sortLocations = (a: ClientWeekBoardRow,b: ClientWeekBoardRow, direction: SortDirection)=>direction === SortDirection.Descending ? b.priority - a.priority : a.priority - b.priority;

    const transformingCurriedSortLocations = (a: GroupedWeekRow, b: GroupedWeekRow)=>sortLocations(a.headingRow, b.headingRow, direction);

    return rows
        .map(x=>({
            headingRow: x.headingRow,
            additionalRows: [...x.additionalRows].sort((a, b) => sortLocations(a, b, direction))
        }))
        .sort(transformingCurriedSortLocations);
}


export function levelSortingFunc(rows: GroupedWeekRow[], direction: SortDirection): GroupedWeekRow[] {
    return alphabeticSortingFunc(rows, direction, (row)=>row.location?.reducedLevelName ?? '');
}

export function generateExtendedPropertySortingFunc(propId: string): (rows: GroupedWeekRow[], direction: SortDirection)=> GroupedWeekRow[] {
    return _.curryRight(alphabeticSortingFunc)((row: ClientWeekBoardRow)=>row.location?.extendedProperties.filter(ep=>ep.propertyDefinitionId === propId)[0]?.value ?? '');
}

export const useWeekBoardColumnManipulation =
    defineManipulationStore<ClientWeekBoardRow, GroupedWeekRow>('weekBoardColumnManipulation', 'WEEK',
        extendedPropertyGroupingFunc,
        generateExtendedPropertySortingFunc,
        {
            type: BoardGroupingType.DEFAULT,
            id: COLUMN_IDS.STOPE,
            text: 'Stope',
            furtherInformation: '',
            groupingFunc: stopeGroupingFunc
        },
        {
            direction: SortDirection.Ascending,
            columnId: COLUMN_IDS.NULL,
            sortFunc: alphabeticAreaAndLocationNameSortingFunc
        } ,
        [
            {
                id: COLUMN_IDS.LEVEL,
                name: 'Level',
                columnNameOverride: 'AREA',
                columnSize: 'l',
                displayValueSelector: row=>row.location?.reducedLevelName ?? '',
                groupingDefinition: {
                    text: 'Area',
                    id: COLUMN_IDS.LEVEL,
                    type: BoardGroupingType.LEVEL,
                    furtherInformation: '',
                    groupingFunc: levelGroupingFunc
                },
                propertyFilter: null,
                sorting: {
                    sortFunc: levelSortingFunc
                },
                reasonOnBoard: ExtraColumnReasonToExist.Default,
            },
            {
                id: COLUMN_IDS.PRIORITY,
                name: 'Priority',
                columnNameOverride: 'PRIO',
                columnSize: 'l',
                displayValueSelector: row=>`${row.priority}`,
                groupingDefinition: null,
                propertyFilter: null,
                sorting: {
                    sortFunc: prioritySortingFunc
                },
                reasonOnBoard: ExtraColumnReasonToExist.Default,
            },
        ], true)();