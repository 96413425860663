import AppHttp from '@/lib/AppHttp';
import Errors from '@/lib/Errors';
import { AxiosError } from 'axios';

export class GenericDataApi<T> {
    public resource: string;
    constructor(resource: string) {
        this.resource = resource;
    }

    get createModelType(): string {
        return 'Create' + this.resource;
    }
    get updateModelType(): string {
        return 'Update' + this.resource;
    }

    async get(include?: string[]): Promise<T[]> {
        let query = '';
        if (include) {
            const p: string = include.map((x) => `include=${x}`).join('&');
            query = '?' + p;
        }
        const response = await AppHttp.get(this.resource + query);
        const data = response ? response.data : null;
        return data;
    }
    async one(id: string, include?: string[]): Promise<T> {
        let query = '';
        if (include) {
            const p: string = include.map((x) => `include=${x}`).join('&');
            query = '?' + p;
        }
        const response = await AppHttp.get(this.resource + `/${id}` + query);
        const data = response ? response.data : null;
        return data;
    }
    async save(model: any) {
        const response = await AppHttp.post(`${this.resource}`, this.cleanOutgoingData({ ...model }));
        const data = response ? response.data : null;
        return data;
    }
    async delete(id: string) {
        const response = await AppHttp.delete(`${this.resource}/${id}`);
        const data = response ? response.data : null;
        return data;
    }
    cleanOutgoingData(model: any) {
        return model;
    }
}
