import { render, staticRenderFns } from "./TaskDebug.vue?vue&type=template&id=b1af6c96&scoped=true&"
import script from "./TaskDebug.vue?vue&type=script&lang=ts&"
export * from "./TaskDebug.vue?vue&type=script&lang=ts&"
import style0 from "./TaskDebug.vue?vue&type=style&index=0&id=b1af6c96&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1af6c96",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VSimpleTable})
