import { GenericDataApi } from '@/lib/data/GenericDataApi';
import AppHttp from '@/lib/AppHttp';
import Errors from '@/lib/Errors';
import { AxiosError } from 'axios';
import {
    CreateCycleCommand,
    CreateTaskTypeCommand,
    Cycle,
    UpdateCycleCommand,
    TaskType,
    UpdateTaskTypeCommand
} from '@/models/api';

export class TaskTypes {
    private resource: string = 'TaskTypes';

    public async get(include?: string[]): Promise<TaskType[]> {
        let query = '';
        if (include) {
            const p: string = include.map((x) => `include=${x}`).join('&');
            query = '?' + p;
        }
        const response = await AppHttp.get(this.resource + query);
        const data = response ? response.data : null;
        return data;
    }

    public async update(command: UpdateTaskTypeCommand) {
        const response = await AppHttp.post(`${this.resource}/Update`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async create(command: CreateTaskTypeCommand) {
        const response = await AppHttp.post(`${this.resource}/Create`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async delete(id: string) {
        const response = await AppHttp.delete(`${this.resource}/${id}`);
        const data = response ? response.data : null;
        return data;
    }

    public async getByDepartment(departmentId: string): Promise<TaskType[]> {
        const response = await AppHttp.get(`${this.resource}/GetForDepartment?departmentId=${departmentId}`);

        const data = response ? response.data : null;
        return data;
    }

    public async export(departmentId: string) {
        const response = await AppHttp.get(`TaskTypes/export/${departmentId}`);
        const data = response ? response.data : null;
        return data;
    }

    public async import(departmentId: string, file: any) {
        const response = await AppHttp.postWithFiles(`TaskTypes/import/${departmentId}`, {}, [file]);
        const data = response ? response.data : null;
        return data;
    }
}

export default new TaskTypes();
