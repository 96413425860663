<template>
    <v-access-control>
        <template #offline>
            <offline-btn text></offline-btn>
        </template>
        <h3 class="subtitle-1">{{ name }}</h3>
        <v-hover v-slot:default="{ hover }">
            <v-img
                :src="previewSource"
                :gradient="
                    hover || isEmpty
                        ? 'to top right, rgba(255,201,14,.8),  rgba(88,223,85,.7), rgba(79,167,255,.8)'
                        : null
                "
                :max-height="200"
                :max-width="360"
                :min-height="200"
                :min-width="360"
                contain
            >
                <v-container fill-height pa-0 v-show="hover || isEmpty">
                    <v-layout row wrap align-center class="ma-0">
                        <v-flex class="text-center text-uppercase white--text">
                            <div v-if="!isEmpty && !$wait.is('uploading' + locationFileType)" class="mb-3">
                                <v-btn @click="show = true">
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </div>
                            <div v-if="canUpload">
                                <span v-if="$wait.is('uploading' + locationFileType)">Sending...</span>
                                <v-btn v-else outlined class="white--text" @click="$refs.inputUpload.click()"
                                    >Upload {{ name }}</v-btn
                                >
                                <input
                                    v-show="false"
                                    ref="inputUpload"
                                    accept="image/*, application/pdf"
                                    type="file"
                                    multiple
                                    @change="onUpload"
                                />
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-img>
        </v-hover>
        <v-dialog v-model="show" :width="'auto'" content-class="lightbox">
            <v-card v-if="show">
                <v-card-text class="pa-2 text-center" align-center>
                    <v-img
                        :src="imageSource"
                        :max-height="'80vh'"
                        :max-width="'90vw'"
                        :min-height="200"
                        :min-width="200"
                        contain
                        v-if="!isDocumentType"
                    ></v-img>

                    <object type="application/pdf" class="preview" v-if="isPdf" :data="imageSource" ref="obj"></object>
                </v-card-text>

                <v-card-actions>
                    <span class="body-1 ml-2">{{ uploadText }}</span>
                    <v-spacer></v-spacer>
                    <v-btn @click="onRemove" class="ml-3"> <v-icon left>mdi-delete</v-icon>Remove </v-btn>
                    <v-btn target="_blank" :href="imageSource" color="primary" class="ml-3">
                        <v-icon left>mdi-open-in-new</v-icon>Open For Printing
                    </v-btn>
                    <v-btn color="gray" text @click="show = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-access-control>
</template>
<script>
import dayjs from 'dayjs';

export default {
    props: ['model', 'name', 'canUpload'],
    data() {
        return { show: false, hover: false, reloading: false };
    },
    methods: {
        onUpload($event) {
            this.$emit('onUpload', $event, this.model.locationFileType);
        },
        onRemove() {
            this.$emit('onRemove', this.model.locationFileId);
            this.show = false;
        }
    },
    computed: {
        isEmpty() {
            return !this.imageSource;
        },
        previewSource() {
            if (this.isEmpty) return 'data:,';
            if (!this.isDocumentType) return this.imageSource;
            return '/img/doc-placeholder.png';
        },
        imageSource() {
            return this.model.imageSource;
        },
        isPdf() {
            return (this.contentType || '').includes('/pdf');
        },
        contentType() {
            return this.model.imageContentType;
        },
        locationFileType() {
            return this.model.locationFileType;
        },
        isDocumentType() {
            return this.isPdf;
        },
        uploadText() {
            let uploadDate = dayjs(this.model.uploadDate).format('YYYY-MM-DD HH:mm');
            return `${this.name} uploaded by ${this.model.userName} on ${uploadDate}`;
        }
    }
};
</script>
<style lang="scss">
.v-dialog.lightbox {
    width: auto;
}
object.preview {
    height: 80vh;
    width: 80vw;
}
</style>
