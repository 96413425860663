<template>
    <div class="pa-5">
        <v-card v-if="data">
            <v-card-title>
                <h1 class="title">{{ 'Crews' | term }}</h1>
                <v-btn small color="secondary" class="ml-3" @click="create()">
                    <v-icon left>mdi-plus</v-icon>Add
                </v-btn>
                <v-menu
                    v-model="importDialog"
                    max-width="310px"
                    :close-on-content-click="false"
                    v-if="!!data && !data.length"
                >
                    <template v-slot:activator="{ on }">
                        <v-alert
                            class="cannot-import"
                            dense
                            outlined
                            border="left"
                            type="warning"
                            v-if="roles.length === 0"
                            >Cannot import: No staff roles available.</v-alert
                        >
                        <v-btn v-on="on" small class="ml-1" color="secondary" :disabled="roles.length === 0">
                            <v-icon left>mdi-upload</v-icon>Import
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-text>
                            <input ref="inputUpload" type="file" accept="text/csv" @change="getFile($event)" />
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="secondary" :disabled="file === null" @click="importData">Upload</v-btn>
                            <v-btn text @click="importDialog = false">cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
                <v-btn small class="ml-1" color="gray" v-if="!!data && data.length" @click="exportData()">
                    <v-icon left>mdi-download</v-icon>Export
                </v-btn>
                <v-spacer></v-spacer>
                <admin-sandpit-visibility-selector></admin-sandpit-visibility-selector>
            </v-card-title>
            <v-data-table disable-pagination hide-default-footer :headers="headers" :items="filteredCrews">
                <template v-slot:item.id="{ item }">
                    <v-btn text icon @click="edit(item)" class="float-right">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <template v-slot:item.staff="{ item }">{{ item.staff.length }}</template>
                <template v-slot:item.name="{ item }">
                    <admin-sandpit-item-name :is-sandpit="item.isSandpitUseOnly">{{ item.name }}</admin-sandpit-item-name>
                </template>
            </v-data-table>
        </v-card>
        <standard-dialog :render="!!selected" :value='showModal' :max-width="900" persistent>
            <template v-slot:title>
                <span v-if="selected.id">Edit</span>
                <span v-else>Create</span>
                <span class="ml-1">{{ 'Crew' | term }}</span>
            </template>
            <template v-slot:content>
                <v-form ref="form" :lazy-validation="true">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" class="pa-2">
                                <v-text-field
                                    autocomplete="off"
                                    label="Name"
                                    :rules="[$rules.required(), $rules.maxLength(50)]"
                                    v-model="selected.name"
                                    counter="50"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pa-2">
                                <v-select
                                    v-model="selected.departmentIds"
                                    :items="getAvailableDepartmentsForCrew(selected)"
                                    item-text="name"
                                    item-value="id"
                                    :menu-props="{ maxHeight: '400' }"
                                    label="Select Departments"
                                    multiple
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pa-2">
                                <h3 class="subtitle-1">Roles</h3>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="3" class="pa-2">Name</v-col>
                            <v-col cols="1" class="pa-2">Count</v-col>
                        </v-row>
                        <v-row v-for="role in selected.crewRolesModel" :key="role.staffRoleId">
                            <v-col cols="3" class="pa-2 pt-4">
                                {{ getRole(role.staffRoleId).name }}
                            </v-col>
                            <v-col cols="1" class="pa-2">
                                <v-text-field
                                    class="mt-0 pt-0"
                                    type="number"
                                    v-model="role.count"
                                    :rules="[$rules.min(0)]"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </template>
            <template v-slot:actions>
                <v-btn color='red' text v-if='selected && selected.id' @click='del()'>Delete</v-btn>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
                <v-btn color='primary' text @click='save()'>Save</v-btn>
            </template>
            <template v-slot:offline-actions>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
                <offline-btn text></offline-btn>
            </template>
        </standard-dialog>
        <confirm ref="confirm"></confirm>
    </div>
</template>
<script>
import { EventBus, Events } from '@/lib/EventBus';
import Crews from '@/lib/data/Crews';
import Departments from '@/lib/data/Departments';
import StaffRoles from '@/lib/data/StaffRoles';
import DownloadHelper from '@/lib/DownloadHelper';
import { v4 as uuidv4 } from 'uuid';
import { mapState } from 'pinia';
import { AdminSandpitVisibility, useSandpitStore } from '@/lib/stores/SandpitStore';
import AdminSandpitVisibilitySelector from '@/components/Admin/Sandpit/AdminSandpitVisibilitySelector.vue';
import AdminSandpitItemName from '@/components/Admin/Sandpit/AdminSandpitItemName.vue';

export default {
    components: {
        AdminSandpitVisibilitySelector,
        AdminSandpitItemName
    },
    data() {
        return {
            data: null,
            showModal: false,
            selected: null,
            roles: null,
            importDialog: false,
            file: null,
            departments: [],
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Staff', value: 'staff' },
                { text: '', value: 'id' }
            ]
        };
    },
    async created() {
        await this.loadData();
    },
    destroyed() {},
    methods: {
        async loadData() {
            var data = await Crews.get(['Staff', 'Staff.StaffRole', 'Departments']);
            this.roles = await StaffRoles.get();

            for (let role of this.roles) {
                for (let crew of data) {
                    if(role.isSandpitUseOnly !== crew.isSandpitUseOnly)
                        continue; // skip role-crew combinations that don't share sandpit status
                    var matching = crew.crewRolesModel.filter(x => x.staffRoleId == role.id);
                    if (matching.length == 0) {
                        crew.crewRolesModel.push({ staffRoleId: role.id, count: 0 });
                    }
                    crew.crewRolesModel = crew.crewRolesModel
                        .filter(x => {
                            var matchedRole = this.roles.filter(r => r.id == x.staffRoleId);
                            var staffRole = matchedRole.length ? matchedRole[0] : null;
                            return !x.isDeleted && !(staffRole == null || staffRole.isDeleted);
                        })
                        .sort((_a, _b) => {
                            var a = this.roles.filter(x => x.id == _a.staffRoleId);
                            var b = this.roles.filter(x => x.id == _b.staffRoleId);
                            return a.displayOrder - b.displayOrder;
                        });
                }
            }

            this.data = data;

            const departments = await Departments.get(['MineArea']);
            const departmentNames = departments.map(department => ({
                id: department.id,
                name: `${department.mineArea.name} - ${department.name}`,
                isSandpit: department.mineArea.isSandpit
            }));
            const sortedDepartents = departmentNames.sort((a, b) => (a.name > b.name ? 1 : -1));
            this.departments = sortedDepartents;
        },
        getRole(id) {
            var filtered = this.roles.filter(x => x.id == id);
            return filtered.length ? filtered[0] : {};
        },
        create() {
            this.edit({
                id: uuidv4(),
                name: '',
                departments: [],
                isSandpitUseOnly: false,
            });
        },
        edit(item) {
            this.selected = { ...item, departmentIds: item.departments.map(x => x.id) };
            this.showModal = true;
            setTimeout(() => this.$refs.form.resetValidation(), 1);
        },
        async del() {
            if (await this.$refs.confirm.openAsDeleteResource(this.$termSync('Crew'),  { })) {
                await Crews.delete(this.selected.id);
                EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Crew')} Deleted`);
                this.showModal = false;
                await this.loadData();
            }
        },
        async save() {
            if (!this.$refs.form.validate()) {
                return;
            }
            await Crews.saveCrew(this.selected);
            EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Crew')} Saved`);
            this.showModal = false;
            await this.loadData();
        },
        async exportData() {
            const result = await Crews.export();
            var csv = result.csvFile;
            var blob = DownloadHelper.makeBlobFromFileString(csv);
            DownloadHelper.download('Crews.csv', blob);
        },
        async importData() {
            try {
                this.$wait.start('saving');
                this.uploadResult = null;
                await Crews.import(this.file);
                this.importDialog = false;
                await this.loadData();
            } finally {
                this.$wait.end('saving');
            }
        },
        async getFile(e) {
            this.file = e.target.files[0];
        },
        getAvailableDepartmentsForCrew(crew) {
            return this.departments.filter(d => d.isSandpit === crew.isSandpitUseOnly);
        }
    },
    computed: {
        ...mapState(useSandpitStore, ['adminSandpitVisibility']),
        filteredCrews() {
            return this.data?.filter(this.sandpitFilter);
        },
        sandpitFilter() {
            if(this.adminSandpitVisibility === AdminSandpitVisibility.BOTH)
                return x=>true;
            else if(this.adminSandpitVisibility === AdminSandpitVisibility.ONLY_SANDPIT)
                return x=>x.isSandpitUseOnly;
            else
                return x=>!x.isSandpitUseOnly;
        },
    }
};
</script>
<style lang="scss" scoped></style>
