import { ICommandMapper } from '../ICommandMapper';
import { AnswerQuestionCommand } from '@/models/api';

export class AnswerQuestionCommandMapper implements ICommandMapper<AnswerQuestionCommand> {
    commandType = 'AnswerQuestionCommand';
    mappers = [
        {
            viewmodelType: 'Meeting',
            map(command: AnswerQuestionCommand, model: any): any | null {
                if (command.meetingId != model.id) {
                    return model;
                }

                for (const answer of model.answers) {
                    if (answer.questionId != command.questionId) {
                        continue;
                    }
                    answer.answer = command.answer;
                    answer.answerText = command.shortAnswer;
                }

                return model;
            }
        }
    ];
}

export default new AnswerQuestionCommandMapper();
