import { useDepartmentStore } from '@/lib/stores/DepartmentStore';
import { useMineAreaStore } from '@/lib/stores/MineAreaStore';
import { useSystemStore } from '@/lib/stores/SystemStore';
import { useShiftWindowStore } from './ShiftWindowStore';
import { useShiftDisplay } from '@/lib/stores/Shift/ShiftDisplayStore';
import { useBoardOptions } from '@/lib/stores/BoardOptionsStore';

export const populateStoresForShift = async (
    mineId: string,
    departmentId: string,
    date: string,
    shiftNumber: string
) => {
    await useMineAreaStore().initializeMineAreaStore(mineId);
    const systemStore = useSystemStore();
    if (systemStore.isInitialized === false) {
        await systemStore.initializeStore();
    }

    const departmentStore = useDepartmentStore();
    if (departmentStore.isInitialized === false) {
        await departmentStore.initializeStore(departmentId);
    }
    await Promise.all([useShiftWindowStore().populateWindow(mineId, departmentId, date, shiftNumber)]);
    useShiftDisplay().setUpStore();
    useBoardOptions().setUpStore();
};
