<template>
    <div>
        <print-window ref="printwindow"></print-window>
        <standard-dialog
            v-model='dialog'
            :max-width="500"
            :isLoading="isContentLoading"
            loaderName="Shift Variance Report"
            :hasError="!snapshotsPreviousShift || snapshotsPreviousShift.length <= 0"
        >
            <template v-slot:title>
                {{ !snapshotsPreviousShift || snapshotsPreviousShift.length == 0 ? '' : 'Compare Last 24Hr Actuals to Published Plan'}}
            </template>
            <template v-slot:content>
                <v-row>
                    <v-col>
                        <v-select
                            v-if="snapshotsCurrentShift && snapshotsCurrentShift.length > 0"
                            label="This shift published baseline"
                            v-model="selectedSnapshotCurrentShift"
                            :items="snapshotsCurrentShift"
                            item-text="name"
                            item-value="id"
                        >
                            <template v-slot:item="{ item }">
                                {{ item.name }}
                                <v-icon class="ml-1" small color="primary" v-if="item.isPublish"
                                >mdi-cloud-check-variant</v-icon
                                >
                                <v-spacer></v-spacer>
                                <v-chip small v-if="item.snapshottedAt">{{
                                        item.snapshottedAt | formatDate(dateFormat)
                                    }}</v-chip>
                            </template>
                            <template v-slot:selection="{ item }">
                                {{ item.name }}
                                <v-chip small v-if="item.snapshottedAt">
                                    {{ item.snapshottedAt | formatDate(dateFormat) }}
                                </v-chip>
                            </template>
                            <template
                                v-if="selectedSnapshotCurrentShift && selectedSnapshotCurrentShift.length > 1"
                            >
                                <v-divider></v-divider>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select
                            v-if="selectedSnapshotPreviousShift && selectedSnapshotPreviousShift.length > 0"
                            label="Previous shift published baseline"
                            v-model="selectedSnapshotPreviousShift"
                            :items="snapshotsPreviousShift"
                            item-text="name"
                            item-value="id"
                        >
                            <template v-slot:item="{ item }">
                                {{ item.name }}
                                <v-icon class="ml-1" small color="primary" v-if="item.isPublish"
                                >mdi-cloud-check-variant</v-icon
                                >
                                <v-spacer></v-spacer>
                                <v-chip small v-if="item.snapshottedAt">{{
                                        item.snapshottedAt | formatDate(dateFormat)
                                    }}</v-chip>
                            </template>
                            <template v-slot:selection="{ item }">
                                {{ item.name }}
                                <v-chip small v-if="item.snapshottedAt">
                                    {{ item.snapshottedAt | formatDate(dateFormat) }}
                                </v-chip>
                            </template>
                            <template
                                v-if="selectedSnapshotCurrentShift && selectedSnapshotCurrentShift.length > 1"
                            >
                                <v-divider></v-divider>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn color="primary" @click="performPrintVarianceReport">Variance Report</v-btn>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:actions>
                <v-spacer></v-spacer>
                <v-btn text @click="dialog = false">Cancel</v-btn>
            </template>
            <template v-slot:error-content>
                <v-alert type="warning" class="white--text mt-4">Baseline For Previous Shift Not Found</v-alert>
            </template>
        </standard-dialog>
    </div>
</template>
<script>
import Shift from '@/lib/data/Shift';
import { EventBus, Events } from '@/lib/EventBus';
import { DateFormat } from '@/plugins/dates';
import Account from '@/lib/data/Account';
import Tenants from '@/lib/Tenants';

export default {
    props: ['shiftId', 'departmentName', 'departmentId', 'date', 'shift'],
    data() {
        return {
            dialog: false,
            snapshotsPreviousShift: [],
            selectedSnapshotPreviousShift: null,
            snapshotsCurrentShift: [],
            selectedSnapshotCurrentShift: null,
            dateFormat: DateFormat.DefaultDateTimeDisplay,
            previousShiftId: null
        };
    },
    async created() {
        EventBus.$on(Events.ReportClicked, this.report);
    },
    destroyed() {
        EventBus.$off(Events.ReportClicked, this.report);
    },
    methods: {
        async loadData() {
            this.$wait.start('loading_shift_variance');

            try {
                this.previousShiftId = await Shift.getPreviousShiftId(this.shiftId);
                if (this.previousShiftId) {
                    this.snapshotsPreviousShift = await Shift.getShiftSnapshots(this.previousShiftId);
                    if (
                        this.snapshotsPreviousShift &&
                        this.snapshotsPreviousShift.length > 0 &&
                        this.snapshotsPreviousShift[0]
                    ) {
                        this.selectedSnapshotPreviousShift = this.snapshotsPreviousShift[
                            this.snapshotsPreviousShift.length - 1
                        ].id;
                    }
                }

                this.snapshotsCurrentShift = await Shift.getShiftSnapshots(this.shiftId);
                const current = { id: 'Current', name: 'Current Plan', isPublish: false, snapshottedAt: null };
                var baselines = [current];
                if (this.snapshotsCurrentShift && this.snapshotsCurrentShift.length > 0) {
                    // API has snapshots already ordered by date decending
                    const publishedMostRecently = this.snapshotsCurrentShift.find(s => s.isPublish);
                    baselines = publishedMostRecently
                        ? this.snapshotsCurrentShift.filter(s => s.id !== publishedMostRecently.id)
                        : this.snapshotsCurrentShift;
                    baselines.unshift({ divider: true });
                    baselines.unshift(current);
                    if (publishedMostRecently) baselines.unshift(publishedMostRecently);
                }
                this.snapshotsCurrentShift = baselines;
                if (
                    this.snapshotsCurrentShift &&
                    this.snapshotsCurrentShift.length > 0 &&
                    this.snapshotsCurrentShift[0]
                ) {
                    this.selectedSnapshotCurrentShift = this.snapshotsCurrentShift[
                        this.snapshotsCurrentShift.length - 1
                    ].id;
                }
            } catch (e) {
                return;
            } finally {
                this.$wait.end('loading_shift_variance');
            }
        },
        async report() {
            this.dialog = true;
            await this.loadData();
        },
        performPrintVarianceReport() {
            this.dialog = false;
            const url = `/Print/${Tenants.GetCurrentTenant()}/Report/Variance/${this.previousShiftId}/${this.selectedSnapshotPreviousShift}/${this.shiftId}/${this.selectedSnapshotCurrentShift}/VarianceReport-${this.fileName}.pdf`;
            this.$refs.printwindow.show(url);
        },
        safeDeptName(name) {
            return name.replace(/[/\\?%*:|"<>]/g, '');
        }
    },
    computed: {
        fileName() {
            const snapshot = this.snapshotsCurrentShift.find(s => s.id === this.selectedSnapshotCurrentShift);
            return `${this.safeDeptName(this.departmentName)}-${this.date}-${this.shift}-${snapshot.name}`;
        },
        isContentLoading() {
            return this.$wait.is('loading_shift_variance') || this.$wait.is('saving');
        }
    }
};
</script>
