import { ICommandMapper } from '../ICommandMapper';

export class CreateStaffCommandMapper implements ICommandMapper<any> {
    commandType = 'CreateStaff';
    mappers = [
        {
            viewmodelType: 'WeekCrew[]',
            map(command: any, model: any[]): any[] | null {
                for (const crew of model) {
                    if (crew.crewId == command.crewId) {
                        crew.staff.push({
                            staffId: command.id,
                            staff: command
                        });
                    }
                }

                return model;
            }
        }
    ];
}

export default new CreateStaffCommandMapper();
