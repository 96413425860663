import { GenericDataApi } from '@/lib/data/GenericDataApi';
import AppHttp from '@/lib/AppHttp';
import Errors from '@/lib/Errors';
import EditMappings from '../OfflineEdits/EditMappings';
import {
    SetCrewForShiftCommand,
    AddRoleToShiftCommand,
    ClearEquipmentAssignmentsForShiftCommand,
    CommandResult,
    StaffForWeekQueryResponse,
    WeekCrew as WeekCrewModel,
    GetShiftResourceAllocationCopyTargetQueryResult,
    CopyResourceAllocationFromPreviousShiftCommandResult
} from '@/models/api';
import { AxiosError } from 'axios';
import RequestHandler from '../api/RequestHandler';

export class WeekCrew extends GenericDataApi<any> {
    constructor() {
        super('WeekCrews');
    }

    async getForWeek(departmentId: string, date: string, include?: string[]): Promise<WeekCrewModel[]> {
        let query = '';
        if (include) {
            const p: string = include.map((x) => `include=${x}`).join('&');
            query = '?' + p;
        }
        const response = await AppHttp.get(`${this.resource}/Department/${departmentId}/ForWeek/${date}${query}`);
        const data = response ? response.data : null;
        return await EditMappings.applyPendingEdits('WeekCrew[]', data);
    }

    async getForShift(departmentId: string, shiftId: string, include?: string[]) {
        let query = '';
        if (include) {
            const p: string = include.map((x) => `include=${x}`).join('&');
            query = '?' + p;
        }
        const response = await AppHttp.get(
            `${this.resource}/Department/${departmentId}/AllForShift/${shiftId}/${query}`
        );
        const data = response ? response.data : null;
        return await EditMappings.applyPendingEdits('ShiftCrew[]', data);
    }
    async setForShift(command: SetCrewForShiftCommand) {
        const response: any = await RequestHandler.execute('POST', `${this.resource}/SetCrewForShift`, command);
        const data = response ? response.data : null;
        return data;
    }

    async clearEquipmentAssignmentsForShift(command: ClearEquipmentAssignmentsForShiftCommand) {
        const response: any = await RequestHandler.execute(
            'POST',
            `${this.resource}/ClearEquipmentAssignmentsForShift`,
            command
        );
        const data = response ? (response.data as CommandResult) : null;
        return data;
    }

    async getStaffWithLeave(departmentId: string, date: string): Promise<StaffForWeekQueryResponse[]> {
        const response = await AppHttp.get(`${this.resource}/Department/${departmentId}/StaffForWeek/${date}`);
        const data = response ? response.data : null;
        return data;
    }

    async setCrew(staffId: string, toWeekCrewId: string | null, deparmentWeekId: string) {
        const response = await AppHttp.post(
            `${this.resource}/DepartmentWeek/${deparmentWeekId}/WeekCrew/${toWeekCrewId}/SetStaff/${staffId}`,
            {}
        );
        const data = response ? response.data : null;
        return data;
    }

    async getShiftEquipmentCopyInfo(shiftId: string, crewIds: string[]): Promise<GetShiftResourceAllocationCopyTargetQueryResult> {
        const response = await AppHttp.post(
            `${this.resource}/GetShiftResourceCopyInfo`,
            {
                _type: 'GetShiftResourceAssignmentCopyTargetQuery',
                shiftId: shiftId,
                crewsAssigned: crewIds
            }
        );
        const data = response ? response.data : null;
        return data;
    }

    async copyAllocationsFromPreviousShift(currentShiftId: string, copyShiftId: string): Promise<CopyResourceAllocationFromPreviousShiftCommandResult> {
        const response = await AppHttp.post(
            `${this.resource}/CopyPreviousResourceAllocations`,
            {
                _type: 'CopyResourceAllocationFromPreviousShiftCommand',
                currentShiftId: currentShiftId,
                copyShiftId: copyShiftId
            }
        );
        const data = response ? response.data : null;
        return data;
    }

    async addShiftRole(command: AddRoleToShiftCommand) {
        const response: any = await RequestHandler.execute('POST', `${this.resource}/AddShiftRole`, command);
        const data = response ? (response.data as CommandResult) : null;
        return data;
    }
    async saveLeave(staffId: string, command: any) {
        const response: any = await RequestHandler.execute('POST', `${this.resource}/Staff/${staffId}/Leave`, command);
        const data = response ? response.data : null;
        return data;
    }

    async uploadStaffAvailabilityPlan(
        departmentId: string,
        constraints: { dateRange: { from: Date; to: Date }; availabilityTypes: string[] },
        file: any
    ) {
        const response = await AppHttp.postWithFiles(
            `${this.resource}/department/${departmentId}/UploadStaffAvailabilityPlan`,
            constraints,
            [file]
        );
        const data = response ? response.data : null;
        return data;
    }

    async saveStaffAvailabilityPlan(departmentId: string, plan: any) {
        const response = await AppHttp.post(
            `${this.resource}/department/${departmentId}/SaveStaffAvailabilityPlan`,
            plan
        );
        const data = response ? response.data : null;
        return data;
    }
}

export default new WeekCrew();
