<template>
    <div class="pa-5">
        <v-card v-if="data">
            <v-card-title>
                <h1 class="title">{{ 'Material Destinations' | term }}</h1>
                <v-btn small color="secondary" class="ml-3" @click="create()" v-if="!!selectedMineArea">
                    <v-icon left>mdi-plus</v-icon>Add
                </v-btn>
                <v-spacer></v-spacer>
                <v-select
                    :items="mineAreas"
                    item-value="id"
                    item-text="name"
                    v-model="selectedMineArea"
                    :label="$termSync('Mine Area')"
                    single-line
                    hide-details
                    class="admin-table-filter"
                    :menu-props="{ dark: true }"
                ></v-select>
            </v-card-title>
            <v-data-table
                disable-pagination
                hide-default-footer
                :headers="headers"
                :items="filtered"
                v-if="!!selectedMineArea"
            >
                <template v-slot:item.id="{ item }">
                    <v-btn text icon @click="edit(item)" class="float-right">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <empty-state
                v-if="!selectedMineArea"
                icon="mdi-alert-circle-outline"
                :message="'Select a ' + $termSync('Mine Area')"
            ></empty-state>
        </v-card>
        <standard-dialog :render="!!selected" :value='showModal' :max-width="500" persistent>
            <template v-slot:title>
                <span v-if="selected.id">Edit</span>
                <span v-else>Create</span>
                <span class="ml-1">{{ 'Material Destination' | term }}</span>
            </template>
            <template v-slot:content>
                <v-form ref="form" :lazy-validation="true">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="8" class="pa-2">
                                <v-text-field
                                    autocomplete="off"
                                    label="Name"
                                    :rules="[$rules.required(), $rules.maxLength(50)]"
                                    v-model="selected.name"
                                    counter="80"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4" class="pa-2">
                                <v-text-field
                                    autocomplete="off"
                                    label="Abbreviation"
                                    :rules="[$rules.required(), $rules.maxLength(50)]"
                                    v-model="selected.abbreviation"
                                    counter="20"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </template>
            <template v-slot:actions>
                <v-btn color='red' text v-if='selected && selected.id' @click='del()'>Delete</v-btn>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
                <v-btn color='primary' text @click='save()'>Save</v-btn>
            </template>
            <template v-slot:offline-actions>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
                <offline-btn text></offline-btn>
            </template>
        </standard-dialog>
        <confirm ref="confirm"></confirm>
    </div>
</template>
<script>
import { EventBus, Events } from '@/lib/EventBus';
import MineAreas from '@/lib/data/MineAreas';
import MaterialDestinations from '@/lib/data/MaterialDestinations';

export default {
    data() {
        return {
            data: null,
            mineAreas: null,
            selectedMineArea: null,
            showModal: false,
            selected: null,
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Abbreviation', value: 'abbreviation' },
                { text: '', value: 'id' }
            ]
        };
    },
    async created() {
        await this.loadData();
    },
    destroyed() {},
    methods: {
        async loadData() {
            this.mineAreas = await MineAreas.get();
            this.data = await MaterialDestinations.get();
        },
        create() {
            this.edit({
                mineAreaId: this.selectedMineArea,
                name: '',
                abbreviation: ''
            });
        },
        edit(item) {
            this.selected = { ...item };
            this.showModal = true;
            setTimeout(() => this.$refs.form.resetValidation(), 1);
        },
        async save() {
            if (!this.$refs.form.validate()) {
                return;
            }
            await MaterialDestinations.save(this.selected);
            EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Material Destination')} Saved`);
            this.showModal = false;
            await this.loadData();
        },
        async del() {
            if (await this.$refs.confirm.openAsDeleteResource(this.$termSync('Material Destination'),  { })) {
                await MaterialDestinations.delete(this.selected.id);
                EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Material Destination')} Deleted`);
                this.showModal = false;
                await this.loadData();
            }
        }
    },
    computed: {
        filtered() {
            if (!this.data || !this.selectedMineArea) {
                return [];
            }
            return this.data.filter(x => x.mineAreaId == this.selectedMineArea);
        }
    }
};
</script>
