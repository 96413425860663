import { GenericDataApi } from '@/lib/data/GenericDataApi';
import { AxiosError } from 'axios';
import RequestHandler from '../api/RequestHandler';
import AppHttp from '../AppHttp';
import Errors from '../Errors';
import {
    CreateLocationCommand,
    CreateStaffCommand,
    Staff as StaffModel,
    UpdateLocationCommand,
    UpdateStaffCommand
} from '@/models/api';
import { Location } from '@/models/api/Data/location';

export class Staff {
    resource: string = 'Staff';

    public async add(staffMember: CreateStaffCommand) {
        await RequestHandler.execute('POST', `${this.resource}/Create`, staffMember);
    }

    public async one(id: string, include?: string[]): Promise<StaffModel> {
        let query = '';
        if (include) {
            const p: string = include.map((x) => `include=${x}`).join('&');
            query = '?' + p;
        }
        const response = await AppHttp.get(`${this.resource}/${id}` + query);
        const data = response ? response.data : null;
        return data;
    }

    public async get(include?: string[]): Promise<StaffModel[]> {
        let query = '';
        if (include) {
            const p: string = include.map((x) => `include=${x}`).join('&');
            query = '?' + p;
        }
        const response = await AppHttp.get(this.resource + query);
        const data = response ? response.data : null;
        return data;
    }

    public async update(command: UpdateStaffCommand) {
        const response = await AppHttp.post(`${this.resource}/Update`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async updateCrew(staffId: string, crewId: string) {
        const response = await AppHttp.put(`${this.resource}/${staffId}/Crew/${crewId}`, null);
        const data = response ? response.data : null;
        return data;
    }

    public async create(command: CreateStaffCommand) {
        const response = await AppHttp.post(`${this.resource}/Create`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async delete(id: string) {
        const response = await AppHttp.delete(`${this.resource}/${id}`);
        const data = response ? response.data : null;
        return data;
    }

    public async archive(id: string) {
        const response = await AppHttp.put(`${this.resource}/${id}/Archive`, null);
        const data = response ? response.data : null;
        return data;
    }

    public async restoreFromArchive(id: string) {
        const response = await AppHttp.put(`${this.resource}/${id}/Restore`, null);
        const data = response ? response.data : null;
        return data;
    }

    public async export() {
        const response = await AppHttp.get(`${this.resource}/export`);
        const data = response ? response.data : null;
        return data;
    }

    public async import(file: any) {
        const response = await AppHttp.postWithFiles(`${this.resource}/import`, {}, [file]);
        const data = response ? response.data : null;
        return data;
    }
}

export default new Staff();
