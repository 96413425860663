<template>
    <v-tooltip bottom>
        <template #activator="{ on }">
            <v-btn :text="text" v-on="on">
                <v-icon left>mdi-network-strength-4-alert</v-icon>
                No Connection
            </v-btn>
        </template>
        <span
            >Your internet connection is not currently active. If this is only temporary please wait a moment and this
            page will become accessible again.</span
        >
    </v-tooltip>
</template>

<script>
export default {
    props: ['text']
};
</script>
