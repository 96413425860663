import {
    CreateRingConfigurationCommand,
    CreateTaskTypeCommand,
    RingConfiguration,
    TaskType,
    UpdateRingConfigurationCommand,
    UpdateTaskTypeCommand
} from '@/models/api';
import AppHttp from '@/lib/AppHttp';

export class RingConfigurations {
    private resource: string = 'RingConfigurations';

    public async get(mineAreaId: string): Promise<RingConfiguration[]> {
        const response = await AppHttp.get(`${this.resource}/MineArea/${mineAreaId}`);
        const data = response ? response.data?.configurations : [];
        return data;
    }

    public async update(command: UpdateRingConfigurationCommand) {
        const response = await AppHttp.post(`${this.resource}/Update`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async create(command: CreateRingConfigurationCommand) {
        const response = await AppHttp.post(`${this.resource}/Create`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async delete(id: string) {
        const response = await AppHttp.delete(`${this.resource}/${id}`);
        const data = response ? response.data : null;
        return data;
    }

    public async export(mineAreaId: string) {
        const response = await AppHttp.get(`${this.resource}/export/${mineAreaId}`);
        const data = response ? response.data : null;
        return data;
    }

    public async import(mineAreaId: string, file: any) {
        const response = await AppHttp.postWithFiles(`${this.resource}/import/${mineAreaId}`, {}, [file]);
        const data = response ? response.data : null;
        return data;
    }
}

export default new RingConfigurations();
