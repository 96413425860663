import { CommandResult, MessageTypes } from '@/models/api';
import { EventBus, Events } from './EventBus';
const messageTypeToToast = (messageType: MessageTypes) => {
    switch (messageType) {
        case MessageTypes.Information:
            return Events.ToastInfo;
        case MessageTypes.Error:
            return Events.ToastError;
        case MessageTypes.Success:
            return Events.ToastSuccess;
        case MessageTypes.Warning:
            return Events.ToastWarning;
    }
};

export const showWarningToast = (message: string) => {
    EventBus.$emit(messageTypeToToast(MessageTypes.Warning), message);
};

export const showToastMessage = (result: CommandResult) => {
    if (result == null || !result.message) return;
    EventBus.$emit(messageTypeToToast(result.level || MessageTypes.Information), result.message);
};
