import { render, staticRenderFns } from "./LocationHazardNotesDialog.vue?vue&type=template&id=3e17295a&scoped=true&"
import script from "./LocationHazardNotesDialog.vue?vue&type=script&lang=ts&"
export * from "./LocationHazardNotesDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e17295a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VDialog})
