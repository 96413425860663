import { ICommandMapper } from '../ICommandMapper';
import {
    CreateShiftSnapshotCommand,
    DepartmentShiftSnapshotOverviewViewModel,
    RecordShiftSnapshotCommand
} from '@/models/api';

export class RecordShiftSnapshotCommandMapper implements ICommandMapper<RecordShiftSnapshotCommand> {
    commandType = 'RecordShiftSnapshotCommand';
    mappers = [
        {
            viewmodelType: 'DepartmentShiftSnapshotOverviewViewModel[]',
            map(
                command: RecordShiftSnapshotCommand,
                model: DepartmentShiftSnapshotOverviewViewModel[]
            ): DepartmentShiftSnapshotOverviewViewModel[] | null {
                model.unshift({
                    _type: 'DepartmentShiftSnapshotOverviewViewModel',
                    id: 'x',
                    name: command.name,
                    snapshottedAt: command.snapshottedAt,
                    modelJson: command.snapshotJson,
                    isPublish: command.isPublish,
                    isActuals: command.isActuals
                });
                return model;
            }
        }
    ];
}

export default new RecordShiftSnapshotCommandMapper();
