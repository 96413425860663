var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":600},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticClass:"px-5 py-3"},[(_vm.editing)?_c('edit-to-do-action-sheet',{attrs:{"description":_vm.editingDescription,"name":_vm.editingName,"allow-selecting-department":_vm.creatingNewAction,"available-departments":_vm.availableDepartmentsFiltered,"department-id":_vm.editingDepartmentId,"department-name":_vm.editingDepartmentName},on:{"save":_vm.onSave,"cancel":_vm.onCancelEdit}}):[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.toDoAction.name)+" "),_c('v-spacer'),_c('v-access-control',{attrs:{"roles":[_vm.AppRoles.WeekPlanner, _vm.AppRoles.ShiftPlanner],"offline-access":""},scopedSlots:_vm._u([{key:"forbidden",fn:function(){return [(_vm.toDoAction.status == _vm.ToDoActionStatus.Completed)?_c('v-icon',{attrs:{"color":"green","large":""}},[_vm._v("mdi-checkbox-marked-circle-outline")]):(_vm.toDoAction.status == _vm.ToDoActionStatus.InProgress)?_c('v-icon',{attrs:{"color":"deep-orange","large":""}},[_vm._v("mdi-clock-outline")]):_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-checkbox-blank-circle-outline")])]},proxy:true},{key:"readonly",fn:function(){return [(_vm.toDoAction.status == _vm.ToDoActionStatus.Completed)?_c('v-icon',{attrs:{"color":"green","large":""}},[_vm._v("mdi-checkbox-marked-circle-outline")]):(_vm.toDoAction.status == _vm.ToDoActionStatus.InProgress)?_c('v-icon',{attrs:{"color":"deep-orange","large":""}},[_vm._v("mdi-clock-outline")]):_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-checkbox-blank-circle-outline")])]},proxy:true}])},[(_vm.toDoAction.status == _vm.ToDoActionStatus.Completed)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"icon":"","color":"green"},on:{"click":_vm.onMarkNotCompleted}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-checkbox-marked-circle-outline")])],1)],1)]}}],null,false,3345722156)},[_c('span',[_vm._v("Undo completion")])]):(_vm.toDoAction.status == _vm.ToDoActionStatus.InProgress)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"icon":"","color":"deep-orange"},on:{"click":_vm.onMarkCompleted}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-clock-outline")])],1)],1)]}}])},[_c('span',[_vm._v("Mark completed")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.onMarkInProgress}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-checkbox-blank-circle-outline")])],1)],1)]}}])},[_c('span',[_vm._v("Mark completed")])])],1)],1),_c('v-card-subtitle',{staticClass:"d-flex flex-row justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.toDoAction.createdBy))]),(_vm.toDoAction.status == _vm.ToDoActionStatus.Completed)?_c('span',[_vm._v("Completed "+_vm._s(_vm._f("formatDateTimeRelative")(_vm.toDoAction.completedAtUTC)))]):(_vm.toDoAction.status == _vm.ToDoActionStatus.InProgress)?_c('span',[_vm._v("Started "+_vm._s(_vm._f("formatDateTimeRelative")(_vm.toDoAction.startedAtUTC)))]):_vm._e()]),_c('v-divider'),_c('v-card-text',{staticClass:"mt-3",staticStyle:{"max-height":"400px","overflow-y":"auto"}},[_c('div',{staticClass:"d-flex flex-row mb-5"},[_c('div',{staticStyle:{"width":"100%"}},[_c('p',{staticClass:"to-do-action--description"},[_vm._v(" "+_vm._s(_vm.toDoAction.description)+" ")])])]),_c('v-divider'),(!_vm.creatingNewAction && !_vm.editing && !_vm.readOnly)?_c('v-form',{ref:"commentForm"},[_c('expanding-textarea',{attrs:{"expand-append-outer":"","label":"Add comment"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('div',{staticClass:"d-flex flex-column"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":_vm.onAddComment}},on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}],null,false,177130320)},[_c('span',[_vm._v("Add comment")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.onClearAddCommentField}},on),[_c('v-icon',[_vm._v("mdi-cancel")])],1)]}}],null,false,597281324)},[_c('span',[_vm._v("Cancel")])])],1)]},proxy:true}],null,false,920499465),model:{value:(_vm.newComment),callback:function ($$v) {_vm.newComment=$$v},expression:"newComment"}})],1):_vm._e(),(!_vm.creatingNewAction && !_vm.editing)?_c('v-list',_vm._l((_vm.orderedComments),function(comment){return _c('v-list-item',{key:comment.id,staticClass:"to-do-action--comment"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(comment.madeBy)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm._f("formatDateTimeRelative")(comment.madeAtUTC))+" ")]),_vm._v(" "+_vm._s(comment.comment)+" ")],1),_c('v-list-item-action',{staticClass:"to-do-action--comment-actions"},[_c('v-access-control',{attrs:{"roles":[_vm.AppRoles.WeekPlanner, _vm.AppRoles.ShiftPlanner]},scopedSlots:_vm._u([{key:"offline",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"icon":"","color":"red","disabled":""}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Cannot remove comments whilst offline.")])])]},proxy:true},{key:"forbidden",fn:function(){return [_c('div')]},proxy:true},{key:"readonly",fn:function(){return [_c('div')]},proxy:true}],null,true)},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.onRemoveComment(comment.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Remove comment")])])],1)],1)],1)}),1):_vm._e()],1),_c('v-card-actions',[_c('v-access-control',{attrs:{"roles":[_vm.AppRoles.WeekPlanner, _vm.AppRoles.ShiftPlanner]},scopedSlots:_vm._u([{key:"offline",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[(!_vm.editing && !_vm.creatingNewAction)?_c('v-btn',{attrs:{"text":"","disabled":""}},[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v("Delete")],1):_vm._e()],1)]}}])},[_c('span',[_vm._v("Cannot delete tasks whilst offline.")])])]},proxy:true},{key:"forbidden",fn:function(){return [_c('div')]},proxy:true},{key:"readonly",fn:function(){return [_c('div')]},proxy:true}])},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[(!_vm.editing && !_vm.creatingNewAction)?_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.onDelete}},[_vm._v("Delete")]):_vm._e()],1)]}}])},[_c('span',[_vm._v("Delete task")])])],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Close")]),_c('v-access-control',{attrs:{"roles":[_vm.AppRoles.WeekPlanner, _vm.AppRoles.ShiftPlanner]},scopedSlots:_vm._u([{key:"offline",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[(!_vm.editing && !_vm.creatingNewAction)?_c('v-btn',{attrs:{"text":"","disabled":""},on:{"click":_vm.onStartEdit}},[_vm._v("Edit")]):_vm._e()],1)]}}])},[_c('span',[_vm._v("Cannot edit tasks whilst offline.")])])]},proxy:true},{key:"forbidden",fn:function(){return [_c('div')]},proxy:true},{key:"readonly",fn:function(){return [_c('div')]},proxy:true}])},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[(!_vm.editing && !_vm.creatingNewAction)?_c('v-btn',{attrs:{"text":""},on:{"click":_vm.onStartEdit}},[_vm._v("Edit")]):_vm._e()],1)]}}])},[_c('span',[_vm._v("Edit task")])])],1)],1)]],2),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }