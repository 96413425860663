import Db from '@/lib/IndexedDb/Db';
import { AddedTask, CycleUpdate, RemovedTask, SyncTasksCommand, TaskLocation, TaskUpdate } from '@/models/api';
import { SyncAdHocTasksCommand } from '@/models/api/Commands/sync-ad-hoc-tasks-command';
import ClientRowModel, { LockTag } from '@/models/client/client-row';
import _ from 'lodash';

export interface Edit<T> {
    command: T;
    url: string;
    method: string;
}

export class EditStore {
    public async hasPendingEdits(): Promise<boolean> {
        const count = await Db.edits.count();
        return count > 0;
    }

    public getAdhocChangeSet(
        original: ClientRowModel,
        heading: ClientRowModel,
        departmentId: string,
        tags: LockTag
    ): SyncAdHocTasksCommand {
        const taskLocations: TaskLocation[] = [];
        const taskUpdates: TaskUpdate[] = [];
        heading.tasks.forEach((element) => {
            taskLocations.push({
                _type: 'TaskLocation',
                taskId: element.id,
                correlationId: element.correlationId,
                startTime: element.startTime.toDate(),
                endTime: element.endTime.toDate(),
                quantity: element.quantity,
                blastPacketRingTargetId: null,
            });

            taskUpdates.push({
                _type: 'TaskUpdate',
                taskId: element.id,
                priority: element.priority,
                description: element.description,
                consumables: element.consumables,
                taskCategoryId: element.taskCategoryId,
                overriddenLocationName: element.overriddenLocationName,
                taskName: element.taskName,
            });
        });

        const removedTasks: RemovedTask[] = [];
        original.tasks.forEach((element) => {
            const foundInUpdated = heading.tasks.some((x) => x.id === element.id);

            if (foundInUpdated !== true) {
                removedTasks.push({ _type: 'RemovedTask', taskId: element.id });
            }
        });

        const addedTasks: AddedTask[] = [];
        heading.tasks.forEach((element) => {
            const foundInUpdated = original.tasks.some((x) => x.id === element.id);

            if (foundInUpdated !== true) {
                addedTasks.push({
                    _type: 'AddedTask',
                    taskId: element.id,
                    isManualSplit: element.isManualSplit,
                    taskTypeId: element.taskTypeId,
                });
            }
        });

        return {
            _type: 'SyncAdHocTasksCommand',
            departmentId: departmentId,
            plannedLocationId: heading.id,
            removedTasks: removedTasks,
            addedTasks: addedTasks,
            taskLocations: taskLocations,
            taskUpdates: taskUpdates,
            currentPlanningTag: tags.current,
            nextPlanningTag: tags.next
        };
    }

    public getChangeSet(original: ClientRowModel, heading: ClientRowModel, tags: LockTag): SyncTasksCommand {
        const tasksGroupedByCycle = _.groupBy(heading.tasks, (x) => x.plannedCycleId);
        const cycleUpdates: CycleUpdate[] = _.keys(tasksGroupedByCycle).map((cycleId) => {
            const taskLocations: TaskLocation[] = tasksGroupedByCycle[cycleId].map((task) => {
                return {
                    _type: 'TaskLocation',
                    taskId: task.id,
                    correlationId: task.correlationId,
                    startTime: task.startTime.toDate(),
                    endTime: task.endTime.toDate(),
                    quantity: task.quantity,
                    blastPacketRingTargetId: task.blastPacketRingTargetId,
                };
            });

            return {
                _type: 'CycleUpdate',
                cycleId: cycleId,
                addedTasks: [] as AddedTask[],
                removedTasks: [] as RemovedTask[],
                taskLocations: taskLocations,
            };
        });

        original.tasks.forEach((element) => {
            const foundInUpdated = heading.tasks.some((x) => x.id === element.id);

            if (foundInUpdated !== true) {
                const cycle = cycleUpdates.find((x) => x.cycleId === element.plannedCycleId);
                // if we can't find the cycle, the implication is that we deleted the last task, but should still send an update
                if(!cycle && element.plannedCycleId != null){
                    cycleUpdates.push({
                        _type: 'CycleUpdate',
                        cycleId: element.plannedCycleId,
                        addedTasks: [] as AddedTask[],
                        removedTasks: [{ _type: 'RemovedTask', taskId: element.id }],
                        taskLocations: [],
                    });
                } else {
                    cycle?.removedTasks.push({ _type: 'RemovedTask', taskId: element.id });
                }


            }
        });

        heading.tasks.forEach((element) => {
            const foundInUpdated = original.tasks.some((x) => x.id === element.id);

            if (foundInUpdated !== true) {
                const cycle = cycleUpdates.find((x) => x.cycleId === element.plannedCycleId);
                cycle?.addedTasks.push({
                    _type: 'AddedTask',
                    isManualSplit: element.isManualSplit,
                    taskId: element.id,
                    taskTypeId: element.taskTypeId,
                });
            }
        });

        return { 
            _type: 'SyncTasksCommand', 
            plannedLocationId: heading.id, 
            cycleUpdates: cycleUpdates,
            currentPlanningTag: tags.current,
            nextPlanningTag: tags.next,
            newTaskCycles: [],
            tasksWithChangedCycles: []
         };
    }

    public async add(model: Edit<any>) {
        await Db.edits.put({
            created: new Date(),
            type: model.command._type,
            model: JSON.stringify(model),
        });
    }

    public async addChangeSet(changeSet: SyncTasksCommand) {
        await Db.edits.put({
            created: new Date(),
            type: 'SyncTasksCommand',
            model: JSON.stringify({ command: changeSet, method: 'POST', url: 'Shift/Cycles/Tasks/Sync' }),
        });
    }

    public async addAdhocChangeSet(changeSet: SyncAdHocTasksCommand) {
        await Db.edits.put({
            created: new Date(),
            type: 'SyncAdHocTasksCommand',
            model: JSON.stringify({ command: changeSet, method: 'POST', url: 'PlannedAdHocTasks/Sync' }),
        });
    }

    public async enumerate(fn: (editModel: any, created: Date) => Promise<boolean>) {
        await Db.edits
            .orderBy('id')
            .toArray()
            .then(async (res) => {
                for (const edit of res) {
                    const editModel = JSON.parse(edit.model);
                    const remove = await fn(editModel, edit.created);
                    if (remove) {
                        await Db.edits.delete(edit.id as number);
                    }
                }
            });
    }

    public async clear() {
        await Db.edits.clear();
    }
}

export default new EditStore();
