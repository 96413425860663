import { ICommandMapper } from '../ICommandMapper';
import { UpdateAdHocTaskTimesCommand, OfflineEditableCommand } from '@/models/api';
import { EditStore, Edit } from '../EditStore';
import { EventBus, Events } from '@/lib/EventBus';
import ConvertCommandsToBaselineHelper from './Helpers/ConvertCommandsToBaselineHelper';

export class UpdateAdHocTaskTimesCommandMapper implements ICommandMapper<UpdateAdHocTaskTimesCommand> {
    commandType = 'UpdateAdHocTaskTimesCommand';
    async onQueue(command: UpdateAdHocTaskTimesCommand, store: EditStore) {
        const meta = command.$meta;
        if (!meta || !meta.shift) {
            return;
        }

        //remove any existing 'edits' for this shift
        await store.enumerate(async (x, c) => {
            if (x.command._type != 'UpdateAdHocTaskTimesCommand') {
                return false;
            }
            const existingMeta = x.command.$meta || {};
            if (existingMeta.shift && existingMeta.shift.id == meta.shift.id) {
                return true;
            }
            return false;
        });
    }
    onDequeue(edit: Edit<OfflineEditableCommand>, attemptedAt: Date): Edit<any> {
        const meta = edit.command.$meta;
        if (!meta || !meta.shift) {
            return edit;
        }
        //change this command to be a snapshot instead
        const command = {
            _type: 'CreateShiftSnapshotCommand',
            name: 'Offline Edit',
            snapshottedAt: attemptedAt,
            isPublish: false,
            departmentShiftId: meta.shift.id,
            snapshotJson: JSON.stringify(meta.shift)
        };
        EventBus.$emit(Events.ToastInfo, 'Task edits you made offline have been saved as a Baseline!', true);
        return { command: command, method: 'POST', url: 'Shift/Board/Snapshots' };
    }
    mappers = [ConvertCommandsToBaselineHelper.createShiftModelMapper<UpdateAdHocTaskTimesCommand>()];
}

export default new UpdateAdHocTaskTimesCommandMapper();
