import Vue from 'vue';
import Terms from '@/lib/Terms';

Vue.prototype.$term = async (term: string) => await Terms.term(term);
Vue.prototype.$plural = async (term: string, count?: number) => await Terms.termPlural(term, count);
Vue.prototype.$termSync = (term: string) => Terms.termSync(term);
Vue.prototype.$pluralSync = (term: string, count?: number) => Terms.termPluralSync(term, count);

Vue.filter('term', (term: string, count?: number) => {
    if (count == undefined) {
        return Terms.termSync(term);
    } else {
        return Terms.termPluralSync(term, count);
    }
});

Vue.filter('termPlural', (term: string) =>{
    return Terms.termPluralSync(term, 2);
})
