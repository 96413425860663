import { GenericDataApi } from '@/lib/data/GenericDataApi';
import { AxiosError } from 'axios';
import AppHttp from '../AppHttp';
import Errors from '../Errors';
import {
    CreateLocationRingCommand,
    UpdateLocationRingCommand,
    DeleteLocationRingCommand,
    GetLocationRingsQueryResponse,
    GetLocationRingQueryResponse, ImportValidationMessageViewModel
} from '@/models/api';

export type HoleDesign = {
    id: string | undefined
    name: string;
    displayOrder: number;
    drillLength: number;
    chargeAmount: number;
}

export type Ring = {
    id: string | undefined;
    name: string;
    displayOrder: number;
    locationId: string;
    departmentId: string;
    ringConfigurationId: string | null;
    holes: HoleDesign[]
}

export interface ImportedLocationRing {
    holes: any[],
    levelId: string,
    levelName: string,
    locationId: string,
    locationName: string,
    locationRingId: string,
    ringName: string,
    extractedAmount: number,
    displayOrder: number,
    validationMessages: ImportValidationMessageViewModel[],
}

export interface ValidationResult {
    isValid: boolean;
    validationMessages: ImportValidationMessageViewModel[];
    validatedImportedRings: ImportedLocationRing[]
}

export interface UploadResult {
    status: number;
    message: string;
}

export class LocationRings extends GenericDataApi<any> {
    constructor() {
        super('LocationRings');
    }

    public async getByDepartment(departmentId: string) : Promise<GetLocationRingsQueryResponse | null> {
        const response = await AppHttp.get(`${this.resource}/Department/${departmentId}`);

        const data = response ? response.data : null;
        return data;
    }

    public async getByStope(departmentId: string, stopeLocationId: string) : Promise<GetLocationRingsQueryResponse | null>{
        const response = await AppHttp.get(`${this.resource}/Department/${departmentId}/Location/${stopeLocationId}`);

        const data = response ? response.data : null;
        return data;
    }

    public async getById(locationRingId: string) : Promise<GetLocationRingQueryResponse> {
        const response = await AppHttp.get(`${this.resource}/ring/${locationRingId}`);

        const data = response ? response.data : null;
        return data;
    }

    public async executeCreateCommand(command: CreateLocationRingCommand){
        await AppHttp.post(`${this.resource}/commands/Create`, command);
    }

    public async executeUpdateCommand(command: UpdateLocationRingCommand){
        await AppHttp.post(`${this.resource}/commands/Update`, command);
    }

    public async executeDeleteCommand(command: DeleteLocationRingCommand){
        await AppHttp.delete(`${this.resource}/commands/Delete`, command);
    }

    public async upload(departmentId: string, locationRings: File) : Promise<ValidationResult | null> {
        const response = await AppHttp.postWithFiles(`${this.resource}/upload/${departmentId}`, [], [locationRings]);
        const data: ValidationResult | null = response ? response.data['file'] : null;
        return data;
    }

    public async import(locationRings: ValidationResult) : Promise<UploadResult | null> {
        const response = await AppHttp.post(`${this.resource}/import`, locationRings);
        const data: UploadResult | null = response ? response.data : null;
        return data;
    }
}

export default new LocationRings();
