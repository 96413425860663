import { ICommandMapper } from '../ICommandMapper';
import { SetCrewForShiftCommand, ShiftIds, ShiftSupervisorQueryResponse } from '@/models/api';

export class SetCrewForShiftCommandMapper implements ICommandMapper<SetCrewForShiftCommand> {
    commandType = 'SetCrewForShiftCommand';
    mappers = [
        {
            viewmodelType: 'ShiftSupervisorQueryResponse',
            map(
                command: SetCrewForShiftCommand,
                model: ShiftSupervisorQueryResponse
            ): ShiftSupervisorQueryResponse | null {
                if (command.departmentShiftId == model.id) {
                    model.weekCrewIds = command.weekCrewIds;
                }

                return model;
            }
        },
        {
            viewmodelType: 'ShiftIds',
            map(command: SetCrewForShiftCommand, model: ShiftIds): ShiftIds | null {
                if (command.departmentShiftId == model.id) {
                    model.weekCrewIds = command.weekCrewIds;
                }

                return model;
            }
        }
    ];
}

export default new SetCrewForShiftCommandMapper();
