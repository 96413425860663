<template>
    <v-tooltip bottom>
        <template #activator="{ on }">
            <v-btn :text="text" v-on="on">
                <v-icon left>mdi-cancel</v-icon>
                {{ label || 'No Permission' }}
            </v-btn>
        </template>
        <span>insufficient permission to perform this action</span>
    </v-tooltip>
</template>

<script>
export default {
    props: ['text', 'label']
};
</script>
