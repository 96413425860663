import {
    LocationAndRingPointer,
    ProductionFrontLevelRulesViewModel, ProductionFrontLocationRulesViewModel, ProductionFrontRingRulesViewModel,
    ProductionFrontRulesViewModel,
    RingRuleEvaluationState,
    RingRuleEvaluationStatus, RingStatesLocationForWindowViewModel
} from '@/models/api';
import ClientTaskModel from '@/models/client/client-task';
import _ from 'lodash';
import {
    isBoggingTask,
    isDrillTask,
    TaskIntersectsTimespan,
    TimespanContainsTask
} from '@/lib/services/Task';
import dayjs from 'dayjs';
import ClientRowModel from '@/models/client/client-row';
import {
    IndexRangeStatusRingRule,
    IndexRangeTaskOverlapRingRule
} from '@/components/ShiftBoard/Board/Validation';
import { AffectedAreaSpecification } from '@/lib/stores/HeadingsStore';
import ClientTask from '@/models/client/client-task';
import { ProductionValidationRow } from '@/models/client/production-validation-row';
import { ProductionValidationTaskModel } from '@/models/client/production-validation-task-model';
import { ClientRowBlastPacketTargetDisplayInformation } from '@/lib/stores/Production/ShiftWindowActualsStore';

const LEVEL_INDEX = 0;
const LOCATION_INDEX = 1;
const RING_INDEX = 2;

export const RING_RULE_ISSUE_TYPE = 'RING_RULE';

const UNIQUE_IDS = {
    INTRA_LEVEL_DRILLING_CLASH: 'INTRA_LEVEL_DRILLING_CLASH',
    INTER_LEVEL_DRILLING_CLASH: 'INTER_LEVEL_DRILLING_CLASH',
    PROGRESS_LAGGING: 'PROGRESS_LAGGING',
    PROGRESS_LEADING: 'PROGRESS_LEADING',
    TOO_MANY_TONNES_BOGGED: 'TOO_MANY_TONNES_BOGGED',
    INTER_LEVEL_DRILL_CHARGE_CLASH: 'INTER_LEVEL_DRILL_CHARGE_CLASH'
}

export interface RingInteractionRuleWarningAdditionFunc {
(task: ProductionValidationTaskModel, affectedArea: AffectedAreaSpecification, warningText: string, warningUniqueId: string): void;
}

export function createMaximumDrawpointBoggingValidationFunction(ruleset: ProductionFrontRulesViewModel, shiftStartTime: dayjs.Dayjs, shiftEndTime: dayjs.Dayjs, warningAdditionFunc: RingInteractionRuleWarningAdditionFunc) {
    const rowBoggedTotalsCache: { [key: string]: number; } = {};

    return (row: ProductionValidationRow, task: ProductionValidationTaskModel) => {
        if(!TaskIntersectsTimespan(task, shiftStartTime, shiftEndTime))
            return;

        let totalBoggedInShift = 0;
        if(row.id in rowBoggedTotalsCache)
            totalBoggedInShift = rowBoggedTotalsCache[row.id];
        else{
            totalBoggedInShift = calculateTotalBoggedInDrawpointInShift(ruleset, row, shiftStartTime, shiftEndTime);
            rowBoggedTotalsCache[row.id]=totalBoggedInShift;
        }

        validateMaximumDrawpointBogging(ruleset, task, totalBoggedInShift, warningAdditionFunc);
    }
}

function calculateTotalBoggedInDrawpointInShift(ruleset: ProductionFrontRulesViewModel, row: ProductionValidationRow, shiftStartTime: dayjs.Dayjs, shiftEndTime: dayjs.Dayjs): number {
    const ringBoggingTasksInShift = row.tasks
        .filter(t=>isBoggingTask(t) && TimespanContainsTask(t, shiftStartTime, shiftEndTime) && t.blastPacketRingTargetId !== null);

    const ringBoggingTasksInOurFront = ringBoggingTasksInShift
        .filter(t=>t.blastPacketRingTargetId! in ruleset.blastPacketRingTargetsToCoordinates);

    const plannedAndActualBoggedTonnesInShift = ringBoggingTasksInOurFront
        .map(t=>({
            bogPlanned: t.quantity
        }))
        .map(toBog=>toBog.bogPlanned)
        .reduce((curr,prev)=>(curr ?? 0)+(prev ?? 0), 0) ?? 0;

    return plannedAndActualBoggedTonnesInShift;
}

export function validateMaximumDrawpointBogging(ruleset: ProductionFrontRulesViewModel, task: ProductionValidationTaskModel, totalBoggedInShift: number, warningAdditionFunc: RingInteractionRuleWarningAdditionFunc) {
    if(totalBoggedInShift > (ruleset.subLevelCavingRingRuleSettings?.maximumTonnesBoggedPerDrawpointPerShift ?? 300)) {
        warningAdditionFunc(task, { locationId: task.locationId, locationName: task.locationName! }, 'Too many tonnes to be bogged in a single shift.', UNIQUE_IDS.TOO_MANY_TONNES_BOGGED);
    }
}

export function validateBlastedRingsLeadLag(ruleset: ProductionFrontRulesViewModel, targetsToBlastPacketsAndRings: ClientRowBlastPacketTargetDisplayInformation[], ringStates: RingStatesLocationForWindowViewModel[], taskCoordinates: number[][], task: ProductionValidationTaskModel, warningAdditionFunc: RingInteractionRuleWarningAdditionFunc) {
    const maximumAllowedLagDistance = ruleset.subLevelCavingRingRuleSettings?.headingLeadLagRuleMaximumLagRingDistance ?? 1;
    const maximumAllowedLeadDistance = ruleset.subLevelCavingRingRuleSettings?.headingLeadLagRuleMaximumLeadRingDistance ?? 3;

    // get most advanced task coordinates
    const mostAdvancedTaskCoordinates = [...taskCoordinates].sort((a,b)=>b[RING_INDEX]-a[RING_INDEX])[0];

    for(const locationIndex of [mostAdvancedTaskCoordinates[LOCATION_INDEX]-2, mostAdvancedTaskCoordinates[LOCATION_INDEX]+2]) {
        const distanceToLastBlastOrBog = getLastRingWithStatus(ruleset,
            ringStates,
            mostAdvancedTaskCoordinates[RING_INDEX],
            mostAdvancedTaskCoordinates[LEVEL_INDEX],
            locationIndex,
            [RingRuleEvaluationStatus.Fired, RingRuleEvaluationStatus.Bogging], [], [RingRuleEvaluationStatus.NotDrilled], task);

        const distanceToProgress = distanceToLastBlastOrBog ?? findDistanceToBeginningOfLocation(mostAdvancedTaskCoordinates[RING_INDEX], mostAdvancedTaskCoordinates[LEVEL_INDEX], locationIndex);

        if(distanceToProgress != null) {
            if(distanceToProgress < -1*maximumAllowedLagDistance)
            {
                const violatingTunnel = getLocationByIndices(ruleset, mostAdvancedTaskCoordinates[LEVEL_INDEX], locationIndex);
                warningAdditionFunc(task, getAffectedAreaFromTaskAndCoordinates(ruleset, targetsToBlastPacketsAndRings, task, mostAdvancedTaskCoordinates), `Too far behind progress of neighbouring tunnel: ${violatingTunnel.locationName}`,
                    `${UNIQUE_IDS.PROGRESS_LAGGING}.${violatingTunnel.locationId}`);
            } else if(distanceToProgress > maximumAllowedLeadDistance) {
                const violatingTunnel = getLocationByIndices(ruleset, mostAdvancedTaskCoordinates[LEVEL_INDEX], locationIndex);
                warningAdditionFunc(task, getAffectedAreaFromTaskAndCoordinates(ruleset, targetsToBlastPacketsAndRings, task, mostAdvancedTaskCoordinates), `Too far ahead of progress of neighbouring tunnel: ${violatingTunnel.locationName}`,
                    `${UNIQUE_IDS.PROGRESS_LEADING}.${violatingTunnel.locationId}`);
            }
        }
    }

    function findDistanceToBeginningOfLocation(distanceFromIndex: number, levelIndex: number, locationIndex: number): number | null {
        const level = ruleset.levels.find(lev=>lev.index === levelIndex);
        const location = level?.locations.find(loc=>loc.coordinates[LOCATION_INDEX]===locationIndex);

        const firstRingIndex = location?.rings.map(r=>r.coordinates[RING_INDEX]).reduce((prev,curr,index)=> prev != null ? Math.min(prev,curr) : curr, null as number | null) ?? null;

        return firstRingIndex != null ? distanceFromIndex - firstRingIndex : null;
    }
}

function getLastRingWithStatus(ruleset: ProductionFrontRulesViewModel, ringStates: RingStatesLocationForWindowViewModel[], ringStartIndex: number, levelIndex: number, locationIndex: number, statuses: RingRuleEvaluationStatus[], terminationStatusesTowardsZero: RingRuleEvaluationStatus[], terminationStatusesAwayFromZero: RingRuleEvaluationStatus[], task: ProductionValidationTaskModel): number | null {
    const locationRingPointers = ruleset.locationAndRingPointerMatrix[levelIndex]?.[locationIndex] as LocationAndRingPointer[][] | undefined;

    if(locationRingPointers == undefined)
        return null;

    let towardsDriveRingIndex = 0;
    let foundStatusTowardsDrive = false;

    for(let i = ringStartIndex; i < locationRingPointers.length; i++) {
        const locationAndRingPointersAtCoordinates = locationRingPointers[i];

        // not all locations will have enough rings, so we may have ringStatuses as null.. in this case we still continue and increment our maximum distance
        if(locationAndRingPointersAtCoordinates != null && locationAndRingPointersAtCoordinates.length > 0) {
            const ringsAndStatuses = ringStates.find(lrs=>lrs.locationId === locationAndRingPointersAtCoordinates[0].locationId)?.rings.filter(rs=>locationAndRingPointersAtCoordinates.some(lrp=>lrp.locationRingId===rs.locationRingId)).map(rs=>rs.states);

            if(ringsAndStatuses != null && ringsAndStatuses.length > 0){
                const violatesRule = ringsAndStatuses.some(rs=>rs.some(s=>s.ringStatus != null && statuses.includes(s.ringStatus) && TaskIntersectsTimespan(task, dayjs(s.startAt).utc(), dayjs(s.endAt).utc())));

                if(!violatesRule){
                    const isTerminationStatus = ringsAndStatuses.some(rs=>rs.some(s=>s.ringStatus != null && terminationStatusesAwayFromZero.includes(s.ringStatus) && TaskIntersectsTimespan(task, dayjs(s.startAt).utc(), dayjs(s.endAt).utc())));

                    if(isTerminationStatus)
                        break;
                } else {
                    towardsDriveRingIndex=i;
                    foundStatusTowardsDrive=true;
                }
            }
        }
    }

    if(foundStatusTowardsDrive)
        return ringStartIndex - towardsDriveRingIndex;

    let awayFromDriveRingIndex = 0;
    let foundStatusAwayFromDrive = false;

    for(let i = ringStartIndex; i >= 0; i--) {
        const locationAndRingPointersAtCoordinates = locationRingPointers[i];

        // not all locations will have enough rings, so we may have ringStatuses as null.. in this case we still continue and increment our maximum distance
        if(locationAndRingPointersAtCoordinates != null && locationAndRingPointersAtCoordinates.length > 0) {
            const ringsAndStatuses = ringStates.find(lrs=>lrs.locationId === locationAndRingPointersAtCoordinates[0].locationId)?.rings.filter(rs=>locationAndRingPointersAtCoordinates.some(lrp=>lrp.locationRingId===rs.locationRingId)).map(rs=>rs.states);

            if(ringsAndStatuses != null) {
                const violatesRule = ringsAndStatuses.some(rs=>rs.some(s=>s.ringStatus != null && statuses.includes(s.ringStatus) && TaskIntersectsTimespan(task, dayjs(s.startAt).utc(), dayjs(s.endAt).utc())));

                if(!violatesRule){
                    const isTerminationStatus = ringsAndStatuses.some(rs=>rs.some(s=>s.ringStatus != null && terminationStatusesTowardsZero.includes(s.ringStatus) && TaskIntersectsTimespan(task, dayjs(s.startAt).utc(), dayjs(s.endAt).utc())));

                    if(isTerminationStatus)
                        break;
                } else {
                    awayFromDriveRingIndex=i;
                    foundStatusAwayFromDrive=true;
                    break;
                }
            }
        }
    }

    if(foundStatusAwayFromDrive)
        return ringStartIndex-awayFromDriveRingIndex;

    return null; // Couldn't find the status at all
}

function getAffectedAreaFromTaskAndCoordinates(ruleset: ProductionFrontRulesViewModel, targetsToBlastPacketsAndRings: ClientRowBlastPacketTargetDisplayInformation[], task: ProductionValidationTaskModel, taskCoordinates: number[]): AffectedAreaSpecification {
    const affectedRing = targetsToBlastPacketsAndRings.find(t=>t.targetId === task.blastPacketRingTargetId);

    return {
        locationId: task.locationId,
        locationName: task.locationName!,
        blastPacketId: affectedRing?.blastPacketId,
        blastPacketName: affectedRing?.blastPacketName,
        locationRingId: affectedRing?.ringId,
        locationRingName: affectedRing?.ringName,
    };
}

export function validateDrillingOrChargingChargedLag(ruleset: ProductionFrontRulesViewModel, targetsToBlastPacketsAndRings: ClientRowBlastPacketTargetDisplayInformation[], ringStates: RingStatesLocationForWindowViewModel[], taskCoordinates: number[], task: ProductionValidationTaskModel, warningAdditionFunc: RingInteractionRuleWarningAdditionFunc) {
    const maximumBannedDistance = ruleset.subLevelCavingRingRuleSettings?.drillChargeRuleRingDistance ?? 6;

    validateIndexRangeRingStatusRule({
        levelIndexOffsets: [-1],
        locationIndexOffsets: [-1, 1],
        ringIndexOffsets: maximumBannedDistance == 0 ? [0] : _.range(-1*maximumBannedDistance, maximumBannedDistance+1, 1),
        prohibitedRingStatuses: [RingRuleEvaluationStatus.Charged],
        violationMessageGenerator: (location,rings) => `Drilling too close to charged rings on the level above: ring(s) ${rings.map(r=>r.ringName).join(", ")} in tunnel ${location.locationName}`,
        ruleUniqueIdGenerator: (location,ring)=>`${UNIQUE_IDS.INTER_LEVEL_DRILL_CHARGE_CLASH}.${location.locationId}`,
    }, targetsToBlastPacketsAndRings, ruleset, ringStates, taskCoordinates, task, warningAdditionFunc);
}

export function validateIntraLevelDrillingOverlaps(rows: ProductionValidationRow[], targetsToBlastPacketsAndRings: ClientRowBlastPacketTargetDisplayInformation[], ruleset: ProductionFrontRulesViewModel, taskCoordinates: number[], task: ProductionValidationTaskModel, warningAdditionFunc: RingInteractionRuleWarningAdditionFunc) {
    const maximumBannedDistance = ruleset.subLevelCavingRingRuleSettings?.intralevelDrillingRuleRingDistance ?? 2;
    validateTaskOverlapIndexRangeRingStatusRule({
        levelIndexOffsets: [0],
        locationIndexOffsets: [-2, 2],
        ringIndexOffsets: maximumBannedDistance == 0 ? [0] :  _.range(-1*maximumBannedDistance, maximumBannedDistance+1, 1),
        prohibitedTaskCheck: isDrillTask,
        violationMessageGenerator: locationName => `Drilling too close to another drill task on the same level in location ${locationName}`,
        ruleUniqueIdGenerator: locationName => `${UNIQUE_IDS.INTRA_LEVEL_DRILLING_CLASH}.${locationName}`,
    }, rows, targetsToBlastPacketsAndRings, ruleset, taskCoordinates, task, warningAdditionFunc);
}

export function validateInterLevelDrillingOverlaps(rows: ProductionValidationRow[], targetsToBlastPacketsAndRings: ClientRowBlastPacketTargetDisplayInformation[], ruleset: ProductionFrontRulesViewModel, taskCoordinates: number[], task: ProductionValidationTaskModel, warningAdditionFunc: RingInteractionRuleWarningAdditionFunc) {
    const maximumBannedDistance = ruleset.subLevelCavingRingRuleSettings?.interlevelDrillingRuleRingDistance ?? 6;

    validateTaskOverlapIndexRangeRingStatusRule({
        levelIndexOffsets: [-1],
        locationIndexOffsets: [-1, 1],
        ringIndexOffsets: maximumBannedDistance == 0 ? [0] : _.range(-1*maximumBannedDistance, maximumBannedDistance+1, 1),
        prohibitedTaskCheck: isDrillTask,
        violationMessageGenerator: locationName => `Drilling too close to another drill task on the level above in location ${locationName}`,
        ruleUniqueIdGenerator: locationName => `${UNIQUE_IDS.INTER_LEVEL_DRILLING_CLASH}.${locationName}`,
    }, rows, targetsToBlastPacketsAndRings, ruleset, taskCoordinates, task, warningAdditionFunc);
}

function validateTaskOverlapIndexRangeRingStatusRule(rule: IndexRangeTaskOverlapRingRule,  rows: ProductionValidationRow[], targetsToBlastPacketsAndRings: ClientRowBlastPacketTargetDisplayInformation[], ruleset: ProductionFrontRulesViewModel, taskCoordinates: number[], task: ProductionValidationTaskModel, warningAdditionFunc: RingInteractionRuleWarningAdditionFunc): boolean {
    const problematicCoordinates = calculateProblematicCoordinates(taskCoordinates, rule.levelIndexOffsets, rule.locationIndexOffsets, rule.ringIndexOffsets);

    const levelCoordinatesToCheck = _.uniq(problematicCoordinates.map(c=>c[LEVEL_INDEX]));

    const locationCoordinatesToCheck = _.uniq(problematicCoordinates.map(c=>c[LOCATION_INDEX]));

    const locationIdsToCheck = ruleset.levels.filter(
        (l:ProductionFrontLevelRulesViewModel)=>levelCoordinatesToCheck.includes(l.index)
    ).flatMap(
        (l:ProductionFrontLevelRulesViewModel)=>l.locations.filter((loc:ProductionFrontLocationRulesViewModel)=>locationCoordinatesToCheck.includes(loc.coordinates[LOCATION_INDEX] ?? -1))
    ).map((loc:ProductionFrontLocationRulesViewModel)=>loc.locationId ?? '');

    const locationsToCheck = rows.filter(r=>locationIdsToCheck.includes(r.location?.id ?? ''));

    let foundViolations = false;

    for(const locationToCheck of locationsToCheck) {
        const violatingTasksExist = locationToCheck.tasks.filter(t=>t.blastPacketRingTargetId !== null && rule.prohibitedTaskCheck(t) && TaskIntersectsTimespan(t, task.startTime, task.endTime))
            .map(vt=>ruleset.blastPacketRingTargetsToCoordinates[vt.blastPacketRingTargetId!])
            .some(cGroup=>cGroup!== null && problematicCoordinates.some(pc=> cGroup.some(c=>pc[LEVEL_INDEX]===c[LEVEL_INDEX] && pc[LOCATION_INDEX]===c[LOCATION_INDEX]&&pc[RING_INDEX]===c[RING_INDEX])));

        if(violatingTasksExist){
            warningAdditionFunc(task, getAffectedAreaFromTaskAndCoordinates(ruleset, targetsToBlastPacketsAndRings, task, taskCoordinates), rule.violationMessageGenerator(locationToCheck.location?.name ?? 'unknown location'), rule.ruleUniqueIdGenerator(locationToCheck.location?.name ?? 'unknown'));
            foundViolations = true;
        }
    }

    return foundViolations;
}

function validateIndexRangeRingStatusRule(rule: IndexRangeStatusRingRule, targetsToBlastPacketsAndRings: ClientRowBlastPacketTargetDisplayInformation[], ruleset: ProductionFrontRulesViewModel, ringStates: RingStatesLocationForWindowViewModel[], taskCoordinates: number[], task: ProductionValidationTaskModel, warningAdditionFunc: RingInteractionRuleWarningAdditionFunc): boolean {
    let foundErrors = false;

    const problematicCoordinates = calculateProblematicCoordinates(taskCoordinates, rule.levelIndexOffsets, rule.locationIndexOffsets, rule.ringIndexOffsets);

    for(const problematicCoordinate of problematicCoordinates) {
        const ringsAndStates = getLocationRingsAndStatesByCoordinates(ruleset, ringStates, problematicCoordinate);

        if(ringsAndStates == null)
            continue;

        const ringsThatViolateRule = ringsAndStates.filter(rs=>rs.states.some(s=>s.ringStatus!=null && rule.prohibitedRingStatuses.includes(s.ringStatus) && TaskIntersectsTimespan(task, dayjs(s.startAt).utc(), dayjs(s.endAt).utc())))

        if(ringsThatViolateRule.length > 0){
            const violatingLocation = getLocationByIndices(ruleset, problematicCoordinate[LEVEL_INDEX], problematicCoordinate[LOCATION_INDEX]);
            const violatingRings = ringsThatViolateRule.map(r=>violatingLocation.rings.find(lr=>lr.ringId===r.locationRingId)).filter(r=>r!=null).map(r=>r!);
            warningAdditionFunc(task, getAffectedAreaFromTaskAndCoordinates(ruleset, targetsToBlastPacketsAndRings, task, taskCoordinates), rule.violationMessageGenerator(violatingLocation, violatingRings), rule.ruleUniqueIdGenerator(violatingLocation,violatingRings));
            foundErrors = true;
        }
    }

    return foundErrors;
}

function calculateProblematicCoordinates(taskCoordinates: number[],levelIndexOffsets: number[], locationIndexOffsets: number[], ringIndexOffsets: number[]): number[][] {
    const problematicCoordinates = [] as number[][];

    for(const levelOffset of levelIndexOffsets){
        const levelIndex = taskCoordinates[LEVEL_INDEX]+levelOffset;

        if(levelIndex < 0)
            continue;

        for(const locationOffset of locationIndexOffsets) {
            const locationIndex = taskCoordinates[LOCATION_INDEX]+locationOffset;
            if(locationIndex < 0)
                continue;

            for(const ringOffset of ringIndexOffsets) {
                const ringIndex = taskCoordinates[RING_INDEX]+ringOffset;

                if(ringIndex < 0 )
                    continue;

                problematicCoordinates.push([levelIndex, taskCoordinates[LOCATION_INDEX]+locationOffset, taskCoordinates[RING_INDEX]+ringOffset]);
            }
        }
    }

    return problematicCoordinates;
}

function getLocationRingsAndStatesByCoordinates(ruleset: ProductionFrontRulesViewModel, ringStates: RingStatesLocationForWindowViewModel[], coordinates: number[]): { locationRingId: string, states: RingRuleEvaluationState[]}[] | null {
    const locationAndRingPointers = ruleset.locationAndRingPointerMatrix[coordinates[LEVEL_INDEX]]?.[coordinates[LOCATION_INDEX]]?.[coordinates[RING_INDEX]] as LocationAndRingPointer[] | null | undefined;

    if(locationAndRingPointers == null || locationAndRingPointers.length === 0)
        return null;

    const statesAndRings = locationAndRingPointers.map(lrp=>({locationRingId: lrp.locationRingId, states: ringStates.find(lrs=>lrs.locationId===lrp.locationId)?.rings.find(rs=>rs.locationRingId===lrp.locationRingId)?.states}))
        .filter(lrs=>lrs.states != null && lrs.states.length > 0)
        .map(lrs=>({ locationRingId: lrs.locationRingId!, states: lrs.states!}));

    if(statesAndRings.length === 0)
        return null;

    return statesAndRings;
}

function getLevelByIndex(ruleset: ProductionFrontRulesViewModel, levelIndex: number): ProductionFrontLevelRulesViewModel{
    const level = ruleset.levels.find(l=>l.index===levelIndex);

    if(level != null)
        return level;

    throw new Error(`Could not find level with index ${levelIndex}`);
}

function getLocationByIndices(ruleset: ProductionFrontRulesViewModel, levelIndex: number, locationIndex: number): ProductionFrontLocationRulesViewModel {
    const level = getLevelByIndex(ruleset, levelIndex);

    const location = level.locations.find(l=>l.coordinates[1] === locationIndex);

    if(location != null)
        return location;

    throw new Error(`Could not find location in level ${level.levelName} with index ${locationIndex}`);
}

function getRingByIndices(ruleset: ProductionFrontRulesViewModel, levelIndex: number, locationIndex: number, ringIndex: number): ProductionFrontRingRulesViewModel {
    const location = getLocationByIndices(ruleset, levelIndex, locationIndex);

    const ring = location.rings.find(r=>r.coordinates[2] === ringIndex);

    if(ring != null)
        return ring;

    throw new Error(`Could not find ring in location ${location.locationName} with index ${ringIndex}`);
}