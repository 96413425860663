<template>
    <div class="pa-5">
        <v-card v-if="data">
            <v-card-title>
                <h1 class="title">{{ 'Meeting Guide Tasks' | term }}</h1>
                <v-btn small color="secondary" class="ml-3" @click="add()">
                    <v-icon left>mdi-plus</v-icon>Add
                </v-btn>
                <v-btn small color="primary" class="ml-3" v-if="dirty" @click="save()" :loading="$wait.any">
                    <v-icon left>mdi-check</v-icon>Save
                </v-btn>
                <v-btn small color="grey" class="ml-3" v-if="dirty" @click="loadData()" :loading="$wait.any">
                    <v-icon left>mdi-close</v-icon>Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    clearable
                    class="admin-table-filter"
                ></v-text-field>
            </v-card-title>
            <v-data-table disable-pagination hide-default-footer :headers="headers" :items="filtered" :search="search">
                <template v-slot:item="{ item }">
                    <tr :class="{ dirty: item.dirty }">
                        <td>
                            <v-textarea
                                v-model="item.question"
                                @keyup="item.dirty = true"
                                class="mt-3"
                                rows="1"
                                auto-grow
                            ></v-textarea>
                        </td>
                        <td>
                            <v-checkbox v-model="item.isShortAnswer" @change="item.dirty = true"></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-model="item.forShiftDeployment" @change="item.dirty = true"></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-model="item.forShiftHandover" @change="item.dirty = true"></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-model="item.forShiftCaptain" @change="item.dirty = true"></v-checkbox>
                        </td>
                        <td>
                            <v-access-control>
                                <div class="no-wrap">
                                    <v-btn text icon @click="up(item)">
                                        <v-icon>mdi-arrow-up</v-icon>
                                    </v-btn>
                                    <v-btn text icon @click="down(item)">
                                        <v-icon>mdi-arrow-down</v-icon>
                                    </v-btn>
                                    <v-btn text icon @click="del(item)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </div>
                            </v-access-control>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
        <confirm ref="confirm"></confirm>
    </div>
</template>
<script>
import { EventBus, Events } from '@/lib/EventBus';
import MeetingGuide from '@/lib/data/MeetingGuide';

export default {
    data() {
        return {
            data: null,
            search: '',
            headers: [
                { text: 'Question', value: 'question', width: '75%' },
                { text: 'Text?', value: '', width: '5%', sortble: false },
                { text: 'Shift Deployment', value: 'forShiftDeployment', width: '5%' },
                { text: 'Shift Handover', value: 'forShiftHandover', width: '5%' },
                { text: 'Shift Interactions', value: 'forShiftCaptain', width: '5%' },
                { text: 'Order', value: 'displayOrder', width: '10%', align: 'right' }
            ]
        };
    },
    async created() {
        await this.loadData();
    },
    destroyed() {},
    methods: {
        async loadData() {
            this.$wait.start('get data');
            try {
                var d = await MeetingGuide.get();
                for (let x of d) {
                    x.dirty = false;
                }
                this.data = d;
            } finally {
                this.$wait.end('get data');
            }
        },
        add() {
            var displayOrder = this.filtered.length ? this.filtered[this.filtered.length - 1].displayOrder + 1 : 1;
            this.data.push({ dirty: true, displayOrder: displayOrder });
        },
        up(item) {
            var prev;
            for (let x of this.filtered) {
                if (x != item) {
                    prev = x;
                    continue;
                }
                var newOrder = prev.displayOrder;
                prev.displayOrder = item.displayOrder;
                item.displayOrder = newOrder;
                prev.dirty = true;
                item.dirty = true;
                break;
            }
        },
        down(item) {
            var isNext = false;
            for (let x of this.filtered) {
                if (isNext) {
                    var newOrder = x.displayOrder;
                    x.displayOrder = item.displayOrder;
                    item.displayOrder = newOrder;
                    x.dirty = true;
                    item.dirty = true;
                    break;
                }
                if (x == item) {
                    isNext = true;
                }
            }
        },
        async save() {
            this.$wait.start('save');
            try {
                for (let x of this.data) {
                    if (!x.dirty) {
                        continue;
                    }
                    await MeetingGuide.save(x);
                }
                EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Meeting Guide')} Saved`);
                await this.loadData();
                EventBus.$emit(Events.TermsUpdated);
            } finally {
                this.$wait.end('save');
            }

        },
        async del(item) {
            if (!item.id) {
                this.data.splice(this.data.indexOf('foo'), 1);
                return;
            }
            if (await this.$refs.confirm.openAsDeleteResource(this.$termSync('Meeting Guide'), { })) {
                await MeetingGuide.delete(item.id);
                EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Meeting Guide')} Deleted`);
                await this.save();
            }
        }
    },
    computed: {
        filtered() {
            if (!this.data) {
                return [];
            }
            return this.data.filter(x => true).sort((a, b) => a.displayOrder - b.displayOrder);
        },

        dirty() {
            for (let x of this.data) {
                if (x.dirty) {
                    return true;
                }
            }
            return false;
        }
    }
};
</script>
