<template>
    <v-dialog v-model='dialog' max-width='1200'>
        <v-card v-if='showMainDetails'>
            <v-card-title class='title dialog-title'>{{ row.location.name }}</v-card-title>

            <v-card-text class='py-0' v-if='model'>
                <v-form ref='form' :lazy-validation='true'>
                    <v-row>
                        <v-col cols='4'>
                            <heading-details-image
                                :canUpload='!readonly'
                                :name="'Survey Memo'"
                                :model='surveyMemoFile'
                                @onUpload='setImage'
                                @onRemove='removeImage'
                            ></heading-details-image>
                        </v-col>
                        <v-col cols='4'>
                            <heading-details-image
                                :canUpload='!readonly'
                                :model='groundSupportFile'
                                :name="'Ground Support Profile'"
                                @onUpload='setImage'
                                @onRemove='removeImage'
                            ></heading-details-image>
                        </v-col>
                        <v-col cols='4'>
                            <heading-details-image
                                :canUpload='!readonly'
                                :model='otherFile'
                                :name="'Other'"
                                @onUpload='setImage'
                                @onRemove='removeImage'
                            ></heading-details-image>
                        </v-col>
                        <v-col cols='3' v-if='showProgress'></v-col>
                        <v-col cols='6' v-if='showProgress'>
                            <v-access-control>
                                <template #offline>
                                    <span></span>
                                </template>
                                <location-progress
                                    :complete='getPastBlasts(row.id)'
                                    :pending='getPendingBlasts(row.id)'
                                    :target='row.targetCycles'
                                />
                                <div class='text-center mt-2 overline'>
                                    {{ getPastBlasts(row.id) }} of {{ row.targetCycles }} complete
                                </div>
                            </v-access-control>
                        </v-col>
                        <v-col cols='3' v-if='showProgress'></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols='2'>
                            <v-access-control :roles='[AppRoles.WeekPlanner]'>
                                <template #offline>
                                    <v-tooltip bottom>
                                        <template #activator='{ on }'>
                                            <v-text-field
                                                type='number'
                                                v-model='row.priority'
                                                :readonly='true'
                                                :disabled='true'
                                                label='Priority'
                                                v-on='on'
                                            ></v-text-field>
                                        </template>
                                        <span>Cannot edit whilst offline.</span>
                                    </v-tooltip>

                                </template>
                                <template #forbidden>
                                    <v-tooltip bottom>
                                        <template #activator='{ on }'>
                                            <v-text-field
                                                type='number'
                                                v-model='row.priority'
                                                :readonly='true'
                                                :disabled='true'
                                                label='Priority'
                                                v-on='on'
                                            ></v-text-field>
                                        </template>
                                        <span>Must have week planner role.</span>
                                    </v-tooltip>
                                </template>
                                <v-text-field
                                    type='number'
                                    v-model='internalLocationPriority'
                                    :readonly='readonly'
                                    label='Priority'
                                    :rules='[$rules.min(1)]'
                                ></v-text-field>
                            </v-access-control>
                        </v-col>
                        <v-col cols='6'></v-col>
                        <v-col cols='2'>
                            <v-btn :color='hasHazards ? "ordo-signage-yellow" : "primary"' :text="!hasHazards"
                                   @click='onShowHazards' class="mr-1">
                                <v-icon v-if="hasHazards" left>mdi-alert</v-icon>
                                {{ hazardsButtonText }}
                            </v-btn>
                        </v-col>
                        <v-col cols='2'>
                            <v-btn @click='onShowCommentary'>
                                <v-icon left>mdi-message-text</v-icon>
                                {{ commentaryButtonText }}
                            </v-btn>
                        </v-col>

                    </v-row>
                    <v-row>
                        <v-col cols='2'>
                            <v-checkbox :readonly='readonly' v-model='model.unavailable' label='Unavailable' />
                        </v-col>
                        <v-col cols='2'>
                            <v-select
                                :readonly='readonly'
                                :items='filteredStockpiles'
                                item-value='id'
                                item-text='name'
                                v-model='model.stockpileId'
                                :label="$termSync('Stockpile')"
                                clearable
                                :menu-props='{ dark: true }'
                                @change='() => loadStockpileStatus()'
                                @click:clear='() => stockpileStatusModel = null'
                            />
                        </v-col>
                        <v-col cols='2'>
                            <v-access-control>
                                <template #offline>
                                    <v-tooltip bottom>
                                        <template #activator='{ on }'>
                                            <div v-on='on'>
                                                <v-select
                                                    :readonly='true'
                                                    :disabled='true'
                                                    :items='stockpileStatuses'
                                                    item-value='id'
                                                    item-text='name'
                                                    v-model='stockpileStatusModel'
                                                    :label="$termSync('Stockpile Status')"
                                                    :menu-props='{ dark: true }'
                                                />
                                            </div>

                                        </template>
                                        <span>Cannot edit whilst offline.</span>
                                    </v-tooltip>
                                </template>
                                <v-select
                                    :readonly='readonly'
                                    :disabled='model.stockpileId === null'
                                    :items='stockpileStatuses'
                                    item-value='id'
                                    item-text='name'
                                    v-model='internalStockpileStatusModel'
                                    :label="$termSync('Stockpile Status')"
                                    :menu-props='{ dark: true }'
                                />
                            </v-access-control>
                        </v-col>
                        <v-col cols='3'>
                            <v-select
                                :readonly='readonly'
                                :items='materialDestinations'
                                item-value='id'
                                item-text='name'
                                v-model='model.materialDestinationId'
                                :label="$termSync('Material Destination')"
                                clearable
                                :menu-props='{ dark: true }'
                            />
                        </v-col>
                        <v-col cols='3'>
                            <v-select
                                :readonly='readonly'
                                :items='materialTypes'
                                item-value='id'
                                item-text='name'
                                v-model='model.materialTypeId'
                                :label="$termSync('Material Type')"
                                clearable
                                :menu-props='{ dark: true }'
                            />
                        </v-col>
                    </v-row>
                    <v-scroll-y-transition>
                        <v-row v-if='showUnavailabilityReason'>
                            <v-col cols='6'>
                                <v-text-field
                                    v-model='model.unavailabilityReason'
                                    :readonly='readonly'
                                    label='Unavailability Reason'
                                    :rules='[$rules.maxLength(100)]'
                                    counter
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-scroll-y-transition>
                    <v-row>
                        <v-col cols='12'>
                            <v-textarea
                                :readonly='readonly'
                                outlined
                                v-model='model.comments'
                                label='Details'
                                :rows='3'
                                auto-grow
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols='12'>
                            <v-textarea
                                :readonly='readonly'
                                outlined
                                v-model='model.varianceToPlan'
                                label='Variance (to plan) Comments'
                                :rows='3'
                                auto-grow
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='onClose'>{{ hasUnsavedChanges ? 'Cancel' : 'Close' }}</v-btn>
                <v-btn v-if='!readonly' color='primary' text @click='save' :loading="$wait.is('saving')">Save</v-btn>
            </v-card-actions>
        </v-card>
        <location-commentary-sheet
            v-else-if='showCommentaryDetails'
            :commentary='model.commentary'
            :title='row.location.name'
            :location-id='row.location.id'
            allow-offline-edits
            @save='onSaveCommentary'
            @close='onCloseCommentary'
        >
        </location-commentary-sheet>
        <hazard-notes-sheet
            v-else-if='showHazardsDetails'
            :hazards='model.hazardNotes'
            :attached-object-name='row.location.name'
            @save='onSaveHazards'
            @add='onAddHazards'
            @close='onCloseHazards'
        >
        </hazard-notes-sheet>
        <confirm ref='confirm'></confirm>
    </v-dialog>
</template>
<script>
import Vue, { PropType } from 'vue';
import { EventBus, Events } from '@/lib/EventBus';
import LocationProgress from '@/components/ShiftBoard/LocationProgress.vue';
import HeadingDetailsImage from './HeadingDetailsImage.vue';
import AppHttp from '@/lib/AppHttp';
import ShiftLocationDetails from '@/lib/data/ShiftLocationDetails';
import {
    AddLocationHazardsAtShiftCommand,
    HazardNoteUpdateModel,
    LocationFileType,
    UpdateLocationHazardsAtShiftCommand
} from '@/models/api';
import Stockpiles from '@/lib/data/Stockpiles';
import MaterialDestinations from '@/lib/data/MaterialDestinations';
import MaterialTypes from '@/lib/data/MaterialTypes';
import ModelBuilder from '@/lib/ModelBuilder';
import Account from '@/lib/data/Account';
import Shift from '@/lib/data/Shift';
import { mapState } from 'pinia';
import { useMineAreaStore } from '@/lib/stores/MineAreaStore';
import { useSystemStore } from '@/lib/stores/SystemStore';
import { useShiftDetails } from '@/lib/stores/Shift';
import { useDepartmentStore } from '@/lib/stores/DepartmentStore';
import { useHeadingsStore } from '@/lib/stores/HeadingsStore';
import { isFireTaskType } from '@/lib/TaskType';
import Logging from '@/lib/Logging';
import { enumOptions } from '@/lib/EnumExtensions';
import { StockpileStatus } from '@/models/api/Data/stockpile-status';
import { Roles as AppRoles } from '@/lib/Security';
import LocationCommentarySheet from '@/components/Shared/LocationCommentarySheet.vue';
import Locations from '@/lib/data/Locations';
import $rules from '@/lib/Validation';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import HazardNotesSheet from '@/components/Shared/HazardNotesSheet.vue';
import { TransformClientAddHazardsCommandForLocation } from '@/models/client/Commands/client-add-hazards-command';
import { TransformClientUpdateHazardsCommandForLocation } from '@/models/client/Commands/client-update-hazards-command';
import { Pluralize } from '@/lib/services/Utils';
import Tenants from '@/lib/Tenants';

export default Vue.extend({
    props: ['readonly'],
    components: { HazardNotesSheet, LocationCommentarySheet, LocationProgress, HeadingDetailsImage },
    data() {
        return {
            dialog: false,
            locationName: 'Location',
            row: null,
            shiftId: null,
            model: null,
            initialModel: null,
            stockpileStatusModel: null,
            showProgress: true,
            showCommentary: false,
            showHazards: false,
            tenantId: null,
            surveyMemoFile: { locationFileType: LocationFileType.SurveyMemo },
            groundSupportFile: { locationFileType: LocationFileType.GroundSupport },
            otherFile: { locationFileType: LocationFileType.Other },
            stockpileStatuses: enumOptions(StockpileStatus, this.$options.filters.stockpileStatus),
            internalLocationPriority: null,
            internalStockpileStatusModel: null
        };
    },
    computed: {
        AppRoles() {
            return AppRoles;
        },
        ...mapState(useMineAreaStore, ['stockPiles', 'materialTypes', 'materialDestinations']),
        ...mapState(useShiftDetails, ['shiftStartTime', 'shiftEndTime', 'weekStartTime']),
        ...mapState(useHeadingsStore, ['getHeadingById']),
        ...mapState(useDepartmentStore, ['endOfShiftTaskType']),
        filteredStockpiles() {
            //TODO: question: Are stockpiles site wide? but defined per mine area?
            // const mineAreaId = this.$route.params.mine;
            // return this.stockPiles.filter(x => x.mineAreaId === mineAreaId);
            return this.stockPiles;
        },
        showMainDetails() {
            return this.row && !this.showCommentary && !this.showHazards;
        },
        showCommentaryDetails() {
            return this.row && this.showCommentary;
        },
        showHazardsDetails() {
            return this.row && this.showHazards;
        },
        commentaryButtonText() {
            return this.model.commentary?.comment ? 'Notes' : 'Add Note';
        },
        hasHazards() {
            return this.hazardsCount > 0;
        },
        hazardsCount() {
            return this.model.hazardNotes.length;
        },
        hazardsButtonText() {
            return this.hasHazards ? `${this.hazardsCount} ${Pluralize(this.hazardsCount, 'Hazard', 'Hazards')}` : 'Add Hazard';
        },
        showUnavailabilityReason() {
            return this.model && this.model.unavailable;
        },
        hasUnsavedChanges() {
            return !_.isEqual(this.model, this.initialModel);
        }
    },
    async created() {
    },
    methods: {
        async show(row, shiftId, weekId, showProgress) {
            this.dialog = true;
            this.row = row;
            this.shiftId = shiftId;
            this.weekId = weekId;
            this.imageSource = null;
            this.model = null;
            this.initialModel = null;
            this.stockpileStatusModel = null;
            this.internalLocationPriority = row.priority;
            this.showProgress = showProgress;
            this.showHazards = false;
            this.showCommentary = false;
            await this.loadData();
        },
        async loadData() {
            try {
                this.$wait.start('get data');

                if (useSystemStore().isOnline) {
                    this.model = await ShiftLocationDetails.getFor(this.shiftId, this.row.id);
                    if (this.model) {
                        this.loadStockpileStatus();
                    }
                } else {
                    const shiftlocationDetails = useShiftDetails().getCurrentShiftLocationDetails(this.row.id);
                    this.model = { ...shiftlocationDetails, images: [] };
                }

                this.initialModel = _.cloneDeep(this.model);

                var cacheKey = +new Date();
                this.surveyMemoFile = this.getImageType(this.model.images, LocationFileType.SurveyMemo, cacheKey);
                this.groundSupportFile = this.getImageType(this.model.images, LocationFileType.GroundSupport, cacheKey);
                this.otherFile = this.getImageType(this.model.images, LocationFileType.Other, cacheKey);
                this.newHazard = '';
                this.removingHazards = [];
            } catch (e) {
                Logging.error(e);
                return;
            } finally {
                this.$wait.end('get data');
            }
        },
        onShowHazards() {
            this.showHazards = true;
        },
        onShowCommentary() {
            this.showCommentary = true;
        },
        async onSaveCommentary(newCommentary) {
            this.$wait.start('saving');
            this.showCommentary = false;

            try {
                var command = ModelBuilder.Commands.UpdateLocationCommentaryCommand(this.row.location.id, newCommentary.comment);
                await Locations.executeUpdateCommentary(command);
                useShiftDetails().updateLocationCommentary(this.row.id, newCommentary);
                this.model.commentary = { ...newCommentary };
            } catch (e) {
                Logging.error(e);
                return;
            } finally {
                this.$wait.end('saving');
            }
        },
        async onSaveHazards(eventInfo) {
            try {
                this.$wait.start('saving');
                this.showHazards = false;

                const command = TransformClientUpdateHazardsCommandForLocation(eventInfo, this.shiftId, this.row.location.id);

                await ShiftLocationDetails.updateHazards(command);
                await useShiftDetails().updateShiftLocationHazards(this.row.location.id, eventInfo);

                await this.loadData();

                this.$emit('saved');
            } catch (e) {
                Logging.error(e);
                return;
            } finally {
                this.$wait.end('saving');
            }
        },
        async onAddHazards(eventInfo) {
            try {
                this.$wait.start('saving');
                this.showHazards = false;

                const command = TransformClientAddHazardsCommandForLocation(eventInfo, this.shiftId, this.locationId);

                await ShiftLocationDetails.addHazards(command);
                await useShiftDetails().addShiftLocationHazards(this.locationId, eventInfo);

                await this.loadData();

                this.$emit('saved');
            } catch (e) {
                Logging.error(e);
                return;
            } finally {
                this.$wait.end('saving');
            }
        },
        onCloseCommentary() {
            this.showCommentary = false;
        },
        onCloseHazards() {
            this.showHazards = false;
        },
        loadStockpileStatus() {
            const stockpile = this.stockPiles.find(x => x.id == this.model.stockpileId);
            this.stockpileStatusModel = stockpile?.status;
            this.internalStockpileStatusModel = stockpile?.status;
        },
        async onClose() {
            if (this.hasUnsavedChanges) {
                if (await this.confirmCancelChanges()) {
                    this.dialog = false;
                }
            } else {
                this.dialog = false;
            }
        },
        async confirmCancelChanges() {
            // @ts-ignore
            return await this.$refs.confirm.open(
                'Abandon Changes',
                'You have updated the location information but must press the "Save" button to save your changes. Are you sure you want to discard them?',
                { color: 'red' }
            );
        },
        async save() {
            var form = this.$refs.form;
            if (!form.validate()) {
                return;
            }

            try {
                this.$wait.start('saving');

                var command = ModelBuilder.Commands.UpdateShiftLocationDetailsCommand({
                    ...this.model
                });
                useShiftDetails().updateShiftLocationDetails(this.row.id, command);
                this.model = await ShiftLocationDetails.send(command);
                if (useSystemStore().isOnline) {
                    if ((command && command.stockpileId) && this.internalStockpileStatusModel !== this.stockpileStatusModel) {
                        await Stockpiles.updateStatus(command.stockpileId, this.stockpileStatusModel);
                    }

                    if (this.internalLocationPriority !== this.row.priority) {
                        this.row.priority = this.internalLocationPriority;
                        await Shift.updateLocationPriorityInWeek(this.weekId, this.row.location.id, this.internalLocationPriority);
                    }
                }

                this.$emit('saved');
                this.dialog = false;
            } catch (e) {
                Logging.error(e);
                return;
            } finally {
                this.$wait.end('saving');
            }
        },
        getPendingBlasts(rowId) {
            const heading = this.getHeadingById(rowId);
            const futureBlasts = heading.tasks.reduce((blastCount, currentTask) => {
                if (
                    isFireTaskType(currentTask.taskType, this.endOfShiftTaskType) &&
                    currentTask.startTime.isBefore(this.shiftEndTime) &&
                    currentTask.endTime.isAfter(this.shiftStartTime)
                ) {
                    blastCount = blastCount + 1;
                }

                return blastCount;
            }, 0);

            return futureBlasts;
        },
        getPastBlasts(rowId) {
            const heading = this.getHeadingById(rowId);

            const futureBlasts = heading.tasks.reduce((blastCount, currentTask) => {
                if (
                    isFireTaskType(currentTask.taskType, this.endOfShiftTaskType) &&
                    currentTask.startTime.isBefore(this.shiftStartTime) &&
                    currentTask.endTime.isAfter(this.weekStartTime)
                ) {
                    blastCount = blastCount + 1;
                }

                return blastCount;
            }, 0);

            return futureBlasts;
        },
        async setImage(e, type) {
            const file = e.target.files[0];

            if (!file.type.includes('image/') && !file.type.includes('/pdf')) {
                EventBus.$emit(Events.ToastError, 'Please select an image or PDF file');
                return;
            }
            try {
                this.$wait.start('uploading' + type);
                await ShiftLocationDetails.uploadLocationFile(this.shiftId, this.row.location.id, type, file);

                var comments = this.model.comments;
                await this.loadData();
                this.model.comments = comments;
            } finally {
                this.$wait.end('uploading' + type);
            }
        },
        async removeImage(locationFileId) {
            try {
                this.$wait.start('removing');
                await ShiftLocationDetails.deleteLocationFile(this.shiftId, locationFileId);
                await this.loadData();
            } finally {
                this.$wait.end('removing');
            }
        },
        getImageType(images, locationFileType, cacheKey) {
            let imageTypes = images.filter((x) => x.locationFileType === locationFileType);
            if (imageTypes.length === 0) {
                return { locationFileType: locationFileType };
            }
            let imageType = imageTypes[0];
            if (!imageType.imageId) {
                return { ...imageType };
            }
            return {
                ...imageType,
                imageSource: AppHttp.urlFromPath(
                    `Images/GetById/${imageType.imageId}?viewTenantId=${Tenants.GetCurrentTenant()}&rnd=${cacheKey}`
                ),
            };
        },
        isHazardMarkedForRemoval(hazardNote) {
            return this.removingHazards.includes(hazardNote.id);
        }
    }
});
</script>
