import { GenericDataApi } from '@/lib/data/GenericDataApi';
import AppHttp from '@/lib/AppHttp';
import { MaterialDestination } from '@/models/api/Data/material-destination';

export class MaterialDestinations extends GenericDataApi<any> {
    constructor() {
        super('MaterialDestinations');
    }

    public async getForMineArea(mineAreaId: string, include?: string[]): Promise<MaterialDestination[]> {
        let query = '';
        if (include) {
            const p: string = include.map((x) => `include=${x}`).join('&');
            query = '?' + p;
        }

        const response = await AppHttp.get(`${this.resource}/MineAreas/${mineAreaId}${query}`);

        const data = response ? response.data : null;
        if(data == null) throw Error('No response data');
        return data;
    }
}

export default new MaterialDestinations();
