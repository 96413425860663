import { EventBus, Events } from '@/lib/EventBus';
import dayjs from 'dayjs';

export const SHIFT_VIEW_SHIFT = 'shift';
export const SHIFT_VIEW_24H = '24h';

export type SHIFT_VIEW = typeof SHIFT_VIEW_SHIFT | typeof SHIFT_VIEW_24H;

export class CurrentShiftStore {
    public selectedDate: Date | string | number | null = null;
    public selectedShiftNumber: string | number | null = null;
    public selectedMineArea: string | number | null = null;
    public selectedDepartment: string | number | null = null;
    public selectedBoardType: string | null = 'shift';
    public selectedSnapshot: string | null = null;
    public selectedShiftView: string | null = SHIFT_VIEW_SHIFT;

    public async set(mineArea: string | null, date: Date | string | number | null, num: string | number | null) {
        this.selectedDate = date;
        this.selectedShiftNumber = Number(num);
        this.selectedMineArea = mineArea;
        this.selectedSnapshot = null;
        localStorage.setItem('selectedDate', dayjs(date || new Date()).format('YYYY-MM-DD'));
        localStorage.setItem('selectedShiftNumber', `${num}`);
        localStorage.setItem('selectedMineArea', mineArea || '');
        localStorage.setItem('selectedSnapshot', '');

        EventBus.$emit(Events.ShiftSelected, {
            mineArea: this.selectedMineArea,
            selectedDate: this.selectedDate,
            shiftNumber: this.selectedShiftNumber,
        });
    }

    public setBoard(type: string) {
        if (type === 'week' || type === 'shift' || type.endsWith('tracker')) {
            EventBus.$emit(Events.ShowFeature, 'Searchbar', true);
        } else {
            EventBus.$emit(Events.ShowFeature, 'Searchbar', false);
        }

        this.selectedBoardType = type;
        localStorage.setItem('selectedBoardType', type);
        EventBus.$emit(Events.SelectedBoardChanged, type);
    }

    public setShiftView(view: SHIFT_VIEW) {
        this.selectedShiftView = view;
        localStorage.setItem('selectedShiftView', view);
    }

    public setDepartment(dept: string) {
        this.selectedDepartment = dept;
        localStorage.setItem('selectedDepartment', dept);
    }

    public setSnapshot(snapshot: string) {
        this.selectedSnapshot = snapshot;
        localStorage.setItem('selectedSnapshot', snapshot);
    }

    public removeSnapshot() {
        this.selectedSnapshot = null;
        localStorage.removeItem('selectedSnapshot');
    }

    public async loadFromLocal() {
        this.selectedDate = localStorage.getItem('selectedDate');
        this.selectedMineArea = localStorage.getItem('selectedMineArea');
        this.selectedDepartment = localStorage.getItem('selectedDepartment');
        this.selectedSnapshot = localStorage.getItem('selectedSnapshot');
        this.selectedShiftView = localStorage.getItem('selectedShiftView') || SHIFT_VIEW_SHIFT;

        let num: any = localStorage.getItem('selectedShiftNumber');
        if (num) {
            num = parseInt(num, 10);
        }
        this.selectedShiftNumber = num;

        this.selectedBoardType = localStorage.getItem('selectedBoardType') || 'shift';
    }

    public wipeLocal() {
        this.selectedDate = null;
        this.selectedMineArea = null;
        this.selectedDepartment = null;
        this.selectedSnapshot = null;
        this.selectedShiftNumber = null;
        this.selectedBoardType = 'shift';
        this.selectedShiftView = SHIFT_VIEW_SHIFT;

        this.wipeLocalStorage();
    }

    wipeLocalStorage() {
        localStorage.removeItem('selectedDate');
        localStorage.removeItem('selectedMineArea');
        localStorage.removeItem('selectedDepartment');
        localStorage.removeItem('selectedSnapshot');
        localStorage.removeItem('selectedShiftNumber');
        localStorage.removeItem('selectedBoardType');
        localStorage.removeItem('selectedShiftView');
    }
}
const instance = new CurrentShiftStore();
instance.loadFromLocal();
export default instance;
