import { ProductionFrontRingDiagramInformationViewModel, RingRuleEvaluationStatus } from '@/models/api';
import _ from 'lodash';

export function SortLocationRingsForDisplay(rings: ProductionFrontRingDiagramInformationViewModel[]): ProductionFrontRingDiagramInformationViewModel[] {
    return [...rings].sort((a,b)=>{
        const indexSort = b.productionFrontCoordinates[2]-a.productionFrontCoordinates[2];

        if(indexSort === 0)
            return b.displayOrder - a.displayOrder;

        return indexSort;
    });
}

export function GroupAndSortLocationRingsForDisplay(rings: ProductionFrontRingDiagramInformationViewModel[]): { ring: ProductionFrontRingDiagramInformationViewModel, proportionalLength: number }[] {
    const ringsGrouped = _.groupBy(rings, r=>r.productionFrontCoordinates[2]);

    return _.flatMap(_.keys(ringsGrouped), k=>{
        const ringsInGroup = ringsGrouped[k];

        return ringsInGroup.map(r=>({
            ring: r,
            proportionalLength: 1/ringsInGroup.length
        }));
    }).sort((a,b)=>{
        const indexSort =  (b.ring.productionFrontCoordinates[2]-a.ring.productionFrontCoordinates[2]);

        if(indexSort === 0)
            return  (b.ring.displayOrder - a.ring.displayOrder);

        return indexSort;
    });
}

export function LastDrilledRingLabelPredicateBuilder() : (ring: ProductionFrontRingDiagramInformationViewModel) => boolean {
    let alreadyApplied = false;

    return (ring)=>{
        if(!alreadyApplied && ring.currentStatus === RingRuleEvaluationStatus.Drilled) {
            alreadyApplied = true;
            return true;
        }

        return false;
    }
}

export function NextToFireRingLabelPredicateBuilder() : (ring: ProductionFrontRingDiagramInformationViewModel, nextRingMaybe: ProductionFrontRingDiagramInformationViewModel | undefined) => boolean {
    let alreadyApplied = false;

    return (ring, nextRingMaybe)=>{
        if(!alreadyApplied && (nextRingMaybe == null || nextRingMaybe.currentStatus === RingRuleEvaluationStatus.Fired || nextRingMaybe.currentStatus === RingRuleEvaluationStatus.Bogging)) {
            alreadyApplied = true;
            return true;
        }

        return false;
    }
}