import { GenericDataApi } from '@/lib/data/GenericDataApi';
import { EquipmentRole } from '@/models/api/Data/equipment-role';
import { AxiosError } from 'axios';
import AppHttp from '../AppHttp';
import Errors from '../Errors';

export class EquipmentRoles extends GenericDataApi<EquipmentRole> {
    constructor() {
        super('EquipmentRoles');
    }
    public async export() {
        const response = await AppHttp.get(`${this.resource}/export`);
        const data = response ? response.data : null;
        return data;
    }
    public async import(file: any) {
        const response = await AppHttp.postWithFiles(`${this.resource}/import`, {}, [file]);
        const data = response ? response.data : null;
        return data;
    }
}

export default new EquipmentRoles();
