import ClientRowModel from '@/models/client/client-row';

export function getRowIndex(rows: ClientRowModel[], rowId: string): number {
    const rowIndex = rows.findIndex((row) => row.id == rowId);
    if (rowIndex === -1) {
        throw new Error(`Could not find heading with planned location id ${rowId} in board row tasks`);
    }
    return rowIndex;
}

export function getTaskIndex(row: ClientRowModel, taskId: string): number {
    const taskIndex = row.tasks.findIndex((task) => task.id === taskId);
    if (taskIndex === -1) {
        throw new Error(`Could not find task id ${taskId} in row ${row.id} in board row tasks`);
    }
    return taskIndex;
}

export function toggleEquipmentAssignment(
    rows: ClientRowModel[],
    previousRows: ClientRowModel[],
    rowId: string,
    taskId: string,
    newEquipmentId: string
): string | null {
    const rowIndex = getRowIndex(rows, rowId);
    const taskIndex = getTaskIndex(rows[rowIndex], taskId);
    const task = rows[rowIndex].tasks[taskIndex];
    const previousTask = previousRows[rowIndex].tasks[taskIndex];
    if (task.taskType?.isDelay === true) {
        return null;
    }
    const isSameEquipmentCurrentlyAssigned = task.primaryEquipment?.equipmentId === newEquipmentId;
    const previousAssignedEquipment = previousTask?.primaryEquipment?.equipmentId ?? null;
    if (isSameEquipmentCurrentlyAssigned && previousAssignedEquipment === newEquipmentId) {
        return null;
    } else if (isSameEquipmentCurrentlyAssigned) {
        return previousAssignedEquipment;
    } else {
        return newEquipmentId;
    }
}
