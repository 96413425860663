import { ICommandMapper } from '../ICommandMapper';
import { UpdateTaskTimesCommand, OfflineEditableCommand } from '@/models/api';
import { EditStore, Edit } from '../EditStore';
import ConvertCommandsToBaselineHelper from './Helpers/ConvertCommandsToBaselineHelper';

export class UpdateTaskTimesCommandMapper implements ICommandMapper<UpdateTaskTimesCommand> {
    commandType = 'UpdateTaskTimesCommand';
    async onQueue(command: UpdateTaskTimesCommand, store: EditStore) {
        await ConvertCommandsToBaselineHelper.onQueue(command, store);
    }
    onDequeue(edit: Edit<OfflineEditableCommand>, attemptedAt: Date): Edit<any> {
        return ConvertCommandsToBaselineHelper.onDequeue(edit, attemptedAt);
    }
    mappers = [ConvertCommandsToBaselineHelper.createShiftModelMapper<UpdateTaskTimesCommand>()];
}

export default new UpdateTaskTimesCommandMapper();
