import { ClientTaskWarning } from '@/models/client/client-task-warning';
import { RingRuleEvaluationState, RingRuleEvaluationStatus } from '@/models/api';
import { ConvertTaskTypeToStatus, RING_STATUS_EN } from '@/lib/services/Production/shared';
import _ from 'lodash';
import { FormatTaskQuantity } from '@/lib/services/Task';
import { BlastPacketRingTargetEvaluation } from '@/lib/services/Production/LocationRingStateMachine';
import dayjs from 'dayjs';
import { AffectedAreaSpecification } from '@/lib/stores/HeadingsStore';
import { ProductionValidationTaskModel } from '@/models/client/production-validation-task-model';

const MESSAGES = {
    STATUS_DOWNGRADE: (incStatus: string,currentStatus: string) => `Cannot ${incStatus} once ring is in state ${currentStatus}.`,
    STATUS_SKIPPED: (incStatus: string, currentStatus: string) => `Cannot ${incStatus} if ring state is ${currentStatus}.`,
    STATUS_INCOMPLETE: (incStatus: string, currentStatus: string) => `Should not start ${incStatus} if ring has not finished ${currentStatus}.`,
    ALREADY_FIRED: 'Ring has already been fired.',
    STATUS_MARKED_COMPLETE: (currentStatus: string) => `${currentStatus} on ring has been marked complete.`,
    STATUS_REACHED_TARGET: (currentStatus: string) => `Ring has already completed target quantity for ${currentStatus}.`,
    TASK_WOULD_EXCEED_TARGET: (incStatus: string, excess: number) => `Would exceed ${incStatus} target by ${FormatTaskQuantity(excess)}.`,
    CANNOT_FIRE_NOT_ALL_RINGS_CHARGED: (ringNames: string[]) => `Cannot Fire. Ring(s) ${ringNames.join(',')} are not charged.`,
    CANNOT_BOG_NOT_FIRED: () => `Cannot Bog blast packet as it has not been fired.`,
    CANNOT_FIRE_NOT_ALL_RINGS_FINISHED_CHARGING: (ringNames: string[]) => `Cannot Fire. Ring(s) ${ringNames.join(',')} have not finished charging.`,
}

const UNIQUE_IDS = {
    STATUS_DOWNGRADE: 'STATUS_DOWNGRADE',
    STATUS_SKIPPED: 'STATUS_SKIPPED',
    STATUS_INCOMPLETE: 'STATUS_INCOMPLETE',
    ALREADY_FIRED: 'ALREADY_FIRED',
    STATUS_MARKED_COMPLETE: 'STATUS_MARKED_COMPLETE',
    STATUS_REACHED_TARGET: 'STATUS_REACHED_TARGET',
    TASK_WOULD_EXCEED_TARGET: 'TASK_WOULD_EXCEED_TARGET',
    NOT_ALL_RINGS_READY: 'NOT_ALL_RINGS_READY',
    CANNOT_FIRE_NOT_ALL_RINGS_CHARGED: 'CANNOT_FIRE_NOT_ALL_RINGS_CHARGED',
    CANNOT_FIRE_NOT_ALL_RINGS_FINISHED_CHARGING: 'CANNOT_FIRE_NOT_ALL_RINGS_FINISHED_CHARGING',
    CANNOT_BOG_NOT_FIRED: 'CANNOT_BOG_NOT_FIRED',
}

export const BLAST_PACKET_STATE_ISSUE_TYPE = 'BLAST_PACKET_STATE';

const NO_ERROR_RESULT = {
    isError: false,
} as BlastPacketStateValidationResult;

export const BLAST_PACKET_STATE_VALIDATION = {
    NO_ERROR_RESULT: NO_ERROR_RESULT,
    MESSAGES: MESSAGES
}

interface BlastPacketStateValidationResult {
    isError: boolean,
    uniqueId: string,
    errorMessage?: string,
}

function callWarningAccumulatorIfRequired(task: ProductionValidationTaskModel, validationResult: BlastPacketStateValidationResult, warningAdditionFunc: (task: ProductionValidationTaskModel, warning: ClientTaskWarning) => void) {
    if(validationResult.isError)
        warningAdditionFunc(task, {
            message: validationResult.errorMessage!,
            warningCategoryId: `${BLAST_PACKET_STATE_ISSUE_TYPE}.${validationResult.uniqueId}`,
            type: BLAST_PACKET_STATE_ISSUE_TYPE
        });
}

function unshieldedProjectedToExceedTargetValidator(target: BlastPacketRingTargetEvaluation,
                                                    task: ProductionValidationTaskModel,
                                                    incomingStatus: RingRuleEvaluationStatus,
                                                    ringSpecifications: AffectedAreaSpecification[],
                                                    currentRingStates: { locationRingId: string, currentState: RingRuleEvaluationState }[],
                                                    currentBlastPacketState: { isFired: boolean, boggedAmount: number, boggingMarkedCompleted: boolean, cutTaskAt: dayjs.Dayjs | null }): BlastPacketStateValidationResult {
    if(incomingStatus !== RingRuleEvaluationStatus.Bogging || task.ratePerHour == null || task.quantity == null)
        return NO_ERROR_RESULT;

    const existingQuantity = currentBlastPacketState.boggedAmount;
    const targetQuantityPlusTolerance = (1+target.tolerance)*target.targetQuantity;

    const overlapDuration = currentBlastPacketState.cutTaskAt != null && currentBlastPacketState.cutTaskAt.isAfter(task.startTime) ? currentBlastPacketState.cutTaskAt.diff(task.startTime, 'minutes') : 0;
    const maximumOverlapDuration = Math.min(overlapDuration, task.durationMinutes);

    if(maximumOverlapDuration === task.durationMinutes)
        return NO_ERROR_RESULT;

    const durationToCalculateFrom = task.durationMinutes - maximumOverlapDuration;

    const taskProRataQuantity = durationToCalculateFrom === task.durationMinutes ? task.quantity : durationToCalculateFrom*task.ratePerHour/60;

    const excessPlannedQuantity = existingQuantity + taskProRataQuantity - targetQuantityPlusTolerance;

    if(excessPlannedQuantity <= 0)
        return NO_ERROR_RESULT;

    if(excessPlannedQuantity > 10)
        return {
            isError: true,
            uniqueId: UNIQUE_IDS.TASK_WOULD_EXCEED_TARGET,
            errorMessage: MESSAGES.TASK_WOULD_EXCEED_TARGET(RING_STATUS_EN.PRESENT_TENSE_PROGRESSIVE[incomingStatus], excessPlannedQuantity)
        };

    return NO_ERROR_RESULT;
}

export const ProjectedToExceedTargetValidator = _.curry(incomingTaskHasCorrectStatusShield)(unshieldedProjectedToExceedTargetValidator);

function unshieldedNotAllRingsChargedValidator(target: BlastPacketRingTargetEvaluation,
                                             task: ProductionValidationTaskModel,
                                             incomingStatus: RingRuleEvaluationStatus,
                                             ringSpecifications: AffectedAreaSpecification[],
                                             currentRingStates: { locationRingId: string, currentState: RingRuleEvaluationState }[],
                                             currentBlastPacketState: { isFired: boolean, boggedAmount: number, boggingMarkedCompleted: boolean, cutTaskAt: dayjs.Dayjs | null }): BlastPacketStateValidationResult {

    if(incomingStatus !== RingRuleEvaluationStatus.Fired)
        return NO_ERROR_RESULT;

    if(currentRingStates.some(r=>r.currentState.ringStatus === RingRuleEvaluationStatus.NotDrilled || r.currentState.ringStatus === RingRuleEvaluationStatus.Drilled)){
        // incorrect rings
        const notChargedRingIds = currentRingStates.filter(r=>r.currentState.ringStatus === RingRuleEvaluationStatus.NotDrilled || r.currentState.ringStatus === RingRuleEvaluationStatus.Drilled)
            .map(r=>r.locationRingId);

        return {
            isError: true,
            uniqueId: UNIQUE_IDS.CANNOT_FIRE_NOT_ALL_RINGS_CHARGED,
            errorMessage: MESSAGES.CANNOT_FIRE_NOT_ALL_RINGS_CHARGED(ringSpecifications.filter(rs=>notChargedRingIds.includes(rs.locationRingId!)).map(rs=>rs.locationRingName ?? 'Unknown'))
        };
    }

    return NO_ERROR_RESULT;
}

export const NotAllRingsChargedValidator = _.curry(incomingTaskHasCorrectStatusShield)(unshieldedNotAllRingsChargedValidator);

function unshieldedNotAllRingsFinishedChargingValidator(target: BlastPacketRingTargetEvaluation,
                                               task: ProductionValidationTaskModel,
                                               incomingStatus: RingRuleEvaluationStatus,
                                               ringSpecifications: AffectedAreaSpecification[],
                                               currentRingStates: { locationRingId: string, currentState: RingRuleEvaluationState }[],
                                               currentBlastPacketState: { isFired: boolean, boggedAmount: number, boggingMarkedCompleted: boolean, cutTaskAt: dayjs.Dayjs | null }): BlastPacketStateValidationResult {

    if(incomingStatus !== RingRuleEvaluationStatus.Fired)
        return NO_ERROR_RESULT;

    if(currentRingStates.some(r=>r.currentState.ringStatus === RingRuleEvaluationStatus.NotDrilled || r.currentState.ringStatus === RingRuleEvaluationStatus.Drilled)){
        // if some haven't charged, there's no point checking if they're all fully charged
        return BLAST_PACKET_STATE_VALIDATION.NO_ERROR_RESULT;
    }

    if(currentRingStates.some(r=>r.currentState.ringStatus === RingRuleEvaluationStatus.Charged && !r.currentState.reachedTarget && !r.currentState.markedCompleted)){
        // incorrect rings
        const notFinishedChargingRingIds = currentRingStates.filter(r=>r.currentState.ringStatus === RingRuleEvaluationStatus.Charged && !r.currentState.reachedTarget && !r.currentState.markedCompleted)
            .map(r=>r.locationRingId);

        return {
            isError: true,
            uniqueId: UNIQUE_IDS.CANNOT_FIRE_NOT_ALL_RINGS_FINISHED_CHARGING,
            errorMessage: MESSAGES.CANNOT_FIRE_NOT_ALL_RINGS_FINISHED_CHARGING(ringSpecifications.filter(rs=>notFinishedChargingRingIds.includes(rs.locationRingId!)).map(rs=>rs.locationRingName ?? 'Unknown'))
        };
    }

    return NO_ERROR_RESULT;
}

export const NotAllRingFinishedChargingValidator = _.curry(incomingTaskHasCorrectStatusShield)(unshieldedNotAllRingsFinishedChargingValidator);

function unshieldedNotFiredValidator(target: BlastPacketRingTargetEvaluation,
                                                        task: ProductionValidationTaskModel,
                                                        incomingStatus: RingRuleEvaluationStatus,
                                                        ringSpecifications: AffectedAreaSpecification[],
                                                        currentRingStates: { locationRingId: string, currentState: RingRuleEvaluationState }[],
                                                        currentBlastPacketState: { isFired: boolean, boggedAmount: number, boggingMarkedCompleted: boolean, cutTaskAt: dayjs.Dayjs | null }): BlastPacketStateValidationResult {

    if(incomingStatus !== RingRuleEvaluationStatus.Bogging)
        return NO_ERROR_RESULT;

    if(currentRingStates.some(r=>r.currentState.ringStatus !== RingRuleEvaluationStatus.Fired && r.currentState.ringStatus !== RingRuleEvaluationStatus.Bogging)){
        return {
            isError: true,
            uniqueId: UNIQUE_IDS.CANNOT_BOG_NOT_FIRED,
            errorMessage: MESSAGES.CANNOT_BOG_NOT_FIRED()
        };
    }

    return NO_ERROR_RESULT;
}

export const NotFiredValidator = _.curry(incomingTaskHasCorrectStatusShield)(unshieldedNotFiredValidator);

function incomingTaskHasCorrectStatusShield(shieldedFunction: (target: BlastPacketRingTargetEvaluation, task: ProductionValidationTaskModel, incomingStatus: RingRuleEvaluationStatus, ringSpecifications: AffectedAreaSpecification[], currentRingStates: { locationRingId: string, currentState: RingRuleEvaluationState }[], currentBlastPacketState: { isFired: boolean, boggedAmount: number}) => BlastPacketStateValidationResult, target: BlastPacketRingTargetEvaluation, task: ProductionValidationTaskModel, ringSpecifications: AffectedAreaSpecification[], currentRingStates: { locationRingId: string, currentState: RingRuleEvaluationState }[], currentBlastPacketState: { isFired: boolean, boggedAmount: number}): BlastPacketStateValidationResult {
    const incomingStatus = ConvertTaskTypeToStatus(task.taskType);

    if(incomingStatus == null)
        return BLAST_PACKET_STATE_VALIDATION.NO_ERROR_RESULT;

    if(incomingStatus !== RingRuleEvaluationStatus.Bogging && incomingStatus !== RingRuleEvaluationStatus.Fired)
        return BLAST_PACKET_STATE_VALIDATION.NO_ERROR_RESULT;

    return shieldedFunction(target, task, incomingStatus, ringSpecifications, currentRingStates, currentBlastPacketState);
}

export function ApplyBlastPacketTaskValidators(target: BlastPacketRingTargetEvaluation, task: ProductionValidationTaskModel, ringSpecifications: AffectedAreaSpecification[], currentRingStates: { locationRingId: string, currentState: RingRuleEvaluationState }[], currentBlastPacketState: { isFired: boolean, boggedAmount: number}, warningAdditionFunc: (task: ProductionValidationTaskModel, warning: ClientTaskWarning) => void) {
    task.warnings = task.warnings.filter(w=>w.type !== BLAST_PACKET_STATE_ISSUE_TYPE);

    const notAllRingsChargedToFireValidation = NotAllRingsChargedValidator(target, task, ringSpecifications, currentRingStates, currentBlastPacketState);

    callWarningAccumulatorIfRequired(task, notAllRingsChargedToFireValidation, warningAdditionFunc);

    const notAllRingsFullyChargedToFireValidation = NotAllRingFinishedChargingValidator(target, task, ringSpecifications, currentRingStates, currentBlastPacketState);

    callWarningAccumulatorIfRequired(task, notAllRingsFullyChargedToFireValidation, warningAdditionFunc);

    const projectedToExceedTargetValidation = ProjectedToExceedTargetValidator(target, task, ringSpecifications, currentRingStates, currentBlastPacketState);

    callWarningAccumulatorIfRequired(task, projectedToExceedTargetValidation, warningAdditionFunc);

    const notFiredValidation = NotFiredValidator(target, task, ringSpecifications, currentRingStates, currentBlastPacketState);

    callWarningAccumulatorIfRequired(task, notFiredValidation, warningAdditionFunc);
}