<template>
    <div class="pa-5">
        <content-loading :value="$wait.is('saving') || $wait.is('get data')" loaderName="User Admin"></content-loading>
        <v-card v-if="data">
            <v-card-title>
                <h1 class="title">{{ 'Users' | term }}</h1>

                <v-btn small color="secondary" class="ml-3" @click="create()">
                    <v-icon left>mdi-plus</v-icon>Add
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    clearable
                    class="admin-table-filter"
                ></v-text-field>
            </v-card-title>
            <v-data-table disable-pagination hide-default-footer :headers="headers" :items="data" :search="search">
                <template v-slot:item.roles="{ item }">
                    <v-btn text icon @click="editRoles(item)">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-chip v-for="role in item.roles" :key="role">{{ role }}</v-chip>
                </template>
                <template v-slot:item.departments="{ item }">
                    <v-btn text icon @click="editDepartments(item)">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    {{ userDepartmentsDisplay(item) }}
                </template>
                <template v-slot:item.id="{ item }">
                    <v-menu offset-y dark>
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" class="float-right">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="deleteUser(item)">
                                <v-list-item-avatar>
                                    <v-icon color="red darken-1">mdi-check</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-title class="red--text text--darken-1">Confirm DELETE</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="on = false">
                                <v-list-item-avatar>
                                    <v-icon color="grey">mdi-close</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-title class="grey--text">Cancel</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn text icon @click="activate(item)" class="float-right" v-if="item.disabled" color="red">
                        <v-icon>mdi-shield_check</v-icon>
                    </v-btn>
                    <v-menu offset-y dark v-if="!item.disabled">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" class="float-right" color="green">
                                <v-icon>mdi-shield_check</v-icon>
                            </v-btn>
                        </template>
                        <v-list v-if="false"><!--TODO: Disabled until we understand how this works across tenants.... maybe admin console only can edit?-->
                            <v-list-item @click="deactivate(item)">
                                <v-list-item-avatar>
                                    <v-icon color="red darken-1">mdi-check</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-title class="red--text text--darken-1"
                                    >Confirm Deactivate</v-list-item-title
                                >
                            </v-list-item>
                            <v-list-item @click="on = false">
                                <v-list-item-avatar>
                                    <v-icon color="grey">mdi-close</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-title class="grey--text">Cancel</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-card>

        <create-user-dialog
            v-if="showCreateDialog && roles !== null"
            :roles="roles"
            :departments = "departments"
            @cancelEdit="showCreateDialog = false"
            @saveUser="saveUser"
        />

       <user-roles-dialog
           v-if="showRoleDialog && selected !== null"
           :userName="selected.name"
           :userRoles="editingRoles"
           @cancelEdit="showRoleDialog = false"
           @saveRoles="saveRoles"
       />

       <user-departments-dialog
           v-if="showDepartmentDialog && selected !== null"
           :departments="departments"
           :user-name="selected.name"
           :user-departments="selected.departments"
           @cancelEdit="showDepartmentDialog = false"
           @saveDepartments="saveDepartments"
       />
    </div>
</template>
<script>
import Users from '@/lib/data/Users';
import UserRolesDialog from './UserRolesDialog.vue';
import CreateUserDialog from './CreateUserDialog.vue';
import Departments from '@/lib/data/Departments';
import UserDepartmentsDialog from '@/components/Admin/Users/UserDepartmentsDialog.vue';
import _ from 'lodash';

export default {
    components: { UserDepartmentsDialog, UserRolesDialog, CreateUserDialog },
    data() {
        return {
            data: null,
            roles: null,
            departments: null,
            search: '',
            selected: null,
            showCreateDialog: false,
            showRoleDialog: false,
            showDepartmentDialog: false,
            editingRoles: [],
            headers: [
                { text: 'Email', value: 'email' },
                { text: 'Name', value: 'name' },
                { text: 'Roles', value: 'roles' },
                { text: 'Departments', value: 'departments' },
                { text: '', value: 'id' }
            ]
        };
    },
    async created() {
        await this.loadData();
    },
    methods: {
        async loadData() {
            this.$wait.start('get data');
            const departments = await Departments.get(['MineArea']);
            const departmentNames = departments.map(department => ({
                id: department.id,
                name: `${department.mineArea.name} - ${department.name}`,
                mineAreaName: department.mineArea.name,
                mineAreaId: department.mineArea.id,
                departmentName: department.name
            }));
            const sortedDepartents = departmentNames.sort((a, b) => (a.name > b.name ? 1 : -1));
            this.departments = sortedDepartents;
            this.$wait.end('get data');

            this.roles = await Users.getRoles();
            this.data = await Users.get();

            for (let x of this.data) {
                x.roleSelection = this.roles.map(role => {
                    var model = { name: role, selected: false };
                    if (x.roles && x.roles.filter(r => r == role).length) {
                        model.selected = true;
                    }
                    return model;
                });
            }


        },
        create() {
            this.showCreateDialog = true;
        },
        userDepartmentsDisplay(user) {
            if(user.departments.length > 4) {
                const totalMineAreas = _.uniq(this.departments.filter(d=>user.departments.includes(d.id)).map(d=>d.mineAreaId)).length;
                return `${user.departments.length} ${this.$pluralSync('department', user.departments.length)} in ${totalMineAreas} ${this.$pluralSync('mine area', totalMineAreas)}`;
            }

            const grouped = _.groupBy(this.departments.filter(d=>user.departments.includes(d.id)), (d)=>d.mineAreaName);
            return _.keys(grouped).map(k=>{
                return `${k} (${grouped[k].map(d=>d.departmentName).join(', ')})`;
            }).join(', ');
        },
        async saveUser(newUser) {
            try {
                this.$wait.start('saving');

                await Users.save({ ...newUser });
                this.showCreateDialog = false;

                setTimeout(() => this.loadData(), 2000);
            } finally {
                //auth0 takes some time
                setTimeout(() => this.$wait.end('saving'), 2001);
            }
        },
        async activate(item) {
            try {
                this.$wait.start('saving');
                await Users.setActive(item.id, true);
                setTimeout(() => this.loadData(), 2000);
            } finally {
                //auth0 takes some time
                setTimeout(() => this.$wait.end('saving'), 2001);
            }
        },
        async deactivate(item) {
            try {
                this.$wait.start('saving');
                await Users.setActive(item.id, false);
                setTimeout(() => this.loadData(), 2000);
            } finally {
                //auth0 takes some time
                setTimeout(() => this.$wait.end('saving'), 2001);
            }
        },
        async deleteUser(item) {
            try {
                this.$wait.start('saving');
                await Users.delete(item.id);
                setTimeout(() => this.loadData(), 2000);
            } finally {
                //auth0 takes some time
                setTimeout(() => this.$wait.end('saving'), 2001);
            }
        },
        editRoles(item) {
            this.selected = item;
            this.editingRoles = JSON.parse(JSON.stringify(item.roleSelection));
            this.showRoleDialog = true;
        },
        editDepartments(item) {
            this.selected = item;
            this.showDepartmentDialog = true;
        },
        async saveRoles(roles) {
            try {
                this.$wait.start('saving');
                await Users.setUserRoles(this.selected.id, roles);
                this.showRoleDialog = false;
                setTimeout(() => this.loadData(), 2000);
            } finally {
                //auth0 takes some time
                setTimeout(() => this.$wait.end('saving'), 2000);
            }
        },
        async saveDepartments(departments) {
            try {
                this.$wait.start('saving');
                await Users.setUserDepartments(this.selected.id, departments);
                this.showDepartmentDialog = false;
                setTimeout(()=> this.loadData(), 2000);
            } finally {
                //auth0 takes some time
                setTimeout(() => this.$wait.end('saving'), 2000);
            }
        }
    }
};
</script>
