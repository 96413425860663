<template>
    <div class="pa-5">
        <v-card v-if="data">
            <v-card-title>
                <h1 class="title">{{ 'Terms' | term }}</h1>
                <v-access-control>
                    <v-btn small color="primary" class="ml-3" v-if="dirty" :loading="$wait.any" @click="save()">
                        <v-icon left>mdi-check</v-icon>Save
                    </v-btn>
                    <v-btn small color="grey" class="ml-3" v-if="dirty" @click="loadData()">
                        <v-icon left>mdi-close</v-icon>Cancel
                    </v-btn>
                </v-access-control>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    clearable
                    class="admin-table-filter"
                ></v-text-field>
            </v-card-title>
            <v-data-table disable-pagination hide-default-footer :headers="headers" :items="data" :search="search">
                <template v-slot:item="{ item }">
                    <tr :class="{ dirty: item.dirty }">
                        <td>
                            {{item.name}}
                        </td>
                        <td>
                            {{item.pluralName}}
                        </td>
                        <td>
                            <v-text-field
                                v-model="item.value"
                                class="mt-3"
                                @change="item.dirty = true"
                                @keydown="item.dirty = true"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                v-model="item.pluralValue"
                                class="mt-3"
                                @change="item.dirty = true"
                                @keydown="item.dirty = true"
                            ></v-text-field>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import { EventBus, Events } from '@/lib/EventBus';
import Terms from '@/lib/data/Terms';
import TermStore from '@/lib/Terms';
import SystemTerms from '@/SystemTerms';

export default {
    data() {
        return {
            data: null,
            search: '',
            headers: [
                { text: 'Term', value: 'name' },
                { text: 'Plural', value: 'pluralName' },
                { text: 'Show As', value: 'value' },
                { text: 'Show As, Plural', value: 'pluralValue' }
            ]
        };
    },
    async created() {
        await this.loadData();
    },
    destroyed() {},
    computed: {
        dirty() {
            for (let x of this.data) {
                if (x.dirty) {
                    return true;
                }
            }
            return false;
        }
    },
    methods: {
        async loadData() {
            var d = await Terms.get();

            for (let x of d) {
                x.dirty = false;
            }

            this.addMissingSystemTerms(d);
            
            d.sort(function(a, b) {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            });

            this.data = d;
        },
        addMissingSystemTerms(data) {
            for(let systemTerm of SystemTerms) {
                var singular = systemTerm[0];
                var plural = systemTerm[1];

                var found = false;
                for(let term of data) {
                    if (term.name == singular) {
                        found = true;
                        if (!term.pluralName == plural) {
                            term.dirty = true;
                            term.pluralName = plural;
                        }
                        break;
                    }
                }
                if (!found) {
                    data.push({name: singular, pluralName: plural, value: '', pluralValue: '', dirty: false});
                }
            }
        },
        add() {
            this.data.push({ dirty: true });
        },
        async save() {
            try {
                this.$wait.start('saving');
                for (let x of this.data) {
                    if (!x.dirty) {
                        continue;
                    }
                    await Terms.save(x);
                }

                EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Terms')} Saved`);
                await TermStore.fetchTerms();
                await this.loadData();
                EventBus.$emit(Events.TermsUpdated);
            } finally {
                this.$wait.end('saving');
            }
        }
    }
};
</script>
