import { defineStore } from 'pinia';
import _ from 'lodash';
import dayjs from 'dayjs';
import {
    transformActualsToClient,
    transformWeekRowToEvaluatableRow
} from '@/lib/stores/Transforms';
import { useWindowedActuals } from '@/lib/stores/Production/WindowedActualsStore';
import { useBlastPacketsStore } from '@/lib/stores/Production/BlastPacketsStore';
import BlastPackets from '@/lib/data/BlastPackets';
import ActualPhysicals from '@/lib/data/ActualPhysicals';
import { AffectedAreaSpecification } from '@/lib/stores/HeadingsStore';
import { RowType } from '@/models/client/types/row-type';
import {
    ClientRowBlastPacketTargetDisplayInformation,
    ClientRowBlastPacketTargetInformation
} from '@/lib/stores/Production/ShiftWindowActualsStore';
import {
    BlastPacketRingTargetViewModel,
    BlastPacketTasksInWeekLocationViewModel,
    CycleType, LocationStopeInformationViewModel,
    ProductionFrontRulesViewModel,
    TaskTypeViewModel,
    WeekBoardRow
} from '@/models/api';
import { AddWarningToTask } from '@/lib/services/Task';
import WeekTask from '@/models/client/week-task';
import selectedMineAreaStore from '@/lib/stores/SelectedMineAreaStore';
import { validateProductionFrontRules } from '@/components/ShiftBoard/Board/Validation';
import { ProductionValidationTaskModel } from '@/models/client/production-validation-task-model';
import { ProductionValidationRow } from '@/models/client/production-validation-row';
import Shift from '@/lib/data/Shift';
import ProductionFronts from '@/lib/data/ProductionFronts';
import { validateBlastPacketRingSequencing } from '@/components/WeekBoard/Validation';
import { ClientTaskWarning } from '@/models/client/client-task-warning';

function convertServerBlastPacketViewModelToClient(t: BlastPacketRingTargetViewModel): ClientRowBlastPacketTargetInformation {
    return {
        defaultRate: t.ratePerHour ?? null,
        isCompletedOrReachedTarget: t.markedCompleted || (t.actualsPercentage ?? 0) >= 100,
        order: t.taskOrder,
        targetId: t.id,
        targetQuantity: t.target,
        taskBackgroundColour: t.taskBackgroundColour,
        taskForegroundColour: t.taskForegroundColour,
        taskName: t.taskName ?? 'Unknown',
        taskTypeId: t.taskTypeId ?? '',
        tolerance: t.tolerance
    }
}

export const useWeeklyProductionStore = defineStore('weeklyProductionStore', {
    state: ()=>{
        return {
            planId: '',
            departmentId: '',
            initialised: false,
        }
    },
    getters: {
        getBlastPacketTargetsDisplayInformation(): ClientRowBlastPacketTargetDisplayInformation[] {
            return _.flatMap(
                useBlastPacketsStore().blastPackets,
                l=>_.flatMap(
                    l.blastPackets,
                    bp=>([
                        {
                            targetId: bp.fireTarget!.id!,
                            blastPacketName: bp.name ?? '',
                            blastPacketId: bp.id,
                            ringName: null,
                            ringId: null
                        },
                        {
                            targetId: bp.bogTarget!.id!,
                            blastPacketName: bp.name ?? '',
                            blastPacketId: bp.id,
                            ringName: null,
                            ringId: null
                        },
                        ..._.flatMap(
                            bp.rings,
                            r=> _.flatMap(
                                r.targets,
                                t=>({
                                    targetId: t.id!,
                                    blastPacketName: bp.name ?? '',
                                    blastPacketId: bp.id,
                                    ringName: r.locationRingName ?? '',
                                    ringId: r.locationRingId
                                })
                            ))
                    ])
                )
            );
        },
    },
    actions: {
        async populateWindow(mineId: string, departmentId: string, planId: string) {
            const blastPacketLocations = useBlastPacketsStore().blastPackets;
            const ringsStateResponse = await BlastPackets.getStatesWindowByWeek(mineId, departmentId, planId);
            const actualsResponse = await ActualPhysicals.GetWindowByWeek(mineId, departmentId, planId);
            const completions = _.flatMap(
                blastPacketLocations,
                l=>_.flatMap(
                    l.blastPackets,
                    bp=>_.flatMap(
                        bp.rings,
                        r=> _.flatMap(r.targets.filter(t=>t.completionId != null), t=>({
                            id: t.completionId!,
                            targetId: t.id!,
                            completionReason: t.completionReason,
                            completedAt: dayjs(t.completedAt!).utc(),
                            locationId: l.id
                        }))
                    )
                ));

            const actuals = transformActualsToClient(actualsResponse.entries);

            const blastPacketRingsInformation = _.flatMap(
                blastPacketLocations,
                l=>_.flatMap(
                    l.blastPackets,
                    bp=>({
                        blastPacketName: bp.name ?? '',
                        blastPacketId: bp.id,
                        locationId: l.id,
                        bogTarget: convertServerBlastPacketViewModelToClient(bp.bogTarget!),
                        fireTarget: convertServerBlastPacketViewModelToClient(bp.fireTarget!),
                        rings: _.flatMap(
                            bp.rings,
                            r=> ({
                                ringId: r.locationRingId ?? '',
                                ringName: r.locationRingName ?? 'unknown',
                                locationId: l.id,
                                targets: r.targets.map(t=>({
                                    targetId: t.id ?? '',
                                    taskName: t.taskName ?? 'Unknown',
                                    taskBackgroundColour: t.taskBackgroundColour,
                                    taskForegroundColour: t.taskForegroundColour,
                                    targetQuantity: t.target,
                                    taskTypeId: t.taskTypeId ?? '',
                                    order: t.taskOrder,
                                    defaultRate: t.ratePerHour ?? null,
                                    isCompletedOrReachedTarget: t.markedCompleted || (t.actualsPercentage ??0) >= 100,
                                    tolerance: t.tolerance,
                                }))
                            })
                        )
                    })
                )
            );

            useWindowedActuals().setUpStore(dayjs(ringsStateResponse.clientCalculationTime).utc(), blastPacketRingsInformation, ringsStateResponse.locations, actuals, completions);

            this.departmentId=departmentId;
            this.planId=planId;
            this.initialised = true;
        },
        evaluateAndValidateRingStatesForHeadings(associatedRowTasks: WeekTask[], headings: ProductionValidationRow[], warningAccumulator: (task: WeekTask, area: AffectedAreaSpecification, warning: ClientTaskWarning)=>void) {
            const addWarningToCycle = (t: ProductionValidationTaskModel,area: AffectedAreaSpecification,warning: ClientTaskWarning)=>{
                const associatedTask = associatedRowTasks.find(at=>at.id === t.plannedCycleId);

                if(associatedTask != null)
                    warningAccumulator(associatedTask, area, warning);
            }

            this.reEvaluateRingStatesForHeadings(headings, addWarningToCycle);
        },
        reEvaluateRingStatesForHeadings(headings: ProductionValidationRow[], ringErrorAccumulator: (t: ProductionValidationTaskModel, affectedArea: AffectedAreaSpecification, warning: ClientTaskWarning)=>void) {
            useWindowedActuals().reEvaluateRingStatesForHeading(headings, ringErrorAccumulator);
        },
        validateProductionFrontRules(rows: ProductionValidationRow[], rulesets: ProductionFrontRulesViewModel[], currentMineTime: dayjs.Dayjs, shiftTimes: {shiftStartTime: dayjs.Dayjs, shiftEndTime: dayjs.Dayjs}[], warningAccumulator: (task: ProductionValidationTaskModel, affectedArea: AffectedAreaSpecification, warning: ClientTaskWarning)=>void){
            const ringStates = useWindowedActuals().locationBlastPacketRingStateInformation;

            validateProductionFrontRules(rows, this.getBlastPacketTargetsDisplayInformation, rulesets,ringStates,currentMineTime,shiftTimes,warningAccumulator);
        },
        async validateProductionRows(rows: { id: string, location: { id: string, name: string | null, cycleType: CycleType | null, stopeInfo: LocationStopeInformationViewModel | null } | null, tasks: WeekTask[] }[], taskTypes: TaskTypeViewModel[], shiftTimes: {shiftStartTime: dayjs.Dayjs, shiftEndTime: dayjs.Dayjs}[], warningAccumulator: (task: WeekTask, affectedArea: AffectedAreaSpecification, warning: ClientTaskWarning)=>void) {
            const currentMineTime = selectedMineAreaStore.getMineCurrentTime();
            const currentMineTimeComparableToTasks = currentMineTime.utc(true);

            // bow out if we're fully in the past as there's no need to evaluate rules
            if(currentMineTimeComparableToTasks.isSameOrAfter(shiftTimes[shiftTimes.length-1].shiftEndTime))
                return;

            const blastPacketTasksAwait = Shift.weekBlastPacketTasks(this.departmentId, this.planId);
            const rulesetsAwait = ProductionFronts.listRulesets(this.departmentId);

            const blastPacketTasks = await blastPacketTasksAwait;
            const rulesets = await rulesetsAwait;

            const evaluatableRows = blastPacketTasks.locations.map(l=>{
                const associatedRow = rows.find(m=>m.location?.id===l.locationId);

                if(associatedRow == null)
                    return null;

                const convertedRow = transformWeekRowToEvaluatableRow(associatedRow, l, taskTypes);

                validateBlastPacketRingSequencing(associatedRow, warningAccumulator);

                return {weekRow: associatedRow, convertedRow: convertedRow };
            }).filter(e=>e!=null).map(e=>e!);

            const rowsGroupedByStope = _.groupBy(evaluatableRows, r=>r.convertedRow.stopeInfo!.stopeId);

            _.keys(rowsGroupedByStope).map(k=>{
                this.evaluateAndValidateRingStatesForHeadings(_.flatMap(rowsGroupedByStope[k], r=>r.weekRow.tasks),
                    rowsGroupedByStope[k].map(r=>r.convertedRow), warningAccumulator);
            })

            this.validateProductionFrontRules(evaluatableRows.map(e=>e.convertedRow),rulesets.productionFronts, currentMineTimeComparableToTasks, shiftTimes, (task, affectedArea, warning)=>{
                const taskGroup = rows.find(m=>m.location?.id ===task.locationId)?.tasks.find(t=>t.id === task.plannedCycleId);

                if(taskGroup != null) {
                    warningAccumulator(taskGroup,affectedArea, warning);
                }
            });
        }
    }
});