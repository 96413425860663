import { defineStore } from 'pinia';
import MineAreas from '@/lib/data/MineAreas';
import { EventBus, Events } from '@/lib/EventBus';
import { TaskCategory } from '@/models/api/Data/task-category';
import TaskCategories from '@/lib/data/TaskCategories';
import { useSystemStore } from './SystemStore';
import Stockpiles from '../data/Stockpiles';
import MaterialDestinations from '../data/MaterialDestinations';
import MaterialTypes from '../data/MaterialTypes';
import { AvailableShifts } from '@/models/api/Data/available-shifts';
import { MaterialType } from '@/models/api/Data/material-type';
import dayjs from 'dayjs';
import { TemperatureScales } from '@/models/api';

type ShiftTimes = {
    name: string,
    times: string[]
}

export const useMineAreaStore = defineStore('mineArea', {
    state: () => {
        return {
            isInitialized: false,
            _firstShiftStartsAt: null as string | null,
            _firstDayOfWeek: null as number | null,
            _numberOfShiftsPerDay: null as number | null,
            _locale: null as string | null,
            _temperatureScale: null as TemperatureScales | null,
            taskCategories: [] as TaskCategory[],
            stockPiles: [] as any[],
            materialDestinations: [] as any[],
            materialTypes: [] as MaterialType[],
            _availableShifts: [] as AvailableShifts[],
            shiftTimes: [] as ShiftTimes[],
            _isSandpit: null as boolean | null,
            mineAreaId: null as string | null,
        };
    },
    getters: {
        firstShiftStartsAt(): string {
            if (this._firstShiftStartsAt === null) throw new Error('First shift starts at is not set');
            return this._firstShiftStartsAt;
        },
        numberOfShiftsPerDay(): number {
            if (this._numberOfShiftsPerDay === null) throw new Error('Number of shifts per day not set');
            return this._numberOfShiftsPerDay;
        },
        firstDayOfWeek(): number {
            if (this._firstDayOfWeek === null) throw new Error('First day of week has not been set');
            return this._firstDayOfWeek;
        },
        temperatureScale(): TemperatureScales {
            if(this._temperatureScale === null) throw new Error('Temperature scale has not been set');
            return this._temperatureScale;
        },
        availableShifts(): AvailableShifts[] {
            return this._availableShifts;
        },
        locale(): string {
            // default locale en
            if (!this._locale) return 'en';
            return this._locale;
        },
        isSandpit(): boolean {
            // default to not sandpit
            if(!this._isSandpit) return false;
            return this._isSandpit;
        }
    },
    actions: {
        async initializeMineAreaStore(id: string) {
            if (useSystemStore().isOnline || this.isInitialized === false) {
                await Promise.all([
                    this.populateMineArea(id),
                    this.populateTaskCategories(id),
                    this.populateStockpiles(id),
                    this.populateMaterialTypes(id),
                    this.populateMaterialDestinations(id),
                ]);
                this.isInitialized = true;
            }
        },
        async populateTaskCategories(mineAreaId: string) {
            if (this.taskCategories.length === 0) this.taskCategories = await TaskCategories.getForMineArea(mineAreaId);
        },
        async populateMineArea(id: string) {
            this.mineAreaId = id;
            const mineArea = await MineAreas.getMineArea(id);
            this._firstShiftStartsAt = mineArea.firstShiftStartsAt;
            this._numberOfShiftsPerDay = mineArea.numberOfShiftsPerDay;
            this._availableShifts = mineArea.availableShifts;
            this._firstDayOfWeek = mineArea.firstDayOfWeek;
            this._locale = mineArea.locale;
            this._temperatureScale = mineArea.temperatureScale;
            this._isSandpit = mineArea.isSandpit;

            const startAtHour = parseInt(this._firstShiftStartsAt?.split(":")[0] ?? '');
            const hoursPerShift = 24/(this._numberOfShiftsPerDay ?? 1);

            this.shiftTimes = this._availableShifts.map((x: AvailableShifts) => {
                const times = [];
                const start = startAtHour + ((x.number-1)*hoursPerShift)
                for (let i = 0; i < hoursPerShift; i++){
                    let h = start + i;
                    if (h >= 24) h = h-24;
                    times.push(`${String(h)}:00`)
                }             
                return {name: x.name, times: times} as ShiftTimes  
            })
        },
        async repopulateStockpiles() {
            if(this.mineAreaId !=null )
                await this.populateStockpiles(this.mineAreaId);
        },
        async populateStockpiles(mineAreaId: string) {
            this.stockPiles = await Stockpiles.getForMineArea(mineAreaId);
        },
        async populateMaterialDestinations(mineAreaId: string) {
            if (this.materialDestinations.length === 0) this.materialDestinations = await MaterialDestinations.getForMineArea(mineAreaId);
        },
        async populateMaterialTypes(mineAreaId: string) {
            if (this.materialTypes.length === 0) this.materialTypes = await MaterialTypes.getForMineArea(mineAreaId);
        },
        formatDateShift(value: Date) {

            const date = new Date(value.toISOString())
            const time = `${date.getUTCHours()}:00`;
            const shift = this.shiftTimes.find(st => st.times.includes(time));

            const day = String(date.getUTCDate()).padStart(2, '0');
            const month = String(date.getUTCMonth()+1).padStart(2, '0');
            const year = String(date.getUTCFullYear()).substring(2);

            return `${day}/${month}/${year} ${shift?.name ?? '-'}`
        },
        formatDate(value: Date) {
            const date = new Date(value.toISOString())
            const day = String(date.getUTCDate()).padStart(2, '0');
            const month = String(date.getUTCMonth()+1).padStart(2, '0');
            const year = String(date.getUTCFullYear()).substring(2);
            return `${day}/${month}/${year}`
        },
        getFirstShiftOnDay(value: dayjs.Dayjs){
            const startAtHour = parseInt(this._firstShiftStartsAt?.split(":")[0] ?? '');
            const startAtMinute = parseInt(this._firstShiftStartsAt?.split(":")[1] ?? '');

            return value.add(startAtHour, 'hour').add(startAtMinute, 'minute');
        },
        getMaterialType(materialTypeId: string): MaterialType | null {
            const materialType = this.materialTypes.find(x => x.id === materialTypeId);
            if (materialType){
                return materialType;
            }
            return null;
        },
        getMaterialDestination(materialDestinationId: string): any | null {
            const materialDestination = this.materialDestinations.find(x => x.id === materialDestinationId);
            if (materialDestination){
                return materialDestination;
            }
            return null;
        },
        getStockpile(stockpileId: string): any | null {
            const stockpile = this.stockPiles.find(x => x.id === stockpileId);
            if (stockpile){
                return stockpile;
            }
            return null;
        },
    },
});

export class MineAreaStore {
    public mineAreas: any[] | null = null;
    public async getMineAreas() {
        if (this.mineAreas == null) {
            await this.updateStoredMineAreas();
        }

        return this.mineAreas;
    }
    public async updateStoredMineAreas() {
        this.mineAreas = await MineAreas.get(['Departments']);
        EventBus.$emit(Events.MineAreasUpdated, this.mineAreas);
    }
}

export default new MineAreaStore();
