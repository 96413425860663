import dayjs from 'dayjs';

export class Dates {
    public ToUtcDate(dateStr: string) {
        const date = dateStr && dateStr.length >= 19 ? new Date(dateStr.substring(0, 19) + 'Z') : new Date(dateStr);
        return new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
        );
    }
    public addMinutes(value: Date, minutes: number): Date {
        const date = dayjs(value);
        return date.add(minutes, 'minutes').toDate();
    }
}

export default new Dates();
