import { GenericDataApi } from '@/lib/data/GenericDataApi';
import {
    CreateActualPhysicalsSourceTypeCommand,
    UpdateActualPhysicalsSourceTypeCommand,
    ReprioritiseActualPhysicalsSourceTypesCommand,
    ActualPhysicalsSourceTypeViewModel,
    GetActualPhysicalsSourceTypesQueryResponse
} from '@/models/api';
import { AxiosError } from 'axios';
import AppHttp from '../AppHttp';
import Errors from '../Errors';

export class ActualPhysicalsSourceTypes extends GenericDataApi<any> {
    constructor() {
        super('ActualPhysicalsSourceTypes');
    }

    public async create(command: CreateActualPhysicalsSourceTypeCommand) {
        await AppHttp.post(`${this.resource}/Create`, command);
    }

    public async update(command: UpdateActualPhysicalsSourceTypeCommand) {
        await AppHttp.post(`${this.resource}/Update`, command);
    }

    public async delete(id: string) {
        await AppHttp.delete(`${this.resource}/${id}`);
    }

    public async reprioritise(command: ReprioritiseActualPhysicalsSourceTypesCommand)
    {
        await AppHttp.post(`${this.resource}/Reprioritise`, command);
    }

    public async export() {
        const response = await AppHttp.get(`${this.resource}/Export`);
        const data = response ? response.data : null;
        return data;
    }

    public async import(file: any) {
        const response = await AppHttp.postWithFiles(`${this.resource}/Import`, {}, [file]);
        const data = response ? response.data : null;
        return data;
    }

    public async getById(sourceTypeId: string) : Promise<ActualPhysicalsSourceTypeViewModel>
    {
        const response = await AppHttp.get(`${this.resource}/${sourceTypeId}`);

        const data = response ? response.data : null;
        if(data == null) throw 'No response data';
        return data;
    }

    public async list(): Promise<GetActualPhysicalsSourceTypesQueryResponse>
    {
        const response = await AppHttp.get(`${this.resource}`);
        const data = response ? response.data : null;
        if(data == null) throw 'No response data';
        return data;
    }
}

export default new ActualPhysicalsSourceTypes();
