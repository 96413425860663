<template>
    <standard-dialog v-model='dialog' :max-width="550" persistent :isLoading="$wait.is('get data')">
        <template v-slot:title>
            Shift owner: {{ actionType | assignShiftOwnerActionType }}
        </template>
        <template v-slot:content>
            <template v-if="isTakeOwnershipAction">
                <v-checkbox
                    v-model="confirmed"
                    label="I confirm that I have permission to take ownership of the shift."
                ></v-checkbox>
            </template>
            <template v-else>
                <v-alert dense outlined border="left" type="warning" v-if="showHandoverWarning">
                    <p>Handover meeting not complete</p>
                    <div class="d-flex flex-row-reverse">
                        <v-btn small color="primary" @click="openHandoverMeeting()">Open handover meeting</v-btn>
                    </div>
                </v-alert>
                <v-autocomplete
                    v-model="selectedUserId"
                    :items="usersList"
                    label="Select user"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    clearable
                    @change="selectedUserChanged()"
                >
                    <template v-slot:item="{ item }">
                        {{ item.name }}
                        <v-spacer></v-spacer>
                        <v-chip small v-if="item.isShiftPlanner">Shift Planner</v-chip>
                    </template>
                    <template v-slot:selection="{ item }">
                        {{ item.name }}
                        <v-chip small v-if="item.isShiftPlanner">Shift Planner</v-chip>
                    </template>
                </v-autocomplete>
                <v-checkbox
                    v-if="showHandoverWarningConfimration"
                    v-model="handoverMeetingConfirmed"
                    label="I confirm that handover meeting isn't complete."
                ></v-checkbox>
                <v-checkbox
                    v-if="showShiftPlannerConfirmation"
                    v-model="confirmed"
                    label="I confirm that the selected user doesn't have Shift Planner role."
                ></v-checkbox>
            </template>
        </template>
        <template v-slot:actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancel()">Cancel</v-btn>
            <v-access-control>
                <template #offline>
                    <offline-btn></offline-btn>
                </template>
                <v-btn color="primary" text @click="assign()" :disabled="!canSubmit">
                    {{ actionType | assignShiftOwnerActionType }}
                </v-btn>
            </v-access-control>
        </template>
    </standard-dialog>
</template>

<script>
import Users from '@/lib/data/Users';
import Departments from '@/lib/data/Departments';
import Meetings from '@/lib/data/Meetings';
import UserStore from '@/lib/stores/UserStore';
import { Roles } from '@/lib/Security';
import { AssignShiftOwnerActionType } from '@/models/api';
import { EventBus, Events } from '@/lib/EventBus';
import { useDepartmentStore } from '@/lib/stores/DepartmentStore';
import { mapActions } from 'pinia';
import { CanAccessDepartment } from '@/lib/services/User';

export default {
    props: ['department'],
    data() {
        return {
            users: null,
            selectedUserId: null,
            loggedInUser: null,
            confirmed: true,
            handoverMeetingConfirmed: false,
            actionType: 'Assign',
            dialog: false,
            shiftInfo: null,
            handoverMeeting: null,
        };
    },
    async created() {
        this.loggedInUser = await UserStore.getCurrentUser();
    },
    methods: {
        ...mapActions(useDepartmentStore, ['setShiftOwner']),
        async loadData() {
            this.$wait.start('get data');
            var users = (await Users.get()).filter((u) => u.id !== this.loggedInUser.sub);
            var shiftPlanners = users.filter((u) => u.roles && u.roles.includes(Roles.ShiftPlanner));
            var nonShiftPlanners = users.filter((u) => !u.roles || !u.roles.includes(Roles.ShiftPlanner));
            this.users = [...shiftPlanners, ...nonShiftPlanners].map((u) => {
                u.isShiftPlanner = u.roles && u.roles.includes(Roles.ShiftPlanner);
                return u;
            });

            if (this.shiftInfo) this.handoverMeeting = await Meetings.one(this.shiftInfo.handoverMeetingId);
            // initializing to true (no confirmation required) if handover meeting warning not required
            this.handoverMeetingConfirmed = !this.showHandoverWarning;
            this.$wait.end('get data');
        },
        selectedUserChanged() {
            this.confirmed = this.isSelectedUserShiftPlanner;
        },
        async show(actionType, shiftInfo) {
            this.dialog = true;
            this.actionType = actionType;
            this.selectedUserId = null;
            this.shiftInfo = shiftInfo;
            if (actionType === AssignShiftOwnerActionType.TakeOwnership) {
                this.confirmed = false;
                this.selectedUserId = this.loggedInUser.sub;
                this.handoverMeetingConfirmed = true;
            } else {
                await this.loadData();
            }
        },
        cancel() {
            this.dialog = false;
            this.selectedUserId = null;
        },
        async assign() {
            try {
                this.$wait.start('saving');
                await this.setShiftOwner(this.actionType, this.selectedUserId);
            } finally {
                this.$wait.end('saving');
                this.dialog = false;
                this.$emit('complete');
            }
        },
        openHandoverMeeting() {
            this.cancel();
            EventBus.$emit(Events.ShowHandoverMeetingSideDrawer);
        },
    },
    computed: {
        selectedUser() {
            if (!this.users) return null;
            return this.users.find((u) => u.id === this.selectedUserId);
        },
        isSelectedUserShiftPlanner() {
            return this.selectedUser && this.selectedUser.roles.includes(Roles.ShiftPlanner);
        },
        isTakeOwnershipAction() {
            return this.actionType === AssignShiftOwnerActionType.TakeOwnership;
        },
        isHandoverAction() {
            return this.actionType === AssignShiftOwnerActionType.Handover;
        },
        showHandoverWarning() {
            return (
                this.shiftInfo && this.handoverMeeting && !this.handoverMeeting.endedMeetingAt && this.isHandoverAction
            );
        },
        usersList() {
            if (!this.department || !this.users) return [];
            return this.users
                .filter((user) => user.email !== this.department.shiftOwnerId)
                .filter((user) => CanAccessDepartment(user, this.department.id))
                .filter((user) => user.roles.includes(Roles.ReadOnly) === false);
        },
        showShiftPlannerConfirmation() {
            return this.selectedUser && !this.isSelectedUserShiftPlanner;
        },
        showHandoverWarningConfimration() {
            return this.selectedUser && this.showHandoverWarning;
        },
        canSubmit() {
            return (
                ((this.isTakeOwnershipAction && this.confirmed) ||
                    (this.selectedUser && (this.confirmed || this.isSelectedUserShiftPlanner))) &&
                this.handoverMeetingConfirmed
            );
        },
    },
};
</script>
