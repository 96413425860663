<template>
    <v-app :class="classes">
        <v-main>
            <router-view />
            <toast></toast>
            <terms-and-conditions />
            <tenant-change />
            <version-check />
            <change-conflict />
        </v-main>
        <v-access-control>
            <template #offline>
                <v-bottom-navigation app fixed bottom dark shift>
                    <offline-btn></offline-btn>
                </v-bottom-navigation>
            </template>
        </v-access-control>
    </v-app>
</template>

<script>
import Toast from '@/components/Shared/Toast.vue';
import TermsAndConditions from '@/components/Shared/TermsAndConditions.vue';
import TenantChange from '@/components/Shared/TenantChange.vue';
import VersionCheck from '@/components/Shared/VersionCheck.vue';
import AuthService from '@/lib/Auth';
import Terms from '@/lib/Terms';
import ChangeConflict from '@/components/Shared/ChangeConflict.vue';
import { Events, EventBus } from '@/lib/EventBus';
import { useSystemStore } from '@/lib/stores/SystemStore';

export default {
    name: 'App',
    components: { Toast, TermsAndConditions, VersionCheck, ChangeConflict, TenantChange },

    data: () => ({
        screenSize: 'm'
    }),

    async created() {
        this.screenSize = localStorage.getItem('screenSize') || 'm';

        this.$wait.start('load app');

        if (AuthService.isAuthenticated()) {
            await Terms.guaranteeTermsFetched();
        }

        this.$wait.end('load app');

        EventBus.$on(Events.AuthChange, async () => {
            if (AuthService.isAuthenticated()) {
                this.$wait.start('load app');
                await Terms.fetchTerms();
                this.$wait.end('load app');
            }
        });

        EventBus.$on(Events.ScreenSizeChanged, (size) => {
            if (!/(s|m|l|x)/.test(size.toString())) {
                return;
            }
            this.screenSize = size;
            localStorage.setItem('screenSize', size);
        });

        window.addEventListener('resize', () => {
            EventBus.$emit(Events.ScreenResized, {
                width: document.documentElement.clientWidth,
                height: document.documentElement.clientHeight,
            });
        });

        window.addEventListener('beforeunload', this.confirmLeaving);
    },
    computed: {
        classes() {
            return {
                'screen-s': this.screenSize == 's',
                'screen-m': this.screenSize == 'm',
                'screen-l': this.screenSize == 'l',
                'screen-x': this.screenSize == 'x',
            };
        },
    },
    methods: {
        confirmLeaving(event) {
            if (useSystemStore().isOnline === false) {
                event.preventDefault();
                event.returnValue = '';
                return '';
            }
        },
    },
};
</script>
<style lang="scss">
@import '@/variables.scss';
$explode-margin: 6px;

// define standard colour css variables
:root {
    --ordo-sunflower-yellow: rgb(254, 155, 22);
    --ordo-warning-yellow: rgb(246, 189, 22);
    --ordo-bright-blue: rgb(11, 88, 132);
    --ordo-jade-green: rgb(33, 115, 78);
    --ordo-signal-red: rgb(175, 49, 42);
    --ordo-danger-red: #d32027;
    --ordo-amber-base: #ffc107;
    --ordo-green-base: #4CAF50;
    --ordo-teal-base: #009688;

    --ordo-signage-green: var(--ordo-jade-green);
    --ordo-signage-yellow: var(--ordo-warning-yellow);
    --ordo-signage-red: var(--ordo-signal-red);
    --ordo-signage-blue: var(--ordo-bright-blue);
}

.v-application {
    .ordo-signage-yellow {
        background-color: var(--ordo-signage-yellow) !important;
        border-color: var(--ordo-signage-yellow) !important;
    }

    .ordo-signage-yellow--text {
        color: var(--ordo-signage-yellow) !important;
    }

    .ordo-signage-green {
        background-color: var(--ordo-signage-green) !important;
        border-color: var(--ordo-signage-green) !important;
    }

    .ordo-signage-green--text {
        color: var(--ordo-signage-green) !important
    }

    .ordo-signage-red {
        background-color: var(--ordo-signage-red) !important;
        border-color: var(--ordo-signage-red) !important;
    }

    .ordo-signage-red--text {
        color: var(--ordo-signage-red) !important;
    }

    .ordo-signage-blue {
        background-color: var(--ordo-signage-blue) !important;
        border-color: var(--ordo-signage-blue) !important;
    }

    .ordo-signage-blue--text {
        color: var(--ordo-signage-blue) !important;
    }

    .flex-basis-0 {
        flex-basis: 0 !important;
    }

    .flex-basis-auto {
        flex-basis: auto !important;
    }

    .card-dragover--primary {
        border-color: var(--v-primary-base) !important;
        border-width: 2px !important;
    }
}

@mixin generateTask($name) {
    .screen#{$name} {
        .custom-handle-class {
            height: calc(#{map-get(map-get($resizeHandle, $name), 'height')});
            width: calc(#{map-get(map-get($resizeHandle, $name), 'width')});
            top: calc(#{map-get(map-get($resizeHandle, $name), 'top')});
        }
        .custom-handle-class-mr {
            right: calc(#{map-get(map-get($resizeHandle, $name), 'right')});;
        }

        .task-container {
            height: #{map-get($sizes, $name)} !important;
            padding: 0 calc(#{map-get($taskGutters, $name)} / 2);
        }
        .task {
            height: #{map-get($sizes, $name)};
            line-height: #{map-get($sizes, $name)};
            font-size: #{map-get($fontSizes, $name)};
            letter-spacing: -0.5px;
            border-radius: #{map-get($corners, $name)};

            &.explode {
                margin-top: calc(0px - #{$explode-margin});
                line-height: calc(#{map-get($sizes, $name)} + #{$explode-margin} + #{$explode-margin});
                height: calc(#{map-get($sizes, $name)} + #{$explode-margin} + #{$explode-margin});
            }

            &.delay {
                &:after {
                    font-size: #{map-get($sizes, $name)};
                }
            }
            .v-icon {
                font-size: #{map-get($taskIcons, $name)};
            }
        }

        .mini {
            height: #{map-get($preview-sizes, $name)} !important;
            opacity: 0.5;
            top: 0;
            .task {
                height: #{map-get($preview-sizes, $name)} !important;
                line-height: #{map-get($preview-sizes, $name)} !important;
                font-size: #{map-get($preview-fontSizes, $name)} !important;
                top: 0;
                border-radius: 4px;

                &.delay:after {
                    font-size: #{map-get($preview-fontSizes, $name)} !important;
                }
                .v-icon {
                    font-size: #{map-get($preview-taskIcons, $name)} !important;
                }
            }
        }

        .taskHeight {
            height: #{map-get($sizes, $name)};
            line-height: #{map-get($sizes, $name)};
            font-size: #{map-get($fontSizes, $name)};
            box-sizing: border-box;
            position: relative;

            &.dropping {
                box-shadow: inset 0px 0px 8px 5px #3339;
            }

            .inset {
                border-radius: 6px;
                background-color: white;
                color: black;
                position: absolute;
                right: 2px;
                top: 5%;
                height: calc(#{map-get($sizes, $name)} - 10%);
                line-height: calc(#{map-get($sizes, $name)} - 10%);
                font-size: calc(#{map-get($fontSizes, $name)} - 4px);
                text-transform: uppercase;
                font-weight: normal;
                padding: 0px 1px;

                @if($name == '-x') {
                    font-size: calc(#{map-get($fontSizes, $name)} - 10px);
                }

                &.empty {
                    border: 1px dashed #666;
                }

                &.inset--large-card {
                    top: 0;
                    right: 10px;
                    height: 100%;
                    line-height: calc(#{map-get($sizes, $name)} - 4%);
                    font-size: calc(#{map-get($fontSizes, $name)});
                    padding: 0px 2px;
                }
            }
        }
        .task.explode .taskHeight {
            line-height: calc(#{map-get($sizes, $name)} + #{$explode-margin} + #{$explode-margin});
            height: calc(#{map-get($sizes, $name)} + #{$explode-margin} + #{$explode-margin});

            .inset {
                top: calc(#{$explode-margin} + 5%);

                &.inset--large-card {
                    top: 0;
                    line-height: calc(#{map-get($sizes, $name)} + #{$explode-margin} + #{$explode-margin});
                }
            }
        }

        .preview-row {
            height: #{map-get($preview-sizes, $name)} !important;
        }
    }
}

.v-main {
    transition: none !important;
}

.printonly {
    display: none;
}

.move-off {
    position: relative;
    left: -10000px;
}

.wobble-in-enter-active {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
}

.custom-icon {
    fill: currentColor;
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}

@keyframes wiggle {
    0% {
        transform: rotate(-3deg);
    }
    4% {
        transform: rotate(25deg);
    }
    8% {
        transform: rotate(-15deg);
    }
    12% {
        transform: rotate(5deg);
    }
    18% {
        transform: rotate(-1deg);
    }
    20% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

@media print {
    .noprint,
    .noprint * {
        display: none !important;
    }
    .printfull {
        display: block !important;
    }
    .printonly {
        display: block !important;
    }
    .full-height {
        height: auto !important;
        overflow-y: visible !important;
    }
    .break-after {
        page-break-after: always;
    }
    .move-off {
        left: 0px;
    }
}

.relative {
    position: relative;
}

.no-wrap {
    white-space: nowrap;
}

.clickable {
    cursor: pointer;
}

//standard center alignment for v-dialog titles
.dialog-title {
    justify-content: center;
}

//pushes top of content down under the appbar
.page-container {
    margin-top: 48px;
    height: calc(100vh - 48px);
}

.full-height {
    height: calc(100vh - 48px);
    overflow-y: auto;
}

//override dark theme background
.theme--dark.v-app-bar.v-toolbar.v-sheet {
    background-color: $color-dark-bg !important;
}

//used for admin tables with inline edits or reordering, to designate which rows need saving
.dirty {
    background-color: var(--v-accent-lighten5) !important;
}

//makes the search or select box on admin tables fit nicer with the card header
.admin-table-filter {
    margin-top: 0px !important;
    padding-top: 0px !important;
    max-width: 500px !important;
}

// so white swatches in color picker have a border
.v-color-picker__swatch .v-color-picker__color > div {
    border: 1px solid #e2e2e2;
}

.v-icon.rotate-left {
    transform: rotate(-90deg);
}
.v-icon.rotate-right {
    transform: rotate(90deg);
}

.with-chip-indicator {
    .chip-indicator {
        .v-chip {
            cursor: pointer;
            width: 14px;
            height: 14px;
            min-width: 14px;
            margin: 0;
            padding: 2px 0px 0px 0px;
            text-align: center;
            font-size: 10px;
            line-height: 12px;
            display: inline-block;
        }
    }
}
.v-divider.fat-separator {
    margin: 1px 0px;
    border-width: 3px 0px 0px 0px;
    border-color: rgba(0, 0, 0, 0.6) !important;
}
.resourcePill {
    font-size: 10px;
    display: block;
    width: 100%;
    margin-bottom: 4px;
    .two-line {
        line-height: 1.1em;
        margin: 0px;
    }
}
.custom-validation-message {
    margin: 0;
    padding: 0;
    .v-input__slot {
        display: none;
    }
}
.drag {
    transform: translate3d(0, 0, 0);
}
.v-chip.v-size--default.resizable {
    height: auto;
}

.v-alert.cannot-import {
    font-size: 0.8em;
    font-weight: normal;
    padding: 2px 5px 2px 5px;
    height: 35px;
    margin: 0 0 0 5px;
}

.v-alert.cannot-import .v-icon {
    margin-right: 0px;
}

.screen-s {
    .header-row--container {
        padding-top: calc(#{$task-height-s} + #{$shift-margin-y} + #{$shift-margin-y});
    }

    @for $i from 1 through 3 {
        .header-row--container-#{$i} {
            padding-top: calc(#{$i} * #{$task-height-s} + #{$shift-margin-y} + #{$shift-margin-y});
        }
    }
}

.screen-m {
    .header-row--container {
        padding-top: calc(#{$task-height-m} + #{$shift-margin-y} + #{$shift-margin-y});
    }

    @for $i from 1 through 3 {
        .header-row--container-#{$i} {
            padding-top: calc(#{$i} * #{$task-height-m} + #{$shift-margin-y} + #{$shift-margin-y});
        }
    }
}

.screen-l {
    .header-row--container {
        padding-top: calc(#{$task-height-l} + #{$shift-margin-y} + #{$shift-margin-y});
    }

    @for $i from 1 through 3 {
        .header-row--container-#{$i} {
            padding-top: calc(#{$i} * #{$task-height-l} + #{$shift-margin-y} + #{$shift-margin-y});
        }
    }
}

.screen-x {
    .header-row--container {
        padding-top: calc(#{$task-height-x} + #{$shift-margin-y} + #{$shift-margin-y});
    }

    @for $i from 1 through 3 {
        .header-row--container-#{$i} {
            padding-top: calc(#{$i} * #{$task-height-x} + #{$shift-margin-y} + #{$shift-margin-y});
        }
    }
}

.vdr {
    border: none !important;
}

.custom-handle-class {
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    margin-top: -9px;
    z-index: 8;
    width: 12px;
    height: 18px;
    border-color: inherit;
    color: inherit;
    border-radius: 3px;
    background-color: white;
    box-shadow: 0px 0 5px #000;
    cursor: ew-resize;
}
.custom-handle-class-ml {
    left: -6px;
}
.custom-handle-class-mr {
    right: -6px;
}

.task-container {
    user-select: none;
    position: relative;
    cursor: pointer;

    &.disabled-task {
        cursor: default;
    }

    &.custom-resizing-class {
        cursor: ew-resize;
    }

    &.custom-dragging-class {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }

    &.custom-draggable-class {
        &:not(.custom-dragging-class) {
            .task {
                cursor: grab;
                cursor: -moz-grab;
                cursor: -webkit-grab;
            }
        }
    }
    .v-speed-dial__list {
        padding: 0;
    }

    .under .v-speed-dial__list {
        padding-top: 0px;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .over {
        min-width: 200px;
    }
    .over .v-speed-dial__list {
        padding-top: 0px;
        bottom: 15px;
        flex-direction: row;
    }
    * {
        user-select: none;
    }
    .explode {
        z-index: 7;
    }
}

.task-visible-hover-only {
    opacity: 0;
}

.task-visible-hover-only:hover {
    opacity: revert;
}

.task {
    border-radius: 6px;
    display: block;
    box-sizing: border-box;
    text-align: center;
    word-wrap: none;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 0;

    &.task-visible-hover-only {
        opacity: 0;
    }

    &.task-visible-hover-only:hover {
        opacity: revert;
    }

    .equiplist {
        top: 500px;
        position: absolute;
    }
    &.explode {
        z-index: 7;
    }
    &.locked-start {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-left: 5px solid #333 !important;
    }
    &.locked-end {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: 5px solid #333 !important;
    }
    &.delay {
        background-color: transparent !important;
        border-width: 0 4px 0 4px;
        border-style: solid;
        border-radius: 0px;

        &:before {
            content: '';
            border-style: dotted;
            border-width: 2px 0 0 0;
            border-color: inherit;
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            margin-top: -1px;
            opacity: 0.35;
        }
        &:after {
            color: inherit;
            content: '\F0150';
            font-family: 'Material Design Icons';
            position: relative;
            left: -2px;
        }
    }

    &.explode {
        margin-top: calc(0px - #{$explode-margin});
        line-height: calc(#{$task-height-s} + #{$explode-margin} + #{$explode-margin});
        height: calc(#{$task-height-s} + #{$explode-margin} + #{$explode-margin});
        font-weight: 500;
    }

    &.raised-light {
        box-shadow: 0 0 3px #000a;
    }
    &.raised-heavy {
        box-shadow: 0 0 7px #000c;
    }
}

@include generateTask('-s');
@include generateTask('-m');
@include generateTask('-l');
@include generateTask('-x');

.task-error-text {
    white-space: pre-wrap;
}

.over {
    z-index: 8 !important;
}

.under {
    margin-top: 15px;
    z-index: 8 !important;
}

.cell-alert {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 4px;
    padding: 0 2px 0 6px;

    .cell-alert--container {
        border-top-right-radius: inherit;
        border-top-left-radius: inherit
    }

    .cell-alert--border {
        position:absolute;
        left:0;
        top:0;
        height: 100%;
        border-style:solid;
        border-width:medium;
        opacity:0.23;
        border-bottom-left-radius: inherit;
        border-top-left-radius: inherit
    }
}

</style>
