<template>
    <v-container fluid class="relative ma-0 pa-0 fill-height">
        <content-loading
            :value="$wait.is('get data') || $wait.is('saving')"
            loaderName="Week's Equipment"
        ></content-loading>
        <v-row class="fill-height ma-0" v-if="model && model.exists && !$wait.is('get data')">
            <v-col :cols="12" class="pa-5">
                <v-container fluid class="ma-0 pa-0">
                    <v-row class="ma-0 pa-0">
                        <v-col>
                            <div class="d-flex">
                                <h3 class="title">Equipment Availability</h3>
                                <v-spacer></v-spacer>
                                <v-text-field
                                    outlined
                                    v-model="equipmentFilter"
                                    label="Filter"
                                    clearable
                                    class="mb-2"
                                    style="max-width: 200px;"
                                    hide-details
                                    dense
                                ></v-text-field>
                            </div>
                        </v-col>
                        <v-col :cols="1" v-if="hasPermission">
                            <div class="d-flex">
                                <v-fab-transition>
                                    <v-btn
                                        color="primary"
                                        fixed
                                        large
                                        right
                                        class="floating-button"
                                        @click="$refs.importEquipmentsAvailabilityPlan.show()"
                                    >
                                        <v-icon left>mdi-upload</v-icon>Import
                                    </v-btn>
                                </v-fab-transition>
                                <import-equipments-availability-plan
                                    ref="importEquipmentsAvailabilityPlan"
                                    :mineId="$route.params.mine"
                                    :weekDate="model.weekStartDateWithTime"
                                    @complete="loadData()"
                                ></import-equipments-availability-plan>
                            </div>
                        </v-col>
                    </v-row>

                    <template v-for="group in groupedEquipment">
                        <v-row class="ma-0 pa-0" :key="group.name">
                            <v-col :cols="2">
                                <h4 class="heading-4">{{ group.name }}</h4>
                            </v-col>
                            <v-col :cols="2">
                                <h4 class="heading-4">Weekly Service</h4>
                            </v-col>
                            <v-col :cols="2">
                                <h4 class="heading-4">Minor Service</h4>
                            </v-col>
                            <v-col :cols="2">
                                <h4 class="heading-4">Major Service</h4>
                            </v-col>
                            <v-col :cols="2">
                                <h4 class="heading-4">Unserviceable</h4>
                            </v-col>
                            <v-col :cols="2">
                                <h4 class="heading-4">Idle/Unavailable This Week</h4>
                            </v-col>
                        </v-row>

                        <v-row
                            class="ma-0 pa-0 body-1"
                            v-for="(plannedEquipment, index) in group.data"
                            :key="plannedEquipment.equipment.id"
                            :class="{ striped: index % 2 == 1, idle: plannedEquipment.idleOrUnavailable }"
                        >
                            <v-col :cols="1" class="pt-5">
                                {{ plannedEquipment.equipment.name }}
                                <br />
                                <span class="body-2">{{ plannedEquipment.equipment.equipmentRole.name }}</span>
                            </v-col>
                            <v-col :cols="1">
                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn icon class="mt-3" @click="openEquipment(plannedEquipment)">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col :cols="2">
                                <shift-assigner
                                    :mine="mine"
                                    :weekDate="model.weekStartDate"
                                    :editable="false"
                                    :data="availabilityFor(plannedEquipment, 0)"
                                    icon="mdi-close"
                                    :color="colorFor(0)"
                                ></shift-assigner>
                            </v-col>
                             <v-col :cols="2">
                                <shift-assigner
                                    :mine="mine"
                                    :weekDate="model.weekStartDate"
                                    :editable="false"
                                    :data="availabilityFor(plannedEquipment, 1)"
                                    icon="mdi-close"
                                    :color="colorFor(1)"
                                ></shift-assigner>
                            </v-col>
                            <v-col :cols="2">
                                <shift-assigner
                                    :mine="mine"
                                    :weekDate="model.weekStartDate"
                                    :editable="false"
                                    :data="availabilityFor(plannedEquipment, 2)"
                                    icon="mdi-close"
                                    :color="colorFor(2)"
                                ></shift-assigner>
                            </v-col>
                              <v-col :cols="2">
                                <shift-assigner
                                    :mine="mine"
                                    :weekDate="model.weekStartDate"
                                    :editable="false"
                                    :data="availabilityFor(plannedEquipment, 3)"
                                    icon="mdi-close"
                                    :color="colorFor(3)"
                                ></shift-assigner>
                            </v-col>
                            <v-col :cols="2">
                                <v-checkbox
                                    :disabled="!hasPermission"
                                    v-model="plannedEquipment.idleOrUnavailable"
                                    @change="saveIdle(plannedEquipment)"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </template>
                </v-container>
            </v-col>
        </v-row>
        <standard-dialog :render="!!selected" v-model='showAvailabilityModal' :max-width="780" :isLoading="$wait.is('get calendar')" loaderName="Week's Equipment Availability Model">
            <template v-slot:title>
                Availability for {{ selected.equipment.name }}
            </template>
            <template v-slot:content>
                <v-form ref="form" :lazy-validation="true">
                    <h3 class="title">Services</h3>
                    <v-row v-for="(availability, index) in selectedAvailability" :key="index">
                        <v-col :cols="3">
                            <v-select
                                :disabled="!hasPermission"
                                :readonly="!hasPermission"
                                :items="availabilityTypes"
                                item-value="id"
                                item-text="name"
                                v-model="availability.equipmentAvailabilityType"
                                :label="$termSync('Type')"
                                single-line
                                :rules="[$rules.notNull()]"
                                :menu-props="{ dark: true }"
                                @change="changeAvailabilityFor(availability)"
                            ></v-select>
                        </v-col>
                        <v-col :cols="4">
                            <v-datetime-picker
                                :disabled="!hasPermission"
                                label="Start At"
                                v-model="availability.startsAt"
                                :dateFormat="dateFormat"
                                timeFormat="HH:mm"
                                :timePickerProps="{ 'allowed-minutes': m => m % 15 === 0 }"
                                :textFieldProps="{ 'hide-details': true }"
                                @input="changeAvailabilityFor(availability)"
                            >
                                <template #dateIcon>
                                    <v-icon>mdi-calendar</v-icon>
                                </template>
                                <template #timeIcon>
                                    <v-icon>mdi-clock-outline</v-icon>
                                </template>
                            </v-datetime-picker>
                            <v-text-field
                                class="custom-validation-message"
                                v-model="availability.endsAt"
                                readonly
                                :rules="[$rules.required()]"
                            ></v-text-field>
                        </v-col>
                        <v-col :cols="4">
                            <v-datetime-picker
                                label="Ends At"
                                :disabled="!hasPermission"
                                v-model="availability.endsAt"
                                :rules="[$rules.required()]"
                                :dateFormat="dateFormat"
                                timeFormat="HH:mm"
                                :timePickerProps="{ 'allowed-minutes': m => m % 15 === 0 }"
                                :textFieldProps="{ 'hide-details': true }"
                                @input="changeAvailabilityFor(availability)"
                            >
                                <template #dateIcon>
                                    <v-icon>mdi-calendar</v-icon>
                                </template>
                                <template #timeIcon>
                                    <v-icon>mdi-clock-outline</v-icon>
                                </template>
                            </v-datetime-picker>
                            <v-text-field
                                class="custom-validation-message"
                                v-model="availability.endsAt"
                                readonly
                                :rules="[
                                        $rules.required(),
                                        $rules.custom(
                                            v => !validateDates(v, availability),
                                            'Must be greater than start'
                                        )
                                    ]"
                            ></v-text-field>
                        </v-col>
                        <v-col :cols="1">
                            <v-btn icon @click="remove(availability)" class="mt-4" v-if="hasPermission">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-btn color="primary" @click="add()" class="mt-3" v-if="hasPermission">
                        <v-icon left>mdi-plus</v-icon>Add
                    </v-btn>
                </v-form>
            </template>
            <template v-slot:actions>
                <v-spacer></v-spacer>
                <v-btn
                    @click="
                            showAvailabilityModal = false;
                            selected = null;
                            selectedAvailability = null;
                        "
                    :disabled="$wait.is('saving')"
                    text
                >Cancel</v-btn
                >
                <v-btn
                    color="primary"
                    @click="saveAvailability()"
                    :loading="$wait.is('saving')"
                    text
                    v-if="hasPermission"
                >Save</v-btn
                >
            </template>
        </standard-dialog>
    </v-container>
</template>
<script>
import { EventBus, Events } from '@/lib/EventBus';
import MineAreas from '@/lib/data/MineAreas';
import EquipmentAvailability from '@/lib/data/EquipmentAvailability';
import Shift from '@/lib/data/Shift';
import CurrentShiftStore from '@/lib/stores/CurrentShiftStore';
import ShiftAssigner from '@/components/Shared/ShiftAssigner';
import dayjs from 'dayjs';
import PlannedEquipment from '@/lib/data/PlannedEquipment';
import { DateFormat } from '@/plugins/dates';
import ImportEquipmentsAvailabilityPlan from './Dialogs/ImportEquipmentsAvailabilityPlan.vue';
import { Roles } from '@/lib/Security';
import Security from '@/lib/Security';
export default {
    components: {
        ShiftAssigner,
        ImportEquipmentsAvailabilityPlan
    },
    data() {
        return {
            shiftsPerDay: 2,
            equipmentFilter: '',
            plannedEquipment: null,
            doesntExist: false,
            model: null,
            shiftMap: [],
            mine: null,
            selected: null,
            selectedAvailability: [],
            showAvailabilityModal: false,
            deleted: [],
            availabilityTypes: [
                { id: 0, name: 'Weekly Service' },
                { id: 1, name: 'Minor Service' },
                { id: 2, name: 'Major Service' },
                { id: 3, name: 'Unserviceable' }
            ],
            dateFormat: DateFormat.ShortDisplayPicker,
            AppRoles: Roles
        };
    },
    async created() {
        await this.loadData();
    },
    destroyed() {
        EventBus.$emit(Events.WeekBoard, null);
    },
    methods: {
        remove(a) {
            this.deleted.push(a);
            this.selectedAvailability = this.selectedAvailability.filter(x => x !== a);
        },
        add() {
            this.selectedAvailability.push({
                equipmentId: this.selected.equipment.id,
                equipmentAvailabilityType: null,
                startsAt: null,
                endsAt: null,
                dirty: true
            });
        },
        async loadData() {
            CurrentShiftStore.setBoard('weekresourcing');
            try {
                this.$wait.start('get data');

                var model = await Shift.week(
                    this.$route.params.mine,
                    this.$route.params.dept,
                    this.$route.params.date,
                    false
                );

                this.model = model;
                if (!model.exists) {
                    this.doesntExist = true;
                    this.shiftsPerDay = model.shiftsPerDay;
                    EventBus.$emit(Events.ShowLeftDrawerButton, false);
                    return;
                }

                var mineReq = MineAreas.getById(this.$route.params.mine);

                await this.getEquipment();

                this.mine = await mineReq;
                this.shiftsPerDay = model.shiftsPerDay;

                var shiftMap = [];
                var j = 0;
                for (let shift of model.shifts) {
                    shiftMap.push({
                        start: dayjs(shift.shiftStart),
                        end: dayjs(shift.shiftEnd),
                        shiftIndex: shift.shiftNumber - 1,
                        dayInShiftWeek: Math.floor(j / model.shiftsPerDay)
                    });
                    j++;
                }
                this.shiftMap = shiftMap;

                EventBus.$emit(Events.WeekBoard, { firstDay: model.weekStartDate, weekNumber: model.weekNumber });
            } catch (e) {
                return;
            } finally {
                this.$wait.end('get data');
            }
        },
        async getEquipment() {
            var plannedEquipment = await PlannedEquipment.forWeekWithoutTasks(this.model.planId);

            this.plannedEquipment = plannedEquipment;
        },
        async openEquipment(equipment) {
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            try {
                this.$wait.start('get calendar');
                this.selectedAvailability = null;
                var availability = await EquipmentAvailability.find(equipment.equipment.id, this.model.weekStartDate);
                this.deleted = [];
                this.selectedAvailability = availability.filter(x => {
                    x.dirty = false;
                    x.startsAt = new Date(dayjs.utc(x.startsAt).format('YYYY-MM-DD HH:mm'));
                    x.endsAt = new Date(dayjs.utc(x.endsAt).format('YYYY-MM-DD HH:mm'));
                    return true;
                });
                setTimeout(() => this.$refs.form.resetValidation(), 1);
            } catch (e) {
                return;
            } finally {
                this.$wait.end('get calendar');
            }

            this.selected = equipment;
            this.showAvailabilityModal = true;
        },
        async saveAvailability() {
            if (!this.$refs.form.validate()) {
                return;
            }
            try {
                this.$wait.start('saving');

                var changed = this.selectedAvailability.filter(x => x.dirty).map(x => ({ ...x, dirty: undefined }));
                for (let x of changed) {
                    x.startsAt = dayjs(x.startsAt).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
                    x.endsAt = dayjs(x.endsAt).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
                    await EquipmentAvailability.save(x);
                }
                for (let x of this.deleted) {
                    await EquipmentAvailability.delete(x.id);
                }
                this.selected = null;
                this.showAvailabilityModal = false;
            } catch (e) {
                return;
            } finally {
                this.$wait.end('saving');
                await this.getEquipment();
            }
        },
        async saveIdle(plannedEquipment) {
            try {
                this.$wait.start('saving');
                await PlannedEquipment.setIdle(plannedEquipment.id, plannedEquipment.idleOrUnavailable);
            } catch (e) {
                plannedEquipment.idleOrUnavailable = !plannedEquipment.idleOrUnavailable;
            } finally {
                this.$wait.end('saving');
            }
        },
        availabilityFor(plannedEquipment, type) {
            var shifts = [];
            for (var i = 1; i <= this.shiftsPerDay; i++) {
                shifts.push({ number: i, days: [false, false, false, false, false, false, false] });
            }

            if (!this.shiftMap || !plannedEquipment.availability || !plannedEquipment.availability.length)
                return shifts;

            for (let availability of plannedEquipment.availability) {
                if (availability.equipmentAvailabilityType != type) {
                    continue;
                }
                var start = dayjs(availability.startsAt);
                var end = dayjs(availability.endsAt);

                for (let shift of this.shiftMap) {
                    if (shift.start.isSameOrAfter(end)) {
                        continue;
                    }
                    if (start.isSameOrAfter(shift.end)) {
                        continue;
                    }
                    shifts[shift.shiftIndex].days[shift.dayInShiftWeek] = true;
                }
            }
            return shifts;
        },
        filterEquipment(e, searchFilter) {
            if (!searchFilter) return true;
            if ((e.equipment.name || '').toLowerCase().indexOf(searchFilter) > -1) return true;
            if (!e.equipment.equipmentRole) return true;
            if ((e.equipment.equipmentRole.name || '').toLowerCase().indexOf(searchFilter) > -1) return true;
        },
        sortByRole(a, b) {
            if (a.equipment.equipmentRole.displayOrder != b.equipment.equipmentRole.displayOrder) {
                return a.equipment.equipmentRole.displayOrder - b.equipment.equipmentRole.displayOrder;
            }

            if (a.equipment.name < b.equipment.name) {
                return -1;
            }
            if (a.equipment.name > b.equipment.name) {
                return 1;
            }
            return 0;
        },
        typedEquip(t) {
            if (!this.plannedEquipment) return [];
            var searchFilter = (this.equipmentFilter || '').toLowerCase();
            var equipment = this.plannedEquipment.filter(
                x => x.equipment.equipmentType == t && this.filterEquipment(x, searchFilter)
            );
            return equipment.sort(this.sortByRole);
        },
        validateDates(v, availability) {
            if (!availability.endsAt || !availability.startsAt) return true;
            var e = dayjs(availability.endsAt);
            var s = dayjs(availability.startsAt);
            var after = e.isAfter(s);
            return after;
        },
        changeAvailabilityFor(availability) {
            availability.dirty = true;
        },
        colorFor(x) {
            return EquipmentAvailability.colorFor(x);
        }
    },
    computed: {
        filteredEquipment() {
            if (!this.plannedEquipment) return [];
            var searchFilter = (this.equipmentFilter || '').toLowerCase();
            return this.plannedEquipment.filter(x => this.filterEquipment(x, searchFilter));
        },
        groupedEquipment() {
            return [
                { name: 'Primary', data: this.typedEquip('Primary') },
                { name: 'Secondary', data: this.typedEquip('Secondary') },
                { name: 'Support', data: this.typedEquip('Support') }
            ];
        },
        hasPermission() {
            return Security.hasAny([Roles.WeekPlanner]) && !Security.isReadOnly();
        }
    }
};
</script>
<style lang="scss">
.v-application .leave-picker .v-btn--active {
    background-color: #aaa !important;
    border-color: #aaa !important;
}
</style>
<style lang="scss" scoped>
@import '@/variables.scss';
.striped {
    background-color: $stripe-color;
}
.idle {
    color: #dd0000aa;
}

.floating-button {
    margin-right: 10px !important;
    z-index: 1;
}
</style>
