import TermsApi from '@/lib/data/Terms';
import { Events, EventBus } from '@/lib/EventBus';
import _ from 'lodash';

let terms = {} as any;
let termsFetched = false;
let termsPromise = null as Promise<any[]> | null;
let plurals = {} as any;

export class Terms {
    public async term(term: string) {
        if (!terms) {
            await this.fetchTerms();
        }
        return this.termSync(term);
    }
    public async termPlural(term: string, count?: number) {
        if (!terms) {
            await this.fetchTerms();
        }

        const lowerCaseTerm = term.toLowerCase();
        const found = terms[lowerCaseTerm];

        if (count == undefined || count > 1 || count < -1) {
            return found ? found.pluralValue : `${term}s`;
        }
        return found ? found.value : term;
    }
    public termSync(term: string) {
        const lowerCaseTerm = term.toLowerCase();
        return terms[lowerCaseTerm]?.value || plurals[lowerCaseTerm]?.pluralValue || term;
    }
    public termPluralSync(term: string, count?: number) {
        const lowerCaseTerm = term.toLowerCase();

        const found = terms[lowerCaseTerm];

        if (count == undefined || count > 1 || count < -1 || count == 0) {
            return found?.pluralValue || `${term}s`;
        }
        return found?.value || term;
    }
    public async fetchTerms() {
        try {
            termsPromise = TermsApi.get();
            const result = await termsPromise;
            if (!result) {
                terms = {};
                termsFetched = true;
                return;
            }
            const newTerms = {} as any;
            const newPlurals = {} as any;
            for (const x of result) {
                if (x.name) {
                    newTerms[x.name.toLowerCase()] = x;
                }
                if (x.pluralName) {
                    newPlurals[x.pluralName.toLowerCase()] = x;
                }
            }
            terms = newTerms;
            plurals = newPlurals;
            termsFetched = true;
        } catch {
            EventBus.$emit(Events.ToastError, `There was an error loading the application, please refresh.`, true);
        } finally {
            termsPromise = null;
        }
    }
    public async guaranteeTermsFetched() {
        if (!termsFetched) {
            if(termsPromise !== null) {
                await termsPromise;
            }
            else
                await this.fetchTerms();
        }
    }
}

const instance = new Terms();

EventBus.$on(Events.TermsUpdated, async () => {
    await instance.fetchTerms();
});

export default instance;
