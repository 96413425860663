import { defineStore } from 'pinia';

export enum AdminSandpitVisibility {
    NO_SANDPIT = 0,
    BOTH = 1,
    ONLY_SANDPIT = 2
}

export const useSandpitStore = defineStore('sandpit', {
    state: () => {
        return {
            adminSandpitVisibility: AdminSandpitVisibility.BOTH as AdminSandpitVisibility
        };
    },
    actions: {
        setAdminSandpitVisibility(newVisibility: AdminSandpitVisibility): void {
            this.adminSandpitVisibility = newVisibility;
        }
    }
})