import { ICommandMapper } from '../ICommandMapper';
import { DeleteTaskCommand, OfflineEditableCommand } from '@/models/api';
import { EditStore, Edit } from '../EditStore';
import ConvertCommandsToBaselineHelper from './Helpers/ConvertCommandsToBaselineHelper';

export class DeleteTaskCommandMapper implements ICommandMapper<DeleteTaskCommand> {
    commandType = 'DeleteTaskCommand';
    async onQueue(command: DeleteTaskCommand, store: EditStore) {
        await ConvertCommandsToBaselineHelper.onQueue(command, store);
    }
    onDequeue(edit: Edit<OfflineEditableCommand>, attemptedAt: Date): Edit<any> {
        return ConvertCommandsToBaselineHelper.onDequeue(edit, attemptedAt);
    }
    mappers = [ConvertCommandsToBaselineHelper.createShiftModelMapper<DeleteTaskCommand>()];
}

export default new DeleteTaskCommandMapper();
