import {
    COLUMN_IDS,
    ExtraColumnReasonToExist,
    SortDirection,
} from '@/components/Board/GroupingFilteringAndSorting';
import {
    ExtendedPropertyInfo
} from '@/models/api';
import { BoardGroupingType } from '@/components/ShiftBoard/Drawers/BoardGroupingDrawerProps';
import _, { curry } from 'lodash';
import { TimePeriod } from '@/models/client/time-block';
import { CollapseTimePeriods } from '@/lib/services/Location';
import InteractionsClientRowModel from '@/models/client/interactions-client-row';
import { alphabeticSortingFunc, defineManipulationStore } from '@/lib/stores/BoardManipulation/shared';
import {
    alphabeticPropertyAndLocationNameSortingFunc,
    createAggregateRowForExtendedPropertyFromClientRows,
    createAggregateRowFromClientRows,
    extendedPropertyGroupingForClientRowsFunc,
    generateExtendedPropertySortingFunc, levelGroupingFromClientRowsFunc,
    levelSortingFunc,
} from '@/lib/stores/BoardManipulation/shift-shared';

export type HeadingClientRowModel = InteractionsClientRowModel & {
    occupiedTimes: TimePeriod[],
    representsRealLocation: boolean,
}

export interface GroupedClientRow {
    headingRow: HeadingClientRowModel,
    additionalRows: InteractionsClientRowModel[]
}

export function levelGroupingFunc(headings: InteractionsClientRowModel[]): GroupedClientRow[] {
    return levelGroupingFromClientRowsFunc(headings, createAggregateRow);
}

export function departmentGroupingFunc(headings: InteractionsClientRowModel[]): GroupedClientRow[] {
    const groupedRows = _.groupBy(headings, h=>h.departmentName);

    return _.keys(groupedRows).map(k=>{
        const rows = groupedRows[k];
        const occupiedTimes = CollapseTimePeriods(_.flatMap(rows, cr=>cr.tasks.filter(t=>!t.isDeleted && !t.taskType.isDelay)));
        return {
            headingRow: {
                ...createAggregateRow(rows),
                occupiedTimes
            },
            additionalRows: rows
        };
    });
}

export function extendedPropertyGroupingFunc(extendPropertyId: string, headings: InteractionsClientRowModel[]): GroupedClientRow[] {
    return extendedPropertyGroupingForClientRowsFunc(extendPropertyId, headings, createAggregateRowForExtendedProperty);
}

function createAggregateRowForExtendedProperty(groupedRows: InteractionsClientRowModel[], extendedProperty: ExtendedPropertyInfo): HeadingClientRowModel {
    return createAggregateRowForExtendedPropertyFromClientRows(groupedRows, extendedProperty, row=>({
        ...row,
        departmentName: _.uniq(groupedRows.map(x=>x.departmentName)).length === 1 ? _.uniq(groupedRows.map(x=>x.departmentName))[0] ?? '' : '',
    }));
}

function createAggregateRow(groupedRows: InteractionsClientRowModel[]): HeadingClientRowModel {
    return createAggregateRowFromClientRows(groupedRows, row=>({
            ...row,
            departmentName: _.uniq(groupedRows.map(x=>x.departmentName)).length === 1 ? _.uniq(groupedRows.map(x=>x.departmentName))[0] ?? '' : '',
        }));
}

const sortLocationsByDepartment = (
    secondaryComparison: (a: InteractionsClientRowModel, b: InteractionsClientRowModel) => number,
    a: InteractionsClientRowModel,
    b: InteractionsClientRowModel,
    descending: boolean
) => {
    const multiplier = descending ? -1 : 1;

    if (a.departmentName < b.departmentName) {
        return -1 * multiplier;
    }
    if (a.departmentName > b.departmentName) {
        return 1 * multiplier;
    }

    return descending ? secondaryComparison(b,a) : secondaryComparison(a, b);
};

export function alphabeticDepartmentAndLocationNameSortingFunc(rows: GroupedClientRow[], direction: SortDirection): GroupedClientRow[] {
    return alphabeticPropertyAndLocationNameSortingFunc(rows, direction, sortLocationsByDepartment);
}

export function departmentSortingFunc(rows: GroupedClientRow[], direction: SortDirection): GroupedClientRow[] {
    return alphabeticSortingFunc(rows, direction, (row)=>row.departmentName);
}

export const useCaptainBoardColumnManipulation =
    defineManipulationStore<InteractionsClientRowModel, GroupedClientRow>('captainBoardColumnManipulation', 'CAPTAIN',
        extendedPropertyGroupingFunc,
        generateExtendedPropertySortingFunc,
        {
            type: BoardGroupingType.DEFAULT,
            id: COLUMN_IDS.LEVEL,
            text: 'Area',
            furtherInformation: '',
            groupingFunc: levelGroupingFunc
        },
        {
            direction: SortDirection.Ascending,
            columnId: COLUMN_IDS.NULL,
            sortFunc: alphabeticDepartmentAndLocationNameSortingFunc
        },
        [
            {
                id: COLUMN_IDS.DEPARTMENT,
                name: 'Department',
                columnNameOverride: 'DEP',
                columnSize: 'x',
                displayValueSelector: row=>row.departmentName,
                groupingDefinition: {
                    text: 'Department',
                    id: COLUMN_IDS.DEPARTMENT,
                    type: BoardGroupingType.DEPARTMENT,
                    furtherInformation: '',
                    groupingFunc: departmentGroupingFunc
                },
                propertyFilter: null,
                sorting: {
                    sortFunc: departmentSortingFunc
                },
                reasonOnBoard: ExtraColumnReasonToExist.Default,
            },
            {
                id: COLUMN_IDS.LEVEL,
                name: 'Level',
                columnNameOverride: 'AREA',
                columnSize: 'l',
                displayValueSelector: row=>row.location?.reducedLevelName ?? '',
                groupingDefinition: {
                    text: 'Area',
                    id: COLUMN_IDS.LEVEL,
                    type: BoardGroupingType.LEVEL,
                    furtherInformation: '',
                    groupingFunc: levelGroupingFunc
                },
                propertyFilter: null,
                sorting: {
                    sortFunc: levelSortingFunc
                },
                reasonOnBoard: ExtraColumnReasonToExist.Default,
            }
        ], false)();