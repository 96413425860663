import Vue from 'vue';

/* eslint-disable no-console */
const logger = new Vue() as any;
export class Logging {
    public error(e: any): void {
        try {
            if (logger.$appInsights) {
                logger.$appInsights.trackException({exception: e});
            }
        } catch (logEx) {
            console.error('Error sending ERROR log', logEx);
        }
        console.error(e);
    }
    public warning(message: string, properties: any | undefined = undefined): void {
        try {
            if (logger.$appInsights) {
                logger.$appInsights.trackTrace({ message, severityLevel: SeverityLevel.Warning.valueOf() }, properties);
            }
        } catch (logEx) {
            console.error('Error sending WARNING log', message, properties);
        }
        console.log('WARNING', message, properties);
    }
    public info(message: string, properties: any | undefined = undefined): void {
        try {
            if (logger.$appInsights) {
                logger.$appInsights.trackTrace({ message, severityLevel: SeverityLevel.Information.valueOf()}, properties);
            }
        } catch (logEx) {
            console.error('Error sending INFO log', message, properties);
        }
        console.log('INFO', message, properties);
    }
}
/* eslint-enable no-console */

export default new Logging();

export enum SeverityLevel {
    Verbose = 0,
    Information = 1,
    Warning = 2,
    Error = 3,
    Critical = 4
}
