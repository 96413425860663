import { defineStore } from 'pinia';
import { AdminSandpitVisibility } from '@/lib/stores/SandpitStore';

const LOCAL_STORAGE_FILTER_KEY: string = 'BOARD_OPTIONS.FILTER';
const STICKY_SHOW_EMPTY_LOCATIONS: boolean = false;

export interface BoardFilterState {
    showEmptyLocations: boolean;
}


export const useBoardOptions = defineStore('boardOptions', {
    state: () => {
        return {
            boardFilterState: {
                showEmptyLocations: true
            } as BoardFilterState,
        };
    },
    getters: {
        defaultFilterState(): BoardFilterState {
            return {
                showEmptyLocations: true
            };
        },
        showEmptyLocations(): boolean {
            return this.boardFilterState.showEmptyLocations;
        },
    },
    actions: {
        setUpStore(){
              const savedFilterSettings = localStorage.getItem(LOCAL_STORAGE_FILTER_KEY);
                if (savedFilterSettings) {
                    const parsedFilterSettings = JSON.parse(savedFilterSettings);
                    if(!STICKY_SHOW_EMPTY_LOCATIONS)
                        parsedFilterSettings.showEmptyLocations = true;
                    this.boardFilterState = { ... this.defaultFilterState, ... parsedFilterSettings};
                } else {
                    this.boardFilterState = this.defaultFilterState;
                }
        },
        setShowEmptyLocations(showEmptyLocations: boolean): void {
            this.boardFilterState.showEmptyLocations = showEmptyLocations;
            this.saveFilterSettings();
        },
        saveFilterSettings(): void {
            localStorage.setItem(LOCAL_STORAGE_FILTER_KEY, JSON.stringify(this.boardFilterState));
        }
    }
})