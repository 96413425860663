import {
    CreateTriggerActionResponsePlanAspectCommand,
    UpdateTriggerActionResponsePlanAspectCommand,
    ReorderTriggerActionResponsePlanAspectsCommand,
    TriggerActionResponsePlanAspectViewModel,
    GetTriggerActionResponsePlanAspectsQueryResponse,
} from '@/models/api';
import { AxiosError } from 'axios';
import AppHttp from '../AppHttp';
import Errors from '../Errors';

export class TriggerActionResponsePlans {
    GetBaseUri(departmentId: string | null): string {
        return `Departments/${departmentId}/TARP`;
    }

    public async create(command: CreateTriggerActionResponsePlanAspectCommand) {
        await AppHttp.post(`${this.GetBaseUri(command.departmentId)}/Create`, command);
    }

    public async update(departmentId: string, command: UpdateTriggerActionResponsePlanAspectCommand) {
        await AppHttp.post(`${this.GetBaseUri(departmentId)}/Update`, command);
    }

    public async reorder(command: ReorderTriggerActionResponsePlanAspectsCommand) {
        await AppHttp.post(`${this.GetBaseUri(command.departmentId)}/Reorder`, command);
    }

    public async export(departmentId: string) {
        const response = await AppHttp.get(`${this.GetBaseUri(departmentId)}/Export`);
        const data = response ? response.data : null;
        return data;
    }

    public async import(departmentId: string, file: any) {
        const response = await AppHttp.postWithFiles(`${this.GetBaseUri(departmentId)}/Import`, {}, [file]);
        const data = response ? response.data : null;
        return data;
    }

    public async getById(departmentId: string, aspectId: string): Promise<TriggerActionResponsePlanAspectViewModel> {
        const response = await AppHttp.get(`${this.GetBaseUri(departmentId)}/${aspectId}`);

        const data = response ? response.data : null;
        if (data == null) throw 'No response data';
        return data;
    }

    public async list(departmentId: string): Promise<GetTriggerActionResponsePlanAspectsQueryResponse> {
        const response = await AppHttp.get(`${this.GetBaseUri(departmentId)}`);
        const data = response ? response.data : null;
        if (data == null) throw 'No response data';
        return data;
    }
}

export default new TriggerActionResponsePlans();
