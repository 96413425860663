import ClientTaskModel from '@/models/client/client-task';
import { NoGoZone } from '@/models/client/no-go-zone';
import { NoGoType } from '@/models/client/types/no-go-type';
import { Dayjs } from 'dayjs';
import { TaskIntersectsTimespan, TimespanContainsTask } from '../Task';

export default class NoGoZoneFinder {
    private _forwardGenerator: IterableIterator<NoGoZone[]>;
    private _backwardGenerator: IterableIterator<NoGoZone[]>;

    public readonly ignoreSos: boolean;
    public readonly ignoreEos: boolean;

    private _noGoZones: NoGoZone[];
    private _noGoZonesReversed: NoGoZone[];

    constructor(
        forwardGenerator: IterableIterator<NoGoZone[]>,
        backwardGenerator: IterableIterator<NoGoZone[]>,
        ignoreSos: boolean,
        ignoreEos: boolean
    ) {
        const nextValues = forwardGenerator.next().value;
        this._noGoZones = [...nextValues];
        this._noGoZonesReversed = [...this._noGoZones].reverse();

        this._forwardGenerator = forwardGenerator;
        this._backwardGenerator = backwardGenerator;
        this.ignoreEos = ignoreEos;
        this.ignoreSos = ignoreSos;
    }

    public getZoneAfter(time: Dayjs, filterType: NoGoType | null = null): NoGoZone {
        let zone: NoGoZone | undefined = undefined;

        while (time.isBefore(this._noGoZones[0].startTime)) {
            this.addEarlierZones();
        }

        let searchGroup = [...this._noGoZones];

        while (zone === undefined) {
            if (filterType === null) {
                zone = searchGroup.find((x) => x.startTime.isSameOrAfter(time));
            } else {
                zone = searchGroup.find((x) => x.noGoType === filterType && x.startTime.isSameOrAfter(time));
            }

            if (zone === undefined) {
                searchGroup = this.addLaterZones();
            }
        }

        return zone;
    }

    public getZoneBefore(time: Dayjs, filterType: NoGoType | null = null): NoGoZone | undefined {
        let zone: NoGoZone | undefined;

        if (filterType === null) {
            zone = this._noGoZonesReversed.find((x) => x.startTime.isSameOrBefore(time));
        } else {
            zone = this._noGoZonesReversed.find((x) => x.noGoType === filterType && x.startTime.isSameOrBefore(time));
        }

        return zone;
    }

    public getZones() {
        return this._noGoZones;
    }

    public getZoneWithIndex(index: number) {
        while (index >= this._noGoZones.length) {
            this.addLaterZones();
        }

        return this._noGoZones[index];
    }

    public isInNoGo(task: ClientTaskModel, noGoType?: NoGoType) {
        if (noGoType === NoGoType.EndOfShift && this.ignoreEos) return false;
        if (noGoType === NoGoType.StartOfShift && this.ignoreSos) return false;

        const zoneAfter = this.getZoneAfter(task.startTime, noGoType);
        const zoneBefore = this.getZoneBefore(task.startTime, noGoType);

        if (zoneAfter && TimespanContainsTask(task, zoneAfter.startTime, zoneAfter.endTime)) return true;
        if (zoneBefore && TimespanContainsTask(task, zoneBefore.startTime, zoneBefore.endTime)) return true;

        return false;
    }

    public overlapsNoGo(task: ClientTaskModel, noGoType?: NoGoType) {
        if (noGoType === NoGoType.EndOfShift && this.ignoreEos) return false;
        if (noGoType === NoGoType.StartOfShift && this.ignoreSos) return false;

        const zoneAfter = this.getZoneAfter(task.startTime, noGoType);
        const zoneBefore = this.getZoneBefore(task.startTime, noGoType);

        if (zoneAfter && TaskIntersectsTimespan(task, zoneAfter.startTime, zoneAfter.endTime)) return true;
        if (zoneBefore && TaskIntersectsTimespan(task, zoneBefore.startTime, zoneBefore.endTime)) return true;

        return false;
    }

    private addEarlierZones() {
        const newZones: NoGoZone[] = this._backwardGenerator.next().value!;
        this._noGoZones = [...newZones, ...this._noGoZones];
        this._noGoZonesReversed = [...this._noGoZones].reverse();

        return newZones;
    }

    private addLaterZones() {
        const newZones = this._forwardGenerator.next().value!;
        this._noGoZones = [...this._noGoZones, ...newZones];
        this._noGoZonesReversed = [...this._noGoZones].reverse();

        return newZones;
    }
}
