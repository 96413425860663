import { ICommandMapper, ICommandToModelMapper } from './ICommandMapper';
import EditStore, { Edit } from './EditStore';
import { OfflineEditableCommand } from '@/models/api';
import Logging from '@/lib/Logging';

import AddRoleToShiftCommandMapper from './Mappings/AddRoleToShiftCommandMapper';
import AddTaskBeforeOrAfterCommandMapper from './Mappings/AddTaskBeforeOrAfterCommandMapper';
import AnswerQuestionCommandMapper from './Mappings/AnswerQuestionCommandMapper';
import CreateShiftSnapshotCommandMapper from './Mappings/RecordShiftSnapshotCommandMapper';
import CreateStaffCommandMapper from './Mappings/CreateStaffCommandMapper';
import DeleteAdHocTaskCommandMapper from './Mappings/DeleteAdHocTaskCommandMapper';
import DeleteTaskCommandMapper from './Mappings/DeleteTaskCommandMapper';
import SetCrewForShiftCommandMapper from './Mappings/SetCrewForShiftCommandMapper';
import SplitTaskCommandMapper from './Mappings/SplitTaskCommandMapper';
import StartStopMeetingCommandMapper from './Mappings/StartStopMeetingCommandMapper';
import UpdateAdHocTaskTimesCommandMapper from './Mappings/UpdateAdHocTaskTimesCommandMapper';
import UpdateTaskTimesCommandMapper from './Mappings/UpdateTaskTimesCommandMapper';
import CreateAdHocTaskCommandMapper from './Mappings/CreateAdHocTaskCommandMapper';
import UpdateAdHocTaskCommandMapper from './Mappings/UpdateAdHocTaskCommandMapper';

const enableDebugLogging = false;
function log(...params: [any?, ...any[]]) {
    if (!enableDebugLogging) {
        return;
    }
    /* eslint-disable no-console */
    /* eslint-disable prefer-spread */
    console.log.apply(console, params);
    /* eslint-enable no-console */
}

export class EditMappings {
    private commandMaps: ICommandMapper<any>[];
    private offlineEditableCommands: { [key: string]: ICommandMapper<any> } = {};
    private mappers: { [key: string]: ICommandMapper<any> } = {};
    private modelMappers: { [key: string]: ICommandToModelMapper<any> } = {};

    constructor() {
        this.commandMaps = [
            AddRoleToShiftCommandMapper,
            AddTaskBeforeOrAfterCommandMapper,
            AnswerQuestionCommandMapper,
            CreateAdHocTaskCommandMapper,
            CreateShiftSnapshotCommandMapper,
            CreateStaffCommandMapper,
            DeleteAdHocTaskCommandMapper,
            DeleteTaskCommandMapper,
            SetCrewForShiftCommandMapper,
            SplitTaskCommandMapper,
            StartStopMeetingCommandMapper,
            UpdateAdHocTaskTimesCommandMapper,
            UpdateAdHocTaskCommandMapper,
            UpdateTaskTimesCommandMapper,
        ];

        for (const commandMapper of this.commandMaps) {
            this.offlineEditableCommands[commandMapper.commandType] = commandMapper;
            this.mappers[commandMapper.commandType] = commandMapper;
            for (const mapper of commandMapper.mappers) {
                this.modelMappers[`${commandMapper.commandType}_${mapper.viewmodelType}`] = mapper;
            }
        }
    }

    public hasCommand(commandType: string): boolean {
        return !!this.offlineEditableCommands[commandType];
    }

    public async performOnQueue(command: OfflineEditableCommand): Promise<void> {
        const mapper = this.mappers[command._type];
        if (!mapper || !mapper.onQueue) {
            return;
        }
        await mapper.onQueue(command, EditStore);
    }

    public performDequeue(editModel: Edit<OfflineEditableCommand>, attemptedAt: Date): Edit<OfflineEditableCommand> {
        const mapper = this.mappers[editModel.command._type];
        if (!mapper || !mapper.onDequeue) {
            return editModel;
        }
        return mapper.onDequeue(editModel, attemptedAt);
    }

    public async applyPendingEdits<TViewModel>(viewmodelType: string, viewmodel: TViewModel): Promise<TViewModel> {
        if (!viewmodel) {
            return viewmodel;
        }
        await EditStore.enumerate(async (x, _) => {
            viewmodel = this.tryMap(viewmodelType, viewmodel, x.command);
            return false;
        });
        return viewmodel;
    }

    public tryMap<TViewModel>(
        viewmodelType: string,
        viewmodel: TViewModel,
        command: OfflineEditableCommand
    ): TViewModel {
        const mapper = this.modelMappers[`${command._type}_${viewmodelType}`];
        if (!mapper) {
            log(`NO MAPPER: ${command._type}_${viewmodelType}`);
            return viewmodel;
        }
        try {
            log('EXEC MAPPER', command._type, viewmodelType, command, viewmodel, mapper);
            return mapper.map(command, viewmodel);
        } catch (e) {
            Logging.error(e);
            return viewmodel;
        }
    }
}

export default new EditMappings();
