import { defineStore } from 'pinia';
import {
    CreateDepartmentToDoActionCommand,
    CreateMineAreaToDoActionCommand,
    ToDoActionCommentViewModel, ToDoActionStatus,
    ToDoActionViewModel
} from '@/models/api';
import  ToDoActions from '@/lib/data/ToDoActions';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import UserStore from '@/lib/stores/UserStore';
import dayjs from 'dayjs';
import { EventBus, Events } from '@/lib/EventBus';
import { ClientToDoAction, ClientToDoActionComment } from '@/models/client/client-to-do-action';
import { TransformToDoActionViewModelToClient } from '@/models/transforms/ToDoActionTransforms';
import { useSystemStore } from '@/lib/stores/SystemStore';

export const useToDoActionsStore = defineStore('toDoActions', {
    state: ()=>{
        return {
            actions: [] as ClientToDoAction[],
            mineAreaId: '',
            departmentId: '',
        };
    },
    actions: {
        async populateStore(departmentId: string, mineAreaId: string){
            // if we're offline and the department and mine area are the same, don't re-fetch
            if(!useSystemStore().isOnline && this.departmentId == departmentId && this.mineAreaId == mineAreaId)
                return;

            this.mineAreaId = mineAreaId;
            this.departmentId = departmentId;
            this.actions = (await ToDoActions.get(departmentId)).map(x=>TransformToDoActionViewModelToClient(x));
        },
        async populateStoreForMineArea(mineAreaId: string){
            // if we're offline and the department and mine area are the same, don't re-fetch
            if(!useSystemStore().isOnline && this.departmentId == '' && this.mineAreaId == mineAreaId)
                return;

            this.mineAreaId = mineAreaId;
            this.departmentId = '';
            this.actions = (await ToDoActions.getForMineArea(mineAreaId)).map(x=>TransformToDoActionViewModelToClient(x));
        },
        clearStore() {
            this.actions = [];
        },
        async createMineAreaToDoAction(mineAreaId: string, name: string, description: string ) {
              const newAction: ClientToDoAction = await this.createNewClientToDoAction(mineAreaId, null, name, description);

            // Add a new to-do action to the mine area
            const command: CreateMineAreaToDoActionCommand = {
                _type: 'CreateMineAreaToDoActionCommand',
                id: newAction.id,
                mineAreaId: mineAreaId,
                name: name,
                description: description,
                displayOrder: newAction.displayOrder,
            };

            this.actions = [...this.actions, newAction];
            await ToDoActions.createForMineArea(command);

            EventBus.$emit(Events.ToastSuccess, 'Task created');

            return newAction;
        },
        async createDepartmentToDoAction(departmentId: string, name: string, description: string){
            const newAction: ClientToDoAction = await this.createNewClientToDoAction(this.mineAreaId, departmentId, name, description);

            // Add a new to-do action to the department
            const command: CreateDepartmentToDoActionCommand = {
                _type: 'CreateDepartmentToDoActionCommand',
                id: newAction.id,
                departmentId: departmentId,
                name: name,
                description: description,
                displayOrder: newAction.displayOrder,
            };

            this.actions = [...this.actions, newAction];
            await ToDoActions.create(command);

            EventBus.$emit(Events.ToastSuccess, 'Task created');

            return newAction;
        },
        async createNewClientToDoAction(mineAreaId: string, departmentId: string | null, name: string, description: string): Promise<ClientToDoAction> {
            const user = await UserStore.getCurrentUser();
            const id = uuidv4();
            return {
                    id: id,
                    name: name,
                    displayOrder: (_.max(this.actions.map(x => x.displayOrder)) ?? 0) + 1,
                    departmentId: departmentId,
                    mineAreaId: this.mineAreaId,
                    description: description,
                    createdBy: user!.name,
                    status: ToDoActionStatus.Open,
                    completedAtUTC: null,
                    startedAtUTC: null,
                    createdAtUTC: dayjs.utc(),
                    comments: [],
              };
        },
        async deleteToDoAction(id: string) {
            await ToDoActions.delete(id);
            this.actions = this.actions.filter(x=>x.id !== id);

            EventBus.$emit(Events.ToastWarning, 'Task deleted');
        },
        async updateToDoAction(id: string, name: string, description: string){
            const actionToUpdate = this.actions.find(x=>x.id === id);

            if(actionToUpdate == null)
                throw new Error(`Could not find action with id ${id}`);

            await ToDoActions.update({
                _type: 'UpdateToDoActionCommand',
                id: id,
                name: name,
                description: description,
            });

            this.actions = [...this.actions.filter(x=>x.id !== id), {
                ...actionToUpdate,
                name: name,
                description: description,
            }];
        },
        async addComment(id: string, comment: string){
            const action: ClientToDoAction | undefined = this.actions.find(x=>x.id === id);
            if(action == null)
                throw new Error(`Could not find action with id ${id}`);

            const commentNewId = uuidv4();

            await ToDoActions.addComment({
                _type: 'AddCommentToToDoActionCommand',
                id: commentNewId,
                toDoActionId: id,
                comment: comment,
                isOfflineReplay: false,
                attemptedAt: new Date(),
            });
            const newComment: ClientToDoActionComment = {
                id: commentNewId,
                comment: comment,
                madeBy: (await UserStore.getCurrentUser())!.name,
                madeAtUTC: dayjs.utc(),
            };

            this.actions = [...this.actions.filter(x=>x.id !== id), {
                ...action,
                comments: [...action.comments, newComment]
            }];
        },
        async removeComment(id: string, commentId: string){
            const action: ClientToDoAction | undefined = this.actions.find(x=>x.id === id);
            if(action == null)
                throw new Error(`Could not find action with id ${id}`);

                await ToDoActions.removeComment(id, commentId);

                this.actions = [...this.actions.filter(x=>x.id !== id), {
                    ...action,
                    comments: action.comments.filter(x=>x.id !== commentId)
                }];
        },
        async markActionCompleted(id: string) {
            const action: ClientToDoAction | undefined = this.actions.find(x=>x.id === id);
            if(action == null)
                throw new Error(`Could not find action with id ${id}`);

            if(action.status !== ToDoActionStatus.InProgress)
                return;

            await ToDoActions.markCompleted(id);

            this.actions = [...this.actions.filter(x=>x.id !== id), {
                ...action,
                status: ToDoActionStatus.Completed,
                completedAtUTC: dayjs.utc(),
            }];

            EventBus.$emit(Events.ToastSuccess, 'Task completed');
        },
        async markActionStarted(id: string) {
            const action: ClientToDoAction | undefined = this.actions.find(x=>x.id === id);
            if(action == null)
                throw new Error(`Could not find action with id ${id}`);

            if(action.status !== ToDoActionStatus.Open)
                return;

            await ToDoActions.markInProgress(id);

            this.actions = [...this.actions.filter(x=>x.id !== id), {
                ...action,
                status: ToDoActionStatus.InProgress,
                startedAtUTC: dayjs.utc()
            }];

            EventBus.$emit(Events.ToastSuccess, 'Task started');
        },
        async markActionNotCompleted(id: string) {
            const action: ClientToDoAction | undefined = this.actions.find(x=>x.id === id);
            if(action == null)
                throw new Error(`Could not find action with id ${id}`);

            if(action.status !== ToDoActionStatus.Completed)
                return;

            await ToDoActions.undoCompletion(id);

            this.actions = [...this.actions.filter(x=>x.id !== id), {
                ...action,
                status: ToDoActionStatus.Open,
                startedAtUTC: null,
                completedAtUTC: null,
            }];

            EventBus.$emit(Events.ToastWarning, 'Task completion undone');
        }
    }
});