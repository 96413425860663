import { GenericDataApi } from '@/lib/data/GenericDataApi';
import { ReducedLevel } from '@/models/api/Data/reduced-level';
import { AxiosError } from 'axios';
import AppHttp from '../AppHttp';
import Errors from '../Errors';

export class ReducedLevels extends GenericDataApi<ReducedLevel> {
    constructor() {
        super('ReducedLevels');
    }

    public async getByMineArea(mineAreaId: string) {
        const response = await AppHttp.get(`${this.resource}/GetForMineArea?mineAreaId=${mineAreaId}`);

        const data = response ? response.data : null;
        return data;
    }

    public async export(mineAreaId: string) {
        const response = await AppHttp.get(`${this.resource}/export/${mineAreaId}`);
        const data = response ? response.data : null;
        return data;
    }

    public async import(mineAreaId: string, file: any) {
        const response = await AppHttp.postWithFiles(`${this.resource}/import/${mineAreaId}`, {}, [file]);
        const data = response ? response.data : null;
        return data;
    }
}

export default new ReducedLevels();
