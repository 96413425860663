<template>
    <screen :showSearchbar="showSearchbar" @searchChanged="onSearchChange" @showFilter="onShowFilter" :show-planning-view-type-toggle="showPlanningViewTypeToggle" :filters-active="filtersActive" @planningViewTypeChanged="onPlanningViewTypeChanged" :isOnline="isOnline">
        <template slot="extensionBarLeft">
            <v-btn icon v-if="leftDrawer" @click="toggleLeft" :key="'leftDrawerToggle'">
                <v-icon>mdi-dock-left</v-icon>
            </v-btn>
            <v-menu
                v-if="showFrequentActions"
                v-model="frequentActionsMenu"
                :close-on-content-click="false"
                offset-y
                :key="'frequentActionsMenu'"
            >
                <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                               icon
                                v-bind="attrs"
                                v-on="{ ...tooltip, ...menu }"
                            >
                                <v-icon>mdi-table-cog</v-icon>
                            </v-btn>
                        </template>
                        <span>Configure Board</span>
                    </v-tooltip>
                </template>
                <v-card>
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Quick Actions</v-list-item-title>
                                <v-list-item-subtitle>Frequently Taken Actions</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-list>
                        <v-list-item>
                            <v-list-item-action>
                                <v-switch
                                    :input-value="showEmptyLocations"
                                    @change="setShowEmptyLocations"
                                    color="purple"
                                ></v-switch>
                            </v-list-item-action>
                            <v-list-item-title>Show inactive {{ 'headings' | term }}</v-list-item-title>
                        </v-list-item>
                    </v-list>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            text
                            @click="frequentActionsMenu = false"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>
        </template>
        <template slot="extensionBarCentre">
            <v-card class='pa-1' v-if='stats'>
                <span class='font-weight-light'>{{ stats.groupTitle }}</span>
                <shift-utilisation-figure
                    v-for="utilFigure of stats.utilisationFigures"
                    :key="utilFigure.shortTitle"
                    :utilisation-label='utilFigure.shortTitle'
                    :utilisation-percentage='utilFigure.utilisationPercentage'
                    :change-from-previous='utilFigure.changeFromPrevious' >
                    <template #tooltip>
                        {{ utilFigure.helpText }}
                    </template>
                </shift-utilisation-figure>
            </v-card>
        </template>
        <template slot="appBarCenter">
            <shift-selector />
        </template>
        <template slot="screenToolPanel">
            <v-tooltip key="fullDayShiftViewButton" v-if="showFullDayShiftViewToggle" bottom>
                <template #activator="{ on }">
                    <div class="d-inline-block" v-on="on">
                        <v-btn text icon @click="onToggleFullDayShiftView" :disabled="showFullDayShiftViewToggleDisabled">
                            <v-icon>{{ fullDayShiftViewToggle ? 'mdi-calendar-clock' : 'mdi-calendar-blank' }}</v-icon>
                        </v-btn>
                    </div>
                </template>
                <span v-if="showFullDayShiftViewToggleDisabled">No next shift planned</span>
                <span v-else-if="!fullDayShiftViewToggle">24-Hour View</span>
                <span v-else>Single Shift View</span>
            </v-tooltip>
            <v-chip key="ringWarningsButton" outlined class="ml-1 headerstats" v-if="ringWarningsCount" @click="onShowRingWarnings" color="yellow">
                <v-icon small left>mdi-alert</v-icon>
                {{ ringWarningsCount }}
                <v-icon small right>mdi-crosshairs-gps</v-icon>
            </v-chip>
            <v-chip key="equipmentWarnings" @click="onShowEquipmentWarnings" outlined class="ml-1 headerstats" v-if="equipmentWarningsCount" :color="equipmentWarningsCount ? 'yellow' : 'default'">
                <v-icon v-if="equipmentWarningsCount" small left>mdi-alert</v-icon>
                <span class="mr-3">{{ equipmentWarningsCount }}</span>
                <v-icon small right>mdi-truck</v-icon>
            </v-chip>
            <v-btn key="addActualsButton" text icon v-if="showAddActuals" @click="onShowAddActuals">
                <v-icon>mdi-scale</v-icon>
            </v-btn>
            <v-btn key="refreshModeButton" text icon v-if="showRefreshMode" @click="onRefreshMode">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn key="showEquipmentButton" text icon v-if="showEquipment" @click="onShowEquipment">
                <v-icon>mdi-truck</v-icon>
            </v-btn>
            <v-tooltip key="manualWeekTasksButton" v-if="showManualWeekTasks" bottom>
                <template #activator="{ on }">
                    <v-btn text icon @click="onShowManualWeekTasks" v-on="on">
                        <v-icon>mdi-view-agenda-outline</v-icon>
                    </v-btn>
                </template>
                <span>Manual Planning</span>
            </v-tooltip>

            <v-menu key="printingMenu" offset-y v-model="printToggle" dark>
                <template v-slot:activator="{ on }">
                    <v-btn style="height: 48px; min-width: auto" :disabled="!isOnline" class="pa-0 ml-2" v-on="on" text>
                        <v-icon color="white">mdi-printer</v-icon>
                        <v-icon small color="white">mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item class="px-5 py-2" title="Print Pack" @click="printEvent()" v-if="managedPrint"
                        >Print {{ managedPrintName }}</v-list-item
                    >
                    <v-list-item class="px-5 py-2" title="Print Screen" @click="printWindow()"
                        >Print Screen</v-list-item
                    >
                    <v-list-item class="px-5 py-2" title="Screenshot Screen" @click="screenshot()"
                        >Screenshot Screen</v-list-item
                    >
                    <v-list-item class="px-5 py-2" title="Variance Report" @click="viewReport()" v-if="managedPrint"
                        >Variance Report</v-list-item
                    >
                </v-list>
            </v-menu>
            <v-menu key="resourceToggleMenu" offset-y v-if="showResourceToggle" v-model="resourceToggle" dark>
                <template v-slot:activator="{ on }">
                    <v-btn style="height: 48px; min-width: auto" class="pa-0 ml-2" v-on="on" text>
                        <v-icon v-if="resourceType == 'equip'">mdi-truck</v-icon>
                        <v-icon v-if="resourceType == 'crew'">mdi-account</v-icon>
                        <v-icon small color="white">mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        class="px-5 py-2"
                        color="primary"
                        title="Equipment"
                        @click="changeResourceView('equip')"
                    >
                        <v-icon left :color="resourceType == 'equip' ? 'primary' : 'white'">mdi-truck</v-icon
                        >Equipment
                    </v-list-item>
                    <v-list-item class="px-5 py-2" color="primary" title="Crew" @click="changeResourceView('crew')">
                        <v-icon left :color="resourceType == 'crew' ? 'primary' : 'white'">mdi-account</v-icon>Crew
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
        <template v-slot:navigation="{ selectedDepartment, selectedDepartmentMenuOptions, screenSize }">
            <navigation-menu :disabled="!isOnline" :selectedDepartment="selectedDepartment" :selected-department-menu-options="selectedDepartmentMenuOptions" :showTitle="screenSize != 's'"/>
        </template>
        <router-view :key="$route.path" />
        <v-speed-dial v-if="false">
            <div>To force speed dial to be included</div>
        </v-speed-dial>
    </screen>
</template>

<script>
import { EventBus, Events } from '@/lib/EventBus';
import CurrentShiftStore from '@/lib/stores/CurrentShiftStore';
import domtoimage from 'dom-to-image';
import dayjs from 'dayjs';
import DownloadHelper from '@/lib/DownloadHelper';
import Screen from '@/templates/Screen.vue';
import NavigationMenu from '@/components/Shared/NavigationMenu.vue';
import { useSystemStore } from '@/lib/stores/SystemStore';
import { mapActions, mapState } from 'pinia';
import { UserNotificationSubject } from '@/models/api';
import ShiftUtilisationFigure from '@/components/ShiftBoard/SubComponents/ShiftUtilisationFigure.vue';
import { useBoardOptions } from '@/lib/stores/BoardOptionsStore';

export default {
    components: { ShiftUtilisationFigure, Screen, NavigationMenu },
    data() {
        return {
            departments: null,
            currentDepartments: [],
            leftDrawer: false,
            showEquipment: false,
            showResourceToggle: false,
            showManualWeekTasks: false,
            showFullDayShiftViewToggle: false,
            showFullDayShiftViewToggleDisabled: false,
            showFrequentActions: false,
            fullDayShiftViewToggle: false,
            shiftsPerDay: 1,
            resourceToggle: false,
            printToggle: false,
            showRefreshMode: false,
            showSearchbar: false,
            filtersActive: false,
            showPlanningViewTypeToggle: false,
            showAddActuals: false,
            resourceType: null,
            stats: null,
            managedPrint: false,
            managedPrintName: '',
            taskWarnings: null,
            fav: false,
            frequentActionsMenu: false,
        };
    },
    computed: {
        ...mapState(useSystemStore, ['isOnline']),
        ...mapState(useBoardOptions, ['showEmptyLocations']),
        equipmentWarningsCount() {
            return this.taskWarnings?.find(w=>w.source === UserNotificationSubject.Equipment)?.count;
        },
        ringWarningsCount() {
            return this.taskWarnings?.find(w=>w.source === UserNotificationSubject.RingRules)?.count;
        },
    },
    async created() {
        EventBus.$on(Events.ShowFeature, this.onShowFeature);
        EventBus.$on(Events.ShowLeftDrawerButton, this.showLeftDrawerButton);
        EventBus.$on(Events.ShowStats, this.showStats);
        EventBus.$on(Events.ShowFiltersActive, this.showFiltersActive);
        EventBus.$on(Events.ChangeResourceView, this.onChangeResourceView);
        EventBus.$on(Events.ManagedPrint, this.onManagedPrint);
        EventBus.$on(Events.ShiftSelected, this.onShiftChanged);
        EventBus.$on(Events.ShowTaskWarningsToggle, this.onShowTaskWarningsToggle);
        EventBus.$on(Events.ShowFullDayShiftViewToggle, this.onShowFullDayShiftViewToggle);

        if (
            this.$route.name.indexOf('default') == 0 &&
            CurrentShiftStore.selectedDate &&
            CurrentShiftStore.selectedShiftNumber &&
            CurrentShiftStore.selectedDepartment
        ) {
            this.$router.push({ name: `${CurrentShiftStore.selectedBoardType}board`, params: this.getParams() });
        }
    },
    destroyed() {
        EventBus.$off(Events.ShowFeature, this.onShowFeature);
        EventBus.$off(Events.ShowLeftDrawerButton, this.showLeftDrawerButton);
        EventBus.$off(Events.ShowStats, this.showStats);
        EventBus.$off(Events.ShowFiltersActive, this.showFiltersActive);
        EventBus.$off(Events.ManagedPrint, this.onManagedPrint);
        EventBus.$off(Events.ChangeResourceView, this.onChangeResourceView);
        EventBus.$off(Events.ShiftSelected, this.onShiftChanged);
        EventBus.$off(Events.ShowTaskWarningsToggle, this.onShowTaskWarningsToggle);
    },
    methods: {
        ...mapActions(useBoardOptions, ['setShowEmptyLocations']),
        onShowFeature(feature, show) {
            this[`show${feature}`] = show;
        },
        onShowEquipment() {
            EventBus.$emit(Events.ToggleEquipment);
        },
        onRefreshMode() {
            EventBus.$emit(Events.ToggleRefreshMode);
        },
        onShowManualWeekTasks() {
            EventBus.$emit(Events.ToggleManualWeekTasks);
        },
        onShowRingWarnings() {
            EventBus.$emit(Events.ShowWarnings, UserNotificationSubject.RingRules);
        },
        onShowEquipmentWarnings() {
            EventBus.$emit(Events.ShowWarnings, UserNotificationSubject.Equipment);
        },
        onShowTaskWarningsToggle(taskWarnings) {
            this.taskWarnings = taskWarnings;
        },
        onShowAddActuals(){
            EventBus.$emit(Events.ShowAddActualsScreen);
        },
        onToggleFullDayShiftView() {
            this.fullDayShiftViewToggle = !this.fullDayShiftViewToggle;

            EventBus.$emit(Events.ToggleFullDayShiftView, this.fullDayShiftViewToggle);
        },
        toggleLeft() {
            EventBus.$emit(Events.LeftDrawer);
        },
        showLeftDrawerButton(show) {
            this.leftDrawer = show;
        },
        showStats(stats) {
            this.stats = stats;
        },
        showFiltersActive(active) {
            this.filtersActive = active;
        },
        onShowFullDayShiftViewToggle(canShowMultiShiftView, toggle, shiftsPerDay) {
            if(toggle == null){
                this.showFullDayShiftViewToggle = false;
                this.showFullDayShiftViewToggleDisabled = false;
                return;
            }

            this.showFullDayShiftViewToggleDisabled = !canShowMultiShiftView;
            this.shiftsPerDay = shiftsPerDay;
            this.showFullDayShiftViewToggle = true;
            this.fullDayShiftViewToggle = toggle;
        },
        getParams() {
            return {
                mine: CurrentShiftStore.selectedMineArea,
                date: CurrentShiftStore.selectedDate,
                shift: CurrentShiftStore.selectedShiftNumber,
                dept: CurrentShiftStore.selectedDepartment,
            };
        },
        changeResourceView(type) {
            EventBus.$emit(Events.ChangeResourceView, type);
        },
        onChangeResourceView(type) {
            this.resourceType = type;
        },
        onManagedPrint(managed, name) {
            this.managedPrint = managed;
            this.managedPrintName = name || 'Pack';
        },
        printWindow() {
            this.printToggle = false;
            setTimeout(() => window.print(), 100);
        },
        screenshot() {
            this.printToggle = false;

            setTimeout(() => {
                domtoimage
                    .toBlob(document.body)
                    .then(function (blob) {
                        var d = dayjs(new Date()).format('yyyy-MM-DD-HH-mm-ss');
                        DownloadHelper.download('ordo-screenshot-' + d, blob);
                    })
                    .catch(function (error) {
                        this.$error('oops, something went wrong!', error);
                    });
            }, 300);
        },
        printEvent() {
            this.printToggle = false;
            EventBus.$emit(Events.PrintClicked);
        },
        viewReport() {
            this.printToggle = false;
            EventBus.$emit(Events.ReportClicked);
        },
        onSearchChange(newValue) {
            EventBus.$emit(Events.SearchChanged, newValue);
        },
        onPlanningViewTypeChanged(newValue) {
            EventBus.$emit(Events.TogglePlanningViewType, newValue);
        },
        onShowFilter(){
            EventBus.$emit(Events.ShowSearchFilter);
        },
        onShiftChanged(newValue) {
            EventBus.$emit(Events.ClearSearchbar);
        },
    },
};
</script>
<style lang="scss" scoped>
.headerstats {
    min-width: 43pt;
}
</style>
