<template>
    <div>
        <print-window ref="printwindow"></print-window>
        <standard-dialog v-model='dialog' :max-width="500">
            <template v-slot:title>
                Print Shift Packs
            </template>
            <template v-slot:content>
                <v-row>
                    <v-col>
                        <v-select
                            label="Source"
                            v-model="selectedSnapshot"
                            :items="snapshots"
                            item-text="name"
                            item-value="id"
                        >
                            <template v-slot:item="{ item }">
                                {{ item.name }}
                                <v-icon class="ml-1" small color="primary" v-if="item.isPublish">mdi-cloud-check-variant</v-icon>
                                <v-spacer></v-spacer>
                                <v-chip small v-if="item.snapshottedAt">{{
                                        item.snapshottedAt | formatDate(dateFormat)
                                    }}</v-chip>
                            </template>
                            <template v-slot:selection="{ item }">
                                {{ item.name }}
                                <v-chip small v-if="item.snapshottedAt">
                                    {{ item.snapshottedAt | formatDate(dateFormat) }}
                                </v-chip>
                            </template>
                            <template v-if="snapshots.length > 1">
                                <v-divider></v-divider>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn color="primary" @click="performPrintPackA">Shift Pack</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn color="primary" @click="performPrintPackCompressed">
                            Compressed Shift Pack
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn color="primary" @click="performPrintCrew">Crew Pack</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn color="primary" @click="performPrintLocations">Location Details</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn color="primary" @click="downloadCSV">Download CSV</v-btn>
                    </v-col>
                </v-row>
                <v-divider class="mt-5 mb-6"></v-divider>
                <div class="d-flex">
                    <v-btn color="primary" @click="performPrintBlank">Blank Shift Plan</v-btn>
                </div>
            </template>
            <template v-slot:actions>
                <v-spacer></v-spacer>
                <v-btn @click="dialog = false">Cancel</v-btn>
            </template>
        </standard-dialog>
        <confirm ref="publishConfirm" />
    </div>
</template>
<script>
import DownloadHelper from '@/lib/DownloadHelper';
import Shift from '@/lib/data/Shift';
import { EventBus, Events } from '@/lib/EventBus';
import { DateFormat } from '@/plugins/dates';
import Account from '@/lib/data/Account';
import Tenants from '@/lib/Tenants';

const CURRENT_PLAN_SNAPSHOT_ID = "Current";

export default {
    props: ['shiftId', 'departmentName', 'departmentId', 'date', 'shift'],
    data() {
        return {
            dialog: false,
            snapshots: [],
            selectedSnapshot: null,
            dateFormat: DateFormat.DefaultDateTimeDisplay
        };
    },
    async created() {
        EventBus.$on(Events.PrintClicked, this.print);
    },
    destroyed() {
        EventBus.$off(Events.PrintClicked, this.print);
    },
    methods: {
        async loadData() {
            this.snapshots = await Shift.getShiftSnapshots(this.shiftId);
            this.snapshots = this.snapshots || [];
            const current = { id: CURRENT_PLAN_SNAPSHOT_ID, name: 'Current Plan', isPublish: false, snapshottedAt: null };
            var baselines = [current];
            if (this.snapshots && this.snapshots.length > 0) {
                // API has snapshots already ordered by date decending
                const publishedMostRecently = this.snapshots.find(s => s.isPublish);
                baselines = publishedMostRecently
                    ? this.snapshots.filter(s => s.id !== publishedMostRecently.id)
                    : this.snapshots;
                baselines.unshift({ divider: true });
                baselines.unshift(current);
                if (publishedMostRecently) baselines.unshift(publishedMostRecently);
            }
            this.snapshots = baselines;
            this.selectedSnapshot = this.snapshots[0].id;
        },
        async print() {
            this.dialog = true;
            await this.loadData();
        },
        async performPrintPackA() {
            await this.performPrintPack('ShiftPackA');
        },
        async performPrintPackCompressed() {
            await this.performPrintPack('ShiftPackC');
        },
        async performPrintPack(printPackName){
            this.dialog = false;
            var snapshotId = this.selectedSnapshot;
            const url = `/Print/${Tenants.GetCurrentTenant()}/Pack/${printPackName}/${this.shiftId}/${snapshotId}/${printPackName}-${this.fileName}.pdf`;

            if(snapshotId === CURRENT_PLAN_SNAPSHOT_ID) {
                if(
                    await this.$refs.publishConfirm.open('Publish Shift?', 'Would you like to publish this shift as a baseline?', { resolveText: 'Yes', rejectText: 'No' })
                ) {
                    Shift.publishShiftSnapshot({
                        _type: 'PublishShiftSnapshotCommand',
                        departmentShiftId: this.shiftId,
                    }).catch(()=>EventBus.$emit(Events.ToastError, 'Failed to publish shift.')).then(()=>
                    {
                        EventBus.$emit(Events.ShiftPublished);
                        EventBus.$emit(Events.ToastSuccess,'Published shift as baseline.');
                    });
                }
            }

            this.$refs.printwindow.show(url);
        },
        performPrintPackB() {
            this.dialog = false;
            var snapshotId = this.selectedSnapshot;
            const url = `/Print/${Tenants.GetCurrentTenant()}/Pack/ShiftPackB/${this.shiftId}/${snapshotId}/ShiftPackB-${this.fileName}.pdf`;
            this.$refs.printwindow.show(url);
        },
        performPrintShiftPlanOperator() {
            this.dialog = false;
            var snapshotId = this.selectedSnapshot;
            const url = `/Print/${Tenants.GetCurrentTenant()}/Pack/ShiftPlanOperator/${this.shiftId}/${snapshotId}/ShiftPlanOperator-${this.fileName}.pdf`;
            this.$refs.printwindow.show(url);
        },
        performPrintCrew() {
            this.dialog = false;
            var snapshotId = this.selectedSnapshot;
            const url = `/Print/${Tenants.GetCurrentTenant()}/Pack/Crew/${this.shiftId}/${snapshotId}/Crew-${this.fileName}.pdf`;
            this.$refs.printwindow.show(url);
        },
        performPrintLocations() {
            this.dialog = false;
            var snapshotId = this.selectedSnapshot;
            const url = `/Print/${Tenants.GetCurrentTenant()}/Pack/Locations/${this.shiftId}/${snapshotId}/Locations-${this.fileName}.pdf`;
            this.$refs.printwindow.show(url);
        },
        performPrintBlank() {
            this.dialog = false;
            const url = `/Print/${Tenants.GetCurrentTenant()}/Pack/Plan/${this.departmentId}/BlankPlan-${this.safeDeptName(
                this.departmentName
            )}.pdf`;
            this.$refs.printwindow.show(url);
        },
        safeDeptName(name) {
            return name.replace(/[/\\?%*:|"<>]/g, '');
        },
        async downloadCSV() {
            var csv = await Shift.csv(this.shiftId, this.selectedSnapshot.id);
            const filename = `Shift-${this.fileName}.csv`;
            DownloadHelper.download(filename, DownloadHelper.makeBlobFromFileString(csv));
            this.dialog = false;
        }
    },
    computed: {
        fileName() {
            const snapshot = this.snapshots.find(s => s.id === this.selectedSnapshot);
            return `${this.safeDeptName(this.departmentName)}-${this.date}-${this.shift}-${snapshot.name}`;
        }
    }
};
</script>
