export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

export function enumOptions<O extends object, K extends keyof O = keyof O>(
    obj: O,
    idToName: (id: Number) => string
): { id: number; name: string }[] {
    return Object.keys(obj)
        .filter(k => !isNaN(Number(k)))
        .map(k => Number(k))
        .map(k => ({ id: k, name: idToName(k) }));
}

export function orderedEnumOptions<O extends object, K extends keyof O = keyof O>(
    obj: O,
    idToName: (id: Number) => string,
    idToOrder: (id: Number) => number
): { id: number; name: string, order: number }[] {
    return Object.keys(obj)
        .filter(k => !isNaN(Number(k)))
        .map(k => Number(k))
        .map(k => ({ id: k, name: idToName(k), order: idToOrder(k) }))
        .sort((a, b) => a.order - b.order);
}