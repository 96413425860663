var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('print-window',{ref:"printwindow"}),_c('standard-dialog',{attrs:{"max-width":500,"isLoading":_vm.isContentLoading,"loaderName":"Shift Variance Report","hasError":!_vm.snapshotsPreviousShift || _vm.snapshotsPreviousShift.length <= 0},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(!_vm.snapshotsPreviousShift || _vm.snapshotsPreviousShift.length == 0 ? '' : 'Compare Last 24Hr Actuals to Published Plan')+" ")]},proxy:true},{key:"content",fn:function(){return [_c('v-row',[_c('v-col',[(_vm.snapshotsCurrentShift && _vm.snapshotsCurrentShift.length > 0)?_c('v-select',{attrs:{"label":"This shift published baseline","items":_vm.snapshotsCurrentShift,"item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(item.isPublish)?_c('v-icon',{staticClass:"ml-1",attrs:{"small":"","color":"primary"}},[_vm._v("mdi-cloud-check-variant")]):_vm._e(),_c('v-spacer'),(item.snapshottedAt)?_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(_vm._f("formatDate")(item.snapshottedAt,_vm.dateFormat)))]):_vm._e()]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(item.snapshottedAt)?_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.snapshottedAt,_vm.dateFormat))+" ")]):_vm._e()]}}],null,false,119072640),model:{value:(_vm.selectedSnapshotCurrentShift),callback:function ($$v) {_vm.selectedSnapshotCurrentShift=$$v},expression:"selectedSnapshotCurrentShift"}},[(_vm.selectedSnapshotCurrentShift && _vm.selectedSnapshotCurrentShift.length > 1)?[_c('v-divider')]:_vm._e()],2):_vm._e()],1)],1),_c('v-row',[_c('v-col',[(_vm.selectedSnapshotPreviousShift && _vm.selectedSnapshotPreviousShift.length > 0)?_c('v-select',{attrs:{"label":"Previous shift published baseline","items":_vm.snapshotsPreviousShift,"item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(item.isPublish)?_c('v-icon',{staticClass:"ml-1",attrs:{"small":"","color":"primary"}},[_vm._v("mdi-cloud-check-variant")]):_vm._e(),_c('v-spacer'),(item.snapshottedAt)?_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(_vm._f("formatDate")(item.snapshottedAt,_vm.dateFormat)))]):_vm._e()]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(item.snapshottedAt)?_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.snapshottedAt,_vm.dateFormat))+" ")]):_vm._e()]}}],null,false,119072640),model:{value:(_vm.selectedSnapshotPreviousShift),callback:function ($$v) {_vm.selectedSnapshotPreviousShift=$$v},expression:"selectedSnapshotPreviousShift"}},[(_vm.selectedSnapshotCurrentShift && _vm.selectedSnapshotCurrentShift.length > 1)?[_c('v-divider')]:_vm._e()],2):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.performPrintVarianceReport}},[_vm._v("Variance Report")])],1)],1)]},proxy:true},{key:"actions",fn:function(){return [_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")])]},proxy:true},{key:"error-content",fn:function(){return [_c('v-alert',{staticClass:"white--text mt-4",attrs:{"type":"warning"}},[_vm._v("Baseline For Previous Shift Not Found")])]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }