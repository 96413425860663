<template>
    <v-navigation-drawer v-model="internalModel" absolute temporary :width='null'>
        <v-row class="fill-height" no-gutters>
            <v-col class="fill-height" style="width: 200px; flex-grow: unset; flex-basis: 200px;">
                <v-list>
                    <v-subheader>
                        Baselines
                        <v-spacer></v-spacer>
                        <v-access-control :roles="[AppRoles.WeekPlanner]" noPermissionLabel="Add" :offlineAccess="true">
                            <v-menu offset-y v-model="addDialog" :close-on-content-click="false">
                                <template v-slot:activator="{ on }">
                                    <v-btn color="primary" small v-on="on">Add</v-btn>
                                </template>
                                <v-card class="px-5 py-3">
                                    <v-form ref="form" :lazy-validation="true">
                                        <v-text-field
                                            label="Name"
                                            v-model="newSnapshotName"
                                            :rules="[$rules.required(), $rules.maxLength(50)]"
                                            counter="50"
                                        ></v-text-field>
                                        <v-btn color="primary" small @click="createSnapshot">Save</v-btn>
                                    </v-form>
                                </v-card>
                            </v-menu>
                        </v-access-control>
                    </v-subheader>
                    <v-list-item-group color="primary" v-if="snapshots">
                        <v-list-item
                            two-line
                            v-for="snapshot in snapshots"
                            :key="snapshot.id"
                            @click="selectSnapshot(snapshot)"
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{ snapshot.name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ snapshot.snapshottedAt | formatDateTime }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="selectedSnapshot" @click="selectSnapshot(null)">
                            <v-list-item-content>
                                <v-btn>Stop Showing Baseline</v-btn>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                    <v-subheader v-if="!isFuture && !isTooFarPast">
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" @click="enableHistoricEdit" small>Enable Historic Edit</v-btn>
                        <v-spacer></v-spacer>
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-list-item-group color="primary">
                        <v-subheader>
                            <h3 class='subtitle-1'>Filters and Groups</h3>
                            <v-spacer></v-spacer>
                        </v-subheader>
                        <v-list-item class="clickable" :class="{ active: showFilters }" @click="toggleFilterPane">
                            <v-list-item-content>
                                <v-list-item-title>Filters</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="clickable" :class="{ active: showGroupings }" @click="toggleGroupingPane">
                            <v-list-item-content>
                                <v-list-item-title>Grouping</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="clickable" :class="{ active: showColumnSelection }" @click="toggleColumnSelection">
                            <v-list-item-content>
                                <v-list-item-title>Columns</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
                <confirm ref="confirm"></confirm>
            </v-col>
            <v-col v-show="showFilters" style="width: 350px; max-width: 480px" class="fill-height">
                <v-subheader>
                    <h3 class="subtitle-1">Filters</h3>
                </v-subheader>
                <board-filter-and-sort-drawer-section
                    :location-property-filters="locationPropertyFilters"
                    @removeFilter="onRemoveFilter"
                    @addOrReplaceFilter="onAddOrReplaceSearchFilter"
                ></board-filter-and-sort-drawer-section>
            </v-col>
            <v-col v-if="plan" v-show="showGroupings" style="width: 350px; max-width: 480px" class="fill-height">
                <v-subheader>
                    <h3 class="subtitle-1">Groupings</h3>
                </v-subheader>
                <board-grouping-drawer-section
                    :grouping-options="groupingOptions"
                    :selected-grouping="selectedGrouping.id"
                    @changeGrouping="onChangeGrouping"
                ></board-grouping-drawer-section>
            </v-col>
            <v-col v-if="plan" v-show="showColumnSelection" style="width: 350px; max-width: 480px" class="fill-height">
                <v-subheader>
                    <h3 class="subtitle-1">Columns</h3>
                </v-subheader>
                <board-columns-drawer-section :column-options="columnOptions"
                                              @changeColumnSelection="onChangeColumnSelection"
                >
                </board-columns-drawer-section>
            </v-col>
        </v-row>
    </v-navigation-drawer>
</template>
<script>
import Shift from '@/lib/data/Shift';
import { Roles } from '@/lib/Security';
import BoardFilterAndSortDrawerSection from '@/components/ShiftBoard/Drawers/BoardFilterAndSortDrawerSection.vue';
import BoardGroupingDrawerSection from '@/components/ShiftBoard/Drawers/BoardGroupingDrawerSection.vue';
import { mapState, mapActions } from 'pinia';
import { useWeekBoardColumnManipulation } from '@/lib/stores/BoardManipulation/WeekBoardColumnManipulationStore';
import { GroupingOption } from '@/components/ShiftBoard/Drawers/BoardGroupingDrawerProps';
import BoardColumnsDrawerSection from '@/components/ShiftBoard/Drawers/BoardColumnsDrawerSection.vue';

export default {
    components: { BoardColumnsDrawerSection, BoardGroupingDrawerSection, BoardFilterAndSortDrawerSection },
    props: ['planId', 'plan', 'mineAreaId', 'departmentId', 'date', 'mineTime', 'timezone', 'value'],
    data() {
        return {
            newSnapshotName: '',
            snapshots: null,
            addDialog: false,
            selectedSnapshot: null,
            AppRoles: Roles,
            isFuture: false,
            isTooFarPast: false,
            showFilters: false,
            showGroupings: false,
            showColumnSelection: false,
        };
    },
    async created() {
        await this.loadData();
    },
    computed: {
        ...mapState(useWeekBoardColumnManipulation, ['groupingOptions', 'locationPropertyFilters', 'selectedGrouping', 'manipulationInformation','columnOptions']),
        internalModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        ...mapActions(useWeekBoardColumnManipulation, ['setGrouping','groupByDefault','removeSearchFilter','addSearchFilter','manuallyAddColumn','manuallyRemoveColumn']),
        async loadData() {
            this.newSnapshotName = '';
            this.snapshots = await Shift.getWeekSnapshots(this.planId);
        },
        async createSnapshot() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.addDialog = false;
            this.$refs.form.resetValidation();
            await Shift.makeWeekSnapshot(this.mineAreaId, this.departmentId, this.date, this.newSnapshotName);
            await this.loadData();
        },
        async selectSnapshot(snapshot) {
            if (snapshot == null) {
                this.selectedSnapshot = null;
            } else {
                this.selectedSnapshot = await Shift.getWeekSnapshot(this.planId, snapshot.id);
                var model = JSON.parse(this.selectedSnapshot.snapshotJson);
            }

            this.$emit('snapshotSelected', model);
        },
        onChangeGrouping(selectedGroupingOption) {
            if(selectedGroupingOption == null)
                this.groupByDefault();
            else
                this.setGrouping(selectedGroupingOption);
        },
        onRemoveFilter(filterId) {
            this.removeSearchFilter(filterId);
        },
        onAddOrReplaceSearchFilter(filterId, selectedIds, emptyIncluded) {
            this.addSearchFilter(filterId, selectedIds, emptyIncluded);
        },
        onChangeColumnSelection(columnId, newValue) {
            if(newValue)
                this.manuallyAddColumn(columnId);
            else
                this.manuallyRemoveColumn(columnId);
        },
        async enableHistoricEdit() {
            if (
                await this.$refs.confirm.open(
                    'Allow Movement',
                    'Are you sure you want to move historic cycles? This will affect actuals! You should only use this if a cycle was erroneously moved to the past',
                    { color: 'red' }
                )
            ) {
                this.$emit('editingActuals', true);
            }
        },
        toggleFilterPane() {
            if(this.showFilters){
                this.hideAllSections();
            }  else {
                this.hideAllSections();
                this.showFilters = true;
            }
        },
        toggleGroupingPane() {
            if(this.showGroupings){
                this.hideAllSections();
            }  else {
                this.hideAllSections();
                this.showGroupings = true;
            }
        },
        toggleColumnSelection(){
            if(this.showColumnSelection){
                this.hideAllSections();
            }  else {
                this.hideAllSections();
                this.showColumnSelection = true;
            }
        },
        hideAllSections() {
            this.showFilters = false;
            this.showGroupings = false;
            this.showColumnSelection = false;
        }
    }
};
</script>
