import { render, staticRenderFns } from "./Task.vue?vue&type=template&id=d258e6c4&"
import script from "./Task.vue?vue&type=script&lang=ts&"
export * from "./Task.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VChip,VFadeTransition,VOverlay,VScaleTransition})
