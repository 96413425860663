<template>
    <div>
        <v-container class="pa-0 ma-0 mb-2 shifttbl" v-if="mine">
            <v-row class="header">
                <v-col style="flex-grow:2">{{ $t('heading.shift') }}</v-col>
                <v-col v-for="(day, index) in days" :key="index" style="text-align:center">{{ day.dayLetter }}</v-col>
            </v-row>
            <v-row class="header" v-for="shift in mine.availableShifts" :key="shift.number">
                <v-col style="flex-grow:2">{{ shift.name }}</v-col>
                <v-col v-for="(day, index) in days" :key="index" style="text-align:center">
                    <v-icon small :color="iconColor" v-if="showFor(shift.number, index)">{{ icon }}</v-icon>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<i18n>
{
    "en": {
        "heading.shift": "Shift"
    },
    "es": {
    }
}
</i18n>
<script>
import { DateFormat } from '@/plugins/dates';
import dayjs from 'dayjs';
var dayLetters = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
export default {
    props: ['data', 'mine', 'weekDate', 'editable', 'icon', 'color'],
    data() {
        return {};
    },
    methods: {
        showFor(shiftNumber, index) {
            return this.data[shiftNumber - 1].days[index];
        }
    },
    computed: {
        iconColor() {
            return this.color || null;
        },
        days() {
            var firstDay = this.mine.firstDayOfWeek;
            var startDate = dayjs(this.weekDate);
            var days = [];
            for (var i = 0; i < 7; i++) {
                var todayDay = firstDay + i;
                var date = startDate.add(i, 'days');
                if (todayDay > 6) {
                    todayDay = todayDay - 7;
                }

                var dayLetter = dayLetters[todayDay];

                days.push({ todayDay, date, dayLetter });
            }

            return days;
        }
    }
};
</script>
<style lang="scss">
.shifttbl {
    font-size: 9px;
    border-bottom: 1px solid #6663;

    .row {
        margin: 0;
        padding: 0;
    }

    .col {
        padding: 2px 4px;
    }
}
</style>
