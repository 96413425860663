export default [
    ['Actual Physicals Source', 'Actual Physicals Sources'],
    ['Blast Group', 'Blast Groups'],
    ['Blast Packet', 'Blast Packets'],
    ['Crew', 'Crews'],
    ['Cycle', 'Cycles'],
    ['Department', 'Departments'],
    ['Equipment', 'Equipment'],
    ['Equipment Role', 'Equipment Roles'],
    ['Location', 'Locations'],
    ['Material Destination', 'Material Destinations'],
    ['Material Type', 'Material Types'],
    ['Meeting Guide Task', 'Meeting Guide Tasks'],
    ['Mine Area', 'Mine Areas'],
    ['Level', 'Levels'],
    ['Extraction Level', 'Extraction Levels'],
    ['Ring','Rings'],
    ['Staff', 'Staff'],
    ['Staff Role', 'Staff Roles'],
    ['Staff/Crew Role', 'Staff/Crew Roles'],
    ['Stockpile', 'Stockpiles'],
    ['TARP', 'TARPs'],
    ['Task', 'Tasks'],
    ['Task Category', 'Task Categories'],
    ['Term', 'Terms'],
    ['User', 'Users'],
] as string[][];
