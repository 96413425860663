import ClientRowModel from '@/models/client/client-row';
import { ExtendedPropertyInfo, PropertyDefinitionModel } from '@/models/api';
import { LocationPropertyFilterInfo } from '@/models/client/location-property-filter-info';
import { GroupingOption } from '@/components/ShiftBoard/Drawers/BoardGroupingDrawerProps';
import { GroupedClientRow } from '@/lib/stores/BoardManipulation/ShiftBoardColumnManipulationStore';
import { CELL_SIZE } from '@/models/client/cell-sizes';

export const COLUMN_IDS = {
    NULL: '__',
    PRIORITY: '__PRIORITY',
    BOGGING_PRIORITY: '__BOGGING_PRIORITY',
    DRILLING_PRIORITY: '__DRILLING_PRIORITY',
    LEVEL: '__AREA',
    DEPARTMENT: '__DEPARTMENT',
    LOCATION_NAME: '__LOC_NAME',
    STOPE: '__STOPE',
}

export enum SortDirection {
    Ascending,
    Descending
}

export interface SortingDefinition<T> {
    direction: SortDirection;
    columnId: string,
    sortFunc: (a: T[], direction: SortDirection) => T[]
}

export enum ExtraColumnReasonToExist {
    None = 0,
    Grouping = 1,
    Sorting = 1 << 1,
    Filtering = 1 << 2,
    Manual = 1 << 3,
    Default = 1 << 4,
}

export interface ColumnInformation {
    id: string,
    name: string,
    columnNameOverride: string,
    columnSize: CELL_SIZE,
    reasonToExist: ExtraColumnReasonToExist,
}

export interface ExtraColumnInfo<T> extends ColumnInformation {
    selector: (row: T) => string,
}

export function CreateExtendedPropertyExtraColumn<T extends { location: { extendedProperties: ExtendedPropertyInfo[] } | null}>(extendedProperty: PropertyDefinitionModel, reasonToExist: ExtraColumnReasonToExist): ExtraColumnInfo<T> {
    return CreateExtraColumn<T>(extendedProperty.id, extendedProperty.name!, (row: T) => row.location?.extendedProperties.filter(ep=>ep.propertyDefinitionId === extendedProperty.id)[0]?.value ?? '',reasonToExist);
}

export function CreateExtraColumn<T>(id: string, name: string, selector: (row: T)=>string, reasonToExist: ExtraColumnReasonToExist): ExtraColumnInfo<T> {
    return {
        id: id,
        name: name,
        selector: selector,
        columnSize: 'm',
        columnNameOverride: name,
        reasonToExist: reasonToExist
    };
}

export function MergeIntoExtraColumns<T>(extraColumns: ExtraColumnInfo<T>[], newColumn: ExtraColumnInfo<T>): ExtraColumnInfo<T>[] {
    const existingColumn = extraColumns.find(ec=>ec.id === newColumn.id);

    if(existingColumn != null) {
        existingColumn.reasonToExist |= newColumn.reasonToExist;
        return extraColumns;
    } else {
        return [...extraColumns, newColumn];
    }

}

export function RemoveReasonForOtherExtraColumnsToExist<T>(extraColumns: ExtraColumnInfo<T>[], extraColumnIdToPreserve: string, reasonToExist: ExtraColumnReasonToExist): ExtraColumnInfo<T>[] {
    return extraColumns.map(ec=>{
        if(ec.id !== extraColumnIdToPreserve)
            ec.reasonToExist &= ~reasonToExist;
        return ec;
    }).filter(ec=>ec.reasonToExist !== ExtraColumnReasonToExist.None);
}

export function RemoveReasonForExtraColumnsToExist<T>(extraColumns: ExtraColumnInfo<T>[], reasonToExist: ExtraColumnReasonToExist): ExtraColumnInfo<T>[] {
    return extraColumns.map(ec=>{
        ec.reasonToExist &= ~reasonToExist;
        return ec;
    }).filter(ec=>ec.reasonToExist !== ExtraColumnReasonToExist.None);
}

export function RemoveReasonForExtraColumnToExist<T>(extraColumns: ExtraColumnInfo<T>[], extraColumnId: string, reasonToExist: ExtraColumnReasonToExist): ExtraColumnInfo<T>[] {
    const existingColumn = extraColumns.find(ec=>ec.id === extraColumnId);

    if(existingColumn != null) {
        existingColumn.reasonToExist &= ~reasonToExist;

        if(existingColumn.reasonToExist === ExtraColumnReasonToExist.None)
            return extraColumns.filter(ec=>ec.id !== extraColumnId);
    }

    return extraColumns;
}

export function RemoveAllReasonsForExtraColumnToExist<T>(extraColumns: ExtraColumnInfo<T>[], extraColumnId: string): ExtraColumnInfo<T>[] {
    const existingColumn = extraColumns.find(ec=>ec.id === extraColumnId);

    if(existingColumn != null) {
        existingColumn.reasonToExist = ExtraColumnReasonToExist.None;

        if(existingColumn.reasonToExist === ExtraColumnReasonToExist.None)
            return extraColumns.filter(ec=>ec.id !== extraColumnId);
    }

    return extraColumns;
}

export function SimpleSortAscending<T>(a: T, b: T, selector: (row: T)=>string): number {
    const aValue = selector(a);
    const bValue = selector(b);

    if(aValue < bValue) return -1;
    if(aValue > bValue) return 1;
    return 0;
}

export function SimpleSortDescending<T>(a: T, b: T, selector: (row: T)=>string): number {
    return -1 * SimpleSortAscending<T>(a, b, selector);
}

export function SimpleSortAscendingGenerator<T>(selector: (row: T)=>string): (a: T, b: T) => number {
    return (a: T, b: T) => SimpleSortAscending(a, b, selector);
}

export function SimpleSortDescendingGenerator<T>(selector: (row: T)=>string): (a: T, b: T) => number {
    return (a: T, b: T) => SimpleSortDescending(a, b, selector);
}

export interface GroupingInformation<T, V> extends GroupingOption {
    groupingFunc: (rows: T[]) => V[];
}

export function ColumnHasReasonToBeOnBoard<T,V>(columnInformation: BoardPropertyFilterGroupSortInfo<T, V>, reasonOnBoard: ExtraColumnReasonToExist): boolean {
    return (columnInformation.reasonOnBoard & reasonOnBoard) === reasonOnBoard;
}

export function AddReasonToColumn<T,V>(columnInformation: BoardPropertyFilterGroupSortInfo<T, V>, reasonOnBoard: ExtraColumnReasonToExist): BoardPropertyFilterGroupSortInfo<T, V> {
    columnInformation.reasonOnBoard |= reasonOnBoard;
    return columnInformation;
}

export function AddReasonForColumnToBeOnBoard<T, V>(columnInformation: BoardPropertyFilterGroupSortInfo<T, V>[], columnId: string, reasonOnBoard: ExtraColumnReasonToExist): BoardPropertyFilterGroupSortInfo<T, V>[] {
    const existingColumn = columnInformation.find(ec=>ec.id === columnId);

    if(existingColumn != null) {
        existingColumn.reasonOnBoard |= reasonOnBoard;
    }

    return columnInformation;
}

export function RemoveReasonForOtherColumnsToBeOnBoard<T, V>(columnInformation: BoardPropertyFilterGroupSortInfo<T, V>[], extraColumnIdToPreserve: string, reasonOnBoard: ExtraColumnReasonToExist): BoardPropertyFilterGroupSortInfo<T, V>[] {
    return columnInformation.map(ec=>{
        if(ec.id !== extraColumnIdToPreserve)
            ec.reasonOnBoard &= ~reasonOnBoard;
        return ec;
    });
}

export function RemoveReasonForColumnsToBeOnBoard<T, V>(columnInformation: BoardPropertyFilterGroupSortInfo<T, V>[], reasonOnBoard: ExtraColumnReasonToExist): BoardPropertyFilterGroupSortInfo<T, V>[] {
    return columnInformation.map(ec=>({
        ...ec,
        reasonOnBoard: ec.reasonOnBoard & ~reasonOnBoard
    }));
}

export function RemoveReasonForColumnToBeOnBoard<T, V>(columnInformation: BoardPropertyFilterGroupSortInfo<T, V>[], extraColumnId: string, reasonOnBoard: ExtraColumnReasonToExist): BoardPropertyFilterGroupSortInfo<T, V>[] {
    const existingColumn = columnInformation.find(ec=>ec.id === extraColumnId);

    if(existingColumn != null) {
        existingColumn.reasonOnBoard &= ~reasonOnBoard;
    }

    return columnInformation;
}

export function RemoveAllReasonsForColumnToBeOnBoard<T, V>(columnInformation: BoardPropertyFilterGroupSortInfo<T, V>[], extraColumnId: string): BoardPropertyFilterGroupSortInfo<T, V>[] {
    const existingColumn = columnInformation.find(ec=>ec.id === extraColumnId);

    if(existingColumn != null) {
        existingColumn.reasonOnBoard = ExtraColumnReasonToExist.None;
    }

    return columnInformation;
}

export interface BoardPropertyFilterGroupSortInfo<T, V> {
    id: string,
    name: string,
    columnNameOverride?: string | null,
    propertyFilter: LocationPropertyFilterInfo<T> | null,
    groupingDefinition: GroupingInformation<T, V> | null,
    sorting: {
        sortFunc: (rows: V[], direction: SortDirection)=> V[]
    } | null,
    columnSize: CELL_SIZE,
    reasonOnBoard: ExtraColumnReasonToExist,
    displayValueSelector: ((location: T)=>string) | null,
}