/*
    Helpful validation functions that work directly with vuetify's form element validation.
    NOTE: You should turn on lazy validatation in your form e.g. <v-form ref="form" :lazy-validation="true">

    Installation (main.ts): 
        import './plugins/validation';
        Vue.use(VueFragment);

    Usage:
        :rules="[$rules.required(), $rules.maxLength(50)]"

    Or, with custom messages:
        :rules="[$rules.required('Name is required'), $rules.maxLength(50, 'Name must be less than 50 characters')]"

    Conditionally required:
        :rules="[$rules.requiredIf(() => someProp > 10), $rules.maxLength(50)]"

    Can handle async validation:
        (element)
            <v-text-field 
                ref="myfield"
                :rules="[$rules.asyncValidator({component:() => $refs.myfield, message: 'OPTIONALMESSAGE', method: someAsyncValidator})]"
            ></v-text-field>
        (async validation method)
            async someAsyncValidator(val) { return await this.someFunc(val); }
*/

import dayjs from 'dayjs';
import { DateFormat } from './dates';
import Validation from '@/lib/Validation';

const emailRegex = /^[a-zA-Z0-9-_+.]+@[a-zA-Z0-9-_]+[.][a-zA-Z0-9-_.]+$/;
const timeRegex = /^[012]?[0-9]:[0-5][0-9]$/;
const charRegex = /[a-z]/i;
const numRegex = /[0-9]/i;

export interface IAsyncValidator {
    component: any;
    method: any;
    message?: string;
}

export default {
    install(Vue: any) {
        Vue.prototype.$rules = {
            required(message?: string) {
                return (v?: string) => !!v || message || 'Required';
            },
            requiredAllowZero(message?: string) {
                return Validation.requiredAllowZero(message);
            },
            requiredAllowZeroIf(getter: any, message?: string) {
                return Validation.requiredAllowZeroIf(getter, message);
            },
            notNull(message?: string) {
                return (v?: any) => (v != null && v != undefined) || message || 'Required';
            },
            notWhitespace(message?: string) {
                return Validation.notWhitespace(message);
            },
            requiredIf(getter: any, message?: string) {
                return (v?: string) => !getter() || !!v || message || 'Required';
            },
            maxLength(num: number, message?: string) {
                return (v?: string) => (v || '').length <= num || message || `Must be ${num} or less characters`;
            },
            minLength(num: number, message?: string) {
                return (v?: string) => (v || '').length >= num || message || `Must be ${num} or more characters`;
            },
            email(message?: string) {
                return (v?: string) => !v || emailRegex.test(v) || message || `Must be a valid email`;
            },
            time(message?: string) {
                return (v?: string) => !v || timeRegex.test(v) || message || `Must be a valid time (hh:mm)`;
            },
            multipleOf(num: number, message?: string) {
                return (v?: number) => !v || (v || 0) % num == 0 || message || `Increments of ${num}`;
            },
            max(num: number, message?: string) {
                return (v?: number) => (v || 0) <= num || message || `Must be ${num} or less`;
            },
            min(num: number, message?: string) {
                return (v?: number) => (v || 0) >= num || message || `Must be ${num} or more`;
            },
            noMoreDecimalPointsThan(decimalPoints: number, message?: string) {
                return Validation.noMoreDecimalPointsThan(decimalPoints, message);
            },
            unique<T>(potentialClashes: T[], message?: string) {
                return (v?: T) => !v || potentialClashes.findIndex((x) => x == v) === -1 || message || `Must be unique`;
            },
            complex(message?: string) {
                function isComplex(v?: string) {
                    if (!v) return false;
                    if (v.length < 6) return false;
                    if (!numRegex.test(v)) return false;
                    if (!charRegex.test(v)) return false;
                    return true;
                }
                return (v?: string) =>
                    isComplex(v) ||
                    message ||
                    `Must be at least 7 characters and contain at least 1 number and 1 letter`;
            },
            int(message?: string) {
                return (v?: number) => v == Math.floor(v || 0) || message || `Must be a whole number`;
            },
            custom(fn: any, message?: string) {
                return (v?: any) => !fn() || message || 'Invalid';
            },
            before(date?: any, message?: string, dateFormat: string = DateFormat.DefaultDisplay) {
                return (val?: any) => {
                    const endDate = dayjs(date, dateFormat);
                    const startDate = dayjs(val, dateFormat);
                    return (
                        !(startDate.isValid() && endDate.isValid()) ||
                        (startDate as any).isSameOrBefore(endDate) ||
                        message ||
                        `Date must be less than or equal ${endDate.format(dateFormat)}`
                    );
                };
            },
            after(date?: any, message?: string, dateFormat: string = DateFormat.DefaultDisplay) {
                return (val?: any) => {
                    const endDate = dayjs(val, dateFormat);
                    const startDate = dayjs(date, dateFormat);
                    return (
                        !(startDate.isValid() && endDate.isValid()) ||
                        (endDate as any).isSameOrAfter(startDate) ||
                        message ||
                        `Date must be greater than or equal ${startDate.format(dateFormat)}`
                    );
                };
            },

            /**
             * @description Create an async validator e.g. $rules.asyncValidator({component:() => $refs.REFNAME, message: 'OPTIONALMESSAGE', method: someAsyncValidator})
             * @param {IAsyncValidator} validator Model defining async validator behaviour. Validator method: e.g. async someAsyncValidator(val) { return await this.someFunc(val); }
             * @returnType {Function}
             */
            asyncValidator(validator: IAsyncValidator) {
                const message = validator.message || 'Invalid';

                let myValue = true;

                setTimeout(() => {
                    validator.component().rules.push(() => myValue || message);
                }, 1);

                let running = false;
                return (v: any) => {
                    if (running) {
                        return true;
                    }
                    running = true;
                    const runner = async () => {
                        myValue = await validator.method(v);
                        validator.component().validate();
                        running = false;
                    };
                    runner();
                    return true;
                };
            },
        };
    },
};
