export enum TaskErrorType {
    GENERIC_ERROR = 0,
    QUANTITY_TARGET_MISMATCH = 1,
    LOCATION_UNAVAILABLE = 2,
    NOT_IN_EOS = 3,
    BLOCKING_EOS = 4,
    BLOCKING_SOS = 5,
    EQUIPMENT_OVERLAP = 6,
    EQUIPMENT_UNAVAILABLE = 7,
}
