import ClientTaskModel from '@/models/client/client-task';
import { RingRuleEvaluationState, RingRuleEvaluationStatus } from '@/models/api';
import { ConvertTaskTypeToStatus, RING_STATUS_EN } from '@/lib/services/Production/shared';
import _ from 'lodash';
import { AddWarningToTask, FormatTaskQuantity } from '@/lib/services/Task';
import { BlastPacketRingTargetEvaluation } from '@/lib/services/Production/LocationRingStateMachine';
import dayjs from 'dayjs';
import { AffectedAreaSpecification } from '@/lib/stores/HeadingsStore';
import { ProductionValidationTaskModel } from '@/models/client/production-validation-task-model';
import { ClientTaskWarning } from '@/models/client/client-task-warning';

const MESSAGES = {
    STATUS_DOWNGRADE: (incStatus: string,currentStatus: string) => `Cannot ${incStatus} once ring is in state ${currentStatus}.`,
    STATUS_SKIPPED: (incStatus: string, currentStatus: string) => `Cannot ${incStatus} if ring state is ${currentStatus}.`,
    STATUS_INCOMPLETE: (incStatus: string, currentStatus: string) => `Should not start ${incStatus} if ring has not finished ${currentStatus}.`,
    ALREADY_FIRED: 'Ring has already been fired.',
    STATUS_MARKED_COMPLETE: (currentStatus: string) => `${currentStatus} on ring has been marked complete.`,
    STATUS_REACHED_TARGET: (currentStatus: string) => `Ring has already completed target quantity for ${currentStatus}.`,
    TASK_WOULD_EXCEED_TARGET: (incStatus: string, excess: number) => `Would exceed ${incStatus} target by ${FormatTaskQuantity(excess)}.`,
}

const UNIQUE_IDS = {
    STATUS_DOWNGRADE: 'STATUS_DOWNGRADE',
    STATUS_SKIPPED: 'STATUS_SKIPPED',
    STATUS_INCOMPLETE: 'STATUS_INCOMPLETE',
    ALREADY_FIRED: 'ALREADY_FIRED',
    STATUS_MARKED_COMPLETE: 'STATUS_MARKED_COMPLETE',
    STATUS_REACHED_TARGET: 'STATUS_REACHED_TARGET',
    TASK_WOULD_EXCEED_TARGET: 'TASK_WOULD_EXCEED_TARGET',
}

export const RING_STATE_ISSUE_TYPE = 'RING_STATE';

const NO_ERROR_RESULT = {
    isError: false,
} as RingStateValidationResult;

export const RING_STATE_VALIDATION = {
    NO_ERROR_RESULT: NO_ERROR_RESULT,
    MESSAGES: MESSAGES
}

interface RingStateValidationResult {
    isError: boolean,
    uniqueId: string,
    errorMessage?: string,
}

function callWarningAccumulatorIfRequired(task: ProductionValidationTaskModel, validationResult: RingStateValidationResult, warningAdditionFunc: (task: ProductionValidationTaskModel, warning: ClientTaskWarning) => void) {
    if(validationResult.isError)
        warningAdditionFunc(task, {
            message: validationResult.errorMessage!,
            warningCategoryId: `${RING_STATE_ISSUE_TYPE}.${validationResult.uniqueId}`,
            type: RING_STATE_ISSUE_TYPE
        });
}

function unshieldedProjectedToExceedTargetValidator(target: BlastPacketRingTargetEvaluation, task: ProductionValidationTaskModel, incomingStatus: RingRuleEvaluationStatus, currentState: RingRuleEvaluationState): RingStateValidationResult {
    if(incomingStatus === RingRuleEvaluationStatus.Fired || task.ratePerHour == null || task.quantity == null)
        return NO_ERROR_RESULT;

    const existingQuantity = incomingStatus === currentState.ringStatus ? currentState.quantity : 0;
    const targetQuantityPlusTolerance = (1+target.tolerance)*target.targetQuantity;

    const currentStateEndAtAsDayjs = dayjs(currentState.endAt).utc();

    const overlapDuration = currentStateEndAtAsDayjs.isAfter(task.startTime) ? currentStateEndAtAsDayjs.diff(task.startTime, 'minutes') : 0;
    const maximumOverlapDuration = Math.min(overlapDuration, task.durationMinutes);

    if(maximumOverlapDuration === task.durationMinutes)
        return NO_ERROR_RESULT;

    const durationToCalculateFrom = task.durationMinutes - maximumOverlapDuration;

    const taskProRataQuantity = durationToCalculateFrom === task.durationMinutes ? task.quantity : durationToCalculateFrom*task.ratePerHour/60;

    const excessPlannedQuantity = existingQuantity + taskProRataQuantity - targetQuantityPlusTolerance;

    if(excessPlannedQuantity <= 0)
        return NO_ERROR_RESULT;

    if(excessPlannedQuantity > 10)
        return {
            isError: true,
            uniqueId: UNIQUE_IDS.TASK_WOULD_EXCEED_TARGET,
            errorMessage: MESSAGES.TASK_WOULD_EXCEED_TARGET(RING_STATUS_EN.PRESENT_TENSE_PROGRESSIVE[incomingStatus], excessPlannedQuantity)
        };

    return NO_ERROR_RESULT;
}

export const ProjectedToExceedTargetValidator = _.curry(incomingTaskHasStatusShield)(unshieldedProjectedToExceedTargetValidator);

function unshieldedStatusIncompleteValidator(target: BlastPacketRingTargetEvaluation, task: ProductionValidationTaskModel, incomingStatus: RingRuleEvaluationStatus, currentState: RingRuleEvaluationState): RingStateValidationResult {
    if(incomingStatus - currentState.ringStatus! === 1) {
        if(!currentState.markedCompleted && !currentState.reachedTarget)
            return {
                isError: true,
                uniqueId: UNIQUE_IDS.STATUS_INCOMPLETE,
                errorMessage: MESSAGES.STATUS_INCOMPLETE(RING_STATUS_EN.PRESENT_TENSE_PROGRESSIVE[incomingStatus], RING_STATUS_EN.PRESENT_TENSE_PROGRESSIVE[currentState.ringStatus!])
            }
    }

    return NO_ERROR_RESULT;
}

export const StatusIncompleteValidator = _.curry(incomingTaskHasStatusShield)(unshieldedStatusIncompleteValidator);

function unshieldedStatusDowngradeValidator(target: BlastPacketRingTargetEvaluation, task: ProductionValidationTaskModel, incomingStatus: RingRuleEvaluationStatus, currentState: RingRuleEvaluationState): RingStateValidationResult {
    if(incomingStatus < currentState.ringStatus!)
        return {
            isError: true,
            uniqueId: UNIQUE_IDS.STATUS_DOWNGRADE,
            errorMessage: MESSAGES.STATUS_DOWNGRADE(RING_STATUS_EN.PRESENT_TENSE[incomingStatus], RING_STATUS_EN.PAST_TENSE[currentState.ringStatus!])
        }

    return NO_ERROR_RESULT;
}

export const StatusDowngradeValidator = _.curry(incomingTaskHasStatusShield)(unshieldedStatusDowngradeValidator);

function unshieldedStatusSkipValidator(target: BlastPacketRingTargetEvaluation, task: ProductionValidationTaskModel, incomingStatus: RingRuleEvaluationStatus, currentState: RingRuleEvaluationState): RingStateValidationResult {
    const statusDifference = incomingStatus - currentState.ringStatus!;

    if(statusDifference > 1)
        return {
            isError: true,
            uniqueId: UNIQUE_IDS.STATUS_SKIPPED,
            errorMessage: MESSAGES.STATUS_SKIPPED(RING_STATUS_EN.PRESENT_TENSE[incomingStatus], RING_STATUS_EN.PAST_TENSE[currentState.ringStatus!])
        };

    return NO_ERROR_RESULT;
}

export const StatusSkipValidator = _.curry(incomingTaskHasStatusShield)(unshieldedStatusSkipValidator);

function unshieldedAlreadyFiredValidator(target: BlastPacketRingTargetEvaluation, task: ProductionValidationTaskModel, incomingStatus: RingRuleEvaluationStatus, currentState: RingRuleEvaluationState): RingStateValidationResult {
    if(incomingStatus !== RingRuleEvaluationStatus.Fired || currentState.ringStatus! !== RingRuleEvaluationStatus.Fired)
        return NO_ERROR_RESULT;

    return {
        isError: true,
        uniqueId: UNIQUE_IDS.ALREADY_FIRED,
        errorMessage: MESSAGES.ALREADY_FIRED
    };
}

function unshieldedMatchingStatusCompletedValidator(target: BlastPacketRingTargetEvaluation, task: ProductionValidationTaskModel, incomingStatus: RingRuleEvaluationStatus, currentState: RingRuleEvaluationState): RingStateValidationResult {
    if(incomingStatus === RingRuleEvaluationStatus.Fired || incomingStatus != currentState.ringStatus!)
        return NO_ERROR_RESULT;

    if(currentState.markedCompleted)
        return {
            isError: true,
            uniqueId: UNIQUE_IDS.STATUS_MARKED_COMPLETE,
            errorMessage: MESSAGES.STATUS_MARKED_COMPLETE(RING_STATUS_EN.PRESENT_TENSE_PROGRESSIVE[incomingStatus])
        };

    return NO_ERROR_RESULT;
}

export const MatchingStatusCompletedValidator = _.curry(incomingTaskHasStatusShield)(unshieldedMatchingStatusCompletedValidator);

function unshieldedMatchingStatusReachedTargetValidator(target: BlastPacketRingTargetEvaluation, task: ProductionValidationTaskModel, incomingStatus: RingRuleEvaluationStatus, currentState: RingRuleEvaluationState): RingStateValidationResult {
    if(incomingStatus === RingRuleEvaluationStatus.Fired || incomingStatus != currentState.ringStatus!)
        return NO_ERROR_RESULT;

    if(currentState.reachedTarget)
        return {
            isError: true,
            uniqueId: UNIQUE_IDS.STATUS_REACHED_TARGET,
            errorMessage: MESSAGES.STATUS_REACHED_TARGET(RING_STATUS_EN.PRESENT_TENSE_PROGRESSIVE[incomingStatus])
        };

    return NO_ERROR_RESULT;
}

export const MatchingStatusReachedTargetValidator = _.curry(incomingTaskHasStatusShield)(unshieldedMatchingStatusReachedTargetValidator);

function incomingTaskHasStatusShield(shieldedFunction: (target: BlastPacketRingTargetEvaluation, task: ProductionValidationTaskModel, incomingStatus: RingRuleEvaluationStatus, currentState: RingRuleEvaluationState) => RingStateValidationResult, target: BlastPacketRingTargetEvaluation, task: ProductionValidationTaskModel, currentState: RingRuleEvaluationState): RingStateValidationResult {
    const incomingStatus = ConvertTaskTypeToStatus(task.taskType);

    if(incomingStatus == null)
        return RING_STATE_VALIDATION.NO_ERROR_RESULT;

    return shieldedFunction(target, task, incomingStatus, currentState);
}

export function ApplyRingTaskValidators(target: BlastPacketRingTargetEvaluation, task: ProductionValidationTaskModel, currentState: RingRuleEvaluationState, warningAdditionFunc: (task: ProductionValidationTaskModel, warning: ClientTaskWarning) => void) {
    task.warnings = task.warnings.filter(w=>w.type !== RING_STATE_ISSUE_TYPE);

    const skippedValidation = StatusSkipValidator(target, task, currentState);

    callWarningAccumulatorIfRequired(task, skippedValidation, warningAdditionFunc);

    const downgradeValidation = StatusDowngradeValidator(target, task, currentState);

    callWarningAccumulatorIfRequired(task, downgradeValidation, warningAdditionFunc);

    const statusIncompleteValidation = StatusIncompleteValidator(target, task, currentState);

    callWarningAccumulatorIfRequired(task, statusIncompleteValidation, warningAdditionFunc);

    const matchingStatusCompletedValidation = MatchingStatusCompletedValidator(target, task, currentState);

    callWarningAccumulatorIfRequired(task, matchingStatusCompletedValidation, warningAdditionFunc);

    const matchingStatusReachedTargetValidation = MatchingStatusReachedTargetValidator(target, task, currentState);

    callWarningAccumulatorIfRequired(task, matchingStatusReachedTargetValidation, warningAdditionFunc);

    const projectedToExceedTargetValidation = ProjectedToExceedTargetValidator(target, task, currentState);

    callWarningAccumulatorIfRequired(task, projectedToExceedTargetValidation, warningAdditionFunc);
}