import { ICommandMapper } from '../ICommandMapper';
import { SplitTaskCommand, OfflineEditableCommand } from '@/models/api';
import { EditStore, Edit } from '../EditStore';
import ConvertCommandsToBaselineHelper from './Helpers/ConvertCommandsToBaselineHelper';

export class SplitTaskCommandMapper implements ICommandMapper<SplitTaskCommand> {
    commandType = 'SplitTaskCommand';
    async onQueue(command: SplitTaskCommand, store: EditStore) {
        await ConvertCommandsToBaselineHelper.onQueue(command, store);
    }
    onDequeue(edit: Edit<OfflineEditableCommand>, attemptedAt: Date): Edit<any> {
        return ConvertCommandsToBaselineHelper.onDequeue(edit, attemptedAt);
    }
    mappers = [ConvertCommandsToBaselineHelper.createShiftModelMapper<SplitTaskCommand>()];
}

export default new SplitTaskCommandMapper();
