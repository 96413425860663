import { GenericDataApi } from '@/lib/data/GenericDataApi';
import { AddShiftCrewMemberCommand } from '@/models/api';
import { DeleteShiftCrewMemberCommand } from '@/models/api/Commands/delete-shift-crew-member-command';
import RequestHandler from '../api/RequestHandler';

export class ShiftCrewApi extends GenericDataApi<any> {
    constructor() {
        super('Department');
    }

    public async addCrewMember(departmentId: string, command: AddShiftCrewMemberCommand) {
        await RequestHandler.execute(
            'POST',
            `${this.resource}/${departmentId}/Shift/${command.shiftId}/Crew/AddMember`,
            command
        );
    }

    public async removeCrewMember(departmentId: string, command: DeleteShiftCrewMemberCommand) {
        await RequestHandler.execute(
            'DELETE',
            `${this.resource}/${departmentId}/Shift/${command.shiftId}/Crew`,
            command
        );
    }
}

export default new ShiftCrewApi();
