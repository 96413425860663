import Dexie from 'dexie';
import { IEdit } from './DbModels/IEdit';

export class Db extends Dexie {
    edits: Dexie.Table<IEdit, number>;

    constructor() {
        super('Ordo');
        this.version(1).stores({
            edits: '++id, created, type, model'
        });
        this.edits = this.table('edits');
    }
}

export default new Db();
