import {
    AddEquipmentHazardsAtShiftCommand,
    AddLocationHazardsAtShiftCommand,
    HazardNoteUpdateModel,
    UpdateEquipmentHazardsAtShiftCommand,
    UpdateLocationHazardsAtShiftCommand
} from '@/models/api';

export interface ClientAddHazardsCommand {
    toAdd: HazardNoteUpdateModel[]
}

export function TransformClientAddHazardsCommandForLocation(command: ClientAddHazardsCommand, shiftId: string, locationId: string): AddLocationHazardsAtShiftCommand {
    return {
        _type: 'AddLocationHazardsAtShiftCommand',
        shiftId,
        locationId,
        toAdd: command.toAdd,
        isOfflineReplay: false,
        attemptedAt: new Date(),
    };
}

export function TransformClientAddHazardsCommandForEquipment(command: ClientAddHazardsCommand, shiftId: string, equipmentId: string): AddEquipmentHazardsAtShiftCommand {
    return {
        _type: 'AddEquipmentHazardsAtShiftCommand',
        shiftId,
        equipmentId,
        toAdd: command.toAdd,
        isOfflineReplay: false,
        attemptedAt: new Date(),
    };
}