export class Palettes {
    public makePalette(palettes: any) {
        if (!palettes || palettes.length == 0) {
            return [];
        }
        const colours = [['#000000'], ['#ffffff']];
        const seen = {} as any;
        for (const palette of palettes) {
            const extracted = palette.colours.map((c: any) => c.split(',')[0]);
            for (const c of extracted) {
                if (!seen[c]) {
                    colours.push([c]);
                }
                seen[c] = true;
            }
        }
        return colours;
    }
}
export default new Palettes();
