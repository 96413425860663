import ClientProductionLocationTrackingInfo from '@/models/client/client-production-location-tracking-info';

export enum BoardGroupingType {
    DEFAULT = 0,
    CUSTOM_PROPERTY = 1,
    MATERIAL_DESTINATION = 2,
    STOCKPILE = 3,
    LEVEL = 4,
    DEPARTMENT = 5,
}

export interface GroupingOption {
    text: string;
    id: string;
    type: BoardGroupingType;
    furtherInformation: string;
}