import { GenericDataApi } from '@/lib/data/GenericDataApi';
import { AxiosError } from 'axios';
import AppHttp from '../AppHttp';
import Errors from '../Errors';
import { MiningMethodType } from '@/models/api/Data/mining-method-type';
import LocationRings from './LocationRings';
import BlastPackets from './BlastPackets';

export class Sandpit extends GenericDataApi<any> {
    constructor() {
        super('Sandpit');
    }

    public async getSandpit(): Promise<any> {
        return await AppHttp.get(`${this.resource}`);
    }

    public async create() {
        return await AppHttp.post(`${this.resource}`, null);
    }

    public async delete(id: string) {
        return await AppHttp.delete(`${this.resource}/${id}`);
    }

    public async deleteWeekPlans(id: string, departmentId: string) {
        return await AppHttp.delete(`${this.resource}/${id}/departments/${departmentId}/weekplans`);
    }

    public async deleteVisualisations(id: string, departmentId: string) {
        return await AppHttp.delete(`${this.resource}/${id}/departments/${departmentId}/front`);
    }

    public async deleteDepartment(id: string, departmentId: string) {
        return await AppHttp.delete(`${this.resource}/${id}/departments/${departmentId}`);
    }

    public async importEquipment(sandpitId: string, file: File) {
        const response = await AppHttp.postWithFiles(`${this.resource}/${sandpitId}/import/equipment`, {}, [file]);
        const data = response ? response.data : null;
        return data;
    }

    public async importCrews(sandpitId: string, file: File) {
        const response = await AppHttp.postWithFiles(`${this.resource}/${sandpitId}/import/crews`, {}, [file]);
        const data = response ? response.data : null;
        return data;
    }

    public async importCycles(sandpitId: string, departmentId: string, taskTypes: File, cycles: File, eos: string = '') {
        const response = await AppHttp.postWithFiles(`${this.resource}/${sandpitId}/import/${departmentId}/cycles?eos=${eos}`, {}, [taskTypes, cycles]);
        const data = response ? response.data : null;
        return data;
    }

    public async importLocations(sandpitId: string, departmentId: string, locations: File, miningMethod?: MiningMethodType) {
        const response = await AppHttp.postWithFiles(`${this.resource}/${sandpitId}/import/${departmentId}/locations?miningMethod=${miningMethod}`, { miningMethod: miningMethod }, [locations]);
        const data = response ? response.data : null;
        return data;
    }

    public async importLevels(sandpitId: string, levels: File) {
        const response = await AppHttp.postWithFiles(`${this.resource}/${sandpitId}/import/levels`, {}, [levels]);
        const data = response ? response.data : null;
        return data;
    }

    public async importWeekPlan(sandpitId: string, departmentId: string, date: string, weekPlan: File, allocate: boolean = false) {
        const response = await AppHttp.postWithFiles(`${this.resource}/${sandpitId}/import/${departmentId}/weekplan?date=${date}&allocate=${allocate}`, {}, [weekPlan]);
        const data = response ? response.data : null;
        return data;
    }

    public async importRings(sandpitId: string, departmentId: string, locationRings: File, blastPackets: File)
    {
        const validation = await LocationRings.upload(departmentId, locationRings);
        if (validation?.isValid){
            await LocationRings.import(validation);
            const bpValidation = await BlastPackets.upload(departmentId, blastPackets);
            if (bpValidation?.isValid){
                await BlastPackets.import(departmentId, bpValidation);
            }
        }
    }

    public async importRateBoardWeekPlan(sandpitId: string, departmentId: string, date: string, weekPlan: File, setActuals: boolean = false, allocate: boolean = false) {
        const response = await AppHttp.postWithFiles(`${this.resource}/${sandpitId}/import/${departmentId}/rateboard/weekplan?date=${date}&actuals=${setActuals}&allocate=${allocate}`, {}, [weekPlan]);
        const data = response ? response.data : null;
        return data;
    }

    public async importCoordinates(sandpitId: string, departmentId: string, coordinates: File) {
        const response = await AppHttp.postWithFiles(`${this.resource}/${sandpitId}/import/${departmentId}/front-coordinates`, {}, [coordinates]);
        const data = response ? response.data : null;
        return data;
    }

    public async importOverlay(sandpitId: string, departmentId: string, overlay: File) {
        const response = await AppHttp.postWithFiles(`${this.resource}/${sandpitId}/import/${departmentId}/front/overlay`, {}, [overlay]);
        const data = response ? response.data : null;
        return data;
    }
}

export default new Sandpit();
