import PlannedEquipment from '@/lib/data/PlannedEquipment';
import Shift from '@/lib/data/Shift';
import {
    EquipmentAvailabilityType,
    PlannedEquipmentViewModel
} from '@/models/api';
import { Staff } from '@/models/api/Data/staff';
import { defineStore } from 'pinia';
import { useShiftDetails } from '@/lib/stores/Shift';
import Equipment from '@/models/client/equipment';
import dayjs from 'dayjs';
import { useSystemStore } from '@/lib/stores/SystemStore';
import { useHeadingsStore } from '../HeadingsStore';
import { showWarningToast } from '@/lib/Toast';
import EquipmentAvailability from '@/lib/data/EquipmentAvailability';
import {
    ClientUpdateHazardsCommand,
    TransformClientUpdateHazardsCommandForEquipment
} from '@/models/client/Commands/client-update-hazards-command';
import { AddToExistingHazards, UpdateExistingHazards } from '@/lib/services/Hazards';
import UserStore from '@/lib/stores/UserStore';
import {
    ClientAddHazardsCommand,
    TransformClientAddHazardsCommandForEquipment
} from '@/models/client/Commands/client-add-hazards-command';

export const useShiftEquipment = defineStore('equipment', {
    state: () => {
        return {
            equipment: [] as Equipment[],
            equipmentInWorkshop: [] as string[],
            equipmentToWorkshop: [] as string[],
        };
    },
    getters: {
        equipmentById(state): (equipmentId: string) => Equipment {
            return (equipmentId) => {
                const matching = state.equipment.find((equipment) => equipment.id === equipmentId);
                if (matching === undefined) throw new Error(`Could not find equipment with Id ${equipmentId}`);

                return matching;
            };
        },
        equipmentByEquipmentId(state): (equipmentId: string) => Equipment {
            return (equipmentId) => {
                const matching = state.equipment.find((equipment) => equipment.equipmentId === equipmentId);
                if (matching === undefined) throw new Error(`Could not find equipment with equipmentId ${equipmentId}`);

                return matching;
            };
        },
        getStaffForEquipment(): (equipmentId: string) => Staff[] {
            return (equipmentId: string) => {
                const equipmentPiece = this.equipmentById(equipmentId);
                const staffMembers: Staff[] = [];

                equipmentPiece.staffIds.forEach((id) => {
                    const staffMember = useSystemStore().getStaffById(id);
                    staffMembers.push(staffMember);
                });

                return staffMembers;
            };
        },
        getEquipmentWithStaff(state): (staffId: string) => Equipment[] {
            return (staffId: string) => {
                return this.equipment.filter((equipmentPiece: Equipment) => equipmentPiece.staffIds.includes(staffId));
            };
        },
        getPrimaryEquipmentByEquipment(state): (secondaryEquipmentId: string) => Equipment[] {
            return (secondaryEquipmentId: string) => {
                const primaries = this.equipment.filter((equipmentPiece: Equipment) =>
                    equipmentPiece.secondaryEquipmentIds.includes(secondaryEquipmentId)
                );

                return primaries;
            };
        },
        getSecondaryEquipmentByEquipment(state): (primaryEquipmentId: string) => Equipment[] {
            return (primaryEquipmentId: string) => {
                const equipment = this.equipmentById(primaryEquipmentId);

                const secondaryEquipmentList: Equipment[] = [];

                equipment.secondaryEquipmentIds.forEach((x) => {
                    const secondaryEquipment = this.equipmentByEquipmentId(x);
                    secondaryEquipmentList.push(secondaryEquipment);
                });

                return secondaryEquipmentList;
            };
        },
        getPlannedEquipment(state): (equipmentId: string) => Equipment {
            return (equipmentId: string) => {
                const plannedEquipmentIndex = this.equipment.findIndex((x) => x.equipmentId == equipmentId);
                if (plannedEquipmentIndex == -1) {
                    throw new Error(`Could not find planned equipment ${equipmentId} in shift store planned equipment`);
                }

                return this.equipment[plannedEquipmentIndex];
            };
        },
    },
    actions: {
        setUpStore(equipment: Equipment[], equipmentInWorkshop: string[], equipmentToWorkshop: string[]) {
            this.equipment = equipment;
            this.equipmentInWorkshop = equipmentInWorkshop;
            this.equipmentToWorkshop = equipmentToWorkshop;
        },
        async addStaffToEquipment(equipmentId: string, staffMemberId: string) {
            const equipment = this.equipmentById(equipmentId);

            if (equipment.staffIds.findIndex((x) => x === staffMemberId) !== -1) {
                showWarningToast('This staff member is already assigned to this equipment piece.');
                return;
            }

            equipment.staffIds.push(staffMemberId);

            const shiftId = useShiftDetails().shiftId;
            await Shift.addStaff({
                _type: 'AddStaffToEquipmentCommand',
                departmentShiftId: shiftId,
                plannedEquipmentId: equipmentId,
                staffId: staffMemberId,
                isOfflineReplay: false,
                attemptedAt: new Date(),
            });
        },
        async removeStaffFromEquipment(equipmentId: string, staffMemberId: string) {
            const equipment = this.equipmentById(equipmentId);
            const staffIndex = equipment.staffIds.findIndex((x) => x == staffMemberId);

            if (staffIndex < 0) throw new Error(`Cannot find staff with id ${staffMemberId} to remove`);

            equipment.staffIds.splice(staffIndex, 1);

            const shiftId = useShiftDetails().shiftId;
            await Shift.removeStaff({
                _type: 'RemoveStaffFromEquipmentCommand',
                departmentShiftId: shiftId,
                plannedEquipmentId: equipmentId,
                staffId: staffMemberId,
                isOfflineReplay: false,
                attemptedAt: new Date(),
            });
        },
        async addSecondaryEquipment(equipmentId: string, secondaryEquipmentId: string) {
            const equipment = this.equipmentById(equipmentId);
            const secondaryEquipment = this.equipmentById(secondaryEquipmentId);

            if (equipment.secondaryEquipmentIds.findIndex((x) => x === secondaryEquipment.equipmentId) !== -1) {
                showWarningToast('This secondary equipment piece is already assigned to this equipment piece.');
                return;
            }

            equipment.secondaryEquipmentIds.push(secondaryEquipment.equipmentId);

            const shiftId = useShiftDetails().shiftId;
            await PlannedEquipment.setSecondaryEquipmentForShift(
                shiftId,
                equipment.id,
                equipment.secondaryEquipmentIds
            );
        },
        async removeSecondaryEquipment(equipmentId: string, secondaryEquipmentId: string) {
            const equipment = this.equipmentById(equipmentId);
            const secondaryEquipment = this.equipmentById(secondaryEquipmentId);
            const index = equipment.secondaryEquipmentIds.findIndex((x) => x === secondaryEquipment.equipmentId);

            if (index === -1) {
                throw new Error(`Could not find secondary equipment with equipment Id ${secondaryEquipment.equipmentId} on equipment ${equipmentId}`);
            }

            equipment.secondaryEquipmentIds.splice(index, 1);

            const shiftId = useShiftDetails().shiftId;
            await PlannedEquipment.setSecondaryEquipmentForShift(
                shiftId,
                equipment.id,
                equipment.secondaryEquipmentIds
            );
        },
        removeAllStaffFromEquipment() {
            this.equipment.forEach((x) => {
                x.staffIds = [];
            });
        },
        async setEquipmentLocation(equipmentId: string, location: string) {
            const equipment = this.equipmentById(equipmentId);
            equipment.location = location;

            const shiftId = useShiftDetails().shiftId;
            await PlannedEquipment.setLocationForShift(shiftId, equipmentId, location);
            useShiftDetails().addEquipmentLocationForShift(location);
        },
        async setEquipmentComments(equipmentId: string, comment: string) {
            const equipment = this.equipmentById(equipmentId);
            equipment.comment = comment;

            const shiftId = useShiftDetails().shiftId;
            await PlannedEquipment.setCommentsForShift(shiftId, equipmentId, comment);
        },
        async updateEquipmentHazards(equipmentId: string, clientCommand: ClientUpdateHazardsCommand) {
            const shiftId = useShiftDetails().shiftId;
            const user = await UserStore.getCurrentUser();
            // @ts-ignore
            const madeAtUTC: Date = dayjs.utc();

            const equipment = this.equipmentById(equipmentId);
            equipment.hazardNotes = UpdateExistingHazards(equipment.hazardNotes, clientCommand, user?.name ?? '', madeAtUTC);

            const serverCommand = TransformClientUpdateHazardsCommandForEquipment(clientCommand, shiftId, equipment.equipmentId);

            await PlannedEquipment.updateHazardsForShift(serverCommand);
        },
        async addEquipmentHazards(equipmentId: string, clientCommand: ClientAddHazardsCommand) {
            const shiftId = useShiftDetails().shiftId;
            const user = await UserStore.getCurrentUser();
            // @ts-ignore
            const madeAtUTC: Date = dayjs.utc();

            const equipment = this.equipmentById(equipmentId);
            equipment.hazardNotes = AddToExistingHazards(equipment.hazardNotes, clientCommand, user?.name ?? '', madeAtUTC);

            const serverCommand = TransformClientAddHazardsCommandForEquipment(clientCommand, shiftId, equipment.equipmentId);

            await PlannedEquipment.addHazardsForShift(serverCommand);
        },
        markEquipmentAsUnserviceable(equipmentId: string, departmentShiftId: string, avaiabilityId: string) {
            const index = this.equipment.findIndex((x) => x.equipmentId === equipmentId);
            if (index === -1) throw new Error(`Planned Equipment with equipment id ${equipmentId} does not exist`);
            const equipment = this.equipment[index];
            equipment.availability.push({
                id: avaiabilityId,
                startsAt: dayjs().toDate(),
                endsAt: dayjs().add(1, 'y').toDate(),
                type: EquipmentAvailabilityType.Unserviceable,
            });
        },
        markEquipmentAsServiceable(availabilityId: string, departmentShiftId: string) {
            this.equipment.forEach((eq) => {
                eq.availability = eq.availability.filter((av) => av.id !== availabilityId);
            });
        },
        async markEquipmentAsInWorkshop(equipmentId: string) {
            const equipment = this.equipmentByEquipmentId(equipmentId);

            if(equipment != null){
                const shiftId = useShiftDetails().shiftId;
                await EquipmentAvailability.markAsInWorkshop(equipmentId, shiftId);
                if(!this.equipmentInWorkshop.includes(equipmentId))
                    this.equipmentInWorkshop.push(equipmentId);
            }
        },
        async markEquipmentAsNotInWorkshop(equipmentId: string) {
            const equipment = this.equipmentByEquipmentId(equipmentId);

            if(equipment != null){
                const shiftId = useShiftDetails().shiftId;
                await EquipmentAvailability.markAsNotInWorkshop(equipmentId, shiftId);
                if(this.equipmentInWorkshop.includes(equipmentId))
                    this.equipmentInWorkshop = this.equipmentInWorkshop.filter(x => x != equipmentId);
            }
        },
        async markEquipmentAsNeededInWorkshop(equipmentId: string) {
            const equipment = this.equipmentByEquipmentId(equipmentId);

            if(equipment != null){
                const shiftId = useShiftDetails().shiftId;
                await EquipmentAvailability.markAsNeededInWorkshop(equipmentId, shiftId);
                if(!this.equipmentToWorkshop.includes(equipmentId))
                    this.equipmentToWorkshop.push(equipmentId);
            }
        },
        async markEquipmentAsNotNeededInWorkshop(equipmentId: string) {
            const equipment = this.equipmentByEquipmentId(equipmentId);

            if(equipment != null){
                const shiftId = useShiftDetails().shiftId;
                await EquipmentAvailability.markAsNotNeededInWorkshop(equipmentId, shiftId);
                if(this.equipmentToWorkshop.includes(equipmentId))
                    this.equipmentToWorkshop = this.equipmentToWorkshop.filter(x => x != equipmentId);
            }
        },
    },
});
