import { Staff } from '@/models/api/Data/staff';
import Equipment from '@/models/client/equipment';
import ClientTaskModel from '@/models/client/client-task';

export enum DropTargetType {
    ROLE = 'role',
    EQUIPMENT = 'equipment',
}

export interface DraggedStaff {
    type: 'staff';
    staff: Staff;
}

export interface DraggedEquipment {
    type: 'equipment';
    equipment: Equipment;
}

export type DragItem = DraggedEquipment | DraggedStaff;
export type EquipmentInfo = Equipment & { secondaryEquipment: Equipment[]; staff: Staff[]; tasks: ClientTaskModel[] };