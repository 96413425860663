import ClientRowModel from '@/models/client/client-row';
import ClientTaskModel from '@/models/client/client-task';
import { ClientTaskWarning } from '@/models/client/client-task-warning';
import { ClientTaskIssueType } from '@/models/client/client-task-issues';
import { unitDescription } from '@/lib/RateMetric';
import { isEosTaskType } from '@/lib/TaskType';
import { TaskErrorType } from '@/models/client/types/task-error-type';
import {
    AddErrorToTask,
    isBoggingTask,
    isCharge,
    IsDelay,
    isDrillTask,
    isFireTask,
    TaskIntersectsTimespan
} from '@/lib/services/Task';
import dayjs from 'dayjs';
import { NoGoType } from '@/models/client/types/no-go-type';
import {
    EquipmentUsageInDepartmentSummaryViewModel,
    ProductionFrontLocationRulesViewModel,
    ProductionFrontRingRulesViewModel,
    ProductionFrontRulesViewModel,
    RingRuleEvaluationStatus,
    RingStatesLocationForWindowViewModel
} from '@/models/api';
import {
    createMaximumDrawpointBoggingValidationFunction,
    RING_RULE_ISSUE_TYPE, RingInteractionRuleWarningAdditionFunc,
    validateBlastedRingsLeadLag,
    validateDrillingOrChargingChargedLag,
    validateInterLevelDrillingOverlaps,
    validateIntraLevelDrillingOverlaps
} from '@/components/ShiftBoard/Board/ValidationRingRules';
import { AffectedAreaSpecification } from '@/lib/stores/HeadingsStore';
import { RowType } from '@/models/client/types/row-type';
import { ProductionValidationTaskModel } from '@/models/client/production-validation-task-model';
import { ProductionValidationRow } from '@/models/client/production-validation-row';
import {
    ClientRowBlastPacketTargetDisplayInformation,
    ClientRowBlastPacketTargetInformation
} from '@/lib/stores/Production/ShiftWindowActualsStore';
import { TransformDateTimeOffsetToClientDate, TransformDateTimeToClientDate } from '@/lib/stores/Transforms';

export const EQUIPMENT_ISSUE_TYPE = 'EQUIPMENT';
export const LOCATION_ISSUE_TYPE = 'LOCATION';

const EQUIPMENT_UNIQUE_IDS = {
    EQUIPMENT_OVERLAP: 'EQUIPMENT_OVERLAP',
    EQUIPMENT_UNAVAILABLE: 'EQUIPMENT_UNAVAILABLE',
}

export interface IndexRangeStatusRingRule {
    levelIndexOffsets: number[],
    locationIndexOffsets: number[],
    ringIndexOffsets: number[],
    prohibitedRingStatuses: RingRuleEvaluationStatus[],
    violationMessageGenerator: (violatingLocation: ProductionFrontLocationRulesViewModel, violatingRings: ProductionFrontRingRulesViewModel[]) => string,
    ruleUniqueIdGenerator: (violatingLocation: ProductionFrontLocationRulesViewModel, violatingRing: ProductionFrontRingRulesViewModel[]) => string,
}

export interface IndexRangeTaskOverlapRingRule {
    levelIndexOffsets: number[],
    locationIndexOffsets: number[],
    ringIndexOffsets: number[],
    prohibitedTaskCheck: (t: ProductionValidationTaskModel) => boolean,
    violationMessageGenerator: (violatingLocationName: string) => string,
    ruleUniqueIdGenerator: (violatingLocationName: string) => string,
}

export function clearTaskErrors(row: ClientRowModel) {
    row.tasks.forEach((task) => {
        task.errors = [];
    });
}

export function ClearAllTaskWarningsForGivenSources(sources: ClientTaskIssueType[], row: ClientRowModel) {
    row.tasks.forEach((task)=>{
        task.warnings = task.warnings.filter(w=>!sources.includes(w.type));
    })
}

export function clearTaskNonLocationErrors(row: ClientRowModel) {
    ClearTaskErrorsNotOfIssueType(row, LOCATION_ISSUE_TYPE);
}

export function ClearTaskErrorsOfIssueType(row: ClientRowModel, issueType: ClientTaskIssueType) {
    row.tasks.forEach((task) => {
        if(task.errors.length == 0 ) return;
        task.errors = task.errors.filter(e=>e.type !== issueType);
    });
}

export function ClearTaskErrorsNotOfIssueType(row: ClientRowModel, issueType: ClientTaskIssueType) {
    row.tasks.forEach((task) => {
        if(task.errors.length == 0 ) return;
        task.errors = task.errors.filter(e=>e.type === issueType);
    });
}

export function validateProductionFrontRules(rows: ProductionValidationRow[], targetsToBlastPacketsAndRings: ClientRowBlastPacketTargetDisplayInformation[], rulesets: ProductionFrontRulesViewModel[], ringStates: RingStatesLocationForWindowViewModel[], currentMineTime: dayjs.Dayjs, shifts: {shiftStartTime: dayjs.Dayjs, shiftEndTime: dayjs.Dayjs}[], warningAdditionFunc: (task: ProductionValidationTaskModel, affectedArea: AffectedAreaSpecification, warning: ClientTaskWarning) => void) {
    const wrappedWarningFunc = (task: ProductionValidationTaskModel, affectedArea: AffectedAreaSpecification, warningText: string, warningUniqueId: string) => {
        warningAdditionFunc(task, affectedArea, {
            message: warningText,
            warningCategoryId: `${RING_RULE_ISSUE_TYPE}.${warningUniqueId}`,
            type: RING_RULE_ISSUE_TYPE
        });
    }

    rows.forEach(row => {
        if (!row.tasks) return;

        for(let i  = 0; i < rulesets.length; i++) {
            const evaluationRuleset = rulesets[i];

            const maximumDrawpointBoggingValidationFunctions = shifts.map(s=>createMaximumDrawpointBoggingValidationFunction(evaluationRuleset, s.shiftStartTime, s.shiftEndTime, wrappedWarningFunc));

            for(const task of row.tasks.filter(t=>t.startTime.isSameOrAfter(currentMineTime) && !IsDelay(t))) {
                if (task.blastPacketRingTargetId == null || task.blastPacketRingTargetId == undefined) continue;

                if(task.blastPacketRingTargetId in evaluationRuleset.blastPacketRingTargetsToCoordinates) {
                    const coordinates = evaluationRuleset.blastPacketRingTargetsToCoordinates[task.blastPacketRingTargetId];

                    if(isBoggingTask(task))
                        maximumDrawpointBoggingValidationFunctions.forEach(f=>f(row, task));

                    const location = evaluationRuleset.levels.find(l=>l.index===coordinates[0][0])?.locations.find(l=>l.locationId===row.location?.id);

                    if(location == null || location.excludeFromInteractionChecks)
                        continue;

                    validateProductionFrontRulesForTaskForGivenFront(rows, targetsToBlastPacketsAndRings, task, evaluationRuleset, ringStates, wrappedWarningFunc);
                }
            }
        }
    });
}

export function validateProductionFrontRulesForTaskForGivenFront(rows: ProductionValidationRow[], targetsToBlastPacketsAndRings: ClientRowBlastPacketTargetDisplayInformation[], task: ProductionValidationTaskModel, ruleset: ProductionFrontRulesViewModel, ringStates: RingStatesLocationForWindowViewModel[], warningAdditionFunc: RingInteractionRuleWarningAdditionFunc) {
    const blastPacketCoordinates = ruleset.blastPacketRingTargetsToCoordinates[task.blastPacketRingTargetId!];

    if(blastPacketCoordinates == null)
        return;

    if(isDrillTask(task) || isCharge(task))
        validateDrillingOrChargingChargedLag(ruleset, targetsToBlastPacketsAndRings, ringStates, blastPacketCoordinates[0], task, warningAdditionFunc);

    if(isDrillTask(task)){
        validateIntraLevelDrillingOverlaps(rows, targetsToBlastPacketsAndRings, ruleset, blastPacketCoordinates[0], task, warningAdditionFunc);
        validateInterLevelDrillingOverlaps(rows, targetsToBlastPacketsAndRings, ruleset, blastPacketCoordinates[0], task, warningAdditionFunc);
    }

    if(isFireTask(task) || isBoggingTask(task))
        validateBlastedRingsLeadLag(ruleset, targetsToBlastPacketsAndRings, ringStates, blastPacketCoordinates, task, warningAdditionFunc);
}

export function validateTaskLocations(row: ClientRowModel) {
    if (!row.tasks) return;

    row.tasks.forEach((task) => {
        if (task.isDeleted) return;

        if (!row.shiftLocationDetails) return;

        if (row.shiftLocationDetails!.unavailable && !task.taskType.isDelay) {
            AddErrorToTask(task, 'Location Unavailable this shift', TaskErrorType.LOCATION_UNAVAILABLE, LOCATION_ISSUE_TYPE);
        }

        if (row.noGoZoneFinder.ignoreEos === false) {
            if (isEosTaskType(task?.taskType, row.eosTask)) {
                if (row.noGoZoneFinder.isInNoGo(task, NoGoType.EndOfShift) === false) {
                    AddErrorToTask(task, 'Must be at EoS', TaskErrorType.NOT_IN_EOS, LOCATION_ISSUE_TYPE);
                }
            } else {
                if (IsDelay(task) === false && row.noGoZoneFinder.overlapsNoGo(task, NoGoType.EndOfShift)) {
                    AddErrorToTask(task, 'Must not be at EoS', TaskErrorType.BLOCKING_EOS, LOCATION_ISSUE_TYPE);
                }
            }
        }

        if (row.noGoZoneFinder.ignoreSos === false) {
            if (IsDelay(task) === false && row.noGoZoneFinder.overlapsNoGo(task, NoGoType.StartOfShift)) {
                AddErrorToTask(task, 'Must not be at SoS', TaskErrorType.BLOCKING_SOS, LOCATION_ISSUE_TYPE);
            }
        }
    });
}

export function validateEquipmentUsage(rows: ClientRowModel[], otherDepartmentUsages: EquipmentUsageInDepartmentSummaryViewModel[], errorAdditionFunc: (task: ClientTaskModel, areaAffected: AffectedAreaSpecification, warning: ClientTaskWarning, errorType: TaskErrorType) => void) {
    const equipmentUsage: any = {};
    rows.forEach((row) => {
        if (!row.tasks) return;
        row.tasks.forEach((task) => {
            if (task.isDeleted) return;
            for (const plannedEquipment of task.plannedEquipment ?? []) {
                const secondary = plannedEquipment.isPrimary ? '' : '(secondary)';
                const overlapError = `${plannedEquipment.name}${secondary} is used for overlapping tasks`;

                if (plannedEquipment.equipmentId === null) continue;

                if (!equipmentUsage[plannedEquipment.equipmentId]) {
                    equipmentUsage[plannedEquipment.equipmentId] = { tasks: [] };
                }

                if(plannedEquipment.showConflicts){
                    const otherDepartmentUsagesOfEquipment = otherDepartmentUsages.find(x=>x.equipmentId == plannedEquipment.equipmentId);

                    for(const x of otherDepartmentUsagesOfEquipment?.usages ?? []) {
                        const startTime = TransformDateTimeToClientDate(x.startsAt);
                        const endTime = TransformDateTimeToClientDate(x.endsAt);
                        if(TaskIntersectsTimespan(task, startTime, endTime)) {
                            errorAdditionFunc(task, { locationId: task.locationId, locationName: task.locationName! }, { message: `${plannedEquipment.name} is used in another department (${x.departmentName}) at this time: ${task.startTime} - ${task.endTime}`, type: EQUIPMENT_ISSUE_TYPE, warningCategoryId: `${EQUIPMENT_ISSUE_TYPE}.${EQUIPMENT_UNIQUE_IDS.EQUIPMENT_OVERLAP}`}, TaskErrorType.EQUIPMENT_OVERLAP);
                        }
                    }

                    for (const x of equipmentUsage[plannedEquipment.equipmentId].tasks) {
                        if (TaskIntersectsTimespan(task, x.startTime, x.endTime)) {
                            errorAdditionFunc(task, { locationId: task.locationId, locationName: task.locationName! }, { message: overlapError, type: EQUIPMENT_ISSUE_TYPE, warningCategoryId: `${EQUIPMENT_ISSUE_TYPE}.${EQUIPMENT_UNIQUE_IDS.EQUIPMENT_OVERLAP}`}, TaskErrorType.EQUIPMENT_OVERLAP);
                            errorAdditionFunc(x, { locationId: x.locationId, locationName: x.locationName! }, { message: overlapError, type: EQUIPMENT_ISSUE_TYPE, warningCategoryId: `${EQUIPMENT_ISSUE_TYPE}.${EQUIPMENT_UNIQUE_IDS.EQUIPMENT_OVERLAP}`}, TaskErrorType.EQUIPMENT_OVERLAP);
                        }
                    }
                }

                if (plannedEquipment.availability) {
                    for (const availability of plannedEquipment.availability) {
                        const start = dayjs.utc(availability.startsAt);
                        const end = dayjs.utc(availability.endsAt);

                        if (task.startTime.isSameOrAfter(end)) {
                            continue;
                        }
                        if (start.isSameOrAfter(task.endTime)) {
                            continue;
                        }

                        errorAdditionFunc(task, { locationId: task.locationId, locationName: task.locationName! }, { message: `${plannedEquipment.name} is not available at this time: ${task.startTime} - ${task.endTime}`, type: EQUIPMENT_ISSUE_TYPE, warningCategoryId: `${EQUIPMENT_ISSUE_TYPE}.${EQUIPMENT_UNIQUE_IDS.EQUIPMENT_UNAVAILABLE}`}, TaskErrorType.EQUIPMENT_UNAVAILABLE);

                        AddErrorToTask(
                            task,
                            `${plannedEquipment.name} is not available at this time`,
                            TaskErrorType.EQUIPMENT_UNAVAILABLE,
                            EQUIPMENT_ISSUE_TYPE
                        );
                    }
                }

                equipmentUsage[plannedEquipment.equipmentId].tasks.push(task);
            }
        });
    });
}
