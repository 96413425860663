import AppHttp, { OfflineReplayApiClient } from '@/lib/AppHttp';
import EditStore, { Edit } from '@/lib/OfflineEdits/EditStore';
import { Events, EventBus } from '@/lib/EventBus';
import Logging from '@/lib/Logging';
import { useSystemStore } from '../stores/SystemStore';
import { useShiftDetails } from '../stores/Shift';
import { useDepartmentStore } from '../stores/DepartmentStore';
import UserStore from '@/lib/stores/UserStore';
import Errors from '@/lib/Errors';
import { AxiosError } from 'axios';
import ClientRowModel, { LockTag } from '@/models/client/client-row';
import { CreateShiftSnapshotCommand, SyncTasksCommand } from '@/models/api';

export class RequestHandler {
    private isReplaying: boolean = false;
    private hasBaselinedDueToConflicts = false;
    private mustBaselineOfflineEdits = false;
    private reasonForBaseliningOfflineEdits: string = '';

    public init() {
        EventBus.$on(Events.Online, () => this.onBackOnline());
        EventBus.$on(Events.ApiCallSuccess, () => this.onBackOnline());
        EventBus.$on(Events.ChangeConflictDuringOfflineEdits, () => this.onFailedToPushChangesDueToConflictLogic('Conflicted Shift Changes', 'Conflicts occurred. The edits that were made while offline have been saved as a baseline.'));
        EventBus.$on(Events.LockFailureDuringOfflineEdits, () => this.onFailedToPushChangesDueToConflictLogic('Lock Failure Shift Changes', 'Failed to obtain location lock. The edits that were made while offline have been saved as a baseline.'));
        EventBus.$on(Events.BaselineOfflineEdits, (reason: string | null)=>this.onBaselineOfflineEditsRequired(reason));
    }

    public async fetch<R>(url: string, offlineResponse: R): Promise<R> {
        if (useSystemStore().isOnline === false) {
            return offlineResponse;
        }

        try {
            const response = await AppHttp.get(url);
            return response?.data ?? null;
        } catch (e) {
            Errors.ApiError(e as AxiosError);
            throw e;
        }
    }

    public async execute<T, R>(
        method: 'POST' | 'DELETE' | 'PATCH' | 'PUT',
        url: string,
        command: T,
        offlineResponse: R | null = null
    ): Promise<R | null> {
        if (useSystemStore().isOnline === false) {
            await EditStore.add({ command, url, method });
            return offlineResponse;
        }

        return this.makeApiCall(command, url, method);
    }

    public async syncChangeSet(original: ClientRowModel, updated: ClientRowModel, tags: LockTag, changeSetTransformer: (changeSet: SyncTasksCommand) => SyncTasksCommand) {
        const changeSet = changeSetTransformer(EditStore.getChangeSet(original, updated, tags));

        if (useSystemStore().isOnline === false) {
            await EditStore.addChangeSet(changeSet);
        } else {
            return await this.makeApiCall(changeSet, 'Shift/Cycles/Tasks/Sync', 'POST');
        }
    }

    public async syncAdhocChangeSet(original: ClientRowModel, updated: ClientRowModel, departmentId: string, tags: LockTag) {
        const changeSet = EditStore.getAdhocChangeSet(original, updated, departmentId, tags);

        if (useSystemStore().isOnline === false) {
            await EditStore.addAdhocChangeSet(changeSet);
        } else {
            return await this.makeApiCall(changeSet, 'PlannedAdHocTasks/Sync', 'POST');
        }
    }

    public async onFailedToPushChangesDueToConflictLogic(conflictIssue: string, toastMessage: string) {
        if(this.hasBaselinedDueToConflicts)
            return;

        this.hasBaselinedDueToConflicts = true;

        await useShiftDetails().saveOfflineSnapshot(conflictIssue);
        
        EditStore.clear();
        EventBus.$emit(
            Events.ToastWarning,
            toastMessage,
            true
        );
    }

    public onBaselineOfflineEditsRequired(reason: string | null) {
        if(reason)
            this.reasonForBaseliningOfflineEdits = reason;

        this.mustBaselineOfflineEdits = true;
    }

    public async onBackOnline() {
        if (this.isReplaying) {
            return;
        }
        try {
            this.isReplaying = true;

            const hasEdits = await EditStore.hasPendingEdits();
            if (!hasEdits) {
                return;
            }

            const pingResult = await OfflineReplayApiClient.get('users/ping');
            if (!pingResult || pingResult.status < 200 || pingResult.status >= 400) {
                Logging.warning('Came back online but ping failed');
                return;
            }

            if (hasEdits) {
                const loggedInUser = await UserStore.getCurrentUser();
                await useDepartmentStore().getShiftOwner();
                if (loggedInUser!.sub !== useDepartmentStore().shiftOwnerId) {
                    await this.saveOfflineEditsAsBaseline('Offline Changes', 'The shift owner has changed, the edits that were made while offline have been saved as a baseline.', 'Shift ownership has changed when offline edits were made');
                    return;
                } else if(this.mustBaselineOfflineEdits){
                    const toastWarningReasonText = this.reasonForBaseliningOfflineEdits.length > 0 ? `${this.reasonForBaseliningOfflineEdits}. ` : '';
                    const loggingWarningReasonText = this.reasonForBaseliningOfflineEdits.length > 0 ? this.reasonForBaseliningOfflineEdits : 'No reason given for requiring offline edits.';

                    await this.saveOfflineEditsAsBaseline('Offline Changes', `${toastWarningReasonText}The edits that were made while offline have been saved as a baseline.`, loggingWarningReasonText);
                }
            }
            let count = 0;
            const failures: any[] = [];

            await EditStore.enumerate(async (editModel, c) => {
                const result = await this.send(editModel, c);
                count++;
                if (!result.success) {
                    failures.push(result);
                }
                return true;
            });

            if (count > 0) {
                if (failures.length > 0) {
                    EventBus.$emit(
                        Events.ToastWarning,
                        'Edits you made while offline have been sent, but one or more edit failed. Please refresh and check your data',
                        true
                    );
                    Logging.warning('One or more offline edits failed', { failures: failures });
                } else {
                    EventBus.$emit(Events.ToastSuccess, 'Edits you made while offline have been saved!', true);
                    EventBus.$emit(Events.OfflineEditsApplied);
                }
            }
        } catch (e) {
            throw e;
        } finally {
            this.isReplaying = false;
            this.hasBaselinedDueToConflicts = false;
            this.mustBaselineOfflineEdits = false;
            this.reasonForBaseliningOfflineEdits = '';
        }
    }

    private async saveOfflineEditsAsBaseline(snapshotName: string, toastWarningText: string, loggedWarningText: string) {
        await useShiftDetails().saveOfflineSnapshot(snapshotName);

        EditStore.clear();
        EventBus.$emit(
            Events.ToastWarning,
            toastWarningText,
            true
        );
        Logging.warning(loggedWarningText);
        EventBus.$emit(Events.OfflineEditsApplied);
    }

    private async makeApiCall(command: any, url: string, method: string) {
        switch (method) {
            case 'POST':
                return await AppHttp.post(url, command);
            case 'DELETE':
                return await AppHttp.delete(url, command);
            case 'PATCH':
                return await AppHttp.patch(url, command);
            case 'PUT':
                return await AppHttp.put(url, command);
        }
    }

    private async send(editModel: Edit<any>, created: Date) {
        try {
            switch (editModel.method) {
                case 'POST':
                    await OfflineReplayApiClient.post(editModel.url, editModel.command, created);
                    break;
                case 'DELETE':
                    await OfflineReplayApiClient.delete(editModel.url, editModel.command, created);
                    break;
                case 'PATCH':
                    await OfflineReplayApiClient.patch(editModel.url, editModel.command, created);
                    break;
                case 'PUT':
                    await OfflineReplayApiClient.put(editModel.url, editModel.command, created);
                    break;
            }
        } catch (e) {
            return { success: false, error: e, url: editModel.url, type: editModel.command._type };
        }
        return { success: true };
    }
}

export default new RequestHandler();
