import { EventBus, Events } from '@/lib/EventBus';
import { AxiosResponse, AxiosError } from 'axios';

export class Errors {
    ApiError(error: AxiosResponse<any> | AxiosError) {
        let response = error as AxiosResponse<any>;
        let err = error as any;
        if (err && err.err) err = err.err;

        if (err.response) {
            response = err.response;
        }

        if (!response || !response.status) {
            EventBus.$emit(
                Events.ToastError,
                `You are currently offline. Please ensure you are connected to the internet to continue.`
            );
            return;
        }

        if (response && typeof response.data == 'string') {
            EventBus.$emit(Events.ToastError, response.data);
        } else if (response && response.data.message) {
            EventBus.$emit(Events.ToastError, response.data.message);
        } else {
            EventBus.$emit(Events.ToastError, `Oops! There was an error. Please try again`);
        }
    }
}

export default new Errors();
