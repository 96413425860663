import Vue from 'vue';

const bus = new Vue({});

export const Events = {
    ScreenSizeChanged: '$screen-size',
    ScreenResized: '$screen-resize',
    TaskSelected: 'task-selected',
    ClearTaskSelection: 'clear-task-selection',

    ShowLeftDrawerButton: 'left-drawer-button',
    ShowStats: 'show-stats',
    LeftDrawer: 'left-drawer',
    ShowFiltersActive: 'show-filters-active',

    ShowTaskWarningsToggle: 'show-task-warnings-toggle',
    ShowWarnings: 'show-task-warnings',

    AuthLogout: '$auth.logout',
    AuthChange: '$auth.change',
    TenantChanged: '$auth.tenantChanged',
    ShowTenantChange: '$auth.showTenantChange',

    ToastError: '$toast.error',
    ToastSuccess: '$toast.success',
    ToastInfo: '$toast.info',
    ToastWarning: '$toast.warn',

    TermsUpdated: 'terms-updated',

    ShiftSelected: 'shift-selected',
    ShiftPublished: 'shift-published',
    MineAreasUpdated: 'mine-areas-updated',
    CurrentUserUpdated: 'user-updated',

    WeekBoard: 'week-board',
    ShiftBoard: 'shift-board',

    ShowFeature: 'show-feature',

    ClearSearchbar: 'clear-searchbar',
    SearchChanged: 'search-changed',
    ShowSearchFilter: 'show-search-filter',
    TogglePlanningViewType: 'toggle-planning-view-type',

    ToggleEquipment: 'toggle-equipment',
    ToggleRefreshMode: 'toggle-refreshmode',
    ToggleLegend: 'toggle-legend',
    ChangeResourceView: 'resource-view',

    ShowAddActualsScreen: 'show-add-actuals',
    ToggleFullDayShiftView: 'toggle-full-day-shift-view',
    ShowFullDayShiftViewToggle: 'show-full-day-shift-view-toggle',

    ManagedPrint: 'managed-print',
    PrintClicked: 'click-print',
    ReportClicked: 'click-report',

    Offline: '$offline',
    Online: '$online',

    OfflineEdit: 'offline-edit',
    ApiCallSuccess: 'api-successs',

    OfflineEditsApplied: 'offline-edits-applied',
    BaselineOfflineEdits: 'baseline-offline-edits',

    ShiftOwnerChanged: 'shift-owner-changed',
    ShiftRefreshRequested: 'shift-refresh-requested',

    SelectedBoardChanged: 'selected-board-changed',

    ShowHandoverMeetingSideDrawer: 'show-handover-meeting-side-drawer',

    UiVersionMatchesApiVersion: 'ui-version-matches-api-version',

    ToggleManualWeekTasks: 'toggle-manual-week-tasks',
    ChangeConflict: 'change-conflict',
    ChangeConflictDuringOfflineEdits: 'change-conflict-offline-edits',
    LockFailureDuringOfflineEdits: 'failed-to-lock-offline-edits',
    LocationLockFailed: 'failed-to-lock-location',

    StaffUpdated: 'staff-updated'
};

export const EventBus = bus;
export default bus;
