import { GenericDataApi } from '@/lib/data/GenericDataApi';
import { AxiosError } from 'axios';
import AppHttp from '../AppHttp';
import Errors from '../Errors';
import { PropertyDefinition } from '@/models/api/Data/property-definition';
import {
    DeletePropertyDefinitionCommand,
    UpsertDepartmentPropertyDefinitionCommand,
    UpsertMineAreaPropertyDefinitionCommand,
    UpsertPropertyDefinitionCommandResponse
} from '@/models/api';

export class PropertyDefinitions extends GenericDataApi<PropertyDefinition> {
    constructor() {
        super('PropertyDefinitions');
    }

    public async deletePropertyDefinition(departmentId: string, command: DeletePropertyDefinitionCommand) {
        await AppHttp.delete(`${this.resource}/${command.id}`);
    }

    public async saveDepartmentPropertyDefinition(departmentId: string, command: UpsertDepartmentPropertyDefinitionCommand) : Promise<{id: string}>{
        command.departmentId = departmentId;
        const response = await AppHttp.post(`${this.resource}/department/${departmentId}`, command);
        const data = response ? response.data : null;
        return { id: data.id };
    }

    public async saveMineAreaPropertyDefinition(mineAreaId: string, command: UpsertMineAreaPropertyDefinitionCommand) : Promise<{id: string}>{
        command.mineAreaId = mineAreaId;
        const response = await AppHttp.post(`${this.resource}/minearea/${mineAreaId}`, command);
        const data = response ? response.data : null;
        return { id: data.id };
    }
}

export default new PropertyDefinitions();
