<template>
    <div style="flex: auto">
        <empty-state icon="mdi-arrow-up" message="Please select a shift to begin" />
    </div>
</template>
<script>
export default {
    created() {},
    destroyed() {},
    methods: {}
};
</script>
