import { Coordinates } from '@/models/client/client-coordinates';
import {
    ProductionFrontLocationDiagramInformationViewModel,
    ProductionFrontRingDiagramInformationViewModel, RingRuleEvaluationStatus
} from '@/models/api';

export interface FrontBand {
    closestToDrivePoints: Coordinates[];
    furthestFromDrivePoints: Coordinates[];
    rotation: number;
    rotationPoint: Coordinates
}

export interface ColourBand {
    startNumberExclusive: number | null;
    endNumberInclusive: number | null;
    legendLabel: string;
    colour: string;
}

export function getChargeSleepColourBands(): ColourBand[] {
    return [
        {
            startNumberExclusive: null,
            endNumberInclusive: 1,
            legendLabel: 'Expires within 1 day',
            colour: '#cc3232'
        },
        {
            startNumberExclusive: 1,
            endNumberInclusive: 2,
            legendLabel: 'Expires within 2 days',
            colour: '#db7b2b'
        },
        {
            startNumberExclusive: 2,
            endNumberInclusive: 7,
            legendLabel: 'Expires within 1 week',
            colour: '#e7b416'
        },
        {
            startNumberExclusive: 7,
            endNumberInclusive: null,
            legendLabel: '> 1 week expiry',
            colour: '#99c140'
        },
    ]
}

export function getChargeSleepBand(expiryDays: number): ColourBand | null {
    return getChargeSleepColourBands().find(band=>(band.startNumberExclusive == null || expiryDays > band.startNumberExclusive!) && (band.endNumberInclusive == null || expiryDays <= band.endNumberInclusive)) ?? null;
}

export function getRingEdgeCoordinates(pivotPoint: Coordinates, ringDistanceFromPivotPoint: number, ringLength: number, ringWidth: number, locationRotationDegrees: number, closestEdgeToAccessDrive: boolean): Coordinates[] {
    const addingRingLengthOffset = closestEdgeToAccessDrive ? ringLength : 0;
    const totalDistanceFromPivotPoint = ringDistanceFromPivotPoint+addingRingLengthOffset;

    const middleCoordinate = {
        xCoordinate: pivotPoint.xCoordinate + totalDistanceFromPivotPoint * Math.cos((270 - locationRotationDegrees) * Math.PI / 180),
        yCoordinate: pivotPoint.yCoordinate - totalDistanceFromPivotPoint * Math.sin((270 - locationRotationDegrees) * Math.PI / 180)
    };

    // coordinates have to be from the perspective of the drive, and we're calculating from the end of the tunnel,
    // so we switch what would be our left and right
    const firstEdgeCoordinate = {
        xCoordinate: middleCoordinate.xCoordinate + 0.5*ringWidth * Math.cos((180 - locationRotationDegrees) * Math.PI / 180),
        yCoordinate: middleCoordinate.yCoordinate - 0.5*ringWidth * Math.sin((180 - locationRotationDegrees) * Math.PI / 180)
    };

    return [firstEdgeCoordinate,
        {
            xCoordinate: firstEdgeCoordinate.xCoordinate + ringWidth * Math.cos((360 - locationRotationDegrees) * Math.PI / 180),
            yCoordinate: firstEdgeCoordinate.yCoordinate - ringWidth * Math.sin((360 - locationRotationDegrees) * Math.PI / 180)
        }];
}

export class FrontBandAccumulator {
    private readonly _isBandLimitingRing: (ring: ProductionFrontRingDiagramInformationViewModel) => boolean;

    private readonly _isBandRing: (location: ProductionFrontLocationDiagramInformationViewModel, ring: ProductionFrontRingDiagramInformationViewModel) => boolean;

    private readonly _isLocationIgnored: (location: ProductionFrontLocationDiagramInformationViewModel) => boolean;

    private _currentClosestToDriveFrontCoordinates: Coordinates[] = [];
    private _currentFurthestFromDriveFrontCoordinates: Coordinates[] = [];
    private _niceBands: FrontBand[] = [];
    private _currentFrontBand: FrontBand = {
        rotation: 0,
        rotationPoint: { xCoordinate: 0, yCoordinate: 0},
        closestToDrivePoints: [],
        furthestFromDrivePoints: []
    };
    private _previousLocationIndex: number = 0;
    private _previousLocationRotation: number = 0;
    private _currentAccumulator: LocationFrontBandAccumulator | null = null;
    private _counter: number = 0;

    constructor(isLocationIgnored: (location: ProductionFrontLocationDiagramInformationViewModel)=>boolean, isBandLimitingRing: (ring: ProductionFrontRingDiagramInformationViewModel) => boolean, isBandRing: (location: ProductionFrontLocationDiagramInformationViewModel, ring: ProductionFrontRingDiagramInformationViewModel) => boolean) {
        this._isBandLimitingRing = isBandLimitingRing;
        this._isBandRing = isBandRing;
        this._isLocationIgnored = isLocationIgnored;
    }

    public getLocationFrontBandAccumulator(location: ProductionFrontLocationDiagramInformationViewModel): LocationFrontBandAccumulator {
        this._counter = this._counter + 1;

        if(this._currentAccumulator == null) {
            this.accumulateFrontProgress();
            this.initializeCoordinatesForLocation(location);
        } else {
            const previousLol = this._currentAccumulator.retrieveFrontCoordinates();

            if(previousLol == null) {
                this.accumulateFrontProgress();
                this.initializeCoordinatesForLocation(location);
            } else {
                [this._currentClosestToDriveFrontCoordinates,this._currentFurthestFromDriveFrontCoordinates]=[[...this._currentClosestToDriveFrontCoordinates, ...previousLol[0]], [...this._currentFurthestFromDriveFrontCoordinates, ...previousLol[1]]];

                if(location.productionFrontCoordinates[1] - this._previousLocationIndex > 4 || location.rotationDegrees !== this._previousLocationRotation){
                    this.accumulateFrontProgress();
                    this.initializeCoordinatesForLocation(location);
                }
            }
        }

        this._previousLocationRotation=location.rotationDegrees;
        this._previousLocationIndex=location.productionFrontCoordinates[1];
        this._currentAccumulator = this._isLocationIgnored(location) ? new NoOpLocationFrontBandAccumulator() : new LocalLocationFrontBandAccumulator(location, this._isBandLimitingRing, this._isBandRing);
        return this._currentAccumulator;
    }

    public finaliseAndRetrieveFrontBand(): FrontBand[] {
        this.accumulateFrontProgress();
        return this._niceBands;
    }

    private accumulateFrontProgress() {
        if(this._currentFurthestFromDriveFrontCoordinates.length > 0){
            this._niceBands.push({
                ... this._currentFrontBand,
                closestToDrivePoints: this._currentClosestToDriveFrontCoordinates,
                furthestFromDrivePoints: this._currentFurthestFromDriveFrontCoordinates
            });
        }
    }


    private initializeCoordinatesForLocation(location: ProductionFrontLocationDiagramInformationViewModel){
        this._currentClosestToDriveFrontCoordinates=[];
        this._currentFurthestFromDriveFrontCoordinates=[];
        this._currentFrontBand={
            rotation: location.rotationDegrees,
            rotationPoint:{
                xCoordinate: (location.locationBasePoint?.xCoordinate ?? 0)-location.segmentWidth/2,
                yCoordinate: (location.locationBasePoint?.yCoordinate ?? 0)-location.segmentLength
            },
            closestToDrivePoints: [],
            furthestFromDrivePoints: []
        };
    }
}

export interface LocationFrontBandAccumulator {
    accumulateWithinLocation(pivotPoint: Coordinates, offsetFromPivotPoint: number, ringLength: number, ring: ProductionFrontRingDiagramInformationViewModel): void;
    retrieveFrontCoordinates(): Coordinates[][] | null;
}

class NoOpLocationFrontBandAccumulator implements LocationFrontBandAccumulator {
    accumulateWithinLocation(pivotPoint: Coordinates, offsetFromPivotPoint: number, ringLength: number, ring: ProductionFrontRingDiagramInformationViewModel): void {
        // no-op
    }

    retrieveFrontCoordinates(): Coordinates[][] | null {
        return null;
    }
}

export class LocalLocationFrontBandAccumulator implements  LocationFrontBandAccumulator {
    private _furthestAcceptableRingFromAccessDrive = null as Coordinates[] | null;
    private _closestAcceptableRingToAccessDrive = null as Coordinates[] | null;
    private _closestBandLimitingRingToAccessDrive = null as Coordinates[] | null;

    private readonly _isBandLimitingRing: (ring: ProductionFrontRingDiagramInformationViewModel) => boolean;
    private readonly _isBandRing: (location: ProductionFrontLocationDiagramInformationViewModel, ring: ProductionFrontRingDiagramInformationViewModel) => boolean;
    private _location: ProductionFrontLocationDiagramInformationViewModel;

    constructor(location: ProductionFrontLocationDiagramInformationViewModel, isBandLimitingRing: (ring: ProductionFrontRingDiagramInformationViewModel) => boolean, isBandRing: (location: ProductionFrontLocationDiagramInformationViewModel, ring: ProductionFrontRingDiagramInformationViewModel)=>boolean) {
        this._location = location;
        this._isBandLimitingRing = isBandLimitingRing;
        this._isBandRing = isBandRing;
    }

    public accumulateWithinLocation(pivotPoint: Coordinates, offsetFromPivotPoint: number, ringLength: number, ring: ProductionFrontRingDiagramInformationViewModel): void{
        if(this._closestBandLimitingRingToAccessDrive == null && this._isBandLimitingRing(ring))
            this._closestBandLimitingRingToAccessDrive = getRingEdgeCoordinates(pivotPoint, offsetFromPivotPoint, ringLength, this._location.segmentWidth, this._location.rotationDegrees,  true);

        if(this._closestBandLimitingRingToAccessDrive == null && this._isBandRing(this._location, ring)){
            if(this._closestAcceptableRingToAccessDrive == null) {
                this._closestAcceptableRingToAccessDrive = getRingEdgeCoordinates(pivotPoint, offsetFromPivotPoint, ringLength, this._location.segmentWidth, this._location.rotationDegrees, true);
            }

            this._furthestAcceptableRingFromAccessDrive = getRingEdgeCoordinates(pivotPoint, offsetFromPivotPoint, ringLength, this._location.segmentWidth, this._location.rotationDegrees, false);
        }
    }

    public retrieveFrontCoordinates(): Coordinates[][] | null {
        if(this._closestAcceptableRingToAccessDrive != null && this._furthestAcceptableRingFromAccessDrive != null)
            return [this._closestAcceptableRingToAccessDrive,this._furthestAcceptableRingFromAccessDrive];
        else
            return this._closestBandLimitingRingToAccessDrive != null ? [this._closestBandLimitingRingToAccessDrive,this._closestBandLimitingRingToAccessDrive] : null;
    }
}