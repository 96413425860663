<template>
    <v-snackbar v-model="show" :color="color" :timeout="-1">
        {{ text }}
        <template #action>
            <v-btn dark text @click="close()">Close</v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { Events, EventBus } from '@/lib/EventBus';
export default {
    data() {
        return {
            show: false,
            color: '',
            timeout: 5000,
            text: '',
            queue: [],
            timeoutHandle: null
        };
    },
    created() {
        EventBus.$on(Events.ToastError, this.onError);
        EventBus.$on(Events.ToastSuccess, this.onSuccess);
        EventBus.$on(Events.ToastInfo, this.onInfo);
        EventBus.$on(Events.ToastWarning, this.onWarning);
    },
    destroyed() {
        EventBus.$off(Events.ToastError, this.onError);
        EventBus.$off(Events.ToastSuccess, this.onSuccess);
        EventBus.$off(Events.ToastInfo, this.onInfo);
        EventBus.$off(Events.ToastWarning, this.onWarning);
    },
    methods: {
        render(text, color, mustShow) {
            this.text = text;
            this.color = color;
            this.show = true;
            if (mustShow) {
                this.timeoutHandle = setTimeout(() => this.close(), 30000);
            } else {
                this.timeoutHandle = setTimeout(() => this.close(), this.timeout);
            }
        },
        onError(text, mustShow) {
            this.enqueue(text, 'error', mustShow);
        },
        onSuccess(text, mustShow) {
            this.enqueue(text, 'success', mustShow);
        },
        onInfo(text, mustShow) {
            this.enqueue(text, 'info', mustShow);
        },
        onWarning(text, mustShow) {
            this.enqueue(text, 'warning', mustShow);
        },
        enqueue(text, color, mustShow) {
            if (this.show && this.text == text && this.color == color) {
                return;
            }
            for (let queued of this.queue) {
                if (queued.text == text && queued.color == color) {
                    return;
                }
            }
            this.queue.push({ text, color, mustShow });
            if (!this.show) {
                this.close();
            }
        },
        close() {
            if (this.timeoutHandle) {
                clearTimeout(this.timeoutHandle);
            }
            this.show = false;
            if (this.queue.length) {
                var next = this.queue.shift();
                this.render(next.text, next.color, next.mustShow);
            }
        }
    }
};
</script>
