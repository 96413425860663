import { defineStore } from 'pinia';
import {
    TaskCommunications,
    UpdateShiftCommunicationsCommand, UpdateTaskAndEquipmentCommunicationsCommand,
    UpdateWeeklyCommunicationsCommand
} from '@/models/api';
import { useShiftEquipment } from './ShiftEquipmentStore';
import { ShiftCommunication } from '@/models/api/Queries/shift-communication';
import { useHeadingsStore } from '@/lib/stores/HeadingsStore';
import Logging from '@/lib/Logging';

export const useShiftCommunication = defineStore('shiftCommunication', {
    state: () => {
        return {
            _shift: null as ShiftCommunication | null,
        };
    },
    getters: {
        getTaskCommentsWithEquipment(state): (equipmentId: string, taskId: string) => string | null {
            return (equipmentId: string, taskId: string) => {
                const equipmentPiece = useShiftEquipment().equipmentById(equipmentId);
                const equipmentTasks = useHeadingsStore().getTasksWithEquipment(
                    equipmentPiece.id,
                    equipmentPiece.equipmentId
                );

                const task = equipmentTasks.find((t) => t.id == taskId);

                return task?.comments.find(c=>c.plannedEquipmentId===equipmentId)?.comments ?? '';
            };
        },
        shiftCommunication(): ShiftCommunication | null {
            return this._shift;
        }
    },
    actions: {
        setUpStore(communications: ShiftCommunication) {
            this._shift = communications;
        },
        getShiftCommunication(): ShiftCommunication | null {
            if (this._shift === null) throw new Error('Shift communication not initialised');

            return this._shift;
        },
        updateWeeklyCommunication(command: UpdateWeeklyCommunicationsCommand) {
            const shiftCommunication = this._shift!;

            shiftCommunication.fieldValues = shiftCommunication.fieldValues.map((field) => {
                const matchedIncoming = command.fieldValues.find(x=>x.commsFieldId===field.fieldId);

                if (matchedIncoming === undefined) {
                    return field;
                }

                return {
                    ...field,
                    value: matchedIncoming.value
                };
            });
        },
        updateShiftCommunication(command: UpdateShiftCommunicationsCommand) {
            const shiftCommunication = this._shift!;

            shiftCommunication.priority1 = command.priority1;
            shiftCommunication.priority2 = command.priority2;
            shiftCommunication.priority3 = command.priority3;
            shiftCommunication.fieldValues = shiftCommunication.fieldValues.map((field) => {
                const matchedIncoming = command.fieldValues.find(x=>x.commsFieldId===field.fieldId);

                if (matchedIncoming === undefined) {
                    return field;
                }

                return {
                    ...field,
                    value: matchedIncoming.value
                };
            });
        },
        updateLocalTaskCommunications(taskCommunications: TaskCommunications[]){
            taskCommunications.forEach((task: TaskCommunications) => {
                if (task.plannedEquipmentId === null) {
                    Logging.error(`Could not find planned equipment to update comment ${task.plannedEquipmentId}`);
                    return;
                }

                const plannedEquipment = useShiftEquipment().equipmentById(task.plannedEquipmentId);
                const tasks = useHeadingsStore().getTasksWithEquipmentByShift(
                    plannedEquipment.id,
                    plannedEquipment.equipmentId
                );
                const plannedTaskIndex = tasks.findIndex((x) => x.id == task.plannedTaskId);

                if (plannedTaskIndex == -1)
                    throw new Error(`Could not find planned task ${task.plannedTaskId} in equipment ${plannedEquipment.name}`);

                tasks[plannedTaskIndex].comments = [...tasks[plannedTaskIndex].comments.filter(c=>c.plannedEquipmentId!==task.plannedEquipmentId), {
                    _type: 'TaskCommentsViewModel',
                    plannedEquipmentId: task.plannedEquipmentId,
                    comments: task.comments
                }];
            });
        },
        updateTaskAndEquipmentCommunications(command: UpdateTaskAndEquipmentCommunicationsCommand){
            const shiftCommunication = this._shift!;

            shiftCommunication.equipmentInWorkshop = command.plannedEquipmentInWorkshop.map((equipmentId) => {
                const equipment = useShiftEquipment().equipmentByEquipmentId(equipmentId);

                return {
                    _type: 'EquipmentWorkshopForShiftViewModel',
                    equipmentId: equipmentId,
                    equipmentName: equipment.name
                };
            });
            shiftCommunication.equipmentToWorkshop = command.plannedEquipmentToWorkshop.map((equipmentId) => {
                const equipment = useShiftEquipment().equipmentByEquipmentId(equipmentId);

                return {
                    _type: 'EquipmentWorkshopForShiftViewModel',
                    equipmentId: equipmentId,
                    equipmentName: equipment.name
                };
            });

            this.updateLocalTaskCommunications(command.tasks);
        },
    },
});
