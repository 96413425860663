<template>
    <v-container fluid class="ma-0 px-3 py-0" v-if="shift">
        <template v-if="shift && shift.exists">
            <h1 class="printonly title">Analytics, {{ $route.params.date | formatDate }}</h1>

            <v-row class="ma-0">
                <v-col :cols="12" class="px-0">
                    <h3 class="title">Analytics Week {{ shift.weekNumber }} - {{ shift.weekOf | formatDate }}</h3>
                </v-col>
            </v-row>
            <priority
                v-if="priority1.length"
                :priority="1"
                :rows="priority1"
                :locname="shift.department.locationName"
                :locnameplural="shift.department.locationPluralName"
                :maxSegments="getMaxSegments()"
            ></priority>
            <priority
                v-if="priority2.length"
                :priority="2"
                :rows="priority2"
                :locname="shift.department.locationName"
                :locnameplural="shift.department.locationPluralName"
                :maxSegments="getMaxSegments()"
            ></priority>
            <priority
                v-if="theRest.length"
                :priority="null"
                :rows="theRest"
                :locname="shift.department.locationName"
                :locnameplural="shift.department.locationPluralName"
                :maxSegments="getMaxSegments()"
            ></priority>
        </template>
        <v-row class="ma-0 fill-height" v-if="doesntExist || planDoesntExist">
            <v-col :cols="12">
                <v-access-control>
                    <template #offline>
                        <empty-state
                            icon="mdi-network-strength-4-alert"
                            :message="'No Connection'"
                        ></empty-state>
                    </template>
                    <empty-state
                        v-if="doesntExist && !planDoesntExist"
                        icon="mdi-alert-circle-outline"
                        :message="'Planning for this shift has not started. Start planning from the Shift board'"
                    ></empty-state>
                    <empty-state
                        v-if="planDoesntExist"
                        icon="mdi-alert-circle-outline"
                        :message="
                            'Planning for this week has not started. Create a weekly plan before planning this shift'
                        "
                    ></empty-state>
                </v-access-control>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Shift from '@/lib/data/Shift';
import CurrentShiftStore from '@/lib/stores/CurrentShiftStore';
import Priority from './Analytics/Priority.vue';
import { CycleType } from '@/models/api';

export default {
    components: { Priority },
    computed: {
        developmentRows() {
            return this.shift.rows.filter(x => x.location.cycleType === CycleType.Duration);
        },
        priority1() {
            return this.developmentRows.filter(x => x.priority == 1);
        },
        priority2() {
            return this.developmentRows.filter(x => x.priority == 2 && x.cycle);
        },
        theRest() {
            return this.developmentRows.filter(x => x.priority > 2);
        }
    },
    data() {
        return {
            shift: null,
            plannedEquipment: null,
            doesntExist: false,
            planDoesntExist: false
        };
    },
    async created() {
        await this.loadData();
    },
    destroyed() {},
    methods: {
        async loadData() {
            CurrentShiftStore.setBoard('priorityanalytics');
            try {
                this.$wait.start('get data');
                this.shift = await Shift.lastShift(
                    this.$route.params.mine,
                    this.$route.params.dept,
                    this.$route.params.date
                );

                if (!this.shift.exists) {
                    this.doesntExist = true;
                    this.planDoesntExist = !this.shift.planExists;
                    return;
                }
            } catch (e) {
                return;
            } finally {
                this.$wait.end('get data');
            }
        },
        getMaxSegments() {
            var max = 0;
            for (let row of this.shift.rows) {
                var complete = row.progress.complete + row.progress.pending;
                if (complete > max) {
                    max = complete;
                }
                if (row.progress.target > max) {
                    max = row.progress.target;
                }
            }
            return max;
        }
    }
};
</script>

<style lang="scss" scoped></style>
