import { GenericDataApi } from '@/lib/data/GenericDataApi';
import AppHttp from '../AppHttp';
import { Stockpile } from '@/models/api/Data/stockpile';
import { StockpileStatus } from '@/models/api/Data/stockpile-status';
import { UpdateStockpileStatusCommand } from '@/models/api';

export class Stockpiles extends GenericDataApi<any> {
    constructor() {
        super('Stockpiles');
    }

    public async updateStatus(id: string, status: StockpileStatus) {
        const command: UpdateStockpileStatusCommand = {
            _type: 'UpdateStockpileStatusCommand',
            stockpileId: id,
            status: status
        };
        const response = await AppHttp.post(`${this.resource}/status`, command);

        const data = response ? response.data : [] as Stockpile[];
        return data;
    }

    public async getForMineArea(mineAreaId: string, include?: string[]): Promise<Stockpile[]> {
        let query = '';
        if (include) {
            const p: string = include.map((x) => `include=${x}`).join('&');
            query = '?' + p;
        }

        const response = await AppHttp.get(`${this.resource}/MineAreas/${mineAreaId}${query}`);

        const data = response ? response.data : null;
        if(data == null) throw Error('No response data');
        return data;
    }
}

export default new Stockpiles();
