import { DefinitionType, ExtendedPropertyInfo, PropertyDefinitionModel } from '@/models/api';
import {
    AddReasonForColumnToBeOnBoard,
    AddReasonToColumn,
    BoardPropertyFilterGroupSortInfo, ColumnInformation,
    ExtraColumnInfo,
    ExtraColumnReasonToExist,
    GroupingInformation, RemoveAllReasonsForColumnToBeOnBoard,
    RemoveReasonForColumnsToBeOnBoard,
    RemoveReasonForColumnToBeOnBoard,
    RemoveReasonForOtherColumnsToBeOnBoard,
    SortDirection,
    SortingDefinition
} from '@/components/Board/GroupingFilteringAndSorting';
import { BoardGroupingType, GroupingOption } from '@/components/ShiftBoard/Drawers/BoardGroupingDrawerProps';
import { CELL_SIZE } from '@/models/client/cell-sizes';
import {
    LocationPropertyFilterInfo,
    LocationPropertyFilterInstructions
} from '@/models/client/location-property-filter-info';
import { defineStore } from 'pinia';
import _ from 'lodash';
import { GroupedRow } from '@/lib/stores/BoardManipulation/models';

const LOCAL_STORAGE_MANIPULATION_SUFFIX: string = '.COLUMNS';
const LOCAL_STORAGE_GROUP_SUFFIX: string = '.GROUP';
const LOCAL_STORAGE_SORT_SUFFIX: string = '.SORT';

export function alphabeticSortingFunc<T>(rows: GroupedRow<T>[], direction: SortDirection, selector: (row: T)=>string): GroupedRow<T>[] {
    const transformingCurriedSortLocations = direction === SortDirection.Ascending ?
        (a: GroupedRow<T>, b: GroupedRow<T>)=>selector(a.headingRow).localeCompare(selector(b.headingRow))
        : (a: GroupedRow<T>, b: GroupedRow<T>)=>selector(b.headingRow).localeCompare(selector(a.headingRow));

    const additionalRowsSortFunc = direction === SortDirection.Ascending ?
        (a: T, b: T)=>selector(a).localeCompare(selector(b))
        : (a: T, b: T)=>selector(b).localeCompare(selector(a));

    return rows
        .map(x=>({
            headingRow: x.headingRow,
            additionalRows: [...x.additionalRows].sort(additionalRowsSortFunc)
        }))
        .sort(transformingCurriedSortLocations);
}

function createExtraColumnFromManipulationInfo<T, V>(manipulationInfo: BoardPropertyFilterGroupSortInfo<T, V>): ExtraColumnInfo<T> {
    if(manipulationInfo.displayValueSelector == null)
        throw new Error('Display value selector must be defined for manipulation info');

    return {
        id: manipulationInfo.id,
        name: manipulationInfo.name,
        selector: manipulationInfo.displayValueSelector,
        columnNameOverride: manipulationInfo.columnNameOverride ?? manipulationInfo.name,
        columnSize: manipulationInfo.columnSize,
        reasonToExist: manipulationInfo.reasonOnBoard
    };
}

export function defineManipulationStore<T extends { location: { extendedProperties: ExtendedPropertyInfo[]} | null},V>(
    storeName: string,
    localStoragePrefix: string,
    extendedPropertyGroupingFunc: (extendedPriorityId: string, headings: T[])=>V[],
    generateExtendedPropertySortingFunc: (extendedPropertyId: string)=>(a: V[], direction: SortDirection)=>V[],
    defaultGrouping: GroupingInformation<T, V>,
    defaultSortingInfo: SortingDefinition<V>,
    defaultManipulationInformation: BoardPropertyFilterGroupSortInfo<T, V>[] = [],
    includeDefaultGroupingAsOption: boolean = true
) {
    const LOCAL_STORAGE_MANIPULATION_KEY = localStoragePrefix + LOCAL_STORAGE_MANIPULATION_SUFFIX;
    const LOCAL_STORAGE_GROUP_KEY = localStoragePrefix + LOCAL_STORAGE_GROUP_SUFFIX;
    const LOCAL_STORAGE_SORT_KEY = localStoragePrefix + LOCAL_STORAGE_SORT_SUFFIX;

    const state = ()=>{
        return {
            extendedProperties: [] as PropertyDefinitionModel[],
            manipulationInformation: [] as BoardPropertyFilterGroupSortInfo<T, V>[],
            defaultGrouping: defaultGrouping as GroupingInformation<T, V>,
            defaultGroupingId: null as string | null,
            defaultSortingInfo: defaultSortingInfo as SortingDefinition<V>,
            overrideSortingInfo: null as SortingDefinition<V> | null,
            grouping: null as GroupingInformation<T, V> | null,
        };
    }

    return ()=>defineStore(storeName, {
        state: state,
        actions: {
            rehydrateViewSettings(extendedProperties: PropertyDefinitionModel[]) {
                const savedViewSettings = localStorage.getItem(LOCAL_STORAGE_MANIPULATION_KEY);
                const savedGrouping = localStorage.getItem(LOCAL_STORAGE_GROUP_KEY);
                const savedSort = localStorage.getItem(LOCAL_STORAGE_SORT_KEY);
                const defaultViewSettings = this.calculateDefaultState(extendedProperties);
                if (savedViewSettings) {
                    const parsedViewSettings = JSON.parse(savedViewSettings);
                    this.manipulationInformation = defaultViewSettings.map(x=>{
                        const savedColumnInfo = parsedViewSettings.find((y: BoardPropertyFilterGroupSortInfo<T, V>)=>y.id === x.id);
                        if(savedColumnInfo == null)
                            return x;

                        const reasonWithoutFiltering = savedColumnInfo.reasonOnBoard & ~ExtraColumnReasonToExist.Filtering;

                        return AddReasonToColumn(x, reasonWithoutFiltering);
                    });
                } else {
                    this.manipulationInformation = defaultViewSettings;
                }

                if(savedGrouping) {
                    const parsedGrouping = JSON.parse(savedGrouping);
                    this.groupByColumnId(parsedGrouping.id);
                }


                if(savedSort) {
                    const parsedSort = JSON.parse(savedSort);
                    this.sortByColumn(parsedSort.columnId, parsedSort.direction);
                }
            },
            saveViewSettings(): void {
                localStorage.setItem(LOCAL_STORAGE_MANIPULATION_KEY, JSON.stringify(RemoveReasonForColumnsToBeOnBoard(this.manipulationInformation, ExtraColumnReasonToExist.Filtering)));

                if(this.grouping != null)
                    localStorage.setItem(LOCAL_STORAGE_GROUP_KEY, JSON.stringify(this.grouping));
                else
                    localStorage.removeItem(LOCAL_STORAGE_GROUP_KEY);

                if(this.overrideSortingInfo != null)
                    localStorage.setItem(LOCAL_STORAGE_SORT_KEY, JSON.stringify(this.overrideSortingInfo));
                else
                    localStorage.removeItem(LOCAL_STORAGE_SORT_KEY);
            },
            resetToDefaults(saveSettings: boolean = true) {
                this.groupByDefault(saveSettings);
                this.sortByDefault(saveSettings);
            },
            calculateDefaultState(extendedProperties: PropertyDefinitionModel[]): BoardPropertyFilterGroupSortInfo<T, V>[] {
                const locationHasOneOfSuppliedValuesForExtendedProperty = (propId: string, propValues: (string | number)[], heading: T) => (heading?.location?.extendedProperties.findIndex((prop: ExtendedPropertyInfo)=>prop.propertyDefinitionId===propId && propValues.indexOf(prop.value ?? '') > -1) ?? -1) > -1;

                return [
                    ... defaultManipulationInformation,
                    ...extendedProperties.map(prop=>({
                        id: prop.id,
                        name: prop.name ?? '',
                        displayValueSelector: (location: T) => location.location?.extendedProperties.filter(ep=>ep.propertyDefinitionId === prop.id)[0]?.value ?? '',
                        groupingDefinition: prop.definitionType === DefinitionType.Fixed ? {
                            text: prop.name!,
                            id: prop.id,
                            type: BoardGroupingType.CUSTOM_PROPERTY,
                            furtherInformation: prop.id,
                            groupingFunc: (headings: T[]) => extendedPropertyGroupingFunc(prop.id, headings)
                        } : null,
                        columnSize: 'x' as CELL_SIZE,
                        propertyFilter: prop.definitionType === DefinitionType.Fixed ? {
                            id: prop.id,
                            name: prop.name ?? '',
                            options: prop.acceptedValues.map(pv=>({ id: pv.value ?? '', name: pv.value ?? '' })),
                            supportEmptyValue: false,
                            selectedIds: [],
                            emptySelected: false,
                            emptyValueOption: { id: '', name: 'Unknown' },
                            filterGenerator: (selectedIds: (string | number)[], includedEmpty: boolean) => (location: T) => locationHasOneOfSuppliedValuesForExtendedProperty(prop.id, selectedIds, location)
                        } : null,
                        sorting: {
                            sortFunc: generateExtendedPropertySortingFunc(prop.id),
                        },
                        reasonOnBoard: ExtraColumnReasonToExist.None,
                    }))];
            },
            populateStore(defaultGroupingId: string, defaultSorting: SortingDefinition<V>, extendedProperties: PropertyDefinitionModel[]) {

                this.defaultGroupingId = defaultGroupingId;
                this.defaultSortingInfo = defaultSorting;
                this.extendedProperties = extendedProperties;

                this.resetToDefaults(false);

                this.rehydrateViewSettings(extendedProperties);

                this.saveViewSettings();
            },
            populateStoreWithDefaultGrouping(defaultGrouping: GroupingInformation<T, V>, defaultSorting: SortingDefinition<V>, extendedProperties: PropertyDefinitionModel[]) {
                this.defaultGrouping = defaultGrouping;
                this.defaultGroupingId = defaultGrouping.id;
                this.defaultSortingInfo = defaultSorting;
                this.extendedProperties = extendedProperties;

                this.resetToDefaults(false);

                this.rehydrateViewSettings(extendedProperties);

                this.saveViewSettings();
            },

            /*Sorting methods*/
            setSortingInformationExplicit(sortingInfo: SortingDefinition<V>) {
                this.manipulationInformation = RemoveReasonForOtherColumnsToBeOnBoard(this.manipulationInformation, sortingInfo.columnId, ExtraColumnReasonToExist.Sorting);
                this.overrideSortingInfo = sortingInfo;
            },
            sortByExtraColumn(column: ExtraColumnInfo<T>, direction: SortDirection) {
                this.sortByColumn(column.id, direction);
            },
            sortByColumn(columnId: string, direction: SortDirection) {
                const manipulationDefinition = this.manipulationInformation.find(x=>x.id === columnId);

                if(manipulationDefinition == null || manipulationDefinition.sorting == null)
                    return;

                this.setSortingInformationExplicit({
                    columnId: columnId,
                    direction: direction,
                    sortFunc: manipulationDefinition.sorting.sortFunc
                });

                if(manipulationDefinition.displayValueSelector != null)
                    this.manipulationInformation = AddReasonForColumnToBeOnBoard(this.manipulationInformation, manipulationDefinition.id, ExtraColumnReasonToExist.Sorting);

                this.saveViewSettings();
            },
            sortByDefault(saveSettings: boolean = true) {
                this.overrideSortingInfo = null;
                this.manipulationInformation = RemoveReasonForColumnsToBeOnBoard(this.manipulationInformation, ExtraColumnReasonToExist.Sorting);

                if(saveSettings)
                    this.saveViewSettings();
            },
            sortDirectionForColumn(extraColumn: ExtraColumnInfo<T>): SortDirection | null {
                return this.sortingInformation.columnId === extraColumn.id ? this.sortingInformation.direction : null;
            },
            canSortByExtraColumn(extraColumn: ExtraColumnInfo<T>) {
                return this.manipulationInformation.find(x=>x.id === extraColumn.id)?.sorting != null;
            },

            /*Grouping methods*/
            isColumnGroupedBy(columnId: string): boolean {
                return this.grouping?.id === columnId;
            },
            canGroupByExtraColumn(extraColumn: ExtraColumnInfo<T>) {
                return this.manipulationInformation.find(x=>x.id === extraColumn.id)?.groupingDefinition != null;
            },
            groupByColumnId(columnId: string) {
                const groupingDefinition = this.manipulationInformation.find(x=>x.id === columnId)?.groupingDefinition;

                if(groupingDefinition != null)
                    this.setGrouping(groupingDefinition);
            },
            groupByExtraColumn(column: ExtraColumnInfo<T>) {
                this.groupByColumnId(column.id);
            },
            setGrouping(selectedGroupingOption: GroupingOption) {
                if(selectedGroupingOption.type === BoardGroupingType.DEFAULT)
                    return this.groupByDefault();

                const manipulationDefinition = this.manipulationInformation.find(x=>x.id === selectedGroupingOption.id);

                if(manipulationDefinition == null || manipulationDefinition.groupingDefinition == null || manipulationDefinition.displayValueSelector == null)
                    return;

                this.manipulationInformation = RemoveReasonForOtherColumnsToBeOnBoard(this.manipulationInformation, manipulationDefinition.id, ExtraColumnReasonToExist.Grouping);
                this.manipulationInformation = AddReasonForColumnToBeOnBoard(this.manipulationInformation, manipulationDefinition.id, ExtraColumnReasonToExist.Grouping);


                this.grouping = manipulationDefinition.groupingDefinition;

                this.saveViewSettings();
            },
            groupByDefault(saveSettings: boolean = true) {
                this.manipulationInformation = RemoveReasonForColumnsToBeOnBoard(this.manipulationInformation, ExtraColumnReasonToExist.Grouping);
                this.grouping = null;

                if(saveSettings)
                    this.saveViewSettings();
            },
            isGroupedByDefault(): boolean {
                return this.grouping == null;
            },

            /*Filtering methods*/
            isColumnFiltered(columnId: string): boolean {
                const manipulationDefinition = this.manipulationInformation.find(x=>x.id === columnId);

                if(manipulationDefinition == null || (manipulationDefinition.reasonOnBoard & ExtraColumnReasonToExist.Filtering) !== ExtraColumnReasonToExist.Filtering)
                    return false;

                return true;
            },
            addSearchFilter(filterId: string, selectedIds: string[], emptySelected: boolean): void {
                const manipulationDefinition = this.manipulationInformation.find(x=>x.id === filterId);

                if(manipulationDefinition == null || manipulationDefinition.propertyFilter == null)
                    return;

                this.manipulationInformation = RemoveReasonForOtherColumnsToBeOnBoard(this.manipulationInformation, filterId, ExtraColumnReasonToExist.Filtering);

                this.manipulationInformation.splice(this.manipulationInformation.findIndex(x=>x.id === filterId), 1, {
                    ...manipulationDefinition,
                    propertyFilter: {
                        ...manipulationDefinition.propertyFilter,
                        selectedIds: selectedIds,
                        emptySelected: emptySelected
                    }
                });

                if(manipulationDefinition.displayValueSelector != null)
                    this.manipulationInformation = AddReasonForColumnToBeOnBoard(this.manipulationInformation, manipulationDefinition.id, ExtraColumnReasonToExist.Filtering);
            },
            removeSearchFilter(filterId: string): void {
                const manipulationDefinition = this.manipulationInformation.find(x=>x.id === filterId);

                if(manipulationDefinition == null || manipulationDefinition.propertyFilter == null)
                    return;

                this.manipulationInformation = RemoveReasonForColumnToBeOnBoard(this.manipulationInformation, filterId, ExtraColumnReasonToExist.Filtering);
                this.manipulationInformation.splice(this.manipulationInformation.findIndex(x=>x.id === filterId), 1, {
                    ...manipulationDefinition,
                    propertyFilter: {
                        ...manipulationDefinition.propertyFilter,
                        selectedIds: [],
                        emptySelected: false
                    }
                });
            },

            filterInstructionsForColumn(extraColumn: ExtraColumnInfo<T>): LocationPropertyFilterInstructions | null {
                const manipulationDefinition = this.manipulationInformation.find(x=>x.id === extraColumn.id);

                return manipulationDefinition?.propertyFilter ?? null;
            },

            /*Column addition/removal*/
            manuallyAddColumn(columnId: string) {
                const manipulationDefinition = this.manipulationInformation.find(x=>x.id === columnId);

                if(manipulationDefinition == null || manipulationDefinition.displayValueSelector == null)
                    return;

                this.manipulationInformation = AddReasonForColumnToBeOnBoard(this.manipulationInformation, manipulationDefinition.id, ExtraColumnReasonToExist.Manual);

                this.saveViewSettings();
            },
            manuallyRemoveColumn(columnId: string){
                const existingColumn = this.extraColumns.find(x=>x.id === columnId);

                // obviously can't remove a column that's not there... and you're not allowed to remove default columns
                if(existingColumn == null || (existingColumn.reasonToExist & ExtraColumnReasonToExist.Grouping) === ExtraColumnReasonToExist.Default)
                    return;

                if(this.sortDirectionForColumn(existingColumn) != null)
                    this.sortByDefault();

                if(this.isColumnGroupedBy(columnId))
                    this.groupByDefault();

                if(this.isColumnFiltered(columnId))
                    this.removeSearchFilter(columnId);

                this.manipulationInformation = RemoveAllReasonsForColumnToBeOnBoard(this.manipulationInformation, columnId);

                this.saveViewSettings();
            }
        },
        getters: {
            columnOptions(): ColumnInformation[] {
                return this.manipulationInformation.filter(x=>x.displayValueSelector != null).map(x=>({
                    id: x.id,
                    name: x.name,
                    columnNameOverride: x.columnNameOverride ?? x.name,
                    columnSize: x.columnSize,
                    reasonToExist: x.reasonOnBoard
                }));
            },
            extraColumns(): ExtraColumnInfo<T>[] {
                return this.manipulationInformation.filter(x=>x.reasonOnBoard !== ExtraColumnReasonToExist.None)
                    .map(x=>createExtraColumnFromManipulationInfo(x));
            },
            groupingOptions(): GroupingOption[] {
                const defaultGroupingOption = includeDefaultGroupingAsOption ? [this.defaultGrouping] : [];
                return [...defaultGroupingOption, ...this.manipulationInformation.filter(x=>x.groupingDefinition != null).map(x=>x.groupingDefinition!)];
            },
            selectedGrouping(): GroupingInformation<T, V> {
                return this.grouping ?? this.safeDefaultGrouping!;
            },
            selectedGroupingFunc(): (rows: T[]) => V[] {
                return this.selectedGrouping.groupingFunc;
            },
            hasExtraColumns(): boolean {
                return this.extraColumns.length > 0;
            },
            locationPropertyFilters(): LocationPropertyFilterInfo<T>[] {
                return this.manipulationInformation.filter(m=>m.propertyFilter != null).map(m=>m.propertyFilter!);
            },
            sortingInformation(): SortingDefinition<V> {
                return this.overrideSortingInfo ?? this.defaultSortingInfo;
            },
            activeFacetFilters(): { [key: string]: (location: T)=>boolean } {
                return this.locationPropertyFilters.filter(f=>f.emptySelected || f.selectedIds.length > 0).reduce((acc, cur)=>{
                    acc[cur.id] = cur.filterGenerator(cur.selectedIds, cur.emptySelected);
                    return acc;
                }, {} as { [key: string]: (location: T)=>boolean });
            },
            activeSortFunction(): (rows: V[]) => V[] {
                return _.curryRight(this.sortingInformation.sortFunc)(this.sortingInformation.direction);
            },
            safeDefaultGrouping(): GroupingInformation<T, V> {
                if(this.defaultGroupingId != null) {
                    const defaultGrouping = this.manipulationInformation.find(x=>x.id === this.defaultGroupingId)?.groupingDefinition;
                    if(defaultGrouping != null)
                        return defaultGrouping;
                }

                return this.defaultGrouping;
            }
        }
    });
}