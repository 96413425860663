import { defineStore } from 'pinia';
import {
    BlastPacketForOffsetViewModel,
    BlastPacketLocationForOffsetViewModel,
    BlastPacketRingForOffsetViewModel,
    BlastPacketRingTargetCompletionDto,
    BlastPacketTargetForOffsetViewModel,
    NewActualPhysicalsEntryDto,
    ProductionFrontRulesViewModel,
    RateMetric,
    RingRuleEvaluationState,
    RingStatesForWindowViewModel,
    RingStatesLocationForWindowViewModel,
    UndoBlastPacketRingTargetCompletionDto,
    UpdateActualPhysicalsEntriesCommand,
    UpdateActualPhysicalsEntryDto
} from '@/models/api';
import { useSystemStore } from '@/lib/stores/SystemStore';
import BlastPackets from '@/lib/data/BlastPackets';
import { AffectedAreaSpecification, useHeadingsStore } from '@/lib/stores/HeadingsStore';
import { useShiftDetails } from '@/lib/stores/Shift';
import { TimespanContainsTask } from '@/lib/services/Task';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useDepartmentStore } from '@/lib/stores/DepartmentStore';
import { BlastPacketRingTargetEvaluation, LocationRingStateMachine } from '@/lib/services/Production';
import {
    ClientBlastPacketActualsEntry,
    ClientBlastPacketTargetCompletion,
    ClientBlastPacketTargetCompletionUndo
} from '@/models/client/client-actuals';
import ActualPhysicals from '@/lib/data/ActualPhysicals';
import { transformActualsToClient } from '@/lib/stores/Transforms';
import UserStore from '@/lib/stores/UserStore';
import { useWindowedActuals } from '@/lib/stores/Production/WindowedActualsStore';
import { RowType } from '@/models/client/types/row-type';
import { ProductionValidationTaskModel } from '@/models/client/production-validation-task-model';
import { ProductionValidationRow } from '@/models/client/production-validation-row';
import selectedMineAreaStore from '@/lib/stores/SelectedMineAreaStore';
import { validateProductionFrontRules } from '@/components/ShiftBoard/Board/Validation';
import { ClientTaskWarning } from '@/models/client/client-task-warning';
import { DefaultTaskBackgroundColour, DefaultTaskForegroundColour } from '@/lib/services/TaskType';

export interface LocationBlastPacketTargetShiftInformation {
    locationId: string,
    locationName: string,
    blastPacketId: string,
    blastPacketName: string,
    ringId: string | null,
    ringName: string | null,
    targetId: string,
    targetName: string,
    targetOrder: number,
    targetForegroundColour: string,
    targetBackgroundColour: string,
    targetRateMetric: RateMetric | null,
    canHaveActuals: boolean,
    plannedInShift: number | null,
    remainingActualsEstimate: number | null,
    completion: LocationBlastPacketTargetShiftCompletion | null,
    recordedActuals: LocationBlastPacketTargetShiftActuals[]
}

export interface LocationBlastPacketTargetShiftCompletion {
    id: string,
    reason?: string,
    completedBy: string,
    completedAt: dayjs.Dayjs
}

export interface LocationBlastPacketTargetShiftActuals {
    id: string,
    quantity: number,
    startTime?: dayjs.Dayjs,
    endTime: dayjs.Dayjs,
    comment?: string | null
}

export interface BlastPacketActualsSuggestion {
    id?: string | null,
    targetId: string,
    locationName: string,
    locationId: string,
    ringName: string,
    targetName: string,
    targetOrder: number,
    quantity?: number,
    startTime?: dayjs.Dayjs,
    endTime: dayjs.Dayjs,
    comment?: string | null,
    completed: boolean,
    completionId?: string | null,
    dirty: boolean,
    delete: boolean
}

export interface ClientRowBlastPacketInformation {
    blastPacketName: string,
    blastPacketId: string,
    locationId: string,
    fireTarget: ClientRowBlastPacketTargetInformation,
    bogTarget: ClientRowBlastPacketTargetInformation,
    rings: ClientRowBlastPacketRingInformation[],
}

export interface ClientRowBlastPacketRingInformation {
    ringName: string,
    ringId: string,
    locationId: string,
    targets: ClientRowBlastPacketTargetInformation[],
}

export interface ClientRowBlastPacketTargetInformation {
    targetId: string,
    taskName: string,
    taskBackgroundColour: string | null,
    taskForegroundColour: string | null,
    targetQuantity: number,
    taskTypeId: string,
    order: number,
    defaultRate: number | null,
    isCompletedOrReachedTarget: boolean,
    tolerance: number,
}

export interface ClientRowBlastPacketTargetDisplayInformation {
    targetId: string,
    blastPacketName: string,
    blastPacketId: string,
    ringName: string | null,
    ringId: string | null,
}

function convertLocationTargetCompletionToShiftInformation(targetCompletion?: ClientBlastPacketTargetCompletion): LocationBlastPacketTargetShiftCompletion | null {
    if(targetCompletion == null)
        return null;

    return {
        id: targetCompletion.id,
        completedAt: targetCompletion.completedAt,
        completedBy: 'TODO',
        reason: targetCompletion.completionReason ?? undefined
    };
}

function convertServerBlastPacketTargetToClientTarget(t: BlastPacketTargetForOffsetViewModel): ClientRowBlastPacketTargetInformation {
    return {
        targetId: t.id ?? '',
        taskName: t.taskName ?? 'Unknown',
        taskBackgroundColour: t.taskBackgroundColour,
        taskForegroundColour: t.taskForegroundColour,
        targetQuantity: t.target,
        taskTypeId: t.taskTypeId ?? '',
        order: t.taskOrder,
        defaultRate: t.ratePerHour ?? null,
        isCompletedOrReachedTarget: t.markedCompleted || t.actualsPercentage >= 100,
        tolerance: t.tolerance,
    };
}

function convertActualsEntriesToShiftInformation(actualsEntries: ClientBlastPacketActualsEntry[]): LocationBlastPacketTargetShiftActuals[] {
    return actualsEntries.map(entry=>({
        id: entry.id,
        startTime: entry.startTime,
        endTime: entry.endTime,
        comment: entry.comment,
        quantity: entry.quantity
    }));
}

export interface ClientUpdateActualPhysicalsEntriesCommand {
    entriesToDelete: string[];
    completionsToUndo: ClientBlastPacketTargetCompletionUndo[];
    blastPacketRingTargetsToComplete: ClientBlastPacketTargetCompletion[];
    entriesToAdd: ClientBlastPacketActualsEntry[];
    entriesToUpdate: ClientBlastPacketActualsEntry[];
}

function convertClientCommandToServerCommand(clientCommand: ClientUpdateActualPhysicalsEntriesCommand, departmentId: string): UpdateActualPhysicalsEntriesCommand {
    return {
        _type: 'UpdateActualPhysicalsEntriesCommand',
        departmentId: departmentId,
        entriesToDelete: clientCommand.entriesToDelete,
        completionsToUndo: clientCommand.completionsToUndo.map((undo:ClientBlastPacketTargetCompletionUndo)=>({
            _type: 'UndoBlastPacketRingTargetCompletionDto',
            completionId: undo.completionId,
            undoReason: undo.undoReason
        })),
        blastPacketRingTargetsToComplete: clientCommand.blastPacketRingTargetsToComplete.map((comp:ClientBlastPacketTargetCompletion)=>({
            _type: 'BlastPacketRingTargetCompletionDto',
            id: comp.id,
            blastPacketRingTargetId: comp.targetId,
            completedAt: comp.completedAt.toDate(),
            completionReason: comp.completionReason ?? null
        })),
        entriesToAdd: clientCommand.entriesToAdd.map((entry:ClientBlastPacketActualsEntry)=>({
            _type: 'NewActualPhysicalsEntryDto',
            id: entry.id,
            blastPacketRingTargetId: entry.targetId,
            startTime: entry.startTime?.toDate() ?? undefined,
            endTime: entry.endTime.toDate(),
            comment: entry.comment ?? null,
            quantity: entry.quantity,
        })),
        entriesToUpdate: clientCommand.entriesToUpdate.map((entry:ClientBlastPacketActualsEntry)=>({
            _type: 'UpdateActualPhysicalsEntryDto',
            id: entry.id,
            blastPacketRingTargetId: entry.targetId,
            startTime: entry.startTime?.toDate() ?? undefined,
            endTime: entry.endTime.toDate(),
            comment: entry.comment ?? null,
            quantity: entry.quantity,
        })),
        isOfflineReplay: false,
        attemptedAt: new Date()
    };
}

export const useShiftWindowActuals = defineStore('shiftWindowActuals', {
    state: () => {
        return {
            locationBlastPacketInformation: [] as BlastPacketLocationForOffsetViewModel[]
        };
    },
    getters: {
        locationBlastPacketRingStateInformation(): RingStatesLocationForWindowViewModel[] {
            return useWindowedActuals().locationBlastPacketRingStateInformation;
        },
        getBlastPacketsInformation(state): ClientRowBlastPacketInformation[] {
            return _.flatMap(
                state.locationBlastPacketInformation,
                l=>_.flatMap(
                    l.blastPackets,
                    bp=> ({
                        blastPacketId: bp.id,
                        blastPacketName: bp.name ?? '',
                        bogTarget: convertServerBlastPacketTargetToClientTarget(bp.bogTarget!),
                        fireTarget: convertServerBlastPacketTargetToClientTarget(bp.fireTarget!),
                        locationId: l.id,
                        rings: _.flatMap(
                            bp.rings,
                            r=> ({
                                ringId: r.locationRingId ?? '',
                                ringName: r.locationRingName ?? 'unknown',
                                locationId: r.locationId!,
                                targets: r.targets.map(t=>convertServerBlastPacketTargetToClientTarget(t))
                            })
                        )
                    })
                )
            );
        },
        getBlastPacketRingsInformation(state): ClientRowBlastPacketRingInformation[] {
            return _.flatMap(
                state.locationBlastPacketInformation,
                l=>_.flatMap(
                    l.blastPackets,
                    bp=>_.flatMap(
                        bp.rings,
                        r=> ({
                            ringId: r.locationRingId ?? '',
                            ringName: r.locationRingName ?? 'unknown',
                            locationId: l.id,
                            targets: r.targets.map(t=>({
                                targetId: t.id ?? '',
                                taskName: t.taskName ?? 'Unknown',
                                taskBackgroundColour: t.taskBackgroundColour,
                                taskForegroundColour: t.taskForegroundColour,
                                targetQuantity: t.target,
                                taskTypeId: t.taskTypeId ?? '',
                                order: t.taskOrder,
                                defaultRate: t.ratePerHour ?? null,
                                isCompletedOrReachedTarget: t.markedCompleted || t.actualsPercentage >= 100,
                                tolerance: t.tolerance,
                            }))
                        })
                    )
                )
            );
        },
        getBlastPacketTargetsDisplayInformation(state): ClientRowBlastPacketTargetDisplayInformation[] {
            return _.flatMap(
                state.locationBlastPacketInformation,
                l=>_.flatMap(
                    l.blastPackets,
                    bp=>([
                        {
                            targetId: bp.fireTarget!.id!,
                            blastPacketName: bp.name ?? '',
                            blastPacketId: bp.id,
                            ringName: null,
                            ringId: null
                        },
                        {
                            targetId: bp.bogTarget!.id!,
                            blastPacketName: bp.name ?? '',
                            blastPacketId: bp.id,
                            ringName: null,
                            ringId: null,
                        },
                        ..._.flatMap(
                            bp.rings,
                            r=> _.flatMap(
                                r.targets,
                                t=>({
                                    targetId: t.id!,
                                    blastPacketName: bp.name ?? '',
                                    blastPacketId: bp.id,
                                    ringName: r.locationRingName ?? '',
                                    ringId: r.locationRingId
                                })
                            ))
                        ])
                    )
                );
        },
        getBlastPacketTargetInformation(state): LocationBlastPacketTargetShiftInformation[] {
            const shiftDetailsStore = useShiftDetails();
            const blastPacketTasksInShift = useHeadingsStore().tasks.filter(t=> !!t.blastPacketRingTargetId && TimespanContainsTask(t,shiftDetailsStore.shiftStartTime, shiftDetailsStore.shiftEndTime));

            return _.flatMap(
                state.locationBlastPacketInformation,
                l=>_.flatMap(
                    l.blastPackets,
                    bp=>([
                        {
                            locationId: l.id,
                            locationName: l.name ?? '',
                            ringId: null,
                            ringName: null,
                            blastPacketId: bp.id,
                            blastPacketName: bp.name!,
                            targetId: bp.bogTarget!.id!,
                            targetName: bp.bogTarget!.taskName!,
                            targetOrder: bp.bogTarget?.taskOrder ?? 0,
                            targetForegroundColour: bp.bogTarget?.taskForegroundColour ?? DefaultTaskForegroundColour,
                            targetBackgroundColour: bp.bogTarget?.taskBackgroundColour ?? DefaultTaskBackgroundColour,
                            targetRateMetric: bp.bogTarget?.rateMetric ?? null,
                            canHaveActuals: bp.bogTarget?.ratePerHour != null,
                            plannedInShift: blastPacketTasksInShift.filter(bprt=>bprt.blastPacketRingTargetId === bp.bogTarget!.id).reduce((curr,prev)=>(prev.quantity ?? 0) + (curr ?? 0), null as number | null),
                            remainingActualsEstimate: Math.max(0, (100 - bp.bogTarget!.actualsPercentage) * bp.bogTarget!.target / 100),
                            completion: convertLocationTargetCompletionToShiftInformation(useWindowedActuals().localBlastPacketTargetCompletions.find(comp=>comp.targetId === bp.bogTarget!.id)),
                            recordedActuals: convertActualsEntriesToShiftInformation(this.getActualsInCurrentShift.filter(entry=>entry.targetId === bp.bogTarget!.id)) as LocationBlastPacketTargetShiftActuals[]
                        },
                        {
                            locationId: l.id,
                            locationName: l.name ?? '',
                            ringId: null,
                            ringName: null,
                            blastPacketId: bp.id,
                            blastPacketName: bp.name!,
                            targetId: bp.fireTarget!.id!,
                            targetName: bp.fireTarget!.taskName!,
                            targetOrder: bp.fireTarget?.taskOrder ?? 0,
                            targetForegroundColour: bp.fireTarget?.taskForegroundColour ?? DefaultTaskForegroundColour,
                            targetBackgroundColour: bp.fireTarget?.taskBackgroundColour ?? DefaultTaskBackgroundColour,
                            targetRateMetric: bp.fireTarget?.rateMetric ?? null,
                            canHaveActuals: bp.fireTarget?.ratePerHour != null,
                            plannedInShift: blastPacketTasksInShift.filter(bprt=>bprt.blastPacketRingTargetId === bp.fireTarget!.id).reduce((curr,prev)=>(prev.quantity ?? 0) + (curr ?? 0), null as number | null),
                            remainingActualsEstimate: Math.max(0, (100 - bp.fireTarget!.actualsPercentage) * bp.fireTarget!.target / 100),
                            completion: convertLocationTargetCompletionToShiftInformation(useWindowedActuals().localBlastPacketTargetCompletions.find(comp=>comp.targetId === bp.fireTarget!.id)),
                            recordedActuals: convertActualsEntriesToShiftInformation(this.getActualsInCurrentShift.filter(entry=>entry.targetId === bp.fireTarget!.id)) as LocationBlastPacketTargetShiftActuals[]
                        },
                        ..._.flatMap(
                            bp.rings,
                            r=> _.flatMap(r.targets, t=>({
                                locationId: r.locationId!,
                                locationName: l.name ?? '',
                                ringId: r.locationRingId!,
                                ringName: r.locationRingName!,
                                blastPacketId: bp.id,
                                blastPacketName: bp.name!,
                                targetId: t.id!,
                                targetName: t.taskName!,
                                targetOrder: t.taskOrder ?? 0,
                                targetForegroundColour: t.taskForegroundColour ?? DefaultTaskForegroundColour,
                                targetBackgroundColour: t.taskBackgroundColour ?? DefaultTaskBackgroundColour,
                                targetRateMetric: t.rateMetric ?? null,
                                canHaveActuals: t.ratePerHour != null,
                                plannedInShift: blastPacketTasksInShift.filter(bprt=>bprt.blastPacketRingTargetId === t.id).reduce((curr,prev)=>(prev.quantity ?? 0) + (curr ?? 0), null as number | null),
                                remainingActualsEstimate: Math.max(0, (100 - t.actualsPercentage) * t.target / 100),
                                completion: convertLocationTargetCompletionToShiftInformation(useWindowedActuals().localBlastPacketTargetCompletions.find(comp=>comp.targetId === t.id)),
                                recordedActuals: convertActualsEntriesToShiftInformation(this.getActualsInCurrentShift.filter(entry=>entry.targetId === t.id)) as LocationBlastPacketTargetShiftActuals[]
                            }))
                        )
                    ])
                )
            );
        },
        getBlastPacketTargetInformationForCurrentShift(state): LocationBlastPacketTargetShiftInformation[] {
            const targetsWithActualsInThisShift = this.getActualsInCurrentShift.map(e=>e.targetId);
            const shiftDetailsStore = useShiftDetails();
            const blastPacketTasksInShift = useHeadingsStore().tasks.filter(t=> !!t.blastPacketRingTargetId && TimespanContainsTask(t,shiftDetailsStore.shiftStartTime, shiftDetailsStore.shiftEndTime));
            const blastPacketTargetsInShift = _.uniq(blastPacketTasksInShift.map(t=>t.blastPacketRingTargetId!));

            const blastPacketTargetsToInclude = _.union(targetsWithActualsInThisShift, blastPacketTargetsInShift);

            return this.getBlastPacketTargetInformation.filter(t=>blastPacketTargetsToInclude.includes(t.targetId));
        },
        getBlastPacketSuggestionsForCurrentShift(state): BlastPacketActualsSuggestion[]  {
            const targetsWithActualsInThisShift = this.getActualsInCurrentShift.map(e=>e.targetId);
            const shiftDetailsStore = useShiftDetails();
            const blastPacketTasksInShift = useHeadingsStore().tasks.filter(t=> !!t.blastPacketRingTargetId && TimespanContainsTask(t,shiftDetailsStore.shiftStartTime, shiftDetailsStore.shiftEndTime));
            const blastPacketTargetsInShift = _.uniq(blastPacketTasksInShift.map(t=>t.blastPacketRingTargetId!));

            return this.getBlastPacketSuggestions.filter(t=>blastPacketTargetsInShift.includes(t.targetId) && !targetsWithActualsInThisShift.includes(t.targetId));
        },
        getActualsInCurrentShift(state): ClientBlastPacketActualsEntry[] {
            const shiftDetailsStore = useShiftDetails();
            return useWindowedActuals().localActualPhysicalEntries.filter(entry=>entry.endTime.isAfter(shiftDetailsStore.shiftStartTime) && entry.endTime.isSameOrBefore(shiftDetailsStore.shiftEndTime));
        },
        getBlastPacketSuggestions(state): BlastPacketActualsSuggestion[] {
            const shiftEndDate = useShiftDetails().shiftEndTime;
            return _.flatMap(
            state.locationBlastPacketInformation,
                l=>_.flatMap(
                        l.blastPackets,
                        bp=>_.flatMap(
                            bp.rings,
                            r=> _.flatMap(r.targets, t=>({
                                targetId: t.id!,
                                locationName: l.name!,
                                locationId: l.id,
                                ringName: r.locationRingName!,
                                targetName: t.taskName!,
                                targetOrder: t.taskOrder!,
                                endTime: shiftEndDate,
                                completed: t.markedCompleted,
                                completionId: t.completionId,
                                dirty: false,
                                delete: false,
                            }))
                        )
                )
        )},
        getBlastPacketRingTargetToRingMap(state): Map<string,string> {
            return new Map<string,string>(_.flatMap(this.getBlastPacketRingsInformation, r=>r.targets.map(t=>[t.targetId!, r.ringId!])));
        },
        getRingStateAtTimePoint(state): (locationId: string, locationRingId: string, timePoint: dayjs.Dayjs) => RingRuleEvaluationState | null {
            return (locationId: string, locationRingId: string, timePoint: dayjs.Dayjs) => {
                const locationStateInformation = useWindowedActuals().locationBlastPacketRingStateInformation.find(si=>si.locationId === locationId);

                if(locationStateInformation == null)
                    return null;

                const states = locationStateInformation.rings.find(r=>r.locationRingId === locationRingId)?.states;

                if(states == null)
                    return null;

                return _.last(states.filter(s=>dayjs(s.startAt).utc().isBefore(timePoint) && dayjs(s.endAt).utc().isSameOrAfter(timePoint))) ?? null;
            }
        },
        getRingStatesAtTimePoint(state): (stopeId: string, blastPacketId: string, timePoint: dayjs.Dayjs) => RingRuleEvaluationState[] {
            return (stopeId: string, blastPacketId: string, timePoint: dayjs.Dayjs): RingRuleEvaluationState[] => {
                const locationStateInformation = useWindowedActuals().locationBlastPacketRingStateInformation.filter((si:RingStatesLocationForWindowViewModel)=>si.stopeId === stopeId);

                if(locationStateInformation.length == 0)
                    return [];

                const ringsStateInformation = _.flatMap(locationStateInformation, (si)=>si.rings);

                return ringsStateInformation.filter((r:RingStatesForWindowViewModel)=>r.blastPacketId === blastPacketId)
                    .map((r:RingStatesForWindowViewModel)=>_.last(r.states.filter(s=>dayjs(s.startAt).utc().isBefore(timePoint) && dayjs(s.endAt).utc().isSameOrAfter(timePoint))) ?? null)
                    .filter((s:RingRuleEvaluationState | null)=>s != null)
                    .map((s:RingRuleEvaluationState | null)=>s!);
            }
        },
        getRingStatesUpToTimePoint(state): (stopeId: string, blastPacketId: string, timePoint: dayjs.Dayjs) => RingRuleEvaluationState[] {
            return (stopeId: string, blastPacketId: string, timePoint: dayjs.Dayjs): RingRuleEvaluationState[] => {
                const locationStateInformation = useWindowedActuals().locationBlastPacketRingStateInformation.filter((si:RingStatesLocationForWindowViewModel)=>si.stopeId === stopeId);

                if(locationStateInformation.length == 0)
                    return [];

                const ringsStateInformation = _.flatMap(locationStateInformation, (si)=>si.rings);

                return ringsStateInformation.filter((r:RingStatesForWindowViewModel)=>r.blastPacketId === blastPacketId)
                    .map((r:RingStatesForWindowViewModel)=>r.states.filter(s=>dayjs(s.startAt).utc().isBefore(timePoint)))
                    .flatMap((s:RingRuleEvaluationState[])=>s);
            }
        }
    },
    actions: {
        async populateWindow(mineId: string, departmentId: string, date: string, shiftNumber: string, offsetPrior: number, offsetFollowing: number) {
            if(useSystemStore().isOnline){
                const blastPacketsResponse = await BlastPackets.getWindowByShift(mineId, departmentId, date, shiftNumber, offsetPrior, offsetFollowing);
                const ringsStateResponse = await BlastPackets.getStatesWindowByShift(mineId, departmentId, date, shiftNumber, offsetPrior, offsetFollowing);
                const actualsResponse = await ActualPhysicals.GetWindowByShift(mineId, departmentId, date, shiftNumber, offsetPrior, offsetFollowing);
                const completions = _.flatMap(
                    blastPacketsResponse.locations,
                    l=>_.flatMap(
                        l.blastPackets,
                        bp=>_.flatMap(
                            bp.rings,
                            r=> _.flatMap(r.targets.filter(t=>t.completionId != null), t=>({
                                id: t.completionId!,
                                targetId: t.id!,
                                completionReason: t.completionReason,
                                completedAt: dayjs(t.completedAt!).utc(),
                                locationId: r.locationId!
                            }))
                        )
                    ));

                const actuals = transformActualsToClient(actualsResponse.entries);

                this.setUpStore(blastPacketsResponse.locations);

                useWindowedActuals().setUpStore(dayjs(ringsStateResponse.clientCalculationTime).utc(), this.getBlastPacketsInformation, ringsStateResponse.locations, actuals, completions)
            }
        },
        setUpStore(locationBlastPacketInformation: BlastPacketLocationForOffsetViewModel[]) {
            this.locationBlastPacketInformation = locationBlastPacketInformation;
        },
        async saveActualChanges(updatedActuals: ClientBlastPacketActualsEntry[], newActuals: ClientBlastPacketActualsEntry[], deletedActuals: string[], newCompletions: ClientBlastPacketTargetCompletion[], undoneCompletions: ClientBlastPacketTargetCompletionUndo[]) {
            const updatedLocations = await this.persistActuals({
                entriesToUpdate: updatedActuals,
                entriesToAdd: newActuals,
                entriesToDelete: deletedActuals,
                blastPacketRingTargetsToComplete: newCompletions,
                completionsToUndo: undoneCompletions,
            });

            useHeadingsStore().actualsUpdatedForLocations(updatedLocations);
        },
        async persistActuals(command: ClientUpdateActualPhysicalsEntriesCommand) {
            const departmentId = useDepartmentStore().departmentId;
            await ActualPhysicals.UpdateActualPhysicals(convertClientCommandToServerCommand(command, departmentId));

            // assuming command is successful, update our local copy
            const locationsUpdatedDueToActuals = await this.patchLocalActuals(command.entriesToUpdate, command.entriesToAdd, command.entriesToDelete);
            const locationsUpdatedDueToCompletions = this.patchLocalCompletions(command.blastPacketRingTargetsToComplete, command.completionsToUndo);

            return _.uniq([...locationsUpdatedDueToActuals, ...locationsUpdatedDueToCompletions]);
        },
        patchLocalCompletions(toAdd: ClientBlastPacketTargetCompletion[], toUndo: ClientBlastPacketTargetCompletionUndo[]): string[] {
            const updatedLocations = [] as string[];

            this.locationBlastPacketInformation.forEach(l=>{
                l.blastPackets.forEach((bp: BlastPacketForOffsetViewModel)=>{
                    bp.rings.forEach((r: BlastPacketRingForOffsetViewModel)=>{
                        r.targets.forEach((t: BlastPacketTargetForOffsetViewModel)=>{
                            const completion = toAdd.find(c=>c.targetId === t.id);
                            if(completion) {
                                updatedLocations.push(l.id);
                                t.markedCompleted = true;
                                t.completedAt = completion.completedAt.toDate();
                                t.markedCompletedBy = 'TODO';
                                t.completionReason = completion.completionReason ?? null;
                                t.completionId = completion.id;
                            }

                            const undo = toUndo.find(u=>u.completionId === t.completionId);
                            if(undo) {
                                updatedLocations.push(l.id);
                                t.markedCompleted = false;
                                t.completionReason = null;
                                t.completionId = null;
                            }
                        });
                    });
                });
            });

            const completionIdsToRemove = toUndo.map(uc=>uc.completionId);
            useWindowedActuals().updateCompletions([...useWindowedActuals().localBlastPacketTargetCompletions.filter(lc=>!completionIdsToRemove.includes(lc.id)), ...toAdd.map(c=>({
                id: c.id,
                targetId: c.targetId,
                completedAt: c.completedAt,
                completionReason: c.completionReason,
                locationId: c.locationId
            }))]);

            return updatedLocations;
        },
        async patchLocalActuals(toUpdate: ClientBlastPacketActualsEntry[], toAdd: ClientBlastPacketActualsEntry[], toRemove: string[]): Promise<string[]> {
            const updatedLocations = [... toUpdate.map(entry=>entry.locationId), ...toAdd.map(entry=>entry.locationId), ...useWindowedActuals().localActualPhysicalEntries.filter(e=>toRemove.includes(e.id)).map(entry=>entry.locationId)];

            const removedAndAltered: ClientBlastPacketActualsEntry[] = [...useWindowedActuals().localActualPhysicalEntries].filter(e=>!toRemove.includes(e.id))
                .map(e=>{
                    const update = toUpdate.find(update=>update.id === e.id);
                    if(update){
                        return {
                            ...e,
                            endTime: update.endTime,
                            startTime: update.startTime,
                            comment: update.comment ?? null,
                            quantity: update.quantity
                        }
                    }

                    return e;
                });

            const user = await UserStore.getCurrentUser();

            const additionalEntries: ClientBlastPacketActualsEntry[] = toAdd.map(add=>({
                ... add
            }));

            useWindowedActuals().updateActuals([...removedAndAltered, ...additionalEntries]);

            return updatedLocations;
        },
        reEvaluateRingStatesForHeadings(headings: { rowType: RowType, stopeInfo: { stopeId: string | null } | null, location: { id: string, name: string | null } | null, tasks: ProductionValidationTaskModel[] }[], ringErrorAccumulator: (t: ProductionValidationTaskModel, affectedArea: AffectedAreaSpecification, warning: ClientTaskWarning)=>void) {
            useWindowedActuals().reEvaluateRingStatesForHeading(headings, ringErrorAccumulator);
        },
        validateProductionFrontRules(rows: ProductionValidationRow[], rulesets: ProductionFrontRulesViewModel[], warningAdditionFunc: (task: ProductionValidationTaskModel, affectedArea: AffectedAreaSpecification, warning: ClientTaskWarning) => void) {
            const shiftStartTime = useShiftDetails().shiftStartTime;
            const shiftEndTime = useShiftDetails().shiftEndTime;

            const ringStates = this.locationBlastPacketRingStateInformation;

            const currentMineTime = selectedMineAreaStore.getMineCurrentTime();
            const currentMineTimeComparableToTasks = currentMineTime.utc(true);

            validateProductionFrontRules(rows, this.getBlastPacketTargetsDisplayInformation, rulesets, ringStates, currentMineTimeComparableToTasks, [{ shiftStartTime, shiftEndTime }], warningAdditionFunc);
        }
    }
});