import dayjs from 'dayjs';
import { DateFormat } from '@/plugins/dates';

const emailRegex = /^[a-zA-Z0-9-_+.]+@[a-zA-Z0-9-_]+[.][a-zA-Z0-9-_.]+$/;
const timeRegex = /^[012]?[0-9]:[0-5][0-9]$/;
const lowerRegex = /[a-z]/;
const upperRegex = /[A-Z]/;
const numRegex = /[0-9]/i;
const specialRegex = /[!@#$%^&*]/;

export interface IAsyncValidator {
    component: any;
    method: any;
    message?: string;
}

export default class Validation {
    public static required(message?: string) {
        return (v?: string) => !!v || message || 'Required';
    }
    public static requiredAllowZero(message?: string) {
        return (v?: string) => v != null || message || 'Required';
    }
    public static requiredAllowZeroIf(getter: any, message?: string) {
        return (v?: string) => !getter() || v != null || message || 'Required';
    }
    public static notNull(message?: string) {
        return (v?: any) => (v != null && v != undefined) || message || 'Required';
    }
    public static notWhitespace(message?: string) {
        return (v?: string) => !v || v.trim().length > 0 || message || 'Cannot be blank';
    }
    public static requiredIf(getter: any, message?: string) {
        return (v?: string) => !getter() || !!v || message || 'Required';
    }
    public static maxLength(num: number, message?: string) {
        return (v?: string) => (v || '').length <= num || message || `Must be ${num} or less characters`;
    }
    public static minLength(num: number, message?: string) {
        return (v?: string) => (v || '').length >= num || message || `Must be ${num} or more characters`;
    }
    public static email(message?: string) {
        return (v?: string) => !v || emailRegex.test(v) || message || `Must be a valid email`;
    }
    public static time(message?: string) {
        return (v?: string) => !v || timeRegex.test(v) || message || `Must be a valid time (hh:mm)`;
    }
    public static multipleOf(num: number, message?: string) {
        return (v?: number) => !v || (v || 0) % num == 0 || message || `Increments of ${num}`;
    }
    public static max(num: number, message?: string) {
        return (v?: number) => (v || 0) <= num || message || `Must be ${num} or less`;
    }
    public static min(num: number, message?: string) {
        return (v?: number) => (v || 0) >= num || message || `Must be ${num} or more`;
    }
    public static unique<T>(potentialClashes: T[], message?: string) {
        return (v?: T) => !v || potentialClashes.findIndex((x) => x == v) === -1 || message || `Must be unique`;
    }
    public static password(message?: string) {
        function isStrongPassword(v?: string) {
            if (!v) return false;
            if (v.length < 8) return false;

            let count = 0;
            if (numRegex.test(v)) count++;
            if (lowerRegex.test(v)) count++;
            if (upperRegex.test(v)) count++;
            if (specialRegex.test(v)) count++;

            return count >= 3;
        }
        return (v?: string) =>
            isStrongPassword(v) ||
            message ||
            'Must be at at least 8 characters including at least 3 of the following 4 types of characters: a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*)';
    }
    public static int(message?: string) {
        return (v?: number) => v == Math.floor(v || 0) || message || `Must be a whole number`;
    }
    public static noMoreDecimalPointsThan(decimalPoints: number, message?: string) {
        return (v?: number) => !v || String(v || 0).split(".").length == 1 || String(v || 0).split(".")[1]?.length <= decimalPoints || message || `No more than ${decimalPoints} decimals`;
    }
    public static custom(fn: any, message?: string) {
        return (v?: any) => !fn() || message || 'Invalid';
    }
    public static before(date?: any, message?: string, dateFormat: string = DateFormat.DefaultDisplay) {
        return (val?: any) => {
            const endDate = dayjs(date, dateFormat);
            const startDate = dayjs(val, dateFormat);
            return (
                !(startDate.isValid() && endDate.isValid()) ||
                (startDate as any).isSameOrBefore(endDate) ||
                message ||
                `Date must be less than or equal ${endDate.format(dateFormat)}`
            );
        };
    }
    public static after(date?: any, message?: string, dateFormat: string = DateFormat.DefaultDisplay) {
        return (val?: any) => {
            const endDate = dayjs(val, dateFormat);
            const startDate = dayjs(date, dateFormat);
            return (
                !(startDate.isValid() && endDate.isValid()) ||
                (endDate as any).isSameOrAfter(startDate) ||
                message ||
                `Date must be greater than or equal ${startDate.format(dateFormat)}`
            );
        };
    }

    /**
     * @description Create an async validator e.g. $rules.asyncValidator({component:() => $refs.REFNAME, message: 'OPTIONALMESSAGE', method: someAsyncValidator})
     * @param {IAsyncValidator} validator Model defining async validator behaviour. Validator method: e.g. async someAsyncValidator(val) { return await this.someFunc(val); }
     * @returnType {Function}
     */
    public static asyncValidator(validator: IAsyncValidator) {
        const message = validator.message || 'Invalid';

        let myValue = true;

        setTimeout(() => {
            validator.component().rules.push(() => myValue || message);
        }, 1);

        let running = false;
        return (v: any) => {
            if (running) {
                return true;
            }
            running = true;
            const runner = async () => {
                myValue = await validator.method(v);
                validator.component().validate();
                running = false;
            };
            runner();
            return true;
        };
    }
}
