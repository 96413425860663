<template>
    <div>
        <print-window ref="printwindow"></print-window>
        <standard-dialog v-model='dialog' :max-width="330" persistent>
            <template v-slot:title>
                Print Supervisor Report
            </template>
            <template v-slot:content>
                <div class="d-flex">
                    <v-btn color="primary" @click="performPrint">Print</v-btn>
                    <v-spacer></v-spacer>
                </div>
            </template>
            <template v-slot:actions>
                <v-spacer></v-spacer>
                <v-btn text @click="dialog = false">Cancel</v-btn>
            </template>
        </standard-dialog>
    </div>
</template>
<script>
import { EventBus, Events } from '@/lib/EventBus';
import Account from '@/lib/data/Account';
import Tenants from '@/lib/Tenants';

export default {
    props: ['shiftId', 'departmentName', 'date', 'shift'],
    data() {
        return { dialog: false };
    },
    async created() {
        EventBus.$on(Events.PrintClicked, this.print);
    },
    destroyed() {
        EventBus.$off(Events.PrintClicked, this.print);
    },
    methods: {
        print() {
            this.dialog = true;
        },
        performPrint() {
            this.dialog = false;
            this.$refs.printwindow.show(
                `/Print/${Tenants.GetCurrentTenant()}/Pack/Supervisor/${this.shiftId}/Shift-Supervisor-${this.safeDeptName(
                    this.departmentName
                )}-${this.date}-${this.shift}.pdf`
            );
        },
        safeDeptName(name) {
            return name.replace(/[/\\?%*:|"<>]/g, '');
        }
    }
};
</script>
