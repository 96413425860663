import AppHttp from '@/lib/AppHttp';
import Errors from '@/lib/Errors';
import { AppEvent } from '@/models/api/Data/app-event';
import { AxiosError } from 'axios';

export class AppEvents {
    public async search(shiftId: string): Promise<AppEvent[]> {
        const response = await AppHttp.get(`AppEvents/Shift/${shiftId}`);
        const data = response ? response.data : null;
        return data;
    }
}

export default new AppEvents();
