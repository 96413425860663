var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-access-control',{scopedSlots:_vm._u([{key:"readonly",fn:function(){return [_c('v-card',{class:("shift-owner-menu mr-2 " + (_vm.reduceSize ? 'reduced' : '')),attrs:{"outlined":""}},[_c('div',{staticClass:"pa-1"},[_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm.shiftOwnerName))]),_c('span',{staticClass:"caption font-weight-light"},[_vm._v(" Shift Owner ")])])])]},proxy:true},{key:"offline",fn:function(){return [_c('v-menu',{attrs:{"offset-y":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-card',_vm._g({class:("shift-owner-menu mr-2 " + (_vm.reduceSize ? 'reduced' : '')),attrs:{"outlined":""}},Object.assign({}, menu)),[_c('div',{staticClass:"pa-1"},[_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm.shiftOwnerName))]),_c('span',{staticClass:"caption font-weight-light"},[_vm._v(" Shift Owner "),_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-chevron-down")])],1)])])]}}]),model:{value:(_vm.shiftOwnerMenu),callback:function ($$v) {_vm.shiftOwnerMenu=$$v},expression:"shiftOwnerMenu"}},[_c('v-list',[_c('v-list-item',{staticClass:"px-5 py-2",attrs:{"color":"primary"}},[_c('offline-btn')],1)],1)],1)]},proxy:true}])},[_c('v-menu',{attrs:{"offset-y":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-card',_vm._g({class:("shift-owner-menu mr-2 " + (_vm.reduceSize ? 'reduced' : '')),attrs:{"outlined":""}},Object.assign({}, menu)),[_c('div',{staticClass:"pa-1"},[_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm.shiftOwnerName))]),_c('span',{staticClass:"caption font-weight-light"},[_vm._v(" Shift Owner "),_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-chevron-down")])],1)])])]}}]),model:{value:(_vm.shiftOwnerMenu),callback:function ($$v) {_vm.shiftOwnerMenu=$$v},expression:"shiftOwnerMenu"}},[_c('v-list',[(_vm.canPublishShift)?_c('v-tooltip',{key:"shiftMenu-PublishShift",attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"px-5 py-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.publishShift()}}},on),[_c('v-icon',{attrs:{"left":"","color":"white"}},[_vm._v("mdi-publish")]),_vm._v(" Publish Shift ")],1)]}}],null,false,1212908476)},[_c('span',[_vm._v("Publish shift as a snapshot")])]):_vm._e(),(_vm.canHandover)?_c('v-tooltip',{key:"shiftMenu-HandoverShiftOwner",attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"px-5 py-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.showAssignShiftOwner(_vm.actionTypes.Handover)}}},on),[_c('v-icon',{attrs:{"left":"","color":"white"}},[_vm._v("mdi-account-multiple")]),_vm._v(" Handover ")],1)]}}],null,false,3892370049)},[_c('span',[_vm._v("Start a handover meeting with another user")])]):_vm._e(),(_vm.canAssignShiftOwner)?_c('v-tooltip',{key:"shiftMenu-AssignShiftOwner",attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"px-5 py-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.showAssignShiftOwner(_vm.actionTypes.Assign)}}},on),[_c('v-icon',{attrs:{"left":"","color":"white"}},[_vm._v("mdi-account-plus")]),_vm._v(" Assign ")],1)]}}],null,false,3340568875)},[_c('span',[_vm._v("Assign shift ownership to another user")])]):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.canTakeOwnership)?_c('v-list-item',_vm._g({staticClass:"px-5 py-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.showAssignShiftOwner(_vm.actionTypes.TakeOwnership)}}},on),[_c('v-icon',{attrs:{"left":"","color":"white"}},[_vm._v("mdi-clipboard-account")]),_vm._v(" Take Ownership ")],1):_vm._e()]}}])},[_c('span',[_vm._v("Take shift ownership")])])],1),_c('assign-shift-owner',{ref:"assignShiftOwner",attrs:{"department":_vm.department},on:{"complete":function($event){return _vm.shiftOwnerAssigned()}}})],1)],1),_c('confirm',{ref:"publishConfirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }