export function getPrevious<T>(array: T[], index: number): T | null {
    if (index <= 0) {
        return null;
    }

    return array[index - 1];
}

export function getNext<T>(array: T[], index: number): T | null {
    if (index + 1 >= array.length) {
        return null;
    }

    return array[index + 1];
}
