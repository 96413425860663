<template>
    <div>
        <v-row class="ma-0">
            <v-col :cols="3" class="ma-0 pa-0">
                <h3 class="subtitle-1 pt-2">{{ priority ? 'Priority ' + priority : 'Other ' + locnameplural }}</h3>
            </v-col>
            <v-col :cols="9" class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                    <v-col class="subtitle-1" :cols="1">Priority</v-col>
                    <v-col class="subtitle-1" :cols="1">{{ 'Level' | term }}</v-col>
                    <v-col class="subtitle-1" :cols="2">{{ locname || 'Location' }}</v-col>
                    <v-col class="subtitle-1" :cols="1">Completion</v-col>
                    <v-col class="subtitle-1" :cols="7"></v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="ma-0">
            <v-col :cols="3" class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                    <v-col :cols="4">
                        <v-chip text-color="white" label :color="color(calcPriorityPercent(true))">{{
                            calcPriorityPercent()[0]
                        }}</v-chip>
                    </v-col>
                    <v-col :cols="8">
                        <pie-chart
                            :chartdata="makePieChart()"
                            :options="{ legend: false }"
                            :styles="{ width: '180px', height: '100px' }"
                        ></pie-chart>
                    </v-col>
                </v-row>
            </v-col>
            <v-col :cols="9" class="ma-0 pa-0">
                <v-row class="ma-0 pa-0" v-for="row in rows" :key="row.id">
                    <v-col :cols="1" class="overline">{{ row.priority }}</v-col>
                    <v-col :cols="1" class="overline">{{ row.location.reducedLevel.name }}</v-col>
                    <v-col :cols="2" class="overline">{{ row.location.name }}</v-col>
                    <v-col class="subtitle-1 pt-2" :cols="1">
                        <v-chip outlined label :color="color(rowPercent(row))">{{
                            row.progress.planned == 0 ? 'NA' : Math.floor(rowPercent(row)[0]) + '%'
                        }}</v-chip>
                    </v-col>
                    <v-col class="subtitle-1" :cols="7">
                        <location-progress
                            :large="true"
                            v-if="row.progress.planned || row.progress.complete || row.progress.pending"
                            :complete="row.progress.complete + row.progress.pending"
                            :pending="0"
                            :target="row.progress.target"
                            :maxSegments="maxSegments"
                        ></location-progress>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import LocationProgress from '@/components/ShiftBoard/LocationProgress.vue';
import PieChart from '@/components/Charts/PieChart.vue';
import colors from 'vuetify/lib/util/colors';

export default {
    components: { LocationProgress, PieChart },
    props: ['rows', 'priority', 'locname', 'locnameplural', 'maxSegments'],
    methods: {
        calcPriorityPercent(justNumber) {
            var percent = 0;
            var divideBy = 0;
            var allInfinite = true;

            for (let row of this.rows) {
                var rowPercent = this.rowPercent(row);
                if (rowPercent[0] == 'NA') continue;
                divideBy++;
                percent += rowPercent[0];
                if (!rowPercent[1]) {
                    allInfinite = false;
                }
            }
            if (divideBy == 0) {
                return 'NA';
            }
            percent = Math.floor(percent / divideBy);
            return [justNumber ? percent : percent + '%', allInfinite];
        },
        color(tuple) {
            var percent = tuple[0];

            if (percent == 'NA') {
                return colors.shades.black;
            }
            if (tuple[1] || percent > 100) {
                return colors.blue.base;
            }
            if (percent >= 99) {
                return colors.green.base;
            }
            if (percent >= 70) {
                return colors.amber.base;
            }
            return colors.red.base;
        },
        rowPercent(row) {
            if (row.progress.target == 0 && row.progress.planned == 0) return ['NA', false];
            var isActuallyInfinite = row.progress.target == 0 && row.progress.complete + row.progress.pending > 0;
            return [
                ((row.progress.complete + row.progress.pending) / (row.progress.target || 1)) * 100,
                isActuallyInfinite,
            ];
        },
        makePieChart() {
            var data = [0, 0, 0];
            for (let row of this.rows) {
                var complete = row.progress.complete + row.progress.pending;
                var planned = row.progress.target;
                var bonus = complete > planned ? complete - planned : 0;

                complete = complete - bonus;

                var remaining = complete > planned ? 0 : planned - complete;

                data[0] += complete;
                data[1] += bonus;
                data[2] += remaining;
            }
            return {
                labels: ['Complete', 'Over-achieved', 'Incomplete'],
                datasets: [
                    {
                        backgroundColor: [colors.green.base, colors.blue.base, colors.grey.lighten2],
                        data: data,
                    },
                ],
            };
        },
    },
};
</script>
<style lang="scss" scoped>
.v-application .overline {
    letter-spacing: 0.1px !important;
    font-size: 0.8rem !important;
}
</style>
