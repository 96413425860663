import ShiftCrewApi from '@/lib/data/ShiftCrewApi';
import WeekCrews from '@/lib/data/WeekCrews';
import { AddRoleToShiftCommand, SetCrewForShiftCommand, CrewMemberForShift, StaffRole } from '@/models/api';
import { defineStore } from 'pinia';
import { useShiftDetails } from '@/lib/stores/Shift';
import { v4 } from 'uuid';
import _ from 'lodash';
import { useDepartmentStore } from '@/lib/stores/DepartmentStore';
import { useSystemStore } from '../SystemStore';
import PlannedEquipment from '@/lib/data/PlannedEquipment';
import { ShiftCrewMember } from '@/lib/services/Staff/type';

interface RoleTemplate {
    id: string;
    count: number;
}

interface StaffTemplate {
    id: string;
    crewId: string;
    weekCrewId: string;
    roleId: string;
    crewName: string;
}

interface CrewTemplate {
    id: string;
    crewId: string;
    name: string;
    roles: RoleTemplate[];
    staff: StaffTemplate[];
}

export const useShiftCrew = defineStore('crew', {
    state: () => {
        return {
            shiftRoles: [] as StaffRole[],
            shiftCrewMembers: [] as ShiftCrewMember[],
            crewIds: [] as string[],
            shiftCrewId: '' as string | null,
            weekCrews: [] as any[],
        };
    },
    getters: {
        roles(): StaffRole[] {
            const templateRoles = this.crewTemplates
                .filter((template: any) => this.crewIds.includes(template.id))
                .flatMap((template: any) => template.roles);

            const additionalRoles = this.shiftRoles.filter(
                (x) => templateRoles.some((templateRole) => templateRole.id === x.id) === false
            );

            const roleIds = [...templateRoles, ...additionalRoles].map((x) => x.id);

            const roles = _.uniq(roleIds)
                .filter((x) => x)
                .map((id) => useSystemStore().getStaffRoleById(id))
                .sort((x, y) => x.displayOrder - y.displayOrder);

            return roles;
        },
        staffOnShift(): { id: string; roleId: string }[] {
            const templateStaff = this.templateStaff.map((x) => {
                return { id: x.id, roleId: x.roleId };
            });

            const additionalStaff = this.shiftCrewMembers
                .filter((x) => templateStaff.some((templateMember) => templateMember.id === x.staffId) === false)
                .map((x) => {
                    return { id: x.staffId, roleId: x.staffRoleId };
                });

            const templateStaffWithStatus = templateStaff.map((x) => {
                return {
                    ...x,
                    shiftStatus:
                        this.shiftCrewMembers.findIndex((shiftStaff) => shiftStaff.staffId === x.id) !== -1
                            ? 'ON_SHIFT'
                            : 'REMOVED',
                };
            });

            const additionalStaffWithStatus = additionalStaff.map((x) => {
                return { ...x, shiftStatus: 'ADDED' };
            });

            const allPossibleStaff = [...templateStaffWithStatus, ...additionalStaffWithStatus]
                .filter((x) => x.id)
                .map((x) => {
                    return { ...x, utilisation: useShiftDetails().individualStaffUtilisation[x.id] };
                })
                .map((x) => {
                    return { ...useSystemStore().getStaffById(x.id), ...x };
                });

            return allPossibleStaff;
        },
        crewTemplates(): CrewTemplate[] {
            return this.weekCrews.map((crewTemplate: any) => {
                return {
                    id: crewTemplate.id,
                    crewId: crewTemplate.crew.id,
                    name: crewTemplate.crew.name,
                    roles: crewTemplate.crew.crewRolesModel
                        .map((role: any) => {
                            return { id: role.staffRoleId, count: role.count };
                        })
                        .filter((role: RoleTemplate) => role.count > 0),
                    staff: crewTemplate.staff.map((x: any) => {
                        return {
                            id: x.staffId,
                            roleId: useSystemStore().getStaffById(x.staffId).staffRoleId,
                            weekCrewId: x.weekCrewId,
                            crewId: crewTemplate.crew.id,
                            crewName: crewTemplate.crew.name,
                        };
                    }),
                };
            });
        },
        templateStaff(): any[] {
            return this.crewTemplates
                .filter((template: any) => this.crewIds.includes(template.id))
                .flatMap((template: any) => template.staff);
        },
    },
    actions: {
        setUpStore(
            shiftCrewMembers: ShiftCrewMember[],
            shiftRoles: StaffRole[],
            selectedCrewIds: string[],
            weekCrewTemplates: any[],
            shiftCrewId: string | null
        ) {
            this.weekCrews = weekCrewTemplates;
            this.shiftCrewMembers = shiftCrewMembers;
            this.shiftRoles = shiftRoles;
            this.crewIds = selectedCrewIds;
            this.shiftCrewId = shiftCrewId;
        },
        async addStaffMemberToShift(staffMemberId: string, roleId: string) {
            const departmentId = useDepartmentStore().departmentId;
            const shiftId = useShiftDetails().shiftId;

            const staffMember = useSystemStore().getStaffById(staffMemberId);
            const staffRole = useSystemStore().getStaffRoleById(roleId);

            const newShiftMember: ShiftCrewMember = {
                id: v4(),
                shiftCrewId: staffMember.crewId ?? null,
                staffRoleId: roleId,
                staffId: staffMemberId,
                // @ts-ignore
                staff: staffMember,
                // @ts-ignore
                staffRole: staffRole,
                preStart: false,
                t5: 0,
                hz: 0,
                hrs: 0,
            };

            this.shiftCrewMembers.push(newShiftMember);

            await ShiftCrewApi.addCrewMember(departmentId, {
                _type: 'AddShiftCrewMemberCommand',
                id: newShiftMember.id,
                shiftId: shiftId,
                staffRoleId: roleId,
                staffId: staffMemberId,
            });
        },
        async addRoleToShift(roleId: string) {
            const role = useSystemStore().getStaffRoleById(roleId);
            this.shiftRoles.push(role);

            const shiftId = useShiftDetails().shiftId;
            const command: AddRoleToShiftCommand = {
                _type: 'AddRoleToShiftCommand',
                id: v4(),
                departmentShiftId: shiftId,
                crewIds: this.crewIds,
                roleId: roleId,
                isOfflineReplay: false,
                attemptedAt: new Date(),
            };

            await WeekCrews.addShiftRole(command);
        },
        async removeStaffMemberFromShift(staffMemberId: string) {
            if (this.shiftCrewMembers.length === 0) return;

            let index = this.shiftCrewMembers.findIndex((x) => x.staffId === staffMemberId);
            while (index !== -1){
                this.shiftCrewMembers.splice(index, 1)
                index = this.shiftCrewMembers.findIndex((x) => x.staffId === staffMemberId);
            }

            const departmentId = useDepartmentStore().departmentId;
            const shiftId = useShiftDetails().shiftId;

            await ShiftCrewApi.removeCrewMember(departmentId, {
                _type: 'DeleteShiftCrewMemberCommand',
                shiftId: shiftId,
                staffId: staffMemberId,
            });
        },
        async changeSelectedCrews(selectedCrewIds: string[]) {
            const removedCrewIds = _.difference(this.crewIds, selectedCrewIds);
            const addedCrewIds = _.difference(selectedCrewIds, this.crewIds);

            if (removedCrewIds.length > 0) {
                this.templateStaff.forEach((templateStaffMember) => {
                    if (removedCrewIds.find((x) => x === templateStaffMember.weekCrewId)) {
                        const index = this.shiftCrewMembers.findIndex((x) => x.staffId === templateStaffMember.id);

                        if (index !== -1) {
                            this.shiftCrewMembers.splice(index, 1);
                        }
                    }
                });
            }

            if (addedCrewIds.length > 0) {
                const addedCrews = this.weekCrews.filter((x: any) => addedCrewIds.find((crewId) => crewId === x.id));

                addedCrews.forEach((crew: any) => {
                    this.shiftCrewMembers.push(
                        ...crew.staff.map((x: any) => {
                            return {
                                id: x.staffId,
                                staffId: x.staffId,
                                roleId: useSystemStore().getStaffById(x.staffId).staffRoleId,
                            };
                        })
                    );
                });
            }

            this.crewIds.splice(0, this.crewIds.length, ...selectedCrewIds);

            const command: SetCrewForShiftCommand = {
                _type: 'SetCrewForShiftCommand',
                departmentShiftId: useShiftDetails().shiftId,
                weekCrewIds: selectedCrewIds,
                isOfflineReplay: false,
                attemptedAt: new Date(),
            };

            await WeekCrews.setForShift(command);
        },
        calculateStaffUtilisation(calculateChangeFromPrevious: boolean) {
            useShiftDetails().populateCrewAndEquipmentUtilisationScores(calculateChangeFromPrevious);
        },
    },
});
