<template>
    <div class="pa-5">
        <v-card v-if="data">
            <v-card-title>
                <h1 class="title">{{ 'Equipment' | term }}</h1>
                <v-btn small color="secondary" class="ml-3" @click="create()">
                    <v-icon left>mdi-plus</v-icon>Add
                </v-btn>
                <v-menu
                    v-model="importDialog"
                    max-width="310px"
                    :close-on-content-click="false"
                    v-if="!!data && data.length === 0"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" small class="ml-1" color="secondary">
                            <v-icon left>mdi-upload</v-icon>Import
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-text>
                            <input ref="inputUpload" type="file" accept="text/csv" @change="getFile($event)" />
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="secondary" :disabled="file === null" @click="importData">Upload</v-btn>
                            <v-btn text @click="importDialog = false">cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
                <v-btn small class="ml-1" color="gray" v-if="!!data && data.length" @click="exportData()">
                    <v-icon left>mdi-download</v-icon>Export
                </v-btn>
                <v-spacer></v-spacer>
                <admin-sandpit-visibility-selector></admin-sandpit-visibility-selector>
            </v-card-title>
            <v-data-table disable-pagination hide-default-footer :headers="headers" :items="filteredEquipment">
                <template v-slot:item.imageUrl="{ item }">
                    <v-img contain :src="item.imageUrl" :max-height="74" :max-width="120"></v-img>
                </template>
                <template v-slot:item.showConflicts="{ item }">
                    <v-simple-checkbox v-model="item.showConflicts" disabled></v-simple-checkbox>
                </template>
                <template v-slot:item.id="{ item }">
                    <v-btn text icon @click="edit(item)" class="float-right">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <template v-slot:item.name="{ item }">
                    <admin-sandpit-item-name :is-sandpit="item.isSandpitUseOnly">{{ item.name }}</admin-sandpit-item-name>
                </template>
            </v-data-table>
        </v-card>
        <edit-equipment-dialog
            v-if="selected !== null"
            :showModal="showModal"
            :equipment="selected"
            :equipmentRoles="getAvailableRolesForEquipment(selected)"
            :departments="getAvailableDepartmentsForEquipment(selected)"
            @deleteEquipment="onDeleteEquipment"
            @saveEquipment="onSaveEquipment"
            @cancelEdit="onCancelEdit"
        />
        <confirm ref="confirm"></confirm>
    </div>
</template>
<script>
import { EventBus, Events } from '@/lib/EventBus';
import Equipment from '@/lib/data/Equipment';
import EquipmentRoles from '@/lib/data/EquipmentRoles';
import DownloadHelper from '@/lib/DownloadHelper';
import EditEquipmentDialog from '@/components/Admin/Equipment/EditEquipmentDialog.vue';
import Departments from '@/lib/data/Departments';
import { v4 as uuidv4 } from 'uuid';
import AdminSandpitVisibilitySelector from '@/components/Admin/Sandpit/AdminSandpitVisibilitySelector.vue';
import { AdminSandpitVisibility, useSandpitStore } from '@/lib/stores/SandpitStore';
import { mapState } from 'pinia';
import AdminSandpitItemName from '@/components/Admin/Sandpit/AdminSandpitItemName.vue';

export default {
    components: { AdminSandpitItemName, AdminSandpitVisibilitySelector, EditEquipmentDialog },
    data() {
        return {
            data: null,
            showModal: false,
            selected: null,
            equipmentRoles: [],
            importDialog: false,
            file: null,
            departments: [],
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Image', value: 'imageUrl', sortable: false },
                { text: 'Type', value: 'equipmentType' },
                { text: 'Role', value: 'equipmentRole.name' },
                { text: 'Show Conflicts', value: 'showConflicts' },
                { text: '', value: 'id' }
            ]
        };
    },
    async created() {
        await this.loadData();
    },
    destroyed() {},
    methods: {
        async loadData() {
            this.equipmentRoles = await EquipmentRoles.get();
            this.data = await Equipment.get(['EquipmentRole', 'Departments']);
            const departments = await Departments.get(['MineArea']);
            const departmentNames = departments.map(department => ({
                id: department.id,
                name: `${department.mineArea.name} - ${department.name}`,
                isSandpit: department.mineArea.isSandpit
            }));
            const sortedDepartents = departmentNames.sort((a, b) => (a.name > b.name ? 1 : -1));
            this.departments = sortedDepartents;
        },
        create() {
            this.edit({ id: uuidv4(), name: '', imageUrl: '', departments: [], isSandpitUseOnly: false });
        },
        edit(item) {
            this.selected = { ...item, departmentIds: item.departments.map(x => x.id) };
            this.showModal = true;
        },
        onCancelEdit() {
            this.showModal = false;
        },
        async onDeleteEquipment(item) {
            if (await this.$refs.confirm.openAsDeleteResource(this.$termSync('Equipment'),  { })) {
                await Equipment.delete(item.id);
                EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Equipment')} Deleted`);
                this.showModal = false;
                await this.loadData();
            }
        },
        async onSaveEquipment(item) {
            await Equipment.saveEquipment(item);
            EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Equipment')} Saved`);
            this.showModal = false;
            await this.loadData();
        },
        async exportData() {
            const result = await Equipment.export();
            var csv = result.csvFile;
            var blob = DownloadHelper.makeBlobFromFileString(csv);
            DownloadHelper.download('Equipments.csv', blob);
        },
        async importData() {
            try {
                this.$wait.start('saving');
                this.uploadResult = null;
                await Equipment.import(this.file);
                this.importDialog = false;
                await this.loadData();
            } finally {
                this.$wait.end('saving');
            }
        },
        async getFile(e) {
            this.file = e.target.files[0];
        },
        getAvailableRolesForEquipment(equipment){
            return this.equipmentRoles.filter(er => er.isSandpitUseOnly === equipment.isSandpitUseOnly);
        },
        getAvailableDepartmentsForEquipment(equipment) {
            return this.departments.filter(d => d.isSandpit === equipment.isSandpitUseOnly);
        }
    },
    computed: {
        ...mapState(useSandpitStore, ['adminSandpitVisibility']),
        filteredEquipment() {
            return this.data.filter(this.sandpitFilter);
        },
        sandpitFilter() {
            if(this.adminSandpitVisibility === AdminSandpitVisibility.BOTH)
                return x=>true;
            else if(this.adminSandpitVisibility === AdminSandpitVisibility.ONLY_SANDPIT)
                return x=>x.isSandpitUseOnly;
            else
                return x=>!x.isSandpitUseOnly;
        },
    }
};
</script>
<style lang="scss" scoped></style>
