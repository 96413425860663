import { defineStore } from 'pinia';
import ClientRowModel from '@/models/client/client-row';
import {
    EquipmentUsageInDepartmentSummaryViewModel,
    OfflineEditableCommand,
    ProductionFrontRulesViewModel,
    ShiftLocationDetailViewModel,
    TaskCommandResponse,
    UserNotificationSubject
} from '@/models/api';
import { useDepartmentStore } from './DepartmentStore';
import { useShiftDetails, useShiftEquipment } from '@/lib/stores/Shift';
import _, { cloneDeep } from 'lodash';
import { ApplyConstraints, ClientOnlyTaskCommand } from '@/lib/services/Location';
import RequestHandler from '@/lib/api/RequestHandler';
import {
    GetClientOnlyCommandHandlers,
    GetCommandHandlers,
    GetCommandType,
    isTaskCommandResponse,
    NoOpChangeSetTransformerFactory
} from '@/lib/services/CommandFactory';
import ClientTaskModel from '@/models/client/client-task';
import { AddErrorToTask, AddWarningToTask, IsFloatDelay } from '@/lib/services/Task';
import {
    ClearAllTaskWarningsForGivenSources,
    clearTaskErrors,
    clearTaskNonLocationErrors,
    EQUIPMENT_ISSUE_TYPE,
    validateEquipmentUsage,
    validateTaskLocations
} from '@/components/ShiftBoard/Board/Validation';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { useShiftWindowActuals } from '@/lib/stores/Production/ShiftWindowActualsStore';
import { RING_RULE_ISSUE_TYPE } from '@/components/ShiftBoard/Board/ValidationRingRules';
import { RING_STATE_ISSUE_TYPE } from '@/lib/services/Production/RingStateValidation';
import { TaskErrorType } from '@/models/client/types/task-error-type';
import { ProductionValidationTaskModel } from '@/models/client/production-validation-task-model';
import { BLAST_PACKET_STATE_ISSUE_TYPE } from '@/lib/services/Production/BlastPacketStateValidation';
import { ClientTaskWarning } from '@/models/client/client-task-warning';
import { ClientTaskIssueType } from '@/models/client/client-task-issues';
import { TransformDateTimeToClientDate } from '@/lib/stores/Transforms';

export interface HeadingsWarning {
    subject: UserNotificationSubject
    text: string;
    targetTasks: ProductionValidationTaskModel[];
    affectedArea: AffectedAreaSpecification;
    source: ClientTaskIssueType;
}

export interface AffectedAreaSpecification {
    locationId: string,
    locationName: string,
    blastPacketId?: string | null,
    blastPacketName?: string | null,
    locationRingId?: string | null,
    locationRingName?: string | null,
}

export interface InterestingError {
    errorText: string,
    errorTargetTask: ClientTaskModel
}

export const useHeadingsStore = defineStore('headings', {
    state: () => {
        return {
            headings: [] as ClientRowModel[],
            rulesets: [] as ProductionFrontRulesViewModel[],
            warnings: [] as HeadingsWarning[],
            otherDepartmentEquipmentAvailability: [] as EquipmentUsageInDepartmentSummaryViewModel[]
        };
    },
    getters: {
        tasks(): ClientTaskModel[] {
            return this.headings.flatMap((x) => x.tasks);
        },
        getHeadingById(): (headingId: string) => ClientRowModel {
            return (headingId: string) => {
                const match = this.headings.find((heading: ClientRowModel) => heading.id === headingId);
                if (match === undefined) throw new Error(`Could not find heading with Id ${headingId}`);

                return match;
            };
        },
        getHeadingByLocationId(): (locationId: string) => ClientRowModel {
            return (locationId: string) => {
                const match = this.headings.find((heading: ClientRowModel) => heading.location?.id === locationId);
                if (match === undefined) throw new Error(`Could not find heading with location Id ${locationId}`);

                return match;
            }
        },
        getTaskById(): (taskId: string) => ClientTaskModel {
            return (taskId: string) => {
                const match = this.tasks.find((task: ClientTaskModel) => task.id === taskId);
                if (match === undefined) throw new Error(`Could not find task with Id ${taskId}`);

                return match;
            };
        },
        getHeadingLocationDetails(): (locationId: string) => ShiftLocationDetailViewModel | null {
            return (locationId: string) => {
                const match = this.headings.find((x) => x.location?.id === locationId);
                return match?.shiftLocationDetails as ShiftLocationDetailViewModel;
            };
        },
        getHeadingByTask(state): (taskId: string) => ClientRowModel {
            return (taskId: string) => {
                const match = state.headings.find((x) => x.tasks.some((x) => x.id === taskId));
                if (match === undefined) throw new Error(`Could not find heading with task Id ${taskId}`);

                return match;
            };
        },
        getTasksWithEquipment(state): (id: string, equipmentId: string) => ClientTaskModel[] {
            return (id: string, equipmentId: string) => {
                const primaryEquipment = useShiftEquipment().getPrimaryEquipmentByEquipment(equipmentId);
                const tasks = this.tasks.filter(
                    (task: ClientTaskModel) =>
                        task.primaryEquipment !== null &&
                        (task.primaryEquipment.id === id ||
                            primaryEquipment.map((x) => x.id).includes(task.primaryEquipment.id))
                );

                tasks.sort((a, b) => {
                    if (a.startTime.isAfter(b.startTime)) return 1;
                    if (a.startTime.isBefore(b.startTime)) return -1;

                    return 0;
                });

                return tasks;
            };
        },
        getTasksWithEquipmentByShift(state): (id: string, equipmentId: string) => ClientTaskModel[] {
            return (id: string, equipmentId: string) => {
                const shiftStartTime = useShiftDetails().shiftStartTime;
                const shiftEndTime = useShiftDetails().shiftEndTime;

                const tasks = this.getTasksWithEquipment(id, equipmentId).filter((task) => {
                    return task.endTime.isAfter(shiftStartTime) && task.startTime.isBefore(shiftEndTime);
                });

                return tasks;
            };
        },
    },
    actions: {
        setUpStore(headings: ClientRowModel[], rulesets: ProductionFrontRulesViewModel[], otherDepartmentEquipmentAvailability: EquipmentUsageInDepartmentSummaryViewModel[]) {
            this.headings = headings;
            this.rulesets = rulesets;
            this.otherDepartmentEquipmentAvailability = otherDepartmentEquipmentAvailability;
            this.validateAll();
        },
        addWarningForTask(subject: UserNotificationSubject, task: ProductionValidationTaskModel, affectedArea: AffectedAreaSpecification, warning: ClientTaskWarning) {
            this.warnings.push({
                text: warning.message,
                subject: subject,
                affectedArea: affectedArea,
                targetTasks: [task],
                source: warning.type
            });
        },
        updateStore(nextShiftHeadings: ClientRowModel[]) {
            // When we change shifts in offline mode, the headings have not had their tasks corrected based off of the previous shift's movements.
            // Unless we reassign tasks to THIS shifts headings the state will be inccorect.
            const headingsWithCorrectedTasks = nextShiftHeadings.map((x) => {
                return {
                    ...x,
                    tasks: this.tasks
                        .filter((task) => task.locationId === x.location!.id)
                        .sort((a, b) => {
                            if (a.startTime.isSame(b.startTime)) {
                                if (a.durationMinutes === 0 && b.durationMinutes !== 0) return -1;
                                else if (a.durationMinutes !== 0 && b.durationMinutes === 0) return 1;
                                else return 0;
                            }

                            return a.startTime.diff(b.startTime);
                        }),
                };
            });

            nextShiftHeadings.splice(0, nextShiftHeadings.length, ...headingsWithCorrectedTasks);
            this.headings = nextShiftHeadings;
            this.validateAll();
        },
        async executeTaskCommand(
            command: OfflineEditableCommand & { shiftId: string; departmentId: string; plannedLocationId: string },
            additionalClientOnlyCommands: ClientOnlyTaskCommand[] = []
        ) {
            const index = this.headings.findIndex((row) => row.id == command.plannedLocationId);
            const original = cloneDeep(this.headings[index]);

            if (index === -1) {
                throw new Error(`Could not find heading with id ${command.plannedLocationId} in shift store headings`);
            }

            const commandType = GetCommandType(command);
            const equipment = useShiftEquipment().equipment.map(
                (e) =>
                    ({
                        _type: 'PlannedEquipmentModel',
                        ...e,
                        equipmentRoleId: e.role?.id ?? null,
                        equipment: {
                            ...e,
                            equipmentRoleId: e.role?.id ?? null,
                            equipmentRole: { ...e.role },
                        },
                        equipmentAvailabilityType: e.type,
                    } as any)
            );
            const clientCommandHandlers = GetClientOnlyCommandHandlers(additionalClientOnlyCommands);
            const commandHandlers = GetCommandHandlers(commandType, this.headings[index].location!, useDepartmentStore().departmentId, equipment, useDepartmentStore().taskTypes, useShiftWindowActuals().getBlastPacketTargetsDisplayInformation);
            // @ts-ignore
            const syncChangeSetTransformer = (commandHandlers.syncChangeSetTransformerFactory ?? NoOpChangeSetTransformerFactory)(command);

            // @ts-ignore
            commandHandlers.clientHandler(this.headings[index].tasks, command);

            for (const clientCommandHandler of clientCommandHandlers) {
                clientCommandHandler(this.headings[index].tasks);
            }

            if (commandHandlers.updateHeading)
                this.updateHeadingTasksAndValidate(command.plannedLocationId, this.headings[index].tasks);
            else this.validateAllWithSpecificRowLocations(index);

            let ignoreResponseTags: boolean = false;
            let response: any = null;
            if (commandHandlers.syncType === 'TASK') {
                const existingTags = { ... this.headings[index].tags };
                this.headings[index].tags.current = this.headings[index].tags.next;
                this.headings[index].tags.next = uuidv4();
                ignoreResponseTags = true;
                response = await RequestHandler.syncChangeSet(original, this.headings[index], existingTags, syncChangeSetTransformer);
            } else if (commandHandlers.syncType === 'ADHOC') {
                const existingTags = { ... this.headings[index].tags };
                ignoreResponseTags = true;
                this.headings[index].tags.current = this.headings[index].tags.next;
                this.headings[index].tags.next = uuidv4();
                // @ts-ignore
                response = await RequestHandler.syncAdhocChangeSet(original, this.headings[index], command.departmentId, existingTags);
            } else if (commandHandlers.syncType === 'TASK_THEN_API') {
                const existingTags = { ... this.headings[index].tags };
                this.headings[index].tags.current = this.headings[index].tags.next;
                this.headings[index].tags.next = uuidv4();
                await RequestHandler.syncChangeSet(original, this.headings[index], existingTags, syncChangeSetTransformer);
                // @ts-ignore
                await commandHandlers.apiHandler(command);
            } else {
                // @ts-ignore
                response = await commandHandlers.apiHandler(command);
            }

            if (isTaskCommandResponse(response?.data) && !ignoreResponseTags) {
                const update = (response.data) as TaskCommandResponse;
                this.headings[index].tags.current = update.planningUpdateTag ?? this.headings[index].tags.next;
                this.headings[index].tags.next = uuidv4(); 
            }
        },
        actualsUpdatedForLocations(locationIds: string[]) {
              const headings = this.headings.filter(row=>locationIds.includes(row.location?.id ?? ''));

            const ringErrorAccumulator = (t: ProductionValidationTaskModel, affectedArea: AffectedAreaSpecification, warning: ClientTaskWarning) => {
                this.addWarningForTask(UserNotificationSubject.RingRules, t, affectedArea, warning);
                AddWarningToTask(t, warning);
            }

              for(const heading of headings) {
                  ClearAllTaskWarningsForGivenSources([RING_STATE_ISSUE_TYPE, BLAST_PACKET_STATE_ISSUE_TYPE], heading);
              }
              this.reEvaluateRingStatesForAllHeadings(ringErrorAccumulator);

              this.validateAll();
        },
        updateHeadingTasksAndValidate(headingId: string, newTasks: ClientTaskModel[], applyConstraints: boolean = true) {
            newTasks.sort((a, b) => {
                if (a.startTime.isSame(b.startTime)) {
                    if (a.durationMinutes === 0 && b.durationMinutes !== 0) return -1;
                    else if (a.durationMinutes !== 0 && b.durationMinutes === 0) return 1;
                    else return 0;
                }

                return a.startTime.diff(b.startTime);
            });
            const index = this.headings.findIndex((row) => row.id == headingId);

            if (index === -1) {
                throw new Error(`Could not find heading id ${headingId} in shift store headings`);
            }
            const heading = this.headings[index];

            const correctedTasks = applyConstraints ? ApplyConstraints(
                newTasks,
                // @ts-ignore
                heading.noGoZoneFinder,
                useDepartmentStore().taskTypes,
                heading.eosTask
            ): newTasks;

            const shiftEndTime = useShiftDetails().shiftEndTime;
            const firstTaskInNextShift = newTasks.find(
                (x) => x.startTime.isSameOrAfter(shiftEndTime) && IsFloatDelay(x) === false
            );
            const newEos = firstTaskInNextShift?.taskType.nameToDo ?? '';

            // Need to use Vue set here to keep reactivity for the store headings
            this.headings[index].tasks.splice(0, this.headings[index].tasks.length, ...correctedTasks);
            this.headings[index].eoS = newEos;

            const ringErrorAccumulator = (t: ProductionValidationTaskModel, affectedArea: AffectedAreaSpecification, warning: ClientTaskWarning) => {
                this.addWarningForTask(UserNotificationSubject.RingRules, t, affectedArea, warning);
                AddWarningToTask(t, warning);
            }

            ClearAllTaskWarningsForGivenSources([RING_STATE_ISSUE_TYPE, BLAST_PACKET_STATE_ISSUE_TYPE], this.headings[index]);
            this.clearWarningSummariesForSubjectsAndLocation([UserNotificationSubject.RingRules], [RING_STATE_ISSUE_TYPE, BLAST_PACKET_STATE_ISSUE_TYPE], this.headings[index].location!.id);
            this.reEvaluateRingStatesForHeading(this.headings[index], ringErrorAccumulator);
            this.validateAllWithSpecificRowLocations(index);
        },
        assignEquipment(rowId: string, taskId: string, equipmentId: string | null) {
            const task = this.getTaskById(taskId);
            task.equipmentAssignedOn = dayjs().utc();
            const index = this.headings.findIndex((row) => row.id == rowId);
            if (equipmentId == null) {
                task.plannedEquipment.splice(0, task.plannedEquipment.length);
                task.primaryEquipment = null;
                this.validateAllWithSpecificRowLocations(index);
                return;
            }
            const plannedEquipment = useShiftEquipment().getPlannedEquipment(equipmentId);

            task.primaryEquipment = {
                id: plannedEquipment.id,
                equipmentId: plannedEquipment.equipmentId!,
                name: plannedEquipment.name!,
                imageUrl: plannedEquipment.imageUrl!,
            };
            if (task.plannedEquipment.findIndex((x) => x.equipmentId === equipmentId) === -1) {
                task.plannedEquipment.splice(0, task.plannedEquipment.length);
                task.plannedEquipment.push({
                    ...plannedEquipment,
                    _type: 'ShiftPlannedEquipment',
                    equipmentRole: {
                        _type: 'EquipmentRoleViewModel',
                        id: plannedEquipment.role.id,
                        name: plannedEquipment.role.name,
                        isCrewType: plannedEquipment.role.isCrewType,
                        displayOrder: plannedEquipment.role.displayOrder,
                    },
                    equipmentRoleId: plannedEquipment.role.id,
                    equipmentId: plannedEquipment.equipmentId,
                    id: plannedEquipment.id,
                    imageUrl: plannedEquipment.imageUrl,
                    availability: plannedEquipment.availability.map((a) => ({
                        _type: 'EquipmentAvailabilityViewModel',
                        id: a.id,
                        endsAt: a.endsAt,
                        equipmentAvailabilityType: a.type,
                        startsAt: a.startsAt,
                    })),
                    idleOrUnavailable: plannedEquipment.idleOrUnavailable,
                    name: plannedEquipment.name,
                    showConflicts: plannedEquipment.showConflicts,
                    isPrimary: true,
                });
            }

            this.validateAllWithSpecificRowLocations(index);
        },
        validateAll() {
            this.clearWarningSummariesForSubjects([UserNotificationSubject.RingRules], [RING_RULE_ISSUE_TYPE, RING_STATE_ISSUE_TYPE, BLAST_PACKET_STATE_ISSUE_TYPE]);
            this.clearWarningSummariesForSubjects([UserNotificationSubject.Equipment], [EQUIPMENT_ISSUE_TYPE]);

            const equipmentErrorAccumulator = (t: ClientTaskModel, affectedArea: AffectedAreaSpecification, warning: ClientTaskWarning, errorType: TaskErrorType) => {
                this.addWarningForTask(UserNotificationSubject.Equipment, t, affectedArea, warning);
                AddErrorToTask(t,warning.message,errorType, EQUIPMENT_ISSUE_TYPE);
            };

            const ringErrorAccumulator = (t: ProductionValidationTaskModel, affectedArea: AffectedAreaSpecification, warning: ClientTaskWarning) => {
                this.addWarningForTask(UserNotificationSubject.RingRules, t, affectedArea, warning);
                AddWarningToTask(t, warning);
            }

            for(const heading of this.headings) {
                clearTaskErrors(heading);
                ClearAllTaskWarningsForGivenSources([RING_RULE_ISSUE_TYPE, RING_STATE_ISSUE_TYPE], heading);
                validateTaskLocations(heading);
            }
            this.reEvaluateRingStatesForAllHeadings(ringErrorAccumulator);
            validateEquipmentUsage(this.headings, this.otherDepartmentEquipmentAvailability, equipmentErrorAccumulator);
            useShiftWindowActuals().validateProductionFrontRules(this.headings, this.rulesets, ringErrorAccumulator);
        },
        validateAllWithSpecificRowLocations(rowToValidateLocations: number) {
            this.clearWarningSummariesForSubjects([UserNotificationSubject.RingRules], [RING_RULE_ISSUE_TYPE]);
            this.clearWarningSummariesForSubjects([UserNotificationSubject.Equipment], [EQUIPMENT_ISSUE_TYPE]);

            const equipmentErrorAccumulator = (t: ClientTaskModel, affectedArea: AffectedAreaSpecification, warning: ClientTaskWarning, errorType: TaskErrorType) => {
                this.addWarningForTask(UserNotificationSubject.Equipment, t, affectedArea, warning);
                AddErrorToTask(t,warning.message,errorType, EQUIPMENT_ISSUE_TYPE);
            };

            this.headings.filter((t, i) => i !== rowToValidateLocations).forEach(clearTaskNonLocationErrors); // Clear all errors except location-related ones for all headings
            this.headings.forEach(_.curry(ClearAllTaskWarningsForGivenSources)([RING_RULE_ISSUE_TYPE]));
            clearTaskErrors(this.headings[rowToValidateLocations]);
            validateTaskLocations(this.headings[rowToValidateLocations]);
            validateEquipmentUsage(this.headings, this.otherDepartmentEquipmentAvailability, equipmentErrorAccumulator);



            const accumulator = (t: ProductionValidationTaskModel, affectedArea: AffectedAreaSpecification, warning: ClientTaskWarning) => {
                this.addWarningForTask(UserNotificationSubject.RingRules, t, affectedArea, warning);
                AddWarningToTask(t, warning);
            }

            useShiftWindowActuals().validateProductionFrontRules(this.headings, this.rulesets, accumulator);
        },
        clearWarningSummariesForSubjects(subjects: UserNotificationSubject[], sourcesToRemove: ClientTaskIssueType[]) {
            this.warnings = this.warnings.filter(w=>!subjects.includes(w.subject) || !sourcesToRemove.includes(w.source));
        },
        clearWarningSummariesForSubjectsAndLocation(subjects: UserNotificationSubject[], sourcesToRemove: ClientTaskIssueType[], locationId: string) {
            this.warnings = this.warnings.filter(w=>w.affectedArea.locationId !== locationId || !subjects.includes(w.subject) || !sourcesToRemove.includes(w.source));
        },
        reEvaluateRingStatesForHeading(heading: ClientRowModel, ringErrorAccumulator: (t: ProductionValidationTaskModel, affectedArea: AffectedAreaSpecification, warning: ClientTaskWarning)=>void){
            // get any associated locations due to sharing a stope
            if(heading.stopeInfo != null){
                const associatedHeadings = this.headings.filter(h=>h.stopeInfo?.stopeId === heading.stopeInfo!.stopeId)
                    .map(h=>({
                        ...h,
                        stopeInfo: h.stopeInfo!
                    }));
                useShiftWindowActuals().reEvaluateRingStatesForHeadings(associatedHeadings, ringErrorAccumulator);
            }
        },
        reEvaluateRingStatesForAllHeadings(ringErrorAccumulator: (t: ProductionValidationTaskModel, affectedArea: AffectedAreaSpecification, warning: ClientTaskWarning)=>void){
            const headingsGroupedByStope = _.groupBy(this.headings.filter(h=>h.stopeInfo != null).map(h=>({
                ...h,
                stopeInfo: h.stopeInfo!
            })), h=>h.stopeInfo!.stopeId);

            _.keys(headingsGroupedByStope).map(k=>{
                useShiftWindowActuals().reEvaluateRingStatesForHeadings(headingsGroupedByStope[k], ringErrorAccumulator);
            });
        }
    },
});
