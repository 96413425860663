<template>
    <basic-screen>
        <template v-slot:appBarCenter>
            <router-link :to="{ name: `default${boardType}board` }">Back to Board</router-link>
        </template>
        <v-container fluid>
            <h1 class="title">My Account</h1>
            <v-card class="d-flex justify-center mt-5" flat tile>
                <v-card width="500px">
                    <v-card-title>Change Password</v-card-title>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-flex sx12>
                                <v-text-field label="Current password" type="password"></v-text-field>
                            </v-flex>
                            <v-flex sx12>
                                <v-text-field label="New password" type="password"></v-text-field>
                            </v-flex>
                            <v-flex sx12>
                                <v-text-field label="New password again" type="password"></v-text-field>
                            </v-flex>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="change()">Change</v-btn>
                    </v-card-actions>
                </v-card>
            </v-card>
        </v-container>
    </basic-screen>
</template>

<script>
import BasicScreen from '@/templates/BasicScreen.vue';
import ShiftStore from '@/lib/stores/CurrentShiftStore';

export default {
    components: {
        BasicScreen
    },
    data() {
        return { boardType: ShiftStore.selectedBoardType };
    },
    methods: {
        change() {}
    }
};
</script>
