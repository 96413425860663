import { ShiftHazardNoteViewModel, UpdateLocationHazardsAtShiftCommand } from '@/models/api';
import { ClientUpdateHazardsCommand } from '@/models/client/Commands/client-update-hazards-command';
import { ClientAddHazardsCommand } from '@/models/client/Commands/client-add-hazards-command';

export function UpdateExistingHazards(existingHazards: ShiftHazardNoteViewModel[], command: ClientUpdateHazardsCommand, userName: string, currentTimeUTC: Date): ShiftHazardNoteViewModel[] {
    const newHazards: ShiftHazardNoteViewModel[] = command.toAddOrUpdate
        .filter(x=>!existingHazards.map(x=>x.id).includes(x.id))
        .map(x=>({
            ...x,
            _type: 'ShiftHazardNoteViewModel',
            madeBy: userName,
            madeAtUTC: currentTimeUTC,
            updatedBy: null,
            updatedAtUTC: null,
        } as ShiftHazardNoteViewModel));

    return [...existingHazards.filter(x=>!command.toRemoveIds.includes(x.id))
        .map(x=>({
            ...x,
            ...command.toAddOrUpdate.find(y=>y.id === x.id),
            _type: 'ShiftHazardNoteViewModel',
            updatedBy: userName,
            updatedAtUTC: currentTimeUTC,
        } as ShiftHazardNoteViewModel)), ...newHazards];
}

export function AddToExistingHazards(existingHazards: ShiftHazardNoteViewModel[], command: ClientAddHazardsCommand, userName: string, currentTimeUTC: Date): ShiftHazardNoteViewModel[] {
    const newHazards: ShiftHazardNoteViewModel[] = command.toAdd
        .filter(x=>!existingHazards.map(x=>x.id).includes(x.id))
        .map(x=>({
            ...x,
            _type: 'ShiftHazardNoteViewModel',
            madeBy: userName,
            madeAtUTC: currentTimeUTC,
            updatedBy: null,
            updatedAtUTC: null,
        } as ShiftHazardNoteViewModel));

    return [...existingHazards, ...newHazards];
}