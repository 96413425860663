<template>
    <v-tooltip bottom v-if="showing">
        <template #activator="{ on }">
            <v-chip v-on="on" outlined color="white" text-color="white" class="myclock ml-2">
                <v-icon left>mdi-clock-time-five</v-icon>
                <span class="time">{{ time }}</span>
            </v-chip>
        </template>
        Mine Time - zone '{{ getMineTimeZone() }}'
    </v-tooltip>
</template>
<style lang="scss">
.v-chip.v-size--default.myclock {
    font-size: 16px;

    .time {
        padding-top: 4px;
    }
}
</style>
<script>
import { EventBus, Events } from '@/lib/EventBus';
import MineAreaStore from '@/lib/stores/MineAreaStore';
import CurrentShiftStore from '@/lib/stores/CurrentShiftStore';
import SelectedMineAreaStore from '@/lib/stores/SelectedMineAreaStore';
import dayjs from 'dayjs';

export default {
    data() {
        return {
            ticks: 1,
            interval: 0
        };
    },
    created() {
        EventBus.$on(Events.ShiftSelected, this.start);
        EventBus.$on(Events.MineAreasUpdated, this.start);
        setTimeout(() => this.start(), 1000);
    },
    destroyed() {
        EventBus.$off(Events.ShiftSelected, this.start);
        EventBus.$off(Events.MineAreasUpdated, this.start);
        this.stop();
    },
    methods: {
        start() {
            this.stop();
            this.interval = setInterval(() => this.ticks++, 1000);
        },
        stop() {
            if (this.interval) {
                clearInterval(this.interval);
            }
        },
        getMineTimeZone() {
            return SelectedMineAreaStore.getMineTimeZone();
        }
    },
    computed: {
        time() {
            var mine = SelectedMineAreaStore.getSelectedMineArea();
            if (!this.ticks || !mine || !mine.timeZone) {
                return '';
            }

            return dayjs()
                .tz(mine.timeZone)
                .format('HH:mm:ss');
        },
        showing() {
            if (
                !this.ticks ||
                !CurrentShiftStore.selectedMineArea ||
                SelectedMineAreaStore.getSelectedMineArea() == null
            ) {
                return false;
            }
            return true;
        }
    }
};
</script>
