import UserSessionState from '@/lib/SessionState';

export class Account {
    private userSessionState = new UserSessionState();

    async me() {
        return this.userSessionState.user;
    }
}

export default new Account();
