import { GenericDataApi } from '@/lib/data/GenericDataApi';
import AppHttp from '@/lib/AppHttp';
import Errors from '@/lib/Errors';
import EditMappings from '@/lib/OfflineEdits/EditMappings';
import { AnswerQuestionCommand, Meeting, StartStopMeetingCommand } from '@/models/api';
import { AxiosError } from 'axios';

export class Meetings extends GenericDataApi<Meeting> {
    constructor() {
        super('Meetings');
    }

    async getMeeting(id: string, include?: string[]) {
        let query = '';
        if (include) {
            const p: string = include.map((x) => `include=${x}`).join('&');
            query = '?' + p;
        }
        const response = await AppHttp.get(this.resource + `/${id}` + query);
        const data = response ? response.data : null;
        return await EditMappings.applyPendingEdits('Meeting', data);
    }

    public async answer(command: AnswerQuestionCommand) {
        const response = await AppHttp.post('Meetings/answer', command);
        const data = response ? response.data : null;
        return data;
    }

    public async startstop(command: StartStopMeetingCommand) {
        const response = await AppHttp.post(`Meetings/startstop`, command);
        const data = response ? response.data : null;
        return data;
    }
}

export default new Meetings();
