import AppHttp from '@/lib/AppHttp';
import Errors from '@/lib/Errors';
import { AxiosError } from 'axios';

export class Admin {
    public async fixUsers() {
        const response = await AppHttp.get(`Tenants/FixUsers`);
        const data = response ? response.data : null;
        return data;
    }
}

export default new Admin();
