import { CycleType } from '@/models/api';
import ClientRowModel from '@/models/client/client-row';
import ClientTaskModel from '@/models/client/client-task';
import { RowType } from '@/models/client/types/row-type';
import { getPrevious } from './Array';
import { IsFloatDelay } from '@/lib/services/Task';
import WeekTask from '@/models/client/week-task';

export const isDurationRow = (row: { location: { cycleType: CycleType | null } | null }) => {
    if (row.location === null) return false;
    return row.location.cycleType === CycleType.Duration;
};

export const isRateRow = (row: { location: { cycleType: CycleType | null } | null }) => {
    if (row.location === null) return false;
    return row.location.cycleType === CycleType.Rate;
};

export const isAdhocRow = (row: { location: { cycleType: CycleType | null } | null }) => {
    if (row.location === null) return false;
    return row.location.cycleType === CycleType.AdHoc;
};

export const getRowType = (row: { location: { cycleType: CycleType | null } | null }): RowType => {
    if (isAdhocRow(row)) {
        return RowType.ADHOC;
    }

    if (isDurationRow(row)) {
        return RowType.DURATION;
    }

    if (isRateRow(row)) {
        return RowType.RATE;
    }

    throw new Error('Row type is unknown');
};

export const extendPreviousFloatDelay = (
    impactingTasks: WeekTask[],
    taskIndex: number,
    currentStart: number,
    task: WeekTask
) => {
    const previousTask = getPrevious(impactingTasks, taskIndex);
    if (previousTask && IsFloatDelay(previousTask) && previousTask.end == currentStart - 1) {
        previousTask.end = task.start - 1;
    }
};

export const sortLocations = (
    priorityComparison: (a: ClientRowModel, b: ClientRowModel) => number,
    a: ClientRowModel,
    b: ClientRowModel,
    descending: boolean
) => {
    const multiplier = descending ? -1 : 1;

    if (a.location!.reducedLevelName! < b.location!.reducedLevelName!) {
        return -1 * multiplier;
    }
    if (a.location!.reducedLevelName! > b.location!.reducedLevelName!) {
        return 1 * multiplier;
    }

    return descending ? priorityComparison(b,a) : priorityComparison(a, b);
};

export const sortLocationsMultiple = (
    comparisons: ((a: ClientRowModel, b: ClientRowModel) => number)[],
    a: ClientRowModel,
    b: ClientRowModel,
    descending: boolean
) => {
    for (const comparison of comparisons) {
        const result = descending ? comparison(b,a) : comparison(a, b);
        if (result !== 0) {
            return result;
        }
    }

    return 0;
};
