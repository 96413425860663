import auth0 from 'auth0-js';
import { EventBus, Events } from '@/lib/EventBus';
import UserSessionState from '@/lib/SessionState';
import Config from './Config';
import Tenants from '@/lib/Tenants';

class IUserDetails {}
interface ITenant {
    tenant_id:string,
    roles: string[]
}

export class AuthSvc {
    private auth0: any;
    private sessionState: any;

    public init() {
        this.auth0 = new auth0.WebAuth({
            domain: Config.AuthDomain,
            clientID: Config.AuthClientID,
            redirectUri: `${window.location.protocol}//${window.location.host}/callback`,
            responseType: 'token id_token',
            scope: 'openid email profile https://ordodeploy.com/tenant_id'
        });
        this.sessionState = new UserSessionState();
    }

    public login() {
        this.auth0.authorize({});
    }

    public async handleAuthentication(): Promise<any | null> {
        await this.mapUrlHashToSessionState();
    }

    public logout() {
        this.sessionState.accessToken = null;
        this.sessionState.idToken = null;
        this.sessionState.expiresAt = null;
        this.sessionState.userPic = null;
        this.sessionState.userDetails = null;
        this.sessionState.user = null;
        EventBus.$emit(Events.AuthChange, false);
        this.auth0.logout({ returnTo: `${window.location.protocol}//${window.location.host}` });
    }

    public isAuthenticated() {
        // Check whether the current time is past the
        // Access Token's expiry time
        const expiresAtString = this.sessionState.expiresAt;
        if (!expiresAtString) return false;
        if (!navigator.onLine) return true;
        return Date.now() / 1000 < JSON.parse(expiresAtString);
    }

    public getUser(): IUserDetails | null {
        if (!this.isAuthenticated()) return null;
        return this.sessionState.userDetails as IUserDetails;
    }
    public getUserField(fieldName: string): string | null {
        if (!this.isAuthenticated()) return null;
        const user = this.getUser();
        if (!user) return null;
        return (user as any)[fieldName];
    }
    public getPic(): string | null {
        return this.sessionState.userPic;
    }
    public getBearer(): string {
        return this.sessionState.idToken || '';
    }
    public hasRole(role: string): boolean {
        const user = this.sessionState.user;
        const tenant = Tenants.GetCurrentTenant();
        if (user == null) return false;
        if (!user.tenants) return false;
        for (const x of user.tenants) {
            if (x.tenant_id.toLowerCase() != tenant?.toLowerCase()) continue;
            for (const r of x.roles) if (r === role) return true;
        }
        return false;
    }
    public getTenants(): ITenant[] {
        return this.sessionState?.user?.tenants || [];
    }
    private parseUrlHash(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.auth0.parseHash(async (err: any, authResult: any) => {
                if (err) return reject(err);

                if (!authResult.expiresIn || !authResult.accessToken || !authResult.idToken) {
                    return reject(new Error('There was an error processing the authentication result.'));
                }

                resolve(authResult);
            });
        });
    }

    private parseAccessToken(accessToken: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.auth0.client.userInfo(accessToken, (err: any, user: any) => {
                if (err) reject(err);
                else resolve(user);
            });
        });
    }

    private async mapUrlHashToSessionState(): Promise<any | null> {
        const authResult = await this.parseUrlHash();
        const user = this.stripEmailFromNameField(await this.parseAccessToken(authResult.accessToken)) as IUserDetails;
        this.sessionState.userDetails = user;
        this.sessionState.user = {
            id: authResult.idTokenPayload.sub,
            name: authResult.idTokenPayload.name,
            email: authResult.idTokenPayload.email,
            sub: authResult.idTokenPayload.sub,
            tenants: authResult.idTokenPayload['https://ordodeploy.com/tenants'] || [{
                tenant_id: authResult.idTokenPayload['https://ordodeploy.com/tenant_id'],
                roles: authResult.idTokenPayload['https://ordodeploy.com/roles']
            }] //support old style metadata
        };
        this.sessionState.accessToken = authResult.accessToken;
        this.sessionState.idToken = authResult.idToken;
        this.sessionState.expiresAt = JSON.stringify(authResult.idTokenPayload.exp);
        EventBus.$emit('auth-details-change', user);
        EventBus.$emit(Events.AuthChange, { authenticated: true });
        return user;
    }

    private stripEmailFromNameField(user: any): any {
        if(user == null) return user;
        if(user.name == null) return user;
        if(user.email == null) return user;
        if(user.name.indexOf(user.email) === -1) return user;
        user.name = user.name.replace(user.email, user.sub ?? 'Anonymous');
        return user;
    }
}

const svc = new AuthSvc();

EventBus.$on(Events.AuthLogout, (initiatedByUser: boolean) => {
    svc.logout();
});

export default svc;
