import { render, staticRenderFns } from "./ExpandingTextarea.vue?vue&type=template&id=728a711f&scoped=true&"
import script from "./ExpandingTextarea.vue?vue&type=script&lang=ts&"
export * from "./ExpandingTextarea.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "728a711f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VTextarea})
