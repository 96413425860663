import { EquipmentRoleViewModel, PlannedEquipmentViewModel } from '@/models/api';
import { EquipmentModel } from '@/models/client/equipment-model';

export interface EquipmentGroup {
    equipment: EquipmentModel[];
    name: string;
}

export const equipmentMatchesFilter = (equipment: PlannedEquipmentViewModel, searchFilter: string) => {
    if (equipment.idleOrUnavailable) return false;

    if (!searchFilter) return true;

    const equipmentName = equipment.equipment?.name;
    if (equipmentName && equipmentName.toLowerCase().indexOf(searchFilter) > -1) return true;

    const equipmentRoleName = equipment.equipment?.equipmentRole?.name;
    if (equipmentRoleName && equipmentRoleName.toLowerCase().indexOf(searchFilter) > -1) return true;

    return false;
};

export const groupEquipmentByRole = (
    equipment: PlannedEquipmentViewModel[],
    roles: EquipmentRoleViewModel[]
): EquipmentGroup[] => {
    const groups: any = {};

    roles.forEach((element) => {
        if (!element.id) return;
        if (groups[element.id] === undefined) {
            groups[element.id] = {
                name: element.name,
                equipment: [],
            };
        }
    });

    groups['Unknown'] = {
        name: 'Uncategorised',
        equipment: [],
    };

    for (const item of equipment) {
        const roleId = item.equipment?.equipmentRoleId;

        if (roleId && groups[roleId]) {
            groups[roleId].equipment.push({
                id: item.id,
                imageUrl: item.equipment?.imageUrl,
                name: item.equipment?.name,
                equipmentId: item.equipment?.id,
            });
        } else {
            groups['Unknown'].equipment.push({
                id: item.id,
                imageUrl: item.equipment?.imageUrl,
                name: item.equipment?.name,
                equipmentId: item.equipment?.id,
            });
        }
    }

    const ret = [];
    for (const groupId in groups) {
        ret.push(groups[groupId]);
    }

    return ret;
};
