import { GenericDataApi } from '@/lib/data/GenericDataApi';
import { MaterialType } from '@/models/api/Data/material-type';
import { GetActualPhysicalEntriesForShiftWindowQueryResponse } from '@/models/api';
import AppHttp from '@/lib/AppHttp';

export class MaterialTypes extends GenericDataApi<MaterialType> {
    constructor() {
        super('MaterialTypes');
    }

    public async getForMineArea(mineAreaId: string, include?: string[]): Promise<MaterialType[]> {
        let query = '';
        if (include) {
            const p: string = include.map((x) => `include=${x}`).join('&');
            query = '?' + p;
        }

        const response = await AppHttp.get(`${this.resource}/MineAreas/${mineAreaId}${query}`);

        const data = response ? response.data : null;
        if(data == null) throw Error('No response data');
        return data;
    }
}

export default new MaterialTypes();
