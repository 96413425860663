import { RingRuleEvaluationState, RingRuleEvaluationStatus, TaskTypeViewModel } from '@/models/api';
import { IsBoggingTaskType, IsChargeTaskType, IsDrillTaskType, IsFireTaskType } from '@/lib/services/TaskType';

export function ConvertTaskTypeToStatus(taskType: TaskTypeViewModel): RingRuleEvaluationStatus | null {
    if(IsDrillTaskType(taskType))
        return RingRuleEvaluationStatus.Drilled;
    else if(IsChargeTaskType(taskType))
        return RingRuleEvaluationStatus.Charged;
    else if(IsFireTaskType(taskType))
        return RingRuleEvaluationStatus.Fired;
    else if(IsBoggingTaskType(taskType))
        return RingRuleEvaluationStatus.Bogging;
    else
        return null;
}

export const RING_STATUS_EN = {
    PAST_TENSE: {
        [RingRuleEvaluationStatus.NotDrilled]: 'Not drilled',
        [RingRuleEvaluationStatus.Drilled]: 'Drilled',
        [RingRuleEvaluationStatus.Charged]: 'Charged',
        [RingRuleEvaluationStatus.Fired]: 'Fired',
        [RingRuleEvaluationStatus.Bogging]: 'Bogging'
    } as { [key in RingRuleEvaluationStatus]: string},
    PRESENT_TENSE: {
        [RingRuleEvaluationStatus.NotDrilled]: 'N/A',
        [RingRuleEvaluationStatus.Drilled]: 'Drill',
        [RingRuleEvaluationStatus.Charged]: 'Charge',
        [RingRuleEvaluationStatus.Fired]: 'Fire',
        [RingRuleEvaluationStatus.Bogging]: 'Bog'
    } as { [key in RingRuleEvaluationStatus]: string},
    PRESENT_TENSE_PROGRESSIVE: {
        [RingRuleEvaluationStatus.NotDrilled]: 'N/A',
        [RingRuleEvaluationStatus.Drilled]: 'Drilling',
        [RingRuleEvaluationStatus.Charged]: 'Charging',
        [RingRuleEvaluationStatus.Fired]: 'Firing',
        [RingRuleEvaluationStatus.Bogging]: 'Bogging'
    } as { [key in RingRuleEvaluationStatus]: string},
}