import { GenericDataApi } from '@/lib/data/GenericDataApi';
import { ShiftWindowQueryResponse, ShiftWindowOffsetQueryResponse } from '@/models/api';
import AppHttp from '../AppHttp';

export class ShiftWindowApi extends GenericDataApi<any> {
    constructor() {
        super('ShiftWindow');
    }

    public async getShiftWindowOffset(
        mineAreaId: string,
        departmentId: string,
        date: string,
        number: string,
        offset: number,
    ): Promise<ShiftWindowOffsetQueryResponse> {
        const params = {
            mineAreaId,
            departmentId,
            date,
            number,
            offset,
        } as any;

        const response = await AppHttp.get(`${this.resource}/offset?${new URLSearchParams(params).toString()}`);
        return response ? response.data : null;
    }
}

export default new ShiftWindowApi();
