import Equipment from '@/models/client/equipment';
import { EquipmentType } from '@/models/api';

export function IsPrimary(equipment: Equipment) {
    // @ts-ignore
    return equipment.type == 'Primary' || equipment.type === EquipmentType.Primary;
}

export function IsSecondary(equipment: Equipment) {
    // @ts-ignore
    return equipment.type == 'Secondary' || equipment.type === EquipmentType.Secondary;
}

export function IsSupport(equipment: Equipment) {
    // @ts-ignore
    return equipment.type == 'Support' || equipment.type === EquipmentType.Support;
}

export function SortByRoleThenName(a: Equipment, b: Equipment) {
    if (a.role && b.role && a.role.displayOrder != b.role.displayOrder) {
        return a.role.displayOrder - b.role.displayOrder;
    }

    if (a.role && !b.role) {
        return -1;
    }

    if (b.role && !a.role) {
        return 1;
    }

    if (!a.name || !b.name) {
        return 0;
    }

    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}
