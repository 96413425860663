import { TaskClasses, TaskTypeViewModel } from '@/models/api';

export function isEosTaskType(
    taskType: TaskTypeViewModel,
    departmentEosTask: TaskTypeViewModel | null = null
): boolean {
    if (taskType.taskClass === TaskClasses.Misfire) return true;
    if (taskType.taskClass === TaskClasses.Strip) return true;
    if (taskType.taskClass === TaskClasses.DidNotFire) return true;
    if(taskType.taskClass === TaskClasses.Fire) return true; // you should always move Fires, even if they're not EoS
    if (isFireTaskType(taskType, departmentEosTask?.id ?? null)) return true;
    return false;
}

export function isFireTaskType(taskType: TaskTypeViewModel, departmentEosTaskId: string | null): boolean {
    if (!departmentEosTaskId) return false;
    return taskType.id === departmentEosTaskId;
}

export function isFireLikeTaskType(taskType: TaskTypeViewModel, departmentEosTaskId: string | null): boolean {
    return taskType.id === departmentEosTaskId ||
        taskType.taskClass === TaskClasses.Fire ||
        taskType.taskClass === TaskClasses.Misfire ||
        taskType.taskClass === TaskClasses.Strip;
}

export function isDidNotFireTaskType(taskType: TaskTypeViewModel): boolean {
    return taskType.taskClass === TaskClasses.DidNotFire;
}
