let logoHeader = process.env.VUE_APP_LogoHeader;
if (!logoHeader || logoHeader.includes('#{')) logoHeader = 'ordo_sm.png';

let logoIcon = process.env.VUE_APP_LogoIcon;
if (!logoIcon || logoIcon.includes('#{')) logoIcon = 'ordo_logo.png';

let logo = process.env.VUE_APP_Logo;
if (!logo || logo.includes('#{')) logo = 'ordo.png';

export default {
    AuthDomain: process.env.VUE_APP_AuthDomain,
    AuthClientID: process.env.VUE_APP_AuthClientID,
    AppInsightsKey: process.env.VUE_APP_AppInsightsKey,
    ApiBasePath: process.env.VUE_APP_ApiBasePath,
    LogoHeader: logoHeader,
    Logo: logo,
    LogoIcon: logoIcon,
    ApplicationVersion: process.env.VUE_APP_ApplicationVersion,
    FeatureFlags: {
        DisplayPowerBIReports: process.env.VUE_APP_DisplayPowerBIReports?.toLowerCase() === 'true',
        DisplayMainMenuHelpLink: process.env.VUE_APP_DisplayMainMenuHelpLink?.toLowerCase() === 'true',
    }
};
