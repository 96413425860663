export default class UserSessionState {
    get accessToken(): string | null {
        return localStorage.getItem('access_token');
    }

    set accessToken(token: string | null) {
        if (token == null) localStorage.removeItem('access_token');
        else localStorage.setItem('access_token', token);
    }

    get idToken(): string | null {
        return localStorage.getItem('id_token');
    }

    set idToken(token: string | null) {
        if (token == null) localStorage.removeItem('id_token');
        else localStorage.setItem('id_token', token);
    }

    get expiresAt(): string | null {
        return localStorage.getItem('expires_at');
    }

    set expiresAt(token: string | null) {
        if (token == null) localStorage.removeItem('expires_at');
        else localStorage.setItem('expires_at', token);
    }

    get account(): any | null {
        const acc = localStorage.getItem('account');
        if (acc == null) return null;
        return JSON.parse(acc);
    }

    set account(acc: any | null) {
        if (acc == null) localStorage.removeItem('account');
        else localStorage.setItem('account', JSON.stringify(acc));
    }

    get user(): any | null {
        const user = localStorage.getItem('user');
        if (user == null) return null;
        return JSON.parse(user);
    }

    set user(user: any | null) {
        if (user == null) localStorage.removeItem('user');
        else {
            if(user.name == user.email) user.name = user.sub ?? 'Anonymous';
            localStorage.setItem('user', JSON.stringify(user));
        }
    }

    get userDetails(): any | null {
        const details = localStorage.getItem('user_details');
        if (details == null) return null;
        return JSON.parse(details);
    }

    set userDetails(details: any | null) {
        if (details == null) localStorage.removeItem('user_details');
        else {
            if(details.name == details.email) details.name = details.sub ?? 'Anonymous';
            localStorage.setItem('user_details', JSON.stringify(details));
        }
    }

    get userPic(): string | null {
        return localStorage.getItem('pic');
    }

    set userPic(pic: string | null) {
        if (pic == null) localStorage.removeItem('pic');
        else localStorage.setItem('pic', pic);
    }

    get impersonate(): string | null {
        return localStorage.getItem('impersonate');
    }
}
