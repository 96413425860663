import {
    TriggerActionResponsePlanAspectViewModel,
    GetTriggerActionResponsePlanAspectsQueryResponse,
    AddLocationsToProductionFrontCommand,
    UpdateProductionFrontCommand,
    CreateProductionFrontCommand,
    ProductionFrontViewModel,
    GetProductionFrontsForDepartmentQueryResponse,
    ProductionFrontLevelDiagramOverlayTransformationDto, GetProductionFrontsRulesForDepartmentQueryResponse
} from '@/models/api';
import { AxiosError } from 'axios';
import AppHttp from '../AppHttp';
import Errors from '../Errors';
import { Dayjs } from 'dayjs';
import { over } from 'lodash';
import dayjs from 'dayjs';

export class ProductionFronts {
    GetBaseUri(departmentId: string | null): string {
        return `Departments/${departmentId}/Fronts`;
    }

    public async create(command: CreateProductionFrontCommand) {
        await AppHttp.post(`${this.GetBaseUri(command.departmentId)}/Create`, command);
    }

    public async update(departmentId: string, command: UpdateProductionFrontCommand) {
        await AppHttp.post(`${this.GetBaseUri(departmentId)}/Update`, command);
    }

    public async addLocations(departmentId: string, command: AddLocationsToProductionFrontCommand) {
        await AppHttp.post(`${this.GetBaseUri(departmentId)}/AddLocations`, command);
    }

    public async delete(departmentId: string, productionFrontId: string) {
        await AppHttp.delete(`${this.GetBaseUri(departmentId)}/${productionFrontId}`);
    }

    public async getById(departmentId: string, productionFrontId: string): Promise<ProductionFrontViewModel> {
        const response = await AppHttp.get(`${this.GetBaseUri(departmentId)}/${productionFrontId}`);

        const data = response ? response.data : null;
        if (data == null) throw 'No response data';
        return data;
    }

    public async list(departmentId: string): Promise<GetProductionFrontsForDepartmentQueryResponse> {
        const response = await AppHttp.get(`${this.GetBaseUri(departmentId)}`);
        const data = response ? response.data : null;
        if (data == null) throw 'No response data';
        return data;
    }

    public async getDiagramInformation(
        departmentId: string,
        productionFrontId: string,
        atDate: Dayjs
    ) {
        const response = await AppHttp.get(
            `${this.GetBaseUri(departmentId)}/${productionFrontId}/Diagram?AtDate=${atDate}`
        );
        const data = response ? response.data : null;
        if (data == null) throw 'No response data';
        return data;
    }

    public async importDiagramInformation(departmentId: string, productionFrontId: string, file: any) {
        const response = await AppHttp.postWithFiles(
            `${this.GetBaseUri(departmentId)}/${productionFrontId}/Diagram/Import`,
            {},
            [file]
        );
        const data = response ? response.data : null;
        return data;
    }

    public async uploadDiagramOverlay(
        departmentId: string,
        productionFrontId: string,
        productionFrontLevelId: string,
        name: string,
        displayOrder: number,
        overlayTransformation: ProductionFrontLevelDiagramOverlayTransformationDto,
        file: any
    ) {
        const response = await AppHttp.postWithFiles(
            `${this.GetBaseUri(
                departmentId
            )}/${productionFrontId}/Level/${productionFrontLevelId}/Diagram/Overlay?overlayName=${name}&displayOrder=${displayOrder}&transformX=${
                overlayTransformation.transformX
            }&transformY=${overlayTransformation.transformY}&rotation=${
                overlayTransformation.rotation
            }&scaleFactor=${overlayTransformation.scaleFactor}`,
            {},
            [file]
        );
        const data = response ? response.data : null;
        return data;
    }

    public async updateDiagramOverlay(
        departmentId: string,
        productionFrontId: string,
        productionFrontLevelId: string,
        overlayId: string,
        name: string,
        displayOrder: number,
        overlayTransformation: ProductionFrontLevelDiagramOverlayTransformationDto
    ) {
        const response = await AppHttp.post(
            `${this.GetBaseUri(
                departmentId
            )}/${productionFrontId}/Level/${productionFrontLevelId}/Diagram/Overlay/${overlayId}?overlayName=${name}&displayOrder=${displayOrder}&transformX=${
                overlayTransformation.transformX
            }&transformY=${overlayTransformation.transformY}&rotation=${
                overlayTransformation.rotation
            }&scaleFactor=${overlayTransformation.scaleFactor}`,
            null
        );
        const data = response ? response.data : null;
        return data;
    }

    public async deleteDiagramOverlay(
        departmentId: string,
        productionFrontId: string,
        productionFrontLevelId: string,
        overlayId: string
    ) {
        const response = await AppHttp.delete(
            `${this.GetBaseUri(
                departmentId
            )}/${productionFrontId}/Level/${productionFrontLevelId}/Diagram/Overlay/${overlayId}`
        );
        const data = response ? response.data : null;
        return data;
    }

    public async listRulesets(departmentId: string): Promise<GetProductionFrontsRulesForDepartmentQueryResponse> {
        const response = await AppHttp.get(
            `${this.GetBaseUri(departmentId)}/ForEvaluation`
        );
        const data = response ? response.data : null;
        if (data == null) throw 'No response data';
        return data;
    }
}

export default new ProductionFronts();
