import { render, staticRenderFns } from "./AssignShiftOwner.vue?vue&type=template&id=2714396f&"
import script from "./AssignShiftOwner.vue?vue&type=script&lang=js&"
export * from "./AssignShiftOwner.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VAutocomplete,VBtn,VCheckbox,VChip,VSpacer})
