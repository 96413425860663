<template>
    <standard-dialog v-model='dialog' :max-width="400">
        <template v-slot:title>
            {{ $t('heading.add-crew-role') }}
        </template>
        <template v-slot:content>
            <v-form ref="form" :lazy-validation="true">
                <v-container fluid v-if="roles">
                    <v-row>
                        <v-col cols="12">
                            <v-select
                                :items="roles"
                                item-value="id"
                                item-text="name"
                                :label="$t('shared.label.role')"
                                v-model="selectedRoleType"
                                :menu-props="{ dark: true }"
                                :rules="[$rules.required()]"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </template>
        <template v-slot:actions>
            <v-spacer></v-spacer>
            <v-btn color="gray" text @click="dialog = false" :loading="$wait.is('saving')">{{
                    $t('shared.button.cancel')
                }}</v-btn>
            <v-access-control :offlineAccess="true">
                <v-btn color="primary" text @click="select()">{{ $t('shared.button.add') }}</v-btn>
            </v-access-control>
        </template>
        <template v-slot:offline-actions>

        </template>
    </standard-dialog>
</template>
<i18n>
{
    "en": {
        "heading": {
            "add-crew-role": "Add Crew Role"
        }
    },
    "es": {
        "heading": {
            "add-crew-role": "Añadir función para el personal"
        }
    }
}
</i18n>
<script>
export default {
    data() {
        return {
            selectedRoleType: null,
            departmentId: null,
            departmentShiftId: null,
            crewIds: null,
            dialog: false,
            roles: [],
        };
    },
    methods: {
        show(departmentId, departmentShiftId, crewIds, roles) {
            this.selectedRoleType = null;
            this.departmentId = departmentId;
            this.crewIds = crewIds;
            this.departmentShiftId = departmentShiftId;
            this.roles = roles;
            this.dialog = true;
            setTimeout(() => this.$refs.form.resetValidation(), 1);
        },
        select() {
            if (!this.$refs.form.validate()) {
                return;
            }
            try {
                this.$wait.start('saving');
                this.dialog = false;
                this.$emit('selectRole', this.selectedRoleType);
            } finally {
                this.$wait.end('saving');
            }
            this.dialog = false;
        },
    },
};
</script>
