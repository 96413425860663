<template>
    <component
        :is="tag"
        :draggable="draggable"
        @drag="emitEvent(events.drag, $event)"
        @dragstart="emitEvent(events.dragstart, $event)"
        @dragenter="emitEvent(events.dragenter, $event)"
        @dragleave="emitEvent(events.dragleave, $event)"
        @dragend="emitEvent(events.dragend, $event)"
        @touchstart="isTouch = true"
        @touchstop="isTouch = false"
    >
        <slot :transfer-data="scopedData"></slot>
        <div v-if="hideImageHtml" :style="hideImageStyle">
            <slot name="image" :transfer-data="scopedData"></slot>
        </div>
        <slot v-else name="image" :transfer-data="scopedData"></slot>
    </component>
</template>

<script>
import transferDataStore from './transferDataStore';
import { dropEffects, effectsAllowed, events } from './constants';

export default {
    props: {
        draggable: { type: Boolean, default: true },
        transferData: {},
        dropEffect: { validator: (value) => value in dropEffects },
        effectAllowed: { validator: (value) => value in effectsAllowed },
        image: String,
        imageXOffset: { type: Number, default: 0 },
        imageYOffset: { type: Number, default: 0 },
        hideImageHtml: { type: Boolean, default: true },
        tag: { type: String, default: 'div' },
    },
    data() {
        return { dragging: false, isTouch: false };
    },
    computed: {
        events: () => events,
        scopedData() {
            return this.dragging && this.transferData;
        },
        hideImageStyle: () => ({ position: 'fixed', top: '-1000px' }),
    },
    watch: {
        isTouch() {
            transferDataStore.touch = this.isTouch;
        },
    },
    methods: {
        setTouch(nativeEvent, isTouch) {
            var target = nativeEvent.target;

            var hasClass = (t, c) => {
                for (let cl of t.classList) if (cl == c) return true;
                return false;
            };

            while (target != window && !hasClass(target, 'drag')) {
                target = target.parentElement;
            }

            this.isTouch = isTouch;

            var touch = event.changedTouches[0];
            const dataTransfer = new DataTransfer();
            dataTransfer.effectAllowed = 'uninitialized';

            var evtType = {
                touchstart: 'dragstart',
                touchmove: 'drag',
                touchend: 'dragend',
            }[nativeEvent.type];
            var simulatedEvent = new DragEvent(evtType, { dataTransfer: dataTransfer });
            simulatedEvent.initMouseEvent(
                evtType,
                true,
                true,
                window,
                1,
                touch.screenX,
                touch.screenY,
                touch.clientX,
                touch.clientY,
                false,
                false,
                false,
                false,
                0,
                null
            );
            //this.emitEvent(name);

            target.dispatchEvent(simulatedEvent);
            nativeEvent.preventDefault();
        },
        emitEvent(name, nativeEvent, isTouch) {
            if (isTouch === true || isTouch === false) this.isTouch = isTouch;
            const transfer = nativeEvent.dataTransfer || new DataTransfer();
            if (name == events.dragenter) {
                nativeEvent.preventDefault();
            }

            // Set drop effect on dragenter and dragover
            if ([events.dragenter, events.dragover].includes(name)) {
                if (this.dropEffect) {
                    transfer.dropEffect = this.dropEffect;
                }
            }

            // A number of things need to happen on drag start
            var initiatedTouchDrag = name == events.drag && this.isTouch && !this.dragging;
            if (name === events.dragstart || initiatedTouchDrag) {
                if (nativeEvent.dataTransfer) nativeEvent.dataTransfer.setData('text', 'anything');
                // Set the allowed effects
                if (this.effectAllowed) {
                    transfer.effectAllowed = this.effectAllowed;
                }
                // Set the drag image
                if (this.image || this.$slots.image) {
                    let image;
                    if (this.image) {
                        image = new Image();
                        image.src = this.image;
                    } else if (this.$slots.image) {
                        image = this.$slots.image[0].elm;
                    }
                    if (transfer.setDragImage) {
                        transfer.setDragImage(image, this.imageXOffset, this.imageYOffset);
                    }
                }

                // Set the transfer data
                if (this.transferData !== undefined) {
                    transferDataStore.data = this.transferData;
                    // Set a dummy string for the real transfer data. Not actually used
                    // for anything, but necesssary for browser compatibility.
                    //
                    // TODO: Maybe this should be the actual data serialized. But since
                    // it's not actually used for anything it seems like a waste of CPU.
                    if (nativeEvent.dataTransfer) nativeEvent.dataTransfer.setData('text', '');
                }

                // Indicate that we're dragging.
                this.dragging = true;
            }

            // At last, emit the event.
            this.$emit(name, this.transferData, nativeEvent);

            // Clean up stored data on drag end after emitting.
            if (name === events.dragend) {
                if (transferDataStore.touch) {
                    this.$emit('touchdrop', this.transferData);
                }
                transferDataStore.touch = false;
                transferDataStore.data = undefined;
                this.isTouch = false;
                this.dragging = false;
            }
        },
    },
};
</script>
