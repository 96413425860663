import AppHttp from '@/lib/AppHttp';
import {
    ToDoActionViewModel,
    CreateDepartmentToDoActionCommand,
    UpdateToDoActionCommand,
    AddCommentToToDoActionCommand, CreateMineAreaToDoActionCommand
} from '@/models/api';
import RequestHandler from '@/lib/api/RequestHandler';

export class ToDoActions {
    private resource: string = 'ToDoActions';

    public async get(departmentId: string): Promise<ToDoActionViewModel[]> {
        const response = await AppHttp.get(`${this.resource}/Department/${departmentId}`);
        const data = response ? response.data.actions : [];
        return data;
    }

    public async getForMineArea(mineAreaId: string): Promise<ToDoActionViewModel[]> {
        const response = await AppHttp.get(`${this.resource}/MineArea/${mineAreaId}`);
        const data = response ? response.data.actions : [];
        return data;
    }

    public async create(command: CreateDepartmentToDoActionCommand): Promise<void> {
        const response = await AppHttp.post(`${this.resource}/Department/${command.departmentId}`, command);
    }

    public async createForMineArea(command: CreateMineAreaToDoActionCommand): Promise<void> {
        const response = await AppHttp.post(`${this.resource}/MineArea/${command.mineAreaId}`, command);
    }

    public async update(command: UpdateToDoActionCommand): Promise<void> {
        const response = await AppHttp.put(`${this.resource}/${command.id}`, command);
    }

    public async addComment(command: AddCommentToToDoActionCommand): Promise<void> {
        await RequestHandler.execute('POST', `${this.resource}/${command.toDoActionId}/comments`, command);
    }

    public async removeComment(toDoActionId: string, commentId: string): Promise<void> {
        const response = await AppHttp.delete(`${this.resource}/${toDoActionId}/comments/${commentId}`);
    }

    public async markCompleted(toDoActionId: string): Promise<void> {
        await RequestHandler.execute('PUT', `${this.resource}/${toDoActionId}/complete`, {
            _type: 'MarkToDoActionAsCompletedCommand',
            id: toDoActionId
        });
    }

    public async undoCompletion(toDoActionId: string): Promise<void> {
        await RequestHandler.execute('PUT', `${this.resource}/${toDoActionId}/uncomplete`, {
            _type: 'MarkToDoActionAsNotCompletedCommand',
            id: toDoActionId
        });
    }

    public async markInProgress(toDoActionId: string): Promise<void> {
        await RequestHandler.execute('PUT', `${this.resource}/${toDoActionId}/start`, {
            _type: 'MarkToDoActionAsInProgressCommand',
            id: toDoActionId
        });
    }

    public async delete(id: string): Promise<void> {
        const response = await AppHttp.delete(`${this.resource}/${id}`);
    }
}

export default new ToDoActions();
