import { GenericDataApi } from '@/lib/data/GenericDataApi';
import AppHttp from '@/lib/AppHttp';
import Errors from '@/lib/Errors';
import { AxiosError } from 'axios';

export class Users extends GenericDataApi<any> {
    constructor() {
        super('Users');
    }
    public async ping() {
        try {
            const response = await AppHttp.get(`Users/Ping`);
            return response && response.status < 400;
        } catch (e) {
            return false;
        }
    }

    public async getRoles() {
        const response = await AppHttp.get(`Users/Roles`);
        const data = response ? response.data : null;
        return data;
    }

    public async setUserRoles(id: string, roles: string[]) {
        const response = await AppHttp.post(`Users/${id}/Roles`, roles);
        const data = response ? response.data : null;
        return data;
    }

    public async setUserDepartments(id: string, departmentIds: string[]) {
        const response = await AppHttp.post(`Users/${id}/Departments`, departmentIds);
        const data = response ? response.data : null;
        return data;
    }

    public async setActive(id: string, active: boolean) {
        const response = await AppHttp.post(`Users/${id}/IsActive`, { data: active });
        const data = response ? response.data : null;
        return data;
    }

    public async delete(id: string) {
        const response = await AppHttp.delete(`Users/${id}`);
        const data = response ? response.data : null;
        return data;
    }

    public async acceptTermsAndConditions() {
        const response = await AppHttp.post(`Users/AcceptTermsAndConditions`, {});
        const data = response ? response.data : null;
        return data;
    }

    public async hasAcceptedTermsAndConditions() {
        const response = await AppHttp.get(`Users/HasAcceptedTermsAndConditions`);
        const data = response ? response.data : null;
        return data;
    }
}

export default new Users();
