import {
    COLUMN_IDS,
    ExtraColumnReasonToExist,
    SortDirection,
} from '@/components/Board/GroupingFilteringAndSorting';
import ClientRowModel from '@/models/client/client-row';
import {
    ExtendedPropertyInfo,
    PlanningPriorityType,
} from '@/models/api';
import { BoardGroupingType } from '@/components/ShiftBoard/Drawers/BoardGroupingDrawerProps';
import _, { curry } from 'lodash';
import { TimePeriod } from '@/models/client/time-block';
import * as rowHelpers from '@/lib/PlannedLocation';
import { isBoggingTask, isDrillTask, TaskStartsDuring } from '@/lib/services/Task';
import { useShiftDisplay } from '@/lib/stores/Shift/ShiftDisplayStore';
import { CollapseTimePeriods } from '@/lib/services/Location';
import {
    alphabeticPropertyAndLocationNameSortingFunc,
    createAggregateRowForExtendedPropertyFromClientRows, createAggregateRowFromClientRows,
    extendedPropertyGroupingForClientRowsFunc, generateExtendedPropertySortingFunc,
    levelGroupingFromClientRowsFunc, levelSortingFunc
} from '@/lib/stores/BoardManipulation/shift-shared';
import { defineManipulationStore } from '@/lib/stores/BoardManipulation/shared';

export type HeadingClientRowModel = ClientRowModel & {
    occupiedTimes: TimePeriod[],
    representsRealLocation: boolean,
}

export interface GroupedClientRow {
    headingRow: HeadingClientRowModel,
    additionalRows: ClientRowModel[]
}

export function stopeGroupingFunc(headings: ClientRowModel[]): GroupedClientRow[] {
    const headerRows = headings.filter(h=>h.stopeInfo == null || h.stopeInfo.stopeId === h.location?.id);

    const rowsGroupedByStope = _.groupBy(headings.filter(h=>h.stopeInfo != null && h.stopeInfo.stopeId !== h.location?.id), h=>h.stopeInfo!.stopeId);

    return headerRows.map(hr=>
    {
        const childRows = rowsGroupedByStope[hr.location!.id] ?? [];

        const occupiedTimes = CollapseTimePeriods(_.flatMap(childRows, cr=>cr.tasks.filter(t=>!t.isDeleted && !t.taskType.isDelay)));

        return {
            headingRow: {
                ...hr,
                representsRealLocation: true,
                occupiedTimes: occupiedTimes
            },
            additionalRows: rowsGroupedByStope[hr.location!.id] ?? []
        }
    });
}

export function levelGroupingFunc(headings: ClientRowModel[]): GroupedClientRow[] {
    return levelGroupingFromClientRowsFunc(headings, createAggregateRow);
}

export function extendedPropertyGroupingFunc(extendPropertyId: string, headings: ClientRowModel[]): GroupedClientRow[] {
    return extendedPropertyGroupingForClientRowsFunc(extendPropertyId, headings, createAggregateRowForExtendedProperty);
}

function createAggregateRowForExtendedProperty(groupedRows: ClientRowModel[], extendedProperty: ExtendedPropertyInfo): HeadingClientRowModel {
    return createAggregateRowForExtendedPropertyFromClientRows(groupedRows, extendedProperty, row=>({
        ...row
    }));
}

function createAggregateRow(groupedRows: ClientRowModel[]): HeadingClientRowModel {
    return createAggregateRowFromClientRows(groupedRows, row=>({
        ...row
    }));
}

export function alphabeticAreaAndLocationNameSortingFunc(rows: GroupedClientRow[], direction: SortDirection): GroupedClientRow[] {
    return alphabeticPropertyAndLocationNameSortingFunc(rows, direction, rowHelpers.sortLocations);
}

export function prioritySortingFunc(rows: GroupedClientRow[], direction: SortDirection): GroupedClientRow[] {
    const sortLocations = (a: ClientRowModel,b: ClientRowModel, direction: SortDirection)=>direction === SortDirection.Descending ? b.priority - a.priority : a.priority - b.priority;

    const transformingCurriedSortLocations = (a: GroupedClientRow, b: GroupedClientRow)=>sortLocations(a.headingRow, b.headingRow, direction);

    return rows
        .map(x=>({
            headingRow: x.headingRow,
            additionalRows: [...x.additionalRows].sort((a, b) => sortLocations(a, b, direction))
        }))
        .sort(transformingCurriedSortLocations);
}

function boggingSortingFunc(rows: GroupedClientRow[], direction: SortDirection): GroupedClientRow[] {
    const shiftDisplayStartTime = useShiftDisplay().shiftDisplayStartTime;
    const shiftDisplayEndTime = useShiftDisplay().shiftDisplayEndTime;

    const curriedSortLocations = curry(rowHelpers.sortLocations)((a: ClientRowModel, b: ClientRowModel)=>{
        const aPriority = a.planningPriorities?.find(pp=>pp.priorityType === PlanningPriorityType.BOGGING)?.priority ?? 100;
        const bPriority = b.planningPriorities?.find(pp=>pp.priorityType === PlanningPriorityType.BOGGING)?.priority ?? 100;

        return direction == SortDirection.Ascending ? aPriority - bPriority : bPriority - aPriority;
    });

    const transformingCurriedSortLocations = (a: GroupedClientRow, b: GroupedClientRow)=>curriedSortLocations(a.headingRow, b.headingRow, false);

    const hasBoggingRows = rows.filter((x)=>x.headingRow.tasks.some(t=>isBoggingTask(t) && TaskStartsDuring(t, shiftDisplayStartTime, shiftDisplayEndTime))).sort(transformingCurriedSortLocations);
    const hasNoBoggingRows = rows.filter((x)=>!x.headingRow.tasks.some(t=>isBoggingTask(t) && TaskStartsDuring(t, shiftDisplayStartTime, shiftDisplayEndTime))).sort(transformingCurriedSortLocations);

    return [... hasBoggingRows, ... hasNoBoggingRows];
}

function drillingSortingFunc(rows: GroupedClientRow[], direction: SortDirection): GroupedClientRow[] {
    const shiftDisplayStartTime = useShiftDisplay().shiftDisplayStartTime;
    const shiftDisplayEndTime = useShiftDisplay().shiftDisplayEndTime;

    const curriedSortLocations = curry(rowHelpers.sortLocations)((a: ClientRowModel,b: ClientRowModel)=>a.priority - b.priority);

    const transformingCurriedSortLocations = (a: GroupedClientRow, b: GroupedClientRow)=>curriedSortLocations(a.headingRow, b.headingRow, direction === SortDirection.Descending);

    const hasDrillingRows = rows.filter((x)=>x.headingRow.tasks.some(t=>isDrillTask(t) && TaskStartsDuring(t, shiftDisplayStartTime, shiftDisplayEndTime))).sort(transformingCurriedSortLocations);
    const hasNoDrillingRows = rows.filter((x)=>!x.headingRow.tasks.some(t=>isDrillTask(t) && TaskStartsDuring(t, shiftDisplayStartTime, shiftDisplayEndTime))).sort(transformingCurriedSortLocations);

    return [... hasDrillingRows, ... hasNoDrillingRows];
}

export const useShiftBoardColumnManipulation =
    defineManipulationStore<ClientRowModel, GroupedClientRow>('shiftBoardColumnManipulation', 'SHIFT',
        extendedPropertyGroupingFunc,
        generateExtendedPropertySortingFunc,
        {
            type: BoardGroupingType.DEFAULT,
            id: COLUMN_IDS.STOPE,
            text: 'Stope',
            furtherInformation: '',
            groupingFunc: stopeGroupingFunc
        },
        {
            direction: SortDirection.Ascending,
            columnId: COLUMN_IDS.NULL,
            sortFunc: alphabeticAreaAndLocationNameSortingFunc
        },
        [
            {
                id: COLUMN_IDS.BOGGING_PRIORITY,
                name: 'Bogging Priority',
                columnNameOverride: 'BOG',
                displayValueSelector: row=>`${row.planningPriorities?.find(pp=>pp.priorityType === PlanningPriorityType.BOGGING)?.priority ?? ''}`,
                propertyFilter: null,
                columnSize: 's',
                groupingDefinition: null,
                sorting: {
                    sortFunc: boggingSortingFunc
                },
                reasonOnBoard: ExtraColumnReasonToExist.None,
            },
            {
                id: COLUMN_IDS.DRILLING_PRIORITY,
                name: 'Drilling Priority',
                columnNameOverride: 'DRILL',
                displayValueSelector: null,
                columnSize: 's',
                propertyFilter: null,
                groupingDefinition: null,
                sorting: {
                    sortFunc: drillingSortingFunc
                },
                reasonOnBoard: ExtraColumnReasonToExist.None,
            },
            {
                id: COLUMN_IDS.LEVEL,
                name: 'Level',
                columnNameOverride: 'AREA',
                columnSize: 'l',
                displayValueSelector: row=>row.location?.reducedLevelName ?? '',
                groupingDefinition: {
                    text: 'Area',
                    id: COLUMN_IDS.LEVEL,
                    type: BoardGroupingType.LEVEL,
                    furtherInformation: '',
                    groupingFunc: levelGroupingFunc
                },
                propertyFilter: null,
                sorting: {
                    sortFunc: levelSortingFunc
                },
                reasonOnBoard: ExtraColumnReasonToExist.Default,
            },
            {
                id: COLUMN_IDS.PRIORITY,
                name: 'Priority',
                columnNameOverride: 'PRIO',
                columnSize: 'l',
                displayValueSelector: row=>`${row.priority}`,
                groupingDefinition: null,
                propertyFilter: null,
                sorting: {
                    sortFunc: prioritySortingFunc
                },
                reasonOnBoard: ExtraColumnReasonToExist.Default,
            },
        ])();