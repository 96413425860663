<template>
    <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
        <v-card>
            <v-toolbar dark :color="options.color" dense flat>
                <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text v-for="(message,i) in messages" :key="i" class="pa-4">{{ message }}</v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn :color="options.rejectColour" text @click.native="cancel">{{ rejectText }}</v-btn>
                <v-btn :color="options.resolveColour" text @click.native="agree">{{ resolveText }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */

const defaultOptions = {
    color: 'primary',
    width: 290,
    zIndex: 200,
    rejectText: 'Cancel',
    resolveText: 'Yes',
    rejectColour: 'grey',
    resolveColour: 'primary darken-1'
};

export default {
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        messages: null,
        title: null,
        options: { ...defaultOptions }
    }),
    computed: {
        resolveText() {
            return this.options.resolveText;
        },
        rejectText() {
            return this.options.rejectText;
        }
    },
    methods: {
        open(title, message, options) {
            return this.multilineOpen(title, [message], options);
        },
        multilineOpen(title, messages, options) {
            this.dialog = true;
            this.title = title;
            this.messages = messages;
            this.options = { ...defaultOptions };
            this.options = Object.assign(this.options, options);
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        openAsDeleteResource(resourceType, options) {
            return this.openAsDelete(`Delete ${resourceType}`, `Are you sure you want to delete this ${resourceType}?`, options);
        },
        openAsDelete(title, message, options) {
            return this.multilineOpen(title, [message], {
                color: 'red',
                resolveText: 'Delete',
                resolveColour: 'red darken-1',
                ...options
            })
        },
        agree() {
            this.resolve(true);
            this.dialog = false;
        },
        cancel() {
            this.resolve(false);
            this.dialog = false;
        },
    },
};
</script>
