import { GenericDataApi } from '@/lib/data/GenericDataApi';
import AppHttp from '@/lib/AppHttp';
import Errors from '@/lib/Errors';
import { UpdateLocationDetailsCommand } from '@/models/api';
import EditMappings from '../OfflineEdits/EditMappings';
import RequestHandler from '../api/RequestHandler';

export class LocationDetails extends GenericDataApi<any> {
    constructor() {
        super('LocationDetails');
    }

    async send(model: UpdateLocationDetailsCommand) {
        const response: any = await RequestHandler.execute(
            'POST',
            `${this.resource}/${model?.locationId}`,
            this.cleanOutgoingData({ ...model })
        );
        const data = response ? response.data : null;
        return data;
    }
}

export default new LocationDetails();
