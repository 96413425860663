<template>
    <div class="pa-5">
        <v-card v-if="data">
            <v-card-title>
                <h1 class="title">{{ 'Staff' | term }}</h1>
                <v-btn small color="secondary" class="ml-3" @click="create()">
                    <v-icon left>mdi-plus</v-icon>Add
                </v-btn>
                <v-menu
                    v-model="importDialog"
                    max-width="310px"
                    :close-on-content-click="false"
                    v-if="!!data && !data.length"
                >
                    <template v-slot:activator="{ on }">
                        <v-alert
                            class="cannot-import"
                            dense
                            outlined
                            border="left"
                            type="warning"
                            v-if="staffRoles.length === 0"
                        >
                            Cannot import: No staff roles available.
                        </v-alert>
                        <v-btn v-on="on" small class="ml-1" color="secondary" :disabled="staffRoles.length === 0">
                            <v-icon left>mdi-upload</v-icon>Import
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-text>
                            <input ref="inputUpload" type="file" accept="text/csv" @change="getFile($event)" />
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="secondary" :disabled="file === null" @click="importData">Upload</v-btn>
                            <v-btn text @click="importDialog = false">cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
                <v-btn small class="ml-1" color="gray" v-if="!!data && data.length" @click="exportData()">
                    <v-icon left>mdi-download</v-icon>Export
                </v-btn>
                <v-spacer></v-spacer>
                <admin-sandpit-visibility-selector></admin-sandpit-visibility-selector>
            </v-card-title>
            <v-spacer></v-spacer>
            <h2>Active {{ 'Staff' | term }}</h2>
            <v-data-table disable-pagination hide-default-footer :headers="headers" :items="activeStaff">
                <template v-slot:item.id="{ item }">
                    <v-btn text icon @click="edit(item)" class="float-right">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <template v-slot:item.name="{ item }">
                    <admin-sandpit-item-name :is-sandpit="item.isSandpitUseOnly">{{ item.name }}</admin-sandpit-item-name>
                </template>
            </v-data-table>
            <h2>Archived {{ 'Staff' | term }}</h2>
            <v-data-table disable-pagination hide-default-footer :headers="headers" :items="archivedStaff">
                <template v-slot:item.id="{ item }">
                    <v-btn text icon @click="edit(item)" class="float-right">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <template v-slot:item.name="{ item }">
                    <admin-sandpit-item-name :is-sandpit="item.isSandpitUseOnly">{{ item.name }}</admin-sandpit-item-name>
                </template>
            </v-data-table>
        </v-card>
        <standard-dialog :render="!!selected" :value='showModal' :max-width="900" persistent>
            <template v-slot:title>
                <span v-if="selected.id">Edit</span>
                <span v-else>Create</span>
                <span class="ml-1">{{ 'Staff' | term }}</span>
            </template>
            <template v-slot:content>
                <v-form ref="form" :lazy-validation="true">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="6" class="pa-2">
                                <v-text-field
                                    autocomplete="off"
                                    label="Name"
                                    :rules="[$rules.required(), $rules.maxLength(50)]"
                                    v-model="selected.name"
                                    counter="50"
                                    :disabled="selected.isArchived"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="pa-2">
                                <v-select
                                    v-model="selected.staffRoleId"
                                    :items="getAvailableRolesForStaffMember(selected)"
                                    :rules="[$rules.required()]"
                                    label="Role"
                                    item-value="id"
                                    item-text="name"
                                    :disabled="selected.isArchived"
                                ></v-select>
                            </v-col>
                            <v-col cols="6" class="pa-2">
                                <v-select
                                    v-model="selected.crewId"
                                    :items="getAvailableCrewsForStaffMember(selected)"
                                    clearable
                                    label="Crew"
                                    item-value="id"
                                    item-text="name"
                                    :disabled="selected.isArchived"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </template>
            <template v-slot:actions>
                <v-btn color="red" text v-if="selected && selected.id && !selected.isArchived" @click="archive()">Archive</v-btn>
                <v-btn color="red" text v-if="selected && selected.id && selected.isArchived" @click="restore()">Restore</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="gray" text @click="showModal = false">Cancel</v-btn>
                <v-btn color="primary" v-if="selected && !selected.isArchived" text @click="save()">Save</v-btn>
            </template>
            <template v-slot:offline-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray" text @click="showModal = false">Cancel</v-btn>
                <offline-btn text></offline-btn>
            </template>
        </standard-dialog>
        <confirm ref="confirm"></confirm>
    </div>
</template>
<script>
import { EventBus, Events } from '@/lib/EventBus';
import Crews from '@/lib/data/Crews';
import Staff from '@/lib/data/Staff';
import StaffRoles from '@/lib/data/StaffRoles';
import DownloadHelper from '@/lib/DownloadHelper';
import AdminSandpitVisibilitySelector from '@/components/Admin/Sandpit/AdminSandpitVisibilitySelector.vue';
import { AdminSandpitVisibility, useSandpitStore } from '@/lib/stores/SandpitStore';
import AdminSandpitItemName from '@/components/Admin/Sandpit/AdminSandpitItemName.vue';
import { mapState } from 'pinia';

export default {
    components: { AdminSandpitVisibilitySelector, AdminSandpitItemName },
    data() {
        return {
            data: null,
            showModal: false,
            selected: null,
            importDialog: false,
            file: null,
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Role', value: 'staffRole.name' },
                { text: 'Crew', value: 'crew.name' },
                { text: '', value: 'id' }
            ],
            staffRoles: [],
            crews: []
        };
    },
    async created() {
        await this.loadData();
    },
    destroyed() {},
    computed: {
        ...mapState(useSandpitStore, ['adminSandpitVisibility']),
        activeStaff() {
            return this.data?.filter(this.sandpitFilter)?.filter(x => !x.isArchived);
        },
        archivedStaff() {
            return this.data?.filter(this.sandpitFilter)?.filter(x => x.isArchived);
        },
        sandpitFilter() {
            if(this.adminSandpitVisibility === AdminSandpitVisibility.BOTH)
                return x=>true;
            else if(this.adminSandpitVisibility === AdminSandpitVisibility.ONLY_SANDPIT)
                return x=>x.isSandpitUseOnly;
            else
                return x=>!x.isSandpitUseOnly;
        },
    },
    methods: {
        async loadData() {
            this.staffRoles = await StaffRoles.get();
            this.crews = await Crews.get();
            this.data = await Staff.get(['StaffRole', 'Crew']);
        },
        create() {
            this.edit({
                name: '',
                isSandpitUseOnly: false
            });
        },
        edit(item) {
            this.selected = { ...item };
            this.showModal = true;
            setTimeout(() => this.$refs.form.resetValidation(), 1);
        },
        async archive() {
            if (await this.$refs.confirm.multilineOpen('Archive', ['Archiving a staff member removes them from their crew but will not affect historical assignments.', 'Archive staff member?'], { color: 'red' })) {
                await Staff.archive(this.selected.id);
                EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Staff')} Archived`);
                this.showModal = false;
                await this.loadData();
                this.emitStaffUpdate();
            }
        },
        async restore() {
            if (await this.$refs.confirm.multilineOpen('Restore', ['Restoring a staff member will not add them back to their crew.', 'Restore archived staff member?'], { color: 'red' })) {
                await Staff.restoreFromArchive(this.selected.id);
                EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Staff')} Restored`);
                this.showModal = false;
                await this.loadData();
                this.emitStaffUpdate();
            }
        },
        async save() {
            if (!this.$refs.form.validate()) {
                return;
            }

            if(this.selected.id){
                await Staff.update({
                    ... this.selected,
                    _type: "UpdateStaffCommand"
                });
            } else {
                await Staff.create({
                    ... this.selected,
                    _type: "CreateStaffCommand"
                });
            }

            EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Staff')} Saved`);
            this.showModal = false;
            await this.loadData();
            this.emitStaffUpdate();
        },
        async exportData() {
            const result = await Staff.export();
            var csv = result.csvFile;
            var blob = DownloadHelper.makeBlobFromFileString(csv);
            DownloadHelper.download('Staff.csv', blob);
        },
        async importData() {
            try {
                this.$wait.start('saving');
                this.uploadResult = null;
                await Staff.import(this.file);
                this.importDialog = false;
                await this.loadData();
                this.emitStaffUpdate();
            } finally {
                this.$wait.end('saving');
            }
        },
        async getFile(e) {
            this.file = e.target.files[0];
        },
        emitStaffUpdate() {
            EventBus.$emit(Events.StaffUpdated);
        },
        getAvailableRolesForStaffMember(staffMember) {
            return this.staffRoles.filter(sr => sr.isSandpitUseOnly === staffMember.isSandpitUseOnly);
        },
        getAvailableCrewsForStaffMember(staffMember) {
            return this.crews.filter(c => c.isSandpitUseOnly === staffMember.isSandpitUseOnly);
        }
    }
};
</script>
<style lang="scss" scoped></style>
