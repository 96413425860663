import AppHttp, { OfflineReplayApiClient } from '@/lib/AppHttp';
import Errors from '@/lib/Errors';
import EditMappings from '@/lib/OfflineEdits/EditMappings';
import {
    RecordShiftSnapshotCommand,
    SetTaskEquipmentCommand,
    UpdateTaskTimesCommand,
    PublishShiftSnapshotCommand,
    ShiftSupervisorQueryResponse,
    SupervisorReportViewModel,
    ShiftIds,
    ShiftBoardResponse,
    TaskCommandResponse,
    DepartmentShiftSnapshotOverviewViewModel,
    FixRateTaskCommand,
    SetTasksEquipmentCommand,
    GetBlastPacketTasksInWeekQueryResponse,
    GetShiftUtilisationScoresQueryResponse,
    GetShiftCrewAndEquipmentUtilisationScoresQueryResponse,
    CreateShiftSnapshotCommand,
    WeekViewModel,
    DeleteLocationsFromPlanCommand,
    UpdateWeeklyCommunicationsCommand,
    UpdateShiftCommunicationsCommand,
    ShiftViewModel,
    UpdateTaskAndEquipmentCommunicationsCommand,
    GetEquipmentUsageInOtherDepartmentsQueryResponse,
    UpdateShiftSafetyAndIncidentsCommand, UpdateShiftVarianceAndCommentaryCommand,
    UpdateShiftManningAndEquipmentCommand
} from '@/models/api';
import { SplitTaskCommand } from '@/models/api/Commands/split-task-command';
import { DeleteTaskCommand } from '@/models/api/Commands/delete-task-command';
import { AddTaskBeforeOrAfterCommand } from '@/models/api/Commands/add-task-before-or-after-command';
import { AddStaffToEquipmentCommand } from '@/models/api';
import { RemoveStaffFromEquipmentCommand } from '@/models/api';
import { AxiosError } from 'axios';
import RequestHandler from '@/lib/api/RequestHandler';
import { LockTag } from '@/models/client/client-row';
import dayjs from 'dayjs';

export class Shift {
    public async shiftId(mineAreaId: string, departmentId: string, date: string, number: number): Promise<ShiftIds> {
        const params = {
            mineAreaId,
            departmentId,
            date,
            number
        } as any;
        const response = await AppHttp.get(`Shift/Id?${new URLSearchParams(params).toString()}`);
        const data = response ? response.data : null;
        return await EditMappings.applyPendingEdits('ShiftIds', data);
    }

    public async supervisor(
        mineAreaId: string,
        departmentId: string,
        date: string,
        number: number,
        create: boolean
    ): Promise<ShiftSupervisorQueryResponse> {
        const params = {
            mineAreaId,
            departmentId,
            date,
            number
        } as any;
        const endpoint = `Shift/Supervisor?${new URLSearchParams(params).toString()}`;

        const response = create ? await AppHttp.post(endpoint, null) : await AppHttp.get(endpoint);

        const data = response ? response.data : null;
        return await EditMappings.applyPendingEdits('ShiftSupervisorQueryResponse', data);
    }

    async getEquipmentUsageForShiftWindow(departmentId: string,
                                 date: string,
                                 number: string,
                                 offsetPrior: number,
                                 offsetFollowing: number): Promise<GetEquipmentUsageInOtherDepartmentsQueryResponse> {
        const response = await AppHttp.get(`Shift/Equipment/UsageForShiftWindow?departmentId=${departmentId}&date=${date}&number=${number}&offsetPrior=${offsetPrior}&offsetFollowing=${offsetFollowing}`);

        const data = response ? response.data : null;
        if(data == null) throw Error('No response data');
        return data;
    }

    public async shift(
        mineAreaId: string,
        departmentId: string,
        date: string,
        number: number,
        create: boolean
    ): Promise<ShiftBoardResponse> {
        const params = {
            mineAreaId,
            departmentId,
            date,
            number
        } as any;
        const endpoint = `Shift/Board?${new URLSearchParams(params).toString()}`;

        const response = create ? await AppHttp.post(endpoint, null) : await AppHttp.get(endpoint);

        return response ? response.data : null;
    }

    public async utilisationScores(shiftId: string): Promise<GetShiftUtilisationScoresQueryResponse> {
        return await RequestHandler.fetch<GetShiftUtilisationScoresQueryResponse>(
            `Shift/${shiftId}/UtilisationScores`,
            { priority1LocationsScore: null, priority2LocationsScore: null, lowerPriorityLocationsScore: null, _type: 'GetShiftUtilisationScoresQueryResponse' }
        );
    }

    public async crewAndEquipmentUtilisationScores(shiftId: string): Promise<GetShiftCrewAndEquipmentUtilisationScoresQueryResponse> {
        return await RequestHandler.fetch<GetShiftCrewAndEquipmentUtilisationScoresQueryResponse>(
            `Shift/${shiftId}/UtilisationScores/CrewAndEquipment`,
            { crewScore: null, individualEquipmentScores: {}, individualStaffScores: {}, primaryEquipmentScore: null, secondaryEquipmentScore: null, supportEquipmentScore: null, _type: 'GetShiftCrewAndEquipmentUtilisationScoresQueryResponse' }
        );
    }

    public async plans(departmentId: string) {
        const response = await AppHttp.get(`Week/Departments/${departmentId}/PlannedDates`);
        const data = response ? response.data : null;
        return data;
    }

    public async lastShift(mineAreaId: string, departmentId: string, date: string): Promise<ShiftViewModel> {
        const params = {
            mineAreaId,
            departmentId,
            date
        } as any;
        const response = await AppHttp.get(`Shift/Last?${new URLSearchParams(params).toString()}`);
        const data = response ? response.data : null;
        return data;
    }

    public async updateWeeklyCommunications(command: UpdateWeeklyCommunicationsCommand) {
        const response: any = await RequestHandler.execute('POST', `Shift/Communications/Weekly`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async updateShiftCommunications(command: UpdateShiftCommunicationsCommand) {
        const response: any = await RequestHandler.execute('POST', `Shift/Communications/Shift`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async updateTaskAndEquipmentCommunications(command: UpdateTaskAndEquipmentCommunicationsCommand) {
        const response: any = await RequestHandler.execute('POST', `Shift/Communications/TaskAndEquipment`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async week(mineAreaId: string, departmentId: string, date: string, create: boolean): Promise<WeekViewModel> {
        const params = {
            mineAreaId,
            departmentId,
            date,
            create: create ? 'true' : 'false'
        } as any;
        const response = await AppHttp.get(`Week/Board?${new URLSearchParams(params).toString()}`);
        const data = response ? response.data : null;
        return data;
    }

    public async weekBlastPacketTasks(departmentId: string, planId: string): Promise<GetBlastPacketTasksInWeekQueryResponse> {
        const response = await AppHttp.get(`Week/BlastPacketTasks?departmentId=${departmentId}&planId=${planId}`);

        const data = response ? response.data : null;
        if(data == null) throw Error('No response data');
        return data;
    }

    public async updateCycleTime(plannedLocationId: string, plannedCycleId: string, newStartTime: Date, tags: LockTag) {
        const response = await AppHttp.post(`Week/Locations/${plannedLocationId}/Cycles/${plannedCycleId}?currentPlanningTag=${tags.current}`, {
            data: newStartTime
        });
        const data = response ? response.data : null;
        return data;
    }

    public async updateEquipment(command: SetTaskEquipmentCommand) {
        const response: any = await RequestHandler.execute('POST', `Shift/Tasks/Equipment`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async assignEquipmentToTasks(command: SetTasksEquipmentCommand) {
        const response: any = await RequestHandler.execute('POST', `Shift/Tasks/Equipment/Multiple`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async addStaff(command: AddStaffToEquipmentCommand) {
        const response: any = await RequestHandler.execute('POST', `Shift/EquipmentStaff`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async removeStaff(command: RemoveStaffFromEquipmentCommand) {
        const response: any = await RequestHandler.execute('DELETE', `Shift/EquipmentStaff`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async addLocationsToWeek(planId: string, locationId: string[]) {
        const response = await AppHttp.post(`Week/${planId}/addLocations`, { data: locationId });
        const data = response ? response.data : null;
        return data;
    }

    public async deleteLocation(planId: string, locationId: string, tags: LockTag) {
        const response = await AppHttp.delete(`Week/${planId}/Locations/${locationId}?currentPlanningTag=${tags.current}`);
        const data = response ? response.data : null;
        return data;
    }

    public async deleteLocations(command: DeleteLocationsFromPlanCommand) {
        const response = await AppHttp.delete(`Week/${command.weekPlanId}/Locations`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async deleteCycle(planId: string, plannedLocationId: string, plannedCycleId: string, tags: LockTag) {
        const response = await AppHttp.delete(
            `Week/${planId}/Locations/${plannedLocationId}/Cycles/${plannedCycleId}?currentPlanningTag=${tags.current}`
        );
        const data = response ? response.data : null;
        return data;
    }

    public async deleteAdHocAsCycle(planId: string, plannedLocationId: string, plannedTaskId: string, tags: LockTag) {
        const response = await AppHttp.delete(
            `Week/${planId}/Locations/${plannedLocationId}/AdHoc/${plannedTaskId}?currentPlanningTag=${tags.current}`
        );
        const data = response ? response.data : null;
        return data;
    }

    public async addCyclesToLocation(planId: string, locationId: string, model: any, tags: LockTag) {
        const response = await AppHttp.post(`Week/${planId}/${locationId}/setCycles?currentPlanningTag=${tags.current}`, model);
        const data = response ? response.data : null;
        return data;
    }

    public async updateLocationPriorityInWeek(planId: string, locationId: string, priority: number) {
        const response = await AppHttp.post(`Week/${planId}/${locationId}/updatePriority`, {
            data: priority
        });
        const data = response ? response.data : null;
        return data;
    }

    public async addCycleToEmptyLocation(planId: string, locationId: string, cycleId: string, timePoint: dayjs.Dayjs, tags: LockTag) {
        const response = await AppHttp.post(`Week/${planId}/${locationId}/addCycleToEmpty/${cycleId}?timePoint=${timePoint}&currentPlanningTag=${tags.current}`, null);
        const data = response ? response.data : null;
        return data;
    }

    public async getWeekSnapshots(planId: string) {
        const response = await AppHttp.get(`Week/Board/${planId}/Snapshots`);
        const data = response ? response.data : null;
        return data;
    }

    public async getWeekSnapshot(planId: string, id: string) {
        const response = await AppHttp.get(`Week/Board/${planId}/Snapshots/${id}`);
        const data = response ? response.data : null;
        return data;
    }

    public async makeWeekSnapshot(mineAreaId: string, departmentId: string, date: string, name: string) {
        const params = {
            mineAreaId,
            departmentId,
            date
        } as any;
        const response = await AppHttp.post(`Week/Board/Snapshots?${new URLSearchParams(params).toString()}`, {
            data: name
        });
        return response && response.status >= 200 && response.status < 400;
    }
    public async getShiftSnapshots(planId: string): Promise<DepartmentShiftSnapshotOverviewViewModel[]> {
        return await RequestHandler.fetch<DepartmentShiftSnapshotOverviewViewModel[]>(
            `Shift/Board/${planId}/Snapshots`,
            []
        );
    }

    public async getShiftSnapshot(planId: string, id: string): Promise<DepartmentShiftSnapshotOverviewViewModel> {
        const response = await AppHttp.get(`Shift/Board/${planId}/Snapshots/${id}`);
        const data = response ? response.data : null;
        return data;
    }

    public async getPreviousShiftId(planId: string) {
        const response = await AppHttp.get(`Shift/Board/${planId}/PreviousShiftId`);
        const data = response ? response.data : null;
        return data;
    }

    public async createShiftSnapshot(command: CreateShiftSnapshotCommand) {
        const response = await AppHttp.post('Shift/Board/Snapshots', command);
        return response && response.status >= 200 && response.status < 400;
    }

    public async recordShiftSnapshot(command: RecordShiftSnapshotCommand) {
        const response = await AppHttp.post(`Shift/Board/Snapshots/Record`, command);
        return response && response.status >= 200 && response.status < 400;
    }

    public async recordOfflineShiftSnapshot(command: RecordShiftSnapshotCommand) {
        const response = await OfflineReplayApiClient.post(`Shift/Board/Offline/Snapshots/Record`, command);
        return response && response.status >= 200 && response.status < 400;
    }

    public async publishShiftSnapshot(command: PublishShiftSnapshotCommand) {
        const response = await AppHttp.post(`Shift/Board/PublishShift`, command);
        return response && response.status >= 200 && response.status < 400;
    }

    public async getSupervisor(id: string): Promise<SupervisorReportViewModel> {
        const response = await AppHttp.get(`Shift/${id}/SupervisorReport`);
        const data = response ? response.data : null;
        return await EditMappings.applyPendingEdits('SupervisorReportViewModel', data);
    }
    public async updateShiftManningAndEquipment(shiftId: string, data: UpdateShiftManningAndEquipmentCommand) {
        const response: any = await RequestHandler.execute('POST', `Shift/${shiftId}/ShiftManningAndEquipment`, data);
        return response && response.status >= 200 && response.status < 400;
    }
    public async updateShiftSafetyAndIncidents(shiftId: string, data: UpdateShiftSafetyAndIncidentsCommand) {
        const response: any = await RequestHandler.execute('POST', `Shift/${shiftId}/ShiftSafety`, data);
        return response && response.status >= 200 && response.status < 400;
    }
    public async updateShiftVarianceAndCommentary(shiftId: string, data: UpdateShiftVarianceAndCommentaryCommand) {
        const response: any = await RequestHandler.execute('POST', `Shift/${shiftId}/ShiftCommentary`, data);
        return response && response.status >= 200 && response.status < 400;
    }

    public async csv(id: string, snapshotId: string) {
        const response = await AppHttp.get(`Shift/${id}/snapshot/${snapshotId}/Csv`);
        const data = response ? response.data : null;
        return data;
    }

    public async exportWeek(planId: string, includeEquipment: boolean, collapseCycles: boolean, includeAdHoc: boolean) {
        const params = {
            includeEquipment,
            collapseCycles,
            includeAdHoc
        } as any;
        const response = await AppHttp.get(`Week/csv/${planId}?${new URLSearchParams(params).toString()}`);
        const data = response ? response.data : null;
        return data;
    }
}
export default new Shift();
