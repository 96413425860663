<template>
    <div class="pa-5">
        <v-card v-if="data">
            <v-card-title>
                <h1 class="title">{{ 'Locations' | term }}</h1>
                <v-btn small color="secondary" class="ml-3" v-if="selectedMineArea" @click="create()">
                    <v-icon left>mdi-plus</v-icon>Add
                </v-btn>
                <split-button class="ml-3" v-if="selectedMineArea" color="secondary" @click="create">
                    <template #items>
                        <v-list-item @click="createDevelopmentLocation">
                            <v-list-item-title>Development</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="createAdHocLocation">
                            <v-list-item-title>Ad-Hoc</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="createStope">
                            <v-list-item-title>Stope</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="createSLC">
                            <v-list-item-title>SLC</v-list-item-title>
                        </v-list-item>
                    </template>
                    <v-icon left>mdi-plus</v-icon>Add
                </split-button>
                <v-menu
                    v-model="importDialog"
                    max-width="310px"
                    :close-on-content-click="false"
                    v-if="!!selectedMineArea"
                >
                    <template v-slot:activator="{ on }">
                        <v-alert
                            class="cannot-import"
                            dense
                            outlined
                            border="left"
                            type="warning"
                            v-if="filteredReducedLevels.length === 0"
                        >
                            Cannot import: No reduced levels available for this mine area.
                        </v-alert>
                        <v-btn
                            v-on="on"
                            small
                            class="ml-1"
                            color="secondary"
                            :disabled="filteredReducedLevels.length === 0"
                        >
                            <v-icon left>mdi-upload</v-icon>Import
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-text>
                            <input ref="inputUpload" type="file" accept="text/csv" @change="getFile($event)" />
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="secondary" :disabled="file === null" @click="importData">Upload</v-btn>
                            <v-btn text @click="importDialog = false">cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
                <v-btn
                    small
                    class="ml-1"
                    color="gray"
                    v-if="!!selectedMineArea && filtered.length"
                    @click="exportData()"
                >
                    <v-icon left>mdi-download</v-icon>Export
                </v-btn>
                <v-btn small color="primary" class="ml-3" v-if="selectedMineArea && dirty" @click="saveList()">
                    <v-icon left>mdi-check</v-icon>Save
                </v-btn>
                <v-btn small color="grey" class="ml-3" v-if="selectedMineArea && dirty" @click="loadData()">
                    <v-icon left>mdi-close</v-icon>Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-select
                    v-if="departments"
                    :items="departments"
                    item-value="id"
                    item-text="name"
                    v-model="selectedDepartment"
                    label="Department"
                    single-line
                    hide-details
                    class="admin-table-filter"
                    @change="selectMineArea()"
                    :menu-props="{ dark: true }"
                >
                    <template v-slot:item="data">
                        <template v-if="data.item.isGroup">
                            <v-list-item-content
                                class="caption"
                                @click="$event.stopPropagation()"
                                v-text="data.item.name"
                            ></v-list-item-content>
                        </template>
                        <template v-else>
                            <v-list-item-content class="pl-5" v-text="data.item.name"></v-list-item-content>
                        </template>
                    </template>
                </v-select>
            </v-card-title>
            <v-data-table
                disable-pagination
                hide-default-footer
                :headers="headers"
                :items="filtered"
                v-if="!!selectedDepartment"
            >
                <template v-slot:item="{ item }">
                    <tr :class="{ dirty: item.dirty }">
                        <td>{{ item.name }}</td>
                        <td>{{ item.reducedLevel.name }}</td>
                        <td>{{ item.cycle ? item.cycle.name : '' }}</td>
                        <td>{{ item.cycleType | cycleType }}</td>
                        <td>
                            <div class="no-wrap">
                                {{ item.displayOrder }}
                                <v-btn text icon @click="up(item)">
                                    <v-icon>mdi-arrow-up</v-icon>
                                </v-btn>
                                <v-btn text icon @click="down(item)">
                                    <v-icon>mdi-arrow-down</v-icon>
                                </v-btn>
                                <v-btn text icon @click="edit(item)">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>
            <empty-state
                v-if="!selectedDepartment"
                icon="mdi-alert-circle-outline"
                :message="'Select a ' + $termSync('Department')"
            ></empty-state>
        </v-card>
        <location-creation-wizard
            :location-name-convention='locationNameConvention'
            :available-default-cycles='filteredDefaultCycles'
            :available-reduced-levels='filteredReducedLevels'
            :initial-information='newLocation'
            v-if='newLocation'
            @save="saveNewLocation"
            @cancel="cancelNewLocation"
        >

        </location-creation-wizard>
        <standard-dialog :render="!!selected" :value='showModal' :max-width="900" persistent>
            <template v-slot:title>
                <span v-if="selected.id">Edit</span>
                <span v-else>Create</span>
                <span class="ml-1">{{ 'Location' | term }}</span>
            </template>
            <template v-slot:content>
                <v-form ref="form" :lazy-validation="true">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="5" class="pa-2">
                                <v-text-field
                                    autocomplete="off"
                                    label="Name"
                                    :rules="[$rules.required(), $rules.maxLength(50), validateLocation]"
                                    v-model="selected.name"
                                    counter="50"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="5" class="pa-2">
                                <v-select
                                    autocomplete="off"
                                    label="Level"
                                    :rules="[$rules.required()]"
                                    v-model="selected.reducedLevelId"
                                    :items="filteredReducedLevels"
                                    item-value="id"
                                    item-text="name"
                                ></v-select>
                            </v-col>
                            <v-col cols="2" class="pa-2">
                                <v-text-field
                                    autocomplete="off"
                                    typoe="number"
                                    label="Priority"
                                    :rules="[$rules.required(), $rules.min(1)]"
                                    v-model="selected.displayOrder"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="pa-2">
                                <v-checkbox
                                    autocomplete="off"
                                    label="Ignores start-of-shift tasks?"
                                    v-model="selected.ignoreStartOfShiftSpecial"
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="6" class="pa-2">
                                <v-checkbox
                                    autocomplete="off"
                                    label="Independent end-of-shift?"
                                    v-model="selected.ignoreEndOfShiftSpecial"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-select
                                    autocomplete="off"
                                    label="Status"
                                    v-model="selected.status"
                                    :items="filteredStatuses"
                                    item-value="id"
                                    :readonly="selected.id !== undefined || filteredStatuses.length == 1"
                                    item-text="name"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-select
                                    autocomplete="off"
                                    label="Cycle Type"
                                    v-model="selected.cycleType"
                                    :items="cycleTypes"
                                    item-value="id"
                                    :readonly="selected.id !== undefined || cycleTypes.length == 1"
                                    item-text="name"
                                    @change="onChangeCycleType"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row v-if="showMiningMethod">
                            <v-col cols="6">
                                <v-select
                                    autocomplete="off"
                                    label="Mining Method"
                                    v-model="selected.miningMethod"
                                    :items="filteredMiningMethods"
                                    item-value="id"
                                    :readonly="selected.id !== undefined || filteredMiningMethods.length == 1"
                                    :rules="[$rules.requiredAllowZero()]"
                                    item-text="name"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-select
                                    autocomplete="off"
                                    label="Default Cycle"
                                    v-model="selected.cycleId"
                                    :items="cyclesFor(selected.departmentId)"
                                    item-value="id"
                                    item-text="name"
                                    v-if="isDuration"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </template>
            <template v-slot:actions>
                <v-btn color='red' text v-if='selected && selected.id' @click='del()'>Delete</v-btn>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
                <v-btn color='primary' text @click='save()'>Save</v-btn>
            </template>
            <template v-slot:offline-actions>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
                <offline-btn text></offline-btn>
            </template>
        </standard-dialog>
        <confirm ref="confirm"></confirm>

    </div>
</template>
<script>
import { EventBus, Events } from '@/lib/EventBus';
import MineAreas from '@/lib/data/MineAreas';
import ReducedLevels from '@/lib/data/ReducedLevels';
import Locations from '@/lib/data/Locations';
import Cycles from '@/lib/data/Cycles';
import DownloadHelper from '@/lib/DownloadHelper';
import { CycleType } from '@/models/api/Enums/cycle-type';
import { MiningMethodType } from '@/models/api/Enums/mining-method-type';
import { enumOptions } from '@/lib/EnumExtensions';
import { LocationStatus } from '@/models/api/Data/location-status';
import { CreateLocationCommand } from '@/models/api';
import { ValidateLocationName } from '@/lib/services/Location';
import LocationCreationWizard from '@/components/Shared/Locations/LocationCreationWizard.vue';
import SplitButton from '@/components/Shared/Common/SplitButton.vue';

export default {
    components: { SplitButton, LocationCreationWizard },
    data() {
        return {
            data: null,
            mineAreas: null,
            departments: null,
            reducedLevels: null,
            cycles: null,
            cycleTypes: enumOptions(CycleType, this.$options.filters.cycleType),
            miningMethods: enumOptions(MiningMethodType, this.$options.filters.miningMethod),
            statuses: enumOptions(LocationStatus, this.$options.filters.locationStatus),
            selected: null,
            newLocation: null,
            selectedLocationConfigs: null,
            selectedMineArea: null,
            selectedDepartment: null,
            showModal: false,
            importDialog: false,
            file: null,
            headers: [
                { text: 'Name', value: 'name', sortable: false, width: '40%' },
                { text: 'Level', value: 'reducedLevel.name', sortable: false, width: '25%' },
                { text: 'Cycle', value: 'cycle.name', sortable: false, width: '15%' },
                { text: 'Cycle Type', value: 'cycleType | cycleType', sortable: false, width: '20%' },
                { text: '', value: 'displayOrder', sortable: false, align: 'right' }
            ]
        };
    },
    async created() {
        await this.loadData();
    },
    destroyed() {},
    methods: {
        async loadData() {
            this.mineAreas = await MineAreas.get(['Departments']);
            this.reducedLevels = await ReducedLevels.get();
            this.cycles = await Cycles.get();
            var data = await Locations.get(['ReducedLevel', 'Cycle']);
            for (let d of data) {
                d.dirty = false;
            }
            this.data = data.filter(x => x.parentLocationId === null);
            var departments = [];
            for (let mine of this.mineAreas) {
                departments.push({ id: mine.id, name: mine.name, isGroup: true });
                for (let dept of mine.departments) {
                    departments.push({
                        id: dept.id,
                        name: dept.name,
                        isGroup: false,
                        mineAreaId: mine.id
                    });
                }
            }
            this.departments = departments;
        },
        selectMineArea() {
            var selected = this.departments.filter(x => x.id == this.selectedDepartment)[0];
            this.selectedMineArea = selected.mineAreaId;
        },
        createDevelopmentLocation() {
            this.create(CycleType.Duration);
        },
        createAdHocLocation() {
            this.create(CycleType.AdHoc);
        },
        createStope(){
            this.create(CycleType.Rate, MiningMethodType.SubLevelStoping);
        },
        createSLC(){
            this.create(CycleType.Rate, MiningMethodType.SubLevelCaving);
        },
        create(cycleType = null, miningMethod = null) {
            var displayOrder = this.filtered.length ? this.filtered[this.filtered.length - 1].displayOrder + 1 : 1;

            this.newLocation = {
                priority: displayOrder,
                locationType: cycleType,
                miningMethod: miningMethod
            };
        },
        edit(item) {
            this.selected = item;
            this.showModal = true;
            setTimeout(() => this.$refs.form.resetValidation(), 1);
        },
        up(item) {
            if (item.displayOrder > 1) {
                item.displayOrder--;
                item.dirty = true;
            }
        },
        down(item) {
            item.displayOrder++;
            item.dirty = true;
        },
        async saveList() {
            for (let x of this.data) {
                if (!x.dirty) {
                    continue;
                }
                await Locations.update({
                    ...x,
                    _type: "UpdateLocationCommand"
                });
            }
            EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Location')} Saved`);
            await this.loadData();
        },
        cancelNewLocation() {
            this.newLocation = null;
        },
        async saveNewLocation(newLocation){
            await Locations.create({
                ... newLocation,
                departmentId: this.selectedDepartment,
                _type: "CreateLocationCommand",
            });
            EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Location')} Saved`);
            this.newLocation = null;
            await this.loadData();
        },
        async save() {
            if (!this.$refs.form.validate()) {
                return;
            }
            if(this.selected.id)
                await Locations.update({
                    ... this.selected,
                    _type: "UpdateLocationCommand"
                });

            EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Location')} Saved`);
            this.showModal = false;
            await this.loadData();
        },
        async del() {
            if (await this.$refs.confirm.openAsDeleteResource(this.$termSync('Location'),  { })) {
                await Locations.delete(this.selected.id);
                EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Location')} Deleted`);
                this.showModal = false;
                await this.loadData();
            }
        },
        validateLocation(v) {
            var mine = this.mineAreas.filter(x => x.id == this.selectedMineArea)[0];
            var convention = mine.locationConvention;

            return ValidateLocationName(v, convention, this.selected?.cycleType ?? null);
        },
        cyclesFor(departmentId) {
            return this.cycles
                .filter(x => x.departmentId == departmentId)
                .filter(x => x.cycleType === this.selected.cycleType);
        },
        async exportData() {
            const result = await Locations.export(this.selectedDepartment);
            var csv = result.csvFile;
            var blob = DownloadHelper.makeBlobFromFileString(csv);
            var departmentName = this.selectedDepartmentModel.name.replace(/[/\\?%*:|"<>]/g, '');
            DownloadHelper.download(`Locations-${departmentName}.csv`, blob);
        },
        async importData() {
            try {
                this.$wait.start('saving');
                this.uploadResult = null;
                await Locations.import(this.selectedDepartment, this.file);
                await this.loadData();
            } finally {
                this.importDialog = false;
                this.file = null;
                this.$refs.inputUpload.value = null;
                this.$wait.end('saving');
            }
        },
        async getFile(e) {
            this.file = e.target.files[0];
        },
        onChangeCycleType() {
            if(this.selected.cycleType !== CycleType.Rate)
                this.selected.miningMethod = null;
        }
    },
    computed: {
        selectedDepartmentModel() {
            if (!this.data || !this.selectedDepartment) {
                return {};
            }
            return this.departments.filter(x => x.id == this.selectedDepartment)[0];
        },
        filtered() {
            if (!this.data || !this.selectedDepartment) {
                return [];
            }
            const filtered = this.data
                .filter(x => x.departmentId == this.selectedDepartment)
                .sort((a, b) => a.displayOrder - b.displayOrder);
            return filtered;
        },
        filteredMiningMethods() {
            return this.miningMethods;
        },
        filteredStatuses() {
            return this.statuses;
        },
        filteredReducedLevels() {
            if (!this.data || !this.selectedDepartment) {
                return [];
            }
            const filtered = this.reducedLevels
                .filter(x => x.mineAreaId == this.selectedMineArea)
                .sort((a, b) => a.displayOrder - b.displayOrder);
            return filtered;
        },
        filteredDefaultCycles() {
            if(!this.selectedDepartment)
                return [];

            return this.cycles.filter(x=>x.departmentId == this.selectedDepartment && x.cycleType === CycleType.Duration);
        },
        locationNameConvention() {
            var mine = this.mineAreas.filter(x => x.id == this.selectedMineArea)[0];
            return mine?.locationConvention;
        },
        dirty() {
            for (let x of this.data) {
                if (x.dirty) {
                    return true;
                }
            }
            return false;
        },
        showMiningMethod() {
            return this.isRateBased;
        },
        isDuration() {
            return this.selected.cycleType === CycleType.Duration;
        },
        isRateBased() {
            return this.selected.cycleType === CycleType.Rate;
        }
    }
};
</script>

<style lang="scss"></style>
