var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"title dialog-title"},[_vm._v("Hazards - "+_vm._s(_vm.attachedObjectName))]),_c('v-card-text',[(_vm.allowChanges)?_c('v-form',{ref:"form"},[_c('expanding-textarea',{attrs:{"label":"Add new hazard","expand-append-outer":"","rules":[_vm.$rules.maxLength(1000)]},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('div',{staticClass:"d-flex flex-column"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":_vm.onAddHazard}},on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}],null,false,964173319)},[_c('span',[_vm._v("Add hazard")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.onClearHazardTextField}},on),[_c('v-icon',[_vm._v("mdi-cancel")])],1)]}}],null,false,702947463)},[_c('span',[_vm._v("Cancel")])])],1)]},proxy:true}],null,false,1624913870),model:{value:(_vm.newHazard),callback:function ($$v) {_vm.newHazard=$$v},expression:"newHazard"}})],1):_vm._e(),_vm._l((_vm.existingHazards),function(hazard){return _c('v-alert',{key:hazard.note.id,attrs:{"elevation":hazard.toRemove ? 0 : 2,"color":"ordo-signage-yellow","icon":"mdi-alert","border":"left","colored-border":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.allowChanges)?_c('v-access-control',{attrs:{"roles":[_vm.AppRoles.ShiftPlanner, _vm.AppRoles.WeekPlanner]},scopedSlots:_vm._u([{key:"offline",fn:function(){return undefined},proxy:true},{key:"forbidden",fn:function(){return [_c('div')]},proxy:true}],null,true)},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(hazard.toRemove)?_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.restoreRemovedNote(hazard)}}},on),[_c('v-icon',[_vm._v("mdi-undo")])],1):_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.removeHazardNote(hazard)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[(hazard.toRemove)?_c('span',[_vm._v("Undo removal")]):_c('span',[_vm._v("Remove hazard")])])],1):_vm._e()]},proxy:true}],null,true)},[_c('span',[_vm._v(_vm._s(hazard.note.text))]),(hazard.toRemove)?_c('span',{staticClass:"font-weight-thin"},[_vm._v(" (Marked for removal)")]):_vm._e()])}),(_vm.existingHazards.length > 0 && _vm.newHazards.length > 0)?_c('v-divider'):_vm._e(),(_vm.newHazards.length > 0)?_c('v-subheader',[_vm._v("New Hazards")]):_vm._e(),_vm._l((_vm.newHazards),function(hazard){return _c('v-alert',{key:hazard.note.id,attrs:{"elevation":"2","color":"ordo-signage-yellow","icon":"mdi-creation","border":"left","colored-border":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.removeHazardNote(hazard)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove hazard")])])]},proxy:true}],null,true)},[_c('span',[_vm._v(_vm._s(hazard.note.text))]),(hazard.toRemove)?_c('span',{staticClass:"font-weight-thin"},[_vm._v(" (Marked for removal)")]):_vm._e()])}),(!_vm.isOnline && _vm.hasRemovedExistingHazards)?_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" Removing hazards is not supported when offline. Only new hazards will be added if you save now. ")]):_vm._e()],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"gray","text":""},on:{"click":_vm.onClose}},[_vm._v(_vm._s(_vm.hasUnsavedChanges ? "Cancel" : "Close"))]),_c('v-access-control',{attrs:{"offline-access":"","roles":[_vm.AppRoles.WeekPlanner, _vm.AppRoles.ShiftPlanner]}},[(_vm.allowChanges)?_c('v-btn',{attrs:{"color":"primary","text":"","loading":_vm.$wait.is('saving')},on:{"click":_vm.save}},[_vm._v("Save")]):_vm._e()],1)],1),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }