import { EventBus, Events } from '@/lib/EventBus';
import { OfflineEditableCommand, ShiftBoardResponse } from '@/models/api';
import { EditStore, Edit } from '@/lib/OfflineEdits/EditStore';
import { ICommandToModelMapper } from '../../ICommandMapper';

const commandTypesToConvertToBaseline = [
    'UpdateTaskTimesCommand',
    'DeleteTaskCommand',
    'DeleteAdHocTaskCommand',
    'AddTaskBeforeOrAfterCommand',
    'SplitTaskCommand'
];

export class ConvertCommandsToBaselineHelper {
    async onQueue(command: OfflineEditableCommand, store: EditStore) {
        const meta = command.$meta;
        if (!meta || !meta.shift) {
            return;
        }

        //remove any existing 'edits' for this shift
        await store.enumerate(async (x: Edit<OfflineEditableCommand>, c: Date) => {
            if (!commandTypesToConvertToBaseline.includes(x.command._type)) {
                return false;
            }
            const existingMeta = x.command.$meta || {};
            if (existingMeta.shift && existingMeta.shift.id == meta.shift.id) {
                return true;
            }
            return false;
        });
    }
    onDequeue(edit: Edit<OfflineEditableCommand>, attemptedAt: Date): Edit<any> {
        const meta = edit.command.$meta;
        if (!meta || !meta.shift) {
            return edit;
        }
        //change this command to be a snapshot instead
        const command = {
            _type: 'CreateShiftSnapshotCommand',
            name: 'Offline Edit',
            snapshottedAt: attemptedAt,
            isPublish: false,
            departmentShiftId: meta.shift.id,
            snapshotJson: JSON.stringify(meta.shift)
        };
        EventBus.$emit(Events.ToastInfo, 'Task edits you made offline have been saved as a Baseline!', true);
        return { command: command, method: 'POST', url: 'Shift/Board/Snapshots' };
    }
    createShiftModelMapper<TCommand extends OfflineEditableCommand>(): ICommandToModelMapper<TCommand> {
        return {
            viewmodelType: 'ShiftBoardResponse',
            map(command: TCommand, model: ShiftBoardResponse): ShiftBoardResponse | null {
                const meta = command.$meta;
                if (!meta || !meta.shift) {
                    return model;
                }
                return meta.shift;
            }
        };
    }
}

export default new ConvertCommandsToBaselineHelper();
